import React from "react";
import Footer from "../Footer/Footer";
import { HowItWorksReviews } from "../HowItWorks/HowItWorks";
import Navbar from "../Navbar/Navbar";
import { CompanyTitle } from "../settings";
import Cards from "./Cards";
import { applyToBeMentorData } from "./StaticData";

import "./ApplyToBecomeMentor";

const ApplyToBecomeMentor = () => {
  return (
    <div>
      <Navbar />
      <hr />
      {/*Main Component  */}
      <div
        className="xl:container mx-auto px-2 flex flex-col md:flex-row justify-between mt-12 rounded-md"
        style={{
          backgroundImage: "url(/applyToBecomeMentor/bg.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className=" flex flex-col w-full md:w-3/4 justify-center items-center  xl:p-1 mb-4 md:mb-0">
          <h1
            className="text-4xl md:text-6xl xl:text-8xl xl:tracking-tight 2xl:text-8xl 2xl:tracking-tight main-heading text-center md:text-left w-full md:w-10/12 xxl:w-9/12 2xl:w-4/6"
            style={{
              fontFamily: "Lato Bold",
            }}
          >
            {/* Apply to become a career mentor */}
            Join the wait list to become a career mentor
          </h1>
          {/* <button className="bg-bgAppColor text-white text-xs md:text-lg px-6 md:px-20 py-1 md:pt-4 md:pl-10 md:pr-10 md:pb-3 rounded-2xl mt-3 md:mt-5 font-semibold mb-2 md:mb-0 md:-ml-56 xl:-ml-96 xl:mr-20"> */}
          <a
            className="bg-bgAppColor text-white text-xs md:text-lg px-6 md:px-20 py-1 md:pt-4 md:pl-10 md:pr-10 md:pb-3 rounded-2xl mt-3 md:mt-5 font-semibold mb-2 md:mb-0 md:-ml-56 xl:-ml-96 xl:mr-20"
            href="/apply-to-become-mentor-form"
            rel="noreferrer"
          >
            {/* Apply Here */}
            Join the wait list
          </a>
          {/* <a className="bg-bgAppColor text-white text-xs md:text-lg px-6 md:px-20 py-1 md:pt-4 md:pl-10 md:pr-10 md:pb-3 rounded-2xl mt-3 md:mt-5 font-semibold mb-2 md:mb-0 md:-ml-56 xl:-ml-96 xl:mr-20" href="https://form.typeform.com/to/toKmpu2Y?typeform-embed-id=2678222441507472&typeform-embed=popup-blank&typeform-source=careerpaths.io&typeform-medium=embed-sdk&typeform-medium-version=next" target='_blank' rel="noreferrer" >Apply Here</a> */}

          {/* </button> */}
        </div>
        <div className="w-full md:w-2/5">
          <img
            src="/applyToBecomeMentor/mentors.png"
            className="w-full md:block hidden"
            alt="applytobecomementor"
          />
          <img
            src="/applyToBecomeMentor/mentors-mobile.png"
            className="w-full block md:hidden"
            alt="applytobecome"
          />
        </div>
      </div>

      {/* Description */}
      <div className="xl:container mx-auto my-10 md:my-20 md:-mb-2 px-2">
        <p className="text-sm md:text-2xl px-2 text-center text-textDark w-full md:w-10/12 mx-auto">
          As a professional with a few years of work experience under your belt,
          you have undoubtedly gained valuable insights and developed a unique
          perspective on your field. If you’ve reached a point where you think
          the insights you’ve gained might benefit others, {CompanyTitle} is the
          right place for you. We are connecting professionals with students,
          graduates, and other professionals interested in specific fields - we
          started out in Private Equity and have since expanded into areas
          including{" "}
          <a
            href="/mentors/tech"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            Tech
          </a>
          ,{" "}
          <a
            href="/mentors/strategy-consulting"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            Strategy Consulting
          </a>
          ,{" "}
          <a
            href="/mentors/investment-banking"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            Investment Banking
          </a>
          , and others. We’ve seen how a single conversation can have a massive
          positive impact on someone’s career trajectory and confidence and our
          goal is to create a platform to allow everyone to get this
          opportunity.
        </p>
      </div>
      <HowItWorksReviews text="Mentor reviews" />
      <div
        className="block md:hidden pb-10 "
        style={{
          backgroundImage: "url(/applyToBecomeMentor/shadow.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="xl:container mx-auto py-10 md:py-20 px-2">
          {/* <img src="/applyToBecomeMentor/light.png" className="absolute right-0 top-0 border border-red-500 w-2/5"/> */}
          <div className="my-10">
            <p className="text-sm md:text-2xl text-center px-2 text-700 w-full md:w-10/12 mx-auto">
              As in any good relationship, also in mentoring both parties
              benefit. In asking our mentors why they choose to engage, we heard
              a number of reasons. Three of the more common motivations include:
            </p>
          </div>

          <div className="my-10 md:my-32 px-2 z-50">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-9 xl:gap-17">
              {applyToBeMentorData.map((data, index) => {
                return <Cards data={data} index={index} />;
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="hidden md:block sm:px-4 md:px-16"
        style={{
          backgroundImage: "url(/applyToBecomeMentor/light.png)",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="xl:container mx-auto py-10">
          {/* <img src="/applyToBecomeMentor/light.png" className="absolute right-0 top-0 border border-red-500 w-2/5"/> */}
          <div className="my-10">
            <p className="text-sm md:text-2xl text-center text-700 w-full md:w-10/12 mx-auto">
              As in any good relationship, also in mentoring both parties
              benefit. In asking our mentors why they choose to engage, we heard
              a number of reasons. Three of the more common motivations include:
            </p>
          </div>

          <div className="my-10 md:my-32 z-50">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-8 xl:gap-16">
              {applyToBeMentorData.map((data, index) => {
                return <Cards data={data} index={index} />;
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="sm:px-4 md:px-16">
        <div
          className="xl:container mx-auto"
          style={{
            backgroundImage: "url(/applyToBecomeMentor/logo-bg.png)",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="md:my-2 md:mt-20 rounded-3xl">
            <div
              className="xl:container mx-auto py-6 flex rounded-xl md:rounded-3xl md:h-80"
              style={{
                backgroundImage: "url(/applyToBecomeMentor/bg-blue.png)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="w-11/12 md:w-3/5 text-white flex flex-col justify-center relative">
                <div className="md:mt-0 md:hidden  absolute left-5 w-48 md:w-auto ml-0">
                  <p className="text-xs md:text-2xl text-left md:text-center text-white w-full md:w-10/12 mx-auto md:font-bold">
                    We’re operating in a marketplace model that has shown to
                    increase mutual reliability and allows mentors to earn some
                    money on the side, or to donate their proceeds directly to
                    charity.
                  </p>
                </div>
                <div className="md:mt-0 hidden md:block ml-4">
                  <p className="text-xs md:text-2xl text-left md:text-center text-white w-full md:w-10/12 mx-auto md:font-bold">
                    We’re operating in a marketplace model that has shown to
                    increase mutual reliability and allows mentors to earn some
                    money on the side, or to donate their proceeds directly to
                    charity.
                  </p>
                </div>
              </div>
              <div className="ml-16 block md:hidden">
                <img
                  src="/applyToBecomeMentor/tree.png"
                  className="-mt-20"
                  style={{
                    width: 300,
                    height: 200,
                  }}
                  alt=""
                />
              </div>
              <div className="hidden md:flex justify-end">
                <img
                  src="/applyToBecomeMentor/tree.png"
                  className="-mt-16 md:-mt-44 w-full"
                  style={{
                    width: 500,
                    height: 500,
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="xl:container mx-auto relative hidden md:block md:mt-32 mb-8 md:mb-20">
            <img
              src="/applyToBecomeMentor/bg-blue.png"
              className="w-full"
              alt="apply"
            />
            <div className="w-full absolute h-full top-0 left-0 flex flex-col justify-center items-center  ">
              <p className="text-xs md:text-2xl text-center text-white font-bold w-11/12 md:w-10/12 px-3 ">
                Our team is checking all mentor applications to ensure profiles
                are real and mentors are able to add value to our members. The
                process is simple and usually profiles are approved within a few
                days. If you’re interested in becoming a mentor.
              </p>
              <button className="bg-white text-bgAppColor  text-xs md:text-lg px-6 md:px-20 py-2  md:pl-8 md:pr-8 md:pb-3 rounded-full mt-3  font-semibold mb-2 md:mb-0">
                <a href="/apply-to-become-mentor-form" rel="noreferrer">
                  Apply here
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="xl:container mx-auto px-4 block md:hidden">
        <div
          className="my-10 md:my-20 h-60 md:h-96 w-full flex flex-col justify-center md:justify-center items-center rounded-xl"
          style={{
            backgroundImage: "url(/applyToBecomeMentor/bg-blue.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <p className="text-xs md:text-2xl text-justify text-white font-bold w-11/12 md:w-10/12 px-3">
            Our team is checking all mentor applications to ensure profiles are
            real and mentors are able to add value to our members. The process
            is simple and usually profiles are approved within a few days. If
            you’re interested in becoming a mentor.
          </p>
          <button className="bg-white text-bgAppColor text-xs md:text-lg px-6 md:px-12 py-1 md:py-3 rounded-full mt-3 md:mt-2 font-semibold mb-2 md:mb-0">
            <a href="/apply-to-become-mentor-form" rel="noreferrer">
              Apply here
            </a>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ApplyToBecomeMentor;
