import React from "react";
import { Mixpanel } from "../../Mixpanel";
import './style.css';
export default function OfferedBy() {
  return (
    <div className="md:container mx-auto mb-6 sm:px-2 xl:pr-12">
      <div
        className=" flex flex-col md:flex-row w-full rounded-lg  items-center md:items-start"
        style={{
          backgroundImage: "url(/events/efinancial-banner-BG.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div class="flex flex-col md:flex-row ">

          <div className="hidden lg:flex rounded-lg w-3/4 items-start justify-start">
            <img
              src="/events/lapto_banner_efc.png"
              className=" object-cover w-full h-full rounded-lg"
              alt=""
            />
          </div>

          <div class="flex flex-col items-start justify-between p-5  lg:ml-12">
            <p class="mt-2 text-lg font-semibold w-full text-textFullDark">
              We know how challenging it can be to grow your career in financial services and tech, that’s why we’re partnering with eFinancialCareers to get you the best roles in your chosen industries
            </p>

            <div className="flex w-full  mt-5 lg:hidden items-center justify-center ">
              <img
                src="/events/lapto_banner_efc.png"
                className=" object-cover "
                alt=""
              />
            </div>

            <div className="flex flex-col mt-6  md:flex-row justify-between items-center w-full ">

              <div className="">
                <img src="/events/efc_logo.svg" className="w-48" alt="" />
              </div>
              <div>
                <button onClick={() => {
                  Mixpanel.track(`web: open user dashboard.`);
                }} className="rounded-lg mt-6 lg:mt-0">
                  <a href='https://www.efinancialcareers.com' className="course-detail-btn" rel="noreferrer" target="_blank">
                    Find your next job
                  </a>
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}