import React from "react";

const SideItem = (props) => {
  return (
    <div>
      <div
        className={`flex md:hidden items-center rounded w-full hover:bg-blue-100 hover:text-textFullDark cursor-pointer `}
        onClick={() => props.setIsNav(!props.isNav)}
      >
        <div className="object-cover w-4 h-4 ml-4">
          <img src={props.image} alt={props.image} />
        </div>
        <div className="ml-4 text-textFullDark">{props.name}</div>
      </div>
      <div
        className={`hidden md:flex items-center rounded hover:bg-blue-100 hover:text-textFullDark cursor-pointer`}
      >
        <div className="object-cover w-4 h-4 ml-4">
          <img src={props.image} alt={props.image} />
        </div>
        <p className="ml-4 text-textFullDark">{props.name}</p>
      </div>
    </div>
  );
};

export default SideItem;
