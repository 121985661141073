import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isFreeCallCompleted: false,
  isModal: false,
  isFromBookingModal: false,
};

export const freeCallSlice = createSlice({
  name: "freeCall",
  initialState,
  reducers: {
    FreeCallCompleted: (state) => {
      state.isFreeCallCompleted = true;
    },
    FreeCallNotCompleted: (state) => {
      state.isFreeCallCompleted = false;
    },
    toggleFreeCallModal: (state) => {
      state.isModal = !state.isModal;
    },
    offFreeCallModal: (state, action) => {
      state.isFromBookingModal = !state.isFromBookingModal;
    },
    isFromBookingModal: (state, action) => {
      state.isFromBookingModal = !state.isFromBookingModal;
    },
  },
  extraReducers: {},
});

export const {
  FreeCallCompleted,
  FreeCallNotCompleted,
  toggleFreeCallModal,
  offFreeCallModal,
  isFromBookingModal,
} = freeCallSlice.actions;
export default freeCallSlice.reducer;
