import React, { Fragment, Suspense } from "react";
import { Query } from "./../Query";

import { PopularMentors } from './PopularMentors'
import ReuseableComponent from "../reusableComponents/reuseableComponents";


export const RecommendedMentors = ({ userSelectedInterests, userSelectedIndustries }) => (
    <Fragment>
        {userSelectedInterests.length > 0? <Query url={`${process.env.REACT_APP_SERVER_PATH}/professionals?interest_id=${userSelectedInterests.toString()}?industry_id=${userSelectedIndustries.toString()}&shouldAddFeature=true`}>
            {({ state: { data, loading, error }, actions }) => {
                if (error) {
                    return <p style={{ color: "red" }}>{error.message}</p>;
                }
                if (data) {
                    return (
                        <React.Fragment>

                            <ReuseableComponent
                                name="Recommended Mentors"
                                Featured={data}
                                len={data.length}
                            />

                        </React.Fragment>
                    );
                }

                return null;
            }}
        </Query> : null}

    </Fragment>
);
