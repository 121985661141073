import { useState } from 'react'

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  token: null,
  identity: null,
  roomName: null
}
/**
 * get token to connect to a room
 */
const useConfig = () => {
  const [config, setConfig] = useState(INITIAL_STATE);


  const getToken = async ({ identity, roomName }) => {
    if (identity && roomName) {
      setConfig({ ...INITIAL_STATE, isLoading: true })
      const res = await fetch(`${process.env.REACT_APP_SERVER_PATH}/bookings/${roomName}/getVideoToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
        }
      })

      const data = await res.json();


      setConfig(c => ({
        ...c,
        isLoading: false,
        token: data.message.token,
        identity,
        roomName
      }))


    }
  }
  return { ...config, getToken }
}

export default useConfig;