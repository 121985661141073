import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { Mixpanel } from '../../../../../Mixpanel';



const AddEducation = (props) => {
    const [isLoading, setLoading] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const [imgUrl, setImageUrl] = useState('')
    const [fileInput, setFileInput] = useState()
    const [universityName, setUniversityName] = useState('')
    const [subjectName, setSubjectName] = useState('')
    const [startDateToShow, setStartDateToShow] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDateToShow, setEndDateToShow] = useState('')
    const [endDate, setEndDate] = useState('')
    const [image, setImage] = useState(null)
    const [dateError, setDateError] = useState(false)
    const [earlierDateError, setEarlierDateError] = useState(false)
    const [universityError, setUniversityError] = useState(false)
    const [subjectError, setSubjectError] = useState(false)
    const handleStartDate = (e) => {
        setDateError(false)
        setStartDate(new Date(e.target.value))
        setStartDateToShow(e.target.value)
    }
    const handleEndDate = (e) => {
        setDateError(false)
        setEndDate(new Date(e.target.value))
        setEndDateToShow(e.target.value)
    }
    const UploadImage = (id) => {
        const formDataExp = new FormData();
        formDataExp.append("file", image);
        formDataExp.append("education_id", id);
        if (image === null) {
            navigate('../../education')
            setLoading(false)
        } else {
            axios.post(process.env.REACT_APP_SERVER_PATH + `/document/education/`, formDataExp).then((response) => {
                navigate('../../education')
                setLoading(false)
            }).catch(e => {
                console.log("error is from uploading");
            })
        }
    }

    const UpdateEducation = () => {
        if (universityName === '') {
            setUniversityError(true)
        }
        if (subjectName === '') {
            setSubjectError(true)
        }
        if ((!moment(startDate).isSameOrBefore(endDate, 'month')) || moment(startDate).isSame(endDate, 'month')) {
            setDateError(true)
        }
        // check if the date is not greater than today date
        else if(moment(startDate).startOf('month').diff(moment(new Date().toISOString().split("T")[0]).startOf('month'), 'month') > 0){
            setEarlierDateError(true)
        } 
        else {
            setLoading(true)
            const Data = {
                school: universityName,
                field_of_study: subjectName,
                user_id: localStorage.getItem("id"),
                start_date: startDate,
                description: 'hello',
                end_date: endDate,
                status: ''
            }
            axios.put(`${process.env.REACT_APP_SERVER_PATH}/education/${id}`, Data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
                }
            }).then((response) => {
                Mixpanel.track(`web: Update Education.`, Data);

                if (imgUrl === '') {
                    setLoading(false)
                    navigate('../../education')
                } else {
                    UploadImage(response.data.id)
                }
            }).catch(e => {
                console.log(e)
            })
        }

    }

    const handleImg = (event) => {
        if (event.target.files[0].type === 'image/jpeg'
            || event.target.files[0].type === 'image/png') {
            setImageUrl(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        } 

    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/getEducation/${id}`).then((response) => {
            setSubjectName(response.data.message[0].field_of_study)
            setStartDate(response.data.message[0].start_date)
            setEndDate(response.data.message[0].end_date)
            setUniversityName(response.data.message[0].school)
            setImageUrl(response.data.message[0].profile_photo ? response.data.message[0].profile_photo : ' /educationplaceholder.png')
            setStartDateToShow(moment(response.data.message[0].start_date).format('YYYY-MM-DD'))
            setEndDateToShow(moment(response.data.message[0].end_date).format('YYYY-MM-DD'))
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <div>
            <div className="w-full flex items-center justify-center flex-col">
                <input className="text-xs text-white ml-2" type="file" onChange=
                    {handleImg}
                    ref={fileinput => setFileInput(fileinput)}
                    style={{ display: 'none' }} />
                <img src={imgUrl ? imgUrl : ' /educationplaceholder.png'} alt="images" className="w-16 h-16 rounded-full object-cover border border-borderApp text-textFullDark" />
                <button className="mt-3" onClick={() => {
                    fileInput.click()
                }}>Add Logo</button>
            </div>
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="university">
                    University
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="school" type="text" placeholder="University" value={universityName}
                    onChange={(e) => {
                        setUniversityError(false)
                        setUniversityName(e.target.value)
                    }} />
            </div>
            {universityError && <p className='text-sm -mt-2 text-red-500'>Please enter university name</p>}
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="subject">
                    Subject
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="job" type="text" placeholder="Subject"
                    value={subjectName}
                    onChange={(e) => {
                        setSubjectError(false)
                        setSubjectName(e.target.value)
                    }} />
            </div>
            {subjectError && <p className='text-sm -mt-2 text-red-500'>Please enter subject name</p>}
            <div className="flex w-full justify-between">
                <div className="w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="firstName">
                        Start Date
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="firstName" type="date" placeholder="Enter first name" value={startDateToShow} onChange={handleStartDate} 
                    max={new Date().toISOString().split("T")[0]}
                    />
                </div>
                <div className="ml-2 w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="firstName">
                        End Date
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="firstName" type="date" placeholder="" value={endDateToShow} onChange={handleEndDate} 
                    />
                </div>
            </div>
            {dateError && <p className='text-sm text-red-500 my-1'>Start date should be less than the end date</p>}
            {earlierDateError && <p className='text-sm text-red-500 my-1'>Date should be today or earlier</p>}
            <button className={`bg-bgAppColor text-white text-center  ${subjectName === '' || universityName === '' ? 'cursor-wait' : 'cursor-pointer'} w-full text-sm rounded flex items-center justify-center mt-3 py-3 hover:bg-blue-300 ${subjectName !== '' && universityName !== '' ? 'bg-bgAppColor' : 'bg-bgdarkGray'}`}
                onClick={UpdateEducation}
                disabled={subjectName === '' && universityName === ''}
            >Save{isLoading ? <span className='ml-4'>
                <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                // timeout={5000} //5 secs
                /></span> : null}</button>
        </div >
    )
}

export default AddEducation
