import React from "react";
export default function OfferedBy() {
  return (
    <div className="md:container mx-auto  sm:px-2 md:py-6">
       <div
        className=" flex flex-col md:flex-row w-full rounded-lg  items-center md:items-start"
        style={{
          backgroundImage: "url(/events/offeredBy-background.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div class="flex flex-col md:flex-row">
          <div className="w-full md:w-48 flex items-center justify-center p-5 ">
            <img
              src="/events/logo.svg"
              className="w-12 md:w-36 mt-3 object-cover"
              alt=""
            />
          <div className=" w-full block md:hidden items-ceter mx-3 justify-between">
              <h1 class="text-xl font-bold">Careerpath learning</h1>
            </div>
          </div>
          <div class=" p-4 md:p-4">
          <div className="md:flex w-full hidden items-ceter justify-between">
              <h1 class="text-xl font-bold">Careerpaths learning</h1>
            </div>

            <div className=" my-2">
              <p class="mt-2  font-semibold w-10/12 text-textFullDark">
                The course deals with the analysis of the private equity and
                venture capital business. Over the course, students will be
                provided with a deep understanding of the mechanism underpinning
                the creation and/or development of a firm and the financial
                support it can get from the financial system through venture
                capital investment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
