import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isCharity: false,
    isCharityModal:false,
    isThankyouModal : false,
    isChangePercentageModal : false,
    charityPercent : 0
}

export const charitySlice = createSlice({
    name:"charity",
    initialState,
    reducers:{
        toggleCharity :(state,action)=>{
            state.isCharity = !state.isCharity
        },
        toggleCharityModal :(state)=>{
         state.isCharityModal = !state.isCharityModal;
        },
        toggleChangePercentageModal :(state)=>{
            state.isChangePercentageModal = !state.isChangePercentageModal;
           },
   
        toggleThankyouModal :(state)=>{
            state.isCharityModal = false
            state.isThankyouModal = !state.isThankyouModal
        },
        updateCharityPercent :(state, action)=>{
           state.charityPercent = action.payload.percent
        }
        
    },
    extraReducers:{
    }
})

export const { updateCharityPercent, toggleCharity, toggleCharityModal,toggleChangePercentageModal, toggleThankyouModal } = charitySlice.actions;
export default charitySlice.reducer;