import axios from "axios";

import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import Colors from "../../../../Colors/Colors";

import WeeklyTransactions from "./WeeklyTransactions";
import {
  showPaymentModalToTrue,
  showPaymentModalToFalse,
} from "../../../../Redux/reducers/payment/paymentSlice";

const PaymentsLower = ({ user }) => {
  let dispatch = useDispatch();
  let showPaymentModal = useSelector((state) => state.payment.showPaymentModal);
  const [isLoading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [hide_load_more, setHide_load_more] = useState(false);

  const [transactionsWeekly, setTransactionsWeekly] = useState([]);
  const [customerTransactionsWeekly, setCustomerTransactionsWeekly] = useState(
    []
  );
  const [transactionsMonthly, setTransactionsMonthly] = useState([]);
  const [customerTransactionsMonthly, setCustomerTransactionsMonthly] =
    useState([]);
  const [transactionsYearly, setTransactionsYearly] = useState([]);
  const [customerTransactionsYearly, setCustomerTransactionsYearly] = useState(
    []
  );
  const [alreadyRequestedPayout, setAlreadyRequestedPayout] = useState(false);
  const [registeredWithStripe, setRegisteredWithStripe] = useState(false);
  const [lessMoneyError, setLessMoneyError] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  // const [link, setLink] = useState('')
  // const [navState, setNavState] = useState(0);
  let type = JSON.parse(localStorage.getItem("type"));
  let link = `https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_BASE_WEB}`;


  useEffect(() => {
    getPaymentTransactions();
  }, []);

  const getPaymentTransactions = () => {
    if (type === "professional") {
      // Weekly Data of Transactions fro Professional
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/professional_transactions?limit=${limit}&page=${page}&status=week`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setTransactionsWeekly(
            transactionsWeekly.concat(response.data.message.transactions)
          );
          setPage(page + 1);
          setHide_load_more(
            response.data.message.transactions.length < 1 ? true : false
          );
        })
        .catch((e) => {
          console.log("Errors in Professional Payment api");
        });
      // Monthly Data of Transactions fro Professional
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/professional_transactions?limit=${limit}&page=${page}&status=month`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setTransactionsMonthly(response.data.message.transactions);
        })
        .catch((e) => {
          console.log("Errors in Professional Payment api");
        });

      // Yearly Data of Transactions fro Professional
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/professional_transactions?limit=${limit}&page=${page}&status=year`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setAlreadyRequestedPayout(
            response.data?.message?.alreadyRequestedPayout
          );
          setRegisteredWithStripe(response.data?.message?.registeredWithStripe);
          setTransactionsYearly(response.data.message.transactions);
          setLoading(false);
        })
        .catch((e) => {
          console.log("Errors in Professional Payment api");
        });
    } else {
      // Weekly Data of Transactions fro Customer
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/customer_transactions?limit=${limit}&page=${page}&status=week`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          const previoudList = customerTransactionsWeekly;
          setCustomerTransactionsWeekly(
            previoudList.concat(response.data.message)
          );
          setPage(page + 1);
          setHide_load_more(response.data.message.length < 1 ? true : false);
        })
        .catch((e) => {
          console.log("Errors in customer Payment api week");
        });

      // Monthly Data of Transactions fro Customer
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/customer_transactions?limit=${limit}&page=${page}&status=month`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setCustomerTransactionsMonthly(response.data.message);
        })
        .catch((e) => {
          console.log("Errors in customer Payment api month");
        });

      // Yearly Data of Transactions for Customer
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/customer_transactions?limit=${limit}&page=${page}&status=year`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setCustomerTransactionsYearly(response.data.message);
          setLoading(false);
        })
        .catch((e) => {
          console.log("Errors in customer Payment api year");
        });
    }
  };

  const requestPayout = () => {
    if (user.User?.wallet < 1) {
      setLessMoneyError(true);
      return;
    }
    setApiLoading(true);
    setLoading(true);
    let token = localStorage.getItem("cp_web_token");
    console.log(localStorage.getItem("cp_web_token"), "dadadadada");
    fetch(`${process.env.REACT_APP_SERVER_PATH}/professional_payout`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response, "dadadadada");
        // dispatch({ type: 'showPaymentModalToTrue' })
        localStorage.setItem('showPaymentModal', true);
        setLoading(false);
        setApiLoading(false);
        window.location.reload();
        
      })
      .catch((e) => {
        console.log(localStorage.getItem("cp_web_token"), "dadadadada");
        console.log("dadadadada Errors in professional request payout api");
        setLoading(false);
        setApiLoading(false);
      });
  };

  useEffect(() => {
    const showPaymentModal = localStorage.getItem('showPaymentModal');
    if (showPaymentModal) {
      dispatch(showPaymentModalToTrue());
      localStorage.removeItem('showPaymentModal');
    }
  }, []);
  

  let showBtnComponent;

  if (registeredWithStripe) {
    if (alreadyRequestedPayout) {
      showBtnComponent = (
        <div className="w-full my-4 flex flex-col items-center justify-center pt-4">
          <button
            className="p-2 text-center rounded-full bg-bgAppColor cursor-not-allowed text-white w-full md:w-96"
            disabled={user.User?.wallet < 100}
            style={{ backgroundColor: alreadyRequestedPayout ? Colors.bgGray : Colors.bgBlue, color: alreadyRequestedPayout ? 'white' : 'black'} }
          >
            Request a payout
          </button>
          <p className="text-sm text-center mt-2 font-bold">
            We have received your request and we are working on it. You should
            receive your payout within 5-7 working days.
          </p>
        </div>
      );
    } else {
      showBtnComponent = (
        <div className="w-full my-4 flex flex-col items-center justify-center pt-4">
          <button
            className="p-2 text-center rounded-full bg-bgAppColor text-white w-full flex justify-center items-center md:w-96"
            onClick={requestPayout}
            disabled = {alreadyRequestedPayout}
            style={{ backgroundColor: alreadyRequestedPayout ? Colors.bgGray : Colors.bgBlue, color: alreadyRequestedPayout ? 'black' : 'white'} }
          >
            {!apiLoading ? (
              "Request a payout"
            ) : (
              <Loader width={20} height={20} type="Oval" color="white" />
            )}
          </button>
          {lessMoneyError && (
            <p className="mt-2 text-sm font-bold text-red-500">
              You don't have enough funds
            </p>
          )}
        </div>
      );
    }
  } else {
    showBtnComponent = (
      <div className="w-full my-4 flex justify-center pt-4">
        <a
          href={`${link}`}
          target="_parent"
          className="p-2 text-center rounded-full bg-bgAppColor text-white w-full md:w-96"
        >
          Connect to stripe
        </a>
      </div>
    );
  }

  const togglePaymentModal = () => {
    if (showPaymentModal) {
      // dispatch({ type: 'showPaymentModalToFalse' })
      dispatch(showPaymentModalToFalse());
    } else {
      // dispatch({ type: 'showPaymentModalToTrue' })
      dispatch(showPaymentModalToTrue());
    }
  };
  return (
    <div>
      <div className="h-2/12">
        <div className="flex p-4 rounded-t items-center justify-between text-white text-xl w-full bg-bgAppColor">
          <div className="w-full flex items-center justify-center">
            <p>Transactions</p>
          </div>
        </div>
      </div>
      <div className="bg-white rounded mb-6 sm:p-2 md:p-6">
        {/* <div className='w-full bg-gray-200 flex rounded-xl'>
                  <button className='py-2 px-4  hover:text-black hover:bg-transparent rounded-xl w-full m-1'
                      onClick={() => setNavState(0)}
                      style={{
                          backgroundColor: navState === 0 ? 'black' : '',
                          color: navState === 0 ? 'white' : ''
                      }}>Week</button>
                  <button className='py-2 px-4  hover:text-black hover:bg-transparent rounded-xl w-full m-1'
                      onClick={() => setNavState(1)}
                      style={{
                          backgroundColor: navState === 1 ? 'black' : '',
                          color: navState === 1 ? 'white' : ''
                      }}>Month</button>
                  <button className='py-2 px-4  hover:text-black hover:bg-transparent rounded-xl w-full m-1'
                      onClick={() => setNavState(2)}
                      style={{
                          backgroundColor: navState === 2 ? 'black' : '',
                          color: navState === 2 ? 'white' : ''
                      }}>Year</button>
              </div> */}
        {showPaymentModal && (
          <PaymentModal
            toggle={togglePaymentModal}
            overlay="overlay"
            modal="modal"
          />
        )}
        <div className="h-96 overflow-y-auto" style={{ height: "550px" }}>
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <span className="ml-8">
                <Loader
                  type="Oval"
                  color={Colors.blue}
                  height={30}
                  width={30}
                  // timeout={5000} //5 secs
                />
              </span>
            </div>
          ) : (
            <>
              <WeeklyTransactions
                transactionsWeekly={transactionsWeekly}
                userType={type}
                customerTransactionsWeekly={customerTransactionsWeekly}
              />
              {!hide_load_more && (
                <div className="w-full my-4 flex flex-col items-center justify-center pt-4">
                  <button
                    onClick={() => {
                      setApiLoading(false);
                      getPaymentTransactions();
                    }}
                    className="px-3 py-2 text-center rounded-full bg-bgAppColor  text-white w-40 "
                  >
                    Load more{" "}
                    {apiLoading && (
                      <span className="ml-8">
                        <Loader
                          type="Oval"
                          color={Colors.blue}
                          height={30}
                          width={30}
                          // timeout={5000} //5 secs
                        />
                      </span>
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {console.log(user?.registeredWithStripe, "user.registeredWithStripe")}
        {!isLoading && <div>{type === "professional" && showBtnComponent}</div>}
      </div>
    </div>
  );
};

export default PaymentsLower;
// All payments transactions related to your meetings will be shown hear

const PaymentModal = (props) => {
  let dispatch = useDispatch();

  return (
    <div className={props.modal}>
      <div className={props.overlay} onClick={props.toggle}></div>
      <div className="w-screen h-screen  flex items-center justify-center">
        <div className="sm:w-11/12 md:w-1/3 bg-white border border-borderGray p-4 md:p-8 z-30 rounded ">
          <p className="text-2xl text-green-700 text-center">
            PAYMENT REQUEST RECEIVED
          </p>
          <p className="text-textFullDark text-sm text-center my-3">
            Your Payout Request is submitted and waiting for approval. You
            should receive your payout within 5 working days
          </p>
          <button
            className="text-center w-full text-white bg-green-700 py-3"
            onClick={() => {
              // dispatch({ type: 'showPaymentModalToFalse' })
              dispatch(showPaymentModalToFalse());
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
