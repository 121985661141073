import React, { useEffect, useState } from "react";
import Ratings from "../../Rating/Ratings";
import Select from "react-select";
import { LogoName } from "../../settings";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toggleReviews } from "../../Redux/reducers/review/reviewSlice";
import { useSelector, useDispatch } from "react-redux";
import { ReviewAll } from "../../Profile/Reviews/Reviews";
import './style.css';
const MainImage = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const validate = Yup.object({
    firstName: Yup.string().max(20, "Must be 20 characters or less").required('First name is required'),
    lastName: Yup.string().max(20, "Must be 20 characters or less").required('Last name is required'),
    email: Yup.string().email('Email is invalid').required('Email is Required'),
    company: Yup.string().required('Company name is required'),
    jobTitle: Yup.string().required('Job title is required'),
  })
  const [reviewerData] = useState([
    {
      "id": 430,
      "rating": 5,
      "feedback": "The course helped me better understand my strengths and weaknesses, and gave me the confidence to quit my job and start a career in Private Equity",
      "reviewer": {
        "id": 346,
        "first_name": "Omegie A.",
      }
    },
    {
      "id": 430,
      "rating": 5,
      "feedback": "Guy was engaging and very knowledgable about Private Equity. He helped me understand the basics and gave me more confidence to move into Private Equity.",
      "reviewer": {
        "id": 346,
        "first_name": "Selim K.",
      }
    },
    {
      "id": 430,
      "rating": 5,
      "feedback": "It's important for me to learn as much as I can. This course is another great asset for my knowledge bank!",
      "reviewer": {
        "id": 346,
        "first_name": "Sophia B.",
      }
    },
    {
      "id": 430,
      "rating": 5,
      "feedback": "I have researched across many platforms, you get a lot for your money through this course!",
      "reviewer": {
        "id": 346,
        "first_name": "Mickey N.",
      }
    },
    {
      "id": 430,
      "rating": 5,
      "feedback": "The course gave me hope and confidence for the future. I've also made some great connections through CareerPaths online learning community",
      "reviewer": {
        "id": 346,
        "first_name": "Eddie R.",
      }
    },
  ]);
  const toggleShow = () => {
    dispatch(toggleReviews());
  };
  const [showModal, setShowModal] = useState(false);
  const show = useSelector((state) => state.review.showReviews);
  const [showSuccessModal, setSuccessShowModal] = useState(false);
  const options = [
    { value: "private-equity", label: "Private Equity" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const colourStyles = {
    control: (styles) => ({ ...styles, padding: "3px", borderRadius: "9px" }),
  };

  return (
    <div className="xl:container mx-auto flex flex-col lg:flex-row px-2  mt-2 md:mt-6">
      {show && (
        <ReviewAll
          toggle={toggleShow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={show}
          reviews={reviewerData}
        />
      )}
      <div
        className="rounded-3xl flex flex-col md:flex-row w-full items-center md:items-start lg:items-end py-8 md:py-10"
        style={{
          backgroundImage: "url(/events/hero-background.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div className="w-full md:w-7/12 flex flex-col items-start px-6  md:py-0 md:px-20 ">
          <p className="mr-3 my-1">
            CareerPaths first flagship training course
          </p>
          <div
            className="text-black text-3xl md:text-4xl lg:text-5xl "
            // style={{ fontFamily: "Cerebri Extra Bold" }}
            style={{ fontFamily: "Lato Extra Bold" }}
          >
            <h1 className=" text-left">Introduction to Private Equity</h1>
          </div>
          <div
            className="flex flex-col items-start mt-5 cursor-pointer"
            onClick={toggleShow}
          >
            <div className="flex items-center justify-center mr-5">
              <Ratings
                value={5}
                size={15}
                activeColor="#ffd700"
                isHalf={true}
                edit={false}
              />
              <p className="ml-2 font-bold text-gray-700 pt-2">5.0</p>
            </div>
            <div className="flex items-center justify-center ">
              <p className="text-xs text-textDark">5 reviews</p>
            </div>
          </div>
          <div className="mt-5 ml-1">
            <p>21 people interested</p>
          </div>

          {/*main image - order of display is different in desktop and mobile, thats why I made 2 div. hide 1 desktop and other in mobile */}
          <div className="w-full mt-8 md:w-5/12 block md:hidden">
            <img src="/events/course-laptop1.svg" alt="" className="w-full" />
          </div>

          <div className="mt-8 self-center md:self-start md:mt-16 lg:mt-24">
            <button
              className="course-detail-btn"
              onClick={() => setShowModal(true)}
            >
              Register Your Interest
            </button>
          </div>
        </div>

        {/*main image - order of display is different in desktop and mobile, thats why I made 2 div. hide 1 desktop and other in mobile */}
        <div className="w-full md:w-5/12 hidden md:block">
          <img src="/events/course-laptop1.svg" alt="" className="w-full" />
        </div>
      </div>

      {/* registration modal - start */}

      {showModal ? (
        <div id="">
          <div class="absolute top-12 self-center right-0.5 flex justify-center items-center z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
            <div class="relative w-full  max-w-lg md:h-auto">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                >
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span class="sr-only">Close modal</span>
                </button>
                <div class="py-6 ">
                  <iframe
                    src="https://careerpaths976.activehosted.com/f/3"
                    className="w-full"
                    style={{ height: "650px" }}
                  ></iframe>
                  {/* I made the below form considering that we will use Register your interest modal with backend APIs but later we decide to use active compaign so I commented this code for futute use if there is any  */}

                  {/* <Formik
                        initialValues={{
                          firstName: '',
                            lastName: '',
                            email: '',
                            company: '',
                            jobTitle: '',
                            interest: '',
                        }}
                        validationSchema={validate}
                        onSubmit={(values) =>  {
                          console.log("hello",values)
                            setShowModal(false)
                            setSuccessShowModal(true)
                        } }
                    >
                        {formik => (
                              <Form class="space-y-3">
                                <div>
                                  <label class=" text-sm font-medium text-textFullDark">
                                    First Name
                                  </label>
                                  <input
                                    id="firstName"
                                    type="text"
                                    class="border border-gray-300 text-textFullDark text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    // placeholder="Enter first name"
                                    {...formik.getFieldProps('firstName')}
                                  />
                                  {formik.touched.firstName && formik.errors.firstName ? (
                                      <div className="text-red-500">{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div>
                                  <label class=" text-sm font-medium text-textFullDark">
                                    Last Name
                                  </label>
                                  <input
                                  id="lastName"
                                    type="text"
                                    class="  border border-gray-300 text-textFullDark text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    // placeholder="Enter last name"
                                    {...formik.getFieldProps('lastName')}
                                  />
                                  {formik.touched.lastName && formik.errors.lastName ? (
                                      <div className="text-red-500">{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                <div>
                                  <label class=" text-sm font-medium text-textFullDark">
                                    Email
                                  </label>
                                  <input
                                  id="email"
                                    type="email"
                                    class="  border border-gray-300 text-textFullDark text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    // placeholder="Enter email"
                                    {...formik.getFieldProps('email')}
                                  />
                                    {formik.touched.email && formik.errors.email ? (
                                      <div className="text-red-500">{formik.errors.email}</div>
                                    ) : null}

                                </div>
                                <div>
                                  <label class=" text-sm font-medium text-textFullDark">
                                    Company
                                  </label>
                                  <input
                                    type="text"
                                    id="company"
                                    class="  border border-gray-300 text-textFullDark text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    // placeholder="Enter company"
                                    {...formik.getFieldProps('company')}
                                  />
                                  {formik.touched.company && formik.errors.company ? (
                                      <div className="text-red-500">{formik.errors.company}</div>
                                    ) : null}
                                </div>
                                <div>
                                  <label class=" text-sm font-medium text-textFullDark">
                                    Job Title
                                  </label>
                                  <input
                                  id="jobTitle"
                                    type="text"
                                    class="  border border-gray-300 text-textFullDark text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    // placeholder="Enter job title"
                                    {...formik.getFieldProps('jobTitle')}
                                  />
                                    {formik.touched.jobTitle && formik.errors.jobTitle ? (
                                      <div className="text-red-500">{formik.errors.jobTitle}</div>
                                    ) : null}
                                </div>
                                <div>
                                  <label class=" text-sm font-medium text-textFullDark">
                                    Interest
                                  </label>
                                  <Select
                                    isSearchable={false}
                                    value={selectedOption}
                                    onChange={(e) => setSelectedOption(e)}
                                    options={options}
                                    styles={colourStyles}
                                    // placeholder="Select interest"
                                  />
                                </div>

                                <button
                                  type="submit"
                                  // onClick={()=>{
                                  //   setShowModal(false)
                                  //   setSuccessShowModal(true)
                                  // }}
                                  class="w-full text-white mt-2 bg-bgAppColor font-bold rounded-lg  px-5 py-3 text-center"
                                >
                                  Register
                                </button>
                              </Form>
                        )}
                    </Formik> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
      {/* registration modal - end */}

      {/* success modal - start */}

      {showSuccessModal ? (
        <>
          <div class="fixed flex justify-center items-center z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
            <div class="relative w-full h-full max-w-lg md:h-auto">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  onClick={() => setSuccessShowModal(false)}
                  type="button"
                  class="absolute top-3 z-50 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                >
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span class="sr-only">Close modal</span>
                </button>
                <div class="px-6 py-6 lg:px-8">
                  <div className=" text-center ">
                    <div className="relative text-left align-middle bg-white dark:bg-gray-900">
                      <div className="flex flex-col items-center justify-center mx-auto">
                        <img
                          className="w-2/3 rounded-lg"
                          src="/events/successModalUser.png"
                          alt="successModalUser"
                        />
                      </div>

                      <div className="text-center flex flex-col items-center">
                        <h3
                          className="text-xl font-bold text-gray-800 dark:text-white"
                          id="modal-title"
                        >
                          Thank you for registering interest.
                        </h3>
                        <p className="mt-2 w-2/3 text-gray-500 dark:text-gray-400">
                          CareerPaths will notify you when the course is
                          available to book.
                        </p>

                        <button
                          onClick={() => {
                            navigate("/");
                          }}
                          className="px-4 w-1/2 py-2.5 mt-3 text-sm font-medium text-white capitalize  bg-bgAppColor rounded-md "
                        >
                          Go to Home page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* success modal - end */}
    </div>
  );
};

export default MainImage;
