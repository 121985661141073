import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isBanner: true,
}

export const bannerSlice = createSlice({
    name:"banner",
    initialState,
    reducers:{
        setBannerFalse :(state,action)=>{
            state.isBanner = false
        },
        setBannerTrue :(state)=>{
            state.isBanner = true
        }
    },
    extraReducers:{
    }
})

export const { setBannerTrue, setBannerFalse } = bannerSlice.actions;
export default bannerSlice.reducer;