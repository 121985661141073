import React, { useEffect, useRef } from "react";
import "./remoteParticipant.css";
const VideoTrack = ({ track, name, isMessage, setIsMessage }) => {
  const ref = useRef();

  useEffect(() => {
    if (track) {
      const el = ref.current;
      track.attach(el);

      return () => {
        track.detach(el);
      };
    }
  }, [track]);
  return (
    <div className="text-gray-700">
      <div className="absolute bg-white m-4 px-4 py-2 rounded-full">{name}</div>
      <video
        style={{
          position: "fixed",
          left: 0,
          minWidth: isMessage ? "85%" : "100%",
          maxHeight: "100%",
          alignSelf:"center",
          padding: "2%",
        }}
        ref={ref}
        className="rounded-lg sm:top-1/4 lg:top-0"
      ></video>
    </div>
  );
};

export default VideoTrack;
