import React from "react";
import MuiRating from '@mui/material/Rating';

const Ratings = (props) => {
  const sizeString = props?.size?.toString();
  return (
    <>
      <div className="flex items-center">
        {props?.value ? (
          <MuiRating
            name="profile-main-rating"
            defaultValue={props.value ? props.value : 0}
            precision={0.1}
            readOnly
            max={props.max ? props.max : 5}
            size = {sizeString}
          />
        ) : (
          <MuiRating
            name="profile-main-rating"
            defaultValue={props.value ? props.value : 0}
            precision={0.1}
            readOnly
          />
        )}
        {/* &nbsp;&nbsp;
        {props.value && (
          <p className="text-textDark">
            {getDecimalsWithoutRounding(props.profile.avgRating, 1)}
            {(+props.value).toFixed(1)}
          </p>
        )} */}
      </div>
    </>
  );
};

export default Ratings;
