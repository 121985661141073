import React, { useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-skeleton-loading";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../Arrows/LeftArrow";
import RightArrow from "../Arrows/RightArrow";
import { useLocation, useNavigate } from "react-router-dom";
import RoundedSkeleton from "./RoundedSkeleton";
const FeaturedSkeleton = () => {
  let queryString = useLocation();
  let navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Ref = useRef(null);
  const SlickNext = () => {
    Ref.current.slickNext();
  };
  const SlickPrev = () => {
    Ref.current.slickPrev();
  };
  return (
    <div className="bg-bgGray py-2">
      <div className="md:container mx-auto my-4 sm:px-2 xl:px-16  flex justify-between items-center">
        <h1 className="text-textFullDark sm:text-lg md:text-2xl font-bold">
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton height={24} width={250} />
          </SkeletonTheme>
        </h1>
        <div className="text-md flex flex-row items-center text-textDark">
          <LeftArrow click={SlickPrev} />
          <RightArrow click={SlickNext} />
        </div>
      </div>
      <div className="md:container mx-auto my-6 px-2 xl:px-16">
        <Slider {...settings} ref={Ref}>
          {[...Array(6)].map((_, i) => (
            <span className="cursor-pointer" key={i}>
              <RoundedSkeleton />
            </span>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default FeaturedSkeleton;
