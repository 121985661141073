import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { pricetoDecimal } from "../price";
import SignIn from "../SignIn/SignIn";
import "../BookingPage/BookingMainContent/MainContent/Payments/Payment.css";
import { Mixpanel } from "../Mixpanel";
import { useDispatch } from "react-redux";
import { setLogin } from "../Redux/reducers/customer/customerSlice";
import axios from "axios";
import Loader from "react-loader-spinner";
// import Colors from "../../Colors/Colors";
import Colors from "../Colors/Colors";

const FormikRadio = (props) => {
  const [isUserEligibleForFreeCall, setIsUserEligibleForFreeCall] =
    useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    let token = localStorage.getItem("cp_web_token");

    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/freecall-indusrty-validation/${props.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setIsUserEligibleForFreeCall(response.data.isUserEligible);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log("error in freecall-indusrty-validation", e, props.id);
        setLoader(false);
      });
  }, []);

  let dispatch = useDispatch();
  let queryString = useLocation();
  const [modal, setModal] = useState(false);
  const [color, setColor] = useState(0);
  const [customerSignUpFlow, setCustomerSignUpFlow] = useState(
    queryString.pathname === "/signup" ? true : false
  );
  const [choice, setChoice] = useState("call_for_15_min");
  const toggle = () => {
    setModal(!modal);
  };
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  let navigate = useNavigate();

  return (
    <div>
      {modal ? (
        <SignIn
          toggle={toggle}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          customerSignUpFlow={customerSignUpFlow}
          setCustomerSignUpFlow={setCustomerSignUpFlow}
        />
      ) : (
        <div>
          {loader ? (
            <div className="flex justify-center py-10 items-center">
              <Loader
                type="TailSpin"
                color={Colors.blue}
                height={100}
                width={100}
                // timeout={5000} //5 secs
              />
            </div>
          ) : (
            <Formik
              initialValues={{
                picked: choice,
              }}
              onSubmit={async (values) => {
                let data;
                if (choice === "call_for_15_min") {
                  data = {
                    minutes: 15,
                    price: props.sessionPrice15,
                    id: props.id,
                    isUserEligibleForFreeCall: isUserEligibleForFreeCall,
                  };
                } else if (choice === "call_for_30_min") {
                  data = {
                    minutes: 30,
                    price: props.sessionPrice30,
                    id: props.id,
                    isUserEligibleForFreeCall: isUserEligibleForFreeCall,
                  };
                }
                if (localStorage.getItem("cp_web_token")) {
                  Mixpanel.track(`web: Click Book a Session Button.`, data);
                  navigate(`/request-a-call/${props.id}`, { state: data });
                } else {
                  // dispatch({ type: 'setLogin' })
                  dispatch(setLogin());
                  // setModal(true)
                }
              }}
            >
              {({ values }) => (
                <Form>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="flex flex-col rounded mt-4"
                  >
                    <div
                      className={`text-textFullDark flex justify-between bg-bgGray my-2 p-4 border-2 ${
                        color === 0 ? "border-borderApp" : "border-transparent"
                      } rounded-lg cursor-pointer`}
                      onClick={() => {
                        setColor(0);
                        setChoice("call_for_15_min");
                        props.setChoice("call_for_15_min");
                      }}
                    >
                      <p className="font-bold">
                        {isUserEligibleForFreeCall
                          ? "Free call for 15 min"
                          : "Call for 15 min"}{" "}
                      </p>
                      {!isUserEligibleForFreeCall && (
                        <p className="font-bold">
                          {pricetoDecimal(props.sessionPrice15)}
                        </p>
                      )}
                    </div>
                    {isUserEligibleForFreeCall ? (
                      <></>
                    ) : (
                      <div
                        className={`text-textFullDark flex justify-between p-4 border-2 ${
                          color === 1
                            ? "border-borderApp"
                            : "border-transparent"
                        }  bg-bgGray rounded-lg cursor-pointer`}
                        onClick={() => {
                          setColor(1);
                          setChoice("call_for_30_min");
                          props.setChoice("call_for_30_min");
                        }}
                      >
                        <p className="font-bold">Call for 30 min</p>
                        <p className="font-bold">
                          {pricetoDecimal(props.sessionPrice30)}
                        </p>
                      </div>
                    )}
                  </div>
                  {!isUserEligibleForFreeCall ? (
                    <button
                      className={`text-center text-lg p-4 w-full bg-bgAppColor mt-3 rounded-lg text-white hover:text-2xl" type="submit ${
                        localStorage.getItem("cp_web_token") ? "" : "hidden"
                      }`}
                    >
                      <div className="flex items-center justify-center font-bold">
                        <p className="mr-2">Book session</p>
                        {choice === "call_for_15_min"
                          ? pricetoDecimal(props.sessionPrice15)
                          : pricetoDecimal(props.sessionPrice30)}
                      </div>
                    </button>
                  ) : (
                    <button
                      className={`text-center text-lg p-4 w-full bg-bgAppColor mt-3 rounded-lg text-white hover:text-2xl" type="submit ${
                        localStorage.getItem("cp_web_token") ? "" : "hidden"
                      }`}
                    >
                      <div className="flex items-center justify-center font-bold">
                        <p className="mr-2">Book free session</p>
                      </div>
                    </button>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
};

export default FormikRadio;
