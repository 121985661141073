import React, { useEffect, useState } from 'react'
import Reviews from './Reviews/Reviews'
import moment from 'moment'
import axios from 'axios'


const RightSide = (props) => {
    const [data, setData] = useState([])
    const getUnreadNotifications = () => {
        let id = JSON.parse(localStorage.getItem("id"))
        axios.get(process.env.REACT_APP_SERVER_PATH + `/notifications_history/` + id + `?limit=20`)
            .then((response) => {
                setData(response.data)
                console.log(response.data, "Response of notifications top")
            }).catch(() => {
                console.log("Error in getting Unread Notifications")
            })
    }
    useEffect(() => {
        getUnreadNotifications()
    }, [])
    return (
      <div className="rounded">
        <div>
          <Reviews
            user={props.user}
            setNavState={props.setNavState}
            navState={props.navState}
            isLoading={props.isLoading}
          />
        </div>
        <div className="bg-white mt-6 rounded p-1 md:flex flex-col hidden ">
          <div>
            <p className="px-4 pt-6 font-bold text-xl text-black">
              Notifications
            </p>
          </div>
          <div
            className={` overflow-y-auto h-32 scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 ${
              data.length > 5 ? "h-96" : "h-auto"
            }`}
          >
            {data.map((notification, index) => {
              return (
                <div
                  key={index}
                  className="text-textFullDark w-full  hover:bg-bgGray items-center flex border-b-2 border-borderGray py-2 px-1 "
                  // onClick={() => {
                  //     navigate(`/dashboard/bookings/${notification.resource_id}`)
                  // }}
                >
                  <div className="ml-4 flex flex-col">
                    <p className="text-sm font-bold">
                      {notification.notif_string}
                    </p>
                    <p className="text-xs">
                      {moment(notification.createdAt).format(
                        "dddd, MMM DD, YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div>
                <Activitys />
            </div> */}
      </div>
    );
}

export default RightSide
