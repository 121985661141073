import React, { useEffect, Component, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import Done from "./Done/Done";
import Loader from "react-loader-spinner";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import Select from "react-select";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import "./MainContent.css";
import moment from "moment";
import "./Payments/Payment.css";
import Colors from "../../../Colors/Colors";
import Voucher from "./Voucher/Voucher";
import { pricetoDecimal } from "../../../price";
import { Mixpanel } from "../../../Mixpanel";
import { CompanyTitle } from "../../../settings";
import { useDispatch, useSelector } from "react-redux";
import { toggleVoucher } from "../../../Redux/reducers/voucher/voucherSlice";
import { useLocation } from 'react-router-dom'

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const MainContent = (props) => {

  const [coupName, setCoupName] = useState("");
  const [sessionPriceFinal, setSessionPriceFinal] = useState(
    props.location.price
  );
  const [customerWallet, setCustomerWallet] = useState(0);
  const [use_partial_wallet, set_use_partial_wallet] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [textBody, setTextBody] = useState("");
  const [date, setDate] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [getLabels] = useState([
    "Topic information",
    "Date and time",
    "Summary",
    "Payment",
  ]);
  const activeStep4 = () => {
    setActiveStep(4);
  };
  const nextStep = () => {
    if (activeStep === 1) {
      Mixpanel.track(`web: Enter Meeting Time and Date.`, {
        Booking_date: date,
        "time Slot": timeSlot,
      });
    } else if (activeStep === 2) {
      Mixpanel.track(`web:Booking Final Summary.`, {
        Booking_date: date,
        "time Slot": timeSlot,
        Booking_topic: selectedOption,
        description: textBody,
        coup_Name: coupName,
      });
    }

    setActiveStep(activeStep + 1);
  };

  const backStep = () => {
    setActiveStep(activeStep - 1);
  };

  const getSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <SetDetails
            nextStep={nextStep}
            backStep={backStep}
            activeStep={activeStep}
            location={props.location}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            date={date}
            setDate={setDate}
            textBody={textBody}
            setTextBody={setTextBody}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            topicBooking={props.topicBooking}
            setTopicBooking={props.setTopicBooking}
            dateBooking={props.dateBooking}
            setDateBooking={props.setDateBooking}
            description={props.description}
            setDescription={props.setDescription}
          />
        );
      case 1:
        return (
          <DateTime
            nextStep={nextStep}
            backStep={backStep}
            location={props.location}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            date={date}
            setDate={setDate}
            textBody={textBody}
            setTextBody={setTextBody}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            dateBooking={props.dateBooking}
            setDateBooking={props.setDateBooking}
            timeSlotBooking={props.timeSlotBooking}
            setTimeSlotBooking={props.setTimeSlotBooking}
          />
        );
      case 2:
        return (
          <DiscountCode
            nextStep={nextStep}
            backStep={backStep}
            activeStep4={activeStep4}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            date={date}
            setDate={setDate}
            textBody={textBody}
            setTextBody={setTextBody}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            sessionPrice={props.location.price}
            Handler1={props.Handler}
            sessionDuration={props.location.minutes}
            dateBooking={props.dateBooking}
            setDateBooking={props.setDateBooking}
            timeSlotBooking={props.timeSlotBooking}
            setTimeSlotBooking={props.setTimeSlotBooking}
            topicBooking={props.topicBooking}
            propslocationprice={props.propslocationprice}
            location={props.location}
            id={props.id}
            sessionPriceFinal={sessionPriceFinal}
            setSessionPriceFinal={setSessionPriceFinal}
            coupName={coupName}
            setCoupName={setCoupName}
            setCustomerWallet={setCustomerWallet}
            set_use_partial_wallet={set_use_partial_wallet}
          />
        );
      case 3:
        return (
          <Payments
            nextStep={nextStep}
            backStep={backStep}
            activeStep4={activeStep4}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            date={date}
            setDate={setDate}
            textBody={textBody}
            setTextBody={setTextBody}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            sessionPrice={props.location.price}
            Handler1={props.Handler}
            sessionDuration={props.location.minutes}
            dateBooking={props.dateBooking}
            setDateBooking={props.setDateBooking}
            timeSlotBooking={props.timeSlotBooking}
            setTimeSlotBooking={props.setTimeSlotBooking}
            topicBooking={props.topicBooking}
            propslocationprice={props.propslocationprice}
            location={props.location}
            id={props.id}
            sessionPriceFinal={sessionPriceFinal}
            coupName={coupName}
            customerWallet={customerWallet}
            use_partial_wallet={use_partial_wallet}
          />
        );

      default:
        return null;
    }
  };
  return (
    <div className="sm:w-full md:w-2/3 bg-white md:ml-6 rounded sm:mt-4 md:mt-0 sm:p-2 md:p-6 h-full">
      <div className="hidden md:flex">
        <Stepper
          activeStep={activeStep}
          className="w-full btn_font_family"
          // style={{ fontFamily: "Lato sans-serif" }}
        >
          {getLabels.map((l) => {
            return (
              <Step
                // style={{ fontFamily: "Lato sans-serif" }}
                className="btn_font_family"
              >
                <StepLabel>{l}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      {activeStep === 4 ? (
        <Done
          textBody={textBody}
          timeSlot={timeSlot}
          date={date}
          selectedOption={selectedOption}
          dateBooking={props.dateBooking}
          sessionDuration={props.location.minutes}
          id={props.location.id}
          sessionPriceFinal={sessionPriceFinal}
        />
      ) : (
        <>{getSteps(activeStep)}</>
      )}
    </div>
  );
};

export default MainContent;

class SetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      options: [],
      remainingWords: 280,
      textColorTextArea: false,
    };
  }
  handleValue = (e) => {
    this.setState({
      remainingWords: 280 - e.target.value.length,
    });
    if (this.state.remainingWords < 12) {
      this.setState({ textColorTextArea: true });
    }
    this.props.setTextBody(e.target.value);
    this.props.setDescription(e.target.value);
  };
  handleChange = (selectedOption) => {
    this.props.setSelectedOption(selectedOption);
    this.props.setTopicBooking(selectedOption);
    this.setState({ selectedOption });
    const email = localStorage.getItem("email")
    axios.post(`${process.env.REACT_APP_SERVER_PATH}/create-abandon-event`, {
      type: 'booking',
      email: JSON.parse(email)

    })
      .catch((err) => {
        console.log("err in create-abandon-event", err);
      });
  };

  componentDidMount() {
    this.setState({
      selectedOption: this.props.selectedOption,
      textBody: this.state.textBody,
    });
    let token = localStorage.getItem("cp_web_token");
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${this.props.location.id}/topics_of_discussion`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        let options = [];
        response.data.message.forEach((i) => {
          options.push({
            value: i.id,
            label: i.topic,
          });
        });
        this.setState({
          options: options,
        });
      });
  }
  nextStep = () => {
    if (this.state.selectedOption !== null && this.props.textBody) {
      Mixpanel.track(`web: Enter Topic Detail.`, {
        Booking_topic: this.props.selectedOption,
        description: this.props.textBody,
      });

      this.props.nextStep();
    }
  };

  render() {
    return (
      <div className="mt-5 text-textFullDark">
        <h1 className="sm:text-lg md:text-2xl font-bold">Enter details</h1>
        <p className="text-sm mt-5 mb-2">Select a topic of conversation</p>
        <Select
          isSearchable={false}
          value={this.props.selectedOption}
          onChange={this.handleChange}
          options={this.state.options}
          placeholder="Please select topic"
        />
        <p className="text-sm mt-5 mb-2">
          What would you like to discuss in your meeting?
        </p>
        <textarea
          className="resize-none border-borderGray rounded-md text-sm border w-full h-36 py-3 px-3   focus:outline-none"
          id="bio"
          type="text"
          placeholder="Write here"
          value={this.props.textBody}
          maxLength={280}
          onChange={(e) => this.handleValue(e)}
        ></textarea>
        <p
          style={{ color: this.state.textColorTextArea ? "red" : "grey" }}
          className="text-xs mt-5 mb-2"
        >
          {this.state.remainingWords} words remianing
        </p>
        <div className="flex justify-between w-full mt-5">
          <button
            className={`text-white ${this.props.activeStep === 0 ? "bg-borderGray" : "bg-bgAppColor"} px-3 py-2 rounded`}
            disabled={this.props.activeStep === 0}
            onClick={this.props.backStep}
          >
            Back
          </button>
          <button
            className={`text-white bg-bgAppColor px-3 py-2 ml-4 rounded`}
            onClick={this.nextStep}
          >
            {this.props.activeStep === 2 ? "Finish" : "Next"}
          </button>
        </div>
      </div>
    );
  }
}

// export default SetDetails

const DateTime = (props) => {
  const [value, setValue] = useState(new Date());
  //  console error  
  const [timeSlot, setTimeSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [slotcolor, setSlotcolor] = useState(null);
  const [dictionary, setDictionary] = useState({});
  const [availableDates, setAvailableDates] = useState([]);

  const makeDic = async (available_slots, month, e, isTile) => {
    let dictionary = {};
    let value = isTile ? e : undefined;
    let todayDate = moment(new Date(), 'DD-MM-YYYY');
    let pastDate = moment(e, 'DD-MM-YYYY');
    for (const time of available_slots) {
      const dateTime = moment(time * 1000);
      if (dateTime.month() === month - 1) {
        const time = dateTime.format("LT");
        const date = dateTime.format("DD-MM-YYYY");
        if (dictionary[date]) dictionary[date].push(time);
        else dictionary[date] = [time];
      }
    }
    setDictionary(dictionary);
    setAvailableDates(Object.keys(dictionary));
    if (todayDate.isSame(pastDate)) {
      for (let i in dictionary) {
        if (value !== undefined) {
          if (
            i ===
            `${moment(value).format("DD").toString()}-${moment(value)
              .format("MM")
              .toString()}-${value.getFullYear().toString()}`
          ) {
            setSlots(dictionary[i]);
          }
        }
      }
    } else if (todayDate.isBefore(pastDate)) {
      for (let i in dictionary) {
        if (value !== undefined) {
          if (
            i ===
            `${moment(value).format("DD").toString()}-${moment(value)
              .format("MM")
              .toString()}-${value.getFullYear().toString()}`
          ) {
            setSlots(dictionary[i]);
          }
        }
      }
    } else {

      setValue(new Date());
      setSlots([]);
      props.setTimeSlotBooking('');
      setSlotcolor(null);
      setDictionary({})
    }

  };

  const getSlots = (month, year, e, isTile) => {
    let token = localStorage.getItem("cp_web_token");
    let duration = props.location.minutes;

    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${props.location.id}/available_slots?month=${month}&year=${year}&slot_duration=${duration}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.message &&
          result.data.message.availableSlots
        ) {
          makeDic(result.data.message.availableSlots, month, e, isTile);
        }

        //  setSlots(result.data.message.availableSlots)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    setValue(props.date);
    setTimeSlot(props.timeSlot);
    setDate(value);
  }, []);

  const handleTimeSlot = (m) => {
    setTimeSlot(m);
    props.setTimeSlot(m);
    props.setTimeSlotBooking(m);
    setSlotcolor(m);
    // props.nextStep()
  };
  const setDate = (e, isTile = true) => {
    setValue(e);
    setSlots([]);
    props.setTimeSlotBooking('');
    setSlotcolor(null);
    setDictionary({})
    props.setDateBooking(e);
    let month = e.getMonth() + 1;
    let year = e.getFullYear();
    getSlots(month, year, e, isTile);
  };

  return (
    <div className="my-5">
      <div className="flex sm:flex-col md:flex-row md:justify-between">
        <div className="flex flex-col justify-center items-center h-96 px-2">
          <h1 className="sm:text-lg md:text-2xl font-bold mt-7">Select a date</h1>
          <div className="calender">
            <Calendar
              onChange={setDate}
              minDate={new Date()}
              view={'month'}
              value={value}
              onClickDay={({ action }) => {
                console.log("action");
              }}
              showNavigation={true}
              tileClassName={({ date, view, activeStartDate }) => {
                if (
                  availableDates.find(
                    (x) => x === moment(date, view).format("DD-MM-YYYY")
                  )
                ) {
                  return "text-textApp font-bold focus:text-white w-12 h-12 rounded-full";
                } else {
                  return "text-textLight font-bold bg-white w-12 h-12 rounded-full pointer-events-none";
                }
              }}
              onActiveStartDateChange={({
                action,
                activeStartDate,
                value,
                view,
              }) => {
                setDate(activeStartDate, false)
              }}
              selectRange={false}
              showNeighboringMonth={false}
              next2Label={null}
              prev2Label={null}
              className="border-2 border-borderApp rounded-lg p-2"
            />
          </div>
        </div>
        <div className="md:ml-4 flex flex-col items-center md:h-96 overflow-auto w-full">
          <h1 className="sm:text-lg md:text-2xl font-bold mt-2 sm:mt-8">Select a time</h1>
          {slots.length < 0 === null
            ? null
            : slots.map((m) => {
              return (
                <p
                  key={m}
                  className="cursor-pointer p-3 border-2 text-center bg-white border-borderApp font-bold rounded-full  mb-2 w-full"
                  style={{
                    backgroundColor: slotcolor === m ? Colors.blue : null,
                    color: slotcolor === m ? "white" : "black",
                  }}
                  onClick={() => handleTimeSlot(m)}
                >
                  {m}
                </p>
              );
            })}
        </div>
      </div>
      <div className="flex justify-between w-full md:mt-10">
        <button
          className="text-white px-3 py-2 bg-bgAppColor rounded"
          disabled={props?.activeStep === 0}
          onClick={props?.backStep}
        >
          Back
        </button>
        <button
          className="text-white px-3 py-2 bg-bgAppColor ml-4 rounded"
          onClick={props?.timeSlotBooking !== "" ? props?.nextStep : null}
        >
          {props?.activeStep === 2 ? "Finish" : "Next"}
        </button>
      </div>
      {/* <button className="w-full bg-blue-500 text-white mt-4 rounded py-2 flex justify-center items-center" onClick={props.nextStep}>Next</button> */}
    </div>
  );
};

//   export default DateTime

const DiscountCode = (props) => {
  let dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [useWallet, setUseWallet] = useState(false);
  const [useCard, setUseCard] = useState(true);
  const [usePartial, setUsePartial] = useState(false);
  const [name, setName] = useState("");
  let voucher = useSelector((state) => state.voucher.isVoucher);
  const [coupenName, setCoupenName] = useState("");
  const [haveacoup, setHaveaCoup] = useState(true);
  const [coupremovehere, setCoupremovehere] = useState(false);
  const [aftercal, setAftercal] = useState(props.propslocationprice + Number(process.env.REACT_APP_BOOKING_FEE));
  const [coupenCaltoggler, setCoupenCaltoggler] = useState(false);
  const [subtotal, setSubtotal] = useState(props.propslocationprice);
  const [discount, setDiscount] = useState(0);
  const [discounttype, setDiscounttype] = useState("");
  const [discount_amount, setDiscountamount] = useState("");
  const [walletMoney, setWalletMoney] = useState(0);
  const [userId, setUserId] = useState(localStorage.getItem("id"));
  const [isUserEligibleForFreeCall, setIsUserEligibleForFreeCall] = useState(false)
  const [referalInvit, setReferalInvit] = useState({
    status: true,
    message: "",
  });
  const [flag, setFlag] = useState(0);

  const AfterCalHandler = (
    total,
    Discount,
    subtotal,
    Discounttype,
    Discamount
  ) => {
    // setPropslocationprice(x);
    setAftercal(Number(total) + Number(process.env.REACT_APP_BOOKING_FEE));
    setCoupenCaltoggler(true);
    setHaveaCoup(false);
    setCoupremovehere(true);
    setDiscount(Discount);
    setSubtotal(subtotal);
    setDiscounttype(Discounttype);
    setDiscountamount(Discamount);
    props.setSessionPriceFinal(total);
  };

  const removeHandler = () => {
    setCoupenCaltoggler(false);
    setHaveaCoup(true);
    setCoupremovehere(false);
    setDiscount(0);
    setAftercal(props.propslocationprice + Number(process.env.REACT_APP_BOOKING_FEE));
    props.setSessionPriceFinal(props.propslocationprice);
    setDiscounttype("");
  };

  const referalCal = () => {
    let z = aftercal;
    let x = aftercal / 100;
    let y = x * 20;
    let c = aftercal - y;
    c = parseFloat(c).toFixed(2);
    setAftercal(c + Number(process.env.REACT_APP_BOOKING_FEE));
    props.setSessionPriceFinal(c);
  };

  const toggleVoucher1 = () => {
    dispatch(toggleVoucher());
  };
  if (voucher) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const coupenNameHandler = (x) => {
    setCoupenName(x);
    props.setCoupName(x);
  };

  const combineDateAndTime = function (date, time12h) {
    if (time12h == null || time12h == undefined || time12h == '') return;
    const [time, modifier] = time12h?.split(" ");

    let [hours, minutes] = time?.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    let finalDate = new Date(date);

    let timeString = hours + ":" + minutes + ":00";

    let year = finalDate.getFullYear();
    let month = finalDate.getMonth() + 1; // Jan is 0, dec is 11
    let day = finalDate.getDate();
    let dateString = "" + year + "/" + month + "/" + day;
    var timeAndDate = moment(dateString + " " + timeString).toDate();

    var timeAndDate = moment(dateString + " " + timeString);
    return timeAndDate.unix();
  };

  const bookingDetailFree = {
    topic_id: props.topicBooking.value,
    notes: props.textBody,
    session_duration: props.sessionDuration,
    booking_time: combineDateAndTime(props.dateBooking, props.timeSlotBooking),
    coupon_code: coupenName,
    is_free_call: true,
  };
  const bookingDetail = {
    topic_id: props.topicBooking.value,
    notes: props.textBody,
    session_duration: props.sessionDuration,
    booking_time: combineDateAndTime(props.dateBooking, props.timeSlotBooking),
    use_wallet: true,
    use_partial_wallet: props.use_partial_wallet,
    coupon_code: coupenName,
    freeIndustryFirstCall: isUserEligibleForFreeCall ? true : false
  };



  const withWallet = () => {

    setLoader(true);
    setButtonDisabled(true);
    if (aftercal === 0.0 || aftercal === "0.00") {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_PATH}/professionals/${props.id}/request_call`,
          bookingDetailFree,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setLoader(false);
          setButtonDisabled(false);
          props.activeStep4();
        })
        .catch((e) => {
          setLoader(false);
          setButtonDisabled(false);
          alert(`Error in requesting call => ${e.response.statusText}`);
          console.log("Error in booking", e.response);
        });
    } else {
      if (useWallet === true || isUserEligibleForFreeCall) {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_PATH}/professionals/${props.id}/request_call`,
            bookingDetail,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
              },
            }
          )
          .then((response) => {
            setLoader(false);
            setButtonDisabled(false);
            props.activeStep4();
          })
          .catch((e) => {
            setLoader(false);
            setButtonDisabled(false);
            console.log("Error in booking", e);
            alert("Error in meeting request is =>", e.response.statusText);
          });
      } else {
        setLoader(false);
        setButtonDisabled(false);
        props.nextStep();
      }
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/isUserMakeFirstBooking/${userId}`
      )
      .then((response) => {
        setReferalInvit({
          status: response.data.status,
          message: response.data.message,
        });
        setFlag(1);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, []);

  useEffect(() => {
    let id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/users/${id}`)
      .then((response) => {
        setWalletMoney(response.data.wallet);
        props.setCustomerWallet(response.data.wallet);
      })
      .catch((e) => {
        console.log("Response of user");
      });
  }, []);

  if (flag === 1) {
    if (referalInvit.status === false && subtotal >= 20) {
      referalCal();
      setFlag(0);
    }
  }
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${props.location.id}`
      )
      .then((response) => {
        setName(
          `${response.data.User?.first_name} ${response.data.User?.last_name}`
        );
      })
      .catch((e) => {
        console.log("Error in Professional Api in Payment");
      });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("cp_web_token");
    console.log(props.location.id);
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/freecall-indusrty-validation/${props.location.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setIsUserEligibleForFreeCall(response.data.isUserEligible)
        console.log(isUserEligibleForFreeCall, "free call?")
      })
      .catch((e) => {
        console.log("error in freecall-indusrty-validation", e, props.location.id);
      });
  }, []);
  const location = useLocation();
  return (
    <div className="text-textFullDark ">
      {voucher && (
        <Voucher
          prices={props.prices}
          Handler3={props.Handler2}
          toggle={toggleVoucher1}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={voucher}
          coupenName={coupenNameHandler}
          professionalID={props.location.id}
          propslocationprice={props.propslocationprice}
          AfterCalHandler={AfterCalHandler}
        />
      )}
      <div>
        <p className="appearance-none border-b-2 rounded w-full mt-4 py-3   leading-tight focus:outline-none ">
          {props.location.minutes} mins video call
        </p>

        <div>
          <div
            className="w-full mt-3 py-1 px-1"
            style={{
              color: "grey",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Table>
              <TableCell align="left" >Subtotal</TableCell>
              <TableCell align="right">{isUserEligibleForFreeCall ? "Free" : pricetoDecimal(subtotal)}</TableCell>
            </Table>
          </div>

          {coupenCaltoggler && (
            <div
              className="w-full  py-1 px-1"
              style={{
                color: "grey",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Table>
                <TableCell align="left" >
                  {coupremovehere && (
                    <p className="flex items-center ">
                      Discount code <p className="font-bold mx-2">{coupenName}</p>
                      <button className="text-red-500" onClick={removeHandler}>
                        Remove
                      </button>
                    </p>
                  )}
                </TableCell>
                <TableCell style={{ color: "green" }} align="right">
                  -{pricetoDecimal(discount)}{" "}
                  {discounttype === "percentage"
                    ? String(discount_amount) + "%"
                    : ""}
                </TableCell>
              </Table>

            </div>
          )}

          {!referalInvit.status && subtotal >= 20 ? (
            <div
              className="w-full mt-3 py-1 px-1"
              style={{
                color: "grey",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >

              <Table>
                <TableCell align="left" >  Your Referral Discount </TableCell>
                <TableCell style={{ color: "green" }} align="right">-20% </TableCell>
              </Table>
            </div>
          ) : null}

          <div
            className="w-full mt-3 py-1 px-1"
            style={{
              color: "grey",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Table>
              <TableCell align="left" >Booking fee</TableCell>
              <TableCell align="right">{!isUserEligibleForFreeCall ? pricetoDecimal(process.env.REACT_APP_BOOKING_FEE) : pricetoDecimal(0)}</TableCell>
            </Table>
          </div>

          <div
            className="w-full font-bold pt-3 py-1 px-1 mt-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Table>
              <TableCell style={{ fontWeight: "bold" }} align="left" > Total </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">{!isUserEligibleForFreeCall ? pricetoDecimal(aftercal) : pricetoDecimal(0)}</TableCell>
            </Table>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-bold text-textFullDark mt-5">
            Payment method
          </h3>
          <div className="w-full flex sm:flex-col md:flex-row mt-4">
            {walletMoney >= aftercal ? (
              <div
                className={`w-full md:w-1/2 p-2 rounded border-2 ${useWallet ? "border-borderApp" : "border-borderGray"
                  } flex justify-between items-center cursor-pointer`}
                onClick={() => {
                  setUseWallet(true);
                  setUseCard(false);
                }}
              >
                <div>
                  <img
                    src="/careerpathlogo.png"
                    alt="careerpathlogo"
                    className="w-6"
                  />
                  <p className=" text-sm font-bold mt-2">
                    {CompanyTitle} wallet
                  </p>
                </div>
                <div>
                  <p className="text-lg font-bold">{walletMoney.toFixed(2)}</p>
                  <p className="text-sm">Balance</p>
                </div>
              </div>
            ) : walletMoney > 0 && walletMoney < aftercal ? (
              <div
                className={`sm:my-2 md:my-0 w-full md:ml-2 md:w-1/2 p-2 rounded border-2 ${usePartial ? "border-borderApp" : "border-borderGray"
                  }  flex justify-between items-center cursor-pointer`}
                onClick={() => {
                  setUseWallet(false);
                  setUseCard(false);
                  setUsePartial(true);
                  props.set_use_partial_wallet(true);
                }}
              >
                <div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="gray"
                    >
                      <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                      <path
                        fillRule="evenodd"
                        d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <p className=" text-sm font-bold mt-2">Use partial balance</p>
                </div>
                <div>
                  <p className="text-lg font-bold">{walletMoney}</p>
                  <p className="text-sm">Balance</p>
                </div>
              </div>
            ) : null}

            <div
              className={`sm:my-2 md:my-0 w-full md:ml-2 md:w-1/2 p-2 rounded border-2 ${useCard ? "border-borderApp" : "border-borderGray"
                }  flex justify-between items-center cursor-pointer`}
              onClick={() => {
                setUseWallet(false);
                setUseCard(true);
                setUsePartial(false);
                props.set_use_partial_wallet(false);
              }}
            >
              <div>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="gray"
                  >
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                    <path
                      fillRule="evenodd"
                      d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <p className=" text-sm font-bold mt-2">Use card</p>
              </div>
            </div>

          </div>
        </div>

        {!isUserEligibleForFreeCall ? (
          (!referalInvit.status && subtotal < 20) ||
          (referalInvit.status && subtotal >= 20) ||
          (referalInvit.status && subtotal < 20)
          ? haveacoup && (
            <p
              className="cursor-pointer"
              style={{ color: Colors.blue, marginTop: "2%" }}
              onClick={toggleVoucher1}
            >
              Do you have a discount code?
            </p>
          )
          : null
        ) : (
         <div></div>
        )}

        <div className="flex justify-between w-full md:mt-20">
          <button
            className="text-white px-3 py-2 bg-bgAppColor rounded"
            style={{ backgroundColor: Colors.blue }}
            onClick={() => {
              props.backStep();
            }}
          >
            Back
          </button>
          <button
            className={`text-white px-3 py-2 ${buttonDisabled ? "bg-bgDark" : "bg-bgAppColor"
              } ml-4 rounded`}
            style={{ backgroundColor: Colors.blue }}
            onClick={withWallet}
            disabled={buttonDisabled}
          >
            {loader ? (
              <Loader
                height={20}
                width={20}
                color="white"
                type="Oval"
                timeout={1000}
              />
            ) : (
              "Next"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const Payments = (props) => {
  const [modal, setModal] = useState(false);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);
  const [card, setCard] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState("");
  const combineDateAndTime = function (date, time12h) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    let finalDate = new Date(date);

    let timeString = hours + ":" + minutes + ":00";

    let year = finalDate.getFullYear();
    let month = finalDate.getMonth() + 1; // Jan is 0, dec is 11
    let day = finalDate.getDate();
    let dateString = "" + year + "/" + month + "/" + day;
    var timeAndDate = moment(dateString + " " + timeString).toDate();

    var timeAndDate = moment(dateString + " " + timeString);
    return timeAndDate.unix();
  };

  const bookingDetail = {
    topic_id: props.topicBooking.value,
    notes: props.textBody,
    session_duration: props.sessionDuration,
    booking_time: combineDateAndTime(props.dateBooking, props.timeSlotBooking),
    use_wallet: false,
    use_partial_wallet: props.use_partial_wallet,
    coupon_code: props.coupName,
    payment_intent: paymentIntent,
  };

  useEffect(() => {
    let token = localStorage.getItem("cp_web_token");
    let userEmail = localStorage.getItem("email");

    token = token;
    userEmail = JSON.parse(userEmail);
    let data = {
      email: userEmail,
      amount: props.use_partial_wallet
        ? props.sessionPriceFinal - props.customerWallet
        : props.sessionPriceFinal,
    };

    data.amount = (Number(data.amount) + Number(process.env.REACT_APP_BOOKING_FEE)).toString();
    axios
      .post(`${process.env.REACT_APP_SERVER_PATH}/stripe-payment-sheet`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        const { paymentIntent } = result.data;
        Mixpanel.track(`web: paymentIntent.`, {
          User_Detail: data,
          paymentIntent: paymentIntent,
        });

        setPaymentIntent(paymentIntent);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: paymentIntent,
    appearance,
  };


  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full mt-6">
          <Loader
            type="TailSpin"
            color={Colors.blue}
            height={200}
            width={200}
            timeout={5000} //5 secs
          />
        </div>
      ) : (
        <div style={{ marginTop: 30 }}>
          {paymentIntent && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                backStep={props.backStep}
                bookingData={bookingDetail}
                activeStep4={props.activeStep4}
                Handler2={props.Handler1}
                propslocationprice={props.propslocationprice}
                location={props.location}
                id={props.id}
              />
            </Elements>
          )}
        </div>
      )}
    </div>
  );
};

// export default Payments
