import React, { useEffect, useRef, useState } from 'react'
import Colors from '../../Colors/Colors'
import axios from 'axios'

const ProfileIndustries = () => {
    const [industry, setIndustry] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState([])
    const [selectedSubInterests, setSelectedInterests] = useState([]);

    const getUser = () => {
        axios.get(process.env.REACT_APP_SERVER_PATH + `/users/${+localStorage.getItem('id')}`)
            .then((response) => {
                if (response.data.type === 'professional') {
                    axios.get(process.env.REACT_APP_SERVER_PATH + `/professionals/${+localStorage.getItem('id')}`).then(response => {
                        getIndustrySelected(response.data.Industries)
                    }).catch(e => {
                        console.log('Error in Professional Api in Professional Api')
                    })
                } else if (response.data.type === 'customer') {
                    axios.get(process.env.REACT_APP_SERVER_PATH + `/customers/${+localStorage.getItem('id')}`).then((response) => {
                        getIndustrySelected(response.data.Industries)
                    }).catch(e => {
                        console.log('Error in getting custoners industries')
                    })
                }
            }).catch(e => {
                console.log("error occured");
            })
    }
    const getAllIndustries = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/industry`).then((response) => {
            setIndustry(response.data)
        }).catch(e => {
            console.log("error in industry api");
        })
    }
    const getIndustrySelected = (dataArray) => {
        dataArray.forEach(m => {
            if (selectedIndustry.includes(m.id)) {
                // Remove ID
                const selectedIndustryCopy = [...selectedIndustry]
                const itemIndex = selectedIndustryCopy.indexOf(m.id)
                selectedIndustryCopy.splice(itemIndex, 1)
                setSelectedIndustry(selectedIndustryCopy)
            } else {
                // ADD ID
                setSelectedIndustry(selectedIndustry => [...selectedIndustry, m.id])
            }
        })
    }

    useEffect(() => {
        getUser()
        getAllIndustries()
    }, [])
    return (
        <div className="w-full bg-bgIndustryColor rounded-lg flex flex-wrap">
            {(selectedSubInterests.length > 0 ? [...industry, ...selectedSubInterests] : industry).map((m) => (
                <div key={m.id}>
                    {selectedIndustry.includes(m.id) && (
                        <button
                            className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                            style={{
                                backgroundColor: Colors.bgBlue,
                                color: 'white',
                                borderColor: Colors.bgBlue,
                            }}
                        >
                            {m.name}
                        </button>
                    )}
                </div>
            ))}
        </div>
    )
}

export default ProfileIndustries