import React, { useEffect, useState } from "react";
import AddEducation from "./AddEducation";
import AddInformation from "./AddInformation";
import AddLocation from "./AddLocation";
import AddProfilePic from "./AddProfilePic";
import CareerInsights from "./CareerInsights";
import EducationForm from "./EducationForm";
import SelectIndustry from "./SelectIndustry";
import EducationHistory from "./EducationHistory";
import AddWorkExperience from "./AddWorkExperience";
import WorkExperienceForm from "./WorkExperienceForm";
import WorkExperienceHistory from "./WorkExperienceHistory";
import AccountCreated from "./AccountCreated";
import SelectInterest from "./SelectInterest";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";

const SignUpProcessOfCustomer = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const [navState, setNavState] = useState(0);
  useEffect(() => {
    if (localStorage.getItem("customer_signup_token")) {
      console.log(
        "🚀 ~ file: SignUpProcessOfCustomer.js:28 ~ useEffect ~ localStorage.getItem('customer_signup_token'):",
        localStorage.getItem("customer_signup_token")
      );
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/users/${
            jwt_decode(localStorage.getItem("customer_signup_token")).id
          }`
        )
        .then((response) => {
          console.log(response.data.profile_step, "Process Signup customer");
          console.log(
            typeof +response.data.profile_step,
            "Process Signup customer"
          );
          if (response.data.isProfile_complete === 1) {
            setNavState(1);
            return;
          }
          if (response.data.profile_step === "6") {
            setNavState(8);
          } else if (response.data.profile_step === "7") {
            setNavState(11);
          } else {
            setNavState(+response.data.profile_step);
          }
        })
        .catch((e) => {
          console.log("Error in fetching user in customer Sign up flow");
        });
    } else {
      setNavState(0);
    }
  }, []);
  let ComponentToRender = null;
  switch (navState) {
    // case 0:
    //     ComponentToRender = <Welcome setNavState={setNavState} navState={navState} />
    //     break
    case 0:
      ComponentToRender = (
        <AddInformation
          setNavState={setNavState}
          navState={navState}
          customerSignUpFlow={props.customerSignUpFlow}
          setCustomerSignUpFlow={props.setCustomerSignUpFlow}
          isFreeCredit={props.isFreeCredit}
        />
      );
      break;
    case 1:
      ComponentToRender = (
        <SelectInterest setNavState={setNavState} navState={navState} />
      );
      break;
    case 2:
      ComponentToRender = (
        <SelectIndustry setNavState={setNavState} navState={navState} />
      );
      break;
    case 3:
      ComponentToRender = (
        <CareerInsights setNavState={setNavState} navState={navState} />
      );
      break;
    case 4:
      ComponentToRender = (
        <AddLocation setNavState={setNavState} navState={navState} />
      );
      break;
    case 5:
      ComponentToRender = (
        <AddProfilePic setNavState={setNavState} navState={navState} />
      );
      break;
    case 6:
      ComponentToRender = (
        <AddEducation setNavState={setNavState} navState={navState} />
      );
      break;
    case 7:
      ComponentToRender = (
        <EducationForm setNavState={setNavState} navState={navState} />
      );
      break;
    case 8:
      ComponentToRender = (
        <EducationHistory setNavState={setNavState} navState={navState} />
      );
      break;
    case 9:
      ComponentToRender = (
        <AddWorkExperience setNavState={setNavState} navState={navState} />
      );
      break;
    case 10:
      ComponentToRender = (
        <WorkExperienceForm setNavState={setNavState} navState={navState} />
      );
      break;
    case 11:
      ComponentToRender = (
        <WorkExperienceHistory setNavState={setNavState} navState={navState} />
      );
      break;
    case 12:
      ComponentToRender = (
        <AccountCreated
          setNavState={setNavState}
          navState={navState}
          toggle={props.toggle}
        />
      );
      break;

    default:
      return;
  }
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className={props.overlay}
        onClick={() => {
          if (location.pathname === "/signup") {
            props.toggle();
            navigate("/");
          } else {
            // dispatch({ type: 'setBannerTrue' })
            dispatch(setBannerTrue());
            props.toggle();
          }
        }}
      ></div>
      <div className="flex flex-col justify-center items-center border border-borderGray sm:w-full md:w-1/3 overflow-auto rounded py-8 px-8 text-textFullDark bg-white m-auto z-30">
        <div className="relative w-full">
          <button
            className="absolute top-3 right-0"
            onClick={() => {
              // dispatch({ type: 'setBannerTrue' })
              dispatch(setBannerTrue());
              props.toggle();
            }}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {ComponentToRender}
      </div>
    </div>
  );
};

export default SignUpProcessOfCustomer;
