import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isTooltip: true,
}

export const Tooltip = createSlice({
    name: "tooltip",
    initialState,
    reducers: {
        setTooltipFalse: (state, action) => {
            state.isTooltip = false
        },
        setTooltipTrue: (state) => {
            state.isTooltip = true
        }
    },
    extraReducers: {
    }
})

export const { setTooltipTrue, setTooltipFalse } = Tooltip.actions;
export default Tooltip.reducer;