import React from 'react'
import { Mixpanel } from '../Mixpanel';

const AccountCreated = (props) => {
    window.localStorage.clear()
    Mixpanel.track(`web: Professional Account Completed Successfully.`);

    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <img src="/accountCreated.png" alt="workexperience" className="w-48" />
            <p className="text-2xl mt-3 font-bold">Success!</p>
            <div className='flex mt-2 w-full'>
                <p className='text-center text-textFullDark'>Your account has been successfully created. We have sent you a verification link, please verify your email.</p>
            </div>
        </div>
    )
}

export default AccountCreated
