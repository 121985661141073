import React from "react";
import  Skeleton  from "react-skeleton-loading";
function IndustrySkeleton_landingPage({ CardLength }) {
  return (
    <div className="bg-white sm:p-1 md:p-3 sm:h-60 sm:w-1/2 border border-borderGray box-border rounded-lg hover:shadow-lg flex mr-2 ">
      <div className="flex flex-col flex-1 gap-5 sm:p-2">
        <Skeleton height={32} width={"100%"} style={{ borderRadius: "1rem" }} />
        <div className="mt-auto flex flex-col gap-3">
          <Skeleton height={8} width={48} style={{ borderRadius: "1rem" }} />
          <Skeleton height={8} width={48} style={{ borderRadius: "1rem" }} />
        </div>
      </div>

      <div className="flex flex-col flex-1 gap-5 sm:p-2 mt-4">
        <div className="flex flex-1 flex-col gap-3">
          <Skeleton height={8} style={{ borderRadius: "1rem" }} />
          <Skeleton height={8} style={{ borderRadius: "1rem" }} />
          <Skeleton height={8} style={{ borderRadius: "1rem" }} />
          <Skeleton height={8} style={{ borderRadius: "1rem" }} />
          <Skeleton height={8} style={{ borderRadius: "1rem" }} />
          <Skeleton height={8} style={{ borderRadius: "1rem" }} />
        </div>
        <div className="mt-auto flex gap-3 justify-between">
          <Skeleton circle={true} height={20} width={20} />
          <Skeleton circle={true} height={20} width={20} />
        </div>
      </div>
    </div>
  );
}

export default IndustrySkeleton_landingPage;