import React from "react";
import moment from "moment";
export default function CourseInfo() {
  return (
    <div className="bg-bgGray px-4 py-7 sm:mt-4 md:mt-0 sm:w-full lg:w-2/5 rounded-lg h-full">
      
        <div className="flex items-center mb-6">
          <div>
            <div className="w-16 h-16 rounded-full bg-white border flex justify-center items-center">
              <img
                src={"/events/calender-icon.png"}
                alt=""
                className="w-8 h-8  object-cover "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 ">
            <h1 className="text-lg  text-textFullDark font-bold">
            Flexible Courses
            </h1>
            <p className="text-sm text-textFullDark">Book a course in accordance to your schedule</p>
            
          </div>
        </div>

        <div className="flex items-center mb-6">
          <div>
            <div className="w-16 h-16 rounded-full bg-white border flex justify-center items-center">
              <img
                src={"/events/certificate-icon.png"}
                alt=""
                className="w-8 h-8  object-cover "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 ">
            <h1 className="text-lg  text-textFullDark font-bold">
            Shareable Certificate
            </h1>
            <p className="text-sm text-textFullDark">Earn a certificate upon completion</p>
          </div>
        </div>

        <div className="flex items-center mb-6">
          <div>
            <div className="w-16 h-16 rounded-full bg-white border flex justify-center items-center">
              <img
                src={"/events/online-icon.png"}
                alt=""
                className="w-8 h-8  object-cover "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 ">
            <h1 className="text-lg  text-textFullDark font-bold">
            100% Online
            </h1>
            <p className="text-sm text-textFullDark">Start instantly and access course material online</p>
          </div>
        </div>
        
        <div className="flex items-center mb-6">
          <div>
            <div className="w-16 h-16 rounded-full bg-white border flex justify-center items-center">
              <img
                src={"/events/timer-icon.png"}
                alt=""
                className="w-8 h-8  object-cover "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 ">
            <h1 className="text-lg  text-textFullDark font-bold">
            Approx, 3 hours to complete
            </h1>
          </div>
        </div>
    
        <div className="flex items-center">
          <div>
            <div className="w-16 h-16 rounded-full bg-white border flex justify-center items-center">
              <img
                src={"/events/messages-icon.png"}
                alt=""
                className="w-8 h-8  object-cover "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 ">
            <h1 className="text-lg  text-textFullDark font-bold">
            English language
            </h1>
          </div>
        </div>
    </div>
  );
}
