import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // this variable remove reason click sign up button show search bar
  zIndex: "z-40",
  // zIndex: "z-41",
};

export const searchBar = createSlice({
  name: "searchBar",
  initialState,
  reducers: {
    zIndexRaise: (state) => {
      state.zIndex = "z-80";
    },
    zIndexNormal: (state) => {
      state.zIndex = "z-40";
    },
    zIndexend: (state) => {
      state.zIndex = "z-41";
    },
  },
});

export const { zIndexRaise, zIndexNormal, zIndexZero, zIndexend } = searchBar.actions;

export default searchBar.reducer;
