import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    showAnonymousInfo: false,
    toggleAnonymous :false
}

export const anonymousSlice = createSlice({
    name:"anonymous",
    initialState,
    reducers:{
        toggleAnonymousInfo :(state, action)=>{
         state.showAnonymousInfo = !state.showAnonymousInfo;
        },
        toggleAnonymous :(state)=>{
            state.toggleAnonymous = !state.toggleAnonymous;
        }
    },
    extraReducers:{
    }
})

export const { toggleAnonymousInfo, toggleAnonymous } = anonymousSlice.actions;
export default anonymousSlice.reducer;