import React  from 'react'
import PaymentsLower from './PaymentsLower/PaymentsLower'
// import PaymentsUpper from './PaymentsUpper/PaymentsUpper'
import { Route, Routes } from 'react-router-dom'
import TransactionDetails from './TransactionDetails/TransactionDetails'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../../../settings'

const Payments = ({ user }) => {
    return (
        <div>
            <Helmet>
                <title>Payments | Dashboard | {CompanyTitle}</title>
                <meta name={`Payments | Dashboard | ${CompanyTitle}`} content={`Payments | Dashboard | ${CompanyTitle}`} />
            </Helmet>
            <Routes>
                <Route path='/' element={<div>
                    {/* <PaymentsUpper user={user} /> */}
                    <PaymentsLower user={user} />
                </div>} />
                <Route path=':id' element={<TransactionDetails user={user} />} />
            </Routes>

        </div>
    )
}

export default Payments
