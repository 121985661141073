export const update_profile_step = (token, id, step) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let raw = {
        token: token,
        profile_step: step,
    };

    if (step == '9') {
        raw = { ...raw, isProfile_complete: 1 };
    }

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow',
    };
    return fetch(`${process.env.REACT_APP_SERVER_PATH}/update_profile_step/${id}`, requestOptions)
        .then(data => data.json())
        .then(text => {
            console.log(text, "successfully for customer");
            return text;
        })
        .catch(err => {
            console.log(err, "failed to update profile");
            return err;
        });
};