import React, { useEffect, useState } from "react";
import AddEducation from "./AddEducation";
import AddFullName from "./AddFullName";
import AddLocation from "./AddLocation";
import AddPrice from "./AddPrice";
import AddProfilePic from "./AddProfilePic";
import AddVideo from "./AddVideo";
import AddWeekHours from "./AddWeekHours";
import AddWorkExperience from "./AddWorkExperience";
import EducationForm from "./EducationForm";
import EducationHistory from "./EducationHistory";
import PriceAdded from "./PriceAdded";
import ProfessionalStatus from "./ProfessionalStatus";
import SelectIndustry from "./SelectIndustry";
import WorkExperienceForm from "./WorkExperienceForm";
import WorkExperienceHistory from "./WorkExperienceHistory";
import YearlySalary from "./YearlySalary";
import AccountCreated from "./AccountCreated";
import SelectInterest from "./SelectInterest";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";

const SignUpProcess = (props) => {
  let dispatch = useDispatch();
  const [navState, setNavState] = useState(0);
  // const [navState, setNavState] = useState(+localStorage.getItem("navState") !== undefined ? +localStorage.getItem("navState") : 0)
  const [ProfessionalId, setProfessionalId] = useState(null);
  const [token, setToken] = useState(null);
  console.log(ProfessionalId);

  useEffect(() => {
    if (localStorage.getItem("signup_token")) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/users/${jwt_decode(localStorage.getItem("signup_token")).id
          }`
        )
        .then((response) => {
          console.log(response.data.profile_step, "Process Signup customer");
          console.log(
            typeof response.data.isProfile_complete,
            "Process Signup customer"
          );
          console.log(
            typeof +response.data.profile_step,
            "Process Signup customer"
          );
          // if (response.data.isProfile_complete === 1) {
          //     console.log("I m in complete profile")
          //     setNavState(0)
          //     return
          // }
          if (response.data.profile_step === "7") {
            setNavState(10);
          } else if (response.data.profile_step === "8") {
            console.log("I m in 8");
            setNavState(12);
          } else if (response.data.profile_step === "9") {
            console.log("I m in 9");
            setNavState(15);
          } else if (response.data.profile_step === "11") {
            console.log("I m in 11");
            setNavState(16);
          } else {
            console.log("I m in every");
            setNavState(+response.data.profile_step);
          }
        })
        .catch((e) => {
          console.log("Error in fetching user in customer Sign up flow");
        });
    } else {
      setNavState(0);
    }
  }, []);
  let ComponentToRender = null;
  switch (navState) {
    // case 0:
    //     ComponentToRender = <Welcome setNavState={setNavState} navState={navState} />
    //     break
    case 0:
      ComponentToRender = (
        <AddFullName
          setNavState={setNavState}
          navState={navState}
          setProfessionalId={setProfessionalId}
          setToken={setToken}
          toggle={props.toggle}
        />
      );
      break;
    case 1:
      ComponentToRender = (
        <ProfessionalStatus
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 2:
      ComponentToRender = (
        <AddLocation
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 3:
      ComponentToRender = (
        <AddProfilePic
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 4:
      ComponentToRender = (
        <AddVideo
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 5:
      ComponentToRender = (
        <SelectInterest
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 6:
      ComponentToRender = (
        <SelectIndustry
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 7:
      ComponentToRender = (
        <AddWorkExperience setNavState={setNavState} navState={navState} />
      );
      break;
    case 8:
      ComponentToRender = (
        <WorkExperienceForm
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
          token={token}
        />
      );
      break;
    case 9:
      ComponentToRender = (
        <WorkExperienceHistory
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 10:
      ComponentToRender = (
        <AddEducation setNavState={setNavState} navState={navState} />
      );
      break;
    case 11:
      ComponentToRender = (
        <EducationForm
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 12:
      ComponentToRender = (
        <EducationHistory
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 13:
      ComponentToRender = (
        <AddPrice
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 14:
      ComponentToRender = (
        <YearlySalary
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 15:
      ComponentToRender = (
        <PriceAdded
          setNavState={setNavState}
          navState={navState}
          ProfessionalId={ProfessionalId}
        />
      );
      break;
    case 16:
      ComponentToRender = (
        <AddWeekHours setNavState={setNavState} navState={navState} />
      );
      break;
    case 17:
      ComponentToRender = (
        <AccountCreated setNavState={setNavState} navState={navState} />
      );
      break;
    default:
      return;
  }
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className={props.overlay}
        onClick={() => {
          // dispatch({ type: 'setBannerTrue' })
          dispatch(setBannerTrue());
          props.toggle();
        }}
      ></div>
      <div className="flex flex-col justify-center items-center border border-borderGray sm:w-full md:w-1/3 overflow-auto rounded py-8 px-8 text-textFullDark bg-white m-auto z-30">
        <div className="relative w-full z-40">
          <button
            className="absolute top-3 right-0"
            onClick={() => {
              // dispatch({ type: 'setBannerTrue' })
              dispatch(setBannerTrue());
              props.toggle();
            }}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {ComponentToRender}
      </div>
    </div>
  );
};

export default SignUpProcess;
