import React, { useEffect, useState, useLayoutEffect } from 'react'
import Loader from 'react-loader-spinner'
import Colors from '../../../../Colors/Colors'
import './ViewBookings.css'
import BookingPageMainContent from './BookingPageMainContent/BookingPageMainContent'
import { Route, useParams, Routes } from 'react-router-dom'
import BookingDetails from './BookingDetails/BookingDetails'
import RescheduleMeeting from './BookingDetails/rescheduleMeeting'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../../../../settings'
import { io } from "socket.io-client";

<Loader
    type="TailSpin"
    color={Colors.blue}
    height={200}
    width={200}
/>
const ViewBookings = (props) => {

    // const [socketState, setSocketState] = useState(null);
    // const { id } = useParams();
    // useEffect(() => {
    //     // "socket.io-client" - start
    //     const connectionOptions = {
    //         "force new connection": true,
    //         "reconnection": true,
    //         "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
    //         "reconnectionDelayMax": 60000,             //1 minute maximum delay between connections
    //         "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
    //         "timeout": 10000,
    //         "path": "/sockets",
    //         "auth": { "token": "KaPd$C&F)J@NcRfUjXn2r5u7x!A%D*G-KaPd", "bookingID": id, "userID": localStorage.getItem("id") },                          //before connect_error and connect_timeout are emitted.
    //         // "transports": ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
    //     };
    //     const socket = io(process.env.REACT_APP_SOCKET_SERVER_PATH, connectionOptions);

    //     socket.on("connect", () => {
    //         console.log("hello! socket client ", socket.id); // x8WIv7-mJelg7on_ALbx
    //         setSocketState(socket)
    //     });

    //     // socket.on("disconnect", () => {
    //     //     console.log("Bye! socket client ", socket.id); // undefined
    //     // });
    //     // return () => {
    //     //     if (socket) socket.disconnect();
    //     //     console.log("Bye! socket client2 ", socket.id);
    //     // }
    //     // "socket.io-client" - end
    // }, [])


    localStorage.setItem('navState', 0)
    return (
        <div>
            <Helmet>
                <title>Bookings | Dashboard | {CompanyTitle}</title>
                <meta name={`Bookings | Dashboard | ${CompanyTitle}`} content={`Bookings | Dashboard | ${CompanyTitle}`} />
            </Helmet>
            <Routes>
                <Route path="/" element={<BookingPageMainContent user={props.user} bookingNavState={props.bookingNavState}
                    setBookingNavState={props.setBookingNavState} />} />
                <Route path=":id" element={<BookingDetails  user={props.user} bookingNavState={props.bookingNavState}
                    setBookingNavState={props.setBookingNavState} />} />
                <Route path="reshedule" element={<RescheduleMeeting user={props.user} bookingNavState={props.bookingNavState}
                    setBookingNavState={props.setBookingNavState} />} />
            </Routes>
        </div>
    )
}

export default ViewBookings



