import axios from "axios";
import React, { useState, useEffect } from "react";
import Colors from "../Colors/Colors";
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";
import { update_profile_step } from "./StepUpdated";
import { Mixpanel } from "../Mixpanel";
import SelectIndustry from "./SelectIndustry";

const CareerInsights = (props) => {
  localStorage.setItem("navState", 3);
  const [isLoading, setLoading] = useState(false);
  const [bio, setBio] = useState("");
  const [error, setError] = useState("");
 const [message,setmessage]=useState(false);

  //#region 
  const getUser = () => {
    axios.get(process.env.REACT_APP_SERVER_PATH + `/users/${jwt_decode(localStorage.getItem("customer_signup_token")).id}`)
      .then((response) => {
        if (response.data.bio != null) {
          setBio(response.data.bio)
        }
      }).catch(e => {
        console.log("error occured");
      })
  }
  useEffect(() => {
    getUser()
  }, [])

  const upDateUser = () => {
    setmessage(true);
    if (bio.length >= 30) {
      setLoading(true);
      axios
        .put(
          process.env.REACT_APP_SERVER_PATH +
          `/users/${jwt_decode(localStorage.getItem("customer_signup_token")).id
          }`,
          {
            bio: bio,
          }
        )
        .then((r) => {
          console.log(r);
          Mixpanel.track(`web: Add Bio.`, { bio: bio });

          update_profile_step(
            localStorage.getItem("customer_signup_token"),
            jwt_decode(localStorage.getItem("customer_signup_token")).id,
            "4"
          );
          props.setNavState(props.navState + 1);
        })
        .catch((e) => {
          console.log("Error");
        });
    }
    else {
      setError("Please provide at least 30 characters");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full text-textFullDark">
      <img
        src="/CustomerFlow/Career insights.png"
        alt="location"
        className="w-36"
      />
      <p className="text-2xl font-bold mt-3 text-textFullDark">
        Career insights
      </p>
      <p className="text-xs mt-3 text-center">
        Tell us what type of insights you're after from a career mentor?
      </p>
      <div className="my-4 w-full text-xs font-bold">
        <label className="block text-textDark  mb-2" for="bio">
          Development (1200 characters max)
        </label>
        <textarea
          className="resize-none border border-gray-300 rounded-md w-full h-24 py-3 px-3 text-textFullDark  focus:outline-none"
          id="bio"
          type="text"
          maxLength={1200}
          placeholder="I would like my mentor to help me with..."
          value={bio}
          onChange={(e) => {
            setBio(e.target.value);
          }}
        ></textarea>

        {bio.length < 30 && (
          <>
            <p className="text-xs text-red-400" style={{ marginRight: "auto" }}>
              {message && "Input must be at least 30 characters long"}
              {/* Input must be at least 100 characters long */}
            </p>
            <p className="text-sm text-green-500 mt-5">
              {bio.length} / 1200 characters
            </p>
          </>
        )}
        {bio.length > 100 && bio.length <= 1200 && (
          <>
            {/* <p className="text-xs text-red-400" style={{ marginRight: "auto" }}>
              {message && ("Input must be at most 1200 characters long")}
           
            </p> */}
            <p className="text-sm text-green-500 mt-5">
              {bio.length} / 1200 characters
            </p>
          </>
        )}
        {bio.length >= 1200 && (
          <p className="text-sm text-red-500">
            {message && "Max characters length reached"}
            {/* Max characters length reached */}
          </p>
        )}
      </div>
      <div className="w-full flex justify-end items-center mt-4 text-white">
        <button
          className="py-2 px-8 mr-auto rounded-lg flex"
          style={{
            backgroundColor: Colors.blue,
            color: "white",
          }}
          onClick={() => {
            props.setNavState(props.navState - 1);
            setLoading(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <p>Back</p>
        </button>
        <button
          className="py-2 px-8 rounded-lg flex bg-blue"
          // style={{
          //   backgroundColor: bio.length >= 100 ? Colors.blue : Colors.gray,
          //   color: bio.length >= 100 ? "white" : "gray",
          // }}
          style={{
            backgroundColor: Colors.blue,
            color: "white",
          }}
          onClick={() => {
            upDateUser();
            setmessage(true);
          }}
          // disabled={bio.length < 100}
        >
          <p>
            Next{" "}
            {isLoading ? (
              <span className="inline-block ml-4">
                <Loader
                  type="TailSpin"
                  color="white"
                  height={20}
                  width={20}
                  // timeout={5000} //5 secs
                />
              </span>
            ) : null}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CareerInsights;
