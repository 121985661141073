let Colors = {
   blue: '#00C4FF',
   purple: '#5d3f6a',
   gray: '#F8F8F8',
   linkedIn: '#0077B5',
   google: '#FC6A57',
   apple: '#313131',
   grey: '#F8F8F8',
   darkBlue: '#1F547B',
   darkGray: '#808080',
   bgBlue: '#00CCFF',
   bgDarkBlue: '#6ee0fd',
   bgGray: '#eaeff3'
}
export default Colors