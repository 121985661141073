import React from "react";
import Footer from "../Footer/Footer";
import { HowItWorksReviews } from "../HowItWorks/HowItWorks";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { CompanyTitle } from "../settings";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setLogin,
  setCustomerFalse,
} from "../Redux/reducers/customer/customerSlice";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";
import {
  setProfessionalsToTrue,
  setProfessionalsToFalse,
} from "../Redux/reducers/professional/professionalSlice";
import { RedirectTo } from "../Redux/reducers/redirect/redirectSlice";
const WhatOurMentorDo = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();


  return (
    <div>
      <Navbar />
      <hr />
      {/*Main Component  */}
      <div className="xl:container mx-auto px-2 w-full mt-4 md:mt-8">
        <img src="/WhatOurMentorDo/bg.png" className="w-full rounded-md" />
      </div>
      <div className="xl:container mx-auto px-2 w-full -mt-8 relative top-2 md:-top-16 rounded-lg">
        <div
          className={`${styles.shadow_box} w-full md:w-11/12 mx-auto flex flex-col items-center justify-center p-4 md:p-12 md:rounded-md bg-white z-10`}
        >
          <h1
            className="text-xl md:text-6xl xl:text-7xl text-center md:text-left"
            style={{
              fontFamily: "Lato Bold",
            }}
          >
            What our mentors do?
          </h1>
          <h2 className="text-xs md:text-2xl text-center text-gray-700 w-full md:w-8/12 mx-auto mt-4">
            We aim to break the traditional bonds which hold you back from
            making life changing connections.
          </h2>
        </div>
      </div>
      <div className="xl:container mx-auto px-2 w-full">
        <div className="md:w-10/12 mx-auto flex flex-col items-center justify-center p-4 rounded-md">
          <p className="text-xs md:text-2xl text-center text-textDark w-full mx-auto mt-8 md:mt-4">
            Mentored employees have found to be more productive, engaged, and
            satisfied with their jobs and 97% of mentees find mentoring
            valuable. Yet, in a representative survey from 2018, only 37% of
            working-aged respondents said they currently had a mentor, and
            furthermore, the majority of those actually worked within the same
            company.
          </p>
          <img
            src="/WhatOurMentorDo/line.png"
            className="w-full my-6 md:my-16"
          />
          <p className="text-xs md:text-2xl text-center text-textDark w-full mx-auto mt-4">
            Still today, most people find a mentor via someone they already
            know, which poses a dramatic limitation and partially denies people
            from historically underrepresented groups access.
          </p>
          <img
            src="/WhatOurMentorDo/line.png"
            className="w-full my-6 md:my-16"
          />
          <p className="text-xs md:text-2xl text-center text-textDark w-full mx-auto mt-4">
            At CareerPaths, our goal is to break these patterns and to enable
            everyone to find meaningful and impactful advice and move their
            career forward. Our mentors are experienced professionals who
            provide guidance and support to help individuals achieve their
            career goals. The 1-1 setting is ideal for individual support and to
            go deep into specific questions. By sharing their own perspectives
            and insights, mentors coach and guide mentees by offering a variety
            of support, including:
          </p>
        </div>
      </div>

      <div className="xl:container mx-auto px-2 ">
        <div className="mx-auto w-11/12">
          <div className="xl:container mx-auto px-2 md:px-16 flex flex-col md:flex-row justify-between items-center  mt-8 md:mt-16 rounded-md">
            <h1
              className="text-3xl md:text-5xl text-center md:text-left mb-6 block md:hidden"
              style={{
                fontFamily: "Lato Bold",
              }}
            >
              Networking
            </h1>
            <div className="w-4/5 md:w-auto mb-8 md:mb-0">
              <img
                src="/WhatOurMentorDo/networking.png"
                className="w-full md:w-11/12"
              />
            </div>
            <div className="w-full md:w-5/12">
              <h1
                className="text-3xl md:text-5xl text-center md:text-left hidden md:block"
                style={{
                  fontFamily: "Lato Bold",
                }}
              >
                Networking
              </h1>
              <p className="text-xs md:text-2xl text-left text-textDark w-full mt-4">
                With a few years of practical experience under their belt, most
                of our mentors have seen more than just one company from the
                inside and naturally built connections during their time. A
                single chat could open that network for you and tapping into
                such secondary contacts will exponentially grow your own
                exposure. And who knows, maybe they’ll just have heard of a
                fitting job opening in their circles that you did not see on
                LinkedIn in your own research.
              </p>
            </div>
          </div>

          <div className="xl:container mx-auto px-2 md:px-16 flex flex-col md:flex-row-reverse justify-between items-center  mt-8 md:mt-24 rounded-md">
            <h1
              className="text-3xl md:text-5xl text-center md:text-left mb-6 block md:hidden"
              style={{
                fontFamily: "Lato Bold",
              }}
            >
              Resume
            </h1>
            <div className="w-4/5 md:w-auto mb-8 md:mb-0">
              <img
                src="/WhatOurMentorDo/resume.png"
                className="w-full md:w-11/12"
              />
            </div>
            <div className="w-full md:w-5/12">
              <h1
                className="text-3xl md:text-5xl text-center md:text-left hidden md:block"
                style={{
                  fontFamily: "Lato Bold",
                }}
              >
                Resume
              </h1>
              <p className="text-xs md:text-2xl text-left text-textDark w-full mt-4">
                The golden ticket and foot in the door that’ll get you the
                chance to interview with your dream company. There’s no one
                perfect way to write or structure a resume, but oftentimes we
                edit and work on that digital business card of ours until we
                lose sight of what’s important. A second pair of eyes with
                insight knowledge of what’s important in a certain line of work
                and what specific companies might be looking for in candidates
                can make the difference between rejection and invitation to
                interview.
              </p>
            </div>
          </div>

          <div className="xl:container mx-auto px-2 md:px-16 flex flex-col md:flex-row justify-between items-center  mt-8 md:mt-24 rounded-md">
            <h1
              className="text-3xl md:text-5xl text-center md:text-left mb-6 block md:hidden"
              style={{
                fontFamily: "Lato Bold",
              }}
            >
              Interviews
            </h1>
            <div className="w-4/5 md:w-auto mb-8 md:mb-0">
              <img
                src="/WhatOurMentorDo/interviews.png"
                className="w-full md:w-11/12"
              />
            </div>
            <div className="w-full md:w-5/12">
              <h1
                className="text-3xl md:text-5xl text-center md:text-left hidden md:block"
                style={{
                  fontFamily: "Lato Bold",
                }}
              >
                Interviews
              </h1>
              <p className="text-xs md:text-2xl text-left text-textDark w-full mt-4">
                Admittedly, interviewing is not the favourite pastime of many
                people but while the setup will inevitably feel staged,
                preparation is key to make a lasting, positive impression in
                that crucial meeting. Speaking to someone who stood in your
                shoes before and ultimately found their dream job, you’ll be
                able to ask questions that Google doesn’t answer, get personal
                insights into the interviewing process in specific companies,
                and generally benefit from the (at times painful) learnings of
                others. The more specific you are in preparation to the call,
                the more tailored the support can be - from general job search
                strategies to practical interview preparation or even mock
                interviews.
              </p>
            </div>
          </div>

          <div className="xl:container mx-auto px-2 md:px-16 flex flex-col md:flex-row-reverse justify-between items-center  mt-8 md:mt-24 rounded-md">
            <h1
              className="text-3xl md:text-5xl text-center md:text-left mb-6 block md:hidden"
              style={{
                fontFamily: "Lato Bold",
              }}
            >
              Personal development
            </h1>
            <div className="w-4/5 md:w-auto mb-8 md:mb-0">
              <img
                src="/WhatOurMentorDo/development.png"
                className="w-full md:w-11/12"
              />
            </div>
            <div className="w-full md:w-5/12">
              <h1
                className="text-3xl md:text-5xl text-center md:text-left hidden md:block"
                style={{
                  fontFamily: "Lato Bold",
                }}
              >
                Personal development
              </h1>
              <p className="text-xs md:text-2xl text-left text-textDark w-full mt-4">
                Which skills are key to landing your dream job? Especially when
                people are looking at lateral moves or substantial career
                changes, they might require additional training or
                certifications to break into a new industry, but the same
                applies for folks who are just starting out. With a helping hand
                and some practical advice, you’ll get ahead of the curve and the
                chance to intentionally build and develop your skillset to get
                closer to your dream job.
              </p>
            </div>
          </div>

          <div className="xl:container mx-auto px-2 md:px-16 flex flex-col md:flex-row justify-between items-center  mt-8 md:mt-24 rounded-md">
            <h1
              className="text-3xl md:text-5xl text-center md:text-left mb-6 block md:hidden"
              style={{
                fontFamily: "Lato Bold",
              }}
            >
              Job finding
            </h1>
            <div className="w-4/5 md:w-auto mb-8 md:mb-0">
              <img src="/WhatOurMentorDo/jobsfinding.png" className="w-full" />
            </div>
            <div className="w-full md:w-5/12">
              <h1
                className="text-3xl md:text-5xl text-center md:text-left hidden md:block"
                style={{
                  fontFamily: "Lato Bold",
                }}
              >
                Job finding
              </h1>
              <p className="text-xs md:text-2xl text-left text-textDark w-full mt-4">
                You’re close to graduation, have just left university, or have
                gained a few years of work experience but feel you’re not in the
                right right place (yet)? Finding our dream job is difficult and
                during our professional lives, most of us will probably have
                more than just one career. Luckily, you’re not alone on your
                journey and the earlier you tap into the vast world of support
                that’s out there, the easier that first step will be. Many of
                our mentors have held different jobs and can share their
                personal story about how they finally ended up in their dream
                profession. Speaking to someone with that practical experience,
                you might find it easier to identify where you’d like to go and
                set career goals accordingly
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="xl:container mx-auto px-2">
        <div
          className="my-10 md:my-20 py-8 md:py-16 w-full flex flex-col justify-center items-center rounded-xl"
          style={{
            backgroundImage: "url(/applyToBecomeMentor/bg-blue.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h2 className="text-xs md:text-2xl text-center text-white font-bold w-11/12 md:w-10/12 px-3">
            If you’re looking for advice in a specific industry or for a
            specific topic, our search bar and filtering options should help you
            find the right person.
          </h2>
          <button className="bg-white text-bgAppColor text-xs md:text-lg px-6 md:px-20 pt-2 py-1 md:pr-10 md:pl-10 md:pt-4 md:pb-3 rounded-full mt-3 md:mt-6 font-semibold" onClick={() => {
            if (localStorage.getItem("cp_web_token")) {
              navigate("/mentors")
            } else {
              dispatch(setLogin());
              // dispatch({ type: 'setBannerToTrue' })
              dispatch(RedirectTo({ url: window.location.pathname }))
              dispatch(setBannerTrue());
              //  dispatch(setProfessionalsToFalse());
              //  dispatch(setCustomerFalse());
            }

          }}>
            Get started
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default WhatOurMentorDo;
