import React, {useEffect, useState } from "react";
import axios from "axios";

import {
  toggleCharityModal,
  toggleChangePercentageModal,
} from "../../../../Redux/reducers/charity/charitySlice";
import LearnMoreModal from "./LearnMoreModal";
import ChangePerecentageModal from "./changePercentageModal";
import { useSelector, useDispatch } from "react-redux";
import StopDonatingModal from "./stopDonatingModal";

function CharityActive({isCharityActive}) {
  const dispatch = useDispatch();

  const isLoading = false;
  const isCharityModal = useSelector((state) => state.charity.isCharityModal);
  const isChangePercentageModal = useSelector(
    (state) => state.charity.isChangePercentageModal
  );
  const [stopDonatingModal, setStopDonatingModal] = useState(false)

  const toggleCharityModal1 = () => {
    dispatch(toggleCharityModal());
  };

  const toggleChangePercentageModal1 = () => {
    dispatch(toggleChangePercentageModal());
  };

  const [currentPercentAtive, setCurrentPercentAtive] =useState(0);

  const getCharityPercentage = () => {
    let id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/professionals_charity/${id}`)
      .then((response) => {
        const percent = response.data[0].percent;
        setCurrentPercentAtive(percent)
      })
      .catch(function (error) {
        console.log("Error in geting Anomysss");
      });
  };

  useEffect(()=>{
   getCharityPercentage()    
  },[])


  const stopDonating = (e) => {
    e.preventDefault();
    setStopDonatingModal(true)
  };
  return (
    <section className="p-4">
      {isCharityModal && (
        <LearnMoreModal
          toggle={toggleCharityModal1}
          overlay="overlay"
          modal="modal"
          isLoading={isLoading}
          isCharityActive={isCharityActive}
        />
      )}

      {isChangePercentageModal && (
        <ChangePerecentageModal
          toggle={toggleChangePercentageModal1}
          overlay="overlay"
          modal="modal"
          isLoading={isLoading}
          percent={currentPercentAtive}
          setPercent={setCurrentPercentAtive}
        />
      )}

      { stopDonatingModal && (
        <StopDonatingModal 
        toggle={()=>setStopDonatingModal(!stopDonatingModal)}
        overlay="overlay"
        modal="modal"
        isLoading={isLoading}
        />
      )

      }

      <div className="w-full ">
        <div className="h-full my-4 border-2 border-gray-200 bg-white border-opacity-60 rounded-lg overflow-hidden">
          <div>
            <div className="flex absolute flex-wrap justify-starts items-center m-4">
            </div>
            <img
              className="w-full object-cover object-center"
              src="/charity/inspire.png"
              alt="blog"
            />
          </div>

          <div className="p-6">
            <p className="leading-relaxed mb-3">
              Inspire! is a registered charity established in 2004. Our work is
              based in Hackney, Camden, Islington and surrounding areas of
              London.
            </p>
            <div className="flex items-center flex-wrap ">
              <button
                onClick={toggleCharityModal1}
                className="text-bgAppColor inline-flex items-center md:mb-2 lg:mb-0"
              >
                View Details
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </div>
            <div
        className="flex items-center justify-between bg-green-100 rounded-lg p-4 text-sm text-green-700"
        
      >
        <div className="flex justify-around items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-patch-check-fill"
            viewBox="0 0 16 16"
          >
            {" "}
            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />{" "}
          </svg>
          <span className="font-medium mx-5 sm:text-sm sm:mx-2 sm:text-sm">
            Verified charity organization.{" "}
          </span>
        </div>
        <h1 className="title-font text-xl font-extrabold text-green-600 sm:text-md sm:ml-2">
          Inspire!
        </h1>
      </div>


          </div>
        </div>
      </div>

      <div className="flex justify-center" style={{backgroundColor:"#00B5FF1F"}}>
        <div className="block w-full p-6 rounded-lg shadow-lg text-center ">
          <p className="text-gray-700 text-base mb-4">
            You are donating to Inspire!
          </p>
          <h5 className="text-gray-800 text-2xl leading-tight font-bold mb-2">
            {currentPercentAtive}%
          </h5>
          <p className="text-gray-700 text-base mb-4">
            of your earnings
          </p>
          <button
            type="button"
            onClick={toggleChangePercentageModal1}
            className=" inline-block px-5 w-2/3 py-5 bg-bgAppColor text-white font-bold text-lg leading-tight rounded shadow-md "
          >
            Change donation amount
          </button>
          <button onClick={stopDonating} className="px-5 py-2.5  rounded overflow-hidden w-2/3 mt-4 font-bold text-red-700 inline-block">
           <span className="relative group-hover:text-white">Stop donating</span>
          </button>
        </div>
      </div>

    </section>
  );
}

export default CharityActive;
