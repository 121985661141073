import React from "react";

const roundedComponent = ({ image }) => {
    return (
        <div className="sm:w-28 sm:h-28 md:w-48 md:h-48 flex items-center justify-center rounded-full mr-2">
            <div className="">
                <img className="rounded-full object-cover sm:w-28 sm:h-28 md:w-40 md:h-40" src={image} alt="images" />
            </div>
        </div>
    );
}

export default roundedComponent;