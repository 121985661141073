import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../Arrows/LeftArrow";
import RightArrow from "../Arrows/RightArrow";

const RoundedComponent = (props) => {
      const Ref = useRef(null);
      const SlickNext = () => {
        Ref.current && Ref.current.slickNext();
         Ref.current && Ref.current.slickPause();
         setAutoplay(true);
        
        setTimeout(() => {
            Ref.current && Ref.current.slickPlay();
            
        }, 5500);
      };
      const SlickPrev = () => {
        Ref.current && Ref.current.slickPrev();
         Ref.current && Ref.current.slickPause();
        setAutoplay(true);
        setTimeout(() => {
          Ref.current && Ref.current.slickPlay();
        }, 5500);
      };
    useEffect(() => {
      // Start autoplay
      Ref.current && Ref.current.slickPlay();
    }, []);
  const [autoplay, setAutoplay] = useState(true);
  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    autoplay: autoplay,
    speed: 3000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => {
      // Pause autoplay when manually changing slides
      setAutoplay(false);
    },
    afterChange: () => {
      // Resume autoplay after manual slide change
      setTimeout(() => {
        setAutoplay(true);
      }, 5000);
    },
  });


  return (
    <div className="bg-bgGray pt-6 py-2 sm:h-36 md:h-56 mt-10">
      <div className="md:container mx-auto my-4 sm:px-2 xl:px-16  flex flex-row justify-between items-center">
        <h1 className="text-textFullDark sm:text-lg md:text-2xl font-bold">
          {props.name}
        </h1>
        <div className="text-md flex flex-row items-center text-textDark">
          <LeftArrow click={SlickNext} />
          <RightArrow click={SlickPrev} />
        </div>
      </div>
      <div className="md:container mx-auto my-6 px-2 xl:px-16">
        <Slider {...settings} ref={Ref}>
          {props.partner.map((p, i) => (
            <div key={i}>
              <Partner image={p.profile_photo} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default RoundedComponent;

const Partner = ({ image }) => {
    return (
        <div className="sm:h-10 2xl:h-20 mr-8 md:mr-16">
            <div className="">
                <img className="sm:h-10 md:h-20" src={image} alt="partner" />
            </div>
        </div>
    );
}