import moment from "moment";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { pricetoDecimal } from "../../../../price";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  // Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Loader from "react-loader-spinner";
import Colors from "../../../../Colors/Colors";


const WeeklyTransactions = ({
  transactionsWeekly,
  userType,
  customerTransactionsWeekly,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    // Legend,
    ChartDataLabels
  );

  const [currentWeek, setCurrentWeek] = useState(0);
  const [isLoading, setLoading] = useState(false);
  //   const labels = ["15", "16", "17", "18", "19", "20", "21"];
  const [labels, setLabels] = useState([]);
  const getLabels = () => {
    const week = currentWeek;
    let startDate = new Date();

    //if previous week selected i.e. passed positve value in week jumping to previous week
    startDate.setDate(startDate.getDate() - 7 * week);

    const { firstday, lastday } = getFirstDayOfWeek(startDate);
    const dateArray = [];
    let currentDate = new Date(firstday);

    while (currentDate <= new Date(lastday)) {
      dateArray.push(formattedDate(new Date(currentDate)));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
    setLabels(dateArray);
    // return dateArray;
  };

  const getFirstDayOfWeek = (d) => {
    const curr = new Date(d);
    const first =
      curr.getDate() - curr.getDay() + (curr.getDay() === 0 ? -6 : 1); // First day is the  day of the month - the day of the week
    const firstday = new Date(curr.setDate(first));
    const lastday = new Date(curr.setDate(curr.getDate() + 6));

    return { firstday, lastday };
  };

  const formattedDate = (d = new Date()) => {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}/${month}`;
  };

  const options = {
    layout: {
      padding: 20,
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: "#4F4F4F",
        anchor: "end",
        offset: -20,
        align: "start",
        formatter: function (value) {
          return value ? "£" + value.toFixed(2) : "0";
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (label) {
            return `${this.getLabelForValue(label)}`;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        display: false,
        grid: {
          drawOnChartArea: false,
        },
      },
      // secondXAxis: {
      //   axis: "x",
      //   labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      //   grid: {
      //     drawOnChartArea: false,
      //   },
      // },
    },
  };

  const [openTab, setOpenTab] = useState(1);

  const [DonationsArray, setDonationArray] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [EarningsArray, setEarningsArray] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [nextBtn, setNextBtn] = useState(false);
  const [totalCharity, setTotalCharity] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    getDonationData();
    getLabels();
  }, [currentWeek]);

  useEffect(() => {
    let id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/users/${id}`)
      .then((response) => {
        setTotalBalance(response.data.wallet);
      })
      .catch((e) => {
        console.log("Response of user");
      });
  }, []);

  const getDonationData = () => {
    setLoading(true);
    axios
      .get(
        //professional-earnings-and-donations/0
        `${process.env.REACT_APP_SERVER_PATH}/professional-earnings-and-donations/${currentWeek}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        setTotalCharity(response.data.message.totalCharity[0].donations);
        // for donation array
        let i = 0;
        let tempDonationArray =
          response.data.message.charityTransactionsWithDays;
        let tempArray1 = [0, 0, 0, 0, 0, 0, 0, 0];
        for (i = 0; i < tempDonationArray.length; i++) {
          if (tempDonationArray[i].day == "Monday") {
            tempArray1[0] = tempDonationArray[i].donations;
          } else if (tempDonationArray[i].day == "Tuesday") {
            tempArray1[1] = tempDonationArray[i].donations;
          } else if (tempDonationArray[i].day == "Wednesday") {
            tempArray1[2] = tempDonationArray[i].donations;
          } else if (tempDonationArray[i].day == "Thursday") {
            tempArray1[3] = tempDonationArray[i].donations;
          } else if (tempDonationArray[i].day == "Friday") {
            tempArray1[4] = tempDonationArray[i].donations;
          } else if (tempDonationArray[i].day == "Saturday") {
            tempArray1[5] = tempDonationArray[i].donations;
          } else if (tempDonationArray[i].day == "Sunday") {
            tempArray1[6] = tempDonationArray[i].donations;
          } else {
            console.log("weekly transactions=> 18", tempDonationArray[i].day);
          }
        }
        setDonationArray(tempArray1);
        // for earning array
        i = 0;
        let tempEarningArray =
          response.data.message.commissionTransactionsWithDays;
        let tempArray2 = [0, 0, 0, 0, 0, 0, 0];
        for (i = 0; i < tempEarningArray.length; i++) {
          if (tempEarningArray[i].day == "Monday") {
            tempArray2[0] = tempEarningArray[i].earning;
          } else if (tempEarningArray[i].day == "Tuesday") {
            tempArray2[1] = tempEarningArray[i].earning;
          } else if (tempEarningArray[i].day == "Wednesday") {
            tempArray2[2] = tempEarningArray[i].earning;
          } else if (tempEarningArray[i].day == "Thursday") {
            tempArray2[3] = tempEarningArray[i].earning;
          } else if (tempEarningArray[i].day == "Friday") {
            tempArray2[4] = tempEarningArray[i].earning;
          } else if (tempEarningArray[i].day == "Saturday") {
            tempArray2[5] = tempEarningArray[i].earning;
          } else if (tempEarningArray[i].day == "Sunday") {
            tempArray2[6] = tempEarningArray[i].earning;
          }
        }
        setEarningsArray(tempArray2);
        setLoading(false);
      })
      .catch((e) => {
        console.log("weekly transactions=> error", e);
      });
  };

  const dataForEarning = {
    labels: labels,
    datasets: [
      {
        label: "Earnings",
        data: EarningsArray,
        borderColor: "orange",
        backgroundColor: "rgba(0, 181, 255, 1)",
        borderRadius: 8,
        datalabels: {
          color: "#4F4F4F",
        },
      },
    ],
  };
  const dataForDonations = {
    labels: labels,
    datasets: [
      {
        label: "Donations",
        data: DonationsArray,
        borderColor: "orange",
        backgroundColor: "rgba(0, 181, 255, 1)",
        borderRadius: 8,
        datalabels: {
          color: "#4F4F4F",
        },
      },
    ],
  };

  const nextWeek = () => {
    if (currentWeek > 0) {
      setCurrentWeek(currentWeek - 1);
    }
  };
  const previousWeek = () => {
    if (currentWeek > 0) {
      setNextBtn(true);
    } else {
      setNextBtn(false);
    }
    setCurrentWeek(currentWeek + 1);
  };

  return (
    <div className="mt-0">
     {userType === "professional" &&
      <div>
        <div className="flex justify-between">
          <div className=" rounded-xl w-36 px-4 my-3 bg-white dark:bg-gray-800">
            <p className="text-md text-gray-500 dark:text-gray-50">Your balance</p>
            <p className="text-gray-500 text-2xl text-left dark:text-white font-bold">
              {pricetoDecimal( totalBalance)}
            </p>
          </div>
          <div className=" rounded-xl w-36 px-4 my-3 bg-white dark:bg-gray-800">
            <p className="text-md text-gray-500 dark:text-gray-50">Your charity</p>
            <p className="text-gray-500 text-2xl text-left dark:text-white font-bold">
              { totalCharity > 0 ? pricetoDecimal(totalCharity) : 0 }
            </p>
          </div>
        </div>
        {/* tabs start */}
        <div className="w-full">
          <ul className="flex mb-0 mx-auto" role="tablist">
            <li className=" mx-auto mr-2  text-center">
              <a
                className={
                  "text-sm font-bold uppercase px-5 py-3 rounded flex leading-normal " +
                  (openTab === 1 ? "text-black" : "text-gray-400 ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Earnings
              </a>
              {openTab === 1 ? (
                <div className="flex-grow border-t border-gray-400"></div>
              ) : (
                <div></div>
              )}
            </li>
            <li className=" mx-auto  text-center">
              <a
                className={
                  "text-sm font-bold uppercase px-5 py-1.5  rounded flex justify-center items-center leading-normal " +
                  (openTab === 2 ? "text-black" : "text-gray-400")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <p>Donations</p>
                <img
                  className="h-8 w-8 mb-0.5"
                  src="/charity/honor.png"
                  alt=""
                />
              </a>
              {openTab === 2 ? (
                <div className="flex-grow border-t border-gray-400"></div>
              ) : (
                <div></div>
              )}
            </li>
          </ul>

          <div className="relative h-1/2 flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            {isLoading ? (
              <div className="flex justify-center h-72 items-center pt-6">
                {" "}
                <Loader
                  type="TailSpin"
                  color={Colors.blue}
                  height={40}
                  width={40}
                />{" "}
              </div>
            ) : (
              <div className="px-4 py-5 flex justify-center items-center">
                <div className="w-1/12">
                  <button onClick={previousWeek} className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path
                        fillRule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        fill="#01b5ff"
                      ></path>{" "}
                    </svg>{" "}
                  </button>
                </div>

                <div className="tab-content w-10/12 tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <Bar options={options} data={dataForEarning} />
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <Bar options={options} data={dataForDonations} />
                  </div>
                </div>

                <div className="w-1/12">
                  {currentWeek > 0 ? (
                    <button onClick={nextWeek} className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-arrow-right-circle"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                          fill="#01b5ff"
                        ></path>{" "}
                      </svg>{" "}
                    </button>
                  ) : (
                    <button onClick={nextWeek} disabled={true} className="">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#817979" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16"> <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/> </svg>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
 }
      {/* tabs end */}

      {userType === "professional"
        ? transactionsWeekly.map((transaction, index) => {
            return (
              <div key={index}>
                {/* Refund of Professional */}
                {transaction.action === "Refund" ? (
                  <Link
                    to={`${transaction.id}`}
                    state={{
                      id: transaction.user_id,
                      booking_id: transaction.booking_id,
                      action: transaction.action,
                    }}
                  >
                    <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                      <div className="flex items-center">
                        <img
                          src={
                            transaction.user_id ===
                            transaction.Booking?.Professionals_Metadatum
                              ?.user_id
                              ? transaction.Booking?.User?.profile_photo ? transaction.Booking?.User?.profile_photo : "/avatar.png"
                              : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo ? transaction.Booking?.Professionals_Metadatum?.User?.profile_photo : "/avatar.png"
                         }
                          alt=""
                          className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                        <div className="ml-1 md:ml-2">
                          <p className="text-sm md:text-lg font-bold">
                            {
                              transaction.user_id === transaction.Booking?.Professionals_Metadatum?.user_id
                                ? <p> {transaction.Booking?.User?.first_name}&nbsp;{transaction.Booking?.User?.last_name} </p>
                                : <p>{transaction.Booking?.Professionals_Metadatum?.User?.first_name}&nbsp;{transaction.Booking?.Professionals_Metadatum?.User?.last_name}</p>
                            }
                          </p>
                          <p className="text-sm">
                            {moment(transaction.createdAt).format(
                              "dddd,MMM D,YYYY h:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <p className="text-bgAppColor font-bold">
                          +{pricetoDecimal(transaction.amount)}
                        </p>
                        <p className="text-sm">
                         Money Refund
                        </p>
                      </div>
                    </div>
                  </Link> // Commission of Professional
                ) : transaction.action === "Commission" ? (
                  <Link
                    to={`${transaction.id}`}
                    state={{
                      id: transaction.user_id,
                      booking_id: transaction.booking_id,
                      action: transaction.action,
                    }}
                  >
                    <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                      <div className="flex items-center">
                        <img
                          src={
                            transaction.user_id ===
                            transaction.Booking?.Professionals_Metadatum
                              ?.user_id
                              ? transaction.Booking?.User?.profile_photo ? transaction.Booking?.User?.profile_photo : "/avatar.png"
                              : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo ? transaction.Booking?.Professionals_Metadatum?.User?.profile_photo : "/avatar.png"
                          }
                          alt=""
                          className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                          />
                        <div className="ml-1 md:ml-2">
                          <p className="text-sm md:text-lg font-bold">
                            <div className="flex">
                            <p className="text-sm md:text-lg font-bold">                              
                            {
                              transaction.user_id === transaction.Booking?.Professionals_Metadatum?.user_id
                                ? <p> {transaction.Booking?.User?.first_name}&nbsp;{transaction.Booking?.User?.last_name} </p>
                                : <p>{transaction.Booking?.Professionals_Metadatum?.User?.first_name}&nbsp;{transaction.Booking?.Professionals_Metadatum?.User?.last_name}</p>
                            }
                            </p>
                            </div>
                          </p>
                          <p className="md:text-sm sm:text-xs">
                            {moment(transaction.createdAt).format(
                              "dddd,MMM D,YYYY h:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <p className="text-bgAppColor font-bold sm:text-sm md:text-lg">+{pricetoDecimal(transaction.amount)}</p>
                        <p className="md:text-sm sm:text-xs">
                          Money Received
                        </p>
                      </div>
                    </div>
                  </Link> // Session Booking of Professional
                ) : transaction.action === "Session Booking" ? (
                  <Link
                    to={`${transaction.id}`}
                    state={{
                      id: transaction.user_id,
                      booking_id: transaction.booking_id,
                      action: transaction.action,
                    }}
                  >
                    <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                      <div className="flex items-center">
                        <img
                          src={
                            transaction.user_id ===
                            transaction.Booking?.Professionals_Metadatum
                              ?.user_id
                              ? transaction.Booking?.User?.profile_photo ? transaction.Booking?.User?.profile_photo : "/avatar.png"
                              : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo ? transaction.Booking?.Professionals_Metadatum?.User?.profile_photo : "/avatar.png"
                        }
                          alt=""
                          className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                          />
                        <div className="ml-1 md:ml-2">
                          <p className="text-sm md:text-lg font-bold">
                          {
                              transaction.user_id === transaction.Booking?.Professionals_Metadatum?.user_id
                                ? <p> {transaction.Booking?.User?.first_name}&nbsp;{transaction.Booking?.User?.last_name} </p>
                                : <p>{transaction.Booking?.Professionals_Metadatum?.User?.first_name}&nbsp;{transaction.Booking?.Professionals_Metadatum?.User?.last_name}</p>
                            }
                          </p>
                          <p className="text-sm">
                            {moment(transaction.createdAt).format(
                              "dddd,MMM D,YYYY h:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <p className="text-red-500 font-bold">
                          -{pricetoDecimal(transaction?.Booking?.session_fee ?(Number(transaction?.amount)+ Number(process.env.REACT_APP_BOOKING_FEE)):transaction?.amount)}
                        </p>
                        <p className="text-sm">
                         Money Sent
                        </p>
                      </div>
                       </div>{" "}
                  </Link> // PAYMENT IS DECLINE
                ) : transaction.action === "PAYMENT IS DECLINE" ? (
                  <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                  <div className="flex items-center">
                    <img
                      src="/cplogo.png"
                      alt=""
                      className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                      />
                    <div className="ml-1 md:ml-2">
                      <div className="text-xs md:text-lg font-bold">
                        <p>Your withdrawal request for {pricetoDecimal(transaction.amount)} has been declined</p>
                        </div>
                      <p className="text-sm">
                        {moment(transaction.createdAt).format(
                          "dddd,MMM D,YYYY h:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                  
                  <div>
                    <p className="text-green-500 font-bold">
                      <div className="flex">
                        <p className="text-red-500 font-bold">
                          {pricetoDecimal(transaction.amount)}
                        </p>
                      </div>
                    </p>
                  </div>
                </div> //Withdrawal
                ) : transaction.action === "Withdrawal" ? (
                  <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                    <div className="flex items-center">
                      <img
                        src="/cplogo.png"
                        alt=""
                        className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                      <div className="ml-1 md:ml-2">                      
                        <div className="text-xs md:text-lg font-bold">
                          <p>Your withdrawal request for {pricetoDecimal(transaction.amount)} has been approved</p>
                        </div>

                        <p className="text-sm">
                          {moment(transaction.createdAt).format(
                            "dddd,MMM D,YYYY h:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-green-500 font-bold">
                        <div className="flex">
                          <p className="text-red-500 font-bold">
                            {pricetoDecimal(transaction.amount)}
                          </p>
                        </div>
                      </p>
                    </div>
                  </div> //Pending Withdrawal Request
                ) : transaction.action === "Pending Withdrawal Request" ? (
                  <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                    <div className="flex items-center">
                      <img
                        src="/cplogo.png"
                        alt=""
                        className="sm:w-10 md:w-14 sm:h-10 rounded-full object-cover border"
                      />
                      <div className="ml-1 md:ml-2">
                        <p className="text-sm md:text-lg font-bold">
                          <p>You requested payment withdrawn of &nbsp; {pricetoDecimal(transaction.amount)}</p>
                        </p>
                        <p className="text-sm">
                          {moment(transaction.createdAt).format(
                            "dddd,MMM D,YYYY h:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-green-500 font-bold">
                        <div className="flex">
                          <p className="text-red-500 font-bold">
                            {pricetoDecimal(transaction.amount)}
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>  //PARTIAL AMOUNT PAID
                ) : transaction.action === "PARTIAL AMOUNT PAID" ? (
                  <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                    <div className="flex items-center">
                      <img
                        src="/cplogo.png"
                        alt=""
                        className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                      <div className="ml-1 md:ml-2">
                        <div className="flex text-sm md:text-lg font-bold">
                            <p>Your withdrawal request for {pricetoDecimal(transaction.amount)} has been partailly accepted</p>
                          </div>
                        <p className="text-sm">
                          {moment(transaction.createdAt).format(
                            "dddd,MMM D,YYYY h:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-red-500 font-bold">
                        <div className="flex">
                          <p>-</p>
                          <p className=" font-bold">
                            {pricetoDecimal(transaction.amount)}
                          </p>
                        </div>
                      </p>
                    </div>
                  </div> // REFERRAL BONUS
                ) : transaction.action === "REFERRAL BONUS" ? (
                  <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                    <div className="flex items-center">
                      <img
                        src="/cplogo.png"
                        alt=""
                        className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                      <div className="ml-1 md:ml-2">
                        <p className="text-sm md:text-lg font-bold">
                          <div className="flex">
                            <p>You have received referral reward of &nbsp; {pricetoDecimal(transaction.amount)}</p>
                          </div>
                        </p>
                        <p className="text-sm">
                          {moment(transaction.createdAt).format(
                            "dddd,MMM D,YYYY h:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-green-500 font-bold">
                        <div className="flex">
                          <p className="text-red-500 font-bold">
                            {pricetoDecimal(transaction.amount)}
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })
        : customerTransactionsWeekly.map((transaction,index) => {
          console.log('==============transaction======================');
          console.log(transaction);
          console.log('====================================');
            return (
              <div key={index}>
                {transaction.action === "Refund" ? (
                  <Link
                    to={`${transaction.id}`}
                    state={{
                      id: transaction.user_id,
                      booking_id: transaction.booking_id,
                      action: transaction.action,
                    }}
                  >
                    <div className="flex justify-between shadow-lg items-center sm:p-1 md:px-4 md:py-3 rounded-lg mb-2">
                      <div className="flex items-center">
                        <img
                          src={
                            transaction.Booking?.Professionals_Metadatum?.User?.profile_photo
                              ? transaction.Booking?.Professionals_Metadatum?.User?.profile_photo
                              : " /avatar.png"
                          }
                          alt=""
                          className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                        <div className="ml-1 md:ml-2">
                          <p className="text-sm md:text-lg font-bold">
                          {transaction.Booking?.Professionals_Metadatum?.User?.first_name}&nbsp;{transaction.Booking?.Professionals_Metadatum?.User?.last_name}
                          </p>
                          <p className="text-sm">
                            {moment(transaction.createdAt).format(
                              "dddd,MMM D,YYYY h:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        
                        <p className="text-bgAppColor font-bold">
                        +&nbsp;{pricetoDecimal(transaction.amount)}
                        </p>
                        <p className="text-sm">
                          Money Refund
                        </p>
                      </div>
                    </div>
                  </Link> //SESSION_BOOKING
                ) : transaction.action === "Session Booking" ? (
                  <Link
                    to={`${transaction.id}`}
                    state={{
                      id: transaction.user_id,
                      booking_id: transaction.booking_id,
                      action: transaction.action,
                    }}
                  >
                    <div className="flex  justify-between items-center p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                      <div className="flex items-center">
                        <img
                          src={
                            transaction.Booking?.Professionals_Metadatum?.User?.profile_photo
                              ? transaction.Booking?.Professionals_Metadatum?.User?.profile_photo
                              : " /avatar.png"
                          }
                          alt=""
                          className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                        <div className="ml-1 md:ml-2">
                          <p className="text-sm md:text-lg font-bold">
                            <div className="flex">
                             <p>
                             {transaction.Booking?.Professionals_Metadatum?.User?.first_name}&nbsp;{transaction.Booking?.Professionals_Metadatum?.User?.last_name}
                             </p>
                            </div>
                          </p>
                          <p className="sm:text-xs md:text-sm">
                            {moment(transaction.createdAt).format(
                              "dddd,MMM D,YYYY h:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div>
                          <div className="flex flex-col items-end justify-center">
                            <p className="text-red-500 font-bold sm:text-sm md:text-lg">-&nbsp;{pricetoDecimal(transaction?.Booking?.session_fee ?(Number(transaction?.amount)+ Number(process.env.REACT_APP_BOOKING_FEE)):transaction?.amount)}</p>
                            <p className="text-sm">
                              Money Sent
                            </p>
                          </div>
                      </div>
                    </div>{" "}
                  </Link> //REFERRAL_BONUS
                ) : transaction.action === "REFERRAL BONUS" ? (
                 <div className="flex justify-between items-center sm:p-1 md:px-4 md:py-3 rounded-lg shadow-lg mb-2">
                    <div className="flex items-center">
                      <img
                        src="/cplogo.png"
                        alt=""
                        className="mr-2 sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover ring-2 ring-bgAppColor dark:ring-gray-500"
                        />
                      <div className="ml-1 md:ml-2">
                        <p className="text-sm md:text-lg font-bold">
                          <div className="flex">
                            <p>You have received referral reward of {pricetoDecimal(transaction.amount)}
                            </p>
                          </div>
                        </p>
                        <p className="text-sm">
                          {moment(transaction.createdAt).format(
                            "dddd,MMM D,YYYY h:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <p className="text-bgAppColor font-bold sm:text-sm md:text-lg">{pricetoDecimal(transaction.amount)}</p>
                  </div>
                ) : null}
              </div>
            );
          })}
      
      {userType === "professional" && transactionsWeekly.length < 1 &&
        <div className="flex items-center justify-center w-full mt-12">
          <h2 className="text-xl w-2/3 text-center">All payment transactions related to your meetings will be shown here</h2>
        </div>
      }

      {userType === "customer" && customerTransactionsWeekly.length < 1 && 
        <div className="flex items-center justify-center w-full mt-12">
          <h2 className="text-xl w-2/3 text-center">All payment transactions related to your meetings will be shown here</h2>
        </div>
      }
    
    </div>
  );
};

export default WeeklyTransactions;
