import React, { useEffect, useState,Suspense } from 'react';
import './App.css';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import BookingPage from './BookingPage/BookingPage';
import { Routes, Route, Navigate, useParams, useNavigate } from "react-router-dom";
import Landing from './Landing/Landing';
import Listing from './Listing/Listing';
import Profile from './Profile/Profile';
import EmailVerify from './EmailVerify/emailVerify';
import Dashboard from './Dashboard/DashboardRoutes/Dashboard';
import VideoChat from './VideoCall/videoChat';
import Done from './BookingPage/BookingMainContent/MainContent/Done/Done';
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Privacy from './Privacy/Privacy';
import Terms from './Terms/Terms';
import UserRating from './UserRating/UserRating';
import HowItWorks from './HowItWorks/HowItWorks';
import PEB from "./PrivateEquityBundle/index"
import { Helmet } from 'react-helmet';
import { CompanyTitle } from './settings';
import {LogoName} from './settings'
import { CompanyHelpEmail } from "./settings";
import FAQs from './FAQs/FAQs';
import AboutUs from './AboutUs/AboutUs';
import ButtonCookies from './Cookies/ButtonCookies';
import FormCookies from './Cookies/FormCookies';
import Welcome from './Welcome/Welcome';
import Download from './Download/Download';
import Company from './Company/Company';
import ForMentor from './ForMentor/ForMentor';
import Support from './Support/Support';
import FreeCredit from './FreeCredit/FreeCredit';
import Courses from './Courses/index';
import Career from './Career/Career';
import PEBProfile from './Dashboard/MainContent/PEB/PEBProfile';
import CheckoutPage from './PrivateEquityBundle/checkoutPage';
import FirebaseComponent from './FirebaseComponent';
import ApplyToBecomeMentorForm from './ApplyToBecomeMentor/ApplyToBecomeMentorForm';
import { useDispatch } from 'react-redux';
import { RedirectTo } from './Redux/reducers/redirect/redirectSlice';
import ApplyToBecomeMentor from './ApplyToBecomeMentor/ApplyToBecomeMentor';
import WhatOurMentorDo from './WhatOurMentorDo/WhatOurMentorDo';

const App = () => {
  const dispatch = useDispatch();
  const [isCookies, setCookies] = useState(false)
  const [isForm, setForm] = useState(false)
  const gtmParams = { id: 'GTM-TMD4H3Q' }
  
  const RequireAuth = ({ children, redirectTo }) => {
    let isAuthenticated = localStorage.getItem("cp_web_token")
    dispatch(RedirectTo({url:window.location.pathname}))
    return isAuthenticated ? children : <Navigate to={redirectTo} /> ;
  }
  return (
    <Suspense fallback={<div></div>}>
      <GTMProvider state={gtmParams}>
        <Helmet>
          <title>{CompanyTitle}</title>
          <meta name="name" content={CompanyTitle} />
        </Helmet>
        {/* <div style={{ fontFamily: "Cerebri Sans Book" }}> */}
        <div style={{ fontFamily: "Lato sans-serif" }}>
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/" element={<Landing />} />
            <Route path="/download" element={<Download />} />
            <Route
              path="/apply-to-become-mentor"
              element={<ApplyToBecomeMentor />}
            />
            <Route
              path="/apply-to-become-mentor-form"
              element={<ApplyToBecomeMentorForm />}
            />
            <Route path="/what-our-mentor-do" element={<WhatOurMentorDo />} />
            <Route path="/course" element={<Courses />} />
            <Route path="/private-equity-bundle" element={<PEB />} />
            <Route path="/career-mentoring" element={<ForMentor />} />
            <Route path="/invite-user" element={<FreeCredit />} />
            <Route path="/support" element={<Support />} />
            <Route path="/career" element={<Career />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/company" element={<Company />} />
            <Route
              path="/how-it-works/*"
              element={<HowItWorks />}
              refreshRout={"/how-it-works"}
            />
            <Route
              path="/dashboard/booking/review/:roomid"
              element={<UserRating />}
            />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/booking/:id" element={<NavigationComponent />} />
            <Route path="/verify/:hash" element={<EmailVerify />} />
            <Route path="/mentors">
              <Route path=":industries" element={<Listing />} />
              <Route path=":industries/:other" element={<Listing />} />
              <Route path="" element={<Listing />} />
            </Route>

            <Route path="/signup" element={<Landing />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/request-a-call/:id" element={<BookingPage />} />
            <Route path="/booking-confirm" element={<Done />} />
            <Route exact path="/linkedin" element={<LinkedInCallback />} />
            <Route exact path="/firebase" element={<FirebaseComponent />} />
            <Route
              path="/dashboard/*"
              element={
                <RequireAuth redirectTo="/">
                  <Dashboard />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/dashboard/booking/call/:name/:roomid"
              element={
                <RequireAuth redirectTo="/">
                  <VideoChat />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="*"
              element={
                <div
                  className="absolute top-0 left-0 bg-black w-screen h-screen flex items-center justify-center"
                  style={{ zIndex: "1000" }}
                >
                  <div>
                    <img alt="" src="/notfound.gif" />
                  </div>
                </div>
              }
            />
          </Routes>
          <div className="md:w-screen flex flex-col md:flex-row md:items-end md:justify-between fixed bottom-0 left-0 z-50 overflow-y-auto h-screen md:h-auto">
            {isCookies && <ButtonCookies setCookies={setCookies} />}
            {isForm && <FormCookies setForm={setForm} />}
          </div>
        </div>
      </GTMProvider>
    </Suspense>
  );
}


export default App;

const NavigationComponent = () => {
  let params = useParams()
  let navigate = useNavigate()
  useEffect(() => {
    navigate(`/dashboard/bookings/${params.id}`)
  })
  return (
    <div>
      hi
    </div>
  )
}
