import React, { useState, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ratings from '../../Rating/Ratings'
import { useSelector } from 'react-redux';
import "./Review.css"
import Loader from 'react-loader-spinner';
import Colors from '../../Colors/Colors';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { zIndexRaise, zIndexNormal } from '../../Redux/reducers/searchBar/searchBar';
const Reviews = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)

    const toggleShow = () => {
        setShow(prevState => !prevState)
    }
    if (show) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return (
        <div >
            {show && <ReviewAll
                toggle={toggleShow}
                overlay="overlay"
                modal="modal"
                modalcontent="modal-content"
                closeModal="close-modal"
                modalValue={show}
                reviews={props.reviews}
            />}
            <div className='md:container mx-auto sm:px-2 md:px-56'>
                <div className="py-2 flex flex-row justify-between items-center md:my-4">
                    <h1 className="sm:text-lg md:text-2xl font-bold text-textFullDark">
                        {props.name}
                    </h1>
                    <div className="text-sm text-gray-600 flex items-center cursor-pointer p-2 rounded-lg hover:bg-bgGray" onClick={() => {
                        setShow(prev => !prev)
                    }}>
                        <p className='text-textFullDark'>See all<span className='font-bold rounded-full mx-1'>{props.reviews.length}</span>reviews</p>
                    </div>
                </div>
                <div>
                    {props.reviews.slice(0, 2).map(r => {
                        return <Review
                            reviewer_image={r.reviewer_image}
                            reviewer_name={r.reviewer_name}
                            reviewer_points={r.rating}
                            reviewer_description={r.feedback}
                            reviewerData={r.reviewer}
                            arrLength={props.reviews.length} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default Reviews

const Review = (props) => {
    return (
        <div>
            <div className='bg-bgGray p-4 rounded-xl md:hidden mb-2'>
                <div className='flex items-center w-full'>
                    <div className='flex items-center justify-center'>
                        <div className='w-12 h-12 bg-bgAppColor flex items-center justify-center  rounded-full '>
                            <p className='text-white font-bold w-12 h-12  items-center justify-center flex'>{props.reviewerData?.first_name ? (props.reviewerData?.first_name).charAt(0) : 'A'}</p>
                        </div>
                    </div>
                    <div className='ml-2'>
                        <p className='text-textFullDark'>{props.reviewerData?.first_name ? `${(props.reviewerData?.first_name)}` : 'A'}</p>
                    </div>
                </div>
                <div className='flex items-center'>
                    <Ratings value={+props.reviewer_points} />
                    <p className='ml-2'>{+props.reviewer_points}</p>
                </div>
                <div className="text-sm text-textDark italic">
                    <p>{props.reviewer_description ? `"${props.reviewer_description}"` : 'No review description'}</p>
                </div>
            </div>

            <div className='w-full bg-bgGray  mb-2 hidden md:flex flex-col rounded-xl p-4'>
                <div className='flex w-full '>
                    <div className='w-1/12 flex items-center justify-center'>
                        <div className='w-12 h-12 bg-bgAppColor flex items-center justify-center  rounded-full '>
                            <p className='text-white font-bold w-12 h-12  items-center justify-center flex'>{props.reviewerData?.first_name ? (props.reviewerData?.first_name).charAt(0) : 'A'}</p>
                        </div>
                    </div>
                    <div>
                        <Ratings value={+props.reviewer_points} />
                    </div>
                </div>
                <div className='p-2 flex w-full items-start mt-2'>
                    <div className='w-1/12 flex items-center justify-center'>
                        <div>
                            <p>{props.reviewerData?.first_name ? `${(props.reviewerData?.first_name)}` : 'A'}</p>
                        </div>
                    </div>
                    <div className="text-sm ml-2 mt-0.5 text-textDark italic">
                        <p>{props.reviewer_description ? `"${props.reviewer_description}"` : 'No review description'}</p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export const ReviewAll = (props) => {
    const dispatch = useDispatch();
    const [reviews, setReviews] = useState([]);
    const [page, setPage] = useState(1);
    const [numReviewsToShow, setNumReviewsToShow] = useState(10);
    const [visible, setVisible] = useState(false);
    let { id } = useParams();
    id = id ? parseInt(id.split('-')[2], 10) : localStorage.getItem("id")
    console.log(id);
    const handleIndexNormal = () => {
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        axios
            .get(`${process.env.REACT_APP_SERVER_PATH}/user_reviews/${id}?limit=10&page=${page}`)
            .then((response) => {
                setReviews(response.data);
                setLoading(false);
                dispatch(zIndexRaise())
            })
            .catch((e) => {
                console.log("Error in Reviews Api", e);
            });
    }, []);

    const handleSeeMoreClick = () => {

        const newPage = page + 1;
        axios
            .get(`${process.env.REACT_APP_SERVER_PATH}/user_reviews/${id}?limit=10&page=${newPage}`)
            .then((response) => {
                const newReviews = response.data;
                const oldreviews = reviews.concat(newReviews);
                setReviews(oldreviews);
                setPage(newPage);
                if (newReviews.length < 10) {
                    document.getElementById("see-more-button").disabled = true;
                    setVisible(true);
                }
            })
            .catch((e) => {
                console.log("Error in Reviews Api", e);
            });
    };

    const [loading, setLoading] = useState(true);
    const show = useSelector((state) => state.review.showReviews);
    console.log(show);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 2000); // simulate loading time for demonstration purposes only
    // }, [show]);

    // if (loading) {
    //     return (
    //         <div className="overlay">
    //             <div className="loader-container">
    //                 <Loader
    //                     type="TailSpin"
    //                     color={Colors.blue}
    //                     height={200}
    //                     width={200}
    //                 // timeout={5000} //5 secs
    //                 />
    //             </div>
    //         </div>);
    // }
    // else {
    // <div className={`${loading ? props.overlay : ""}`}></div>    

    return (
        <>
            <div className={props.modal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className={`${props.overlay}`}></div>
                {loading ? (
                    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                        <Loader type="TailSpin" color={Colors.blue} height={200} width={200} />
                    </div>
                ) : (<div className='w-11/12 md:w-2/5 z-50 bg-white rounded-lg p-4 sm:px-4 md:px-12'>
                    <div className='mb-4 flex justify-between items-center'>
                        <p className='text-2xl font-bold'>Reviews</p>
                        <span className='bg-bgGray rounded-full p-2 hover:bg-bgAppColor hover:text-white cursor-pointer' onClick={() => {
                            dispatch(zIndexNormal());
                            props.toggle();
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div >
                    {
                        reviews.length > 0 ? <div className="h-96 overflow-auto">
                            {reviews.map(r => {
                                return <ReviewAllComponent
                                    reviewer_image={r.reviewer_image}
                                    reviewer_name={r.reviewer_name}
                                    reviewer_points={r.rating}
                                    reviewer_description={r.feedback}
                                    reviewerData={r.reviewer}
                                    arrLength={props.reviews.length} />
                            })}
                            {reviews.length > 0 && (
                                <>
                                    {
                                        !visible ? (<div className="flex justify-center" >
                                            <button id="see-more-button" className="py-2 px-4 rounded-lg bg-bgAppColor text-white font-semibold hover:bg-opacity-80 focus:outline-none" onClick={handleSeeMoreClick}>
                                                See more
                                            </button>
                                        </div>) : null}
                                </>
                            )}
                        </div> : <div>
                            <p className='text-center text-2xl text-textFullDark font-bold'>No reviews</p>
                        </div>
                    }
                </div >)}

            </div >
        </>
    )
};

const ReviewAllComponent = (props) => {
    return (
        <div className='w-full bg-bgGray  mb-2 flex flex-col rounded-xl p-4 '>
            <div className='flex'>
                <div className='w-1/12 flex items-center justify-center'>
                    <div className='w-12 h-12 bg-bgAppColor text-white flex items-center justify-center  rounded-full '>
                        <p className='font-bold w-12 h-12  items-center justify-center flex'>{props.reviewerData?.first_name ? (props.reviewerData?.first_name).charAt(0) : 'A'}</p>
                    </div>
                </div>
                <div className='ml-4 flex items-center justify-center'>
                    <div>
                        <p className='text-textFullDark'>{props.reviewerData?.first_name ? `${(props.reviewerData?.first_name)}` : 'A'}</p>
                    </div>
                </div>
            </div>
            <div>
                <Ratings value={+props.reviewer_points} />
            </div>
            <div className='p-2 flex w-full items-start'>
                <div className="text-sm text-textDark italic">
                    <p>{props.reviewer_description ? `"${props.reviewer_description}"` : 'No review description'}</p>
                </div>
            </div>
        </div>
    )
}
