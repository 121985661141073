import React from 'react'
import Hero from './Hero'
import Navbar from '../Navbar/Navbar'
import Reviews from '../Courses/components/Reviews'
import Footer from '../Footer/Footer'
const PEB = () => {
    return (
        <div>
            <Navbar />
            <Hero />

            <Footer />
        </div>
    )
}

export default PEB