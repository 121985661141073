import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RightArrow from "../Arrows/RightArrow";
import LeftArrow from "../Arrows/LeftArrow";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import './Category.css';
import { useDispatch, useSelector } from "react-redux";
import { disableScroll, enableScroll } from "../Redux/reducers/Scroll/Scroll";

const Catagories = (props) => {
    const dispatch = useDispatch();
    let Ref = useRef(null)
    const scrollingEnabled = useSelector((state) => state.scroll.scrollingEnabled);
    const [colors] = useState(['#EDF7FE', '#F2F1F0', '#FDF8F0'])
    const [viewAll, setViewAll] = useState(false)
    const modalRef = useRef(null);

    const [settings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: props.len <= 4 ? props.len : 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    })

    //#region disabling scroll
    useEffect(() => {
        if (!scrollingEnabled) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [scrollingEnabled]);
    //#endregion


    const toggleViewAll = () => {
        setViewAll(!viewAll);
        if (viewAll) {
          dispatch(enableScroll());
        } else {
          dispatch(disableScroll());
        }
    }
    const SlickNext = () => {
        Ref.current.slickNext()
    }

    const SlickPrev = () => {
        Ref.current.slickPrev()
    }
    let counter = 0
    return (
        <div>
            {viewAll && <ViewAllIndustry
                catagories={props?.catagories}
                colors={colors}
                toggle={toggleViewAll}
                overlay="overlay"
                modal="modal"
                modalValue={viewAll}
            />}

            <div className="md:container mx-auto my-4 sm:px-2 xl:px-16 py-2 flex flex-row justify-between items-center">
                <h1 className="text-textFullDark text-2xl font-bold">
                    {props.name}
                </h1>
                <div className="text-md flex flex-row items-center text-textDark">
                    <button onClick={toggleViewAll}>View All</button>
                    <LeftArrow click={SlickPrev} isDisabled={props?.len <= 4} />
                    <RightArrow click={SlickNext} isDisabled={props?.len <= 4} />
                </div>
            </div>
            <div className="md:container mx-auto sm:px-2 md:pl-16 md:pr-12">
                <Slider {...settings} ref={Ref}>
                    {
                        props?.catagories && props?.catagories?.length && props?.catagories.map((p, i) => {
                            let counter2 = counter
                            if (counter === 2) {
                                counter = 0
                            } else {
                                counter += 1
                            }
                            return <Link key={i} to={`/mentors/${p.slug}`} state={{ id: p.id, slug: p.slug }}>
                                <Industry
                                    image={colors[counter2]}
                                    label={p?.name}
                                    count={p?.UserCount}
                                    icon={p?.industry_image}
                                    usersList ={p?.user_images_array}
                                     />
                                   
                            </Link>
                        })
                    }
                </Slider>
            </div>
        </div>
    );
}


export default Catagories;

const Industry = ({ image, label, count, icon,usersList }) => {
   
    return (
        <div className={`mr-2 md:mr-3 sm:p-2 md:py-4  md:px-2 rounded-xl text-gray-600 hover:shadow-lg overflow-hidden h-52 flex flex-col justify-between items-center`} 
        style={{
            backgroundColor: image,
        }}
         >
             
            <div className="p-1 text-left  w-full">
            <h1 className="font-bold sm:text-lg md:w-2/3 sm:w-full md:text-xl">{label}</h1>
            <img src="/landing-page/Arrow 2 (2).png" className="mt-3" alt="" />
            </div>
            <div className="flex justify-between items-center w-full px-2">
            <p className="sm:text-sm md:text-lg w-1/2">{count} mentors</p>
            <div class="flex -space-x-3">
            <img class="sm:h-7 sm:w-7 md:w-10 md:h-10 rounded-full border-2 border-white object-cover" src={usersList ? usersList[0] : 'avatar.png'} alt="" />
            <img class="sm:h-7 sm:w-7 md:w-10 md:h-10 rounded-full border-2 border-white object-cover" src={usersList ? usersList[1] : 'avatar.png'} alt="" />
            <img class="sm:h-7 sm:w-7 md:w-10 md:h-10 rounded-full border-2 border-white object-cover" src={usersList ? usersList[2] : 'avatar.png'} alt="" />
            <div class="flex justify-center items-center sm:h-7 sm:w-7 md:w-10 md:h-10 sm:text-xs md:text-sm font-medium text-white bg-gray-700 rounded-full border-2 border-white hover:bg-gray-600 dark:border-gray-800" >+{count - 3}</div>
            </div>
            </div>

        <div class="w-64 md:w-64 md:ml-24 mt-8 h-48 absolute sm:w-48" style={{backgroundImage:"url('/landing-page/logo-transparent.png')"}}>            </div>
        </div>
    );
}

const ViewAllIndustry = (props) => {
    console.log(props);
    const [colors] = useState(['#EDF7FE', '#F2F1F0', '#FDF8F0'])
    let counter = 0
    return (
      <div
        className={props.modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow:"hidden",
        }}
      >
        <div className={props.overlay} onClick={props.toggle}></div>
        <div className="w-11/12 h-3/4 md:h-auto rounded-lg bg-white z-50 overflow-y-auto flex text-center p-5 md:p-4">
          <div className="w-full flex flex-wrap items-start justify-start">
            {props.catagories.map((p, i) => {
              let counter2 = counter;
              if (counter === 2) {
                counter = 0;
              } else {
                counter += 1;
              }
              return (
                <Link
                  key={i}
                  to={`/mentors/${p.slug}`}
                  className="p-2 mt-3 sm:w-6/12 md:w-3/12"
                  state={{ id: p.id }}
                >
                  <div
                    className={`sm:p-2 md:py-4 w-full md:px-2 rounded-xl text-gray-600 shadow-lg overflow-hidden h-52 flex flex-col justify-between items-center`}
                    style={{ backgroundColor: colors[counter2] }}
                  >
                    <div className="py-2 pl-3 text-left  w-full">
                      <p className="font-bold md:w-2/3 sm:w-full md:text-xl lg:text-sm sm:text-md">
                        {p.name}
                      </p>
                      {/* <img src="/landing-page/right-arrow.png" className="mt-3" alt="" /> */}
                      <img
                        src="/landing-page/Arrow 2 (2).png"
                        className="mt-3"
                        alt=""
                      />
                    </div>
                    <div className="flex justify-between items-center w-full px-2">
                      <p className="sm:text-xs md:text-lg w-1/2">
                        {p.UserCount} mentors
                      </p>
                      <div class="flex -space-x-3">
                        <img
                          class="sm:h-6 sm:w-6 md:w-8 md:h-8 rounded-full border-2 border-white object-cover"
                          src={
                            p.user_images_array
                              ? p.user_images_array[0]
                              : "/avatar.png"
                          }
                          alt=""
                        />
                        <img
                          class="sm:h-6 sm:w-6 md:w-8 md:h-8 rounded-full border-2 border-white object-cover"
                          src={
                            p.user_images_array
                              ? p.user_images_array[1]
                              : "/avatar.png"
                          }
                          alt=""
                        />
                        <img
                          class="sm:h-6 sm:w-6 md:w-8 md:h-8 rounded-full border-2 border-white object-cover"
                          src={
                            p.user_images_array
                              ? p.user_images_array[2]
                              : "/avatar.png"
                          }
                          alt=""
                        />
                        <div class="flex justify-center items-center sm:h-6 sm:w-6 md:w-8 md:h-8 sm:text-xs md:text-sm font-medium text-white bg-gray-700 rounded-full border-2 border-white hover:bg-gray-600 dark:border-gray-800">
                          +{p.UserCount - 3}
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-64 md:w-64 md:ml-24 mt-8 h-48 absolute sm:w-48"
                      style={{
                        backgroundImage:
                          "url('/landing-page/logo-transparent.png')",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
}