import React,{useState } from "react";

const Cards = ({ data, index }) => {
  const [image, setImage] = useState(data.image);
  return (
    <div
      className={`w-full p-4 bg-white rounded-md hover:shadow-xl md:h-22 mb-2 md:mb-0`}
      onMouseEnter={() => {
        setImage(data.image1)
      }}
      onMouseLeave={() => {
        setImage(data.image)
      }}
    >
      <div className="w-16 h-16 bg-gray-50 rounded-sm">
        <img src={image} className="w-16 h-16" alt="img" />
      </div>
      <div className="mt-8">
        <p className="text-sm md:text-xl text-gray-800 w-full font-bold">
          {data.title}
        </p>
        <p className="text-sm md:text-xl text-gray-500 w-full mt-4">
          {data.description}
        </p>
      </div>
    </div>
  );
};

export default Cards;
