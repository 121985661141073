import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isLogin: false,
}

export const customerSlice = createSlice({
    name:"customer",
    initialState,
    reducers:{
        setLogin :(state)=>{
         state.isLogin = !state.isLogin;
        },
        setCustomerFalse :(state)=>{
            state.isLogin = false
        }
    },
    extraReducers:{
    }
})

export const { setLogin, setCustomerFalse } = customerSlice.actions;
export default customerSlice.reducer;