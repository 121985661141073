import React, { useEffect, useState } from 'react'
import { pricetoDecimal } from '../../../price';
import Ratings from '../../../Rating/Ratings'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner';
import Colors from '../../../Colors/Colors';
import { useSelector, useDispatch } from 'react-redux'
import { ReviewAll } from '../../../Profile/Reviews/Reviews';
import { toggleReviews } from '../../../Redux/reducers/review/reviewSlice' 
import axios from 'axios';
import { RatingHandler } from '../../../utils/Rating';
const Reviews = ({ user, setNavState, isLoading }) => {
    const [reviews, setReviews] = useState([])
    const dispatch = useDispatch()
    const show = useSelector(state => state.review.showReviews)
    let type = JSON.parse(localStorage.getItem('type'))
    let id = JSON.parse(localStorage.getItem('id'))
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/user_reviews/${id}`).then((response) => {
            setReviews(response.data)
        }).catch(e => {
            console.log("Error in Reviews Api", e);
        })
    }, [])
    const toggleShow = () => {
        // setShow(prevState => !prevState)
        // dispatch({ type: 'toggleReviews' })
        dispatch(toggleReviews())
    }
    return (
        <div className="bg-white px-4 py-7 rounded">
            {show && <ReviewAll
                toggle={toggleShow}
                overlay="overlay"
                modal="modal"
                modalcontent="modal-content"
                closeModal="close-modal"
                modalValue={show}
                reviews={reviews}
            />}
            {isLoading ? <div className='w-full flex items-center justify-center'>
                <Loader
                    type='Oval'
                    color={Colors.blue}
                    width={40}
                    height={40}
                />
            </div> : <div>
                <div className="flex w-full">
                    <div>
                        <div className="w-16 h-16 rounded-full border border-borderApp flex items-center justify-center">
                            <img src={type === 'professional' ? user.User?.profile_photo ? user.User?.profile_photo : ' /avatar.png' : user.profile_photo ? user.profile_photo : ' /avatar.png'}
                                alt="alon" className="w-16 h-16 rounded-full p-1 object-cover" />
                        </div>
                    </div>
                    <div className="flex flex-col mx-3">
                        <div className="flex items-center">
                            <p className="text-xl font-bold">{type === 'professional' ? `${user.User?.first_name} ${user.User?.last_name}`
                                : `${user.first_name} ${user.last_name}`}</p>
                            <Link to="profile">
                                <button className="h-4 w-4 ml-3 hover:text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="gray">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                </svg></button></Link>
                        </div>
                        <div className='cursor-pointer' onClick={toggleShow}>
                            <div className='flex items-center -my-1 cursor-pointer'>
                                {user.avgRating > 0 ? <div>
                                    {user.avgRating && <Ratings
                                        value={RatingHandler(user.avgRating)}
                                        size={20}
                                        activeColor="#ffd700"
                                        isHalf={true}
                                        edit={false}
                                    />}
                                </div> : <div onClick={toggleShow}>
                                    <Ratings
                                        value={0}
                                        size={20}
                                        activeColor="#ffd700"
                                        isHalf={true}
                                        edit={false}
                                    />
                                </div>}
                                &nbsp;&nbsp;<p className='text-textDark'>{user.avgRating ? (+user.avgRating).toFixed(2) : Math.trunc(0).toFixed(1)}</p>
                            </div>
                            <p className="text-xs text-textFullDark">{user.reviewCount ? user.reviewCount : 0} reviews</p>
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                    <div className="text-lg text-textDark">Balance</div>
                    <div className="text-2xl text-textFullDark">{type === 'professional' ? pricetoDecimal(user.User?.wallet) : pricetoDecimal(user.wallet)}</div>
                </div>
            </div>}
        </div>
    )
}

export default Reviews
