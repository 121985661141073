import React from "react";
import { Mixpanel } from "../../Mixpanel";

export default function OfferedBy() {
  return (
    <div className="md:container mx-auto my-6 sm:px-2 xl:px-16">
      <div
        className=" flex flex-col md:flex-row w-full rounded-lg  items-center lg:items-start"
        style={{
          backgroundImage: "url(/events/efinancial-banner-BG.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div class="flex flex-col md:flex-row px-5 py-5">

          <div className="hidden lg:flex w-full md:w-96 items-center justify-center mx-5 my-6">
            <img
              src="/events/efinancial-banner-img.svg"
              className=" object-cover w-full"
              alt=""
            />
          </div>

          <div class="flex flex-col items-start justify-between p-5 lg:ml-12">
            <p class="mt-2 text-lg font-semibold w-full text-textFullDark">
              We know how challenging it can be to grow your career in financial services and tech, that’s why we’re partnering with eFinancialCareers to get you the best roles in your chosen industries
            </p>

            <div className="flex w-full  mt-5 lg:hidden items-center justify-center ">
              <img
                src="/events/efinancial-banner-img.svg"
                className=" object-cover "
                alt=""
              />
            </div>

            <div className="flex flex-col mt-6  md:flex-row justify-between items-center w-full ">

              <div className="">
                <img src="/events/efc_logo.svg" className="w-full" alt="" />
              </div>
              <div>
                <button onClick={() => {
                  Mixpanel.track(`web: open user dashboard.`);
                }} className=" mt-6 lg:mt-0 ">
                  <a href='https://www.efinancialcareers.com' rel="noreferrer" className="rounded-lg px-10  md:px-16 py-3 text-white  font-bold bg-bgAppColor border-2 border-borderApp" target="_blank">
                    Find your next job
                  </a>
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
