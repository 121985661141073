import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Colors from '../../Colors/Colors'


const ProfileInterest = (props) => {
    console.log("🚀 ~ file: ProfileInterest.js:8 ~ ProfileInterest ~ props", props)
    const [isLoading, setLoading] = useState(false)
    const [industry, setIndustry] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState([])
    const [selectedSubInterests, setSelectedInterests] = useState([]);
    const [selectInput, setSelectInput] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [interest, setInterest] = useState("");
    const [options, setOptions] = React.useState([]);
    let loading = open && options.length === 0;

    const getAllInterests = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/interest?featured=1&limit=1000`).then((response) => {
            console.log(response.data);
            console.log("🚀 ~ file: SelectInterest.js:20 ~ axios.get ~ response.data", response.data)
            setIndustry(response.data)
        }).catch(e => {
            console.log("error in industry api");
        })
    }

    const getAllSelectedInterests = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/get-user-interes/${props?.id}`).then((response) => {
            const { Interests } = response.data;
            if (Interests.length > 0) {
                let selectedIndustryCopy = [...selectedIndustry]
                let industryCopy = [...industry]

                Interests.map((item) => {
                    selectedIndustryCopy.push(item.id);
                    // if (item.is_featured !== 1) {
                        industryCopy.push(item);
                    // }

                })
                setSelectedIndustry(selectedIndustryCopy)
                setSelectedInterests(industryCopy)

            }
        }).catch(e => {
            console.log("error in industry api");
        })
    }
    useEffect(() => {
        // getAllInterests();
        getAllSelectedInterests();
    }, [])









    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <div className="w-full bg-bgIndustryColor rounded-lg flex flex-wrap">
                {selectedSubInterests.length > 0 ? [...industry, ...selectedSubInterests].map((m, i) => {
                    return <> <button key={i} className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                        style={{
                            backgroundColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray, color: selectedIndustry.includes(m.id) ? 'white' : 'black',
                            borderColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray
                        }}
                    >{m.name}
                    </button>

                    </>

                }) :null}
            </div>
        </div>
    )
}

export default ProfileInterest
