import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
function Instructors() {
  const instructors = [
    {
      name: "Tobias Buck",
      firstName: "Tobias",
      lastName: "Buck",
      profileId: process.env.REACT_APP_TOBAIS_ID,
      designation: "Multi start Investor",
      company: "Olsam Group",
      noOfLearner: 1953,
      experience: "10+",
      avgRating: 4.5,
      noOfRating: "20+",
      img: "/5p.png"
    },
    {
      name: "Guy Ellis",
      firstName: "Guy",
      lastName: "Ellis",
      profileId: process.env.REACT_APP_GUY_ID,
      designation: "Partner",
      company: "Rockpool",
      noOfLearner: 353,
      experience: "10+",
      avgRating: "5.0",
      noOfRating: "10+",
      img: "/2p.png"

    },
  ];
  return (
    <div className="md:container mx-auto md:py-6 px-2 ">
      <div className=" text-textFullDark mt-5 px-4 mb-2 md:px-12">
        <h1 className="text-3xl font-bold ">Instructors</h1>
      </div>
      <div className=" flex flex-col md:flex-row justify-center items-center">
        {instructors.map((instructor, index) => (
          <Link className="flex bg-white rounded-lg  w-full lg:w-5/12 shadow-xl mb-5 md:mr-6" to={`/profile/${instructor.firstName.toLowerCase()}-${instructor.lastName.toLowerCase()}-${instructor.profileId}`} key={index}>
            <div className=" md:w-64 mr-5 rounded-lg">
              <img src={instructor.img} className="h-full object-cover" alt="" />
              <span className="flex mid:hidden left-14 absolute -mt-8 items-center bg-bgDark rounded-full px-2">

                <p className="text-xs text-white font-bold">{instructor.avgRating}</p>

                <ReactStars
                  value={5}
                  size={15}
                  edit={false}
                  activeColor="#ffd700"
                  count={1}
                />
                <span className="text-xs text-gray-100">{instructor.noOfRating}</span>
              </span>
            </div>
            <div class="w-full p-3 md:p-4">
              <div className="flex w-full items-center justify-between">
                <h1 class="text-xl font-bold text-gray-800 dark:text-white">
                  {instructor.name}
                </h1>

                <span className="hidden mid:flex items-center bg-bgDark rounded-full px-2">
                  <p className="text-xs text-white font-bold">{instructor.avgRating}</p>

                  <ReactStars
                    value={5}
                    size={15}
                    edit={false}
                    activeColor="#ffd700"
                    count={1}
                  />
                  <span className="text-xs text-gray-100">{instructor.noOfRating}</span>
                </span>
              </div>

              <div className="my-2">
                <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  {instructor.designation}
                </p>
                <p class=" text-sm text-gray-600 dark:text-gray-400">
                  {instructor.company}
                </p>
              </div>
              <div className="mt-4 flex items-center ">
                <img src="/events/group-users.png" alt="" />
                <p class="ml-2 text-sm text-gray-600 flex dark:text-gray-400">
                  <p className="font-bold mr-1">{instructor.noOfLearner}</p> Learners
                </p>
              </div>
              <div className="mt-3 flex">
                <img src="/events/experience.png" className="max-h-10" alt="" />
                <p class="ml-2 text-sm text-gray-600 flex truncate text-ellipsis  dark:text-gray-400">
                  <p className="font-bold mr-1">{instructor.experience}</p> years experience
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Instructors;
