import ProfileBio from './ProfileBio/ProfileBio'
import ProfileExperience from './ProfileExperience/ProfileExperience'
import ProfileEducation from './ProfileEducation/ProfileEducation'
import { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import { Mixpanel } from '../../../Mixpanel';
import { CompanyTitle } from '../../../settings'


const Profile = (props) => {
    const navState = 0;
    const [navStateProfile, setNavStateProfile] = useState(localStorage.getItem('navStateProfile') ? +localStorage.getItem('navStateProfile') : 0)
    const toggleGeneral = () => {
        localStorage.setItem('navStateProfile', 0)
        setNavStateProfile(0)
    }
    const toggleEducation = () => {
        localStorage.setItem('navStateProfile', 1)
        setNavStateProfile(1)
    }
    const toggleExperience = () => {
        localStorage.setItem('navStateProfile', 2)
        setNavStateProfile(2)
    }
    useEffect(() => {
        return () => {
            localStorage.setItem('navStateProfile', 0)
        }
    }, [navStateProfile])
    let ComponentToRender = null
    Mixpanel.track(`web: open profile settings.`);

    switch (navState) {
        case 0:
            ComponentToRender = <ProfileBio user={props.user} />
            break;
        case 1:
            ComponentToRender = (<div>
                <ProfileEducation user={props.user} />
            </div>
            )
            break;
        case 2:
            ComponentToRender = (<div>
                <ProfileExperience user={props.user} />
            </div>
            )
            break;
        default:
            return null
    }


    return (
        <div className="bg-white rounded pt-1 pb-4 mb-6">
            <Helmet>
                <title>Profile | Dashboard | {CompanyTitle}</title>
                <meta name={`Profile | Dashboard | ${CompanyTitle}`} content={`Profile | Dashboard | ${CompanyTitle}`} />
            </Helmet>
            <div>
                <div className="mx-6 my-6 flex">

                    <Link to=''>
                        <button className={`px-4 py-2 bg-bgAppColor rounded-lg text-sm ${navStateProfile === 0 ? 'bg-bgAppColor' : 'bg-bgGray'} ${navStateProfile === 0 ? 'text-white' : 'text-black'}`}
                            onClick={toggleGeneral}>General</button>
                    </Link>
                    <Link to='education'>
                        <button className={`px-4 py-2 bg-bgAppColor rounded-lg mx-4 text-sm ${navStateProfile === 1 ? 'bg-bgAppColor' : 'bg-bgGray'} ${navStateProfile === 1 ? 'text-white' : 'text-black'}`}
                            onClick={toggleEducation}>Education</button>
                    </Link>
                    <Link to='experience'>
                        <button className={`px-4 py-2 bg-bgAppColor rounded-lg text-sm ${navStateProfile === 2 ? 'bg-bgAppColor' : 'bg-bgGray'} ${navStateProfile === 2 ? 'text-white' : 'text-black'}`}
                            onClick={toggleExperience}>Experience</button></Link>

                </div>
                <div>
                    <Routes>
                        <Route path='/' element={<ProfileBio user={props.user} />} />
                        <Route path='education/*' element={<ProfileEducation user={props.user} />} />
                        <Route path='experience/*' element={<ProfileExperience user={props.user} />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Profile
