import axios from "axios";
import React, { useState } from "react";
import Colors from "../Colors/Colors";
import jwt_decode from "jwt-decode";
import { Mixpanel } from "../Mixpanel";
import moment from "moment";

const EducationForm = (props) => {
  localStorage.setItem("navState", 7);
  const [schoolName, setSchoolName] = useState("");
  const [degreeName, setDegreeName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [earlierDateError, setEarlierDateError] = useState(false)
 const [type, setType] = useState("text");
 const [types, setTypes] = useState("text");
  const AddEducation = () => {
    // check if the date is not greater than today date
    if( 
      moment(startDate).startOf('month').diff(moment(new Date().toISOString().split("T")[0]).startOf('month'), 'month') > 0 
      && 
      moment(endDate).startOf('month').diff(moment(new Date().toISOString().split("T")[0]).startOf('month'), 'month') > 0){
      setEarlierDateError(true)
      return;
  } 
    const Data = {
      school: schoolName,
      degree: degreeName,
      field_of_study: subjectName,
      user_id: jwt_decode(localStorage.getItem("customer_signup_token")).id,
      start_date: startDate,
      description: "hello",
      end_date: endDate,
      status: "",
    };
    axios
      .post(`${process.env.REACT_APP_SERVER_PATH}/education`, Data)
      .then((response) => {
        Mixpanel.track(`web: Add Education.`, Data);
        props.setNavState(props.navState + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleStartDate = (e) => {
    setStartDate(new Date(e.target.value));
    // setStartDate(moment(e.target.value).format("MM YYYY"));
  };
  const handleEndDate = (e) => {
    setEndDate(new Date(e.target.value));
    // setEndDate(moment(e.target.value).format("MM YYYY"));
  };
  return (
    <div className="font-bold w-full text-textFullDark">
      <div className="flex relative w-full items-center justify-center">
        <div className="-mt-2">
          <button
            className="absolute left-0"
            onClick={() => {
              props.setNavState(props.navState - 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />{" "}
            </svg>{" "}
          </button>
        </div>

        <p className="text-2xl font-bold mt-3 text-textFullDark">
          Add Education
        </p>
      </div>

      <div className="mb-4 mt-4">
        <label
          className="block text-textFullDark text-sm mb-2"
          for="schoolName"
        >
          School
        </label>
        <input
          className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
          id="schoolName"
          type="text"
          placeholder="School"
          value={schoolName}
          onChange={(e) => {
            setSchoolName(e.target.value);
          }}
        />
      </div>
      <div className="mb-4">
        <label className="block text-textFullDark text-sm mb-2" for="degree">
          Degree
        </label>
        <input
          className="text-xs border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
          id="degree"
          type="text"
          placeholder="Degree"
          value={degreeName}
          onChange={(e) => {
            setDegreeName(e.target.value);
          }}
        />
      </div>
      <div className="mb-4">
        <label className="block text-textFullDark text-xs mb-2" for="subject">
          Subject
        </label>
        <input
          className="text-xs border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
          id="job"
          type="text"
          placeholder="Subject"
          value={subjectName}
          onChange={(e) => {
            setSubjectName(e.target.value);
          }}
        />
      </div>
      <div className="flex w-full justify-between">
        <div className="w-1/2">
          <label
            className="block text-textFullDark text-xs mb-2"
            for="firstName"
          >
            Start Date
          </label>
          <input
            className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
            id="firstName"
            type={types}
            max={new Date().toISOString().split("T")[1]}
            placeholder="MM/YY"
            onChange={handleStartDate}
            onFocus={() => setTypes("month")}
            onBlur={() => setTypes("text")}
          />
          {earlierDateError && (
            <p className="text-sm text-red-500 my-1">
              Date should be today or earlier
            </p>
          )}
        </div>
        <div className="ml-2 w-1/2">
          <label
            className="block text-textFullDark text-xs mb-2"
            for="firstName"
          >
            End Date
          </label>

          <input
            className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
            id="firstName"
            type={types}
            max={new Date().toISOString().split("T")[0]}
            onChange={handleEndDate}
            placeholder="MM/YY"
            onFocus={() => setTypes("month")}
            onBlur={() => setTypes("text")}
          />
        </div>
      </div>
      <button
        className="text-white w-full py-2 mt-4 rounded-lg"
        style={{ backgroundColor: Colors.blue }}
        onClick={AddEducation}
      >
        Save
      </button>
    </div>
  );
};

export default EducationForm;
