import React, { useState } from "react";

export default function Syllabus() {
  
  const [parts] = useState([
    {
      sNo: "Part 01",
      partLabel: "An Introduction to Private Equity",
      topics : [
        "What is Private Equity?",
        "How do Private Equity funds make money?",
        "Who are the investors?",
        "Types of Private Equity funds",
        "Private Equity landscape"
      ]
    },
    {
      sNo: "Part 02",
      partLabel: "Private Equity Investing",
      topics : [
        "Finding target companies",
        "Private company valuation",
        "Deal structuring",
        "Private Equity returns"
      ]
    },
    {
      sNo: "Part 03",
      partLabel: "Working in Private Equity",
      topics : [
        "Investment process",
        "Private Equity Associate",
        "Private Equity glossary",
        "Private Equity incentives ",
        "Is Private Equity for you?",
        "Further reading"
      ]
    }
  ])

  const [topics] = useState([
    {
      SNo: "01",
      title: "What is Private Equity and Venture Capital?",
    },
    {
      SNo: "02",
      title: "Why companies need Private Equity and Venture Capital?",
    },
    {
      SNo: "03",
      title: "Private Equity Clusters: Through the fund’s life cycle",
    },
    {
      SNo: "04",
      title: "Seed, Startup, and early stage financing",
    },
  ]);

  const [topic2] = useState([
    {
      SNo: "05",
      title: "Expansion Financing",
    },
    
    {
      SNo: "06",
      title: "Replacement Financing",
    },

    {
      SNo: "07",
      title: "Vulture Financing",
    },
  ])
  return (
      <div className="md:container mx-auto px-2 ">
        <div className="md:container  mt-6 px-2  text-textFullDark  py-10  md:px-10  rounded-lg ">
        <div className=" text-textFullDark my-4">
          <h1 className="text-3xl mx-2 font-bold ">Syllabus</h1>
        </div>

           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-start gap-6 ">
            
           {
            parts.map((part, index)=>(
            <div key={index} className="bg-bgGray border-2 border-bgAppColor p-10 rounded-lg w-full h-full">
                  <h2 className="text-4xl font-bold text-bgAppColor">{part.sNo}</h2>
                  <h3 className="text-2xl mt-1">{part.partLabel}</h3>
                  <ul className="max-w-md mt-5 ml-5 space-y-2.5  mx-1 list-disc list-outside dark:text-gray-400">
                    {
                      part.topics.map((topic, index)=>(
                        <li className="text-base" key={index}>{topic}</li>
                      ))
                    }
                  </ul>

            </div>
            ))
           }

           </div>

        <div className="flex items-end mt-5 justify-end">
              <img
                src={"/events/timer-icon.png"}
                alt=""
                className="w-8 h-8  object-cover "
              />
            <p className="mx-2 text-lg text-textFullDark font-bold">
            3 Hours to complete
            </p>
        </div>
        </div>
        </div>
  );
}
