import React from 'react'

const RightArrow = (props) => {
    return (
        <button className={`hover:bg-bgGray border rounded-full  border-borderColorDark ${props.isDisabled ? "opacity-30" :""} `} onClick={props?.click} disabled={props.disabled}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
        </button>
    )
}

export default RightArrow
