import React, { Fragment } from "react";
import IndustrySkeleton_landingPage from "../Skeletons/IndustryCardsSkeleton_landingPage";
import Gifts from "../Gifts/Gifts";

const giftCards = [
  {
    price: "£25",
    startPriceinNumber : "0",
    priceinNumber : "25",
  },
  {
    price: "£50",
    startPriceinNumber : "25",
    priceinNumber : "50",
  },
  {
    price: "£100",
    startPriceinNumber : "50",
    priceinNumber : "100",
  },
  {
    price: "£150",
    startPriceinNumber : "100",
    priceinNumber : "150",
  },
];

export const GiftCards = () => (
  <Fragment>
    {giftCards.length > 0 ? (
      <React.Fragment>
        <Gifts
          name="Mentors by price"
          catagories={giftCards}
          len={giftCards.length}
        />
      </React.Fragment>
    ) : (
      <div className="flex md:container mx-auto my-4 sm:px-2 md:pl-16 md:pr-12 ">
        {["1", "2", "3", "4"].map((event, index) => (
          <IndustrySkeleton_landingPage key={index} />
        ))}
      </div>
    )}
  </Fragment>
);
