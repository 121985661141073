import React, { useEffect, useState } from "react";
import { CompanyTitle } from "../settings";
import SignUpProcess from "../SignUpProcess/SignUpProcess";
import SignUpProcessOfCustomer from "../SignUpProcessOfCustomer/SignUpProcessOfCustomer";
import { HowItWorksReviews } from "./HowItWorks";
import Skeleton from "react-skeleton-loading"
const HowItWorksMain = () => {
  const [professionalSignUpFlow, setProfessionalSignUpFlow] = useState(false);
  const [customerSignUpFlow, setCustomerSignUpFlow] = useState(false);
  const toggleProfessionalSignUpFlow = () => {
    setProfessionalSignUpFlow(!professionalSignUpFlow);
  };
  const toggleCustomerSignUpFlow = () => {
    setCustomerSignUpFlow(!customerSignUpFlow);
    setProfessionalSignUpFlow(false);
  };

  return (
    <div>
      {professionalSignUpFlow && (
        <SignUpProcess
          toggle={toggleProfessionalSignUpFlow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={professionalSignUpFlow}
        />
      )}
      {customerSignUpFlow && (
        <SignUpProcessOfCustomer
          toggle={toggleCustomerSignUpFlow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={customerSignUpFlow}
          customerSignUpFlow={customerSignUpFlow}
          setCustomerSignUpFlow={setCustomerSignUpFlow}
          professionalSignUpFlow={professionalSignUpFlow}
          setProfessionalSignUpFlow={setProfessionalSignUpFlow}
        />
      )}
      <HowItWorksFourSteps />
      <HowITWorkGif />
      <HowItWorksIcons />
      <HowItWorksParagraphy />
      {/* Reviews Section */}
      <HowItWorksReviews />
      <HowItWorksFindMentor />
    </div>
  );
};

export default HowItWorksMain;

export const HowITWorkGif = () => {
  return (
    // <></>
    <div className="container mx-auto justify-center">
      <div className="sm:p-4 md:p-16" style={{ backgroundColor: "#F7F9FD" }}>
        {/* <img src="/how-it-works/ezgif.com-gif-maker.gif" alt="how it work gif" className=" w-3/4 self-center" /> */}
        <video
          preload="metadata"
          className="w-full"
          controls={false}
          loop
          autoPlay
          muted
        >
          <source
            src={`https://cp-assets-pub.s3.eu-west-2.amazonaws.com/New_VIdeo_2.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export const HowItWorksFourSteps = () => {
  const [count, setCount] = useState(0);
  const [images] = useState([
    "/how1.png",
    "/how2.png",
    "/how3.png",
    "/how4.png",
  ]);
  const [description] = useState([
    {
      icon: "/Number1.svg",
      heading: "Browse and select from dozens of mentors",
      title: "Search by name, industry, company name or job title",
    },
    {
      icon: "/Number2.svg",
      heading: "Tell your mentor what you would like help with",
      title:
        "It could be anything from interview tips, advice on training and development or simply a chat to expand your network",
    },
    {
      icon: "/Number.svg",
      heading: "Select a date and time that is easy for you",
      title:
        "Our intelligent calendar management system allows you to see when your mentor is available months in advance",
    },
    {
      icon: "/Number3.svg",
      heading: "1-1 personalised mentor session",
      title:
        "Use our website or in app video call system to speak to your mentor instantly",
    },
  ]);

  const getCount = () => {
    if (count === 3) {
      setTimeout(() => {
        setCount((prev) => prev * 0);
      }, 2000);
    } else {
      setTimeout(() => {
        setCount((prev) => prev + 1);
      }, 2000);
    }
  };
  useEffect(() => {
    getCount();
  }, [count]);
  return (
    <div className="sm:p-4 lg:p-16" style={{ backgroundColor: "#F7F9FD" }}>
      <div className="md:container md:mx-auto sm:p-4 lg:p-16 bg-white rounded">

        <div className="flex sm:flex-col md:flex-row md:items-center md:justify-center bg-white rounded">
          <div className="md:mr-6 w-full md:w-3/12">
            {count === 0 ? (
              <img src={images[0]} alt="iphone13" />
            ) : count === 1 ? (
              <img src={images[1]} alt="iphone13" />
            ) : count === 2 ? (
              <img src={images[2]} alt="iphone13" />
            ) : (
              <img src={images[3]} alt="iphone13" />
            )}
          </div>
          <div className="w-full md:w-6/12">
            <div className="flex sm:justify-center md:justify-start">
              <h1 className="sm:text-3xl md:text-5xl text-textFullDark mt-2 font-bold ml-4 mb-4 text-center md:text-left">
                How it works?
              </h1>
            </div>
            <div>
              {description.map((item, index) => {
                return (
                  <div
                    className={`sm:my-8 md:my-4 cursor-pointer p-4 ${count === index ? "bg-gray-700" : "bg-white"
                      } ${count === index ? "text-white" : "text-gray-700"
                      } flex rounded-3xl items-start`}
                  >
                    <img src={item.icon} alt="Number" />
                    <div className="ml-4">
                      <h1 className="font-bold text-xl">{item.heading}</h1>
                      <p className="text-sm">{item.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HowItWorksSkeleton = () => {
  return (
    <div className="sm:p-4 md:p-16" style={{ backgroundColor: "#F7F9FD" }}>
      <div className="md:container md:mx-auto sm:p-4 md:p-16 bg-white rounded">
        <div className="flex sm:flex-col md:flex-row md:items-center md:justify-center bg-white rounded">
          <div className="md:mr-6 w-full md:w-3/12">
            <Skeleton height={500} width={300} />
          </div>
          <div className="w-full md:w-6/12" style={{ marginLeft: 20 }}>
            <div className="flex sm:justify-center md:justify-start">
              <h1 className="sm:text-3xl md:text-5xl text-textFullDark mt-2 font-bold ml-4 mb-4 text-center md:text-left">
                <Skeleton height={60} width={400} />
              </h1>
            </div>
            <div>
              <div
                className={`sm:my-8 md:my-4 cursor-pointer p-4 bg-white text-gray-700 flex rounded-3xl items-start`}
              >
                <Skeleton height={40} width={40} />
                <div className="ml-4">
                  <h1 className="font-bold text-xl">
                    <Skeleton height={20} width={200} />
                  </h1>
                  <p className="text-sm">
                    <Skeleton height={15} width={300} />
                  </p>
                </div>
              </div>
              <div
                className={`sm:my-8 md:my-4 cursor-pointer p-4 bg-white text-gray-700 flex rounded-3xl items-start`}
              >
                <Skeleton height={40} width={40} />
                <div className="ml-4">
                  <h1 className="font-bold text-xl">
                    <Skeleton height={20} width={200} />
                  </h1>
                  <p className="text-sm">
                    <Skeleton height={15} width={300} />
                  </p>
                </div>
              </div>
              <div
                className={`sm:my-8 md:my-4 cursor-pointer p-4 bg-white text-gray-700 flex rounded-3xl items-start`}
              >
                <Skeleton height={40} width={40} />
                <div className="ml-4">
                  <h1 className="font-bold text-xl">
                    <Skeleton height={20} width={200} />
                  </h1>
                  <p className="text-sm">
                    <Skeleton height={15} width={300} />
                  </p>
                </div>
              </div>
              <div
                className={`sm:my-8 md:my-4 cursor-pointer p-4 bg-white text-gray-700 flex rounded-3xl items-start`}
              >
                <Skeleton height={40} width={40} />
                <div className="ml-4">
                  <h1 className="font-bold text-xl">
                    <Skeleton height={20} width={200} />
                  </h1>
                  <p className="text-sm">
                    <Skeleton height={15} width={300} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const HowItWorksIcons = () => {
  return (
    <div className="xl:container sm:p-4 md:p-16 md:mx-auto flex flex-col justify-center items-center">
      <h1 className="sm:text-3xl md:text-5xl text-textFullDark sm:leading-8 md:leading-none mb-4 font-extrabold sm:mt-4 md:mt-0">
        Why {CompanyTitle}?
      </h1>
      <div className="flex sm:flex-col md:flex-row sm:items-center md:items-start justify-between">
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center">
            <img
              src="/featured1.png"
              alt="featured1"
              className="sm:w-36 sm:h-36 md:w-24 md:h-24"
            />
            <h2 className="w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              Industry experienced mentors
            </h2>
          </div>
          <img src="/line1.png" alt="line 1" className="hidden md:block" />
        </div>
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center">
            <img
              src="/featured2.png"
              alt="featured1"
              className="sm:w-36 sm:h-36 md:w-24 md:h-24"
            />
            <h2 className="w-full md:w-44 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              Search and select from dozens of mentors
            </h2>
          </div>
          <img src="/line2.png" alt="line 1" className="hidden md:block" />
        </div>
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center">
            <img
              src="/featured3.png"
              alt="featured1"
              className="sm:w-36 sm:h-36 md:w-24 md:h-24"
            />
            <h2 className="w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              1-1 personalised mentoring sessions
            </h2>
          </div>
          <img src="/line3.png" alt="line 1" className="hidden md:block" />
        </div>
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center">
            <img
              src="/featured4.png"
              alt="featured1"
              className="sm:w-36 sm:h-36 md:w-24 md:h-24"
            />
            <h2 className="w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              Choose a time and date that's easy for you
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HowItWorksIconsSkeleton = () => {
  return (
    <div className="xl:container sm:p-4 md:p-16 md:mx-auto flex flex-col justify-center items-center">
      <h1 className="sm:text-3xl md:text-5xl text-textFullDark sm:leading-8 md:leading-none mb-4 font-extrabold sm:mt-4 md:mt-0">
        {<Skeleton width={250} height={50} />}
      </h1>
      <div className="flex sm:flex-col md:flex-row sm:items-center md:items-start justify-between">
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center" style={{ marginRight: 200, marginLeft: 200 }}>
            <Skeleton width={100} height={100} circle={true} />
            <h2 className="w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              {<Skeleton width={150} height={20} />}
            </h2>
          </div>
          {/* <Skeleton width={5} height={100} className="hidden md:block" /> */}
        </div>
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center" style={{ marginRight: 200 }}>
            <Skeleton width={100} height={100} circle={true} />
            <h2 className="w-full md:w-44 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              {<Skeleton width={150} height={20} />}
            </h2>
          </div>
          {/* <Skeleton width={5} height={100} className="hidden md:block" /> */}
        </div>
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center" style={{ marginRight: 200 }}>
            <Skeleton width={100} height={100} circle={true} />
            <h2 className="w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              {<Skeleton width={150} height={20} />}
            </h2>
          </div>
          {/* <Skeleton width={5} height={100} className="hidden md:block" /> */}
        </div>
        <div className="p-4 flex items-center">
          <div className="flex flex-col justify-center items-center" style={{ marginRight: 200 }}>
            <Skeleton width={100} height={100} circle={true} />
            <h2 className="w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center">
              {<Skeleton width={150} height={20} />}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};


export const HowItWorksParagraphy = () => {
  return (
    <div className="container px-4 md:px-16 mx-auto my-8 bg-white">
      <h2 className="text-xl italic text-textFullDark text-center">
        {CompanyTitle} mentor sessions are treated with utmost confidentiality.
        What you talk about with your mentors is kept private and not disclosed
        to anyone. Rest assured we have your best interest at heart
      </h2>
    </div>
  );
};

export const HowItWorksParagraphySkeleton = () => {
  return (
    <div className="container px-4 md:px-16 mx-auto my-8 bg-white">
      <h2 className="text-xl italic text-textFullDark text-center">
        <Skeleton width={1000} height={50} />
      </h2>
    </div>
  );
};

export const HowItWorksFindMentor = () => {
  return (
    <div className="sm:px-4 md:px-0 my-12">
      <div className="md:container mx-auto px-4 xl:px-16 flex sm:flex-col md:flex-row rounded-xl bg-bgAppColor md:h-64">
        <div className="sm:w-full md:w-1/2 text-white flex flex-col justify-center">
          <div>
            <h1 className="sm:text-lg md:text-3xl font-bold sm:mt-8">
              FIND A CAREER MENTOR NOW
            </h1>
            <button className="text-xs">Download App</button>
            <div className="flex my-3">
              <button className="bg-gray-800 hover:bg-black text-white px-5 py-2 w-36 rounded flex justify-center">
                <a
                  href="https://apps.apple.com/us/app/careerpaths/id1558302311"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="/appstore.png" alt="apple" />
                </a>
              </button>
              <button className="bg-gray-800 hover:bg-black text-white px-5 py-2 ml-2 rounded flex w-36">
                <a
                  href="https://play.google.com/store/apps/details?id=com.careerpaths.mobile"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="/googleplay.png" alt="apple" />
                </a>
              </button>
            </div>
            <button className="text-xs">Apply to be a member</button>
          </div>
        </div>
        <div className="sm:w-full md:w-1/2 ">
          <img src="/Mobile-Mockup.png" className="md:-mt-16 md:h-80" alt="" />
        </div>
      </div>
    </div>
  );
};

export const HowItWorksFindMentorSkeleton = () => {
  return (
    <div className="sm:px-4 md:px-0 my-12">
      <div className="md:container mx-auto px-4 xl:px-16 flex sm:flex-col md:flex-row rounded-xl bg-bgAppColor md:h-64">
        <div className="sm:w-full md:w-1/2 text-white flex flex-col justify-center">
          <div>
            <h1 className="sm:text-lg md:text-3xl font-bold sm:mt-8">
              <Skeleton />
            </h1>
            <button className="text-xs">              <Skeleton />
            </button>
            <div className="flex my-3">
              <button className="bg-gray-800 hover:bg-black text-white px-5 py-2 w-36 rounded flex justify-center">
                <Skeleton />
              </button>
              <button className="bg-gray-800 hover:bg-black text-white px-5 py-2 ml-2 rounded flex w-36">
                <Skeleton />
              </button>
            </div>
            <button className="text-xs">
              <Skeleton />
            </button>
          </div>
        </div>
        <div className="sm:w-full md:w-1/2 ">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
