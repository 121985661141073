import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import { Mixpanel } from '../../../../../Mixpanel';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";


const Experience = (props) => {
    const [isProfileFromCP, setIsProfileFromCP] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const [imgUrl, setImageUrl] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyNamePermanent, setCompanyNamePermanent] = useState('')
    // variable to check that company name is changed or not
    const [tempCompanyName, setTempCompanyName] = useState('') 
    
    const [jobTitle, setJobTitle] = useState('')
    const [location, setLocation] = useState('')
    const [startDateToShow, setStartDateToShow] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [earlierDateError, setEarlierDateError] = useState(false)

    const [endDateToShow, setEndDateToShow] = useState('')
    const [endDate, setEndDate] = useState(new Date())
    const [description, setDescription] = useState('')
    const [currentlyWorking, setCurrentlyWorking] = useState(true)
    const [fileInput, setFileInput] = useState()
    const [image, setImage] = useState(null)
    const [locationError, setLocationError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    const [companyError, setCompanyError] = useState(false)
    const [jobError, setJobError] = useState(false)

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  let loading = open && options.length === 0;

    const searchHandler = async (value) => {
        let active = true;
    
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_PATH}/company?search=${value}`
        );
    
        const items = response.data;
        if (!loading && items.length === 0) {
          return undefined;
        }
        if (active) {
          setOptions([...items]);
        }
    
        return () => {
          active = false;
        };
      };
    
      React.useEffect(() => {
        searchHandler(companyName);
      }, []);
    
      React.useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);
    
    

    const UploadImage = (id) => {
        const formDataExp = new FormData();
        if(!isProfileFromCP){
            formDataExp.append("file", image);
        }
        formDataExp.append("experience_id", id);
        if (image === null) {
            navigate('../../experience')
            setLoading(false)
        } else {
            axios.post(process.env.REACT_APP_SERVER_PATH + `/document/experience/`, formDataExp).then((response) => {
                navigate('../../experience')
                setLoading(false)
            }).catch(e => {
                console.log("error is from uploading");
            })
        }

    }

    const UploadImageOfCompany = (id) => {
        const formDataExp = new FormData();
        if(!isProfileFromCP){
            formDataExp.append("file", image);
        } 
        formDataExp.append("company_id", id);
        axios.post(process.env.REACT_APP_SERVER_PATH + `/document/company/`, formDataExp).then((response) => {
            navigate('../../experience')
            setLoading(false)
        }).catch(e => {
            console.log("error is from uploading");
        })
    }

    const UpdateExperience = () => {
        if (companyName === '' && companyName?.trim().length < 1) {
            setCompanyError(true)
        }
        if (jobTitle === '') {
            setJobError(true)
        }
        if (location === '') {
            setLocationError(true)
        }
        if (description === '') {
            setDescriptionError(true)
        }
        // check if the date is not greater than today date
        if(moment(startDate).startOf('month').diff(moment(new Date().toISOString().split("T")[0]).startOf('month'), 'month') > 0){
            setEarlierDateError(true)
            return;
        } 
        setLoading(true)
        const Data = {
            user_id: JSON.parse(localStorage.getItem("id")),
            company: companyName,
            job: jobTitle,
            title: jobTitle,
            employement_type: "full time",
            location: location,
            current_job: currentlyWorking ? '1' : '0',
            start_date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}`,
            end_date: currentlyWorking ? `${endDate.getFullYear()}-${endDate.getMonth() + 1}` : `${endDate.getFullYear()}-${endDate.getMonth() + 1}`,
            description: description,
            status: "",
            profile_photo:imgUrl
        }
        axios.put(`${process.env.REACT_APP_SERVER_PATH}/experience/${id}`, Data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
            }
        }).then((response) => {
            Mixpanel.track(`web: Update Experience.`, Data);
             
            if (imgUrl === '' && !isProfileFromCP) {
                // props.setNavState(0)
                setLoading(false)
                navigate('../../experience')
            } else {
                if(!isProfileFromCP){
                    UploadImage(response.data.id);
                    UploadImageOfCompany(response.data.company_id);
                }else{
                 navigate('../../experience')
                 setLoading(false)
                }
            }
        }).catch(e => {
            console.log(e)
        })
        // navigate('../../experience')
    }

    const handleImg = (event) => {
        if (event.target.files[0].type === 'image/jpeg'
            || event.target.files[0].type === 'image/png') {
            setImageUrl(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        } 
    }
    const handleStartDate = (e) => {
        setStartDate(new Date(e.target.value))
        setStartDateToShow(moment(new Date(e.target.value)).format('YYYY-MM-DD'))
    }
    const handleEndDate = (e) => {
        setEndDate(new Date(e.target.value))
        setEndDateToShow(moment(new Date(e.target.value)).format('YYYY-MM-DD'))
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/getExperience/${id}`).then((response) => {
            setImageUrl(response.data.message[0].Company?.profile_photo ? response.data.message[0].Company?.profile_photo : ' /experienceplaceholder.png')
            setStartDateToShow(moment(response.data.message[0].start_date).format('YYYY-MM-DD'))
            setEndDateToShow(moment(response.data.message[0].end_date).format('YYYY-MM-DD'))
            setStartDate(new Date(response.data.message[0].start_date))
            setEndDate(new Date(response.data.message[0].end_date))
            setDescription(response.data.message[0].description)
            setJobTitle(response.data.message[0].job)
            setLocation(response.data.message[0].location)
            setCompanyName(response.data.message[0].Company?.name ? response.data.message[0].Company?.name : "")
            setCompanyNamePermanent(response.data.message[0].Company?.name ? response.data.message[0].Company?.name : "")
            setTempCompanyName(response.data.message[0].Company?.name ? response.data.message[0].Company?.name : "")
            setCurrentlyWorking(response.data.message[0].current_job === '1' ? true : false)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    const DropdownWithButton = ({ children, ...other }) => (
        <Paper {...other}>
            { companyName?.trim() !== companyNamePermanent?.trim()  && 
            <div className="flex w-full items-center hover:bg-gray-300 bg-gray-50 cursor-pointer">
                <Button
              sx={{ width: "100%",textTransform: "none", padding:1, justifyContent: "flex-start" }}
              onMouseDown={(e) => {
                e.preventDefault();
                setIsProfileFromCP(false)
                setOpen(false);
                document.querySelector(`input[id=job]`).focus();
            }}
              >
                <img src='/educationplaceholder.png' alt="educationplaceholder" className="flex-shrink-0 h-6 w-6 rounded-full" />
                <p className="ml-3 block font-normal truncate">+ Add Company: {companyName}</p>
            </Button>
                </div>
            }
            {children}
        </Paper>
        );

    return (
        <div>
            <div className="w-full flex items-center justify-center flex-col">
                <input className="text-xs text-white ml-2" type="file" onChange=
                    {handleImg}
                    ref={fileinput => setFileInput(fileinput)}
                    style={{ display: 'none' }} />
                <img src={imgUrl ? imgUrl : ' /experienceplaceholder.png'} alt="images" className="w-16 h-16 rounded-full object-cover text-textFullDark" />
                {!isProfileFromCP && (companyName?.trim() !== companyNamePermanent?.trim()) ? <button className="mt-3" onClick={() => {
                    fileInput.click()
                }}>Add Logo</button> : null}
            </div>
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="company">
                    Company
                </label>
                <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name ?? option}
                options={options}
                freeSolo={true}
                PaperComponent={DropdownWithButton}
                value={{title:companyName, name:companyName}}
                renderOption={(props, option) => (
                    <div
                    {...props}
                    className="flex items-center p-2 hover:bg-gray-300 bg-gray-50 cursor-pointer"
                    >
                    <img
                        src={option?.profile_photo ? option?.profile_photo : '/educationplaceholder.png'}
                        alt={option?.name}
                        className="flex-shrink-0 h-6 w-6 rounded-full"
                    />
                    <span className="ml-3 block font-normal truncate">
                        {option?.title}
                        {option?.name}
                    </span>
                    </div>
                )}
                sx={{ alignItems: "center" }}
                clearOnBlur={false}
                onInputChange={(e, value) => {
                    setCompanyName(value);
                }}
                onChange={(e, obj) => {
                    if (obj) {
                   if(obj.name){
                    setImageUrl(obj.profile_photo);
                    setCompanyName(obj.name);
                    setCompanyNamePermanent(obj.name)                    
                    
                    }else{
                    setCompanyName(obj);
                    setCompanyNamePermanent(obj)
                    }
                    document.querySelector(`input[id=job]`).focus();
                    }
                }}
                onKeyDown={(e)=>{
                    if (e.key === 'Enter') {
                    setCompanyName(e.target.value);
                    setCompanyNamePermanent(e.target.value);
                    setIsProfileFromCP(false)
                  }
                  }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    id="company"
                    type="text"
                    placeholder="Company"
                    // value={companyName}
                    onChange={(e) => {
                        setIsProfileFromCP(true)
                        setCompanyName(e.target.value);
                        searchHandler(e.target.value);
                    }}
                      defaultValue={companyName}
                    InputProps={{
                        style: { height: 50 },
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
                />
            </div>
            {companyError && <p className='text-sm -mt-2 text-red-500'>Please enter company name</p>}
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="job">
                    Job
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="job" type="text" placeholder="Enter Job title"
                    value={jobTitle}
                    onChange={(e) => {
                        setJobError(false)
                        setJobTitle(e.target.value)
                    }} />
            </div>
            {jobError && <p className='text-sm -mt-2 text-red-500'>Please enter job title</p>}

            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="location">
                    Location
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="location" type="text" placeholder="Enter location name"
                    value={location}
                    onChange={(e) => {
                        setLocationError(false)
                        setLocation(e.target.value)
                    }} />
            </div>
            {locationError && <p className='text-sm -mt-2 text-red-500'>Please enter location</p>}

            <div className="flex w-full justify-between">
                <div className="w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="firstName">
                        Start Date
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="firstName" type="date" placeholder="Enter first name" value={startDateToShow} onChange={handleStartDate}
                    max={new Date().toISOString().split("T")[0]}
                    />
                    {earlierDateError && <p className='text-sm text-red-500 my-1'>Date should be today or earlier</p>}

                </div>
                {!currentlyWorking ? <div className="ml-2 w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="firstName">
                        End Date
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="firstName" type="date" placeholder="" value={endDateToShow} onChange={handleEndDate} 
                    max={new Date().toISOString().split("T")[0]}
                    />
                </div> : null}
            </div>
            <div className="flex mb-4 items-center">
                <input type="checkbox"
                    value={currentlyWorking}
                    checked={currentlyWorking}
                    onChange={() => { setCurrentlyWorking(!currentlyWorking) }} />
                <p className=" ml-3 text-sm text-textDark">Currently working here</p>
            </div>
            <div className="my-4">
                <label className="block text-textFullDark text-sm mb-2" for="location">
                    Description
                </label>
                <textarea maxlength="128" className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="description" type="text" placeholder="Description"
                    value={description}
                    name='description'
                    onChange={(e) => {
                    setDescriptionError(false)
                    setDescription(e.target.value)
                    }}
                    />
                <p className="text-sm text-green-500">{128 - description.length } characters remaining</p>
            
            </div>
            {descriptionError && <p className='text-sm -mt-2 text-red-500'>Please enter company name</p>}
            <button className={`${companyName?.trim() === '' || jobTitle.trim() === '' || location.trim() === '' || description.trim() === '' ? 'cursor-wait' : 'cursor-pointer'} bg-blue-500 text-white text-center w-full text-sm  rounded mt-3 py-3 flex items-center justify-center hover:bg-blue-300`}
                style={{ backgroundColor: companyName?.trim() === '' || jobTitle.trim() === '' || location.trim() === '' || description.trim() === '' ? 'grey' : '#00C4FF' }}
                disabled={companyName?.trim() === '' || jobTitle.trim() === '' || location.trim() === '' || description.trim() === ''}
                onClick={UpdateExperience}>Save{isLoading ? <span className='ml-4'>
                    <Loader
                        type="TailSpin"
                        color="white"
                        height={20}
                        width={20}
                    // timeout={5000} //5 secs
                    /></span> : null}</button>
        </div>
    )
}

export default Experience
