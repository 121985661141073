import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isProfessionalFlow: false,
  professionalIdOfModal: null,
  professionalNameOfModal: "",
};

export const professionalSlice = createSlice({
  name: "professional",
  initialState,
  reducers: {
    setProfessionalsToFalse: (state) => {
      state.isProfessionalFlow = false;
    },
    setProfessionalsToTrue: (state) => {
      state.isProfessionalFlow = true;
    },
    setProfessionalID: (state, action) => {
      state.professionalIdOfModal = action.payload.id;
    },
    setNameAndId: (state, action) => {
      state.professionalNameOfModal = action.payload.name;
      state.professionalIdOfModal = action.payload.id;
    },
  },
  extraReducers: {},
});

export const {
  setProfessionalsToFalse,
  setProfessionalsToTrue,
  setProfessionalID,
  setNameAndId,
} = professionalSlice.actions;
export default professionalSlice.reducer;
