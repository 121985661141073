import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Colors from "../../Colors/Colors";
import SideItem from "./SideItem/SideItem";
// import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import "./Sidebar.css";
class Sidebar extends Component {
  // const history = useHistory();
  state = {
    loading: false,
    isMobile: false, // Added isMobile state
    SideItemsUser: [
      // { name: "Dashboard", image: "/home copy2.png", link: "home" },
      {
        name: "Dashboard",
        imageUnclicked: "/home.png",
        imageClicked: "/home copy2.png",
        link: "home",
        isClicked: false,
      },

      // { name: "Profile", image: "/profile copy.png", link: "profile" },
      {
        name: "Profile",
        imageUnclicked: "/profile.png",
        imageClicked: "/profile copy.png",
        link: "profile",
        isClicked: false,
      },

      {
        name: "Private Equity Bundle",
        // image: "/peb copy.png",
        imageUnclicked: "/peb1.png",
        imageClicked: "/peb copy.png",
        link: "private",
        isClicked: false,
      },
      {
        name: "View Bookings",
        // image: "/bookings copy.png",
        imageUnclicked: "/bookings.png",
        imageClicked: "/bookings copy.png",
        link: "bookings",
        isClicked: false,
      },
      {
        name: "Payments",
        // image: "/payments copy.png",
        imageUnclicked: "/payments.png",
        imageClicked: "/payments copy.png",
        link: "payments",
        isClicked: false,
      },
      {
        name: "Notifications Settings",
        // image: "/notification copy.png",
        imageUnclicked: "/notification.png",
        imageClicked: "/notification copy.png",
        link: "notifications",
        isClicked: false,
      },
      {
        name: "Change Email",
        // image: "/messege copy.png",
        imageUnclicked: "/messege.png",
        imageClicked: "/messege copy.png",
        link: "changeemail",
        isClicked: false,
      },
      {
        name: "Change Password",
        // image: "/lock copy.png",
        imageUnclicked: "/lock.png",
        imageClicked: "/lock copy.png",
        link: "changepassword",
        isClicked: false,
      },
      // { name: 'Get £20 Referral', image: '/refer.png', link: 'get-referal-20' },
    ],
    SideItemsProfessional: [
      // { name: "Dashboard", image: "/home copy2.png", link: "home" },
      {
        name: "Dashboard",
        imageUnclicked: "/home.png",
        imageClicked: "/home copy2.png",
        link: "home",
        isClicked: false,
      },

      // { name: "Profile", image: "/profile copy.png", link: "profile" },
      {
        name: "Profile",
        imageUnclicked: "/profile.png",
        imageClicked: "/profile copy.png",
        link: "profile",
        isClicked: false,
      },
      {
        name: "Availability",
        image: "/availability copy.png",
        imageUnclicked: "/availability.png",
        imageClicked: "/availability copy.png",
        link: "availability",
        isClicked: false,
      },
      // { name: "View Bookings", image: "/bookings copy.png", link: "bookings" },
      {
        name: "View Bookings",
        // image: "/bookings copy.png",
        imageUnclicked: "/bookings.png",
        imageClicked: "/bookings copy.png",
        link: "bookings",
        isClicked: false,
      },
      // { name: "Payments", image: "/payments copy.png", link: "payments" },
      {
        name: "Payments",
        // image: "/payments copy.png",
        imageUnclicked: "/payments.png",
        imageClicked: "/payments copy.png",
        link: "payments",
        isClicked: false,
      },
      // {
      //   name: "Notifications Settings",
      //   image: "/notification copy.png",
      //   link: "notifications",
      // },
      {
        name: "Notifications Settings",
        // image: "/notification copy.png",
        imageUnclicked: "/notification.png",
        imageClicked: "/notification copy.png",
        link: "notifications",
        isClicked: false,
      },

      // { name: "Change Email", image: "/messege copy.png", link: "changeemail" },
      {
        name: "Change Email",
        // image: "/messege copy.png",
        imageUnclicked: "/messege.png",
        imageClicked: "/messege copy.png",
        link: "changeemail",
        isClicked: false,
      },
      // {
      //   name: "Change Password",
      //   image: "/lock copy.png",
      //   link: "changepassword",
      // },
      {
        name: "Change Password",
        // image: "/lock copy.png",
        imageUnclicked: "/lock.png",
        imageClicked: "/lock copy.png",
        link: "changepassword",
        isClicked: false,
      },
      {
        name: "Charity",
        // image: "/charity/Charity (3).png",
        imageUnclicked: "/charity/Charity (6) .png",
        imageClicked: "/charity/Charity (3).png",
        link: "charity",
        isClicked: false,
      },
      {
        name: "Profile Anonymity",
        // image: "/Profile Anonymity icon (1).png",
        imageUnclicked: "/anonymous(1).png",
        imageClicked: "/Profile Anonymity icon (1).png",
        link: "anonymous",
        isClicked: false,
      },
      {
        name: "Manage Stripe Account",
        image: "/stripe(2).png",
        link: "manage-stripe",
      },
    ],
    componentDidMount() {
      this.checkIsMobile();
      window.addEventListener("resize", this.checkIsMobile);

      // ... your existing componentDidMount logic
    },
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.checkIsMobile);
  }

  checkIsMobile = () => {
    const isMobile = window.innerWidth <= 768; // Adjust the threshold as needed
    this.setState({ isMobile });
  };

  componentDidMount() {
    this.checkIsMobile();
    window.addEventListener("resize", this.checkIsMobile);
    // ... your existing componentDidMount logic

   
    if (typeof window !== "undefined") {
      const { pathname } = window.location;
      console.log(
        "🚀 ~ file: Sidebar.js:185 ~ Sidebar ~ componentDidMount ~ pathname:",
        pathname
      );
      let type = JSON.parse(localStorage.getItem("type"));
      if (type == "professional") {
        this.setState((prevState) => {
          // this.state.SideItemsProfessional?.map((navItem, index) => {
          const updatedProfessionalItems = prevState.SideItemsProfessional.map(
            (navItem, index) => {
              const { link } = navItem;
              const navItemLink = "/dashboard/" + link;
              return {
                ...navItem,
                isClicked:
                  navItemLink === pathname
                    ? !navItem.isClicked
                    : navItem.isClicked,
              };
            }
          );
          // if (navItemLink == pathname) {
          //   navItem.isClicked = !navItem.isClicked;
          //   return navItem;
          // }
          // return navItem;
          return { SideItemsProfessional: updatedProfessionalItems };
        });
      } else {
        this.setState((prevState) => {
          const updatedUserItems = prevState.SideItemsUser.map(
            (navItem, index) => {
              // this.state.SideItemsUser?.map((navItem, index) => {
              const { link } = navItem;
              const navItemLink = "/dashboard/" + link;
              return {
                ...navItem,
                isClicked:
                  navItemLink === pathname
                    ? !navItem.isClicked
                    : navItem.isClicked,
              };
            }
          );
          return { SideItemsUser: updatedUserItems };
        });
      }
    }
  }

  handleClickImage = (i, type) => {
    this.setState({ loading: true });
    const stateKey =
      type === "customer" ? "SideItemsUser" : "SideItemsProfessional";
    const navItemList = [...this.state[stateKey]];

    navItemList.forEach((item, index) => {
      if (i === index) {
        item.isClicked = true;
      } else {
        item.isClicked = false;
      }
    });

    this.setState({ [stateKey]: navItemList, loading: false });
  };

  render() {
    let type = JSON.parse(localStorage.getItem("type"));
    console.log(this.props.setIsNav, this.props.isNav, "checking");
    return (
      <div>
        <div className="flex flex-col p-3 bg-white rounded">
          {!this.state.loading && type !== "professional"
            ? this.state.SideItemsUser.map((m, i) => {
                console.log("mm", m);
                return (
                  <NavLink
                    key={i}
                    to={`${m.link}`}
                    
                    onClick={(e) => {
                     
                      this.handleClickImage(i, type);
                      if (this.state.isMobile) {
                        this.props.setIsNav(!this.props.isNav);
                      }
                     
                    }}
                    className={(nav) => {
                      return nav.isActive
                        ? "bg-blue-100 rounded m-2 py-2"
                        : "flex items-center m-2 py-2 rounded hover:bg-blue-100 hover:text-textFullDark cursor-pointer";
                    }}
                  >
                    <SideItem
                      isNav={this.props.isNav}
                      setIsNav={this.props.setIsNav}
                      key={i}
                      name={m.name}
                      // image={m.image}
                      image={m.isClicked ? m.imageClicked : m.imageUnclicked}
                      setNavState={this.props.setNavState}
                      navState={this.props.navState}
                      index={i}
                      setBookingNavState={this.props.setBookingNavState}
                    />
                  </NavLink>
                );
              })
            : !this.state.loading &&
              this.state.SideItemsProfessional.map((m, i) => {
                console.log("mm", m);
                return (
                  <>
                    {m.link == "manage-stripe" ? (
                      <NavLink
                        onClick={() => {
                          //                   let url = `getLoginLink/${id}`;
                          // let headers = {
                          //   Authorization: 'Bearer ' + jwt_token,
                          // };
                          // this.handleClickImage(i, type);
                          axios
                            .get(
                              process.env.REACT_APP_SERVER_PATH +
                                `/getLoginLink/${localStorage.getItem("id")}`
                            )
                            .then((response) => {
                              const { data } = response;
                              try {
                                console.log(data, "data");
                                if (data.status) {
                                  window.open(
                                    `${data.object.url}`,
                                    "_blank",
                                    "noreferrer"
                                  );
                                } else {
                                  const pattern =
                                    /which is not an Express account/;
                                  const notRegisteredWithStripe =
                                    /Stripe: Argument \"account\" must be a string, but got: null/;
                                  if (pattern.test(data.msg)) {
                                    var answer = window.confirm(
                                      "Your are using lagacy account with limited capabilities.please complete our onboarding flow to enable this feature.\nAre you sure want to continue?"
                                    );
                                    if (answer) {
                                      //some code
                                      window.open(
                                        `https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_BASE_WEB}`,
                                        "_blank",
                                        "noreferrer"
                                      );
                                    } else {
                                      //some code
                                      console.log("Cancel Pressed");
                                    }
                                  } else if (
                                    notRegisteredWithStripe.test(data.msg)
                                  ) {
                                    window.open(
                                      `https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_BASE_WEB}`,
                                      "_blank",
                                      "noreferrer"
                                    );
                                  } else {
                                    alert(
                                      "Error occured.please try again later"
                                    );
                                  }
                                }
                              } catch (err) {
                                console.log(err);
                                alert("Error occured.please try again later");
                              }
                            })
                            .catch((e) => {
                              console.log("error is happend");
                            });
                        }}
                        className={(nav) => {
                          console.log(nav);

                          return nav.activebtn
                            ? "rounded m-2"
                            : "flex items-center m-2 py-2 rounded hover:bg-blue-100 hover:text-textFullDark cursor-pointer";
                        }}
                      >
                        <SideItem
                          isNav={this.props.isNav}
                          setIsNav={this.props.setIsNav}
                          key={i}
                          name={m.name}
                          image={m.image}
                          setNavState={this.props.setNavState}
                          navState={this.props.navState}
                          index={i}
                          setBookingNavState={this.props.setBookingNavState}
                        />
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`${m.link}`}
                        // onClick={() => this.handleClickImage(i)}
                        onClick={() =>{ this.handleClickImage(i, type)
                         if (this.state.isMobile) {
                        this.props.setIsNav(!this.props.isNav);
                      }
                        }
                      }
                        className={(nav) => {
                          console.log(nav);
                          return nav.isActive
                            ? "bg-blue-100 rounded m-2 py-2"
                            : "flex items-center m-2 py-2 rounded hover:bg-blue-100 hover:text-textFullDark cursor-pointer";
                        }}
                      >
                        <SideItem
                          isNav={this.props.isNav}
                          setIsNav={this.props.setIsNav}
                          key={i}
                          name={m.name}
                          // image={m.image}
                          image={
                            m.isClicked ? m.imageClicked : m.imageUnclicked
                          }
                          setNavState={this.props.setNavState}
                          navState={this.props.navState}
                          index={i}
                          setBookingNavState={this.props.setBookingNavState}
                        />
                      </NavLink>
                    )}
                  </>
                );
              })}
        </div>
        <div className="flex flex-col mt-6">
          <button
            className="bg-gray-800 hover:bg-black text-white py-3 mb-3 rounded flex justify-center"
            style={{ backgroundColor: Colors.apple }}
          >
            <a
              href="https://apps.apple.com/us/app/careerpaths/id1558302311"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/appstore.png" alt="apple" />
            </a>{" "}
          </button>
          <button
            className="bg-gray-800 hover:bg-black text-white py-3 rounded flex justify-center"
            style={{ backgroundColor: Colors.apple }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.careerpaths.mobile"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/googleplay.png" alt="apple" />
            </a>
          </button>
        </div>
      </div>
    );
  }
}

export default Sidebar;
