import React from "react";
import Skeleton, { SkeletonTheme } from "react-skeleton-loading"

const RoundedSkeleton = () => {
  return (
    <div className="sm:w-28 sm:h-28 md:w-48 md:h-48 flex items-center justify-center rounded-full mr-2">
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="rounded-full">
          <p>
            <Skeleton height={80} width={80} />
          </p>
        </div> 
      </SkeletonTheme>
    </div>
  );
}

export default RoundedSkeleton;
