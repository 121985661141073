import React from 'react'
import { CompanyTitle } from '../settings'

const CompanyMain = () => {
    return (
      <div>
        <div className="xl:container mx-auto">
          <div className="my-4 mx-2 rounded-xl p-4 md:p-16 flex flex-col md:flex-row justify-center md:my-16">
            <div className="w-full md:w-10/12 flex flex-col items-center justify-center">
              <h1
                className="text-black text-center font-bold text-4xl md:text-6xl xl:text-7xl"
                
                style={{ fontFamily: "Lato Bold" }}
              >
                We make impossible career connections possible
              </h1>
              <h2 className="text-textFullDark text-xl md:text-2xl mt-3 text-center w-full  md:w-10/12">
                {CompanyTitle} connects people to the mentors in the most
                exclusive and sought after professional industries
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CompanyMain