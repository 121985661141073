import React, { useState } from "react";
import Colors from "../Colors/Colors";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { CompanyTitle } from "../settings";
import axios from "axios";
import "./Form.css";
import "./ApplyToBecomeMentor.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApplyToBecomeMentorForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [linkedinUrl, setUrl] = useState("");
  const [bio, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fileError, setFileError] = useState(false);
  const handleSubmit = (e) => {
    setLoading(true);
    setError("");
    e.preventDefault();
    const data = { firstName, lastName, email, linkedinUrl, bio };
    console.log(linkedinUrl);
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(process.env.REACT_APP_SERVER_PATH + `/application-form`, data)
      .then((response) => {
        axios
          .patch(
            process.env.REACT_APP_SERVER_PATH +
              `/application-form/upload-cv/${response.data.id}`,
            formData
          )
          .then((response) => {
            console.log("CV uploaded successfully!");
          })
          .catch((e) => {
            console.log("Error while uploading CV:", e.response.data.error);
          });
        setLoading(false);
        toast("Form Submitted Successfully!");
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error while submitting form:", e.response.data.error);
        setError(e.response.data);
        toast("Enter Unique Email and Linkedin URL");
      });
  };

  return (
    <div className="">
      {loading ? (
        <div className="loader-container z-10">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <Navbar />
          <hr />
          <div
            className="container mx-auto px-4 flex flex-col justify-center py-6 md:py-40 z-20"
            style={{
              fontFamily: "Lato sans-serif",
              width: "70%",
            }}
          >
            <h1
              className="text-4xl md:text-7xl text-center heading"
              style={{
                fontFamily: "Lato Bold",
                width: "80%",
              }}
            >
              Apply to become a career mentor
              {/* Join the wait list to become a career mentor */}
            </h1>
            <form className="pt-6 font-bold" onSubmit={handleSubmit}>
              <div className="flex mb-4">
                <div className="w-1/2 mr-2">
                  <label
                    className="block text-textFullDark text-sm mb-2"
                    for="firstName"
                  >
                    First Name
                  </label>
                  <input
                    className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                    id="firstName"
                    type="text"
                    placeholder="Enter first name"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="w-1/2 ml-2">
                  <label
                    className="block text-textFullDark text-sm mb-2"
                    for="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  className="block text-textFullDark text-sm mb-2"
                  for="email"
                >
                  Email
                </label>
                <input
                  className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {error ? <p className="text-xs text-red-500">{error}</p> : null}
              </div>
              <div className="mb-6">
                <label
                  className="block text-textFullDark text-sm mb-2"
                  for="linkdin"
                >
                  Linkedin Profile URL
                </label>
                <input
                  className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                  id="linkedin"
                  type="text"
                  placeholder="Enter your URL"
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
                {error ? <p className="text-xs text-red-500">{error}</p> : null}
              </div>
              <div className="mb-6">
                <label
                  className="block text-textFullDark text-sm mb-2"
                  for="anything"
                >
                  Anything else we should know about you
                </label>
                <textarea
                  className="text-sm border rounded w-full h-28 border-borderGray py-3 px-3 text-textFullDark focus:outline-none resize-none text-left"
                  id="anything"
                  placeholder="Enter your message..."
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-textFullDark text-sm mb-2"
                  htmlFor="anything"
                >
                  Attach CV
                </label>
                <div className="relative border rounded bg-white py-2 px-3">
                  <label className="flex items-center text-gray-800 text-lg mb-1">
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          fillRule="evenodd"
                          d="M17.707 9.293a1 1 0 0 0-1.414-1.414L11 13.586V3a1 1 0 1 0-2 0v10.586l-5.293-5.293a1 1 0 1 0-1.414 1.414l7 7a1 1 0 0 0 1.414 0l7-7z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span>Upload your file</span>
                  </label>
                  <div
                    id="file-name"
                    className="block text-textFullDark text-sm"
                  >
                    Upload a doc, txt or pdf file
                  </div>
                  <input
                    className="opacity-0 absolute top-0 left-0 h-full w-full cursor-pointer"
                    type="file"
                    id="anything"
                    name="file"
                    accept=".doc,.docx,.pdf,.txt,.jpg,.jpeg,.png"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const allowedExtensions = /(pdf|txt|docx|doc)$/i; // Updated regular expression

                      if (
                        !selectedFile ||
                        !allowedExtensions.test(selectedFile.name)
                      ) {
                        setFile(null);
                        document.getElementById("file-name").innerHTML =
                          "Wrong file format";
                        document.getElementById("file-name").style.color =
                          "red";

                        setFileError(true);
                        e.target.value = ""; // Clear the file input tag
                      } else {
                        setFile(selectedFile);
                        document.getElementById("file-name").innerHTML =
                          selectedFile.name;
                        document.getElementById("file-name").style.color =
                          "green";
                        setFileError(false);
                      }
                    }}
                    required
                  />
                  {fileError && (
                    <p className="text-xs text-red-500 mt-2">
                      Please upload a PDF, DOC, DOCX, or TXT file
                    </p>
                  )}
                </div>
                <button className="bg-bgAppColor mt-6 text-white text-center flex justify-center mx-auto w-36 items-center text-lg font-bold rounded mt-3 py-3 hover:bg-blue-300">
                  <Notify />
                </button>
              </div>
            </form>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default ApplyToBecomeMentorForm;

function Notify() {
  return (
    <div>
      <button>Submit</button>
      <ToastContainer style={{ width: "400px" }} />
    </div>
  );
}
