import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import shapeBelow from "../Images/Shape.png"
import Global from "../Images/Global.png"
import Master from "../Images/Mastercard.png"
import Visa from "../Images/visa-logo.png"
import Paypal from "../Images/PayPal.png"
import American from "../Images/image 4.png"
import card from "../Images/cardIcon.png"
import DateIcon from "../Images/Date.png"
import lock from "../Images/lock.png"
import PEB from "../Images/pebImg.png"
import Footer from '../Footer/Footer'
import { useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./paymentForm";
import { autoBatchEnhancer } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from "react-redux";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";
import {
    setLogin,
    setCustomerFalse,
} from "../Redux/reducers/customer/customerSlice";
import { RedirectTo } from "../Redux/reducers/redirect/redirectSlice";
import { Mixpanel } from "../Mixpanel";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutPage = () => {
    let dispatch = useDispatch();

    const toggleLogin = () => {
        Mixpanel.track("Press login button web");
        // dispatch({ type: 'setLogin' })
        dispatch(setLogin());
        dispatch(RedirectTo({ url: window.location.pathname }))
        // dispatch({ type: 'setBannerToTrue' })
        dispatch(setBannerTrue());
        // setLogin(!login);
    };


    useEffect(() => {
        if (localStorage.getItem("cp_web_token") || localStorage.getItem("customer_signup_token") || localStorage.getItem("signup_token")) {
        } else {
            toggleLogin();
        }
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <div className="xl:container mx-auto lg:flex-row px-2  mt-2 md:mt-6">
                <div className='grid lg:grid-cols-7  sm:grid-cols-1 py-10 sm:px-4 lg:px-20'>
                    <Elements stripe={stripePromise}>
                        <PaymentForm />
                    </Elements>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default CheckoutPage