import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { CompanyTitle } from "../settings";

const Career = () => {
  return (
    <div>
      <Navbar />
      <hr />
      <div
        className="container mx-auto flex flex-col items-center justify-center py-6 md:py-40"
        style={{
          fontFamily: "Lato sans-serif",
        }}
      >
        <h1
          className="text-4xl md:text-7xl text-center"
          style={{
            fontFamily: "Lato Bold",
          }}
        >
          Careers
        </h1>
        <h2 className="text-sm md:text-2xl text-center text-textDark w-full md:w-3/5 my-3">
          If you’re looking to join us at {CompanyTitle} and think you have
          something unique to offer, drop us a message with your details on
        </h2>

        <div className="w-full px-4 flex justify-center">
          <button
            className="p-2 text-center text-lg bg-bgAppColor rounded-full px-8 text-white"
            style={{ fontFamily: "Lato Bold" }}
            onClick={() =>
              (window.location.href = "mailto:hello@careerpaths.io")
            }
          >
            <a
              href="mailto:hello@careerpaths.io"
              target="_blank"
              rel="noreferrer"
              data-auto-recognition="true"
              className=""
              style={{ paddingLeft: "5px" }}
            >
              hello@careerpaths.io
            </a>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
