import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    showPaymentModal: false
}

export const paymentSlice = createSlice({
    name:"payment",
    initialState,
    reducers:{
        showPaymentModalToTrue :(state, action)=>{
            state.showPaymentModal = true
        },
        showPaymentModalToFalse:(state,action)=>{
            state.showPaymentModal = false
        },
    },
    extraReducers:{
    }
})

export const { showPaymentModalToFalse, showPaymentModalToTrue } = paymentSlice.actions;
export default paymentSlice.reducer;