import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isVoucher: false,
}

export const voucherSlice = createSlice({
    name:"voucher",
    initialState,
    reducers:{
        toggleVoucher :(state)=>{
         state.isVoucher = !state.isVoucher;
        },
    },
    extraReducers:{
    }
})

export const { toggleVoucher } = voucherSlice.actions;
export default voucherSlice.reducer;