import React from "react";
import Loader from "react-loader-spinner";
import Colors from "../../../../Colors/Colors";
import { useDispatch } from "react-redux";
import { toggleThankyouModal } from "../../../../Redux/reducers/charity/charitySlice";
import { useNavigate } from "react-router-dom";

const LearnMoreModal = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleConfirm1 = () => {
    dispatch(toggleThankyouModal());
    navigate("/dashboard/home");
  };

  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>

      <div className="flex flex-col items-center justify-center md:w-2/6 w-11/12  z-20 bg-white rounded-xl py-8">
        {!props.isLoading ? (
          <div className=" text-center ">
            <div className="relative text-left align-middle bg-white dark:bg-gray-900">
              <div className="flex flex-col items-center justify-center mx-auto">
                <img
                  className="h-full rounded-lg"
                  src="/charity/congrat.png"
                  alt=""
                />
                <img
                  className="h-16 w-16 mt-2"
                  src="/charity/honor.png"
                  alt=""
                />
              </div>

              <div className="text-center flex flex-col items-center">
                <p className="mt-2 text-gray-500 dark:text-gray-400">You are</p>
                <h3
                  className="text-xl font-bold text-gray-800 dark:text-white"
                  id="modal-title"
                >
                  Awesome
                </h3>

                <p className="mt-2 w-3/4 text-gray-500 dark:text-gray-400">
                  Thank you for donating and helping us with our mission to
                  improve social mobility. You can keep track of your earnings
                  and donations in your wallet.
                </p>

                <button
                  onClick={handleConfirm1}
                  className="px-4 w-1/2 py-2.5 mt-3 text-sm font-medium text-white capitalize  bg-bgAppColor rounded-md "
                >
                  Go to Dashboard
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center h-full">
            <Loader width={200} height={200} type="Oval" color={Colors.blue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LearnMoreModal;
