import React, { useEffect, useState, useRef } from "react";
import { useRoom } from "use-twilio-video";
import { FiCameraOff, FiCamera } from "react-icons/fi";
import { MdChat, MdCallEnd, MdMic, MdMicOff } from "react-icons/md";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";
import Colors from "../../Colors/Colors";
import LocalParticipant1 from "./localParticipant/localParticipant";
import RemoteParticipant from "./remoteParticipant/remoteParticipant";
import Messagimage  from "../../Images/Untitled design.png";
import "./room.css";

const Room = ({ token, identity, roomName, onDisconnected, peer }) => {
  const [remoteUser, setRemoteUser] = useState(null);
  const [bookingDetails, setBookingDetails] = useState();
  const [hasError, setHasError] = useState(false);
  const [type, setType] = useState("");
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  } = useRoom();
  const { roomid } = useParams();
  let navigate = useNavigate();

  const setToken = () => {
    setType(jwt_decode(localStorage.getItem("cp_web_token")).type);
  };

  // nabeel
  // const videoRef = useRef(null);
  // const beginShare = () => {
  //   navigator.mediaDevices.getDisplayMedia().then((feed) => {
  //     videoRef.current.srcObject = feed;
  //     if (peer) {
  //       console.log("sending video feed");
  //       peer.addStream(feed);
  //     }
  //   });
  // };
//  useEffect(() => {
//     if (!peer) return;
//     console.log("peer set");
//     peer.on("stream", (stream) => {
//       console.log("peer stream received");
//       console.log(stream);
//       videoRef.current.srcObject = stream;
//     });
//   }, [peer]);
  // end

  const [isMessage, setIsMessage] = useState(true);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const isNewMessageRef = useRef(true);
  const isPlaySound = useRef(true);

  useEffect(async () => {
    if (!room && token && roomName) {
      connectRoom({
        token,
        options: { name: roomName, dominantSpeaker: true },
      });
      return () => disconnectRoom();
    }
  }, [connectRoom, disconnectRoom, room, roomName, token]);

  useEffect(() => {
    setToken();
    if (remoteParticipants.length > 0) {
      dominantUser();
    }
  }, [remoteParticipants.length]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings/${roomid}`)
      .then((response) => {
        setBookingDetails(response.data);
      })
      .catch((e) => {
        console.log("Error in Fetching Api in Video Component");
      });
  }, []);

  const dominantUser = () => {
    remoteParticipants.map((p) => {
      if (p?.identity !== localParticipant?.identity) {
        setRemoteUser(p);
      }
    });
  };
  useEffect(() => {
    // Check for an error condition
    if (error) {
      setHasError(true);
    }
  }, [error]);
  // if (error) return (
  //               <div className="absolute -top-1 right-0 inline-block w-3 h-3 bg-red-600 rounded-full">
  //                 Error
  //               </div>
  //             );
  if (hasError) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-md">
          {/* Your error message or content */}
          {/* <div
            className=" w-[70px] h-[70px] rounded-full "
            style={{ fontFamily: "Lato sans-serif" }}
          >
            Please allow permission for microphone and camera in your browser
          </div> */}
          <div className="row flex justify-center items-center">
            <div className="col-md-6">
              <img src={Messagimage} alt="Messagimage" className="w-52 h-52" />
            </div>
            <div className="col-md-6 ml-3">
              <h2 className="ml-2 text-xl text-textFullDark">
                Video Call is blocked from your browser
              </h2>
              <p className="ml-3 text-textDark pt-2">
                1. Click the lock icon in your browser's address bar
              </p>
              <p className="ml-3 text-textDark pt-2">
                2. Turn on Microphone and Camera
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // connected
  if (room)
  // console.log(remoteParticipants, "stream hello"); 
    return (
      <>
        <div className="flex justify-center  py-10  min-h-screen">
          <div className=" rounded-2xl p-2 relative">
            {remoteParticipants.length ? (
              remoteUser && (
                <div>
                  <RemoteParticipant
                    isMessage={isMessage}
                    setIsMessage={setIsMessage}
                    isPlaySound={isPlaySound}
                    // peer={peer}
                    setIsNewMessage={setIsNewMessage}
                    isNewMessageRef={isNewMessageRef}
                    participant={remoteUser}
                    // videoRef={videoRef}
                    flag={1}
                    name={
                      type === "customer"
                        ? bookingDetails.Professionals_Metadatum?.User
                            ?.first_name
                        : bookingDetails.User?.first_name
                    }
                  />

                  {remoteParticipants.length ? (
                    <div
                      className={`fixed py-2 lg:top-6  ${
                        isMessage ? "lg:left-10 lg:top-25" : "lg:right-20"
                      } md:top-2 md:right-4 sm:top-0 sm:right-2 flex md:w-2/6 lg:w-1/6 sm:w-3/6 max-w-3xl`}
                    >
                      {/* // <div className="fixed"> */}
                      <div className=" text-white rounded-xl ">
                        <div className="text-textFullDark">
                          <LocalParticipant1
                            participant={localParticipant}
                            name="you"
                            flag={0}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            ) : (
              <RemoteParticipant
                isMessage={isMessage}
                setIsMessage={setIsMessage}
                setIsNewMessage={setIsNewMessage}
                isPlaySound={isPlaySound}
                // peer={peer}
                // videoRef={videoRef}
                isNewMessageRef={isNewMessageRef}
                participant={localParticipant}
                flag={2}
                name="you"
              />
            )}

            <div className=" absolute z-20  transform -translate-x-1/2 lg:flex  text-white bottom-0  items-center justify-between">
              <div className=" flex w-64 sm:my-5 items-center justify-center md:justify-around">
                <p className="text-md">{bookingDetails.topic}</p>
                <p className="ml-2 lg:text-xl text-sm">
                  {moment(bookingDetails.booking_time * 1000).format("hh:mm A")}
                </p>
              </div>

              <div className="flex items-center  ">
                <button
                  className="bg-gray-500 rounded-full mx-1 p-2"
                  onClick={() => toggleMicrophone()}
                >
                  {isMicrophoneOn ? (
                    <MdMic className="w-6 h-6" />
                  ) : (
                    <MdMicOff className="w-6 h-6" />
                  )}
                </button>

                <button
                  className="bg-red-500 rounded-full mx-1 p-3 px-8"
                  onClick={() => {
                    disconnectRoom();
                    onDisconnected && onDisconnected();
                    axios
                      .post(
                        `${process.env.REACT_APP_SERVER_PATH}/bookings/${bookingDetails.id}/update_status`,
                        { status: "call-ended" },
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization:
                              "Bearer " + localStorage.getItem("cp_web_token"),
                          },
                        }
                      )
                      .then((response) => {})
                      .catch((e) => {
                        console.log(
                          "Error in updating the call status to callStarted"
                        );
                      });
                    const otherUser =
                      type === "customer"
                        ? `${bookingDetails.Professionals_Metadatum?.User?.first_name}`
                        : `${bookingDetails.User?.first_name}`;
                    navigate(
                      `/dashboard/booking/review/${roomid}?remoteUser=${otherUser}`,
                      {
                        state: { roomid },
                      }
                    );
                  }}
                >
                  <MdCallEnd className="w-8 h-6 shadow-xl" />
                </button>
                <button
                  className="bg-bgdarkGray rounded-full mx-1 p-3"
                  onClick={() => toggleCamera()}
                >
                  {isCameraOn ? (
                    <FiCamera className="w-6 h-6" />
                  ) : (
                    <FiCameraOff className="w-6 h-6" />
                  )}
                </button>

                <button
                  className="bg-bgdarkGray rounded-full mx-1 p-3"
                  onClick={() => {
                    setIsMessage(!isMessage);
                    isNewMessageRef.current = !isNewMessageRef.current;
                    setIsNewMessage(false);
                    isPlaySound.current = true;
                  }}
                >
                  <span class="relative ">
                    <MdChat className="w-6 h-6" />
                    {isNewMessage && (
                      <span class="absolute -top-1 right-0 inline-block w-3 h-3 bg-red-600 rounded-full"></span>
                    )}
                  </span>
                </button>
                {/* <button onClick={beginShare}>Share Screen</button> */}
                <div
                  style={{ minHeight: "100%", minWidth: "100%" }}
                  className="items-start bg-gray-500 rounded-lg flex justify-start"
                >
                  {" "}
                  {/* <video id="video" autoPlay ref={videoRef} /> */}
                </div>
              </div>

              <div className="flex items-center justify-center">
                {!remoteParticipants.length ? (
                  <p className="text-white text-xs">
                    Waiting for{" "}
                    {type === "customer"
                      ? `${bookingDetails.Professionals_Metadatum?.User?.first_name} ${bookingDetails.Professionals_Metadatum?.User?.last_name}`
                      : `${bookingDetails.User?.first_name} ${bookingDetails.User?.last_name}`}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <div className="flex justify-center py-10  min-h-screen items-center">
      <Loader
        type="TailSpin"
        color={Colors.blue}
        height={300}
        width={300}
        // timeout={5000} //5 secs
      />
      {/* <div style={{ width: "740px", height: "550px" }} className="bg-gray-600 rounded-xl"></div> */}
    </div>
  );

  // return (
  //   <div className="flex justify-center py-10  min-h-screen">
  //     <div style={{ width: "740px", height: "550px" }} className="bg-gray-600 rounded-xl"></div>
  //   </div>
  // );
};

export default Room;
