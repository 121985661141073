import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Colors from '../Colors/Colors'
import jwt_decode from "jwt-decode";
import { update_profile_step } from './StepUpdated';
import { Mixpanel } from '../Mixpanel';
import { CompanyTitle } from '../settings';
const AddPrice = (props) => {
    const [error, setError] = useState(false)
    localStorage.setItem("navState", 13)
    const [session_15_price, setSession_15_price] = useState(null)
    const [session_30_price, setSession_30_price] = useState(null)
    // const [session_15, setSession_15] = useState(null)
    // const [session_30, setSession_30] = useState(null)

    const priceAddHandler = () => {
        if (session_15_price !== null && session_30_price !== null) {
            // let s15 = (Math.round(+session_15_price).toFixed(2) / 4)
            // let s30 = (Math.round(+session_30_price).toFixed(2) / 4)

            // let s15_commission = Math.round(+session_15_price) + s15
            // let s30_commission = Math.round(+session_30_price) + s30
            if (session_15_price >= 5 && session_30_price >= 5) {
                axios
                    .put(
                        process.env.REACT_APP_SERVER_PATH +
                        `/professionals/${jwt_decode(
                            localStorage.getItem("signup_token")
                        ).id}`,
                        {
                            session_price_15_min: session_15_price,
                            session_price_30_min: session_30_price,
                        }
                    )
                    .then((r) => {
                        console.log(r);
                        Mixpanel.track(`web: Add Booking Price.`, {
                            session_price_15_min: session_15_price,
                            session_price_30_min: session_30_price,
                        });
                        update_profile_step(
                            localStorage.getItem("signup_token"),
                            jwt_decode(localStorage.getItem("signup_token")).id,
                            "10"
                        );
                        props.setNavState(16);
                    })
                    .catch((e) => {
                        console.log("Error");
                    });
            } else {
                setError(true);
            }
        }
    };

    const GetMentorPrice = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`).then((response) => {
            console.log(response)
            // let s15 = (Math.round(+response.data.session_price_15_min * 0.75).toFixed(2))
            // let s30 = (Math.round(+response.data.session_price_30_min * 0.75).toFixed(2))
            
            // setSession_15_price(s15)
            // setSession_30_price(s30)
            // setSession_15_price(s15_commission.toFixed(2))
            // setSession_30_price(s30_commission.toFixed(2))
            setSession_15_price(response.data.session_price_15_min);
            setSession_30_price(response.data.session_price_30_min);
        }).catch(e => {
            console.log(e)
        })
    }
    useEffect(() => {
        GetMentorPrice();
    }, [])

    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <img src="/addPrice.png" alt="workexperience" className="w-36" />
            <p className="text-2xl font-bold mt-3 text-textFullDark">Pricing</p>
            <p className="text-xs mt-3 text-center">Let your mentees know how much a session with you will cost. Remember you can always use our auto price calculator if you need help!</p>
            <div className="w-full flex mt-4">
                <div className="w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="15min">
                        15 min
                    </label>
                    <div className="flex border border-borderGray rounded-lg ">
                        <p className="bg-bgAppColor px-4 flex items-center justify-center rounded text-white" style={{ backgroundColor: Colors.darkBlue }}>£</p>
                        <input className="text-sm rounded-lg w-full  py-2 px-3 text-textFullDark  focus:outline-none" type="number" placeholder="150.00" value={session_15_price}
                            onChange={(e) => {
                                setSession_15_price(e.target.value)
                            }} />
                    </div>
                </div>
                <div className="w-1/2 ml-1">
                    <label className="block text-textFullDark text-sm mb-2" for="30min">
                        30 min
                    </label>
                    <div className="flex border border-borderGray rounded-lg ">
                        <p className="bg-blue-500 px-4 flex items-center justify-center rounded text-white" style={{ backgroundColor: Colors.darkBlue }}>£</p>
                        <input className="text-sm rounded-lg w-full  py-2 px-3 text-textFullDark  focus:outline-none" type="number" placeholder="300.00"
                            value={session_30_price}
                            onChange={(e) => {
                                setSession_30_price(e.target.value)
                            }} />
                    </div>
                </div>
            </div>
            {error && <p className='text-red-500 text-xs font-bold'>Price for both must be greater than 5</p>}
            <div>
                <p className='text-xs mt-2 w-full text-center italic'>Note: Please remember to take into account that {CompanyTitle} will deduct 25% commission from your quoted price.</p>
            </div>
            <button className="mt-4 font-light underline" style={{ color: Colors.blue }}
                onClick={() => { props.setNavState(props.navState + 1) }}>Let us adjust your price accordingly</button>
            <div className="w-full flex justify-end items-center mt-4 text-white">
                <button className="py-2 mr-auto px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue, color: 'white' }}
                    onClick={() => { props.setNavState(props.navState - 1) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <p >Back</p>
                </button>
                <button className="py-2  px-8 rounded-lg flex bg-bgAppColor"
                    onClick={priceAddHandler}>
                    <p>Next</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </button>
            </div>

        </div>
    )
}

export default AddPrice
