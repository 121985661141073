import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Colors from '../Colors/Colors'
import jwt_decode from 'jwt-decode'
import Loader from 'react-loader-spinner'
import { update_profile_step } from './StepUpdated'
import { Mixpanel } from '../Mixpanel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


const SelectInterest = (props) => {
    localStorage.setItem("navState", 2)
    const [isLoading, setLoading] = useState(false)
    const [industry, setIndustry] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState([])
    const [selectedSubInterests, setSelectedInterests] = useState([]);
    const [selectInput, setSelectInput] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [interest, setInterest] = useState("");
    const [options, setOptions] = React.useState([]);
    const [industryError, setIndustryError] = useState(false)
    const [industryErrorText, setIndustryErrorText] = useState(false)
    let loading = open && options.length === 0;

    const searchHandler = async (value) => {
        let active = true;

        const response = await axios.get(
            `${process.env.REACT_APP_SERVER_PATH}/interest?featured=0&limit=1000&search=${value}`
        );

        const items = response.data;
        if (!loading && items.length === 0) {
            return undefined;
        }
        if (active) {
            setOptions([...items]);
        }

        return () => {
            active = false;
        };
    };

    const DropdownWithButton = ({ children, ...other }) => (
        <Paper {...other}>

            <div className="flex w-full items-center hover:bg-gray-300 bg-gray-50 cursor-pointer">
                <Button
                    sx={{ width: "100%", textTransform: "none", padding: 1, justifyContent: "flex-start" }}
                    onMouseDown={async (e) => {
                        e.preventDefault();
                        if (interest.trim() === "") {
                            setIndustryErrorText('You can not add empty Specialty')
                            setIndustryError(true);
                            setOpen(false);
                            return
                        }
                        if (interest.length > 20) {
                            setIndustryErrorText('You can not add more the 20 characters.')
                            setIndustryError(true);
                            setOpen(false);
                            return
                        }
                        let bodyContent = JSON.stringify({
                            "name": interest.trim()
                        });

                        let headersList = {
                            "Accept": "*/*",
                            "Content-Type": "application/json"
                        }

                        let reqOptions = {
                            url: `${process.env.REACT_APP_SERVER_PATH}/interest`,
                            method: "POST",
                            headers: headersList,
                            data: bodyContent,
                        }

                        let response = await axios.request(reqOptions);
                        if (response.data) {
                            const industryCopy = [...industry]
                            const itemIndex = industryCopy.indexOf(response.data.id)
                            if (itemIndex != -1) { industryCopy.splice(itemIndex, 1); }
                            industryCopy.push(response.data);
                            setIndustry(industryCopy)
                            if (selectedIndustry.length === 3) {
                                if (selectedIndustry.includes(response.data.id)) {
                                    setIndustryError(false)
                                    handleIndustry(response.data.id, response.data.name)
                                } else {
                                    setIndustryErrorText('You can not select more than 3 interests')
                                    setIndustryError(true)
                                    setOpen(false);
                                }
                            } else {
                                handleIndustry(response.data.id, response.data.name)
                            }
                        }
                        setOpen(false);
                    }}
                >
                    {/* <img src='/educationplaceholder.png' alt="educationplaceholder" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                    <p className="ml-3 block font-normal truncate">+ Add Specialty: {interest}</p>
                </Button>
            </div>

            {children}
        </Paper>
    );

    const getAllInterests = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/interest?featured=1&limit=1000`).then((response) => {
            console.log(response.data);
            console.log("🚀 ~ file: SelectInterest.js:20 ~ axios.get ~ response.data", response.data)
            setIndustry(response.data)
            console.log("step 1", industry);
        }).catch(e => {
            console.log("error in industry api");
        })
    }

    
    const getAllSelectedInterests = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/get-user-interes/${jwt_decode(localStorage.getItem("signup_token")).id}`).then((response) => {
            const { Interests } = response.data;
            if (Interests.length > 0) {
                let selectedIndustryCopy = [...selectedIndustry]
                let industryCopy = [...industry]

                Interests.map((item) => {
                    selectedIndustryCopy.push(item.id);
                    if (item.is_featured !== 1) {
                        industryCopy.push(item);
                    }

                })
                setSelectedIndustry(selectedIndustryCopy)
                setSelectedInterests(industryCopy)

            }
        }).catch(e => {
            console.log("error in industry api");
        })
    }
    useEffect(() => {
        getAllInterests();
        getAllSelectedInterests();
    }, [])


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const addIndustry = (id) => {
        let data = {
            user_id: jwt_decode(localStorage.getItem("signup_token")).id,
            interest_id: id
        }
        axios.post(`${process.env.REACT_APP_SERVER_PATH}/users_interests`, data).then((response) => {
            console.log(response);
            Mixpanel.track(`web: Add New Interest.`, data);
            console.log("Interest Added");
        }).catch(e => {
            console.log("error in industry added");
        })
    }

    const removeIndustry = (id) => {
        axios.delete(`${process.env.REACT_APP_SERVER_PATH}/users_interests/${jwt_decode(localStorage.getItem("signup_token")).id}/${id}`).then((response) => {
            console.log(response);
            console.log("Interest deleted");
            Mixpanel.track(`web: Remove Specialty.`, { 'Interest_id': id });

        }).catch(e => {
            console.log("error in industry deleted");
        })
    }
    const handleIndustry = (id, name) => {
        if (name === 'other' || name === 'Other') {
            setSelectInput(true);
        }
        if (selectedIndustry.includes(id)) {
            // Remove ID
            const selectedIndustryCopy = [...selectedIndustry]
            const itemIndex = selectedIndustryCopy.indexOf(id)
            selectedIndustryCopy.splice(itemIndex, 1)
            setSelectedIndustry(selectedIndustryCopy)
            setSelectInput(false);
            setIndustryError(false);
            removeIndustry(id)
        } else {
            // Add ID
            // selectedIndustry.push(id)
            setSelectedIndustry([...selectedIndustry, id])
            addIndustry(id)
            setSelectInput(false);
            setIndustryError(false);
        }
    }

    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <img src="/CustomerFlow/AddInterest.png" alt="addVideo" className="w-36" />
            <p className="text-2xl font-bold mt-3 text-textFullDark">Specialty</p>
            <p className="text-xs mt-3 text-center">Tell us what you are Specialize in</p>
            <div className="w-full bg-bgIndustryColor border border-borderGray rounded-lg p-4 flex flex-wrap mt-4">
            {selectedSubInterests.length > 0 ? [...industry, ...selectedSubInterests].map((m, i) => {
                return <> <button key={i} className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                    style={{
                        backgroundColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray, color: selectedIndustry.includes(m.id) ? 'white' : 'black',
                        borderColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray
                    }}
                    onClick={() => {
                        if (selectedIndustry.length === 3) {
                            if (selectedIndustry.includes(m.id)) {
                                setIndustryError(false)
                                handleIndustry(m.id, m.name)
                            } else {
                                setIndustryErrorText('You can not select more than 3 Specialty')
                                setIndustryError(true)
                                setOpen(false);
                            }
                        } else {
                            handleIndustry(m.id, m.name)
                        }
                    }}>{m.name}
                </button>

                </>

            }) : industry.map((m, i) => {
                return <> <button key={i} className={`flex items-center m-1  border rounded-full m-1 py-1 px-3 text-sm`}
                    style={{
                        backgroundColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray, color: selectedIndustry.includes(m.id) ? 'white' : 'black',
                        borderColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray
                    }}
                    onClick={() => {
                        if (selectedIndustry.length === 3) {
                            if (selectedIndustry.includes(m.id)) {
                                setIndustryError(false)
                                handleIndustry(m.id, m.name)
                            } else {
                                setIndustryErrorText('You can not select more than 3 Specialty')
                                setIndustryError(true)
                                setOpen(false);
                            }
                        } else {
                            handleIndustry(m.id, m.name)
                        }
                    }}>{m.name}
                </button>

                </>

            })}
                <button className={`flex items-center border border-borderGray rounded-full m-1 pt-1 px-3 py-1 text-sm`}
                    style={{
                        backgroundColor: selectInput ? Colors.bgBlue : Colors.bgGray, color: selectInput ? 'white' : 'black',
                        borderColor: selectInput ? Colors.bgBlue : Colors.bgGray
                    }}
                    onClick={() => setSelectInput(!selectInput)}>{`Other`}
                </button>
                {selectInput && <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name ?? option}
                    options={options}
                    freeSolo={true}
                    PaperComponent={DropdownWithButton}
                    renderOption={(props, option) => (
                        <div
                            {...props}
                            className="flex items-center p-2 hover:bg-gray-200 bg-gray-50 cursor-pointer"
                        >
                            <span className="ml-3 block font-normal truncate">
                                {option?.name}
                            </span>
                        </div>
                    )}
                    sx={{ width: 400 }}
                    clearOnBlur={false}
                    onInputChange={(e, value) => {
                        setInterest(value.trim());
                    }}
                    onChange={(e, obj) => {
                        if (obj?.id) {
                            if (selectedIndustry.includes(obj.id)) {
                                // Remove ID
                                if (selectedIndustry.length === 3) {
                                    if (selectedIndustry.includes(obj.id)) {
                                        setIndustryError(false)
                                        handleIndustry(obj.id, obj.name)
                                    } else {
                                        setIndustryErrorText('You can not select more than 3 Specialty')
                                        setIndustryError(true)
                                        setOpen(false);
                                    }
                                } else {
                                    handleIndustry(obj.id, obj.name)
                                }

                            } else {
                                const industryCopy = [...industry];
                                let interest = industryCopy.find((o, i) => {
                                    if (o.id === obj.id) {
                                        return true; // stop searching
                                    }
                                });
                                if (interest) {
                                    if (selectedIndustry.length === 3) {
                                        if (selectedIndustry.includes(obj.id)) {
                                            setIndustryError(false)
                                            handleIndustry(obj.id, obj.name)
                                        } else {
                                            setIndustryErrorText('You can not select more than 3 Specialty')
                                            setIndustryError(true)
                                            setOpen(false);
                                        }
                                    } else {
                                        handleIndustry(obj.id, obj.name)
                                    }
                                } else {
                                    industryCopy.push(obj);
                                    setIndustry(industryCopy)
                                    if (selectedIndustry.length === 3) {
                                        if (selectedIndustry.includes(obj.id)) {
                                            setIndustryError(false)
                                            handleIndustry(obj.id, obj.name)
                                        } else {
                                            setIndustryErrorText('You can not select more than 3 Specialty')
                                            setIndustryError(true)
                                            setOpen(false);
                                        }
                                    } else {
                                        handleIndustry(obj.id, obj.name)
                                    }
                                }

                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="interest"
                            type="text"
                            placeholder="Interest"
                            value={interest}
                            onChange={(e) => {
                                setInterest(e.target.value.trim());
                                searchHandler(e.target.value);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />}
            </div>
            {industryError && <p className='text-left text-red-500 font-bold text-sm mt-2 w-full'>{industryErrorText}</p>}


            <div className="w-full flex justify-end items-center mt-4 text-white">
                <button className="py-2 mr-auto px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue, color: 'white' }}
                    onClick={() => {
                        props.setNavState(props.navState - 1)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <p >Back</p>

                </button>
                <button className="py-2 px-8 rounded-lg flex" style={{ backgroundColor: selectedIndustry.length > 0 ? Colors.blue : Colors.gray, color: selectedIndustry.length > 0 ? 'white' : 'gray' }}
                    onClick={() => {
                        if (selectedIndustry.length > 0) {
                            update_profile_step(localStorage.getItem('signup_token'), jwt_decode(localStorage.getItem("signup_token")).id, "6")
                            props.setNavState(props.navState + 1)
                            setLoading(false)
                        }
                    }}>
                    <p >Next {isLoading ? <span className='inline-block ml-4'><Loader
                        type="TailSpin"
                        color="white"
                        height={20}
                        width={20}
                    // timeout={5000} //5 secs
                    /></span> : null}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default SelectInterest
