import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useSelector } from "react-redux";
import Ratings from "../../../../../Rating/Ratings";
import moment from "moment";
import { ReviewAll } from "../../../../../Profile/Reviews/Reviews";
import ProfileInterest from "../../../../../Profile/ProfileInterests/ProfileInterest"
import "./Customer.css"
import { useDispatch } from "react-redux";
import { setTooltipFalse, setTooltipTrue } from "../../../../../Redux/reducers/ToolTip/ToolTip";
import ProfileIndustries from "../../../../../Profile/ProfileIndustries/ProfileIndustries";
import 'fix-date' 
const CustomerDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CustomerDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <svg
            className="h-7 w-7 sm:h-6 "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomerDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const handleClose = () => {
    props.setIsCustomerModal(false);
  };
  const handleTooltip = () => {
    dispatch(setTooltipTrue());

  }
  let dispatch = useDispatch()
  const [reviews, setReviews] = useState([]);
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [userInterests, setUserInterest] = useState([]);
  const [profile, setProfile] = useState({});

  let id = props.customer?.id;

  const show = useSelector((state) => state.review.showReviews);
  const toggleShow = () => {
  };
  const responseTime = () => {
    const duration = moment.duration(profile.response_time, "minutes");
    let responseTime = "";
    for (const [unit, value] of Object.entries(duration._data)) {
      if (value > 1) responseTime = `${value} ${unit}`;
      else if (value > 0) responseTime = `${value} ${unit.slice(0, -1)}`;
    }
    return responseTime;
  };

  const getAllSelectedInterests = () => {
    axios.get(`${process.env.REACT_APP_SERVER_PATH}/get-user-interes/${id}`).then((response) => {
      const { Interests } = response.data;
      if (Interests.length > 0) {
        setUserInterest(Interests)
      }
    }).catch(e => {
      console.log("error in industry api");
    })
  }



  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/user_reviews/${id}`)
      .then((response) => {
        setReviews(response.data);
      })
      .catch((e) => {
        console.log("Error in Reviews Api", e);
      });
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/experience/${id}`)
      .then((response) => {
        setExperience(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/education/${id}`)
      .then((response) => {
        setEducation(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`)
      .then((response) => {
        setProfile(response.data);
      });
    getAllSelectedInterests();
  }, [id]);

  return (
    <div className="z-50">
      {show && (
        <ReviewAll
          toggle={toggleShow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={show}
          reviews={reviews}
        />
      )}
      <CustomerDialog
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.isCustomerModal}
        onClick={handleTooltip}
      >
        <CustomerDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className="flex items-start">
            <img
              className="border border-borderGray rounded-xl mr-6 w-1/3 object-cover"
              style={{ height: "250px" }}
              alt={props.customer?.first_name}
              src={
                props.customer?.profile_photo
                  ? props.customer?.profile_photo
                  : "/avatar.png"
              }
            />
            <div className="mx-4 w-2/3 sm:mt-6 flex flex-col">
              <p>
                {props.customer?.first_name} {props.customer?.last_name}
              </p>
              <div>
                {profile?.bio &&
                  profile?.bio
                    .split("\n")
                    .map((paragraph, index) => (
                      <p
                        key={index}
                        className={`text-xs text-justify text-textDark sm:w-full prose lg:prose-xl pt-1`}
                      >
                        {paragraph
                          ?.split("\n")
                          .reduce((total, line) => [total, <br />, line])}
                      </p>
                    ))}
              </div>
              <div className={`flex items-center flex-wrap`}>
                <div className={`flex items-center sm:mr-6`}>
                  <div className="flex flex-col">
                    <div className="flex items-center" onClick={toggleShow}>
                      {profile?.avgRating > 0 ? (
                        <div>
                          {profile?.avgRating && (
                            <Ratings isHalf={true} value={profile?.avgRating} />
                          )}
                        </div>
                      ) : (
                        <Ratings value={0} />
                      )}
                      &nbsp;&nbsp;
                      {profile?.avgRating && (
                        <p className="text-textDark sm:text-md">
                          {(+profile?.avgRating).toFixed(1)}
                        </p>
                      )}
                    </div>
                    {profile?.reviewCount > 0 ? (
                      <div className="cursor-pointer" onClick={toggleShow}>
                        {profile?.reviewCount && (
                          <p className="text-xs text-textDark">
                            {profile?.reviewCount} reviews
                          </p>
                        )}
                      </div>
                    ) : (
                      <p
                        className="text-xs text-textDark cursor-pointer"
                        onClick={toggleShow}
                      >
                        {0} reviews
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center sm:ml-8 mt-2 sm:mt-6 ">
                  <div
                    className="flex flex-col"
                    data-tip="Time it takes for mentor to respond to your meeting request"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <div>
                        <img src="/Vector.png" alt="photos" className="w-2 h-3" />
                      </div>
                      <p className="text-textDark">
                        &nbsp;{responseTime() ? responseTime() : 0}
                      </p>
                    </div>
                    <p className="text-xs text-textDark">Response time</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </CustomerDialogTitle>

        <DialogContent dividers>
          <div className="md:container mx-auto px-2 w-full justify-between mt-4 h-fit">

            <div
              className=" bg-bgGray p-2 w-full rounded-lg h-fit"
              style={{ height: "fit-content" }}
            >
              {userInterests.length > 0 && (<>
                <h1 className="text-3xl font-bold my-4 ml-2 text-textFullDark">
                  Interest
                </h1>
                <ProfileInterest id={id} />
                <hr className="mt-4" />
              </>)}

              <h1 className="text-3xl font-bold my-4 ml-2 text-textFullDark">
                Industries
              </h1>
              <ProfileIndustries id={id} />
              <hr className="mt-4" />
              {experience.length > 0 && (
                <>
                  <p className="text-3xl font-bold my-4 ml-2 text-textFullDark">
                    Experience
                  </p>
                  {experience.map((m, i) => {
                    return (
                      <Experience
                        key={i}
                        companyImage={m.profile_photo}
                        position={m.job}
                        companyName={m.company}
                        startDate={m.start_date}
                        endDate={m.end_date}
                        location={m.location}
                        description={m.description}
                        currentJob={m.current_job}
                      />
                    );
                  })}
                </>
              )}
            </div>
            {education.length > 0 && (
              <div
                className="bg-bgGray p-4 mt-2 w-full rounded-lg h-fit"
                style={{ height: "fit-content" }}
              >
                <p className="text-3xl font-bold my-4 ml-2 text-textFullDark h-100">
                  Education
                </p>
                {education.map((m) => {
                  return (
                    <Education
                      universityImage={m.profile_photo}
                      education_type={m.field_of_study}
                      universityName={m.school}
                      endDate={m.end_date}
                      startDate={m.start_date}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </DialogContent>
      </CustomerDialog>
    </div>
  );
}

const Experience = (props) => {
  const calcDate = (date1, date2) => {
    let diff = Math.floor(date1.getTime() - date2.getTime()) * -1;
    let day = 1000 * 60 * 60 * 24;
    let days = Math.floor(diff / day);
    let months = Math.floor(days / 31);
    let years = Math.floor(months / 12);
    let rem = months % 12;
    let message = years + " yrs ";
    message += rem + " mos ";
    return message;
  };

  return (
    <div className="py-2">
      <div className="flex flex-row ">
        <div>
          <div className="bg-bgAppColor sm:w-12 sm:h-12 md:w-16 md:h-16 rounded-full flex items-center justify-center">
            <img
              src={
                props.companyImage
                  ? props.companyImage
                  : "/experienceplaceholder.png"
              }
              alt=""
              className="sm:w-12 sm:h-12 md:w-16 md:h-16 rounded-full object-cover bg-blue-400"
            />
          </div>
        </div>
        <div className="flex flex-col ml-5">
          <p className="text-lg text-textFullDark font-bold">
            {props.position}
          </p>
          <p className="text-sm text-textDark">
            {props.companyName} - {props.location}
          </p>
          <div className="flex items-center">
            <p className="text-sm text-textDark">
              {" "}
              {`${moment(props.startDate).format("MMM YYYY")} - `}
            </p>
            {props.currentJob === "1" ? (
              <p className="text-sm text-textDark">&nbsp;Present</p>
            ) : (
              <p className="text-sm text-textDark">
                &nbsp;{`${moment(props.endDate).format("MMM YYYY")}`}
              </p>
            )}
            <p className="text-textDark">&nbsp;•&nbsp;</p>
            <p className="text-sm text-textDark">
              {" "}
              {props.currentJob === "1"
                ? calcDate(new Date(props.startDate), new Date())
                : calcDate(new Date(props.startDate), new Date(props.endDate))}
            </p>
          </div>

          <div className=" mt-2 ">
            <p className="text-xs text-textFullDark ">{props.description}</p>
          </div>
        </div>
      </div>
      <hr className="mt-4" />
    </div>
  );
};

const Education = (props) => {
  return (
    <div>
      <div className="py-2">
        <div className="flex items-start">
          <div>
            <div className="w-16 h-16 rounded-full bg-bgAppColor border flex justify-center items-center">
              <img
                src={
                  props.universityImage
                    ? props.universityImage
                    : "/educationplaceholder.png"
                }
                alt=""
                className="w-16 h-16 rounded-full object-cover "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 ">
            <p className="text-lg  text-textFullDark font-bold">
              {props.education_type}
            </p>
            <p className="text-sm text-textFullDark">{props.universityName}</p>
            <p className="text-sm text-textFullDark">{`${moment(
              props.startDate
            ).format("MMM-YYYY")} - ${moment(props.endDate).format(
              "MMM-YYYY"
            )}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
