import React from 'react'
import { useNavigate } from 'react-router-dom'
import './style.css';
function Index({name}) {
   const navigate = useNavigate();
    return (
      <div className="md:container mx-auto my-6 sm:px-2 xl:px-16 ">
        <div className=" text-textFullDark ">
          <p className="text-textFullDark sm:text-lg md:text-2xl font-bold ">
            {name}
          </p>
        </div>
        <div
          className=" flex flex-col md:flex-row w-full my-4 rounded-lg  items-center justify-between p-5"
          style={{
            backgroundImage: "url(/events/landingPageCardbg.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <div class="flex flex-col md:flex-row">
            <div className="w-full lg:w-2/3 p-2 md:p-5 ">
              <h1 className="text-3xl font-bold">
                Check out our special Private Equity Bundle
              </h1>
              <p className="text-textFullDark py-5">
                Develop practical skills and insights into the world of Private
                Equity
              </p>

              <div className="w-full md:w-1/2 md:hidden flex items-center justify-center ">
                <img
                  src="/events/course-laptop1.svg"
                  alt=""
                  className="w-full"
                />
              </div>

              <div className="w-full flex items-center justify-center md:justify-start">
                <button
                  onClick={() => navigate("/private-equity-bundle")}
                  className="course-detail-btn"
                >
                  See Private Equity Bundle
                </button>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 hidden md:flex md:items-start md:justify-start lg:items-end lg:justify-end ">
            <img
              src="/events/course-laptop1.svg"
              alt=""
              className="md:w-full lg:w-1/2 self-end"
            />
          </div>
        </div>
      </div>
    );
}

export default Index
