import React, { useState, useEffect, Fragment, Suspense } from "react";
import { Query } from "./../Query";
import EFCBanner from '../components/CoursesCard/Banner';
import CourseCards from "../components/CoursesCard/index";
import CourseCardSkelekon from "../Skeletons/CoursesCardSkelekon__landingPage";
import ReuseableComponent from '../reusableComponents/reuseableComponents';
import OfferedBySkeleton from "../Skeletons/EFCBannerSkeleton";
import PrivateEquitySkeleton from "../Skeletons/PEBannerSkeleton";
import { GiftCards } from "./GiftCards";
export const MentorsInIndustries = () => {
    const [isCoursesLoading, setIsCoursesLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const getCourses = () => {
        setTimeout(() => {
            setCourses([1]);
            setIsCoursesLoading(false);
        }, 100);
    };

    useEffect(() => {
        getCourses();
        setLoading(false);
    }, []);
    return (
        <Fragment>
            <Query url={`${process.env.REACT_APP_SERVER_PATH}/web-home`}>
                {({ state: { data, loading, error }, actions }) => {
                    if (error) {
                        return <p style={{ color: "red" }}>{error.message}</p>;
                    }
                    if (data) {
                        return (
                            <React.Fragment>
                                <React.Fragment>
                                    {data?.industriesAndRelatedMentors?.map((industry, index) => {
                                        return (
                                            <div key={index}>
                                                {
                                                    industry.mentors?.length > 0 &&
                                                    <ReuseableComponent
                                                        key={index}
                                                        industryId={industry?.id}
                                                        industrySlug={industry?.slug}
                                                        name={industry?.name}
                                                        Featured={industry?.mentors}
                                                        len={industry.mentors && industry.mentors?.length}
                                                    />
                                                }
                                                {/* efc banner */}
                                                {industry?.name === "Asset Management" &&
                                                    <div>
                                                        {
                                                            isLoading ? (
                                                                <OfferedBySkeleton />
                                                            )
                                                                : (<EFCBanner />)   //EFCBanner
                                                        }
                                                    </div>}
                                                {/* banner for private equity course */}
                                                {industry?.name === "Private Equity" &&
                                                    <div>
                                                        {
                                                            isLoading ? (
                                                                <PrivateEquitySkeleton />
                                                            )
                                                                : (courses.length > 0 && <CourseCards name="" />)  //CourseCards
                                                        }
                                                    </div>
                                                }
                                                {industry?.name === "Tech" &&
                                                    <div>
                                                        {
                                                            isLoading ? (
                                                                <PrivateEquitySkeleton />
                                                            )
                                                                : <GiftCards />  //CourseCards
                                                        }
                                                    </div>
                                                }

                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            </React.Fragment>
                        );
                    }

                    return null;
                }}
            </Query>
        </Fragment>
    )
}
