import React, { useState, useRef } from "react";
import ReactStars from "react-rating-stars-component";
import LeftArrow from "../../Arrows/LeftArrow";
import RightArrow from "../../Arrows/RightArrow";
import Slider from "react-slick";
import Img1 from "../../Images/img6.png"
import Img2 from "../../Images/img1.jpeg"
import Img3 from "../../Images/img5.jpeg"
import Img4 from "../../Images/img7.jpeg"
import Img5 from "../../Images/img3.jpeg"

export default function Reviews() {
  const [reviewerData] = useState([
    {
      description:
        "“I'm so glad I subscribed to the Bundle. The conversations with my mentor were personalized and highly informative. The Bundle also helped me better understand how to ace my interviews.”",
      name: "Omegie A.",
      nameFirstLetter: "O",
      title: "Student UCL",
      imageUser: Img1,
    },
    {
      description:
        "“As someone new to private equity, the bundle mentorship was a perfect starting point. The content was easy to understand, and the mentor patiently addressed all my queries.”",
      name: "Selim K.",
      nameFirstLetter: "S",
      title: "Investment Banking Analyst",
      imageUser: Img2,
    },
    {
      description:
        "“Provides a pretty comprehensive understanding of private equity dynamics. Helped by my mentor's clear explanations and the example case studies… I quickly grasped some of the complex concepts.”",
      name: "Sophia B.",
      nameFirstLetter: "S",
      title: "Equities Research",
      imageUser: Img3,
    },
    {
      description: "“Great wisdom and clear explanations of industry nuances”",
      name: "Mickey N.",
      nameFirstLetter: "M",
      title: "Business Analyst",
      imageUser: Img4,
    },
    {
      description:
        "“I was hesitant initially, but the Bundle proved its value”",
      name: "Eddie R.",
      nameFirstLetter: "E",
      title: "Management Accountant",
      imageUser: Img5,
    },
  ]);
  let Ref = useRef(null);
  const [settings] = useState({
    dots: false,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const SlickNext = () => {
    Ref.current.slickNext();
  };
  const SlickPrev = () => {
    Ref.current.slickPrev();
  };
  return (
    <div className="md:container mx-auto mt-5  sm:px-2 md:pt-6">
      <div className="flex items-center justify-between px-4 mb-2 md:px-12">
        <h1 className="sm:text-xl md:text-3xl text-textFullDark font-bold">
          Reviews
        </h1>
        <div className="text-md text-textFullDark flex items-center">
          <LeftArrow click={SlickPrev} />
          <RightArrow click={SlickNext} />
          <div></div>
        </div>
      </div>

      <div className="md:container mx-auto my-4">
        <Slider {...settings} ref={Ref}>
          {reviewerData.map((reviewer) => {
            return (
              <div>
                <div className="p-6 bg-bgGray mr-2 md:mr-4 rounded flex flex-col justify-between h-96 md:h-80">
                  <div>
                    <ReactStars
                      value={5}
                      size={25}
                      activeColor="#FFD700"
                      isHalf={true}
                      edit={false}
                    />
                    <p className="text-lg text-textFullDark my-4 font-bold">
                      {reviewer.description}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center text-white font-bold" src={reviewer.imageUser} />
                    <div className="ml-4">
                      <h1 className="text-xl text-black font-bold">
                        {reviewer.name}
                      </h1>
                      <p className="text-xs text-gray-600 font-bold">
                        {reviewer.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
