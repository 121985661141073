import React, { useState } from 'react'
import CongratulationPassword from './CongratulationPassword/CongratulationPassword'
import ChangePassword from './ChangePassword/ChangePassword'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../../../settings'

const ChangePasswordComponent = () => {
    const [navState, setNavState] = useState(0)
    let ComponentToRender = null
    switch (navState) {
        case 0:
            ComponentToRender = <ChangePassword setNavState={setNavState} />
            break;
        case 1:
            ComponentToRender = (<div>
                <CongratulationPassword setNavState={setNavState} />
            </div>
            )
            break;

        default:
            return null
    }
    return (
        <div className="mb-6 bg-white sm:p-2 md:p-4 rounded">
            <Helmet>
                <title>Change Password | Dashboard | {CompanyTitle}</title>
                <meta name={`Change Password | Dashboard | ${CompanyTitle}`} content={`Change Password | Dashboard | ${CompanyTitle}`} />
            </Helmet>
            {ComponentToRender}
        </div>
    )
}

export default ChangePasswordComponent