import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Colors from '../../../../Colors/Colors'
import jwt_decode from 'jwt-decode'
import Loader from 'react-loader-spinner'
import { Mixpanel } from '../../../../Mixpanel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


const ProfileInterest = (props) => {
    const {userType } = props;
    localStorage.setItem("navState", 2)
    const [isLoading, setLoading] = useState(false)
    const [industry, setIndustry] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState([])
    const [selectedSubInterests, setSelectedInterests] = useState([]);
    const [selectInput, setSelectInput] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [interest, setInterest] = useState("");
    const [options, setOptions] = React.useState([]);
    const [industryError, setIndustryError] = useState(false)
    const [industryErrorText, setIndustryErrorText] = useState(false)
    let loading = open && options.length === 0;

    const searchHandler = async (value) => {
        let active = true;

        const response = await axios.get(
            `${process.env.REACT_APP_SERVER_PATH}/interest?featured=0&limit=1000&search=${value}`
        );

        const items = response.data;
        if (!loading && items.length === 0) {
            return undefined;
        }
        if (active) {
            setOptions([...items]);
        }

        return () => {
            active = false;
        };
    };

    const DropdownWithButton = ({ children, ...other }) => (
        <Paper {...other}>

            <div className="flex w-full items-center hover:bg-gray-300 bg-gray-50 cursor-pointer">
                <Button
                    sx={{ width: "100%", textTransform: "none", padding: 1, justifyContent: "flex-start" }}
                    onMouseDown={async (e) => {
                        e.preventDefault();
                        if (interest.trim() === "") {
                            setIndustryErrorText(`You can not add empty ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                            setIndustryError(true);
                            setOpen(false);
                            return
                        }
                        if (interest.length > 20) {
                            setIndustryErrorText('You can not add more the 20 characters.')
                            setIndustryError(true);
                            setOpen(false)
                            return
                        }
                        let bodyContent = JSON.stringify({
                            "name": interest.trim()
                        });

                        let headersList = {
                            "Accept": "*/*",
                            "Content-Type": "application/json"
                        }

                        let reqOptions = {
                            url: `${process.env.REACT_APP_SERVER_PATH}/interest`,
                            method: "POST",
                            headers: headersList,
                            data: bodyContent,
                        }

                        let response = await axios.request(reqOptions);
                        if (response.data) {
                            const industryCopy = [...industry]
                            const itemIndex = industryCopy.indexOf(response.data.id)
                            if (itemIndex != -1) { industryCopy.splice(itemIndex, 1); }
                            industryCopy.push(response.data);
                            setIndustry(industryCopy)
                            if (selectedIndustry.length === 3) {
                                if (selectedIndustry.includes(response.data.id)) {
                                    setIndustryError(false)
                                    handleIndustry(response.data.id, response.data.name)
                                } else {
                                    setIndustryErrorText(`You can not select more than 3 ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                                    setIndustryError(true)
                                    setOpen(false);
                                }
                            } else {
                                handleIndustry(response.data.id, response.data.name)
                            }
                        }
                        setOpen(false);
                    }}
                >
                    {/* <img src='/educationplaceholder.png' alt="educationplaceholder" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                    <p className="ml-3 block font-normal truncate">+ Add {userType === 'professional' ? 'Specialty' : 'Interest'}: {interest}</p>
                </Button>
            </div>

            {children}
        </Paper>
    );

    const getAllInterests = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/interest?featured=1&limit=1000`).then((response) => {
            console.log(response.data);
            console.log("🚀 ~ file: SelectInterest.js:20 ~ axios.get ~ response.data", response.data)
            setIndustry(response.data)
        }).catch(e => {
            console.log("error in industry api");
        })
    }

    const getAllSelectedInterests = () => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/get-user-interes/${+localStorage.getItem('id')}`).then((response) => {
            const { Interests } = response.data;
            if (Interests.length > 0) {
                let selectedIndustryCopy = [...selectedIndustry]
                let industryCopy = [...industry]

                Interests.map((item) => {
                    selectedIndustryCopy.push(item.id);
                    if (item.is_featured !== 1) {
                        industryCopy.push(item);
                    }

                })
                setSelectedIndustry(selectedIndustryCopy)
                setSelectedInterests(industryCopy)

            }
        }).catch(e => {
            console.log("error in industry api");
        })
    }
    useEffect(() => {
        getAllInterests();
        getAllSelectedInterests();
    }, [])


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const addIndustry = (id) => {
        let data = {
            user_id: +localStorage.getItem('id'),
            interest_id: id
        }
        axios.post(`${process.env.REACT_APP_SERVER_PATH}/users_interests`, data).then((response) => {
            console.log(response);
            Mixpanel.track(`web: Add New Interest.`, data);
            console.log("Interest Added");
        }).catch(e => {
            console.log("error in industry added");
        })
    }

    const removeIndustry = (id) => {
        axios.delete(`${process.env.REACT_APP_SERVER_PATH}/users_interests/${+localStorage.getItem('id')}/${id}`).then((response) => {
            console.log(response);
            console.log("Interest deleted");
            Mixpanel.track(`web: Remove Interest.`, { 'Interest_id': id });

        }).catch(e => {
            console.log("error in industry deleted");
        })
    }
    const handleIndustry = (id, name) => {
        if (name === 'other' || name === 'Other') {
            setSelectInput(true);
        }
        if (selectedIndustry.includes(id)) {
            // Remove ID
            const selectedIndustryCopy = [...selectedIndustry]
            const itemIndex = selectedIndustryCopy.indexOf(id)
            selectedIndustryCopy.splice(itemIndex, 1)
            setSelectedIndustry(selectedIndustryCopy)
            setSelectInput(false);
            setIndustryError(false);
            removeIndustry(id)
        } else {
            // Add ID
            // selectedIndustry.push(id)
            setSelectedIndustry([...selectedIndustry, id])
            addIndustry(id);
            setSelectInput(false);
            setIndustryError(false);

        }
    }

    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <div className="w-full bg-bgIndustryColor rounded-lg flex flex-wrap">
                {selectedSubInterests.length > 0 ? [...industry, ...selectedSubInterests].map((m, i) => {
                    return <> <button key={i} className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                        style={{
                            backgroundColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray, color: selectedIndustry.includes(m.id) ? 'white' : 'black',
                            borderColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray
                        }}
                        onClick={() => {
                            if (selectedIndustry.length === 3) {
                                if (selectedIndustry.includes(m.id)) {
                                    setIndustryError(false)
                                    handleIndustry(m.id, m.name)
                                } else {
                                    setIndustryErrorText(`You can not select more than 3 ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                                    setIndustryError(true)
                                    setOpen(false);
                                }
                            } else {
                                handleIndustry(m.id, m.name)
                            }
                        }}>{m.name}
                    </button>

                    </>

                }) : industry.map((m, i) => {
                    return <> <button key={i} className={`flex items-center m-1  border rounded-full m-1 py-1 px-3 text-sm`}
                        style={{
                            backgroundColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray, color: selectedIndustry.includes(m.id) ? 'white' : 'black',
                            borderColor: selectedIndustry.includes(m.id) ? Colors.bgBlue : Colors.bgGray
                        }}
                        onClick={() => {
                            if (selectedIndustry.length === 3) {
                                if (selectedIndustry.includes(m.id)) {
                                    setIndustryError(false)
                                    handleIndustry(m.id, m.name)
                                } else {
                                    setIndustryErrorText(`You can not select more than 3 ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                                    setIndustryError(true)
                                    setOpen(false);
                                }
                            } else {
                                handleIndustry(m.id, m.name)
                            }
                        }}>{m.name}
                    </button>

                    </>

                })}
                <button className={`flex items-center m-1  border rounded-full m-1 py-1 px-3 text-sm`}
                    style={{
                        backgroundColor: selectInput ? Colors.bgBlue : Colors.bgGray, color: selectInput ? 'white' : 'black',
                        borderColor: selectInput ? Colors.bgBlue : Colors.bgGray
                    }}
                    onClick={() => setSelectInput(!selectInput)}>{`Other`}
                </button>
                {selectInput && <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name ?? option}
                    options={options}
                    freeSolo={true}
                    PaperComponent={DropdownWithButton}
                    renderOption={(props, option) => (
                        <div
                            {...props}
                            className="flex items-center p-2 hover:bg-gray-200 bg-gray-50 cursor-pointer"
                        >
                            <span className="ml-3 block font-normal truncate">
                                {option?.name}
                            </span>
                        </div>
                    )}
                    sx={{ width: 500 }}
                    clearOnBlur={false}
                    onInputChange={(e, value) => {
                        setInterest(value.trim());
                    }}
                    onChange={(e, obj) => {
                        if (obj?.id) {
                            if (selectedIndustry.includes(obj.id)) {
                                // Remove ID
                                if (selectedIndustry.length === 3) {
                                    if (selectedIndustry.includes(obj.id)) {
                                        setIndustryError(false)
                                        handleIndustry(obj.id, obj.name)
                                    } else {
                                        setIndustryErrorText(`You can not select more than 3 ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                                        setIndustryError(true)
                                        setOpen(false);
                                    }
                                } else {
                                    handleIndustry(obj.id, obj.name)
                                }

                            } else {
                                const industryCopy = [...industry];
                                let interest = industryCopy.find((o, i) => {
                                    if (o.id === obj.id) {
                                        return true; // stop searching
                                    }
                                });
                                if (interest) {
                                    if (selectedIndustry.length === 3) {
                                        if (selectedIndustry.includes(obj.id)) {
                                            setIndustryError(false)
                                            handleIndustry(obj.id, obj.name)
                                        } else {
                                            setIndustryErrorText(`You can not select more than 3 ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                                            setIndustryError(true)
                                            setOpen(false);
                                        }
                                    } else {
                                        handleIndustry(obj.id, obj.name)
                                    }
                                } else {
                                    industryCopy.push(obj);
                                    setIndustry(industryCopy)
                                    if (selectedIndustry.length === 3) {
                                        if (selectedIndustry.includes(obj.id)) {
                                            setIndustryError(false)
                                            handleIndustry(obj.id, obj.name)
                                        } else {
                                            setIndustryErrorText(`You can not select more than 3 ${userType === 'professional' ? 'Specialty' : 'Interests'}`)
                                            setIndustryError(true)
                                            setOpen(false);
                                        }
                                    } else {
                                        handleIndustry(obj.id, obj.name)
                                    }
                                }

                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="interest"
                            variant="standard"
                            type="text"
                            placeholder="Type to add Interest"
                            value={interest}
                            onChange={(e) => {
                                console.log("🚀 ~ file: ProfileInterest.js:298 ~ ProfileInterest ~ params", params)

                                setInterest(e.target.value.trim());
                                searchHandler(e.target.value);
                            }}

                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                                // style: {
                                //     top:'50px',
                                //     // height: "30px",
                                //     border: "none", 
                                //     width: "100%",
                                // },
                            }}
                        />
                    )}
                />}
            </div>
            {industryError && <p className='text-left text-red-500 font-bold text-sm mt-2 w-full'>{industryErrorText}</p>}

        </div>
    )
}

export default ProfileInterest
