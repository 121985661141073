import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { getRefFromQuery } from '../CustomFunctions/CustomFunctions'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import { CompanyTitle } from '../settings'
import ListingIndex from './Listing_Page'


const Listing = (props) => {
    let location = useLocation()
    getRefFromQuery(location)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <div className='bg-white'>
            <Helmet>
                <title>Search mentors | {CompanyTitle}</title>
                <meta name={`Search mentors | ${CompanyTitle}`} content={`Search mentors | ${CompanyTitle}`} />
            </Helmet>
            <Navbar />
            <hr />
            <ListingIndex />
            <Footer />
        </div>
    )
}

export default Listing
