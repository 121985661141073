import React from 'react'
import Colors from '../Colors/Colors'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import { CompanyTitle } from '../settings'

const Download = () => {
    return (
      <div>
        <Navbar />
        <hr />
        {/* Download Main Component  */}
        <div
          className="container mx-auto flex flex-col items-center justify-center py-6 md:py-40"
          style={{
            fontFamily: "Lato sans-serif",
          }}
        >
          <h1
            className="text-4xl md:text-7xl text-center"
            style={{
              fontFamily: "Lato Bold",
            }}
          >
            Download {CompanyTitle}
          </h1>
          <p className="text-sm md:text-lg text-center text-textDark w-full md:w-1/3 my-3">
            Search for "CareerPaths" in the App Store or Google Play store on
            your phone to download the app.
          </p>

          <div className="flex flex-col md:flex-row w-56 md:w-96 mt-6">
            <button
              className="bg-gray-800 hover:bg-black text-white py-3 mb-3 rounded flex justify-center w-full md:36 h-12"
              style={{ backgroundColor: Colors.apple }}
            >
              <a
                href="https://apps.apple.com/us/app/careerpaths/id1558302311"
                rel="noreferrer"
                target="_blank"
              >
                <img src="/appstore.png" alt="apple" />
              </a>{" "}
            </button>
            <button
              className="md:ml-4 bg-gray-800 hover:bg-black text-white py-3 w-full md:36 h-12 rounded flex justify-center"
              style={{ backgroundColor: Colors.apple }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.careerpaths.mobile"
                rel="noreferrer"
                target="_blank"
              >
                <img src="/googleplay.png" alt="apple" />
              </a>
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default Download