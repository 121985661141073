import React, { Fragment, Suspense } from "react";
import { Query } from "./../Query";
import IndustrySkeleton_landingPage from "../Skeletons/IndustryCardsSkeleton_landingPage";
import Catagories from "../Catagories/Catagories";

export const IndustriesCards = () => (
    <Fragment>
        <Query url={`${process.env.REACT_APP_SERVER_PATH}/getIndustryStats`}>
            {({ state: { data, loading, error }, actions }) => {
                if (loading) {
                    return (
                        <div className="flex md:container mx-auto my-4 sm:px-2 md:pl-16 md:pr-12 ">
                            {["1", "2", "3", "4"].map((event, index) => (
                                <IndustrySkeleton_landingPage key={index} />
                            ))}
                        </div>
                    )
                }

                if (error) {
                    return <p style={{ color: "red" }}>{error.message}</p>;
                }

                if (data) {
                    return (
                        <React.Fragment>
                            <React.Fragment>

                                <Catagories
                                    name="Industries"
                                    catagories={data}
                                    len={data.length}
                                />

                            </React.Fragment>
                        </React.Fragment>
                    );
                }

                return null;
            }}
        </Query>
    </Fragment>
);
