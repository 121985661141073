import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Colors from "../../../../../Colors/Colors";

const BookingPageMainContent = (props) => {
  const [navState, setNavState] = useState(
    localStorage.getItem("navState") ? +localStorage.getItem("navState") : 0
  );
  const [confirmed, setConfirmed] = useState([]);
  const [pending, setPending] = useState([]);
  const [expired, setExpired] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [declined, setDeclined] = useState([]);
  const [all, setAll] = useState([]);
  const [Show, setShow] = useState([]);

  let type = JSON.parse(localStorage.getItem("type"));
  const [isLoading, setLoading] = useState(false);
  const [backArray] = useState([
    "/backOrange.png",
    "/backBlue.png",
    "/backPurple.png",
    "/greenish.png",
  ]);
  const [bookindCatagory] = useState([
    "Confirmed",
    "Pending",
    "Declined",
    "Expired",
    "Cancelled",
    "No Show",
    "Completed",
    "All",
    
    
  ]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings?status=approved`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setConfirmed(response.data.message);
        setLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        if (response.data.message.length > 0) {
          setIsFirstTime(false);
        } else {
          setIsFirstTime(true);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings?status=pending`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setPending(response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/bookings?status=declined&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setDeclined(response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/bookings?status=expired&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setExpired(response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/bookings?status=cancelled&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setCancelled(response.data.message);
      });
  }, []);

// 
 useEffect(() => {
   axios
     .get(
       `${process.env.REACT_APP_SERVER_PATH}/bookings?status=cancelled&limit=100`,
       {
         headers: {
           "Content-Type": "application/json",
           Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
         },
       }
     )
     .then((response) => {
       console.log(">>>upcomming calls from majid", response);
       console.log(response.data.message);
      setShow(response.data.message);
     });
 }, []);



// 
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/bookings?status=completed&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setCompleted(response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings?limit=100`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        console.log(">>>upcomming calls from majid", response);
        console.log(response.data.message);
        setAll(response.data.message);
      });
  }, []);

  let counter = 0;

  return (
    <div className="bg-white rounded sm:pt-2 md:p-4 mb-8">
      <div className="flex flex-wrap">
        {bookindCatagory.map((catagory, index) => {
          return (
            <button
              className={`${
                navState !== index ? "bg-bgGray" : "bg-bgAppColor"
              } ${
                navState !== index ? "text-black" : "text-white"
              } hover:bg-bgAppColor hover:text-white px-3 py-2 text-sm m-1 rounded-lg`}
              onClick={() => {
                localStorage.setItem("navState", index);
                setNavState(index);
              }}
            >
              {catagory}
            </button>
          );
        })}
      </div>
      {navState === 0 && (
        <div className="flex flex-wrap">
          {isLoading ? (
            <div className="flex items-center justify-center w-full">
              <Loader
                type="TailSpin"
                color={Colors.blue}
                height={40}
                width={40}
              />
            </div>
          ) : null}
          {confirmed.length > 0 ? (
            confirmed.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment"
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {navState === 1 && (
        <div className="flex flex-wrap">
          {pending.length > 0 ? (
            pending.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment"
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {navState === 2 && (
        <div className="flex flex-wrap">
          {declined.length > 0 ? (
            declined.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment "
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {navState === 3 && (
        <div className="flex flex-wrap">
          {expired.length > 0 ? (
            expired.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment "
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {navState === 4 && (
        <div className="flex flex-wrap">
          {cancelled.length > 0 ? (
            cancelled.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment "
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {/*  */}
      {navState === 5 && (
        <div className="flex flex-wrap">
          {Show.length > 0 ? (
            Show.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment "
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {/*  */}

      {navState === 6 && (
        <div className="flex flex-wrap">
          {completed.length > 0 ? (
            completed.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment "
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}

      {navState === 7 && (
        <div className="flex flex-wrap">
          {all.length > 0 ? (
            all.map((m) => {
              let counter2 = counter;
              if (counter === 3) {
                counter = 0;
              } else {
                counter += 1;
              }
              console.log(m, "m?.Professionals_Metadatum?.User?.profile_photo");
              console.log(m?.User?.profile_photo, "upcomming");
              if (type === "professional") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.first_name
                        : m.Professionals_Metadatum?.User?.first_name
                    }
                    prof_lastName={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.last_name
                        : m.Professionals_Metadatum?.User?.last_name
                    }
                    professionalImage={
                      props.user.user_id === m.Professionals_Metadatum?.user_id
                        ? m.User?.profile_photo
                        : m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              } else if (type === "customer") {
                return (
                  <Bookings
                    bookingID={m.id}
                    time={m.booking_time}
                    prof_firstName={m.Professionals_Metadatum?.User?.first_name}
                    prof_lastName={m.Professionals_Metadatum?.User?.last_name}
                    professionalImage={
                      m.Professionals_Metadatum?.User?.profile_photo
                    }
                    backImage={backArray[counter2]}
                    user={props.user}
                    session_duration={m.session_duration}
                    setBookingNavState={props.setBookingNavState}
                    setNavState={props.setNavState}
                  />
                );
              }
            })
          ) : (
            <div className="w-full mt-3 text-center flex flex-col items-center justify-center">
              <img
                className="object-cover w-32 "
                src="/appointment.png"
                alt="appointment "
              />
              <p className="font-bold text-textDark mt-2">No booking found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingPageMainContent;

const Bookings = ({
  time,
  prof_firstName,
  prof_lastName,
  professionalImage,
  bookingID,
  session_duration,
  backImage,
}) => {
  const name = prof_firstName + " " + prof_lastName;
  return (
    <Link to={`${bookingID}`}>
      <div
        className="text-white hover:shadow-2xl p-2 pt-4 overflow-y-hidden bg-bgAppColor rounded-xl flex flex-col items-center m-2 h-48"
        style={{
          backgroundImage: `url(${backImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "160px",
        }}
      >
        <div className="flex items-center">
          <img
            src={professionalImage ? professionalImage : " /avatar.png"}
            alt="elo0n"
            className="w-16 h-16 rounded-full object-cover"
          />
          {/* <img src={user.profile_photo ? user.profile_photo : ' /avatar.png'} alt="e" className="z-10 -m-4 w-12 h-12 object-cover rounded-full" /> */}
        </div>
        <p className="text-sm">Meeting at</p>
        <p className="text-xs text-center">{`${moment(time * 1000).format(
          "MMMM DD, YYYY"
        )} ${moment(time * 1000).format("hh:mm A")}`}</p>
        {/* <p className="text-lg mt-1">with</p> */}
        <p className="text-xs mt-1 text-center">
          with {name.slice(0, 7)}...-{session_duration}min
        </p>
        <button
          className="text-xs mt-2 flex items-center justify-center"
          // onClick={() => {
          //     // setBookingNavState(1)
          // }}
        >
          View Details{" "}
          <span className="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </span>
        </button>
      </div>
    </Link>
  );
};
