import React, { useState, useEffect, useRef } from "react";
import AudioTrack from "../AudioTrack";
import VideoTrack from "./VideoTrack";
import { useTrack } from "use-twilio-video";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";


import "./remoteParticipant.css";
import { io } from "socket.io-client";
function Participant({
  participant,
  name,
  flag,
  isMessage,
  setIsMessage,
  isPlaySound,
  setIsNewMessage,
  isNewMessageRef,
  // peer,
}) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({
    participant,
  });

  const audioPlayer = useRef(null);

  function playAudio() {
    if (audioPlayer.current) {
      audioPlayer.current.play();
      isPlaySound.current = false;
    }
  }

  const [messages, setMessages] = useState([]);
  const [loginUser, setLoginUser] = useState({});
  const [booking, setBooking] = useState({});
  const [message, setMessage] = useState([]);
  const [socketState, setSocketState] = useState(null);

  console.log(message);
  const { roomid } = useParams();

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };

  // with smooth-scroll
  const scrollToBottomWithSmoothScroll = () => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // "socket.io-client" - start
    const connectionOptions = {
      "force new connection": true,
      reconnection: true,
      reconnectionDelay: 2000, //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
      reconnectionDelayMax: 60000, //1 minute maximum delay between connections
      reconnectionAttempts: "Infinity", //to prevent dead clients, having the user to having to manually reconnect after a server restart.
      timeout: 10000,
      path: "/sockets",
      auth: {
        token: "KaPd$C&F)J@NcRfUjXn2r5u7x!A%D*G-KaPd",
        bookingID: roomid,
        userID: localStorage.getItem("id"),
      }, //before connect_error and connect_timeout are emitted.
      // "transports": ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
    };
    const socket = io(
      process.env.REACT_APP_SOCKET_SERVER_PATH,
      connectionOptions
    );

    socket.on("connect", () => {
      console.log("hello! socket client ", socket.id); // x8WIv7-mJelg7on_ALbx
      setSocketState(socket);
    });

    socket.on("disconnect", () => {
      console.log("Bye! socket client ", socket.id); // undefined
    });
    return () => {
      if (socket) socket.disconnect();
      console.log("Bye! socket client2 ", socket.id);
    };
    // "socket.io-client" - end
  }, []);

  useEffect(() => {
    scrollToBottom();
    scrollToBottomWithSmoothScroll();
  }, [messages]);

  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer((prev) => !prev);
    }, [20000]);
    fetchBooking();
  }, [timer]);

  useEffect(() => {
    fetchBooking();
  }, []);

  // nabeel

  // const videoRef = useRef(null);
  // useEffect(() => {
  //   if (!peer) return;
  //   console.log("peer set");
  //   peer.on("stream", (stream) => {
  //     console.log("peer stream received");
  //     console.log(stream);
  //     videoRef.current.srcObject = stream;
  //   });
  // }, [peer]);

  // end
  useEffect(() => {
    if (socketState) {
      socketState?.on("messageResponse", (data) => {
        console.log(
          "🚀 ~ file: BookingDetails.js:339 ~ socketState?.on ~ data:",
          data
        );

        if (data.booking_id == booking.id) {
          if (
            data.booking_id == booking.id &&
            data?.action_user != loginUser?.id &&
            data.action == "MESSAGE"
          ) {
            if (isNewMessageRef.current) {
              setIsNewMessage(false);
            } else {
              setIsNewMessage(true);
              if (isPlaySound.current) {
                playAudio();
              }
            }
            const item = {
              ...data,
              _id: Math.random(),
              text: data?.action_data,
              createdAt: new Date(data?.createdAt),
            };

            if (!data.system) {
              item.user = {
                ...data?.user,
                name: data?.user?.name,
              };
            }

            const newMessagesList = [...messages];
            newMessagesList.push(item);

            setMessages(newMessagesList);
          }
        }

        // return () => (ran = true);
      });
    }
  }, [socketState, messages, setMessages]);

  const userId = localStorage.getItem("id");

  const fetchBooking = async () => {
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings/${roomid}`)
      .then((result) => {
        if (result.data) {
          axios
            .get(
              `${process.env.REACT_APP_SERVER_PATH}/booking_logs/${roomid}?limit=1000`
            )
            .then((response) => {
              if (response.data) {
                let details = {};
                let { data } = response;
                let professional = data?.Professionals_Metadatum?.User;
                let customer = data?.User;

                if (professional?.id == userId) {
                  details = {
                    ...details,
                    rating: {
                      ReviewCount: data?.customerReviewCount,
                      AvgRating: data?.customerAvgRating,
                    },
                    LoginUser: { ...professional },
                    OtherUser: { ...customer },
                    bookingLogs: [...data],
                  };
                } else {
                  details = {
                    ...details,
                    rating: {
                      ReviewCount: data?.professionalReviewCount,
                      AvgRating: data?.professionalAvgRating,
                    },
                    LoginUser: { ...customer },
                    OtherUser: { ...professional },
                    bookingLogs: [...data],
                  };
                }
                updateMessages(details);
                setBooking((prevState) => {
                  return { ...prevState, ...result.data };
                });
              }
            })
            .catch((e) => {
              console.log("Error in booking_logs api", e);
            });
        }
      });
  };

  const updateMessages = (details) => {
    const { LoginUser, OtherUser, bookingLogs } = details;
    console.log("updatesocket", details.bookingLogs.User);
    const messages = bookingLogs.map((booking1) => {
      if (booking1.action == "MESSAGE") {
        const data = {
          ...booking1,
          _id: booking1?.id,
          text: booking1?.action_data,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }

        return data;
      }
    });
    setMessages(messages.reverse());
    setLoginUser((prevState) => {
      return { ...prevState, ...LoginUser };
    });
  };

  const ChangeStatus = (status, text) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PATH}/bookings/${roomid}/update_status`,
        { status: status, data: text },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        fetchBooking();
      });
  };

  const addMessage = (e) => {
    e.preventDefault();
    setMessage("");
    ChangeStatus("message", message);
  };

  return (
    <div className="flex items-center">
      <audio ref={audioPlayer} src="/notification/notification-sound.wav" />
      <div
        style={{
          padding: "2%",
          minHeight: "100%",
          minWidth: isMessage ? "75%" : "100%",
        }}
        className={`fixed items-center rounded-lg max-h-full left-0 bottom-0 top-0`}
      >
        {videoOn ? (
          <div
            style={{ minHeight: "100%", minWidth: "100%" }}
            className="items-start bg-gray-500 rounded-lg flex justify-start"
          >
            <VideoTrack
              track={videoTrack}
              name={name}
              isMessage={isMessage}
              setIsMessage={setIsMessage}
            />
            {/* <video id="video" autoPlay ref={videoRef} /> */}
          </div>
        ) : flag === 0 ? (
          <div className="rounded-xl w-72 h-48 bg-gray-600">
            <div className="absolute bg-white m-4 px-4 py-2 rounded-full">
              {name}
            </div>
          </div>
        ) : (
          <div
            style={{ minHeight: "100%", minWidth: "100%" }}
            className="bg-gray-600 rounded-xl"
          >
            <div className="absolute bg-white m-4 px-4 py-2 rounded-full">
              {name}
            </div>
            {/* <video id="video" autoPlay ref={videoRef} /> */}
          </div>
        )}
        <br />
        {audioOn ? (
          <AudioTrack track={audioTrack} />
        ) : (
          <img src="/mute.svg" alt="audio off" className="-mt-2 w-6 h-6" />
        )}
      </div>

      <div
        style={{
          display: isMessage ? "block" : "none",
          padding: "2%",
        }}
        className="fixed z-40 sm:w-full md:w-full lg:w-1/4 rounded-lg max-h-full right-0 bottom-0 top-0"
      >
        <div className="h-full bg-white rounded-lg">
          <div className="flex p-4 rounded-t items-center justify-between text-white text-lg w-full bg-bgAppColor">
            <div className="cursor-pointer">
              <span
                onClick={() => {
                  setIsMessage(false);
                  isNewMessageRef.current = false;
                  isPlaySound.current = true;
                }}
              >
                <svg
                  className="h-6 w-6"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                    fill="currentColor"
                  />{" "}
                </svg>
              </span>
            </div>
            <div className="w-5/6 flex items-center justify-center">
              <p>In-call messages</p>
            </div>
          </div>

          <div
            className="h-4/5 overflow-y-auto px-4 relative scrollbar"
            ref={messagesEndRef}
          >
            {messages.map((message) => {
              if (message === undefined || message === null) return;
              if (message.action === "MESSAGE") {
                const isCustomer = message.user.type === "customer";
                const alignClass = isCustomer ? "justify-end" : "justify-start";
                return (
                  <div className="">
                    <div
                      className={
                        loginUser?.id !== message?.user?._id
                          ? "flex"
                          : `flex ${alignClass}`
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message?.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {isCustomer ? (
                          <>
                            <div className="flex flex-col ml-12">
                              <p
                                className={`text-sm p-3 px-6 flex items-center mr-2 ${
                                  loginUser?.id !== message?.user?._id
                                    ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl break-after-column"
                                    : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                                } `}
                              >
                                {message?.action_data}
                              </p>
                              <div className="flex">
                                <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                                  message.createdAt
                                ).format("MMMM DD,YYYY")} at ${moment(
                                  message.createdAt
                                ).format("hh:mm A")}`}</p>
                              </div>
                            </div>
                            <img
                              src={
                                message?.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="customer"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={
                                message?.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="user"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                            <div className="flex flex-col ml-2">
                              <p
                                className={`text-sm p-3 px-6 flex items-center  mr-2 ${
                                  loginUser?.id !== message?.user?._id
                                    ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                    : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                                } `}
                              >
                                {message?.action_data}
                              </p>
                              <div className="flex">
                                <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                                  message.createdAt
                                ).format("MMMM DD,YYYY")} at ${moment(
                                  message.createdAt
                                ).format("hh:mm A")}`}</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="w-full border-t ">
            <form onSubmit={addMessage} className="flex justify-between p-2">
              <input
                className={`w-full rounded px-2`}
                type="text"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Message ..."
              />
              <div className="ml-2 w-10 h-10">
                <button
                  className={`w-10 h-10 border bg-bgAppColor text-white p-1 rounded-full flex items-center justify-center`}
                  disabled={message.length === 0}
                  type="submit"
                >
                  <img src="/send.png" className="w-24" alt="" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Participant;
