import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import ReactStars from "react-rating-stars-component";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Colors from '../Colors/Colors';
import { CompanyTitle } from '../settings';
import queryString from 'query-string';
import "./userRating.css";
import jwt_decode from 'jwt-decode'

const UserRating = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [type, setType] = useState("")
    let params = queryString.parse(window.location.search)
    console.log("🚀 ~ file: Room.js:18 ~ Room ~ params", params.remoteUser)
    const [rating, setRating] = useState(5)
    const [description, setDescription] = useState('Great session with ' + `${params.remoteUser}`)
    let navigate = useNavigate()
    const { roomid } = useParams();
    // searchParams.get("remoteUser"

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_SERVER_PATH}/users/${jwt_decode(localStorage.getItem("cp_web_token")).id}`
            )
            .then((response) => {
                setType(response.data.type)
            })
            .catch((e) => {
                console.log("Error from professional api in Modal");
            });
    })
    const ratingChanged = (newRating) => {
        setRating(newRating)
    };

    const handleRating = (e) => {
        setSubmitButtonDisabled(true);
        axios.post(`${process.env.REACT_APP_SERVER_PATH}/bookings/${roomid}/review`, {
            rating: rating,
            feedback: description
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
            }
        }).then((response) => {
            navigate(`/dashboard/bookings/${roomid}`)
        }).catch(e => {
            console.log('Error in Feedback page')
        })
    }
    return (
        <div className='w-screen h-screen flex justify-center items-center'>
            <Helmet>
                <title>Review | {CompanyTitle}</title>
                <meta name={`Review | ${CompanyTitle}`} content={`Review | ${CompanyTitle}`} />
            </Helmet>
            <div className='border rounded-lg shadow-lg p-4 w-11/12 md:w-1/3 flex items-center flex-col'>
                <textarea
                    placeholder='Enter your feedback'
                    value={description}
                    maxLength={300}
                    cols={10}
                    onChange={(e) => setDescription(e.target.value)}
                    className='w-full text-sm rounded border p-2 focus:outline-none h-48'></textarea>
                <p className="text-sm text-green-500 w-full text-start mt-2">{300 - description.length} characters remaining</p>
                <ReactStars
                    count={5}
                    value={rating}
                    onChange={ratingChanged}
                    size={40}
                    activeColor="#ffd700"
                />
                {type === "customer" && (
                    <div className='flex gap-3 flex-wrap'>
                        <p className=' rounded text-textDark py-1 px-2 text-center cursor-pointer border-2 rounded-full grow truncate text-style' onClick={(e) => setDescription(`Great session with ${params.remoteUser}`)}>Great session with {`${params.remoteUser}`}</p>
                        <p className=' rounded text-textDark py-1 px-2 text-center cursor-pointer border-2 rounded-full grow truncate text-style' onClick={(e) => setDescription(`Helpful conversation, thanks ${params.remoteUser}`)}>Helpful conversation, thanks {`${params.remoteUser}`}</p>
                        <p className=' rounded text-textDark py-1 px-2 text-center cursor-pointer border-2 rounded-full grow truncate text-style' onClick={(e) => setDescription(`I feel more motivated having spoken to ${params.remoteUser}`)}>I feel more motivated having spoken to {`${params.remoteUser}`}</p>
                        <p className=' rounded text-textDark py-1 px-2 text-center cursor-pointer border-2 rounded-full grow truncate text-style' onClick={(e) => setDescription(`Good guidance on what to do next in my career, thanks ${params.remoteUser}`)}>Good guidance on what to do next in my career, thanks {`${params.remoteUser}`}</p>
                        <p className=' rounded text-textDark py-1 px-2 text-center cursor-pointer border-2 rounded-full grow truncate text-style' onClick={(e) => setDescription(`Already looking forward to our next session, thanks ${params.remoteUser}`)}>Already looking forward to our next session, thanks {`${params.remoteUser}`}</p>
                        <p className=' rounded text-textDark py-1 px-2 text-center cursor-pointer border-2 rounded-full grow truncate text-style' onClick={(e) => setDescription(`Great value for money, thanks ${params.remoteUser}`)}>Great value for money, thanks {`${params.remoteUser}`}</p>
                    </div>
                )}
                <button
                    disabled={submitButtonDisabled}
                    className={`w-full rounded ${submitButtonDisabled ? "text-textDark" : "text-white"} py-2 text-center cursor-pointer mt-2`}
                    onClick={(e) => handleRating(e)}
                    style={{ backgroundColor: submitButtonDisabled ? Colors.bgGray : Colors.blue }}>
                    Submit review
                </button>
            </div>
        </div >
    )
}

export default UserRating