import React from 'react'
import jwt_decode from 'jwt-decode'
import { update_profile_step } from './StepUpdated'
import { Mixpanel } from '../Mixpanel';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { setBannerTrue } from '../Redux/reducers/banner/bannerSlice'

const AccountCreated = (props) => {
    let dispatch = useDispatch()
    let navigate = useNavigate()
    const updateTimeZone = (id) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        axios.put(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`, { time_zone: timezone }).then((response) => {
            console.log("Successfull updated timezone", timezone)
        }).catch(e => {
            console.log("error")
        })
    }
    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <img src="/accountCreated.png" alt="workexperience" className="w-48" />
            <p className="text-2xl mt-3 font-bold">Success!</p>
            <div className='flex mt-2 w-full'>
                <p className='text-center'>Your account has been successfully created. We have sent you a verification link, please verify your email.</p>
            </div>
            {/* <div className="flex mt-6 w-full justify-between">
                <button className="bg-gray-800 hover:bg-black text-white py-3 mb-3 rounded flex justify-center w-1/2"
                    style={{ backgroundColor: Colors.apple }}><a href='https://apps.apple.com/us/app/careerpaths/id1558302311' rel="noreferrer" target="_blank"><img src="appstore.png" alt="apple"
                    /></a></button>
                <button className="ml-2 bg-gray-800 hover:bg-gray-500 text-white py-3 mb-3 rounded flex justify-center w-1/2"
                    style={{ backgroundColor: Colors.apple }}><a href='https://play.google.com/store/apps/details?id=com.careerpaths.mobile' rel="noreferrer" target="_blank"><img src="googleplay.png" alt="apple"
                    /></a> </button>
            </div> */}
            <p className='text-sm font-bold cursor-pointer text-textApp' onClick={() => {
                let linkedin_login = localStorage.getItem('linkedInLogin') && localStorage.getItem('linkedInLogin')
                let google_login = localStorage.getItem('googleLogin') && localStorage.getItem('googleLogin')
                console.log(linkedin_login)
                console.log(typeof linkedin_login)
                if (linkedin_login === '1' || google_login === '1') {
                    updateTimeZone(jwt_decode(localStorage.getItem("customer_signup_token")).id)
                    Mixpanel.track(`web: Profile Completed Successfully.`);
                    update_profile_step(localStorage.getItem('customer_signup_token'), jwt_decode(localStorage.getItem("customer_signup_token")).id, "9")
                    localStorage.setItem('cp_web_token', localStorage.getItem('customer_signup_token'))
                    localStorage.setItem('id', jwt_decode(localStorage.getItem("customer_signup_token")).id)
                    setTimeout(() => {
                        props.toggle()
                        if (window?.location?.pathname?.includes('checkout')) {
                            localStorage.removeItem('navState')
                        } else {
                            navigate("/dashboard/home");
                        }

                    }, [1000])
                } else {
                    if (window?.location?.pathname?.includes('checkout')) {
                        update_profile_step(localStorage.getItem('cp_web_token'), jwt_decode(localStorage.getItem("cp_web_token")).id, "9").then(() => {
                            // dispatch({ type: 'setBannerTrue' })
                            if (window?.location?.pathname?.includes('checkout')) {
                                localStorage.removeItem('navState')
                            } else {
                                localStorage.clear()
                            }
                            dispatch(setBannerTrue())
                            props.toggle()
                        }).catch(e => {
                            console.log("Error in manual login step incrementer")
                        })
                    } else {
                        update_profile_step(localStorage.getItem('customer_signup_token'), jwt_decode(localStorage.getItem("customer_signup_token")).id, "9").then(() => {
                            // dispatch({ type: 'setBannerTrue' })
                            if (window?.location?.pathname?.includes('checkout')) {
                                localStorage.removeItem('navState')
                            } else {
                                localStorage.clear()
                            }
                            dispatch(setBannerTrue())
                            props.toggle()
                        }).catch(e => {
                            console.log("Error in manual login step incrementer")
                        })
                    }
                }

            }}>
                {`${window?.location?.pathname?.includes('checkout') ? 'Go to Checkout' : 'Go to home page'}`}</p>
        </div>
    )
}

export default AccountCreated
