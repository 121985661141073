import React, { useState, useEffect } from 'react'
import Colors from '../Colors/Colors'
import axios from 'axios'
import { update_profile_step } from './StepUpdated'
import jwt_decode from "jwt-decode";
import { Mixpanel } from '../Mixpanel';
import { CompanyTitle } from '../settings';

const PriceAdded = (props) => {
    const [error, setError] = useState(false)
    localStorage.setItem("navState", 15)
    const [session_15_price, setSession_15_price] = useState(null)
    const [session_30_price, setSession_30_price] = useState(null)

    const priceAddHandler = () => {
        if (session_15_price !== null && session_30_price !== null) {
            if (session_15_price >= 5 && session_30_price >= 5) {
                axios.put(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                    session_price_15_min: session_15_price,
                    session_price_30_min: session_30_price
                }).then((r) => {
                    console.log(r);
                    Mixpanel.track(`web: Add booking Price.`, {
                        'session_price_15_min': session_15_price,
                        'session_price_30_min': session_30_price
                    });
                    update_profile_step(localStorage.getItem('signup_token'), jwt_decode(localStorage.getItem("signup_token")).id, "9")
                    props.setNavState(15)
                }).catch(e => {
                    console.log('Error');
                })
            } else {
                setError(true)
            }

        }
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${props.ProfessionalId}`).then((response) => {
            // console.log("from yearly price",response)
            setSession_15_price(response.data.session_price_15_min)
            setSession_30_price(response.data.session_price_30_min)
        }).catch(e => {
            console.log(e)
        })
    }, [])
    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <img src="/addPrice.png" alt="workexperience" className="w-36" />
            <p className="text-2xl font-bold mt-3 text-textFullDark">Price Added</p>
            <p className="text-xs mt-3">{CompanyTitle} helps you boost your productivity on a differnet level </p>
            <div className="w-full flex mt-4">
                <div className="w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="15min">
                        15 min
                    </label>
                    <div className="flex border border-borderGray rounded-lg ">
                        <p className="px-4 flex items-center justify-center rounded text-white" style={{ backgroundColor: Colors.darkBlue }}>$</p>
                        <input className="text-sm rounded-lg w-full  py-2 px-3 text-textFullDark  focus:outline-none" id="firstName" type="text" placeholder="150.00" value={session_15_price} />
                    </div>
                </div>
                <div className="w-1/2 ml-1">
                    <label className="block text-textFullDark text-sm mb-2" for="30min">
                        30 min
                    </label>
                    <div className="flex border border-borderGray rounded-lg ">
                        <p className="px-4 flex items-center justify-center rounded text-white" style={{ backgroundColor: Colors.darkBlue }}>$</p>
                        <input className="text-sm rounded-lg w-full  py-2 px-3 text-textFullDark  focus:outline-none" id="30min" type="text" placeholder="300.00" value={session_30_price} />
                    </div>
                </div>
            </div>
            {error && <p className='text-red-500 text-xs font-bold'>Price for both must be greater than 5</p>}
            <p className="mt-4 font-light underline" style={{ color: Colors.blue }}>Let us adjust your price accordingly</p>
            <div className="w-full flex justify-between items-center mt-4 text-white">
                <button className="py-2  px-8 rounded-lg" style={{ backgroundColor: Colors.blue }}
                    onClick={() => { props.setNavState(props.navState - 1) }}>Back</button>
                <button className="py-2  px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue }}
                    onClick={priceAddHandler}>
                    <p >Next</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </button>
            </div>

        </div>
    )
}

export default PriceAdded
