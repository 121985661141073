import { configureStore } from '@reduxjs/toolkit'
import anonymousSlice from './reducers/anonymous/anonymousSlice';
import bannerSlice from './reducers/banner/bannerSlice';
import Tooltip from './reducers/ToolTip/ToolTip';
import charitySlice from './reducers/charity/charitySlice';
import customerSlice from './reducers/customer/customerSlice';
import freeCallSlice from './reducers/freeCall/freeCallSlice';
import navSlice from './reducers/nav/navSlice';
import paymentSlice from './reducers/payment/paymentSlice';
import professionalSlice from './reducers/professional/professionalSlice';
import profileSlice from './reducers/profile/profileSlice';
import reviewSlice from './reducers/review/reviewSlice';
import voucherSlice from './reducers/voucher/voucherSlice';
import redirectSlice from './reducers/redirect/redirectSlice';
import Scroll from './reducers/Scroll/Scroll';
import searchBar from './reducers/searchBar/searchBar';
import pebSlice from './reducers/peb/pebSlice';
export const store = configureStore({
  reducer: {
    anonymous : anonymousSlice,
    banner : bannerSlice,
    tooltip:Tooltip,
    charity:charitySlice,
    customer : customerSlice,
    freeCall : freeCallSlice,
    nav : navSlice,
    payment : paymentSlice,
    professional: professionalSlice,
    profile : profileSlice,
    review : reviewSlice,
    voucher : voucherSlice,
    redirect :redirectSlice,
    scroll: Scroll,
    searchBar: searchBar,
    PEBtoggle: pebSlice
  },
  
})