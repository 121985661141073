import React, { useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { Mixpanel } from '../../../../../Mixpanel';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";



const AddExperience = (props) => {
    const [isProfileFromCP, setIsProfileFromCP] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    let loading = open && options.length === 0;
  
    const searchHandler = async (value) => {
      let active = true;
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_PATH}/company?search=${value}`
      );

      const items = response.data;
      if (!loading && items.length === 0) {
        return undefined;
      }
      if (active) {
        setOptions([...items]);
      }
  
      return () => {
        active = false;
      };
    };
  
  
    useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);
    
    const DropdownWithButton = ({ children, ...other }) => (
    <Paper {...other}>
        {companyName && 
        <div className="flex w-full items-center hover:bg-gray-300 bg-gray-50 cursor-pointer">
            <Button
          sx={{ width: "100%",textTransform: "none", padding:1, justifyContent: "flex-start" }}
          onMouseDown={(e) => {
            e.preventDefault();
            setIsProfileFromCP(false)
            setOpen(false);
            document.querySelector(`input[name=job]`).focus();
        }}
          >
            <img src='/educationplaceholder.png' alt="educationplaceholder" className="flex-shrink-0 h-6 w-6 rounded-full" />
            <p className="ml-3 block font-normal truncate">+ Add Company: {companyName}</p>
        </Button>
            </div>
        }
        {children}
    </Paper>
    );
    
    const navigate = useNavigate()
    const [values, setValues] = useState({});
    const [isLoading, setLoading] = useState(false)
    const [imgUrl, setImageUrl] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [currentlyWorking, setCurrentlyWorking] = useState(false)
    const [fileInput, setFileInput] = useState()
    const [image, setImage] = useState(null)
 const [type, setType] = useState("text");
 const [types, setTypes] = useState("text");
    const UploadImage = (id) => {
        const formDataExp = new FormData();
        if(!isProfileFromCP){
            formDataExp.append("file", image);
        } 
        formDataExp.append("experience_id", id);
        axios.post(process.env.REACT_APP_SERVER_PATH + `/document/experience/`, formDataExp).then((response) => {
            navigate('../../experience')
            setLoading(false)
        }).catch(e => {
            console.log("error is from uploading");
        })
    }
    const UploadImageOfCompany = (id) => {
        const formDataExp = new FormData();
        if(!isProfileFromCP){
            formDataExp.append("file", image);
        } 
        formDataExp.append("company_id", id);
        axios.post(process.env.REACT_APP_SERVER_PATH + `/document/company/`, formDataExp).then((response) => {
            navigate('../../experience')
            setLoading(false)
        }).catch(e => {
            console.log("error is from uploading");
        })
    }
    const validate = Yup.object({
        description: Yup.string().trim()
            .required(<p className='text-red-600 text-xs mt-1'>Required</p>),
        location: Yup.string().trim()
            .required(<p className='text-red-600 text-xs mt-1'>Required</p>),
        company: Yup.string().trim()
            .required(<p className='text-red-600 text-xs mt-1'>Required</p>),
        job: Yup.string().trim()
            .required(<p className='text-red-600 text-xs mt-1'>Required</p>),
    })
    const formik = useFormik({
        initialValues: {
            description: '',
            location: '',
            company: '',
            job: ''
        },
        validationSchema: validate,
        onSubmit: (values) => {
            console.log("comapny has been added enter")

            setLoading(true)
            const Data = {
                user_id: JSON.parse(localStorage.getItem("id")),
                company: values.company,
                job: values.job,
                title: values.job,
                employement_type: "full time",
                current_job: currentlyWorking ? '1' : '0',
                location: values.location,
                start_date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}`,
                end_date: currentlyWorking ? `${endDate.getFullYear()}-${endDate.getMonth() + 1}` : `${endDate.getFullYear()}-${endDate.getMonth() + 1}`,
                description: values.description,
                status: "",
                profile_photo:imgUrl
            }
            axios.post(`${process.env.REACT_APP_SERVER_PATH}/experience`, Data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
                }
            }).then((response) => {
                console.log("comapny has been added 1")
                Mixpanel.track(`web: Add Experience.`, Data);

                if (imgUrl === '') {
                    navigate('../../experience')
                    setLoading(false)
                } else {
                   if(!isProfileFromCP){
                       UploadImage(response.data.id)
                       UploadImageOfCompany(response.data.company_id);
                   }else{
                    navigate('../../experience')
                    setLoading(false)
                   }
                }
            }).catch(e => {
                console.log(e)
                setLoading(false)
            })
        }
    });

    const handleImg = (event) => {
        console.log(event.target.files[0]);
        if (event.target.files[0].type === 'image/jpeg'
            || event.target.files[0].type === 'image/png') {
            setImageUrl(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        }

    }

    const handleStartDate = (e) => {
        setStartDate(new Date(e.target.value))
        // setStartDate(moment(e.target.value).format("MM YYYY"))

    }
    const handleEndDate = (e) => {
        setEndDate(new Date(e.target.value))
    }

    const handleChange = (event) => {
        setValues(prevValues => ({
            ...prevValues,
            // we use the name to tell Formik which key of `values` to update
            [event.target.name]: event.target.value
        }))
    }
    return (
      <div>
        <div className="w-full flex items-center justify-center flex-col">
          <input
            className="text-xs text-white ml-2"
            type="file"
            onChange={handleImg}
            ref={(fileinput) => setFileInput(fileinput)}
            style={{ display: "none" }}
          />
          <img
            src={imgUrl ? imgUrl : " /experienceplaceholder.png"}
            alt="images"
            className="w-16 h-16 rounded-full object-cover text-textFullDark"
          />
          {!isProfileFromCP ? (
            <button
              className="mt-3"
              onClick={() => {
                fileInput.click();
              }}
            >
              Add Logo
            </button>
          ) : null}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="company"
            >
              Company
            </label>
            <Autocomplete
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name ?? option}
              name="company"
              freeSolo={true}
              PaperComponent={DropdownWithButton}
              options={options}
              renderOption={(props, option) => (
                <div
                  {...props}
                  className="flex items-center p-2 hover:bg-gray-300 bg-gray-50 cursor-pointer"
                >
                  <img
                    src={
                      option?.profile_photo
                        ? option?.profile_photo
                        : "/educationplaceholder.png"
                    }
                    alt={option?.name}
                    className="flex-shrink-0 h-6 w-6 rounded-full"
                  />
                  <span className="ml-3 block font-normal truncate">
                    {option?.title}
                    {option?.name}
                  </span>
                </div>
              )}
              sx={{ alignItems: "center" }}
              onInputChange={(e, value) => {
                handleChange(e);
                setCompanyName(value);
                searchHandler(value);
              }}
              closeIcon=""
              onChange={(e, obj) => {
                if (obj) {
                  if (obj.name) {
                    setIsProfileFromCP(true);
                    formik.setFieldValue("company", obj.name);
                    setImageUrl(obj.profile_photo);
                    setCompanyName(obj.name);
                  } else {
                    setCompanyName(obj.name);
                    setIsProfileFromCP(false);
                  }
                  document.querySelector(`input[name=job]`).focus();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setCompanyName(e.target.value);
                  setIsProfileFromCP(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="company"
                  name="company"
                  type="text"
                  placeholder="Company"
                  onChange={(e) => {
                    handleChange(e);
                    setCompanyName(e.target.value);
                    searchHandler(e.target.value);
                  }}
                  {...formik.getFieldProps("company")}
                  defaultValue={formik.values.company}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <React.Fragment></React.Fragment>,
                  }}
                />
              )}
            />
            {formik.touched.company && formik.errors.company ? (
              <div>{formik.errors.company}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-textFullDark text-sm mb-2" for="job">
              Job
            </label>
            <input
              className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none"
              id="job"
              type="text"
              placeholder="Enter Job title"
              value={formik.values.job}
              name="job"
              onChange={handleChange}
              {...formik.getFieldProps("job")}
            />
            {formik.touched.job && formik.errors.job ? (
              <div>{formik.errors.job}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="location"
            >
              Location
            </label>
            <input
              className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none"
              id="location"
              type="text"
              placeholder="Enter location name"
              value={formik.values.location}
              name="location"
              onChange={handleChange}
              {...formik.getFieldProps("location")}
            />
            {formik.touched.location && formik.errors.location ? (
              <div>{formik.errors.location}</div>
            ) : null}
          </div>

          <div className="flex mb-4 items-center">
            <input
              type="checkbox"
              value={currentlyWorking}
              onChange={() => {
                setCurrentlyWorking(!currentlyWorking);
              }}
            />
            <p className="ml-3 text-sm text-textDark">Currently working here</p>
          </div>

          <div className="flex w-full justify-between">
            <div className="w-1/2">
              <label
                className="block text-textFullDark text-sm mb-2"
                for="firstName"
              >
                Start Date
              </label>
              <input
                className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none"
                id="firstName"
            
                // placeholder="Enter first name"
                onChange={handleStartDate}
                type={type}
                placeholder="MM/YY"
                onFocus={() => setType("month")}
                onBlur={() => setType("text")}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            {!currentlyWorking ? (
              <div className="ml-2 w-1/2">
                <label
                  className="block text-textFullDark text-sm mb-2"
                  for="firstName"
                >
                  End Date
                </label>
                <input
                  className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none"
                  id="firstName"
                 
                  //   placeholder="Enter first name"
                  onChange={handleEndDate}
                  placeholder="MM/YY"
                  type={types}
                  onFocus={() => setTypes("month")}
                  onBlur={() => setTypes("text")}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
            ) : null}
          </div>

          <div className="my-4">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="location"
            >
              Description
            </label>
            <textarea
              maxlength="280"
              className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none"
              id="description"
              type="text"
              placeholder="Description"
              value={formik.values.description}
              name="description"
              onChange={handleChange}
              {...formik.getFieldProps("description")}
            />
            <p className="text-sm text-green-500">
              {280 - formik.values.description.length} characters remaining
            </p>
            {formik.touched.description && formik.errors.description ? (
              <div>{formik.errors.description}</div>
            ) : null}
          </div>
          <button
            className="bg-bgAppColor text-white text-center w-full text-sm rounded flex items-center justify-center mt-3 py-3 hover:bg-blue-300"
            type="submit"
          >
            {isLoading ? (
              <span>
                <Loader
                  type="TailSpin"
                  color="white"
                  height={20}
                  width={20}
                  // timeout={5000} //5 secs
                />
              </span>
            ) : (
              "Save"
            )}
          </button>
        </form>
      </div>
    );
}

export default AddExperience
