import React from 'react'

function descriptionCharityMain(props) {
  return (
   <div>
            <div className="flex w-full flex-col items-center justify-center">
            <div className="w-full flex items-center mb-2 py-4">
              <img
                src="/charity/aspiration.png"
                className="w-8 h-8 rounded object-cover"
                alt=''
              />
              <p className="text-xl font-bold ml-2">Aspiration</p>
            </div>
            <p className="text-sm text-textFullDark">
              Our aspiration is to use our platform to improve social mobility,
              inclusivity and democratise talent development. And we would like
              to extend this opportunity to all our professionals, coaches and
              mentors.
            </p>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="w-full flex items-center mb-2 py-4">
              <img
                src="/charity/opportunity.png"
                className="w-8 h-8 rounded object-cover"
                alt=''
              />
              <p className="text-xl font-bold ml-2">Opportunity</p>
            </div>
            <p className="text-sm text-textFullDark">
              CareerPaths gives you the opportunity to donate a percentage of
              your earnings to charities who are helping empower young people
              realise their ambitions. Working in partnership with these
              charities we aim to improve access to the world of work and
              enhance future career prospects.
            </p>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="w-full flex items-center mb-2 py-4">
              <img
                src="/charity/honor.png"
                className="w-8 h-8 rounded object-cover"
                alt=''
              />
              <p className="text-xl font-bold ml-2">Your charity badge</p>
            </div>
            <p className="text-sm text-textFullDark">
              If you choose to donate, your online profile will be tagged with
              our Charity Badge. The badge will highlight to customers that you
              have chosen to donate a proportion of earnings to charity.{" "}
            </p>
          </div>

   </div>
    )
}


export default descriptionCharityMain
