import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import Colors from "../Colors/Colors";
import { CompanyTitle } from "../settings";
import { setProfessionalsToTrue } from '../Redux/reducers/professional/professionalSlice'
const Footer = () => {
    let dispatch = useDispatch()
    let navigate = useNavigate()
    const [links] = useState(["Professional Sign Up", "Referral Rewards", "About Us", "Privacy Policy", "FAQs", "help", "Terms & Conditions"])
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://app.mailjet.com/pas-nc-embedded-v1.js';
        script.async = true;

        document.body.appendChild(script);
    }, [])

    const applyAsMentor = () => {
        dispatch(setProfessionalsToTrue())
    }
    return (
        <div >
            <div className="bg-bgGray mb-6 sm:px-2 md:px-4 py-6" style={{
                backgroundImage: 'url(/footer.png)', backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }} >
                <div className="md:container mx-auto sm:px-2 xl:px-16 py-2 flex sm:flex-col md:flex-row  justify-between items-center">
                    <div className="sm:w-full md:w-2/6">
                        <div className="-ml-5 -mt-10 text-gray-800">
                            <div className="">
                                <iframe 
                                   data-w-type="embedded"
                                    frameborder="0"
                                //     scrolling="no"
                                    marginheight="0"
                                    marginwidth="0"
                                    src="https://01hq5.mjt.lu/wgt/01hq5/xw1j/form?c=5154e752"
                                    className="w-full"
                                    style={{ height: '100%' }}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-start sm:w-full md:w-1/3">
                        <div className="flex flex-row items-center justify-between">
                            <a href="https://www.linkedin.com/company/careerpathsio/" target='_blank' rel="noreferrer" ><img src="/linkedinPic.png" alt="linkedin" className="rounded mr-3 w-5 h-5" /></a>
                            <a href="https://www.instagram.com/careerpathsapp/" target='_blank' rel="noreferrer" ><img src="/instagramPic.png" alt="fb" className="rounded mr-3 w-5 h-5" /></a>
                            <a href="mailto:hello@careerpaths.io" target='_blank' rel="noreferrer" ><img src="/emailicon2.png" alt="fb" className="rounded w-6 h-6" /></a>
                        </div>
                        <button onClick={applyAsMentor}>
                            <p className="text-xs text-textDark my-4">Ready to become a mentor on {CompanyTitle}?</p>
                        </button>

                        <div className="flex flex-col md:flex-row w-full">
                            <button className="bg-gray-800 hover:bg-black text-white py-3 mb-3 rounded flex justify-center w-full md:36 h-12"
                                style={{ backgroundColor: Colors.apple }}><a href='https://apps.apple.com/us/app/careerpaths/id1558302311' rel="noreferrer" target="_blank"><img src="/appstore.png" alt="apple"
                                /></a> </button>
                            <button className="md:ml-4 bg-gray-800 hover:bg-black text-white py-3 w-full md:36 h-12 rounded flex justify-center"
                                style={{ backgroundColor: Colors.apple }}><a href='https://play.google.com/store/apps/details?id=com.careerpaths.mobile' rel="noreferrer" target="_blank"><img src="/googleplay.png" alt="apple"
                                /></a></button>
                        </div>

                        <div className="flex my-4 sm:w-full">
                            <button className="sm:mr-4 text-xs text-textDark hover:text-black" onClick={() => {
                                navigate('/privacy-policy')
                            }}>{links[3]}</button>
                            <button className="mr-4 text-xs text-textDark hover:text-black" onClick={() => {
                                navigate('/terms-and-conditions')
                            }}>{links[6]}</button>
                            <button className="mr-4 text-xs text-textDark hover:text-black" onClick={() => {
                                navigate('/faqs')
                            }}>{links[4]}</button>
                            <button className="mr-4 text-xs text-textDark hover:text-black" onClick={() => {
                            }}><Link to="/support">Contact</Link></button>
                        </div>
                        <div className="flex flex-wrap mt-4 mb-4 md:mt-0">
                            <img src="/visapay.png" className="w-12 object-contain" alt="" />
                            <img src="/gpay.png" className="w-12 ml-2 object-contain" alt="" />
                            <img src="/orangepay.png" className="w-12 ml-2 object-contain" alt="" />
                            <img src="/amexpay.png" className="w-12 ml-2 object-cover" alt="" />
                            <img src="/appplepay.png" className="w-12 ml-2 object-contain" alt="" />
                        </div>

                    </div>

                </div>
            </div>
            <p className="text-sm text-textDark text-center mb-3">Copyright &copy; 2021 {CompanyTitle}. All Rights Reserved. | <span className="text-textDark hover:text-black cursor-pointer" onClick={() => {
                navigate('/privacy-policy')
            }}>Privacy Policy</span></p>
        </div>
    );

}

export default Footer;