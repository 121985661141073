import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    peb: false,
}

export const pebSlice = createSlice({
    name: "PEBtoggle",
    initialState,
    reducers: {
        setpebtotrue: (state, action) => {
            state.peb = true
        },
        setpebtofalse: (state) => {
            state.peb = false
        }
    },
    extraReducers: {
    }
})

export const { setpebtotrue, setpebtofalse } = pebSlice.actions;
export default pebSlice.reducer;