import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    url : '/',
}

export const redirectSlice = createSlice({
    name:"redirect",
    initialState,
    reducers:{
        RedirectTo :(state, action)=>{
        console.log("redirect to", action.payload.url)
         state.url = action.payload.url;
        },
    },
    extraReducers:{
    }
})

export const { RedirectTo } = redirectSlice.actions;
export default redirectSlice.reducer;