import React, { Fragment } from "react";
import { Query } from "./../Query";
import FeaturedSkeleton from "../Skeletons/featuredImagesSkeleton_landingPage";
import RoundedComponent from "../roundedComponent/roundedComponent";

export const Companies = () => (
    <Fragment>
        <Query url={`${process.env.REACT_APP_SERVER_PATH}/company?featured=1&limit=100`}>
            {({ state: { data, loading, error }, actions }) => {
                if (loading) {
                    return (
                        <FeaturedSkeleton />
                    )
                }
                if (error) {
                    return <p style={{ color: "red" }}>{error.message}</p>;
                }
                if (data) {
                    return (
                        <React.Fragment>
                            <React.Fragment>

                                <RoundedComponent
                                    name="Mentors featured from"
                                    Company={data}
                                    len={data.length}
                                />

                            </React.Fragment>
                        </React.Fragment>
                    );
                }
                return null;
            }}
        </Query>
    </Fragment>
);
