import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../../../settings'
import { useSelector, useDispatch } from 'react-redux'
import Loader from 'react-loader-spinner'
import Colors from '../../../Colors/Colors'
import { toggleAnonymous } from '../../../Redux/reducers/anonymous/anonymousSlice'
const Anonymous = ({ user }) => {
    const [isAnonymous, setAnonymous] = useState(false)
    const [isLoading, setLoading] = useState(false)
    let dispatch = useDispatch()
    const isAnonymousModal = useSelector(state => state.anonymous.toggleAnonymous)
    // const [isAnonymousModal] = useState(false)
    const toggleAnonymousToTrue = () => {
        setLoading(true)
        window.scroll(0, 0)
        let id = localStorage.getItem("id")
        axios.put(process.env.REACT_APP_SERVER_PATH + `/professionals/${id}`, { is_anonymous: true }).then((response) => {
            setLoading(false)
            getAnonymousInfo()
            toggleAnonymous1()
        }).catch(function (error) {
            setLoading(false)
            console.log('Error in geting Anomysss')
        });
    }
    const toggleAnonymousToFalse = () => {
        setLoading(true)
        window.scroll(0, 0)
        let id = localStorage.getItem("id")
        axios.put(process.env.REACT_APP_SERVER_PATH + `/professionals/${id}`, { is_anonymous: false }).then((response) => {
            setLoading(false)
            getAnonymousInfo()
            toggleAnonymous1()
        }).catch(function (error) {
            setLoading(false)
            console.log('Error in geting Anomysss')
        });
    }
    const getAnonymousInfo = () => {
        setLoading(true)
        let id = localStorage.getItem("id")
        axios.get(process.env.REACT_APP_SERVER_PATH + `/professionals/${id}`).then((response) => {
            setLoading(false)
            setAnonymous(response.data.is_anonymous === 1 ? true : false)
        }).catch(function (error) {
            setLoading(false)
            console.log('Error in geting Anomysss')
        });
    }
    const toggleAnonymous1 = () => {
        // dispatch({ type: 'toggleAnonymous' })
        dispatch(toggleAnonymous())
    }
    useEffect(() => {
        window.scroll(0, 0)
        getAnonymousInfo()
    }, [])
    return (
        <div className="bg-white pb-8 mb-4 rounded p-6">
            {!isLoading ? <div>
                {isAnonymousModal && <AnonymousModal
                    toggle={toggleAnonymous1}
                    overlay="overlay"
                    modal="modal"
                    isAnonymous={isAnonymous}
                    isLoading={isLoading}
                />}
                <Helmet>
                    <title>Anonymous | Dashboard | {CompanyTitle}</title>
                    <meta name={`Anonymous | Dashboard | ${CompanyTitle}`} content={`Anonymous | Dashboard | ${CompanyTitle}`} />
                </Helmet>
                <div className='flex w-full flex-col items-center justify-center border-b'>
                    <img src='/anonymous.png' className='w-20 h-20 rounded-full object-cover mb-8' alt='' />
                    <p className='text-3xl font-bold text-textFullDark text-center'>{isAnonymous ? 'Your profile is Anonymous' : 'Make your profile anonymous'}</p>
                    {isAnonymous ? <p className='text-lg text-textFullDark text-center my-4'>Your name and profile picture is hidden and cannot be seen by other users</p> : <p className='text-lg text-textFullDark text-center my-4'>Make your profile private by hiding your name and profile picture</p>}
                </div>
                <div className='flex w-full flex-col items-center justify-center'>
                    <div className='w-full flex items-center mb-2 py-4'>
                        <img src='/privateanonymous.png' className='w-8 h-8 rounded object-cover' alt='' />
                        <p className='text-xl font-bold ml-2'>Private</p>
                    </div>
                    <p className='text-sm text-textFullDark'>{CompanyTitle} allows you the option to remain anonymous. This means your profile picture and name will be hidden from Customers and fellow Professionals. Please note that your Bio, Company Name, Role Title and Experience will still be shown on your profile. This gives customers just enough information to know whether you can support their career development.</p>
                </div>
                <div className='flex w-full flex-col items-center justify-center'>
                    <div className='w-full flex items-center mb-2 py-4'>
                        <img src='/dangeranonymous.png' className='w-8 h-8 rounded object-cover' alt='' />
                        <p className='text-xl font-bold ml-2'>Be Aware</p>
                    </div>
                    <p className='text-sm text-textFullDark'>Our customer research has shown that going anonymous reduces the probability of a successful booking by up to 80%. We would highly recommend keeping your profile public. You are helping the next generation of talent to succeed! That's something to shout about, not hide!</p>
                </div>
                <p className='text-center text-xs my-2'>If you have any questions related to the Anonymous feature, please contact </p>
                <p className='text-center text-lg text-textApp font-bold'><a href='mailto:hello@careerpaths.io'>hello@careerpaths.io</a></p>
                <button className={`py-4 flex items-center justify-center ${isAnonymous ? 'bg-bgAppColor' : 'bg-red-500'} text-white w-full rounded my-4`} onClick={isAnonymous ? toggleAnonymousToFalse : toggleAnonymousToTrue}>{isAnonymous ? 'Make your profile public' : 'Make your profile anonymous'}</button>

            </div> : <div className='w-full flex justify-center items-center h-full'>
                <Loader
                    width={30}
                    height={30}
                    type="Oval"
                    color={Colors.blue}
                />
            </div>}

        </div>
    )
}

export default Anonymous


const AnonymousModal = (props) => {
    return (
        <div className={props.modal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className={props.overlay}></div>
            <div className="flex flex-col items-center justify-center w-11/12 md:w-1/3 z-20 bg-white rounded-xl p-8">
                {!props.isLoading ? <div>
                    {props.isAnonymous ? <div className='flex flex-col items-center justify-center'>
                        <img src='/anonymous.png' className='w-20 h-20 rounded-full object-cover mb-8' alt='' />
                        <p className='text-3xl text-center font-bold'>Your profile is now Anonymous</p>
                        <p className='text-sm text-center my-3'>Your name and profile picture will now be hidden from all users</p>
                    </div> : <div className='flex flex-col items-center justify-center'>
                        <img src='/anonymous.png' className='w-20 h-20 rounded-full object-cover mb-6' alt='' />
                        <p className='text-3xl text-center font-bold mb-3'>Your profile is now Public</p>
                    </div>}
                    <button className='w-full flex justify-center items-center p-2 bg-bgAppColor text-white rounded font-bold' onClick={props.toggle}>Ok</button>
                </div> : <div className='w-full flex justify-center items-center h-full'>
                    <Loader
                        width={200}
                        height={200}
                        type="Oval"
                        color={Colors.blue}
                    />
                </div>}

            </div>
        </div>
    )
}