import axios from 'axios'
import React, { useState } from 'react'

const ChangePassword = (props) => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setconfirmNewPassword] = useState('')
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const handleSubmit = () => {
        let values = {
            old_password: oldPassword,
            new_password: newPassword
        }
        axios.patch(process.env.REACT_APP_SERVER_PATH + `/users/${+localStorage.getItem('id')}/password/reset`, values)
            .then((response) => {
                props.setNavState(1)
            }).catch(e => {
                setErrorText(e.response.data.errors[0])
                setError(true)
            })
    }
    return (
        <div>
            <p className="font-bold mt-4 text-textFullDark text-2xl">Change Password</p>
            <div className="pt-6 mb-4">
                <div className="mb-4">
                    <label className="block text-textFullDark text-sm mb-2" for="oldPassword">
                        Enter your old password
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none" id="oldPassword" type="password" placeholder="xxxxxxxx" value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-textFullDark text-sm mb-2" for="newPassword">
                        Enter your new password
                    </label>
                    <input className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none" id="newPassword" type="password" placeholder="xxxxxxx"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-textFullDark text-sm mb-2" for="confirmPassword">
                        Confirm new password
                    </label>
                    <input className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none" id="confirmPassword" type="password" placeholder="xxxxxxx"
                        value={confirmNewPassword}
                        onChange={(e) => setconfirmNewPassword(e.target.value)} />
                </div>
                {error && <p className='text-xs mb-4 text-red-500 font-bold'>{errorText}</p>}
                <button className={`w-full text-sm py-3 text-white rounded ${(oldPassword !== '' && newPassword !== '' && confirmNewPassword !== '') ? 'bg-bgAppColor' : 'bg-bgdarkGray'}`}
                    disabled={oldPassword === '' ? true : false}
                    onClick={() => {
                        if (newPassword === confirmNewPassword) {
                            handleSubmit()
                        } else {
                            setErrorText('Password didn\'\ t match')
                            setError(true)
                        }
                    }}>Submit</button>
            </div>
        </div>
    )
}

export default ChangePassword
