import React from 'react'

const FormCookies = (props) => {
    return (
        <div className='md:w-5/12 bg-gray-700 m-4 flex flex-col items-start justify-start p-8 z-50 rounded'>
            <div className='w-full flex justify-end'>
                <span onClick={() => props.setForm(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>
            </div>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mb-4" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
            </span>
            <p className='text-white text-4xl' 
            style={{ fontFamily: 'Lato Bold' }}
            
            >10% off your first purchase</p>
            <p className='text-white text-xl'
             style={{ fontFamily: 'Lato Bold' }}
            
            >Connect with your favorite mentors!</p>
            <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-white bg-transparent font-light focus:outline-none my-8" id="email" type="text" placeholder="Enter email"
            />
            <button className='bg-bgAppColor text-lg text-white w-full rounded-lg py-2 mb-8'>Submit</button>
            <p className='text-xs text-white' 
            style={{ fontFamily: 'Lato Bold' }}
            
            >New customer only. Offer valid for next 24 hours. Must create a new user account and enter promotional code at checkout to redeem offer. Cannot be combined with other offers. By providing your email, you agree to recieve email marketing communication.</p>
        </div>
    )
}

export default FormCookies