import React from "react";
import moment from "moment";
export default function AboutCourse() {
  return (
    <div className="md:mr-10 text-textFullDark bg-bgGray py-10 px-10 sm:w-full lg:w-3/4  rounded-lg ">
      <div className=" text-textFullDark">
        <h1 className="text-3xl font-bold ">About this course</h1>
        <p className="text-md">21 recent views</p>
      </div>
      <div className="my-2 md:my-6 py-3.5 w-full md:w-9/12 ">
        <p className="text-textFullDark text-lg tracking-wide leading-7">
          The course deals with the analysis of the private equity and venture
          capital business. Over the course, students will be provided with a
          deep understanding of the mechanism underpinning the creation and/or
          development of a firm and the financial support it can get from the
          financial system through venture capital investment.
        </p>
      </div>

      <div className="mt-2 md:mt-6 py-2 w-full">
        <h1 className="text-xl font-bold ">Skills you will gain</h1>
        <div className="mt-2 ">

          <button
            className={` bg-bgAppColor text-white font-bold rounded-full m-1 pb-1 pt-1.5 px-3 text-sm`}
          >
            Private Equity
          </button>
          <button
            className={` bg-bgAppColor text-white font-bold rounded-full m-1 pb-1 pt-1.5 px-3 text-sm`}
          >
            Venture Capital
          </button>
          <button
            className={` bg-bgAppColor text-white font-bold rounded-full m-1 pb-1 pt-1.5 px-3 text-sm`}
          >
             Business financing 
          </button>
          <button
            className={` bg-bgAppColor text-white font-bold rounded-full m-1 pb-1 pt-1.5 px-3 text-sm`}
          >
            Funding lifecycle
          </button>
        </div>
      </div>
    </div>
  );
}
