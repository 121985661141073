import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
// import Navbar from '../../Navbar/Navbar'
// import MainContent from '../MainContent/MainContent'
import RightSide from "../rightSide/rightSide";
import Sidebar from "../Sidebar/Sidebar";
import Home from "../MainContent/Home/Home";
import ProfileTop from "../MainContent/Profile/ProfileTop/ProfileTop";
// import ProfileBio from '../MainContent/Profile/ProfileBio/ProfileBio'
import ViewBookings from "../MainContent/Bookings/ViewBookings/ViewBookings";
import Payments from "../MainContent/Payments/Payments";
import NotificationsSetting from "../MainContent/NotificationsSetting/NotificationsSetting";
import ChangeEmailComponent from "../MainContent/ChangeEmailComponent/ChangeEmailComponent";
import ChangePasswordComponent from "../MainContent/ChangePasswordComponent/ChangePasswordComponent";
import Profile from "../MainContent/Profile/Profile";
import Colors from "../../Colors/Colors";
import Availability from "../MainContent/Availability/Availability";
import { Route, Routes } from "react-router-dom";
import VideoChat from "../../VideoCall/videoChat";
import Footer from "../../Footer/Footer";
import Anonymous from "../MainContent/Anonymous/Anonymous";
import GetReferal from "../MainContent/GetReferal/GetReferal";
import Charity from "../MainContent/Charity/Charity";
import PEBProfile from "../MainContent/PEB/PEBProfile";
import { useRef } from "react";
import "./Dashboard.css";
const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);
  const [bookingNavState, setBookingNavState] = useState(0);
  const [isNav, setIsNav] = useState(false);
  const [image, setimage] = useState();
  // const [isModalOpen, setIsModalOpen] = useState(true);
  const modalAlert = localStorage.getItem("PEB-Alert");
  const [modalS, setModalS] = useState(modalAlert === "show" ? true : false);
  console.log(modalS);
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        localStorage.setItem("PEB-Alert", "hide");
        setModalS(false);
      }
    };

    if (modalS) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [modalS]);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  const updateTimeZone = (id) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios
      .put(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`, {
        time_zone: timezone,
      })
      .then((response) => {
        console.log("Successfull updated timezone", timezone);
      })
      .catch((e) => {
        console.log("error");
      });
  };
  const [user, setUser] = useState({});
  const [navState, setNavState] = useState(0);
  let type = JSON.parse(localStorage.getItem("type"));
  useEffect(() => {
    let id = localStorage.getItem("id");
    updateTimeZone(id);
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/users/${id}`)
      .then((response) => {
        if (type === "professional") {
          axios
            .get(process.env.REACT_APP_SERVER_PATH + `/professionals/${id}`)
            .then((response) => {
              setUser(response.data);
              setLoading(false);
            })
            .catch(function (error) {
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            });
        } else if (type === "customer") {
          setUser(response.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error");
      });
  }, []);

  return (
    <div className="bg-bgGray h-full w-full">
      <Navbar />
      <hr />
      <div className="md:container w-full mx-auto sm:px-2 xl:px-16 md:my-6 flex sm:flex-col md:flex-row justify-between">
        <div className="my-2 md:hidden">
          <span
            className="bg-white inline-block"
            onClick={() => setIsNav(!isNav)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 bg-white p-1 rounded"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </span>
        </div>
        {isNav ? (
          <div className="bg-white top-0 left-0 absolute w-screen h-screen z-30 p-4 md:hidden">
            <div className="bg-white absolute w-screen top-0 left-0 p-4 overflow-y-auto flex flex-col justify-center items-center">
              <div className="flex w-full justify-end">
                <span onClick={() => setIsNav(!isNav)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="gray"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </div>
              <div className="sm:w-full md:w-1/4 md:hidden">
                <Sidebar
                  setNavState={setNavState}
                  navState={navState}
                  setBookingNavState={setBookingNavState}
                  // image={image}
                  user={user && user}
                  isNav={isNav}
                  setIsNav={setIsNav}
                  // image={image}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="sm:w-full md:inline-block hidden md:w-1/4 ">
          <Sidebar
            setNavState={setNavState}
            navState={navState}
            image={image}
            setBookingNavState={setBookingNavState}
            user={user && user}
          />
        </div>
        <div className={`${isNav ? "hidden" : ""} sm:w-full md:w-2/4 md:mx-6`}>
          <Routes>
            <Route
              path="*"
              element={
                <div
                  className="absolute top-0 left-0 bg-black w-screen h-screen flex items-center justify-center"
                  style={{ zIndex: "1000" }}
                >
                  <div>
                    <img src="/notfound.gif" />
                  </div>
                </div>
              }
            />
            <Route
              path="home"
              element={
                <Home
                  user={user && user}
                  bookingNavState={bookingNavState}
                  setBookingNavState={setBookingNavState}
                  navState={navState}
                  setNavState={setNavState}
                />
              }
            />
            <Route
              path="availability"
              element={
                <div>
                  {/* <ProfileTop user={user && user}
                                setBookingNavState={setBookingNavState}
                                setNavState={setNavState} /> */}
                  <Availability
                    user={user && user}
                    bookingNavState={bookingNavState}
                    setBookingNavState={setBookingNavState}
                    navState={navState}
                    setNavState={setNavState}
                  />
                </div>
              }
            />
            <Route
              path="profile/*"
              element={
                <div>
                  <Profile user={user && user} />
                </div>
              }
            />
            <Route
              path="bookings/*"
              element={
                <ViewBookings
                  user={user && user}
                  bookingNavState={bookingNavState}
                  setBookingNavState={setBookingNavState}
                />
              }
            />
            <Route
              path="payments/*"
              element={
                <div>
                  <Payments user={user && user} />
                </div>
              }
            />
            <Route
              path="notifications"
              element={
                <div>
                  <NotificationsSetting user={user && user} />
                </div>
              }
            />
            <Route
              path="changeemail"
              element={
                <div>
                  <ChangeEmailComponent user={user && user} />
                </div>
              }
            />
            <Route
              path="changepassword"
              element={
                <div>
                  <ChangePasswordComponent user={user && user} />
                </div>
              }
            />
            <Route
              path="anonymous"
              element={
                <div>
                  <Anonymous user={user && user} />
                </div>
              }
            />
            <Route
              path="private"
              element={
                <div>
                  <PEBProfile user={user && user} />
                </div>
              }
            />
            <Route
              path="charity"
              element={
                <div>
                  <Charity user={user && user} />
                </div>
              }
            />
            <Route
              path="get-referal-20"
              element={
                <div>
                  <GetReferal user={user && user} />
                </div>
              }
            />
          </Routes>
        </div>
        <div className="sm:w-full md:w-1/4">
          {user && (
            <RightSide
              user={user}
              setNavState={setNavState}
              navState={navState}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <div className={`${isNav ? "hidden" : ""}`}>
        <Footer />
      </div>

      {modalS && (
        <div
          className={`fixed inset-0 flex items-center justify-center bg-black z-50 bg-opacity-80 w-full`}
        >
          <div
            ref={modalRef}
            className="interest-Model shadow-lg px-6 py-8 pb-10 sm:w-5/6 lg:w-2/5"
            style={{ borderRadius: "25px" }}
          >
            <h2
              className="text-lg mb-4 text-black"
              style={{ fontFamily: "Lato Bold" }}
            >
              Bundle Pre-order Successfull
            </h2>
            <p>
              Thank you for pre-ordering our bundle! We are thrilled to offer
              you an amazing collection of content that will be available on 1st
              January.
            </p>
            <h2
              className="text-lg mt-3 text-black"
              style={{ fontFamily: "Lato Bold" }}
              ss
            >
              What to Expect:
            </h2>
            <ol className="list-decimal px-4 py-2 text-sm">
              <li>
                <b style={{ fontFamily: "Lato Bold" }}>
                  {" "}
                  Bundle Availability:{" "}
                </b>{" "}
                The bundle will be accessible in your dashboard on 1st January.
                Mark your calendars and get ready to explore its exciting
                content!
              </li>
              <li className="mt-2">
                <b style={{ fontFamily: "Lato Bold" }}> Refreshed Content: </b>{" "}
                We are constantly updating and enhancing the bundle's content.
                Rest assured that you'll have access to the latest and greatest
                materials when the bundle becomes available.
              </li>
            </ol>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
