import React from "react";
import Navbar from "../Navbar/Navbar";
import Hero from "./components/Hero";
import AboutCourse from "./components/AboutCourse";
import CourseInfo from "./components/CourseInfo";
import Instructors from "./components/Instructors";
import OfferedBy from "./components/OfferedBy";
import Reviews from "./components/Reviews";
import CompaniesLogo from "./components/CompaniesLogo";
import Footer from "../Footer/Footer";
import Syllabus from "./components/Syllabus";
function Index() {
  window.scroll(0, 0)
  return (
    <div className="bg-white">
      <Navbar />
      <hr />
      <Hero />

      <div className="md:container px-2  mt-2 md:mt-6 mx-auto  flex sm:flex-col md:flex-row h-full ">
        <AboutCourse />
        <CourseInfo />
      </div>
      <Syllabus />
      <Instructors />

      <OfferedBy />
      <div id='reviews'>
        <Reviews />
      </div>
      <CompaniesLogo />
      <Footer />
    </div>
  );
}

export default Index;
