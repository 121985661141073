import React, { useState } from 'react'
import MainContent from './MainContent/MainContent'
import SideContent from './SideContent/SideContent'

const BookingMainContent = (props) => {

    const [topicBooking, setTopicBooking] = useState(null)
    const [dateBooking, setDateBooking] = useState(null)
    const [timeSlotBooking, setTimeSlotBooking] = useState(null)
    const [propslocationprice] = useState((props.location.price));
    const [description, setDescription] = useState('')

    return (
        <div className="md:container mx-auto px-2 sm:px-2 md:px-0 lg:px-10 2xl:px-56 py-2 flex sm:flex-col md:flex-row">
            <SideContent
                location={props.location}
                topicBooking={topicBooking}
                setTopicBooking={setTopicBooking}
                dateBooking={dateBooking}
                setDateBooking={setDateBooking}
                timeSlotBooking={timeSlotBooking}
                setTimeSlotBooking={setTimeSlotBooking}
                propslocationprice={propslocationprice}
                description={description}
            />

            <MainContent
                location={props.location}
                topicBooking={topicBooking}
                setTopicBooking={setTopicBooking}
                dateBooking={dateBooking}
                setDateBooking={setDateBooking}
                timeSlotBooking={timeSlotBooking}
                setTimeSlotBooking={setTimeSlotBooking}
                propslocationprice={propslocationprice}
                id={props.id}
                description={description}
                setDescription={setDescription} />

        </div>
    )
}

export default BookingMainContent
