import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../../Arrows/LeftArrow";
import RightArrow from "../../Arrows/RightArrow";

export default function CompaniesLogo(props) {

  const companies = [
    {
      profile_photo: "/events/Boston Consulting Group.png",
    },
    {
      profile_photo: "/events/Ardian.png",
    },
    {
      profile_photo: "/events/Advent International.jpg",
    },
    {
      profile_photo: "/events/Bain Capital.png",
    },
    {
      profile_photo: "/events/global gate capital .png",
    },
    {
      profile_photo: "/events/mobeus equity partners.jpg",
    },
    {
      profile_photo: "/events/Seedcloud.png",
    },
    {
      profile_photo: "/events/PAI PARTNERS.jpg",
    },
    {
      profile_photo: "/events/UCB Ventures.png",
    },
    {
      profile_photo: "/events/Seedrs.jpeg",
    },
    
  ];

  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const Ref = useRef(null);
  const SlickNext = () => {
    Ref.current.slickNext();
  };
  const SlickPrev = () => {
    Ref.current.slickPrev();
  };

  return (
    <div className="md:container mx-auto px-2">
     <div className="md:container mx-auto  sm:px-2  py-12 bg-bgGray my-2 md:mb-5">

      <div className=" text-textFullDark flex items-center justify-center">
        <p className="text-xl md:text-3xl font-bold w-full  md:w-2/5 text-center self-center">
          Our teachers are sourced from the best companies in their industries
        </p>
      </div>

      <div className="bg-bgGray py-2 sm:h-36 md:h-56 ">
        <div className="text-md flex flex-row items-center justify-end w-full text-textDark px-4">
          <LeftArrow click={SlickPrev} />
          <RightArrow click={SlickNext} />
        </div>
        <div className="md:container mx-auto my-6 px-2 xl:px-16">
          <Slider {...settings} ref={Ref}>
            {companies.map((p, i) => (
              <div key={i}>
                <CompanyLogo image={p.profile_photo} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      </div>

    </div>
  );
}

const CompanyLogo = ({ image }) => {
  return (
    <div className="sm:h-10 md:h-24 mr-8 md:mr-16">
      <div className="">
        <img className="sm:h-10 md:h-24" src={image} alt="images" />
      </div>
    </div>
  );
};
