import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isProfilePopUp: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    isProfilePopUp: (state) => {
      state.isProfilePopUp = !state.isProfilePopUp;
    },
  },
  extraReducers: {},
});

export const { isProfilePopUp } = profileSlice.actions;
export default profileSlice.reducer;
