import React from "react";
import Skeleton from "react-skeleton-loading"

export default function OfferedBySkeleton() {
  return (
    <div className="md:container mx-auto my-6 sm:px-2 xl:px-16">
      <div className=" flex flex-col md:flex-row w-full rounded-lg  items-center lg:items-start bg-gray-200">
        <div className="flex flex-col md:flex-row px-5 py-5 w-full">
          <div className="hidden lg:flex w-full md:w-96 items-center justify-center mx-5 my-6">
            <Skeleton height={200} width={300} />
          </div>

          <div className="flex flex-col items-start justify-between p-5 lg:ml-12 w-full">
            <p className="mt-2 text-lg font-semibold w-full text-textFullDark">
              <Skeleton height={30} width={500} />
            </p>

            <div className="flex w-full  mt-5 lg:hidden items-center justify-center">
              <Skeleton height={200} width={300} />
            </div>

            <div className="flex flex-col mt-6  md:flex-row justify-between items-center w-full ">
              <div className="">
                <Skeleton height={50} width={200} />
              </div>
              <div>
                <button className=" mt-6 lg:mt-0 ">
                  <Skeleton height={50} width={200} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
