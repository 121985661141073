export const applyToBeMentorData = [
    {
      image: "/applyToBecomeMentor/given-back-gray.png",
      image1:"/applyToBecomeMentor/given-back-blue.png",
      title: "Giving back",
      description:
        "Many people who have achieved success in their careers want to give back by sharing their knowledge and experiences with others. They see mentoring as a way to pay it forward and help others achieve their own career goals.",
    },
    {
      image: "/applyToBecomeMentor/personal-development-gray.png",
      image1:"/applyToBecomeMentor/personal-development-blue.png",
      title: "Professional development",
      description:
        "Many mentors find that mentoring others helps them stay current and continue to develop their own skills and knowledge. Through mentoring, they can learn new perspectives and ways of thinking, and stay engaged in their field.",
    },
    {
      image: "/applyToBecomeMentor/personal-fulfilment-gray.png",
      image1:"/applyToBecomeMentor/personal-fulfilment-blue.png",
      title: "Personal fulfilment:",
      description:
        "For many mentors, the act of mentoring brings a sense of personal fulfilment. They derive satisfaction from seeing their mentees grow and succeed, and from knowing that they played a role in that success.",
    },
  ];