import React from 'react'
import { CompanyTitle } from '../../settings'

const CharityBadgePopUp = (props) => {
    return (
        <div className={props.modal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className={props.overlay}></div>
            <div className='p-8 bg-white rounded-xl z-30 w-11/12  md:w-1/3 flex flex-col items-center'>
                <div className='w-full flex justify-end'>
                    <button onClick={props.toggle}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <img src='/charity.svg' alt='charity' className='w-8' />
                <div className='my-3'>
                    <p><strong>{CompanyTitle}</strong> mentor donates proportion of earnings to {CompanyTitle} charity partners.</p>
                </div>
            </div>
        </div>
    )
}

export default CharityBadgePopUp