import React, { useState, useEffect } from 'react'
import Colors from '../Colors/Colors'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import Loader from "react-loader-spinner";
import { update_profile_step } from './StepUpdated';
import { Mixpanel } from '../Mixpanel';

const AddLocation = (props) => {
    localStorage.setItem("navState", 3)
    const [isLoading, setLoading] = useState(false);
    const [location, setLocation] = useState('');
    const [locationSuggestions, setSuggestions] = useState([]);
    const [error, setError] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [selectedLocation, setselectedLocation] = useState("");
    const [insertedLocation, setInsertedLocation] = useState("");
    //#region Getting Current Location of User - Mentor
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                axios
                    .get(
                        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&result_type=locality&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                    )
                    .then((response) => {
                        if (response.data && response.data.results && response.data.results[0]) {
                            const city = response.data.results[0].address_components.find(component => component.types.includes('locality'))?.long_name;
                            setLocation(city);
                            console.log(city);
                        } else {
                            console.error('Invalid response from geocode API:', response.data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            (error) => {
                console.error(error);
            }
        );
    }, []);

    //#endregion

    const handleClearLocation = () => {
        setLocation("");
    };

    const UpdatedlocationClear = () => {
        setInsertedLocation("");
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
        setError(false);

        if (event.target.value.length >= 3) {

            axios.get(process.env.REACT_APP_SERVER_PATH + '/googleMapsAutocomplete', {
                params: { input: event.target.value },
            })
                .then((response) => {
                    const predictions = response.data.predictions;
                    if (predictions && predictions.length > 0) {
                        const suggestions = predictions.map(
                            (prediction) => prediction.terms[prediction.terms.length - 2].value
                        );
                        const uniqueSuggestions = [...new Set(suggestions)];
                        setSuggestions(uniqueSuggestions);
                    } else {
                        setSuggestions([]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setSuggestions([]);
            setLoading(false);
        }
    };
    

    const handleUpdatedLocation = (event) => {
        setInsertedLocation(event.target.value);
        setError(false);

        if (event.target.value.length >= 3) {

            axios.get(process.env.REACT_APP_SERVER_PATH + '/googleMapsAutocomplete', {
                params: { input: event.target.value },
            })
                .then((response) => {
                    const predictions = response.data.predictions;
                    if (predictions && predictions.length > 0) {
                        const suggestions = predictions.map(
                            (prediction) => prediction.terms[prediction.terms.length - 2].value
                        );
                        const uniqueSuggestions = [...new Set(suggestions)];
                        setSuggestions(uniqueSuggestions);
                    } else {
                        setSuggestions([]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setSuggestions([]);
            setLoading(false);
        }
    };

    const handleLocationSelect = (value) => {
        setLocation(value);
        setSuggestions([]);
        // Retrieve the latitude and longitude of the selected location
        axios
            .get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            )
            .then((response) => {
                if (response.data && response.data.results && response.data.results[0]) {
                    const location = response.data.results[0].geometry.location;
                    setLatitude(location.lat);
                    setLongitude(location.lng);
                    setselectedLocation(location)

                } else {
                    console.error('Invalid response from geocode API:', response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleUpdateLocationSelect = (value) => {
        console.log(insertedLocation)
        setInsertedLocation(value);
        setSuggestions([]);
        // Retrieve the latitude and longitude of the selected location
        axios
            .get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            )
            .then((response) => {
                if (response.data && response.data.results && response.data.results[0]) {
                    const location = response.data.results[0].geometry.location;
                    setLatitude(location.lat);
                    setLongitude(location.lng);
                    setselectedLocation(location)

                } else {
                    console.error('Invalid response from geocode API:', response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmit = () => {
        console.log(location, latitude, longitude);
        if (location !== '' && latitude && longitude) {
            setLoading(true);

            axios.put(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                location: location,
                lat: latitude,
                lng: longitude
            })
                .then((r) => {
                    console.log(r);
                    Mixpanel.track(`web: Add Location.`, { 'location': location });
                    update_profile_step(localStorage.getItem('signup_token'), jwt_decode(localStorage.getItem("signup_token")).id, "3");
                    props.setNavState(props.navState + 1);

                    axios.put(process.env.REACT_APP_SERVER_PATH + `/users/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                        lat: latitude,
                        lng: longitude
                    })
                        .then((response) => {
                            console.log(response, "users");
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                })
                .catch(e => {
                    console.log('Error');
                });
        } else {
            setError(true);
        }
    };

    const LocationAdded = () => {
        handleSubmit();
    };

    //#region Get user location to update it
    const getUser = () => {
        axios.get(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`)
            .then((response) => {
                setInsertedLocation(response.data.location)
                axios.get(process.env.REACT_APP_SERVER_PATH + `/users/${jwt_decode(localStorage.getItem("signup_token")).id}`).then((response) => {
                setLatitude(response.data.lat);
                setLongitude(response.data.lng);
                }).catch (e => {
                    console.log(e.message)
                })
            }).catch(e => {
                console.log("error occured");
            })
    }
    useEffect(() => {
        getUser()
    }, [])

    const upDateUser = () => {
        console.log(insertedLocation, latitude, longitude);
        if (insertedLocation !== '' && latitude && longitude) {
            setLoading(true);

            axios.put(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                location: insertedLocation,
                lat: latitude,
                lng: longitude
            })
                .then((r) => {
                    console.log(r);
                    Mixpanel.track(`web: Add Location.`, { 'location': insertedLocation });
                    update_profile_step(localStorage.getItem('signup_token'), jwt_decode(localStorage.getItem("signup_token")).id, "3");
                    props.setNavState(props.navState + 1);

                    axios.put(process.env.REACT_APP_SERVER_PATH + `/users/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                        lat: latitude,
                        lng: longitude
                    })
                        .then((response) => {
                            console.log(response, "users");
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                })
                .catch(e => {
                    console.log('Error');
                });
        } else {
            setError(true);
        }
    };
    //#endregion


    return (
        <div className="flex flex-col justify-center items-center w-full text-textFullDark">
            <img src="/CustomerFlow/Add your Location.png" alt="location" className="w-36" />
            <p className="text-2xl font-bold mt-3 text-textFullDark">Add your Location</p>
            <p className="text-xs mt-3 text-center">Tell us where you're based so we can match you with mentees in your location</p>
            <div className="mt-6 relative w-full">
                {insertedLocation ? (<><input
                    type="text"
                    className={`border border-textFullDark rounded-lg w-full py-2 px-3 pr-10 text-sm ${error && 'border-red-500'}`}
                    placeholder="Your location"
                    value={insertedLocation}
                    onChange={handleUpdatedLocation}
                />
                {insertedLocation && locationSuggestions.length === 0 && (
                    <button
                        className="absolute top-0 right-0 h-full w-10 text-center text-gray-400 hover:text-gray-600"
                        onClick={UpdatedlocationClear}
                    >
                        <svg viewBox="0 0 20 20" fill="currentColor" className="x-circle w-6 h-6">
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM5.293 6.707a1 1 0 111.414-1.414L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 11-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                )}
                {locationSuggestions.length > 0 && (
                    <div className="border border-textFullDark border-t-0 rounded-b-lg shadow-lg">
                        {locationSuggestions.map((insertedLocation) => (
                            <div
                                key={insertedLocation}
                                className="cursor-pointer py-1 px-3 hover:bg-gray-200"
                                onClick={() => handleUpdateLocationSelect(insertedLocation)}
                            >
                                {insertedLocation}
                            </div>
                        ))}
                    </div>
                )}
                {error && (
                    <p className="text-xs text-red-500 mt-2">Please enter a valid location</p>
                )}
                </>) : (<><input
                    type="text"
                    className={`border border-textFullDark rounded-lg w-full py-2 px-3 pr-10 text-sm ${error && 'border-red-500'}`}
                    placeholder="Your location"
                    value={location}
                    onChange={handleLocationChange}
                />
                    {location && locationSuggestions.length === 0 && (
                        <button
                            className="absolute top-0 right-0 h-full w-10 text-center text-gray-400 hover:text-gray-600"
                            onClick={handleClearLocation}
                        >
                            <svg viewBox="0 0 20 20" fill="currentColor" className="x-circle w-6 h-6">
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM5.293 6.707a1 1 0 111.414-1.414L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 11-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    )}
                    {locationSuggestions.length > 0 && (
                        <div className="border border-textFullDark border-t-0 rounded-b-lg shadow-lg">
                            {locationSuggestions.map((location) => (
                                <div
                                    key={location}
                                    className="cursor-pointer py-1 px-3 hover:bg-gray-200"
                                    onClick={() => handleLocationSelect(location)}
                                >
                                    {location}
                                </div>
                            ))}
                        </div>
                    )}
                    {error && (
                        <p className="text-xs text-red-500 mt-2">Please enter a valid location</p>
                    )}
                </>)}
            </div>
            <div className="w-full flex justify-end items-center mt-4 text-white">
                <button className="py-2 px-8 mr-auto rounded-lg flex" style={{ backgroundColor: location.length >= 3 ? Colors.blue : Colors.blue, color: location.length >= 3 ? 'white' : 'white' }}
                    onClick={() => {
                        props.setNavState(props.navState - 1)
                        setLoading(false)

                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <p >Back</p>
                </button>
                {insertedLocation ? (
                    <button className="py-2 px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue, color: 'white' }}
                        onClick={upDateUser}
                    >
                        <p>Update {isLoading ? <span className='inline-block ml-4'><Loader
                            type="TailSpin"
                            color="white"
                            height={20}
                            width={20}
                        // timeout={5000} //5 secs
                        /></span> : null}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </button>
                ) : (
                    <button className="py-2 px-8 rounded-lg flex" style={{ backgroundColor: location.length >= 3 ? Colors.blue : Colors.gray, color: location.length >= 3 ? 'white' : 'gray' }}
                        onClick={LocationAdded}
                        disabled={location.length <= 3}
                    >
                        <p>Next {isLoading ? <span className='inline-block ml-4'><Loader
                            type="TailSpin"
                            color="white"
                            height={20}
                            width={20}
                        // timeout={5000} //5 secs
                        /></span> : null}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </button>
                )}
            </div>

        </div>
    );
}

export default AddLocation