import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Colors from '../Colors/Colors'
import Education from '../Profile/Education/Education'
import jwt_decode from 'jwt-decode'
import { update_profile_step } from './StepUpdated'
const EducationHistory = (props) => {
    localStorage.setItem("navState", 8)
    const [education, setEducation] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/education/${jwt_decode(localStorage.getItem("customer_signup_token")).id}`).then((response) => {
            console.log(response.data)
            setEducation(response.data)
        }).catch(e => {
            console.log(e)
        })
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/customers/${jwt_decode(localStorage.getItem("customer_signup_token")).id}`).then((response) => {
            console.log(response)
        }).catch(e => {
            console.log(e)
        })
    }, [])
    return (
        <div className="flex flex-col justify-center items-center text-textFullDark w-full">
            <img src="/CustomerFlow/Add education.png" alt="workexperience" className="w-36" />
            <p className="text-2xl font-bold mt-3 text-textFullDark">Add education</p>
            <p className="text-xs mt-3 text-center">Adding this detail will help your mentor understand your educational background</p>
            <div className="mt-6 w-full">
                {education.map(m => {
                    return <Education
                        universityImage="/educationplaceholder.png"
                        education_type={m.field_of_study}
                        universityName={m.school}
                        startDate={m.start_date}
                        endDate={m.end_date} />
                })}
            </div>
            <button className="w-full py-2 rounded-lg text-white mt-4" style={{ backgroundColor: Colors.blue }}
                onClick={() => { props.setNavState(props.navState - 1) }}>Add Education</button>
            <div className="w-full flex justify-end items-center mt-4 text-white">
                <button className="py-2 mr-auto px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue, color: 'white' }}
                    onClick={() => { props.setNavState(props.navState - 3) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <p >Back</p>
                </button>
                <button className="py-2  px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue }}
                    onClick={() => {
                        update_profile_step(localStorage.getItem('customer_signup_token'), jwt_decode(localStorage.getItem("customer_signup_token")).id, "6")
                        props.setNavState(props.navState + 1)
                    }}>
                    <p >Next</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default EducationHistory
