import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    showReviews: false,
}

export const reviewSlice = createSlice({
    name:"review",
    initialState,
    reducers:{
        toggleReviews :(state)=>{
         state.showReviews = !state.showReviews;
        },
    },
    extraReducers:{
    }
})

export const { toggleReviews } = reviewSlice.actions;
export default reviewSlice.reducer;