import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import ReactStars from 'react-rating-stars-component'
import { useLocation } from 'react-router-dom'
import { getRefFromQuery } from '../CustomFunctions/CustomFunctions'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import { CompanyTitle } from '../settings'
import SignUpProcess from '../SignUpProcess/SignUpProcess'
import SignUpProcessOfCustomer from '../SignUpProcessOfCustomer/SignUpProcessOfCustomer'
import LeftArrow from "../Arrows/LeftArrow";
import RightArrow from "../Arrows/RightArrow";
import Slider from "react-slick";
import MainImage from '../mainImage/mainImage'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import { toggleFreeCallModal } from '../Redux/reducers/freeCall/freeCallSlice'
import { navStateAddBy1 } from '../Redux/reducers/nav/navSlice';
import { setNameAndId } from '../Redux/reducers/professional/professionalSlice'
import ReactTooltip from 'react-tooltip'
import { RatingHandler } from '../utils/Rating';

const Welcome = () => {
    let queryString = useLocation()
    getRefFromQuery(queryString)
    const [partner, setPartner] = useState(false)
    let [partnerName, setPartnerName] = useState('')
    const [professionals, setProfessionals] = useState([])

    const [professionalSignUpFlow, setProfessionalSignUpFlow] = useState(false)
    const [customerSignUpFlow, setCustomerSignUpFlow] = useState(false)
    const toggleProfessionalSignUpFlow = () => {
        setProfessionalSignUpFlow(!professionalSignUpFlow);
    };
    const toggleCustomerSignUpFlow = () => {
        setCustomerSignUpFlow(!customerSignUpFlow);
        setProfessionalSignUpFlow(false)
    };
    const getQueryParam = (name) => {
        let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    let checkForPartner = () => {
        let forPartner = getQueryParam('org')
        if (forPartner !== null) {
            setPartner(true)
            setPartnerName(forPartner)
        } else {
            console.log('Error in partner check')
        }
    }

    let professionalsToRender = null
    if (partner) {
        if (partnerName.toLowerCase() === 'Finatal'.toLowerCase() || partnerName.toLowerCase() === 'finataljobs'.toLowerCase() || partnerName.toLowerCase() === 'linkedinpejobs'.toLowerCase() || partnerName.toLowerCase() === 'linkedintechjobs'.toLowerCase() || partnerName.toLowerCase() === 'PER'.toLowerCase() || partnerName.toLowerCase() === 'JBM'.toLowerCase() || partnerName.toLowerCase() === 'efinancialcareers'.toLowerCase() || partnerName.toLowerCase() === 'LinkedIn'.toLowerCase()) {
            professionalsToRender = <div className='flex flex-col md:flex-row flex-wrap md:justify-center sm:px-16 md:px-0'>
                {professionals.map((p) => {
                    return <ReuseableComponentForPartner
                        id={p.user_id}
                        name={p.User?.first_name}
                        lastName={p.User?.last_name}
                        designation={p.job_title}
                        time={p.response_time}
                        price={p.session_price_15_min}
                        image={p.User?.profile_photo}
                        isCharity={p.is_charity}
                        intro_link={p.prof_intro_link}
                        location={p.location}
                        Experiences={p.Experiences}
                        avgRating={p.avgRating}
                        reviewCount={p.reviewCount}
                    // setProfilePopUp={setProfilePopUp}
                    />
                })}
            </div>
        } else {
            professionalsToRender = <div className='flex flex-col md:flex-row flex-wrap md:justify-center sm:px-16 md:px-0'>
                {professionals.map((p) => {
                    return <ReuseableComponentForNoCompany
                        id={p.id}
                        name={p.first_name}
                        lastName={p.last_name}
                        designation={p.job_title}
                        time={p.response_time}
                        price={p.session_price_15_min}
                        image={p.profile_photo}
                        isCharity={p.is_charity}
                        intro_link={p.prof_intro_link}
                        location={p.location}
                        Experiences={p.Experiences}
                        avgRating={p.avgRating}
                        reviewCount={p.reviewCount}
                    // setProfilePopUp={setProfilePopUp}
                    />
                })}
            </div>
        }
    } else {
        professionalsToRender = <div className='flex flex-col md:flex-row flex-wrap md:justify-center sm:px-16 md:px-0'>
            {professionals.map((p) => {
                return <ReuseableComponentForNoCompany
                    id={p.id}
                    name={p.first_name}
                    lastName={p.last_name}
                    designation={p.job_title}
                    time={p.response_time}
                    price={p.session_price_15_min}
                    image={p.profile_photo}
                    isCharity={p.is_charity}
                    intro_link={p.prof_intro_link}
                    location={p.location}
                    Experiences={p.Experiences}
                    avgRating={p.avgRating}
                    reviewCount={p.reviewCount}
                // setProfilePopUp={setProfilePopUp}
                />
            })}
        </div>
    }
    useEffect(() => {
        if (partner) {
            if (partnerName.toLowerCase() === 'Finatal'.toLowerCase() || partnerName.toLowerCase() === 'finataljobs'.toLowerCase() || partnerName.toLowerCase() === 'linkedinpejobs'.toLowerCase() || partnerName.toLowerCase() === 'linkedintechjobs'.toLowerCase() || partnerName.toLowerCase() === 'PER'.toLowerCase() || partnerName.toLowerCase() === 'JBM'.toLowerCase() || partnerName.toLowerCase() === 'efinancialcareers'.toLowerCase() || partnerName.toLowerCase() === 'LinkedIn'.toLowerCase()) {
                axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals?include=${process.env.REACT_APP_FREECALL_PROFESSIONLS_FINITAL}`).then((response) => {
                    setProfessionals(response.data)
                }).catch(e => {
                    console.log("Error from professional api in Modal")
                })
            } else {
                axios.get(`${process.env.REACT_APP_SERVER_PATH}/users?search=&limit=10&page=1&freeCall=true&type=professional`).then((response) => {
                    setProfessionals(response.data)
                }).catch(e => {
                    console.log("Error from professional api in Modal")
                })
            }
        } else {
            axios.get(`${process.env.REACT_APP_SERVER_PATH}/users?search=&limit=10&page=1&freeCall=true&type=professional`).then((response) => {
                setProfessionals(response.data)
            }).catch(e => {
                console.log("Error from professional api in Modal")
            })
        }
        checkForPartner()
    }, [partner])

    return (
        <div className='w-full'>
            <Helmet>
                <title>Welcome | {CompanyTitle}</title>
                <meta name={`Welcome | ${CompanyTitle}`} content={`Welcome | ${CompanyTitle}`} />
            </Helmet>
            {professionalSignUpFlow && (
                <SignUpProcess
                    toggle={toggleProfessionalSignUpFlow}
                    overlay="overlay"
                    modal="modal"
                    modalcontent="modal-content"
                    closeModal="close-modal"
                    modalValue={professionalSignUpFlow} />
            )}
            {customerSignUpFlow && (
                <SignUpProcessOfCustomer
                    toggle={toggleCustomerSignUpFlow}
                    overlay="overlay"
                    modal="modal"
                    modalcontent="modal-content"
                    closeModal="close-modal"
                    modalValue={customerSignUpFlow}
                    customerSignUpFlow={customerSignUpFlow}
                    setCustomerSignUpFlow={setCustomerSignUpFlow}
                    professionalSignUpFlow={professionalSignUpFlow}
                    setProfessionalSignUpFlow={setProfessionalSignUpFlow} />
            )}
            <Navbar />
            <hr />
            <div className='mb-6'>
                <MainImage />
            </div>

            <div className='mt-1 mb-3 md:container mx-auto'>
                {professionalsToRender}
            </div>
            <ChangingImage />

            <div className='xl:container sm:p-4 md:p-16 md:mx-auto flex flex-col justify-center items-center'>
                <p className='sm:text-3xl md:text-5xl text-textFullDark sm:leading-8 md:leading-none mb-4 font-extrabold sm:mt-4 md:mt-0'>Why {CompanyTitle}?</p>
                <div className='flex sm:flex-col md:flex-row sm:items-center md:items-start justify-between'>
                    <div className='p-4 flex items-center'>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='/featured1.png' alt="featured1" className='sm:w-36 sm:h-36 md:w-24 md:h-24' />
                            <p className='w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center'>Industry experienced mentors</p>
                        </div>
                        <img src='/line1.png' alt='line 1' className='hidden md:block' />
                    </div>
                    <div className='p-4 flex items-center'>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='/featured2.png' alt="featured1" className='sm:w-36 sm:h-36 md:w-24 md:h-24' />
                            <p className='w-full md:w-44 mt-2 sm:text-2xl md:text-lg font-bold text-center'>Search and select from dozens of mentors</p>
                        </div>
                        <img src='/line2.png' alt='line 1' className='hidden md:block' />
                    </div>
                    <div className='p-4 flex items-center'>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='/featured3.png' alt="featured1" className='sm:w-36 sm:h-36 md:w-24 md:h-24' />
                            <p className='w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center'>1-1 personalised mentoring sessions</p>
                        </div>
                        <img src='/line3.png' alt='line 1' className='hidden md:block' />
                    </div>
                    <div className='p-4 flex items-center'>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='/featured4.png' alt="featured1" className='sm:w-36 sm:h-36 md:w-24 md:h-24' />
                            <p className='w-full md:w-36 mt-2 sm:text-2xl md:text-lg font-bold text-center'>Choose a time and date that's easy for you</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Reviews Section */}
            <div className='mb-12'>
                <HowItWorksReviews />
            </div>
            <div className='sm:px-4 md:px-0 mb-12'>
                <div className='md:container mx-auto px-4 xl:px-16 flex sm:flex-col md:flex-row rounded-xl bg-bgAppColor'>
                    <div className='sm:w-full md:w-1/2 text-white flex flex-col justify-center'>
                        <div>
                            <p className='sm:text-lg md:text-3xl font-bold sm:mt-8'>FIND A CAREER MENTOR NOW</p>
                            <button className='text-xs'>Download App</button>
                            <div className="flex my-3">
                                <button className="bg-gray-800 hover:bg-black text-white px-5 py-2 w-36 rounded flex justify-center"
                                ><a href='https://apps.apple.com/us/app/careerpaths/id1558302311' rel="noreferrer" target="_blank"><img src="/appstore.png" alt="apple"
                                /></a> </button>
                                <button className="bg-gray-800 hover:bg-black text-white px-5 py-2 ml-2 rounded flex w-36"
                                ><a href='https://play.google.com/store/apps/details?id=com.careerpaths.mobile' rel="noreferrer" target="_blank"><img src="/googleplay.png" alt="apple"
                                /></a></button>
                            </div>
                            <button className='text-xs'>Apply to be a member</button>
                        </div>
                    </div>
                    <div className='sm:w-full md:w-1/2'>
                        <img alt="" src='/Mobile-Mockup.png' className='md:-mt-32' />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Welcome


export const HowItWorksReviews = (props) => {
    const [reviewerData] = useState([
        {
            description: '“I found my session with Guy very insightful; his candid approach was refreshing, allowing me to extract the maximum value from our session”',
            name: 'R*****',
            nameFirstLetter: 'R',
            title: 'Student LSE'
        }, {
            description: '“Excellent session with Shaun, very knowledgeable on the sector and in particular Series A investing”',
            name: 'M*****',
            nameFirstLetter: 'M',
            title: 'PE Analyst'
        }, {
            description: '“Wanted to switch careers from Consulting into Private Equity. Guy helped me understand what I needed to do to make the transition a success. Knowledge is power!”',
            name: 'H****',
            nameFirstLetter: 'H',
            title: 'Senior Consultant'
        }, {
            description: '“Completed a mock case study with Tobias who was super helpful in pointing out my development areas. I’m hoping this will help me get across the final interview hurdle!”',
            name: 'L***',
            nameFirstLetter: 'L',
            title: 'Corporate Finance Analyst'
        }, {
            description: '“Spoilt for choice… great variety of mentors to pick from in Private Equity and Venture Capital”',
            name: 'S**',
            nameFirstLetter: 'S',
            title: 'Student Oxford'
        }, {
            description: '“Quickest way to build a network in PE”',
            name: 'W***',
            nameFirstLetter: 'W',
            title: 'Financial Analyst'
        }, {
            description: '“The best £50 I\'ve spent on my career to date”',
            name: 'A****',
            nameFirstLetter: 'A',
            title: 'Credit Analyst'
        }, {
            description: '“Unbelievable access to PE professionals”',
            name: 'G**',
            nameFirstLetter: 'G',
            title: 'Investor Relations'
        }, {
            description: '“Appreciated the 1:1 feedback and hearing career advice from someone actually working in the industry”',
            name: 'T***',
            nameFirstLetter: 'T',
            title: 'Business Analyst'
        }, {
            description: '“I enjoyed the conversation with my mentor. He gave good advice and the app was super convenient to use!”',
            name: 'M****',
            nameFirstLetter: 'M',
            title: ' Investment Banking Associate'
        }
    ])
    let Ref = useRef(null)
    const [settings] = useState({
        dots: false,
        speed: 500,
        slidesToShow: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })

    const SlickNext = () => {
        Ref.current.slickNext()
    }
    const SlickPrev = () => {
        Ref.current.slickPrev()
    }
    return (
        <div className='sm:p-4 md:p-16' >
            <div>
                <p className='sm:text-3xl md:text-5xl text-textFullDark font-bold text-center my-4'>Reviews</p>
                <div className="md:container mx-auto my-4 py-1 flex justify-end pr-4">
                    <div className="text-md text-textFullDark flex items-center">
                        <LeftArrow click={SlickPrev} />
                        <RightArrow click={SlickNext} />
                    </div>
                </div>
                <div className="md:container mx-auto my-4">
                    <Slider {...settings} ref={Ref}>
                        {
                            reviewerData.map((reviewer) => {
                                return <div>
                                    <div className='p-6 bg-blue-50 md:mr-4 rounded flex flex-col justify-between h-80'>
                                        <div>
                                            <ReactStars
                                                value={5}
                                                size={20}
                                                activeColor="#F04037"
                                                isHalf={true}
                                                edit={false}
                                            />
                                            <p className='text-lg text-textFullDark my-4 font-bold'>{reviewer.description}</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <div className='w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center text-white font-bold'>{reviewer.nameFirstLetter}</div>
                                            <div className='ml-4'>
                                                <p className='text-xl text-black font-bold'>{reviewer.name}</p>
                                                <p className='text-xs text-gray-600 font-bold'>{reviewer.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div >
    )
}

const ChangingImage = () => {
    const [count, setCount] = useState(0);
    const [images] = useState(['/how1.png', '/how2.png', '/how3.png', '/how4.png'])
    const [description] = useState([
        {
            icon: '/Number1.svg',
            heading: 'Browse and select from dozens of mentors',
            title: 'Search by name, industry, company name or job title'
        },
        {
            icon: '/Number2.svg',
            heading: 'Tell your mentor what you would like help with',
            title: 'It could be anything from interview tips, advice on training and development or simply a chat to expand your network'
        },
        {
            icon: '/Number.svg',
            heading: 'Select a date and time that is easy for you',
            title: 'Our intelligent calendar management system allows you to see when your mentor is available months in advance'
        },
        {
            icon: '/Number3.svg',
            heading: '1-1 personalised mentor session',
            title: 'Use our website or in app video call system to speak to your mentor instantly'
        }
    ])

    const getCount = () => {
        if (count === 3) {
            setTimeout(() => {
                setCount(prev => prev * 0)
            }, 2000)
        } else {
            setTimeout(() => {
                setCount(prev => prev + 1)
            }, 2000)

        }

    }
    useEffect(() => {
        getCount()
    }, [count])
    return (
        <div className='sm:p-4 md:p-16' style={{ backgroundColor: '#F7F9FD' }}>
            <div className='md:container md:mx-auto sm:p-4 md:p-16 bg-white rounded'>

                <div className='flex sm:flex-col md:flex-row md:items-center md:justify-center bg-white rounded'>
                    <div className='md:mr-6 w-full md:w-3/12'>
                        {count === 0 ? <img src={images[0]} alt='iphone13' /> :
                            count === 1 ? <img src={images[1]} alt='iphone13' /> :
                                count === 2 ? <img src={images[2]} alt='iphone13' /> :
                                    <img src={images[3]} alt='iphone13' />
                        }

                    </div>
                    <div className='w-full md:w-6/12'>
                        <div className='flex sm:justify-center md:justify-start'>
                            <p className='sm:text-3xl md:text-5xl text-textFullDark mt-2 font-bold ml-4 mb-4 text-center md:text-left'>How it works?</p>
                        </div>
                        <div>
                            {description.map((item, index) => {
                                return <div className={`sm:my-8 md:my-4 cursor-pointer p-4 ${count === index ? 'bg-gray-700' : 'bg-white'} ${count === index ? 'text-white' : 'text-gray-700'} flex rounded-3xl items-start`}>
                                    <img src={item.icon} alt='Number' />
                                    <div className='ml-4'>
                                        <p className='font-bold text-xl'>{item.heading}</p>
                                        <p className='text-sm'>{item.title}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ReuseableComponentForPartner = ({ name, designation, time, price, image, isCharity, intro_link, location, professionalID, setProfessionalID, id, setProfilePopUp, lastName, setProfessionalName, Experiences, avgRating, reviewCount }) => {
    let dispatch = useDispatch()

    const getLatestCompany = arr => {
        if (arr.length > 0) {
            const data = arr.sort(function (a, b) {
                var keyA = new Date(a.start_date),
                    keyB = new Date(b.start_date);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
            return data[0]?.company;
        } else {
            return null
        }
    };
    return (
        <div className="cursor-pointer mb-4 md:mb-2 sm:mr-2 md:mr-4 sm:p-1 md:p-3 border border-borderGray box-border rounded-lg hover:shadow-lg  w-56 sm:h-56 md:h-80" onClick={() => {
            // dispatch({ type: 'toggleFreeCallModal' })
            dispatch(toggleFreeCallModal())
            // dispatch({ type: "setNameAndId", id, name: `${name} ${lastName}` });
            dispatch(setNameAndId({ id, name: `${name} ${lastName}` }));
            // dispatch({ type: "navStateAddBy1" });
            dispatch(navStateAddBy1());
            // navigate(`/profile/${id}`)

        }} >
            <div className="rounded overflow-hidden">
                <div className="w-full">
                    <ReactTooltip place="bottom" className="w-40 text-xs" />
                    {intro_link !== null && <div className="relative" data-tip="Mentor has a video introduction">
                        <div className="absolute  m-2">
                            <img src="/showvideo.png" alt="icon" className="w-6" />
                        </div>
                    </div>}
                    {isCharity === 1 && <div className="relative" data-tip="Mentor donates proportion of earnings to charity">
                        <div className="absolute right-0 m-2">
                            <img src="/charity.svg" alt="charity" className="w-5 z-20" />
                        </div>
                    </div>}
                    <img className="rounded-lg w-full sm:h-36 md:h-56 mb-1 md:mb-2 object-cover border border-borderGray" src={image ? image : '/avatar.png'} alt={name} />
                </div>
                <div className="pt-1">
                    <div className="flex items-center justify-between">
                        <div className="text-textFullDark font-bold text-md truncate">
                            {name}
                        </div>
                        <div className="flex items-center">
                            {avgRating > 0 ? <div className="h-5 flex items-center justify-center">
                                {avgRating && <ReactStars
                                    value={RatingHandler(avgRating)}
                                    size={20}
                                    activeColor="#ffd700"
                                    isHalf={true}
                                    edit={false}
                                    count={1}
                                />}
                            </div> : <div className="h-5 flex items-center justify-center">
                                <ReactStars
                                    value={0}
                                    size={20}
                                    activeColor="#ffd700"
                                    isHalf={true}
                                    edit={false}
                                    count={1}
                                />
                            </div>
                            }
                            &nbsp;&nbsp;<p className='text-sm text-textDark -ml-1'>{avgRating ? (+avgRating).toFixed(1) : Math.trunc(0).toFixed(1)}</p>
                        </div>
                    </div>
                    <div className="w-full flex items-end justify-between">
                        <div className="text-xs text-textDark truncate hidden md:inline-block"> {designation.length > 15 ? `${designation.slice(0, 15)} ...` : designation}</div>
                        <div className="text-xs text-textDark truncate md:hidden"> {designation.length > 10 ? `${designation.slice(0, 10)} ...` : designation}</div>
                        <p className="text-xs md:text-sm text-textDark underline">{reviewCount ? reviewCount : 0} reviews</p>
                    </div>
                </div>
                <div className="text-textDark mt-1 text-xs truncate">{getLatestCompany(Experiences) !== null ? getLatestCompany(Experiences) : null}</div>
                <div className="py-1 flex flex-row justify-between items-center text-sm text-textFullDark">
                </div>
            </div>
        </div>
    );
}

const ReuseableComponentForNoCompany = ({ name, designation, time, price, image, isCharity, intro_link, location, professionalID, setProfessionalID, id, setProfilePopUp, lastName, setProfessionalName, Experiences, avgRating, reviewCount }) => {
    let dispatch = useDispatch()

    const getLatestCompany = arr => {
        if (arr.length > 0) {
            const data = arr.sort(function (a, b) {
                var keyA = new Date(a.start_date),
                    keyB = new Date(b.start_date);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
            return data[0]?.company;
        } else {
            return null
        }
    };
    return (
        <div className="cursor-pointer mb-4 md:mb-2 sm:mr-2 md:mr-4 sm:p-1 md:p-3 border border-borderGray box-border rounded-lg hover:shadow-lg  w-56 sm:h-56 md:h-80" onClick={() => {
            // dispatch({ type: 'toggleFreeCallModal' })
            dispatch(toggleFreeCallModal())
            // dispatch({ type: "setNameAndId", id, name: `${name} ${lastName}` });
            dispatch(setNameAndId({ id, name: `${name} ${lastName}` }));
            // dispatch({ type: "navStateAddBy1" });
            dispatch(navStateAddBy1());
            // navigate(`/profile/${id}`)
        }} >
            <div className="rounded overflow-hidden">
                <div className="w-full">
                    <ReactTooltip place="bottom" className="w-40 text-xs" />
                    {intro_link !== null && <div className="relative" data-tip="Mentor has a video introduction">
                        <div className="absolute  m-2">
                            <img src="/showvideo.png" alt="icon" className="w-6" />
                        </div>
                    </div>}
                    {isCharity === 1 && <div className="relative" data-tip="Mentor donates proportion of earnings to charity">
                        <div className="absolute right-0 m-2">
                            <img src="/charity.svg" alt="charity" className="w-5 z-20" />
                        </div>
                    </div>}
                    <img className="rounded-lg w-full sm:h-36 md:h-56 mb-1 md:mb-2 object-cover border border-borderGray" src={image ? image : '/avatar.png'} alt={name} />
                </div>
                <div className="pt-1">
                    <div className="flex items-center justify-between">
                        <div className="text-textFullDark font-bold text-md truncate">
                            {name}
                        </div>
                        <div className="flex items-center">
                            {avgRating > 0 ? <div className="h-5 flex items-center justify-center">
                                {avgRating && <ReactStars
                                    value={RatingHandler(avgRating)}
                                    size={20}
                                    activeColor="#ffd700"
                                    isHalf={true}
                                    edit={false}
                                    count={1}
                                />}
                            </div> : <div className="h-5 flex items-center justify-center">
                                <ReactStars
                                    value={0}
                                    size={20}
                                    activeColor="#ffd700"
                                    isHalf={true}
                                    edit={false}
                                    count={1}
                                />
                            </div>
                            }
                            &nbsp;&nbsp;<p className='text-sm text-textDark -ml-1'>{avgRating ? (+avgRating).toFixed(1) : Math.trunc(0).toFixed(1)}</p>
                        </div>
                    </div>
                    <div className="w-full flex items-end justify-between">
                        <div className="text-xs text-textDark truncate hidden md:inline-block"> {designation.length > 15 ? `${designation.slice(0, 15)} ...` : designation}</div>
                        <div className="text-xs text-textDark truncate md:hidden"> {designation.length > 10 ? `${designation.slice(0, 10)} ...` : designation}</div>
                        <p className="text-xs md:text-sm text-textDark underline">{reviewCount ? reviewCount : 0} reviews</p>
                    </div>
                </div>
                <div className="text-textDark mt-1 text-xs truncate">{getLatestCompany(Experiences) !== null ? getLatestCompany(Experiences) : null}</div>
                {/* <div className="py-1 flex flex-row justify-between items-center text-sm text-textFullDark"> */}
                {/* {price && <div>From <strong> {pricetoDecimal(price)}</strong></div>} */}
                {/* {location && <div><p><strong>{location}</strong></p></div>} */}
                {/* {responseTime() ? <div className="flex items-center justify-center">
                        <img src="Vector.png" alt="photos" className="w-2 h-3" />
                        &nbsp;<p className="text-sm text-gray-500 inline-block"><strong>{responseTime()}</strong></p>
                    </div> : null} */}
                {/* </div> */}
            </div>
        </div>
    );
}