import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bookingModalNavState: 0,
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    navStateToZero: (state) => {
      state.bookingModalNavState = 0;
    },
    navStateDecBy1: (state) => {
      state.bookingModalNavState = state.bookingModalNavState - 1;
    },
    existingNavState: (state) => {
      state.bookingModalNavState = 1;
    },
    navStateAddBy1: (state) => {
      state.bookingModalNavState = state.bookingModalNavState + 1;
    },
    navStateToOne: (state) => {
      state.bookingModalNavState = 1;
    },
  },
  extraReducers: {},
});

export const {
  navStateToZero,
  navStateDecBy1,
  existingNavState,
  navStateAddBy1,
  navStateToOne,
} = navSlice.actions;
export default navSlice.reducer;
