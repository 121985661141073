import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RightArrow from "../Arrows/RightArrow";
import LeftArrow from "../Arrows/LeftArrow";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import './Category.css';
import { useDispatch, useSelector } from "react-redux";
import { disableScroll, enableScroll } from "../Redux/reducers/Scroll/Scroll";
import { useNavigate } from "react-router-dom";
import gifticon from "./gifticon-black.png"
const Gifts = (props) => {
    console.log("gifts", props)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let Ref = useRef(null)
    const scrollingEnabled = useSelector((state) => state.scroll.scrollingEnabled);
    const gradient1 = '#EDF7FE';
    const gradient2 = 'linear-gradient(254.29deg, #5790FF 1.45%, #0075FF 99.12%';
    const gradient3 = 'linear-gradient(254.29deg, #2C91EE 1.45%, #1E8DF3 28.37%, #3B63CB 99.12%)';
    const gradient4 = 'linear-gradient(254.29deg, #4EB2C9 1.45%, #2473D0 28.37%, #005CB1 88.97%)';
    
    const [colors] = useState(['#EDF7FE', '#F2F1F0', '#FDF8F0']);
    const [btnColor] = useState(["#2CC9EE", "#2C91EE", "#4EB2C9", "#5790FF"])
    const [viewAll, setViewAll] = useState(false)
    const modalRef = useRef(null);

    const [settings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: props.len <= 4 ? props.len : 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    })

    //#region disabling scroll
    useEffect(() => {
        if (!scrollingEnabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [scrollingEnabled]);
    //#endregion


    const toggleViewAll = () => {
        setViewAll(!viewAll);
        if (viewAll) {
            dispatch(enableScroll());
        } else {
            dispatch(disableScroll());
        }
    }
    const SlickNext = () => {
        Ref.current.slickNext()
    }

    const SlickPrev = () => {
        Ref.current.slickPrev()
    }
    let counter = 0
    return (
        <div>
            <div className="md:container mx-auto my-4 sm:px-2 xl:px-16 py-2 flex flex-row justify-between items-center">
                <h1 className="text-textFullDark text-2xl font-bold">
                    {props.name}
                </h1>
            </div>
            <div className="md:container mx-auto sm:px-2 md:pl-16 md:pr-12">
                <Slider {...settings} ref={Ref}>
                    {
                        props.catagories.map((p, i) => {
                            let counter2 = counter
                            if (counter === 2) {
                                counter = 0
                            } else {
                                counter += 1
                            }
                            // return <Link key = {i} to={`/mentors/?&end_price=${p.priceinNumber}&start_price=0`} state={{ id: p.id,priceinNumber: p.priceinNumber }}>
                            // return <Link key={i} to={`/mentors/${p.slug}`} state={{ id: p.id, slug: p.slug }}>
                            // <a key={i} href={`http://localhost:3000/mentors/?&end_price=20&start_price=0&id=${p.id}&slug=${p.slug}`}>

                            // return <Link key={i} to={`/mentors/${p.slug}`} state={{ id: p.id, slug: p.slug }}>
                            return <Link key={i} to={`/mentors/?&end_price=${p.priceinNumber}&start_price=${p.startPriceinNumber}`}>
                                <Industry   
                                    image={colors[counter2]}
                                    label={p?.price}
                                    colorBtn={btnColor[counter2]}
                                />

                            </Link>
                        })
                    }
                </Slider>
            </div>
        </div>
    );
}


export default Gifts;

const Industry = ({ image, label, colorBtn }) => {

    return (
        <div className={`mr-2 md:mr-3 sm:p-2 md:py-4 md:px-2 rounded-xl text-gray-600 hover:shadow-lg overflow-hidden h-52 flex flex-col justify-between items-center`}
            style={{
                backgroundColor: image,
            }}
        >
            <div className="p-1 text-left  w-full">
                <img src={gifticon} className="mt-3 w-8 h-8 text-black-700" alt="" />
            </div>
            <div className="flex justify-end items-center w-full px-2">
                <div className="flex flex-col justify-between items-end">
                    <button className="mt-2" style={{ backgroundColor: "white", padding: "5px 18px 5px 18px", borderRadius: "60px" }}>Under</button>
                    <div className="text-right">
                        <p style={{  fontSize: "40px" }}>{label}</p>
                    </div>
                </div>
            </div>
            <div class="w-64 md:w-64 md:ml-24 mt-8 h-48 absolute sm:w-48" style={{ backgroundImage: "url('/landing-page/logo-transparent.png')" }}>
            </div>
        </div>
    );

}