import React, { useState, useEffect, useRef } from "react";
import Colors from "../../../../../Colors/Colors";
import Ratings from "../../../../../Rating/Ratings";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "./BookingDetails.css";
import "react-calendar/dist/Calendar.css";
import { Mixpanel } from "../../../../../Mixpanel";
import "@culturehq/add-to-calendar/dist/styles.css";
import AddToCalendar from "@culturehq/add-to-calendar";
import Customer from "./Customer";
import ReactTooltip from "react-tooltip";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { styled } from '@mui/material/styles';
import { setpebtotrue, setpebtofalse} from "../../../../../Redux/reducers/peb/pebSlice"
import { zIndexNormal } from "../../../../../Redux/reducers/searchBar/searchBar";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  updateDoc,
  doc as remoteDoc,
} from "firebase/firestore";
import db from "../../../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setTooltipFalse, setTooltipTrue } from "../../../../../Redux/reducers/ToolTip/ToolTip";
import { io } from "socket.io-client";
import 'fix-date' 
const BookingDetails = (props) => {
  let dispatch = useDispatch()
  let tooltip = useSelector(state => state.tooltip.isTooltip)
  const { id } = useParams();
  const [cancelOrRecheduleOpen, setCancelOrRecheduleOpen] = useState(tooltip);
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef([]);

  const navigate = useNavigate();
  const [loginUser, setLoginUser] = useState({});
  const [otherUser, setOtherUser] = useState({});
  const [booking, setBooking] = useState({});
  const bookingRef = useRef({});

  const [reviewsCount, setReviewsCount] = useState(0);
  const [rating, setRating] = useState(null);
  const [isBookingActionable, setIsBookingActionable] = useState(true);
  const [isCallStartActionable, setisCallStartActionable] = useState(true);
  const [isBookingRescheduleActionable, setisBookingRescheduleActionable] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [decline, setDecline] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [status, setStatus] = useState(null);
  const [style_, setStyle] = useState(false);
  const [booking_date_end, setBooking_date_end] = useState(new Date());
  const [AfterConvertingtoUTC, setAfterConvertingtoUTC] = useState(new Date());
  const [message, setMessage] = useState([]);
  const [socketState, setSocketState] = useState(null);
  const [isCustomerModal, setIsCustomerModal] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [disableAcceptBtn, setDisabledAcceptBtn] = useState(false);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };

  // with smooth-scroll
  const scrollToBottomWithSmoothScroll = () => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
    scrollToBottomWithSmoothScroll();
  }, [messages]);

  const userId = localStorage.getItem("id");

  const addToCalender = (bookingTime, duration) => {
    let monthValue = "";
    let booking_date = new Date(bookingTime);
    // const booking_time = moment(bookingTime * 1000).format("hh:mm A").split(" ");
    // let hours_mint = booking_time[0].split(":");
    // if (booking_time[1] === "PM") {
    //   hours_mint[0] = parseInt(hours_mint[0]) + 12;
    // }
    // booking_date = booking_date.setHours(hours_mint[0], hours_mint[1]);
    // setBooking_date_end(new Date(booking_date).setMinutes(parseInt(duration) + parseInt(hours_mint[1])));
    // let dateString = new Date(bookingTime).getFullYear() + "-" + monthValue + "-";
    // dateString = new Date(dateString);
    // setAfterConvertingtoUTC(moment(booking_date).utcOffset("GMT-06:00").format());
  };


  const fetchBooking = async () => {

    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER_PATH}/bookings/${id}`).then((result) => {
      if (result.data) {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/booking_logs/${id}?limit=1000`).then((response) => {
          if (response.data) {
            let details = {};
            let { data } = response;
            let professional = result.data?.Professionals_Metadatum?.User;
            let customer = result.data?.User;

            if (professional?.id == userId) {
              setReviewsCount(result.data?.customerReviewCount);
              setRating(result.data?.customerAvgRating);
              details = {
                ...details,
                rating: {
                  ReviewCount: result.data?.customerReviewCount,
                  AvgRating: result.data?.customerAvgRating,
                },
                LoginUser: { ...professional },
                OtherUser: { ...customer },
                bookingLogs: [...data],
              };
            } else {
              setReviewsCount(result.data?.professionalReviewCount);
              setRating(result.data?.professionalAvgRating);
              details = {
                ...details,
                rating: {
                  ReviewCount: result.data?.professionalReviewCount,
                  AvgRating: result.data?.professionalAvgRating,
                },
                LoginUser: { ...customer },
                OtherUser: { ...professional },
                bookingLogs: [...data],
              };
            }
            updateMessagesSocket(details);
            setBooking((prevState) => {
              return { ...prevState, ...result.data };
            });
            bookingRef.current = result.data
          }
        }).catch(e => {
          console.log('Error in booking_logs api', e)
        })
      }
    });
  };

  useEffect(() => {
    fetchBooking();
  }, []);

  useEffect(() => {
    // "socket.io-client" - start
    const connectionOptions = {
      "force new connection": true,
      "reconnection": true,
      "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
      "reconnectionDelayMax": 60000,             //1 minute maximum delay between connections
      "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
      "timeout": 10000,
      "path": "/sockets",
      "auth": { "token": "KaPd$C&F)J@NcRfUjXn2r5u7x!A%D*G-KaPd", "bookingID": id, "userID": localStorage.getItem("id") },                          //before connect_error and connect_timeout are emitted.
      // "transports": ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
    };
    const socket = io(process.env.REACT_APP_SOCKET_SERVER_PATH, connectionOptions);

    socket.on("connect", () => {
      console.log("hello! socket client ", socket.id); // x8WIv7-mJelg7on_ALbx
      setSocketState(socket)
    });

    socket.on("disconnect", () => {
      console.log("Bye! socket client ", socket.id); // undefined
    });
    return () => {
      if (socket) socket.disconnect();
      console.log("Bye! socket client2 ", socket.id);
    }
    // "socket.io-client" - end
  }, [navigate])
  const toggleDecline = () => {
    setDecline(!decline);
  };

  const toggleCancel = () => {
    setCancelled(!cancelled);
    setStyle(false);
    dispatch(zIndexNormal())
  };

  const startCall = () => {
    Mixpanel.track(`web: Start Video Call.`);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PATH}/bookings/${id}/update_status`,
        { status: "call-started" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        console.log("Status Changed to Call Started");
      })
      .catch((e) => {
        console.log("Error in updating the call status to callStarted");
      });
    navigate(`/dashboard/booking/call/hello/${id}`);
  };

  const reschedule = () => {
    navigate("/dashboard/bookings/reshedule", {
      state: { bookingDetails: booking },
    });
  };

  if (decline) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  if (cancelled) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const ChangeStatus = (status, text) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PATH}/bookings/${id}/update_status`,
        { status: status, data: text },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        Mixpanel.track(`web: Update Booking status.`, {
          status: status,
          data: text,
        });

        setStatus(response.data.message.status);
        fetchBooking();
      });
  };


  // const [lastRescheduleId, setLastRescheduleId] = useState(0);
  const lastRescheduleId = useRef(0)

  const setShowResheduleBtn = (item, index) => {
    console.log("🚀 ~ file: BookingDetails.js:231 ~ setShowResheduleBtn ~ index:", item.action, index, lastRescheduleId.current, index >= lastRescheduleId.current, index == lastRescheduleId.current)

    if (index >= lastRescheduleId.current) {
      lastRescheduleId.current = index;
      return true;
    }
    return false;
  }

  const getFinaltext = (booking_status, data) => {
    if (booking_status == 'REQUEST_CANCELLED') {
      setIsBookingActionable(false);
      setisCallStartActionable(false);
      return loginUser.id == data.user?._id
        ? `You've cancelled the session \nReason: ${data.action_data}`
        : `${otherUser?.first_name} cancelled the session \nReason: ${data.action_data}`;
    }
    else if (booking_status == 'REQUEST_ACCEPTED') {
      props.setBookingStatus("approved");
      setIsBookingActionable(false)
      return loginUser.id == data.user?._id
        ? `You've accepted the session request`
        : `${otherUser?.first_name} accepted the session request`;
    }
    else if (booking_status == 'REQUEST_DECLINED') {
      setIsBookingActionable(false);
      setisCallStartActionable(false);
      return loginUser.id == data.user?._id
        ? `You've declined the request.${data.action_data != ''
          ? ' \nReason: ' + `${data.action_data}`
          : ''
        }`
        : `${otherUser?.first_name} declined the request.${data.action_data != ''
          ? ' \nReason: ' + `${data.action_data}`
          : ''
        }`;
    }
    else if (booking_status == 'REQUEST_RESCHEDULE') {
      setIsBookingActionable(false);
      return loginUser.id == data.user?._id
        ? `You've requested to reschedule the session`
        : `${otherUser?.first_name} requested to reschedule the session`;
    }
    else if (booking_status == 'request-reschedule-decline') {
      setisBookingRescheduleActionable(false);
      setisCallStartActionable(false);
      return loginUser.id == data.user?._id
        ? `You've declined request to reschedule the session`
        : `${otherUser?.first_name} declined request to reschedule the session`;
    }
    else if (booking_status == 'REQUEST_RESCHEDULE_ACCEPTED') {
      setIsBookingActionable(false)
      return loginUser.id == data.user?._id
        ? `You've accepted the request to reschedule the session`
        : `${otherUser?.first_name} accepted the request to reschedule the session`;
    }
    else if (booking_status == 'CALL_STARTED') {
      return loginUser.id == data?.user._id
        ? `You've started Call`
        : `${otherUser?.first_name} started Call`
    }
    else if (booking_status == 'CALL_ENDED') {

      return loginUser.id == data?.user._id
        ? `You've ended Call`
        : `${otherUser?.first_name} ended Call`
    }
    else {
      return data?.action_data;
    }


  }


  useEffect(() => {
    if (socketState) {
      socketState?.on('messageResponse', (data) => {
        console.log("🚀 ~ file: BookingDetails.js:339 ~ socketState?.on ~ data:", data)

        if (data.booking_id == booking.id) {
          if (data.action == 'REQUEST_CANCELLED' || data.action == 'REQUEST_ACCEPTED' || data.action == 'SHOW_CALL_BUTTON' || data.action == 'REQUEST_DECLINED' || data.action == 'REQUEST_RESCHEDULE' || data.action == 'request-reschedule-decline' || data.action == 'REQUEST_RESCHEDULE_ACCEPTED' || data?.action === 'NO_SHOW' || data?.action === 'CALL_STARTED' || data?.action === 'CALL_ENDED') {
            let final_text = getFinaltext(data.action, data);
            const item = {
              ...data,
              _id: Math.random(),
              text: final_text,
              createdAt: new Date(data?.createdAt),
            };

            if (!data.system) {
              item.user = {
                ...data?.user,
                name: data?.user?.name,
              };
            }

            const newMessagesList = [...messages];
            newMessagesList.push(item);

            setMessages(newMessagesList);

          }

          if (data.booking_id == booking.id && data.action == 'SHOW_RESCHEDULE_BUTTON') {
            let final_text = getFinaltext(data.action, data);
            const item = {
              ...data,
              _id: Math.random(),
              text: final_text,
              createdAt: new Date(data?.createdAt),
            };

            if (!data.system) {
              item.user = {
                ...data?.user,
                name: data?.user?.name,
              };
            }
            const newMessagesList = [...messages];
            newMessagesList.push(item);

            setMessages(newMessagesList);

          }

          if (
            data.booking_id == booking.id &&
            data?.action_user != loginUser?.id &&
            data.action == 'MESSAGE'
          ) {
            const item = {
              ...data,
              _id: Math.random(),
              text: data?.action_data,
              createdAt: new Date(data?.createdAt),
            };


            if (!data.system) {
              item.user = {
                ...data?.user,
                name: data?.user?.name,
              };
            }

            const newMessagesList = [...messages];
            newMessagesList.push(item);

            setMessages(newMessagesList);

          }
        }

        // return () => (ran = true);
      });
    }
  }, [socketState, messages, setMessages]);

  const ChangeStatusReschedule = (status, data) => {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_PATH}/booking_logs/${data?.id}`,
        {
          "activity_string": true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_PATH}/bookings/${id}/update_status`,
            {
              status: status,
              data: data?.action_data,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
              },
            }
          )
          .then((response) => {
            Mixpanel.track(`web: Update Booking status.`, {
              status: status,
              data: data?.action_data,
            });
            console.log("Status Changed");
            // getBooking();
          })
          .catch((e) => {
            console.log("Error in Change Status");
          });
      })
      .catch((e) => {
        console.log("Error in Change Status");
      });
  };

  const updateMessagesSocket = (details) => {
    const { LoginUser, OtherUser, bookingLogs } = details;
    console.log("updatesocket", details)
    const messages = bookingLogs.map((booking1) => {
      if (booking1.action == "BOOKING_REQUESTED") {
        let final_text =
          LoginUser?.id == booking1.user?._id
            ? `You've ${booking1?.action_data}`
            : `${OtherUser?.first_name} ${booking1?.action_data}`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1.system) {
          data.user = {
            ...booking1.user,
            name: booking1.user?.name,
          };
        }

        return data;
      }
      else if (booking1?.action == "REQUEST_ACCEPTED") {
        let final_text =
          LoginUser.id == booking1?.user?._id
            ? `You've accepted the session request`
            : `${OtherUser?.first_name} accepted the session request`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }
        setIsBookingActionable(false);
        addToCalender(booking?.booking_time, booking?.session_duration);
        return data;
      }
      else if (booking1?.action == "REQUEST_CANCELLED") {
        let final_text =
          LoginUser.id == booking1?.user?._id
            ? `You've cancelled the session \nReason: ${booking1?.action_data}`
            : `${OtherUser?.first_name} cancelled the session \nReason: ${booking1?.action_data}`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }
        setIsBookingActionable(false);
        setisCallStartActionable(false);

        return data;
      }
      else if (booking1?.action == "REQUEST_DECLINED") {
        let final_text =
          LoginUser.id == booking1?.user?._id
            ? `You've declined the request.${booking1?.action_data != ""
              ? " \nReason: " + `${booking1?.action_data}`
              : ""
            }`
            : `${OtherUser?.first_name} declined the request.${booking1?.action_data != ""
              ? " \nReason: " + `${booking1?.action_data}`
              : ""
            }`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }

        setIsBookingActionable(false);
        setisCallStartActionable(false);
        return data;
      }
      else if (booking1?.action == "REQUEST_RESCHEDULE_ACCEPTED") {
        let final_text =
          LoginUser.id == booking1?.user?._id
            ? `You've accepted the request to reschedule the session`
            : `${OtherUser?.first_name} accepted the request to reschedule the session`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }

        setisBookingRescheduleActionable(false);
        setisCallStartActionable(false);

        return data;
      }
      else if (booking1?.action == "request-reschedule-decline") {
        let final_text =
          LoginUser.id == booking1?.user?._id
            ? `You've declined request to reschedule the session`
            : `${OtherUser?.first_name} declined request to reschedule the session`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user.name,
          };
        }

        setisBookingRescheduleActionable(false);
        setisCallStartActionable(false);
        return data;
      }
      else if (booking1?.action == "REQUEST_RESCHEDULE") {
        let final_text =
          LoginUser.id == booking1?.user?._id
            ? `You've requested to reschedule the session`
            : `${OtherUser?.first_name} requested to reschedule the session`;
        const data = {
          ...booking1,
          _id: booking1.id,
          text: final_text,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }
        setIsBookingActionable(false);
        return data;
      } else if (booking1?.action == "SHOW_RESCHEDULE_BUTTON") {
        const data = {
          ...booking1,
          _id: booking1.id,
          text: booking1?.action_data,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }


        setisBookingRescheduleActionable(true);
        return data;
      }
      else {
        const data = {
          ...booking1,
          _id: booking1.id,
          text: booking1?.action_data,
          createdAt: new Date(booking1?.createdAt),
        };

        if (!booking1?.system) {
          data.user = {
            ...booking1?.user,
            name: booking1?.user?.name,
          };
        }

        return data;
      }

    })
    setMessages(messages.reverse());
    setLoginUser((prevState) => {
      return { ...prevState, ...LoginUser };
    });
    setOtherUser((prevState) => {
      return { ...prevState, ...OtherUser };
    });
    setLoading(false);
    setDisabledAcceptBtn(false);

  }
  const addMessageSocket = (e) => {
    e.preventDefault();
    setMessage("");
    ChangeStatus("message", message);
  }

  const [myTime, setMyTime] = useState(new Date());

  useEffect(() => {
    var timerID = setInterval(() => tick(), 10000);

    return () => clearInterval(timerID);
  }, []);

  function tick() {
    fetchBooking();
  }
  // socket - end
  const checkBookingTime = (bookingItem) => {
    var date = new Date();
    var FIVE_MIN = 5 * 60 * 1000;

    var MEETING_END = bookingItem?.session_duration * 60 * 1000;
    if (
      new Date(bookingItem?.booking_time * 1000) - date <= FIVE_MIN &&
      new Date(bookingItem?.booking_time * 1000 + MEETING_END + FIVE_MIN) > date
    ) {
      return true;
    } else {
      return false;
    }
  }

  const handleCancelOrRescheduleClose = () => {
    dispatch(setTooltipFalse())
  };



  // render() {
  return (
    <div>
      {cancelled && (
        <CancelReasonBox
          toggle={toggleCancel}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={decline}
          ChangeStatus={ChangeStatus}
        />
      )}
      {decline && (
        <DeclinedReasonBox
          toggle={toggleDecline}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={decline}
          ChangeStatus={ChangeStatus}
        />
      )}
      <div className="bg-white rounded mb-4">
        <div
          className="flex flex-col justify-between"
          style={{ height: "700px" }}
        >
          {/* Header  */}

          <div className="h-2/12">
            <div className="flex p-4 rounded-t items-center justify-between text-white text-xl w-full bg-bgAppColor">
              <div className="cursor-pointer">
                <span
                  onClick={() => {
                    navigate(`/dashboard/bookings`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </span>
              </div>
              <div className="w-5/6 flex items-center justify-center">
                <p>Booking Details</p>
              </div>
              <div
                className="dropdown"
                onClick={() => {
                  handleCancelOrRescheduleClose();
                  setStyle(!style_);
                }}
              >
                {/* <LightTooltip placement="bottom-end" arrow open={cancelOrRecheduleOpen} onClose={handleCancelOrRescheduleClose} title="Cancel or reshcadule from here" className="inline-block"> */}
                <Tooltip
                  title={`${
                    JSON.parse(localStorage.getItem("type")) === "customer"
                      ? "Cancel or reschedule from here"
                      : "Cancel or reschedule from here"
                  }`}
                  placement="bottom-end"
                  arrow
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={tooltip}
                  sx={{ position: "relative" }}
                  classes={{
                    position: "relative",
                    popper: {
                      position: "relative",
                    },
                  }}
                  onClose={handleCancelOrRescheduleClose}
                  className="z-40 btn_font_family"
                >
                  <span className="dropbtn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      />
                    </svg>
                  </span>
                </Tooltip>

                {/* </LightTooltip> */}
                {style_ ? (
                  <div>
                    <div className="dropdown-content rounded-lg overflow-hidden z-20">
                      {JSON.parse(localStorage.getItem("type")) ===
                        "customer" &&
                        booking?.status === "approved" && (
                          <span
                            onClick={reschedule}
                            className="text-sm  hover:bg-bgdarkGray hover:text-white flex w-full items-center p-3 text-black cursor-pointer"
                          >
                            <span>
                              <img
                                src="/re-schedule.png"
                                className="w-4 h-4"
                                alt="reschedule"
                              />
                            </span>
                            <p className="ml-6">Reschedule</p>
                          </span>
                        )}
                      {JSON.parse(localStorage.getItem("type")) ===
                        "professional" &&
                        booking?.status === "approved" && (
                          <span
                            onClick={reschedule}
                            className="text-sm  hover:bg-bgdarkGray hover:text-white flex w-full items-center p-3 text-black cursor-pointer"
                          >
                            <span>
                              <img
                                src="/re-schedule.png"
                                className="w-4 h-4"
                                alt="reschedule"
                              />
                            </span>
                            <p className="ml-6">Reschedule</p>
                          </span>
                        )}
                      <a
                        href="https://careerpaths.io/support"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <div className="text-sm  hover:bg-bgdarkGray hover:text-white flex w-full items-center p-3 text-black cursor-pointer">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                          <p className="ml-6">Help</p>
                        </div>
                      </a>
                      {booking?.status === "approved" ||
                      booking?.status === "pending" ? (
                        <div
                          className="text-sm hover:bg-bgdarkGray flex w-full items-center p-3 text-red-700 cursor-pointer"
                          onClick={toggleCancel}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </span>
                          <p className="ml-6">Cancel</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* Main Content of Booking Details  */}
          <div
            className="h-full overflow-y-auto px-4 py-6 scrollbar relative"
            ref={messagesEndRef}
          >
            {messages.map((message, index) => {
              if (message.action === "BOOKING_REQUESTED") {
                return (
                  <div key={Math.random()}>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        <img
                          role="button"
                          onClick={() => {
                            setCustomer(
                              loginUser?.id === message.user?._id
                                ? loginUser
                                : otherUser
                            );
                            setIsCustomerModal(true);
                            dispatch(setTooltipFalse());
                          }}
                          src={
                            message.user?.avatar
                              ? message.user?.avatar
                              : "/avatar.png"
                          }
                          alt="elonmusk"
                          className="w-12 h-12 rounded-full object-cover"
                        />
                        <div className={`flex flex-col ml-2`}>
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "BOOKING_DETAIL") {
                return (
                  <div className="mx-auto mt-4 SShadow p-6 rounded-xl sm:w-full md:w-96">
                    <div className="flex">
                      {message?.professional_id === loginUser?.id ? (
                        <img
                          role="button"
                          onClick={() => {
                            setCustomer(otherUser);
                            setIsCustomerModal(true);
                            dispatch(setTooltipFalse());
                          }}
                          src={
                            otherUser?.profile_photo
                              ? otherUser?.profile_photo
                              : "/avatar.png"
                          }
                          className="w-12 h-12 rounded-full object-cover"
                          alt=""
                        />
                      ) : (
                        <Link
                          to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                            message?.professional_id
                          }`}
                          target="_blank"
                        >
                          <img
                            src={
                              otherUser?.profile_photo
                                ? otherUser?.profile_photo
                                : "/avatar.png"
                            }
                            className="w-12 h-12 rounded-full object-cover"
                            alt=""
                          />
                        </Link>
                      )}

                      <div className="flex flex-col ml-4">
                        <p className="font-bold text-lg text-textFullDark -mb-2">{`${otherUser?.first_name} ${otherUser?.last_name}`}</p>
                        <div className="flex items-center justify-center">
                          {rating ? (
                            <div>
                              <Ratings value={Number(rating)?.toFixed(1)} />
                            </div>
                          ) : (
                            <Ratings value={0} />
                          )}

                          {rating && (
                            <p className="text-textDark">
                              {Number(rating)?.toFixed(1)}
                            </p>
                          )}
                        </div>
                        {reviewsCount ? (
                          <p className="text-xs text-textFullDark mt-1">
                            {reviewsCount} reviews
                          </p>
                        ) : (
                          <p className="text-xs text-textFullDark mt-1">
                            {0} reviews
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex mt-4 items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="gray"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                        </span>
                        <p className="text-sm text-gray-700 ml-2 font-bold">{`${moment(
                          booking.booking_time * 1000
                        ).format("MMMM DD,YYYY")} at ${moment(
                          booking.booking_time * 1000
                        ).format("hh:mm A")}`}</p>
                      </div>
                      <div className="flex mt-4 items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="gray"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                            />
                          </svg>
                        </span>
                        <p className="text-sm text-gray-700 ml-2 font-bold">
                          {booking?.topic}
                        </p>
                      </div>
                      <div className="flex mt-4 items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="gray"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                        <p className="text-sm text-gray-700 ml-2 font-bold">
                          Call request notes
                        </p>
                      </div>
                    </div>
                    <div className="text-xs text-textDark mt-4">
                      {booking?.notes}
                    </div>
                    {booking?.status == "pending" &&
                      booking?.professional_id == loginUser?.id &&
                      isBookingActionable && (
                        <div className="flex flex-col mt-4">
                          {disableAcceptBtn ? (
                            <div
                              className={`bg-bgdarkGray border border-borderGray text-white py-3 flex justify-center items-center text-sm rounded-full w-full`}
                            >
                              <div className="loading-spinner"></div>
                            </div>
                          ) : (
                            <>
                              <button
                                disabled={disableAcceptBtn}
                                className={`${
                                  disableAcceptBtn
                                    ? "bg-bgdarkGray"
                                    : "bg-bgAppColor"
                                } border border-borderGray text-white py-3 flex justify-center items-center text-sm rounded-full w-full`}
                                onClick={() => {
                                  // firebaseAcceptBookinglogs();
                                  setDisabledAcceptBtn(true);
                                  ChangeStatus("approved");
                                }}
                              >
                                Accept
                              </button>
                            </>
                          )}

                          <div className="flex w-full mt-2">
                            <button
                              className="border border-borderGray text-white bg-bgAppColor  py-3 flex justify-center items-center text-sm rounded-full w-1/2"
                              onClick={reschedule}
                            >
                              Reschedule
                            </button>
                            <button
                              className="border border-borderGray text-white bg-black  py-3 flex justify-center items-center text-sm rounded-full w-1/2 ml-2"
                              onClick={toggleDecline}
                            >
                              Decline
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                );
              }

              if (message.action === "REQUEST_ACCEPTED") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        <Link
                          to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                            message?.professional_id
                          }`}
                          target="_blank"
                        >
                          <img
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        </Link>
                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              if (message.action === "SHOW_CALL_BUTTON") {
                return booking?.status === "approved" ? (
                  <>
                    <div className="w-full flex justify-center mt-8">
                      <AddToCalendarButton
                        name={booking?.topic}
                        label="Add reminder to calendar"
                        description={booking?.notes}
                        options="'Apple','Google','iCal','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
                        startDate={moment(
                          new Date(booking.booking_time * 1000)
                        ).format("YYYY-MM-DD")}
                        endDate={moment(
                          new Date(booking.booking_time * 1000)
                        ).format("YYYY-MM-DD")}
                        startTime={moment(
                          new Date(booking.booking_time * 1000)
                        ).format("HH:mm")} //"10:10"
                        endTime={moment(new Date(booking.booking_time * 1000))
                          .add(booking.session_duration, "minutes")
                          .format("HH:mm")} //"10:40"
                        styleLight="--btn-background: #00C4FF;--btn-text:#f9f9f9;--btn-shadow:0 0 #0000;--btn-background-hover:#00C4FF"
                        styleDark="--btn-background: #00C4FF;--btn-text:#f9f9f9;--btn-shadow:0 0 #0000;--btn-background-hover:#00C4FF"
                        buttonStyle="round"
                        inline
                        listStyle="modal"
                        hideCheckmark
                        timeZone="currentBrowser"
                      ></AddToCalendarButton>
                    </div>
                    <StartVideoCall
                      Time={booking.booking_time}
                      booking={booking}
                      startCall={startCall}
                      myTime={myTime}
                    />
                  </>
                ) : (
                  <></>
                );
              }
              if (message.action === "BOOKING_EXPIRED") {
                return (
                  <div className="flex w-full justify-center items-center mt-8 mb-2">
                    <div className="flex rounded-full bg-gray-500 p-2 px-5 justify-center">
                      <div>
                        <p className="text-sm text-white text-center">
                          This request has expired
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "REQUEST_CANCELLED") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <img
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </Link>
                        ) : (
                          <img
                            role="button"
                            onClick={() => {
                              setCustomer(
                                loginUser?.id !== message.user?._id
                                  ? otherUser
                                  : loginUser
                              );
                              setIsCustomerModal(true);
                              dispatch(setTooltipFalse());
                            }}
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        )}

                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "REQUEST_DECLINED") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <img
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </Link>
                        ) : (
                          <img
                            role="button"
                            onClick={() => {
                              setCustomer(
                                loginUser?.id !== message.user?._id
                                  ? otherUser
                                  : loginUser
                              );
                              setIsCustomerModal(true);
                              dispatch(setTooltipFalse());
                            }}
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        )}

                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "REQUEST_RESCHEDULE") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <img
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </Link>
                        ) : (
                          <img
                            role="button"
                            onClick={() => {
                              setCustomer(
                                loginUser?.id !== message.user?._id
                                  ? otherUser
                                  : loginUser
                              );
                              setIsCustomerModal(true);
                              dispatch(setTooltipFalse());
                            }}
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        )}

                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action == "SHOW_RESCHEDULE_BUTTON") {
                return (
                  <>
                    <div className="w-full flex items-center justify-center mt-4">
                      <div className="w-3/5  bg-bgGray rounded-lg p-4 text-textFullDark">
                        <p className="text-sm font-bold">New Proposed Time</p>
                        <div className="flex items-center">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="gray"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                              />
                            </svg>
                          </span>
                          <p className="text-xs font-bold ml-2">
                            Time :{" "}
                            {moment(message.action_data * 1000).format(
                              "MMMM DD,YYYY hh:mm A"
                            )}
                          </p>
                        </div>
                        {message?.user?._id != loginUser.id &&
                          (booking.status == "pending" ||
                            booking.status == "approved") &&
                          true &&
                          index ===
                            messages
                              .map((item) => item.action)
                              .lastIndexOf("SHOW_RESCHEDULE_BUTTON") &&
                          message?.activity_string == null && (
                            <div>
                              <div className="flex mt-4">
                                {disableAcceptBtn ? (
                                  <div
                                    className={`bg-bgdarkGray border border-borderGray text-white py-3 flex justify-center items-center text-sm rounded w-1/2`}
                                  >
                                    <div className="loading-spinner"></div>
                                  </div>
                                ) : (
                                  <>
                                    <button
                                      disabled={disableAcceptBtn}
                                      className={`${
                                        disableAcceptBtn
                                          ? "bg-bgdarkGray"
                                          : "bg-bgAppColor"
                                      } border border-borderGray text-white py-3 flex justify-center items-center text-sm rounded w-1/2 `}
                                      onClick={() => {
                                        setDisabledAcceptBtn(true);
                                        ChangeStatusReschedule(
                                          "request-reschedule-accept",
                                          message
                                        );
                                      }}
                                    >
                                      Accept
                                    </button>
                                  </>
                                )}

                                {booking.status == "pending" ? (
                                  <button
                                    className="border border-borderGray text-gray-600  py-3 flex justify-center items-center text-sm rounded w-1/2 ml-2"
                                    onClick={() =>
                                      ChangeStatusReschedule(
                                        "request-reschedule-decline",
                                        message
                                      )
                                    }
                                  >
                                    Decline
                                  </button>
                                ) : (
                                  <button
                                    className="border border-borderGray text-gray-600  py-3 flex justify-center items-center text-sm rounded w-1/2 ml-2"
                                    onClick={() => reschedule()}
                                  >
                                    Reschedule
                                  </button>
                                )}
                              </div>
                              {booking.status == "pending" ? (
                                <button
                                  className="bg-bgAppColor border border-borderGray text-white py-3 flex justify-center items-center text-sm rounded w-full mt-2 "
                                  onClick={() => reschedule()}
                                >
                                  Reschedule
                                </button>
                              ) : null}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                );
              }
              if (message.action === "REQUEST_RESCHEDULE_ACCEPTED") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <img
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </Link>
                        ) : (
                          <img
                            role="button"
                            onClick={() => {
                              setCustomer(
                                loginUser?.id !== message.user?._id
                                  ? otherUser
                                  : loginUser
                              );
                              setIsCustomerModal(true);
                              dispatch(setTooltipFalse());
                            }}
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        )}

                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "request-reschedule-decline") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <img
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </Link>
                        ) : (
                          <img
                            role="button"
                            onClick={() => {
                              setCustomer(
                                loginUser?.id !== message.user?._id
                                  ? otherUser
                                  : loginUser
                              );
                              setIsCustomerModal(true);
                              dispatch(setTooltipFalse());
                            }}
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        )}
                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "MESSAGE") {
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <img
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </Link>
                        ) : (
                          <img
                            role="button"
                            onClick={() => {
                              setCustomer(
                                loginUser?.id !== message.user?._id
                                  ? otherUser
                                  : loginUser
                              );
                              setIsCustomerModal(true);
                              dispatch(setTooltipFalse());
                            }}
                            src={
                              message.user?.avatar
                                ? message.user?.avatar
                                : "/avatar.png"
                            }
                            alt="elonmusk"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                        )}
                        <div className="flex flex-col ml-2">
                          <p
                            className={`text-sm p-3 px-6 flex items-center break-all mr-2 ${
                              loginUser?.id !== message.user?._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-bgAppColor"
                            } `}
                          >
                            {message?.text}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">{`${moment(
                              message.createdAt
                            ).format("MMMM DD,YYYY")} at ${moment(
                              message.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "BOOKING_FEEDBACK") {
                const data = message.action_data?.trim().split("&&");
                const feedBackRating = data[0];
                const feedBackText = data[1];
                return (
                  <div>
                    <div
                      className={
                        loginUser?.id !== message.user?._id
                          ? "flex"
                          : "flex justify-end"
                      }
                    >
                      <div
                        className={
                          loginUser?.id !== message.user?._id
                            ? "flex mt-4"
                            : "flex flex-row-reverse mt-4"
                        }
                      >
                        {message?.professional_id === message.user?._id ? (
                          <Link
                            to={`/profile/${otherUser?.first_name?.toLowerCase()}-${otherUser?.last_name?.toLowerCase()}-${
                              message?.professional_id
                            }`}
                            target="_blank"
                          >
                            <div className="w-12 h-12 rounded-full object-cover">
                              <img
                                src={
                                  message.user?.avatar
                                    ? message.user?.avatar
                                    : "/avatar.png"
                                }
                                alt="elonmusk"
                                className="w-12 h-12 rounded-full object-cover"
                              />
                            </div>
                          </Link>
                        ) : (
                          <div className="w-12 h-12 rounded-full object-cover">
                            <img
                              role="button"
                              onClick={() => {
                                setCustomer(
                                  loginUser?.id !== message.user._id
                                    ? otherUser
                                    : loginUser
                                );
                                setIsCustomerModal(true);
                                dispatch(setTooltipFalse());
                              }}
                              src={
                                message.user?.avatar
                                  ? message.user?.avatar
                                  : "/avatar.png"
                              }
                              alt="elonmusk"
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          </div>
                        )}
                        <div className="flex flex-col ml-2">
                          <div
                            className={`text-sm p-3 px-6 flex justify-end flex-col mr-2 ${
                              loginUser?.id !== message.user._id
                                ? "text-black bg-bgGray rounded-r-2xl rounded-bl-2xl"
                                : " rounded-l-2xl rounded-br-2xl text-white bg-gray-500"
                            } `}
                          >
                            <div className="flex ">
                              <p>
                                {loginUser?.id == message.user?._id
                                  ? `You gave`
                                  : "You got"}{" "}
                                &nbsp;
                              </p>
                              {feedBackRating} ⭐ &nbsp;
                              <p>
                                {" "}
                                {loginUser?.id == message.user?._id
                                  ? `to ${otherUser?.first_name} ${otherUser?.last_name}`
                                  : `from ${message?.user.name}`}{" "}
                                &nbsp;{" "}
                              </p>
                            </div>
                            <p>
                              {feedBackText
                                ? ` and also said " ${feedBackText} "`
                                : ""}
                            </p>
                            {/* {message?.text} */}
                          </div>
                          <div className="flex">
                            <p className="text-xs text-textDark mt-2 mr-2">
                              {`${message.createdAt.toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "CALL_STARTED") {
                return (
                  <div className="flex w-full justify-center items-center mt-8 mb-2">
                    <div className=" rounded-full bg-gray-500 py-2 px-5 justify-center">
                      <div>
                        <p className="text-sm text-white text-center">
                          {loginUser?.id == message.user?._id
                            ? `You've `
                            : `${otherUser?.first_name} ${otherUser?.last_name}`}{" "}
                          started the call
                        </p>
                        <p className="text-sm text-white text-center">
                          {`${moment(message.createdAt).format(
                            "MMMM DD,YYYY"
                          )} at ${moment(message.createdAt).format("hh:mm A")}`}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.action === "NO_SHOW") {
                return (
                  <div className="flex w-full justify-center items-center mt-8 mb-2">
                    <div className="flex rounded-full bg-gray-500 p-2 px-5 justify-center">
                      <div>
                        <p className="text-sm text-white text-center">
                          {message?.action_data}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {/* Message Input  */}
          {booking?.status === "expired" ||
          booking?.status === "cancelled" ||
          booking?.status === "declined" ||
          booking?.status === "pending" ? null : (
            <div className="p-2 mt-5 w-full border-t">
              <form
                onSubmit={addMessageSocket}
                className="flex justify-between"
              >
                {booking?.status === "approved" && (
                  //  <LightTooltip placement="bottom" arrow open={videoCallOpen} onClose={handleVideoCallClose}  title="Start video call button will be enabled 5 minutes before the scheduled meeting time!">
                  <span
                    className={`w-10 h-10 cursor-pointer border bg-bgAppColor text-white p-2 rounded-full mr-2 flex items-center justify-center`}
                    disabled={checkBookingTime(booking)}
                    style={{
                      backgroundColor: checkBookingTime(booking)
                        ? Colors.blue
                        : "gray",
                    }}
                    onClick={() => {
                      if (checkBookingTime(booking)) {
                        startCall();
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </span>
                  // </LightTooltip>
                )}
                <input
                  className={`w-full rounded px-2`}
                  type="text"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="Message ..."
                />
                <div className="ml-2 w-10 h-10">
                  <button
                    className={`w-10 h-10 border bg-bgAppColor text-white p-1 rounded-full flex items-center justify-center`}
                    disabled={message.length === 0}
                    type="submit"
                  >
                    <img alt="" src="/send.png" className="w-24" />
                  </button>
                </div>
              </form>
            </div>
          )}

          {isCustomerModal && (
            <Customer
              customer={customer}
              setIsCustomerModal={setIsCustomerModal}
              isCustomerModal={isCustomerModal}
            />
          )}
        </div>
      </div>
    </div>
  );
  // };
};

export default BookingDetails;

const DeclinedReasonBox = (props) => {
  const [declineText, setDeclineText] = useState("");
  const [remainingWords, setRemainingWords] = useState(100);
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay} onClick={props.toggle}></div>
      <div className="flex w-full items-center justify-center md:w-1/3 z-50">
        <div className="shadow flex w-3/4 rounded-lg bg-white p-8">
          <div className="w-full">
            <p className="text-sm text-textDark w-full font-bold">Note</p>
            <textarea
              className="w-full border border-borderGray focus:outline-none mt-2 rounded-lg h-36 text-xs p-3"
              placeholder="Write here"
              maxLength={100}
              value={declineText}
              onChange={(e) => {
                setDeclineText(e.target.value);
                setRemainingWords(100 - e.target.value.length);
              }}
            ></textarea>
            <p className="text-xs mt-2 text-red-500">
              {remainingWords} words remaining
            </p>
            <button
              className="py-3 mt-4 text-white bg-black flex justify-center items-center text-sm rounded w-full "
              onClick={() => {
                if (declineText !== "") {
                  props.toggle();
                  props.ChangeStatus("declined", declineText);
                }
              }}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CancelReasonBox = (props) => {
  const [cancelText, setCancelText] = useState("");
  const [remainingWords, setRemainingWords] = useState(100);
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay} onClick={props.toggle}></div>
      <div className="flex w-full items-center justify-center md:w-1/3 z-50">
        <div className="SShadow flex w-3/4 rounded-lg bg-white p-8">
          <div className="w-full">
            <p className="text-sm text-textDark w-full font-bold">Note</p>
            <textarea
              className="w-full border border-borderGray focus:outline-none mt-2 rounded-lg h-36 text-xs p-3"
              placeholder="Write here"
              maxLength={100}
              value={cancelText}
              onChange={(e) => {
                setCancelText(e.target.value);
                setRemainingWords(100 - e.target.value.length);
              }}
            ></textarea>
            <p className="text-xs mt-2 text-red-500">
              {remainingWords} words remaining
            </p>
            <button
              className="py-3 mt-3 text-white bg-black flex justify-center items-center text-sm rounded w-full "
              onClick={() => {
                if (cancelText !== "") {
                  props.ChangeStatus("cancel", cancelText);
                  props.toggle();
                }
              }}
            >
              Cancel Booking
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const StartVideoCall = (props) => {
  const [timer, setTimer] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const checkBookingTime = (bookingItem) => {
    var date = new Date();
    var FIVE_MIN = 5 * 60 * 1000;

    var MEETING_END = bookingItem?.session_duration * 60 * 1000;
    if (
      new Date(bookingItem?.booking_time * 1000) - date <= FIVE_MIN &&
      new Date(bookingItem?.booking_time * 1000 + MEETING_END + FIVE_MIN) > date
    ) {
      return true;
    } else {
      return false;
    }
  }

  const startCall = () => {
    Mixpanel.track(`web: Start Video Call.`);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PATH}/bookings/${id}/update_status`,
        { status: "call-started" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        console.log("Status Changed to Call Started");
      })
      .catch((e) => {
        console.log("Error in updating the call status to callStarted");
      });
    navigate(`/dashboard/booking/call/hello/${id}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setTimer((prev) => !prev);
    }, [20000]);
  }, [timer]);
  return (
    <div className="flex w-full justify-center items-center mt-2">
      <div className="SShadow flex w-3/4 rounded-lg bg-white p-8">
        <div>
          <p className="text-sm text-textDark text-center">
            Start video call button will be enabled 5 minutes before the
            scheduled meeting time
          </p>
          <p className="text-sm text-textDark text-center">
            {props?.myTime[0]}
          </p>
          <button
            className="py-3 mt-4 text-white flex justify-center items-center text-sm rounded-full w-full bg-bgdarkGray"
            disabled={!checkBookingTime(props.booking)}
            style={{
              backgroundColor: checkBookingTime(props.booking) ? Colors.blue : "gray",
            }}
            onClick={() => {
              if (checkBookingTime(props.booking)) {
                startCall();
              }
            }}
          >
            <span className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </span>
            <p className="sm:text-xs md:text-sm">Start Video Call</p>
          </button>
        </div>
      </div>
    </div>
  );
};
