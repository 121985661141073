import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TextLoop from "react-text-loop";
import BookingModal from "../BookingModal/BookingModal";
import { CompanyTitle } from "../settings";
import { toggleFreeCallModal } from '../Redux/reducers/freeCall/freeCallSlice'
import { navStateToZero } from '../Redux/reducers/nav/navSlice'
import Button from '../components/Button';
const MainImage = () => {
    let location = useLocation()
    let isModal = useSelector(state => state.freeCall.isModal)
    let [partner, setPartner] = useState(false)
    let [partnerName, setPartnerName] = useState('')
    let dispatch = useDispatch()
    let isBanner = useSelector(state => state.banner.isBanner)
    let navigate = useNavigate()
    const [industries, setIndustries] = useState([])
    const toggleModal = () => {
        dispatch(toggleFreeCallModal())
        dispatch(navStateToZero())
    }
    const getQueryParam = () => {
        let match = RegExp('[?&]org=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    let checkForPartner = () => {
        let forPartner = getQueryParam()
        if (forPartner !== null) {
            setPartner(true)
            setPartnerName(forPartner)
        } else {
            console.log('Error in partner check')
        }
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_PATH + `/topics_of_discussion`)
            .then((response) => {
                setIndustries(prev => [...prev, response.data.message.industries])
                let industryName = []
                response.data.message.industries.forEach((topic) => {
                    industryName.push(topic.industry)
                })
            }).catch((e) => {
                console.log("Error in Fetching Industry Api from Filters.js in Landing page");
            })
        checkForPartner()
    }, [])

    return (
      <div className="xl:container mx-auto flex flex-col lg:flex-row sm:px-2 mt-2 md:mt-6">
        {/* Testing */}
        {isModal && (
          <BookingModal
            toggle={toggleModal}
            overlay="overlay"
            modal="modal"
            modalcontent="modal-content"
            closeModal="close-modal"
            partner={partner}
            partnerName={partnerName}
          />
        )}
        <div
          className="rounded-3xl hidden md:flex w-full justify-center"
          style={{
            backgroundImage: "url(/bannergradientslanding.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <div className="w-full md:w-7/12 xl:w-7/12 flex flex-col justify-center items-start px-2 py-10 md:py-0 md:px-8 ">
            <div
              className="text-black text-2xl md:text-4xl 2xl:text-5xl"
              //   style={{ fontFamily: "Cerebri Extra Bold" }}
              style={{ fontFamily: "Lato Extra Bold" }}
            >
              <h1 className="mr-3">
                1:1 mentoring and career advice in{" "}
                {industries.length > 0 && (
                  <TextLoop
                    interval={2000}
                    className="border-b-4 border-black underline text-left"
                    children={industries}
                  />
                )}
              </h1>
            </div>
            {partner && partnerName === "Finatal" ? (
              <p className="text-textDark text-lg my-4">
                {CompanyTitle} have partnered with Finatal to offer a free
                mentoring session with a Private Equity professional. Click
                below to choose your mentor!
              </p>
            ) : (
              <h2
                className="text-2xl text-textDark my-4"
                style={{ fontFamily: "Lato sans-serif" }}
              >
                Make impossible career connections possible
              </h2>
            )}
            <div>
              {!partner && (
                <button
                  className="myButton"
                  onClick={() => navigate("/mentors")}
                >
                  Get Started
                </button>
              )}
            </div>

            {partner && (
              <div
                className="my-4 md:mt-24"
                // style={{ fontFamily: "Cerebri Extra Bold" }}
                style={{ fontFamily: "Lato Extra Bold" }}
              >
                {partnerName.toLowerCase() === "Linkedin".toLowerCase() ? (
                  <div className="flex items-center">
                    <p className="text-3xl mr-2">In partnership with</p>
                    <img
                      src="/linkedinPic.png"
                      alt="linkedin"
                      className="rounded ml-4 w-14"
                    />
                  </div>
                ) : partnerName.toLowerCase() === "PER".toLowerCase() ? (
                  <div className="flex items-center">
                    <p className="text-3xl mr-2">In partnership with</p>
                    <img
                      src="/per.png"
                      alt="per"
                      className="rounded-lg ml-6 w-36"
                    />
                  </div>
                ) : partnerName.toLowerCase() === "Finatal".toLowerCase() ? (
                  <div className="flex items-center">
                    <p className="text-3xl mr-2">In partnership with</p>
                    <img
                      src="/finital.png"
                      alt="finital"
                      className="rounded-lg ml-1 w-36"
                    />
                  </div>
                ) : partnerName.toLowerCase() === "JBM".toLowerCase() ? (
                  <div className="flex items-center">
                    <p className="text-3xl mr-2">In partnership with</p>
                    <img
                      src="/jbm.jpeg"
                      alt="finital"
                      className="rounded-lg ml-1 w-36"
                    />
                  </div>
                ) : partnerName.toLowerCase() ===
                  "efinancialcareers".toLowerCase() ? (
                  <div className="flex items-center">
                    <p className="text-3xl mr-2">In partnership with</p>
                    <img
                      src="/eFinancialServices.png"
                      alt="finital"
                      className="rounded-lg ml-1 w-36"
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div className="hidden md:flex md:w-3/12 xl:w-3/12">
            <img
              src="/bannergif.gif"
              alt="header"
              className="w-full object-cover -mt-12"
              style={{ height: "600px" }}
            />
          </div>
        </div>

        {isBanner ? (
          <div
            className="rounded-3xl flex flex-col md:hidden w-full justify-center"
            style={{
              backgroundImage: "url(bannergradientslanding.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              zIndex: "2",
            }}
          >
            <div className="flex md:hidden -mt-6">
              <div className="relative top-6 overflow-hidden">
                <img
                  src="/smallscreenvideo.gif"
                  alt="header"
                  className="object-cover"
                  style={{ height: "430px" }}
                />
              </div>
            </div>
            <div
              className="w-full flex flex-col justify-center items-start px-2 pb-10 md:px-8 mt-2"
              style={{ fontFamily: "Lato Bold" }}
            >
              <div className="text-black text-3xl">
                <div>
                  <h1 className="text-center px-4">
                    1:1 mentoring and career advice in
                  </h1>
                  <h1 className={`text-center`}>
                    {industries.length > 0 && (
                      <TextLoop
                        interval={2000}
                        className="border-b-4 border-black "
                        children={industries}
                      />
                    )}
                  </h1>
                </div>
              </div>
              <h2
                className="text-lg text-textFullDark my-4 text-center"
                style={{ fontFamily: "Lato sans-serif" }}
              >
                Make impossible career connections possible
              </h2>
              <div className="w-full flex items-center flex-col justify-center">
                {!partner && (
                  <button
                    className="w-2/3 py-2 rounded-full px-4 text-white font-bold text-lg z-10 border-2 border-borderApp bg-bgAppColor"
                    style={{ fontFamily: "Lato Bold" }}
                    onClick={() => navigate("/mentors")}
                  >
                    Get Started
                  </button>
                )}
              </div>
              {partner && (
                <div
                  className="my-4 w-full flex justify-center"
                  //   style={{ fontFamily: "Cerebri Extra Bold" }}
                  style={{ fontFamily: "Lato Extra Bold" }}
                >
                  {partnerName.toLowerCase() === "Linkedin".toLowerCase() ? (
                    <div className="flex flex-col items-center">
                      <p className="text-2xl">In partnership with</p>
                      <img
                        src="/linkedinPic.png"
                        alt="linkedin"
                        className="rounded mr-3 w-10 h-10"
                      />
                    </div>
                  ) : partnerName.toLowerCase() === "PER".toLowerCase() ? (
                    <div className="flex flex-col items-center">
                      <p className="text-2xl">In partnership with</p>
                      <img
                        src="/per.png"
                        alt="finital"
                        className="rounded-lg mr-3 w-24"
                      />
                    </div>
                  ) : partnerName.toLowerCase() === "Finatal".toLowerCase() ? (
                    <div className="flex flex-col items-center">
                      <p className="text-2xl">In partnership with</p>
                      <img
                        src="/finital.png"
                        alt="finital"
                        className="rounded-lg mr-3 w-24"
                      />
                    </div>
                  ) : partnerName.toLowerCase() === "JBM".toLowerCase() ? (
                    <div className="flex flex-col items-center">
                      <p className="text-2xl">In partnership with</p>
                      <img
                        src="/jbm.jpeg"
                        alt="finital"
                        className="rounded-lg mr-3 w-24"
                      />
                    </div>
                  ) : partnerName.toLowerCase() ===
                    "efinancialcareers".toLowerCase() ? (
                    <div className="flex flex-col items-center">
                      <p className="text-2xl">In partnership with</p>
                      <img
                        src="/eFinancialServices.png"
                        alt="finital"
                        className="rounded-lg mr-3 w-24"
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
}

export default MainImage;
