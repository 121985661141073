import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import { Mixpanel } from "../../Mixpanel";
import "./listing.css";
import FilterUsers from "../FilterUsers/FilterUsers";
import Loader from "react-loader-spinner";
import Colors from "../../Colors/Colors";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { useNavigate, useLocation, useParams } from "react-router";
import EFCBanner from "../../components/CoursesCard/BannerForListingPage";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function Index({ route }) {
  let navigate = useNavigate();
  const location = useLocation();
  const loc = useLocation();
  let { industries } = useParams();
  const sortingOptions = [
    {
      label: "Recommended",
      value: "recommended",
    },
    {
      label: "Price:High-Low",
      value: "highToLow",
    },
    {
      label: "Price:Low-High",
      value: "lowToHigh",
    },
    // {
    //   label: "Reviews:Low-High",
    //   value: "rattingLowHigh",
    // },
    // {
    //   label: "Reviews:High-Low",
    //   value: "rattingHighLow",
    // },

    {
      label: "Newest",
      value: "newest",
    },
    {
      label: "Sort by video",
      value: "profileVideo",
    },
  ];

  const filterByPriceOptions = [
    {
      label: " £0  -  £20",
      value: ["0", "20"],
    },
    {
      label: " £20  -  £30",
      value: ["20", "30"],
    },
    {
      label: " £30  -  £40",
      value: ["30", "40"],
    },
    {
      label: " £40  -  £50",
      value: ["40", "50"],
    },
    {
      label: " £50+",
      value: ["51", "50000"],
    },
  ];

  const filterByNoOfStars = [4, 3, 2, 1];
  const [selectedFilterByNoOfStars, setSelectedFilterByNoOfStars] = useState(0);

  const [filterByIndustryOptions, setFilterByIndustryOptions] = useState([]);
  const [isVideoSeleted, setVideoSelected] = useState(false);

  // selectedSearchKeywords state is used for call the API on submit search
  // while tempSelectedSearchKeywords is used for taking value from input
  const selectedSearchKeywords = useRef("");
  const [tempSelectedSearchKeywords, setTempSelectedSearchKeywords] =
    useState("");

  const [selectedSort, setSelectedSort] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState([null, null]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [interest, setInterest] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState([]);

  // we will change this variable after changes in any filter option and then the page will reload.
  const [reloadFlag, setReloadFlag] = useState(true);

  // selectedIndustriesRef reference is use for directly selecting variables so that we will not wait for loading of state.
  // it is added because when we navigate from landing page by clicking on view all button it was fetching all mentors
  const selectedIndustriesRef = useRef([]);

  // selectedExperinceRange state is used for call the API on release of slider
  // while tempSelectedExperinceRange is used for taking value on dragging of slider
  const [selectedExperinceRange, setSelectedExperinceRange] = useState([0, 20]);
  const [tempSelectedExperinceRange, setTempSelectedExperinceRange] = useState([
    0, 20,
  ]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...availableLocations]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const selectedIndustriesChangeHandler = (event) => {
    const value = event.target.value;
    if (selectedIndustries.includes(value)) {
      setSelectedIndustries(
        selectedIndustries.filter((selectedIndustry) => {
          return selectedIndustry !== value;
        })
      );

      selectedIndustriesRef.current = selectedIndustries.filter(
        (selectedIndustry) => {
          return selectedIndustry !== value;
        }
      );
    } else {
      setSelectedIndustries([...selectedIndustries, value]);
      selectedIndustriesRef.current = [...selectedIndustriesRef.current, value];
    }
  };
  // open/toggle sub industries of an industry
  const [toggledIndustriesId, setToggledIndustriesId] = useState([]);
  const toggleSubIndustries = (index) => {
    if (toggledIndustriesId.includes(index)) {
      setToggledIndustriesId(
        toggledIndustriesId.filter((toggledIndustry) => {
          return toggledIndustry !== index;
        })
      );
    } else {
      setToggledIndustriesId([...toggledIndustriesId, index]);
    }
  };

  const clearAllFilters = () => {
    setSelectedSort("");
    setSelectedPriceRange([null, null]);
    setSelectedIndustries([]);
    setSelectedInterest([]);
    selectedIndustriesRef.current = [];
    setSelectedExperinceRange([0, 20]);
    selectedSearchKeywords.current = "";
    setTempSelectedExperinceRange([0, 20]);
    setTempSelectedSearchKeywords("");
    setVideoSelected(false);
    setSelectedFilterByNoOfStars(0);
    setReloadFlag(!reloadFlag);
    setSelectedLocation(false);
  };

  const getIdsBySlug = (slugs) => {
    console.log(slugs, "slug");
    const selectIndustryIds = [];
    slugs.forEach((slug) => {
      filterByIndustryOptions.forEach((industry) => {
        if (industry.slug == slug) {
          selectIndustryIds.push(industry.id);
        }
        if (industry.sub.length > 0) {
          industry.sub.forEach((subIndustry) => {
            if (subIndustry.slug == slug) {
              selectIndustryIds.push(subIndustry.id);
            }
          });
        }
      });
    });
    return selectIndustryIds;
  };

  const [mentors, setMentors] = useState([]);

  const getMentors = (locat) => {
    const searchParams = new URLSearchParams(window.location.search);
    const endPrice = searchParams.get("end_price");
    const startPrice = searchParams.get("start_price");
    const loca = searchParams.get("location");
    console.log("loga", loca);
    let isFeatured = searchParams.get("featured") === "1";

    setLoading(true);
    const endpoint = `${process.env.REACT_APP_SERVER_PATH}/professionals?${
      isFeatured ? "featured=1&" : ""
    }industry_id=${getIdsBySlug(
      selectedIndustriesRef.current
    )}&rating=${selectedFilterByNoOfStars}&end_price=${
      endPrice || selectedPriceRange[1]
    }&start_price=${startPrice || selectedPriceRange[0]}&start_exp_range=${
      selectedExperinceRange[0]
    }&end_exp_range=${
      selectedExperinceRange[1] < 19 ? selectedExperinceRange[1] : 70
    }&sortby=${selectedSort}&interest_id=${selectedInterest.toString()}&sort_by_video=${isVideoSeleted}${
      selectedSearchKeywords.current
        ? "&search=" + selectedSearchKeywords.current
        : ""
    }${loca || locat ? "&location=" + loca || locat : ""}`;
    console.log("🚀 ~ file: index.js:170 ~ getMentors ~ endpoint", endpoint);

    axios
      .get(endpoint)
      .then((response) => {
        Mixpanel.track(`web: Search professionals.`, {
          Industry_id: selectedIndustriesRef.current,
          end_price: endPrice || selectedPriceRange[1],
          start_price: startPrice || selectedPriceRange[0],
          sortby: selectedSort,
          search: selectedSearchKeywords.current,
        });
        setMentors(response.data);
        console.log("response", response.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occur in professional");
      });
  };
  const getPerametersFromURL = () => {
    let isFeatured = false;
    var qd = {};
    window.location.href
      .substr(1)
      .split("&")
      .forEach(function (item) {
        var k = item.split("=")[0],
          v = decodeURIComponent(item.split("=")[1]);
        k in qd ? qd[k].push(v) : (qd[k] = [v]);
      });

    const URL = new URLSearchParams(window.location.href.split("?")[1]);

    isFeatured =
      window.location.href.split("?")[1] == "featured=1" ? true : false;
    if (URL.get("search")) {
      setTempSelectedSearchKeywords(URL.get("search"));
      selectedSearchKeywords.current = URL.get("search");
    } else {
      setTempSelectedSearchKeywords("");
    }
    const industriesFromURL = industries.split("&")[0].split(",");
    industries
      ? setSelectedIndustries(industriesFromURL)
      : setSelectedIndustries([]);
    industries
      ? (selectedIndustriesRef.current = industriesFromURL)
      : (selectedIndustriesRef.current = []);
    industries
      ? setToggledIndustriesId(industriesFromURL)
      : setToggledIndustriesId([]);
    window.location.href.includes("interest_id")
      ? setSelectedInterest(qd?.interest_id)
      : setSelectedInterest([]);
    URL.get("sortby") || URL.get("featured") || isFeatured
      ? setSelectedSort(URL.get("sortby") || "recommended")
      : setSelectedSort("");
    URL.get("end_price")
      ? setSelectedPriceRange([URL.get("start_price"), URL.get("end_price")])
      : setSelectedPriceRange([null, null]);
    URL.get("min_exp")
      ? setSelectedExperinceRange([URL.get("min_exp"), URL.get("max_exp")])
      : setSelectedExperinceRange([0, 20]);
    URL.get("sort_by_video") ? setVideoSelected(true) : setVideoSelected(false);
    URL.get("rating")
      ? setSelectedFilterByNoOfStars(Number(URL.get("rating").charAt(0)))
      : setSelectedFilterByNoOfStars(0);
    setReloadFlag(!reloadFlag);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/industry`)
      .then((response) => {
        setFilterByIndustryOptions(response.data);

        getPerametersFromURL();

        setToggledIndustriesId((current) => [
          ...current,
          location.state?.industryForToggle,
        ]);
      })
      .catch((e) => {
        console.log("Error occured in industry API");
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/interest?featured=1&limit=1000`
      )
      .then((response) => {
        setInterest(response.data);
      })
      .catch((e) => {
        console.log("error in interest api");
      });
  }, []);

  //#region Get available locations
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/getAvailableLocations`)
      .then((response) => {
        const options = response.data.locations.map((location) => ({
          value: location,
          label: location,
        }));
        setAvailableLocations(options);
        setFilteredLocations(options.slice(0, 5));
      })
      .catch((error) => {
        console.log("Error fetching available locations: ", error);
      });
  }, []);
  //#endregion

  //#region selecting location based on user input
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    updateURL(event.target.value);
    getMentors(event.target.value);
  };

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    const filteredOptions = availableLocations.filter((location) =>
      location.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLocations(filteredOptions.slice(0, 5));
  };

  const checkLocationItem = (arr, name) => {
    const { length } = arr;
    const id = length + 1;
    const found = arr.some((el) => el.label === name);
    if (!found) arr.push({ label: name, value: name });
    setFilteredLocations(arr);
  };

  //#endregion

  //#region styling for Location Dropdown
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: state.isFocused ? "2px solid #3498db" : "2px solid #e0e0e0",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        border: state.isFocused ? "2px solid #3498db" : "2px solid #e0e0e0",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3498db" : "#ffffff",
      color: state.isSelected ? "#ffffff" : "#333333",
      "&:hover": {
        backgroundColor: state.isSelected ? "#3498db" : "#f3f3f3",
        color: state.isSelected ? "#ffffff" : "#333333",
      },
    }),
  };

  //#endregion
  useLayoutEffect(() => {
    
    if (industries) {
      if (filterByIndustryOptions.length > 0) {
       getMentors();
        updateURL();
      }
    } else {
      updateURL();
      const URL = new URLSearchParams(window.location.href.split("?")[1]);
      if (URL.get("search")) {
       setTimeout(async () => {
         getMentors();
       }, 1000);
      } else {
       getMentors();
      }
    }
  }, [reloadFlag, selectedSearchKeywords.current]);
  const updateURL = (loc) => {
    // check that the industry promise has been resolved or not. then navigate to update url
    if (filterByIndustryOptions.length > 0) {
      let url = `/mentors/${
        selectedIndustries.length < 1 ? "" : selectedIndustries
      }?${selectedSort === "" ? "" : `&sortby=${selectedSort}`}${
        selectedInterest.length < 1
          ? ""
          : `&interest_id=${selectedInterest.toString()}`
      }${
        selectedFilterByNoOfStars > 0
          ? `&rating=${selectedFilterByNoOfStars}-and-above`
          : ""
      }${
        selectedExperinceRange[0] === 0 && selectedExperinceRange[1] === 20
          ? ""
          : `&min_exp=${selectedExperinceRange[0]}&max_exp=${selectedExperinceRange[1]}`
      }${
        selectedPriceRange[0] === null && selectedPriceRange[1] === null
          ? ""
          : `&end_price=${selectedPriceRange[1]}&start_price=${selectedPriceRange[0]}`
      }${isVideoSeleted ? "&sort_by_video=true" : ""}${
        selectedSearchKeywords.current === ""
          ? ""
          : `&search=${selectedSearchKeywords.current}`
      }`;

      if (loc) {
        url += `&location=${loc}`;
      } else if (selectedLocation) {
        url += `&location=${selectedLocation}`;
      }

      navigate(url);
    }
  };

  const [showFiltersInMobile, setShowFilterInMobile] = useState(false);

  // Years of experiece slider's functions
  const valueLabelFormat = (label) => {
    let labelToShow = label;
    if (labelToShow > 19) {
      labelToShow = "20+";
    } else if (labelToShow < 2) {
      labelToShow = "< 2";
    }
    return labelToShow;
  };

  const minDistance = 2; // min value of maximum experience
  // we can simply set the value but we need to control the minimum value of maximum experience to 4 so that it will not be less then 4.
  // Documentation : https://mui.com/material-ui/react-slider/#minimum-distance
  const handleChangeForSlider = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setTempSelectedExperinceRange([
        Math.min(newValue[0], tempSelectedExperinceRange[1] - minDistance),
        tempSelectedExperinceRange[1],
      ]);
    } else {
      setTempSelectedExperinceRange([
        tempSelectedExperinceRange[0],
        Math.max(newValue[1], tempSelectedExperinceRange[0] + minDistance),
      ]);
    }
  };

  const handleIndustry = (id, name) => {
    if (selectedInterest.includes(id)) {
      // Remove ID
      const selectedIndustryCopy = [...selectedInterest];
      const itemIndex = selectedIndustryCopy.indexOf(id);
      selectedIndustryCopy.splice(itemIndex, 1);
      setSelectedInterest(selectedIndustryCopy);
      setReloadFlag(!reloadFlag);
    } else {
      // Add ID
      // selectedIndustry.push(id)
      setSelectedInterest([...selectedInterest, id]);
      setReloadFlag(!reloadFlag);
    }
  };

  return (
    <div>
      <div className="md:container mx-auto my-3 md:my-6 px-2 xl:px-16 flex sm:flex-col md:flex-row  justify-center ">
        {/* Button for the opening filters in mobile - start */}
        <div className="flex justify-around md:hidden">
          {!showFiltersInMobile ? (
            <div
              className="mb-2  flex items-center"
              onClick={() => {
                setShowFilterInMobile(!showFiltersInMobile);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="gray"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
              <p className="text-textFullDark font-bold ml-2">Filters</p>
            </div>
          ) : (
            <div
              className="mb-2  flex items-center justify-center"
              onClick={() => {
                setShowFilterInMobile(!showFiltersInMobile);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="gray"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <p className="text-textFullDark font-bold ml-2">close</p>
            </div>
          )}
        </div>
        {/* Button for the opening filters in mobile - end */}

        {/* left side - start */}
        <div
          className={`mx-2 md:flex flex-col relative z-0 ${
            showFiltersInMobile ? " flex" : " hidden"
          } `}
        >
          {/* search */}
          <div className="flex flex-row items-center border border-borderGray bg-white h-10 px-2 rounded-lg text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-2 h-5"
              viewBox="0 0 20 20"
              fill="gray"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                selectedSearchKeywords.current = tempSelectedSearchKeywords;
                setShowFilterInMobile(!showFiltersInMobile);
                setReloadFlag(!reloadFlag);
              }}
            >
              <input
                value={tempSelectedSearchKeywords}
                onChange={(e) => setTempSelectedSearchKeywords(e.target.value)}
                placeholder="Search"
                className="focus:outline-none"
              />
            </form>
          </div>
          {/* clear filters button - start */}
          <div className="mt-5">
            <Button
              className={`w-full flex items-center  text-bgAppColor btn_font_family`}
              variant="text"
              onClick={clearAllFilters}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="gray"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              }
            >
              Clear Filters
            </Button>
          </div>
          {/* clear filters button - end */}
          {isLoading ? (
            <div class="absolute inset-0 flex justify-center items-center z-10"></div>
          ) : null}
          <div>
            {/* sort by industry - start */}
            <div className="block text-gray-800 my-4 ">
              <h1 className="text-black text-lg font-bold">Industry</h1>
              {filterByIndustryOptions.map((industry, index) => {
                return (
                  <div className="flex flex-col" key={index}>
                    <div className="flex items-center">
                      <label className="inputElement">
                        {industry.name}
                        <input
                          type="checkbox"
                          checked={selectedIndustries.includes(
                            `${industry.slug}`
                          )}
                          value={industry.slug}
                          onChange={(e) => {
                            selectedIndustriesChangeHandler(e);
                            if (e.target.checked) {
                              toggleSubIndustries(industry.slug);
                              setToggledIndustriesId([
                                ...toggledIndustriesId,
                                industry.slug,
                              ]);
                            }
                            setReloadFlag(!reloadFlag);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* sub industry - start */}
                      {industry.sub.length > 0 ? (
                        <div>
                          {toggledIndustriesId.includes(industry.slug) ? (
                            <span
                              onClick={() => toggleSubIndustries(industry.slug)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-2 h-4 w-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 15l7-7 7 7"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              onClick={() => toggleSubIndustries(industry.slug)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-2 h-4 w-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      ) : null}
                    </div>

                    <div className="flex flex-col">
                      {(toggledIndustriesId.includes(industry.slug) ||
                        industry?.sub?.find((o, i) => {
                          if (toggledIndustriesId.includes(o.slug)) {
                            return true; // stop searching
                          }
                          return false;
                        })) &&
                        industry?.sub?.map((subIndustry, index) => {
                          return (
                            <span
                              key={index}
                              className="inline-flex items-center text-2 ml-6"
                            >
                              <label className="inputElement">
                                {subIndustry.name}
                                <input
                                  type="checkbox"
                                  value={subIndustry.slug}
                                  checked={selectedIndustries.includes(
                                    `${subIndustry.slug}`
                                  )}
                                  onChange={(e) => {
                                    selectedIndustriesChangeHandler(e);
                                    setReloadFlag(!reloadFlag);
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </span>
                          );
                        })}
                    </div>
                    {/* sub industry - end */}
                  </div>
                );
              })}
            </div>
            <hr />
            {/* sort by industry - end */}

            {/* sort by interest - start */}
            <div className="block text-gray-800 my-4 ">
              <h1 className="text-black text-lg font-bold">Specialty</h1>
              {interest.map((m, i) => {
                return (
                  <>
                    {" "}
                    <button
                      key={i}
                      className={`flex items-center m-1 cp-interest-filter-btn`}
                      style={{
                        backgroundColor: selectedInterest.includes(m.id)
                          ? Colors.bgBlue
                          : Colors.bgGray,
                        color: selectedInterest.includes(m.id)
                          ? "#fff"
                          : "#000",
                        borderColor: selectedInterest.includes(m.id)
                          ? Colors.bgBlue
                          : Colors.bgGray,
                      }}
                      onClick={() => {
                        handleIndustry(m.id, m.name);
                      }}
                    >
                      {m.name}
                    </button>
                  </>
                );
              })}
            </div>
            <hr />
            {/* sort by interest - end */}

            {/* Sort By Locations - start */}
            <div className="block text-gray-800 my-4">
              <h1 className="text-black text-lg font-bold">Location</h1>
              <Autocomplete
                id="Location"
                // sx={{ height: 10 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                getOptionLabel={(option) => option.label}
                options={options}
                defaultValue={selectedLocation}
                freeSolo={true}
                disableClearable
                onChange={(e, obj) => {
                  checkLocationItem(filteredLocations, obj.label);
                  setSelectedLocation(obj.label);
                  updateURL(obj.label);
                  getMentors(obj.label);
                }}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    type="text"
                    placeholder="Search Locations..."
                    value={selectedLocation}
                    defaultValue={selectedLocation}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              {/* <input
                type="text"
                placeholder="Search locations"
                onChange={handleSearchInputChange}
              /> */}
              {filteredLocations.map((option) => (
                <label className="radioElement" key={option.value}>
                  <input
                    type="radio"
                    name="location"
                    value={option.value}
                    checked={selectedLocation === option.value}
                    onChange={handleLocationChange}
                  />
                  {option.label}
                  <span className="radioCustom"></span>
                </label>
              ))}
            </div>

            <hr />
            {/* Sort By Locations - end */}

            {/* Sort By Filters - start */}
            <div className="block text-textFullDark my-4 ">
              <h1 className="text-black text-lg font-bold">Sort By</h1>
              {sortingOptions.map((sortingOption, index) => {
                return (
                  <div key={index}>
                    <label className="radioElement">
                      {sortingOption.label}
                      <input
                        type="radio"
                        value={sortingOption.value}
                        onChange={(event) => {
                          setSelectedSort(event.target.value);
                          setReloadFlag(!reloadFlag);
                        }}
                        checked={selectedSort === sortingOption.value}
                      />
                      <span className="radioCustom"></span>
                    </label>
                  </div>
                );
              })}
            </div>
            <hr />
            {/* Sort By Filters - end */}

            {/* sort by years of experience - start */}
            <div className="my-4">
              <label className="text-black text-lg font-bold">
                Years of Experience
              </label>
              <div className="pl-3.5 pr-5">
                <Slider
                  value={tempSelectedExperinceRange}
                  min={0}
                  step={2}
                  max={20}
                  // values={[2,20]}
                  defaultValue={[2, 20]}
                  // key={`slider-${[2,20]}`}
                  onChange={handleChangeForSlider}
                  onChangeCommitted={(_, values) => {
                    setSelectedExperinceRange(tempSelectedExperinceRange);
                    setReloadFlag(!reloadFlag);
                  }}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  disableSwap
                />
              </div>

              <div className="flex justify-between text-textFullDark items-center">
                <span>
                  {selectedExperinceRange[0] >= 2 ? (
                    selectedExperinceRange[0]
                  ) : (
                    <span className="flex items-center justify-center">
                      <p className="mb-0.5 text-lg ">{"<"}</p>
                      <p>{"2"}</p>
                    </span>
                  )}
                </span>

                <span>
                  {selectedExperinceRange[1] < 20 ? (
                    selectedExperinceRange[1]
                  ) : (
                    <span className="flex items-center justify-center">
                      <p>{"20"}</p>
                      <p className="mb-0.5 text-lg">{"+"}</p>
                    </span>
                  )}
                </span>
              </div>
            </div>
            <hr />
            {/* sort by years of experience - end */}

            {/* sort by no of stars - start */}
            <div className="my-4">
              <label className="text-black text-lg font-bold">
                Average reviews rating
              </label>
              {filterByNoOfStars.map((noOfStars, index) => (
                <span key={index}>
                  <label className="radioElement">
                    {/* {noOfStars} stars & up */}
                    <p className="text-textFullDark"> {noOfStars} stars & up</p>
                    <input
                      type="radio"
                      value={noOfStars}
                      onChange={(event) => {
                        setSelectedFilterByNoOfStars(noOfStars);
                        setReloadFlag(!reloadFlag);
                      }}
                      checked={selectedFilterByNoOfStars === noOfStars}
                    />
                    <span className="radioCustom"></span>
                  </label>
                </span>
              ))}
            </div>
            {/* sort by no of stars - end */}

            {/* Sort By Prices range - start */}
            <div className="block text-textFullDark my-4 ">
              <span className="text-black text-lg font-bold">Price</span>
              {filterByPriceOptions.map((priceOption, index) => {
                return (
                  <div key={index}>
                    <label className="radioElement">
                      {priceOption.label}
                      <input
                        type="radio"
                        value={priceOption.value}
                        onChange={(event) => {
                          setSelectedPriceRange(event.target.value.split(","));
                          setReloadFlag(!reloadFlag);
                        }}
                        checked={selectedPriceRange[0] === priceOption.value[0]}
                      />
                      <span className="radioCustom"></span>
                    </label>
                  </div>
                );
              })}
            </div>
            <hr />
            {/* Sort By Prices range - end */}
          </div>

          {/* clear filters button - start */}
          {/* <div className="mt-5">
            <Button
              className={`w-full flex items-center  text-bgAppColor`}
              variant="text"
              onClick={clearAllFilters}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="gray"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              }
            >
              Clear Filters
            </Button>
          </div> */}
          {/* clear filters button - end */}
        </div>
        {/* left side - end */}

        {/* right side - start */}
        <div className="w-full min-h-screen">
          <div className="flex">
            <EFCBanner />
          </div>
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <Loader
                type="TailSpin"
                color={Colors.blue}
                height={200}
                width={200}
              />
            </div>
          ) : (
            <div>
              <FilterUsers Featured={mentors} />
            </div>
          )}
        </div>
        {/* right side - end */}
      </div>
    </div>
  );
}

export default Index;

const topFilms = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
];
