import React, { useEffect, useRef, useState } from "react";
import FormikRadio from "../../FormikRadio/FormikRadio";
import { useNavigate } from "react-router-dom";
import Ratings from "../../Rating/Ratings";
import MuiRating from "@mui/material/Rating";
import Education from "../Education/Education";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../../Arrows/LeftArrow";
import RightArrow from "../../Arrows/RightArrow";
import { pricetoDecimal } from "../../price";
import Colors from "../../Colors/Colors";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Mixpanel } from "../../Mixpanel";
import CharityBadgePopUp from "../CharityBadgePopUp/CharityBadgePopUp";
import { CompanyTitle } from "../../settings";
import axios from "axios";
import { ReviewAll } from "../Reviews/Reviews";
import { useParams } from "react-router-dom";
import { toggleCharity } from "../../Redux/reducers/charity/charitySlice";
import { toggleReviews } from "../../Redux/reducers/review/reviewSlice";
import Loader from "react-loader-spinner";
import "./profile.css";
import { setBannerTrue } from "../../Redux/reducers/banner/bannerSlice";
import {
  setLogin,
  setCustomerFalse,
} from "../../Redux/reducers/customer/customerSlice";
import { RedirectTo } from "../../Redux/reducers/redirect/redirectSlice";
import ProfileInterest from "../ProfileInterests/ProfileInterest";
import { navStateToZero } from "../../Redux/reducers/nav/navSlice";
const ProfileMainContent = (props) => {
  const { scrollToDiv } = props;

  const bio = props.profile.User?.bio;
  let params = useParams();
  let { id } = useParams();
  id = parseInt(id.split("-")[2], 10);
  const [settings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow:5,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    arrows: false,
  });
  let dispatch = useDispatch();
  let charity = useSelector((state) => state.charity.isCharity);
  const show = useSelector((state) => state.review.showReviews);
  let showAnonymousInfo = useSelector(
    (state) => state.anonymous.showAnonymousInfo
  );

  const [play, setPlay] = useState(false);
  const [mute, setMute] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [choice, setChoice] = useState("call_for_15_min");

  const Ref = useRef(null);
  const [userInterests, setUserInterest] = useState([]);

  const toggleLogin = () => {
    Mixpanel.track("Press login button web");
    // dispatch({ type: 'setLogin' })
    dispatch(setLogin());
    dispatch(RedirectTo({ url: window.location.pathname }));
    // dispatch({ type: 'setBannerToTrue' })
    dispatch(setBannerTrue());
    // setLogin(!login);
  };

  const getAllSelectedInterests = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/get-user-interes/${id}`)
      .then((response) => {
        const { Interests } = response.data;
        if (Interests.length > 0) {
          setUserInterest(Interests);
        }
      })
      .catch((e) => {
        console.log("error in industry api");
      });
  };

  const getAllIndustries = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/industry`)
      .then((response) => {
        setIndustry(response.data);
      })
      .catch((e) => {
        console.log("error in industry api");
      });
  };
  const getIndustrySelected = (dataArray) => {
    dataArray.length > 0 &&
      dataArray.forEach((m) => {
        if (selectedIndustry.includes(m.id)) {
          // Remove ID
          const selectedIndustryCopy = [...selectedIndustry];
          const itemIndex = selectedIndustryCopy.indexOf(m.id);
          selectedIndustryCopy.splice(itemIndex, 1);
          setSelectedIndustry(selectedIndustryCopy);
        } else {
          // ADD ID
          setSelectedIndustry((selectedIndustry) => [
            ...selectedIndustry,
            m.id,
          ]);
        }
        m.sub &&
          m.sub.length > 0 &&
          m.sub.forEach((m) => {
            if (selectedIndustry.includes(m.id)) {
              // Remove ID
              const selectedIndustryCopy = [...selectedIndustry];
              const itemIndex = selectedIndustryCopy.indexOf(m.id);
              selectedIndustryCopy.splice(itemIndex, 1);
              setSelectedIndustry(selectedIndustryCopy);
            } else {
              // ADD ID
              setSelectedIndustry((selectedIndustry) => [
                ...selectedIndustry,
                m.id,
              ]);
            }
          });
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/user_reviews/${id}`)
      .then((response) => {
        setReviews(response.data);
      })
      .catch((e) => {
        console.log("Error in Reviews Api", e);
      });
    ReactTooltip.rebuild();
  }, [props.profile]);
  const toggleCharity1 = () => {
    // dispatch({ type: 'toggleCharity' })
    dispatch(toggleCharity());
  };
  const toggleAnonymous = () => {
    dispatch({ type: "toggleAnonymousInfo" });
  };

  const SlickNext = () => {
    Ref.current.slickNext();
  };
  const SlickPrev = () => {
    Ref.current.slickPrev();
  };
  const responseTime = () => {
    const duration = moment.duration(props.profile.response_time, "minutes");
    let responseTime = "";
    for (const [unit, value] of Object.entries(duration._data)) {
      if (value > 1) responseTime = `${value} ${unit}`;
      else if (value > 0) responseTime = `${value} ${unit.slice(0, -1)}`;
    }
    return responseTime;
  };

  if (props.profile.User?.id) {
    Mixpanel.track(
      `web: open professional ${props.profile.User?.first_name} profile.`,
      {
        professional_id: props.profile.User?.id,
        professional_name: props.profile.User?.first_name,
      }
    );
  }

  const [latestCompany, setLatestCompany] = useState({});

  const getLatestCompany = (arr) => {
    if (arr.length > 0) {
      const data = arr.sort(function (a, b) {
        var keyA = new Date(a.start_date),
          keyB = new Date(b.start_date);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setLatestCompany(data[0]);
    } else {
      setLatestCompany(null);
    }
  };
  const toggleShow = () => {
    dispatch(toggleReviews());
    dispatch(navStateToZero());
  };

  const [isOnline, setIsOnline] = useState(0);
  const [isLoader, setLoader] = useState(true);
  const getProfessional = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${props.id}`)
      .then((response) => {
        if (response.data?.User?.is_online) {
          getData(response.data?.User?.id);
        } else {
          setLoader(false);
        }
        getIndustrySelected(response.data?.Industries);
        getLatestCompany(response.data.Experiences);
      });
  };

  const getData = (id1) => {
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH + `/is_professional_available/${id1}`
      )
      .then((response) => {
        if (response.data.message?.length > 0) {
          setIsOnline(true);
          setLoader(false);
        } else {
          setLoader(false);
          setIsOnline(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setLoader(false);
          // Request made and server responded
          console.log("error is=>", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("error is=>", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("error is=>", error.message);
        }
      });
  };

  useEffect(() => {
    getProfessional();
    getAllIndustries();
    getAllSelectedInterests();
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      {props.profile.User?.first_name && (
        <Helmet>
          <title>{`${
            props.profile.is_anonymous === 1
              ? "Anonymous"
              : `${props.profile.User?.first_name} ${props.profile.User?.last_name}`
          } | ${latestCompany?.job ? latestCompany?.job : ""}`}</title>
          <meta
            name="Mentor name and job title"
            content="Mentor name and job title"
          />
        </Helmet>
      )}
      <ReactTooltip />

      <div className="md:container mx-auto sm:px-2 lg:px-56 py-2 flex sm:flex-col md:flex-row justify-between mt-4">
        {show && (
          <ReviewAll
            toggle={toggleShow}
            overlay="overlay"
            modal="modal"
            modalcontent="modal-content"
            closeModal="close-modal"
            modalValue={show}
            reviews={reviews}
          />
        )}

        {isLoader ? (
          <div className="flex justify-center py-10 items-center">
            <Loader
              type="TailSpin"
              color={Colors.blue}
              height={100}
              width={100}
              // timeout={5000} //5 secs
            />
            {/* <div style={{ width: "740px", height: "550px" }} className="bg-gray-600 rounded-xl"></div> */}
          </div>
        ) : (
          <div className="sm:w-full md:w-2/5 md:mr-8">
            {props.profile.is_anonymous === 1 ? (
              <div>
                <div
                  className="sm:ml-0 ml-8 flex justify-center items-center border border-borderGray rounded-xl bg-gray-600"
                  style={{
                    backgroundImage: "url(/anonymousbg.png)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "500px",
                  }}
                >
                  <img
                    src={
                      latestCompany?.profile_photo
                        ? latestCompany?.profile_photo
                        : "/anonymousavatar.png"
                    }
                    alt="photoss"
                    className="w-48 h-48 rounded-full object-cover"
                  />
                </div>
              </div>
            ) : (
              <div>
                {props.profile.prof_intro_link ? (
                  <div>
                    <Slider {...settings} ref={Ref}>
                      <div style={{ height: "500px" }}>
                        {props.profile.prof_intro_link ? (
                          <div>
                            <div
                              className="absolute m-5 z-10 bottom-0 bg-white rounded-full p-1"
                              onClick={() => setMute(!mute)}
                            >
                              {mute ? (
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                            <Video
                              link={props.profile.prof_intro_link}
                              mute={mute}
                              play={play}
                              setPlay={setPlay}
                            />
                          </div>
                        ) : (
                          <img
                            src={
                              props.profile.User?.profile_photo
                                ? props.profile.User?.profile_photo
                                : " /avatar.png"
                            }
                            alt="photoss"
                            className="border border-borderGray rounded-xl mr-6 w-full object-cover"
                            style={{ height: "500px" }}
                          />
                        )}
                      </div>
                      <div className="ml-8" style={{ height: "500px" }}>
                        <img
                          src={
                            props.profile.User?.profile_photo
                              ? props.profile.User?.profile_photo
                              : " /avatar.png"
                          }
                          alt="photoss"
                          className="border border-borderGray rounded-xl mr-6 w-full object-cover"
                          style={{ height: "500px" }}
                        />
                      </div>
                    </Slider>
                  </div>
                ) : (
                  <div className="sm:ml-0 ml-8">
                    <img
                      src={
                        props.profile.User?.profile_photo
                          ? props.profile.User?.profile_photo
                          : " /avatar.png"
                      }
                      alt="photoss"
                      className="border border-borderGray rounded-xl mr-6 w-full object-cover"
                      style={{ height: "500px" }}
                    />
                  </div>
                )}
              </div>
            )}
            {props.profile.prof_intro_link ? (
              <div className="w-full flex justify-end mt-4">
                {props.profile.is_anonymous === 1 ? null : (
                  <div className="text-md text-textDark flex flex-row items-center">
                    {/* <p>View All</p> */}
                    <LeftArrow click={SlickPrev} />
                    <RightArrow click={SlickNext} />
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex justify-end mt-4">
                <div className="text-md text-textDark flex items-center">
                  {/* <p>View All</p> */}
                  <p className="h-6"></p>
                </div>
              </div>
            )}
          </div>
        )}
        {/* {ConditionaforImageandVideo} */}
        <div className="sm:w-full md:w-3/5 sm:pt-2 md:pt-4">
          <div className="py-1">
            <div className="flex items-start">
              {charity && (
                <CharityBadgePopUp
                  toggle={toggleCharity1}
                  overlay="overlay"
                  modal="modal"
                />
              )}
              {showAnonymousInfo && (
                <Anonymous
                  toggle={toggleAnonymous}
                  overlay="overlay"
                  modal="modal"
                />
              )}
              {props.profile.is_anonymous === 1 ? (
                <img
                  src="/anonymous.png"
                  alt="canonymous"
                  className="w-6 mr-4 cursor-pointer"
                  onClick={toggleAnonymous}
                  data-tip="This profile is anonymous"
                />
              ) : null}
              {props.profile.is_charity === 1 ? (
                <img
                  src="/charity.svg"
                  alt="charity"
                  className="w-6 mr-4 cursor-pointer"
                  onClick={toggleCharity1}
                  data-tip="Mentor donates proportion of earnings to charity"
                />
              ) : null}
              <div className="flex flex-wrap">
                {industry.map((m) => {
                  if (selectedIndustry.includes(m.id)) {
                    return (
                      <>
                        <h1
                          className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                          style={{
                            backgroundColor: Colors.bgBlue,
                            color: "white",
                            borderColor: Colors.bgBlue,
                          }}
                        >
                          {m.name}
                        </h1>
                        {m.sub.length > 0 &&
                          m.sub.map((s) => {
                            if (selectedIndustry.includes(s.id)) {
                              return (
                                <h1
                                  className={`flex items-center border border-borderGray rounded-full m-1 py-1 px-3 text-sm`}
                                  style={{
                                    backgroundColor: Colors.bgDarkBlue,
                                    color: "#383838",
                                    borderColor: Colors.bgDarkBlue,
                                  }}
                                >
                                  {s.name}
                                </h1>
                              );
                            }
                          })}
                      </>
                    );
                  }
                })}
              </div>
            </div>
            {props.profile.is_anonymous === 1 ? (
              <div className="text-2xl font-bold text-black my-2 truncate">
                {latestCompany !== null ? (
                  <p>
                    {latestCompany?.job} at {latestCompany?.company}
                  </p>
                ) : (
                  "No experience"
                )}
              </div>
            ) : (
              <div className="text-2xl font-bold text-black my-2 truncate">
                {props.profile.User?.first_name} {props.profile.User?.last_name}
              </div>
            )}
            {props.profile.is_anonymous !== 1 && (
              <div className="text-sm text-textFullDark">
                {latestCompany?.job}
              </div>
            )}
            <div className="py-4">
              {localStorage.getItem("cp_web_token") && bio ? (
                bio
                  .split("\n")
                  .map((paragraph, index) => (
                    <p
                      className={`text-xs text-justify text-textDark sm:w-full prose lg:prose-xl pt-1`}
                    >
                      {paragraph
                        ?.split("\n")
                        .reduce((total, line) => [total, <br />, line])}
                    </p>
                  ))
              ) : (
                <p
                  className={`text-xs text-justify text-textDark sm:w-full prose truncate lg:prose-xl pt-1`}
                >
                  {bio?.split("\n")[0]}
                </p>
              )}
            </div>
            <div className={`flex items-center`}>
              <div className="flex flex-col">
                <div
                  className={`flex items-center ${
                    localStorage.getItem("cp_web_token") ? "" : "blur-content"
                  }`}
                  onClick={scrollToDiv}
                >
                  {props.profile.avgRating > 0 ? (
                    <div className="cursor-pointer">
                      <MuiRating
                        name="profile-main-rating"
                        defaultValue={props.profile.avgRating}
                        precision={0.1}
                        readOnly
                      />
                    </div>
                  ) : (
                    <Ratings value={0} />
                  )}
                  &nbsp;&nbsp;
                  {props.profile.avgRating && (
                    <p className="text-textDark">
                      {/* {getDecimalsWithoutRounding(props.profile.avgRating, 1)} */}
                      {(+props.profile.avgRating).toFixed(1)}
                    </p>
                  )}
                </div>
                {props.profile.reviewCount > 0 ? (
                  <div
                    id="reviews"
                    className={`cursor-pointer ${
                      localStorage.getItem("cp_web_token") ? "" : "blur-content"
                    }`}
                    onClick={scrollToDiv}
                  >
                    {props.profile.reviewCount && (
                      <p className="text-xs text-textDark">
                        {props.profile.reviewCount} reviews
                      </p>
                    )}
                  </div>
                ) : (
                  <p
                    className={`text-xs text-textDark cursor-pointer ${
                      localStorage.getItem("cp_web_token") ? "" : "blur-content"
                    }`}
                    id="reviews"
                    onClick={toggleShow}
                  >
                    {0} reviews
                  </p>
                )}
              </div>
              <div
                className={`flex flex-col ml-4 ${
                  localStorage.getItem("cp_web_token") ? "" : "blur-content"
                }`}
                data-tip="Time it takes for mentor to respond to your meeting request"
              >
                <div className="flex flex-row items-center justify-center">
                  <div>
                    <img src="/Vector.png" alt="photos" className="w-2 h-3" />
                  </div>
                  <p className="text-textDark">
                    &nbsp;{responseTime() ? responseTime() : 0}
                  </p>
                </div>
                <p className="text-xs text-textDark">Response time</p>
              </div>
            </div>
            {isLoader ? (
              <div className="flex justify-center py-10 items-center">
                <Loader
                  type="TailSpin"
                  color={Colors.blue}
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <div>
                {isOnline ? (
                  <div
                    className={`${
                      localStorage.getItem("cp_web_token") ? "" : "blur-content"
                    }`}
                  >
                    {!(localStorage.getItem("id") == props.id) && (
                      <FormikRadio
                        id={props.id}
                        setChoice={setChoice}
                        sessionPrice15={props.profile.session_price_15_min}
                        sessionPrice30={props.profile.session_price_30_min}
                      />
                    )}
                  </div>
                ) : (
                  localStorage.getItem("cp_web_token") && (
                    <div className="w-5/6 max-w-full text-center px-4 mt-5 py-3 bg-white rounded-md shadow-md dark:bg-gray-800">
                      <div>
                        <h1 className="mt-2 text-lg font-semibold text-gray-800 dark:text-white">
                          Mentor currently unavailable
                        </h1>
                        {/* <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                        Please find another mentor
                      </p> */}
                        <div className="flex items-center justify-between gap-4 w-full mt-4">
                          <button
                            type="button"
                            className="py-2 px-4  bg-bgAppColor text-white w-full text-center text-base font-semibold shadow-md  rounded-lg "
                            onClick={() => navigate("/")}
                          >
                            Find another mentor
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          {localStorage.getItem("cp_web_token") ? null : (
            <div className="flex items-center justify-between gap-4 w-full py-1">
              <button
                type="button"
                className="py-2 px-4  bg-bgAppColor text-white w-full text-center text-base font-semibold shadow-md  rounded-lg "
                onClick={toggleLogin}
              >
                <div className="flex items-center justify-center font-bold">
                  <p className="mr-2">Book session</p>
                  {choice === "call_for_15_min"
                    ? pricetoDecimal(props.profile.session_price_15_min)
                    : pricetoDecimal(props.profile.session_price_30_min)}
                </div>
                {/* Login */}
              </button>
            </div>
            // <div className="w-80 h-28 ml-24 2xl:ml-36 text-center px-4 mt-3 top-5 py-3 rounded-md  dark:bg-gray-800 shadow-2xl bg-white">
            //   <div>
            //     <h1 className="mt-2 text-lg font-semibold text-gray-800 dark:text-white">
            //     No Login
            //   </h1>
            //     <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
            //     Login to view more information mentor
            //   </p>

            //   </div>
            // </div>
            // <button
            //   className={`text-center text-lg p-4 w-full bg-bgAppColor mt-3 rounded-lg text-white hover:text-2xl" type="submit ${
            //     localStorage.getItem("cp_web_token") ? "" : "hidden"
            //   }`}
            //   onClick={toggleLogin}
            // >
            //   <div className="flex items-center justify-center font-bold">
            //     <p className="mr-2">Book session</p>
            //     {choice === "call_for_15_min"
            //       ? pricetoDecimal(props.sessionPrice15)
            //       : pricetoDecimal(props.sessionPrice30)}
            //   </div>
            // </button>
          )}
        </div>
      </div>
      {/* <div className={`${localStorage.getItem("cp_web_token") ? "" : "blur-content"}`}> */}
      <div
        className={`md:container mx-auto sm:px-2 lg:px-56 md:py-6 flex sm:flex-col md:flex-row justify-between mt-4 h-fit`}
      >
        <div
          className={`md:mr-10 ${
            localStorage.getItem("cp_web_token") ? "bg-bgGray" : "blur-content"
          } p-5  sm:w-full lg:w-3/4 rounded-lg h-fit`}
          style={{ height: "fit-content" }}
        >
          {userInterests.length > 0 && (
            <>
              <h1 className="text-3xl font-bold my-4 ml-2 text-textFullDark">
                Specialty
              </h1>
              <ProfileInterest id={id} />
            </>
          )}
          {props.experience.length > 0 && (
            <>
              <hr className="mt-4" />
              <h1 className="text-3xl font-bold my-4 ml-2 text-textFullDark">
                Experience
              </h1>
              <>
                {props.experience.map((m, i) => {
                  return (
                    <Experience
                      key={i}
                      companyImage={m.Company?.profile_photo}
                      position={m.job}
                      companyName={m.Company?.name}
                      startDate={m.start_date}
                      endDate={m.end_date}
                      location={m.location}
                      description={m.description}
                      currentJob={m.current_job}
                    />
                  );
                })}
              </>
            </>
          )}
        </div>
        {props.education.length > 0 && (
          <div
            className={`${
              localStorage.getItem("cp_web_token")
                ? "bg-bgGray"
                : "blur-content"
            } p-4 sm:mt-4 md:mt-0 sm:w-full lg:w-2/5 rounded-lg h-fit`}
            style={{ height: "fit-content" }}
          >
            <h1 className="text-3xl font-bold my-4 ml-2 text-textFullDark h-100">
              Education
            </h1>
            {props.education.map((m) => {
              return (
                <Education
                  universityImage={m.profile_photo}
                  education_type={m.field_of_study}
                  universityName={m.school}
                  endDate={m.end_date}
                  startDate={m.start_date}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
    // </div>
  );
};

export default ProfileMainContent;

const Experience = (props) => {
  const calcDate = (date1, date2) => {
    let diff = Math.floor(date1.getTime() - date2.getTime()) * -1;
    let day = 1000 * 60 * 60 * 24;
    let days = Math.floor(diff / day);
    let months = Math.floor(days / 31);
    let years = Math.floor(months / 12);
    let rem = months % 12;
    let message = years + " yrs ";
    message += rem + " mos ";
    return message;
  };

  return (
    <div className="py-2">
      <div className="flex flex-row ">
        <div>
          <div className="bg-bgAppColor sm:w-12 sm:h-12 md:w-16 md:h-16 rounded-full flex items-center justify-center">
            <img
              src={
                props.companyImage
                  ? props.companyImage
                  : "/experienceplaceholder.png"
              }
              alt=""
              className="sm:w-12 sm:h-12 md:w-16 md:h-16 rounded-full object-cover bg-blue-400"
            />
          </div>
        </div>
        <div className="flex flex-col ml-5">
          <h1 className="text-lg text-textFullDark font-bold">
            {props.position}
          </h1>
          <p className="text-sm text-textDark">
            {props.companyName} - {props.location}
          </p>
          <div className="flex items-center">
            <p className="text-sm text-textDark">
              {" "}
              {`${moment(props.startDate).format("MMM YYYY")} - `}
            </p>
            {props.currentJob === "1" ? (
              <p className="text-sm text-textDark">&nbsp;Present</p>
            ) : (
              <p className="text-sm text-textDark">
                &nbsp;{`${moment(props.endDate).format("MMM YYYY")}`}
              </p>
            )}
            <p className="text-textDark">&nbsp;•&nbsp;</p>
            <p className="text-sm text-textDark">
              {" "}
              {props.currentJob === "1"
                ? calcDate(new Date(props.startDate), new Date())
                : calcDate(new Date(props.startDate), new Date(props.endDate))}
            </p>
          </div>

          <div className="overflow-ellipsis mt-2">
            <p className="text-xs text-textFullDark">{props.description}</p>
          </div>
        </div>
      </div>
      <hr className="mt-4" />
    </div>
  );
};

const Video = (props) => {
  const videoRef = useRef(null);
  const [play, setPlay] = useState(window.innerWidth < 768 ? false : true);

  useEffect(() => {
    playVideo();
  }, []);
  const playVideo = () => {
    if (play) {
      videoRef.current.play();
      setPlay(false);
    } else {
      videoRef.current.pause();
      setPlay(true);
    }
  };

  return (
    <div style={{ height: "500px" }}>
      {/* <button className='absolute bg-white bottom-8 left-80' >sese</button> */}
      <div
        className="absolute bg-white m-4 z-10 rounded-full p-1"
        onClick={playVideo}
      >
        {!play ? (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        ) : (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        )}
      </div>

      <video
        ref={videoRef}
        className="w-full object-cover rounded-xl border"
        muted={props.mute}
        // controls={false}
        // autoPlay={true}

        onEnded={() => setPlay(true)}
        style={{ height: "500px" }}
        key={props.link}
      >
        <source src={`${props.link}#t=0.001`} />
      </video>
    </div>
  );
};

const Anonymous = (props) => {
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>
      <div className="flex flex-col items-center justify-center w-11/12 md:w-1/3 z-20 bg-white rounded-xl p-8">
        <div className="flex flex-col items-center justify-center">
          <img
            src="/anonymous.png"
            className="w-20 h-20 rounded-full object-cover mb-8"
            alt=""
          />
          <p className="text-3xl text-center font-bold">
            Mentor has chosen to remian anonymous
          </p>
          <p className="text-sm text-center my-3">
            This profile is verified by {CompanyTitle}
          </p>
        </div>
        <button
          className="w-full flex justify-center items-center p-2 bg-bgAppColor text-white rounded font-bold"
          onClick={props.toggle}
        >
          Go back to profile
        </button>
      </div>
    </div>
  );
};
