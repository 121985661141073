import React, { useState } from "react";
import Colors from "../Colors/Colors";
import axios from "axios";
import { Formik, Form } from "formik";
import TextField from "./TextField";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";
import { Mixpanel } from "../Mixpanel";
import { update_profile_step } from "./StepUpdated";
import { CompanyTitle } from "../settings";

const AddFullName = (props) => {
  localStorage.setItem("navState", 1);
  const [codeError, setCodeError] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false); //Show Error for Using Existing Email
  const [isLoading, setLoading] = useState(false);
  const [passwordshown, setpasswordshown] = useState(false);
  const [confirmpasswordshown, setConfirmpasswordshown] = useState(false);
  const validate = Yup.object({
    first_name: Yup.string()
      .max(
        20,
        <p className="text-red-500 -mt-4">Must be 20 characters or less</p>
      )
      .required(<p className="text-red-500 -mt-4">Required</p>),
    invite_code: Yup.string().required(<p className=""></p>),

    last_name: Yup.string()
      .max(
        20,
        <p className="text-red-500 -mt-4">Must be 20 characters or less</p>
      )
      .required(<p className="text-red-500 -mt-4">Required</p>),

    email: Yup.string()
      .email(<p className="text-red-500 -mt-4">Please enter valid email</p>)
      .required(<p className="text-red-500 -mt-4">Email is required</p>),

    password: Yup.string()
      .min(
        6,
        <p className="text-red-500 -mt-4">Must be at least 6 characters</p>
      )
      .required(<p className="text-red-500 -mt-4">Password is required</p>)
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "Password must be atleast 8 characters, having one uppercase, one lowercase, and one number"
      ),

    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        <p className="text-red-500 -mt-4">
          Password and confirm password must be same
        </p>
      )
      .required(
        <p className="text-red-500 -mt-4">Confirm password is required</p>
      ),
    phone: Yup.string().required(
      <p className="text-red-500 -mt-4">Please enter Phonenumber </p>
    ),
  });

   // this function will call when email input is focus and then unfocus. we check the the value if it is valid email then call the API 
   const handleInputBlur = event => {
    var regularExpression = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    let valid = regularExpression.test(String(event.target.value).toLowerCase());
    if(valid){
        axios.post(`${process.env.REACT_APP_SERVER_PATH}/create-abandon-event`, {
            type: 'signup',
            email: event.target.value,
        })
        .catch((err) => {
          console.log("err in create-abandon-event", err);
        });       
    }else{
    }    
};

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        type: "",
        status: "",
        invite_code: "",
      }}
      validationSchema={validate}
      //New Sign Up
      onSubmit={(values) => {
        if (checked) {
          setCodeError(false);
          // /validate_reference_code/:code
          if (values.invite_code) {
            axios
              .get(
                process.env.REACT_APP_SERVER_PATH +
                  `/invite_code/${values.invite_code}/validate`
              )
              .then((response) => {
                // console.log(response);
                if (!response.data.status) {
                  setCodeError(true);
                }
                // console.log(response.data.status);
                values.type = "professional";
                if (response.data.status) {
                  setLoading(true);
                  axios
                    .post(process.env.REACT_APP_SERVER_PATH + `/signup`, values)
                    .then((response) => {
                      props.setNavState(props.navState + 1);
                      let email = response.data.message.email;
                      let cp_ref = localStorage.getItem("cp_ref");
                      Mixpanel.alias(email);
                      Mixpanel.track("Successful Signup on website", {
                        "User id": jwt_decode(response.data.message.token).id,
                        ref_cp: cp_ref,
                      });
                      Mixpanel.people.set({
                        cp_ref: cp_ref,
                      });
                      props.setToken("token", response.data.message.token);
                      localStorage.setItem(
                        "signup_token",
                        response.data.message.token
                      );

                      // console.log(jwt_decode(response.data.message.token).id);
                      props.setProfessionalId(
                        jwt_decode(response.data.message.token).id
                      );

                      update_profile_step(
                        response.data.message.token,
                        jwt_decode(response.data.message.token).id,
                        "1"
                      );
                    })
                    .catch((e) => {
                      setError(true);
                      console.log("error occured");
                    });
                }
              })
              .catch((e) => {
                console.log("error is happend");
              });
          } else {
            values.type = "professional";
            setLoading(true);
            axios
              .post(process.env.REACT_APP_SERVER_PATH + `/signup`, values)
              .then((response) => {
                props.setNavState(props.navState + 1);
                let email = response.data.message.email;
                let cp_ref = localStorage.getItem("cp_ref");
                Mixpanel.alias(email);
                Mixpanel.track("Successful Signup on website", {
                  "User id": jwt_decode(response.data.message.token).id,
                  ref_cp: cp_ref,
                });
                Mixpanel.people.set({
                  cp_ref: cp_ref,
                });
                props.setToken("token", response.data.message.token);
                localStorage.setItem(
                  "signup_token",
                  response.data.message.token
                );

                // console.log(jwt_decode(response.data.message.token).id);
                props.setProfessionalId(
                  jwt_decode(response.data.message.token).id
                );

                update_profile_step(
                  response.data.message.token,
                  jwt_decode(response.data.message.token).id,
                  "1"
                );

                localStorage.removeItem("cp_ref");
              })
              .catch((e) => {
                setError(true);
                console.log("error occured");
              });
          }
        }
      }}
    >
      {/* Using Formik for Form Submittion and Validation */}
      {(formik) => (
        <div className="flex flex-col justify-center items-center">
          <img src="/addName.png" alt="addName" className="w-48" />
          <p className="text-2xl font-bold mt-3 text-textFullDark">
            Let’s start with the basics
          </p>
          <p className="text-xs mt-3">
            Please enter your contact details and set up your password
          </p>
          <div className="w-full mt-4">
            <Form>
              <div className="flex">
                <TextField
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                />
                <div className="ml-2">
                  <TextField
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <TextField
                name="email"
                type="email"
                placeholder="Email"
                onBlur={handleInputBlur}
              />
              {error === true ? (
                <p className="text-red-500 -mt-4">Email already exists </p>
              ) : null}
              <TextField
                name="phone"
                type="phone"
                placeholder="Phone"
               
              />

              <TextField
                name="password"
                type={passwordshown ? "text" : "password"}
                placeholder="Password"
                passwordshown={passwordshown}
                setpasswordshown={setpasswordshown}
              />

              <TextField
                name="confirmPassword"
                type={confirmpasswordshown ? "text" : "password"}
                placeholder="Confirm password"
                confirmpasswordshown={confirmpasswordshown}
                setConfirmpasswordshown={setConfirmpasswordshown}
              />
              <TextField
                name="invite_code"
                type="text"
                placeholder="VIP CODE"
              />
              {codeError === true ? (
                <p className="text-red-500 -mt-4">Vip code is invalid </p>
              ) : null}
              <div className="w-full flex mb-4 mt-2 text-xs text-textDark">
                <label className="inline-flex items-center text-2">
                  <input
                    type="checkbox"
                    className="form-checkbox rounded"
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                  />
                  <span className="ml-2">Yes, I agree with {CompanyTitle}</span>
                </label>
                <a
                  className="text-textApp ml-1"
                  target="_blank"
                  rel="noreferrer"
                  href="https://careerpaths.io/terms-and-conditions"
                >
                  Terms &amp; Conditions
                </a>
              </div>
              <button
                type="submit"
                className="w-full flex items-center justify-center text-center py-3 rounded bg-bgAppColor text-white hover:bg-bgAppColors focus:outline-none my-1"
                style={{
                  backgroundColor:
                    checked &&
                    formik.values.invite_code !== "" &&
                    formik.values.first_name !== "" &&
                    formik.values.last_name !== "" &&
                    formik.values.email !== "" &&
                    formik.values.password !== "" &&
                    formik.values.confirmPassword !== ""
                      ? Colors.blue
                      : Colors.gray,
                  color:
                    checked &&
                    formik.values.invite_code !== "" &&
                    formik.values.first_name !== "" &&
                    formik.values.last_name !== "" &&
                    formik.values.email !== "" &&
                    formik.values.password !== "" &&
                    formik.values.confirmPassword !== ""
                      ? "white"
                      : "gray",
                }}
              >
                Next{" "}
                {isLoading ? (
                  <span className="inline-block ml-4">
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={30}
                      width={30}
                      // timeout={5000} //5 secs
                    />
                  </span>
                ) : null}{" "}
              </button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default AddFullName;
