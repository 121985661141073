import React, { useRef, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import Colors from "../../../../Colors/Colors";
import { useDispatch } from "react-redux";
import { toggleThankyouModal } from "../../../../Redux/reducers/charity/charitySlice";

const LearnMoreModal = (props) => {
  const [isDonate, setIsDonate] = useState(props.isDonate);

  const donations = [
    { id: 0, value: 2, label: "2%", isActive: false },
    { id: 1, value: 5, label: "5%", isActive: false },
    { id: 2, value: 10, label: "10%", isActive: false },
    { id: 3, value: 15, label: "15%", isActive: false },
  ];
  const [currentPercentAtive, setCurrentPercentAtive] = useState(0);
  const currentActiveValue = useRef(2);

  const dispatch = useDispatch();
  const onChangeValue = (event, id) => {
    setCurrentPercentAtive(id);
    currentActiveValue.current = event.target.value;
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    let id = localStorage.getItem("id");
  if(currentActiveValue.current > 0 && currentActiveValue.current <100){
    axios
      .post(process.env.REACT_APP_SERVER_PATH + `/professionals_charity`, {
        charity_id: "1",
        user_id: id,
        percent: currentActiveValue.current,
      })
      .then((response) => {
        dispatch(toggleThankyouModal());
      })
      .catch(function (error) {
        console.log("Error in posting charity", error);
      });
    }else{
      alert("Percentage should be between 0 and 100")
    }
    };

  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>

      <div className="flex flex-col items-center justify-center md:w-2/5 w-11/12  z-20 bg-gray-100 rounded-xl py-8">
        {!props.isLoading ? (
          <div className="w-full">
            <section className="text-gray-600 body-font">
              {isDonate ? (
                <section className="text-gray-600 body-font">
                  <div className="container px-5 mx-auto flex flex-col">
                    <div className="sm:col-span-8 ">
                      <div className="flex justify-end">
                        <button
                          onClick={props.toggle}
                          type="button"
                          className="text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                        >
                          <span className="sr-only">Close</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>

                      <section
                        aria-labelledby="options-heading"
                        className="mt-5"
                      >
                        <img
                          className="w-full p-5 object-cover object-center"
                          // src="https://dummyimage.com/720x400"
                          src="/charity/inspire.png"
                          alt="blog"
                        />
                        <form>
                          <div className="">
                            <div>
                              <h2 className="text-1xl font-bold text-gray-900 sm:pr-12">
                                Select Percentage
                              </h2>
                              <p className="leading-relaxed mb-3">
                                We’re delighted that you want to be a part of
                                our mission! We will provide you regular updates
                                of your total contribution to our charities.
                                Please select the proportion of your earnings
                                that you would like to donate to charity:{" "}
                              </p>
                            </div>
                            <div
                              // onChange={(e) => onChangeValue(e)}
                              className=" grid grid-cols-2 gap-4"
                            >
                              {donations.map((donation) => (
                                <label
                                  key={donation.id}
                                  className={`group ${
                                    donation.id === currentPercentAtive
                                      ? "ring ring-offset-1"
                                      : ""
                                  } relative border rounded-md py-6 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 bg-white shadow-sm text-gray-900 cursor-pointer`}
                                >
                                  <input
                                    type="radio"
                                    name="size-choice"
                                    value={donation.value}
                                    onChange={(e) =>
                                      onChangeValue(e, donation.id)
                                    }
                                    className={"sr-only"}
                                    aria-labelledby="size-choice-0-label"
                                  />
                                  <span>{donation.label}</span>
                                </label>
                              ))}
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="w-full">
                              <div className=" relative ">
                                <input
                                  onChange={(e) =>
                                    (currentActiveValue.current =
                                      e.target.value)
                                  }
                                  type="text"
                                  className=" rounded-lg text-center border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base"
                                  placeholder="Enter Percentage Manually"
                                />
                              </div>
                            </div>
                            <button
                              onClick={(e) => handleConfirm(e)}
                              className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-bgAppColor py-3 px-8 text-base font-medium text-white "
                            >
                              Confirm
                            </button>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </section>
              ) : (
                <div className="container px-5 mx-auto flex flex-col">
                  <div className=" w-full border-gray-200 ">
                    <div
                      className="flex items-center justify-between bg-white rounded-lg p-4 my-4 "
                      role="alert"
                    >
                      <div className="flex justify-around items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-patch-check-fill text-green-400"
                          viewBox="0 0 16 16"
                        >
                          {" "}
                          <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />{" "}
                        </svg>
                        <span className="font-bold text-lg mx-5">
                          Verified charity organization.{" "}
                        </span>
                      </div>
                      <h1 className="title-font text-xl font-extrabold text-green-600">
                        Inspire!
                      </h1>
                    </div>

                    <div className=" bg-white my-4 rounded-lg p-4">
                      <span className="font-bold text-lg">About inspire. </span>
                      <p className="leading-relaxed text-lg mb-4">
                        Inspire! is a registered charity established in 2004.
                        Our work is based in Hackney, Camden, Islington and
                        surrounding areas of London. We began as an Education
                        Business Partnership (EPB): an organisation which links
                        businesses like CareerPaths, trusts and foundations, and
                        our education partners to continue our mission.
                      </p>
                    </div>
                    <div className=" bg-white rounded-lg p-4">
                      <span className="font-bold text-lg">
                        Where Inspire spent money in 2017-18
                      </span>

                      {/* progress bar */}
                      <div className="w-full flex dark:bg-gray-700d rounded-lg ">
                        <div className="bg-green-600 w-3/6 text-xs font-medium text-blue-100 rounded-l-lg text-center p-0.5">
                          73%
                        </div>

                        <div className="bg-purple-600 w-1/6 text-xs font-medium text-blue-100 text-center p-0.5">
                          13%
                        </div>

                        <div className="bg-red-600 w-1/6 text-xs font-medium text-blue-100 text-center p-0.5">
                          12%
                        </div>
                        <div className="bg-bgAppColor w-1/6 text-xs font-medium text-blue-100 rounded-r-lg text-center p-0.5">
                          13%
                        </div>
                      </div>

                      <div className="">
                        <div className="flex my-3 items-baseline ">
                          <span className="bg-green-600 rounded-full w-3 h-3"></span>
                          <p className="mx-3">Running Programms</p>
                        </div>
                        <div className="flex my-3 items-baseline ">
                          <span className="bg-purple-600 rounded-full w-3 h-3"></span>
                          <p className="mx-3">
                            Leasing & maintaining school, office
                          </p>
                        </div>
                        <div className="flex my-3 items-baseline ">
                          <span className="bg-red-600 rounded-full w-3 h-3"></span>
                          <p className="mx-3">
                            Admin & governance to ensure our work is high
                            quality
                          </p>
                        </div>
                        <div className="flex my-3 items-baseline ">
                          <span className="bg-bgAppColor rounded-full w-3 h-3"></span>
                          <p className="mx-3">
                            Promoting our work & raising more funds
                          </p>
                        </div>
                      </div>

                      {/* end progress bar */}
                    </div>

                    <div className="flex justify-between ">
                      <button
                        onClick={props.toggle}
                        className={`py-4 flex items-center w-1/2 justify-center bg-red-600 text-white rounded mx-1 md:m-4`}
                      >
                        Close
                      </button>
                      <button
                        disabled={props.isCharityActive}
                        onClick={() => setIsDonate(true)}
                        className={`py-4 flex items-center justify-center ${props.isCharityActive ? "bg-gray-300 text-textDark cursor-not-allowed" : "bg-bgAppColor text-white" }   w-1/2 rounded mx-1 md:m-4`}
                      >
                        Donate
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center h-full">
            <Loader width={200} height={200} type="Oval" color={Colors.blue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LearnMoreModal;
