import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Support = () => {
    return (
      <div>
        <Navbar />
        <hr />
        <div
          className="container mx-auto flex flex-col items-center justify-center py-6 md:py-40"
          style={{
            fontFamily: "Lato sans-serif",
          }}
        >
          <h1
            className="text-4xl md:text-7xl text-center"
            style={{
              fontFamily: "Lato Bold",
            }}
          >
            Support
          </h1>
          <h2 className="text-sm md:text-2xl text-center text-textDark w-full md:w-2/5 my-3">
            We are here to help and answer any question that you might have.
          </h2>
          <div className="w-full px-4 flex justify-center">
            <button
              className="p-2 text-center text-lg bg-bgAppColor rounded-full px-8 text-white"
              style={{ fontFamily: "Lato Bold" }}
            >
              <a href="mailto:hello@careerpaths.io">hello@careerpaths.io</a>
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default Support