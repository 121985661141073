import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import BookingMainContent from './BookingMainContent/BookingMainContent'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { getRefFromQuery } from '../CustomFunctions/CustomFunctions'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../settings'

const BookingPage = () => {
    const [profile, setProfile] = useState([])
    const location = useLocation();
    getRefFromQuery(location)
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${id}`).then((response) => {
            setProfile(response.data)
        }).catch(e => {
            console.log('Error in Booking page Professional Api')
        })
    }, [])
    
    return (
      <div className="bg-bgGray h-screen w-full">
        {profile.User?.first_name && (
          <Helmet>
            <title>{`${
              profile?.is_anonymous
                ? "Anonymous"
                : `${profile.User?.first_name} ${profile.User?.last_name}`
            } | Request a call | ${CompanyTitle}`}</title>
            <meta
              name={`Request a call | ${CompanyTitle}`}
              content={`Request a call | ${CompanyTitle}`}
            />
          </Helmet>
        )}
        <Navbar />
        <hr />
        <div className="md:container mx-auto px-2 xl:px-56 md:py-2">
          <h1 className="text-lg md:text-2xl font-bold lg:mt-6 sm:py-4 sm:px-4">
            {" "}
            Well this is exciting!
            {profile?.is_anonymous
              ? ""
              : ` You're about to meet with ${profile.User?.first_name} ${profile.User?.last_name}`}
          </h1>
        </div>
        <BookingMainContent location={location.state} id={id} />
      </div>
    );
}

export default BookingPage
