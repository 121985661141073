import React, { useState } from "react";
import Loader from "react-loader-spinner";
import Colors from "../../../../Colors/Colors";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const StopDonatingModal = (props) => {
  let navigate = useNavigate();
  const [confirmStop, setConfirmStop] = useState(false);
  const handleConfirm1 = () => {
    let id = localStorage.getItem("id");
    axios
      .delete(
        process.env.REACT_APP_SERVER_PATH + `/professional/${id}/charity/${1}`
      )
      .then((response) => {
        setConfirmStop(true);
        console.log(response);
      })
      .catch(function (error) {
        console.log("Error in deleting charity", error);
      });
  };

  const gotoDashboard = () => {
    navigate("/dashboard/home");
  };

  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>

      <div className="flex flex-col items-center justify-center md:w-2/6 w-11/12  z-20 bg-white rounded-xl py-4">
        {!props.isLoading ? (
          <div className="relative text-center">
            {!confirmStop ? (
              <div>
                <img
                  className="h-24 w-24 mt-2 mx-auto"
                  // src="https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  src="/charity/stopHonor.png"
                  alt=""
                />

                <div className="mt-5 text-center">
                  <h3 className="text-2xl font-bold text-gray-800 dark:text-white">
                    Your charity Badge
                  </h3>
                  <h3 className="text-xl font-bold text-red-600 dark:text-white">
                    will be removed
                  </h3>
                  <p className="mt-2 text-gray-500 dark:text-gray-400">
                    If you choose to stop donating to charity, donations will no
                    longer be deducted from your earnings. Please also be aware
                    that your charity badge will be removed from your profile.
                  </p>
                </div>

                <button
                  onClick={handleConfirm1}
                  className="px-4 sm:mx-2 w-1/2 py-2.5 my-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-bgAppColor rounded-md  focus:outline-none  focus:ring-opacity-40"
                >
                  Stop donating
                </button>
                <button
                  onClick={gotoDashboard}
                  className="px-4 sm:mx-2 w-1/2 py-2.5 text-sm font-medium tracking-wide text-gray-100 capitalize rounded-md bg-gray-500  focus:outline-none  focus:ring-opacity-40"
                >
                  Go back
                </button>

                <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                  If you have any questions please contact
                </p>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:someone@yoursite.com"
                  className="mt-2 text-bgAppColor text-lg"
                >
                  hello@careerpaths.io
                </a>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <img
                  className="h-24 w-24 mt-2 mx-auto"
                  // src="https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  src="/charity/stopHonor.png"
                  alt=""
                />

                  <h3 className="font-bold text-gray-800 dark:text-white w-2/3  text-sm">
                    Charity donation will no longer be deducted from your earnings
                  </h3>

                <button
                  onClick={gotoDashboard}
                  className="px-4 sm:mx-2 w-1/2 py-2.5 my-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-bgAppColor rounded-md  focus:outline-none  focus:ring-opacity-40"
                >
                  Go back to dashboard
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full flex justify-center items-center h-full">
            <Loader width={200} height={200} type="Oval" color={Colors.blue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StopDonatingModal;
