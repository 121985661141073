import React, { useState, useEffect } from 'react'
import Colors from '../Colors/Colors'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import jwt_decode from "jwt-decode";
import { update_profile_step } from './StepUpdated';
import { Mixpanel } from '../Mixpanel';

const AddVideo = (props) => {
    localStorage.setItem("navState", 5)
    const [videoUrl, setVideoUrl] = useState("")
    const [videoUploaded, setVideoUploaded] = useState(false)
    const [fileInput, setFileInput] = useState()
    const [isLoading, setIsloading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [showVideoError, setShowVideoError] = useState(false)
    const [afterLoading, setAfterLoading] = useState(false);
    const getProfessional = () => {
        return axios.get(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`).then((response) => {
            if (response.data.prof_intro_link != null) {
                setVideoUploaded(true)
                setVideoUrl(response.data.prof_intro_link)
                return response.data
            }
        }).catch(e => {
            console.log("error is from uploading");
        })
    }
    useEffect(() => {
        getProfessional()
    }, [])

    const handleVideo = (event) => {
        console.log(event.target.files[0].name);
        console.log(event.target.files[0].type);
        if (event.target.files[0].type === 'video/mp4'
            || event.target.files[0].type === 'video.mov'
            || event.target.files[0].type === 'video.wmv'
            || event.target.files[0].type === 'video.avi'
            || event.target.files[0].type === 'video.mkv') {
            setShowVideoError(false)
            setIsloading(true)
            const formDataExp = new FormData();
            formDataExp.append("file", event.target.files[0]);
            formDataExp.append("user_id", jwt_decode(localStorage.getItem("signup_token")).id);
            getProfessional().then((professional) => {
                if (professional != null) {
                    // Check if the user has already uploaded a video
                    if (professional.video_intro) {
                        // Delete the old video from the server
                        axios.delete(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}/video_intro`).then((response) => {
                            console.log(response);
                        }).catch(e => {
                            console.log("error is from deleting old video");
                        })
                    }
                }
                // Upload the new video
                axios.patch(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}/video_intro`, formDataExp).then((response) => {
                    console.log(response);
                    Mixpanel.track(`web: Add Intro Video.`);
                    setVideoUrl(URL.createObjectURL(event.target.files[0]))
                    setIsloading(false)
                    setAfterLoading(false);
                    setDisabled(false)
                    getProfessional()
                    // props.setNavState(props.navState + 1)

                }).catch(e => {
                    console.log("error is from uploading");
                })
            })
        } else {
            setShowVideoError(true)
        }
    }


    return (
      <div className="flex flex-col justify-center items-center text-textFullDark w-full">
        <img src="/addVideo.png" alt="addVideo" className="w-36" />
        <p className="text-2xl font-bold mt-3 text-textFullDark">
          Profile intro video
        </p>
        <p className="text-xs mt-3 text-center">
          Increase the number of times you get booked by adding a video intro.
          Tell your mentees what you can help them with.
        </p>
        {/* but remember to keep it short and insightful! */}
        {showVideoError ? (
          <p className="text-red-500 mt-2 text-center">
            Failed to upload video;the format is not supported
          </p>
        ) : null}
        <div
          className="flex flex-col items-center justify-center p-4 border border-borderGray w-1/2 mt-4 rounded-lg  hover:border-borderGray"
          onClick={() => {
            fileInput.click();
          }}
        >
          {videoUploaded ? (
            <video
              className="w-full rounded"
              key={videoUrl}
              muted
              autoplay="autoplay"
              preload="metadata"
            >
              <source src={videoUrl} type="video/mp4" />
              <input
                className="text-xs text-white ml-2"
                type="file"
                onChange={handleVideo}
                style={{ display: "none" }}
                // ref={fileinput => setFileInput(fileinput)}
                ref={(fileinput) => setFileInput(fileinput)}
                accept="video/*"
              />
            </video>
          ) : isLoading ? (
            <span>
              <Loader
                type="TailSpin"
                color={Colors.blue}
                height={50}
                width={50}
                // timeout={5000} //5 secs
              />
            </span>
          ) : (
            <div className="flex items-center flex-col">
              <img src="/flowvideo.png" alt="videoIcon" className="w-8" />
              <input
                className="text-xs text-white ml-2"
                type="file"
                onChange={handleVideo}
                style={{ display: "none" }}
                ref={(fileinput) => setFileInput(fileinput)}
                accept="video/*"
              />
              <button className="text-xs  mt-2">Upload a video</button>
            </div>
          )}
        </div>
        <div>
          <button
            className="w-full text-textFullDark mt-2"
            onClick={() => {
              update_profile_step(
                localStorage.getItem("signup_token"),
                jwt_decode(localStorage.getItem("signup_token")).id,
                "5"
              );
              props.setNavState(props.navState + 1);
            }}
          >
            Skip
          </button>
        </div>
        <div className="w-full flex justify-end items-center mt-4 text-white">
          <button
            className="py-2 mr-auto px-8 rounded-lg flex"
            style={{ backgroundColor: Colors.blue, color: "white" }}
            onClick={() => {
              props.setNavState(props.navState - 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            <p>Back</p>
          </button>
          <button
            className="py-2  px-8 rounded-lg flex text-white"
            disabled={!videoUrl || videoUrl.length === 0}
            style={{
              backgroundColor: videoUrl.length > 0 ? Colors.blue : Colors.gray,
              color: videoUrl.length > 0 ? "white" : "gray",
            }}
            onClick={() => {
              update_profile_step(
                localStorage.getItem("signup_token"),
                jwt_decode(localStorage.getItem("signup_token")).id,
                "5"
              );
              props.setNavState(props.navState + 1);
            }}
          >
            <p>Next </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>
        </div>
      </div>
    );
}

export default AddVideo
