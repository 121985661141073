import Select from "react-select";
import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "@culturehq/add-to-calendar/dist/styles.css";
import AddToCalendar from "@culturehq/add-to-calendar";
import Calendar from "react-calendar";
import Colors from "../Colors/Colors";
import Ratings from "../Rating/Ratings";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { CompanyTitle } from "../settings";
import Loader from "react-loader-spinner";
import { isProfilePopUp } from "../Redux/reducers/profile/profileSlice";
import User from "../components/User/UserCard";

import {
  isFromBookingModal,
  toggleFreeCallModal,
} from "../Redux/reducers/freeCall/freeCallSlice";
import {
  navStateAddBy1,
  navStateDecBy1,
  navStateToZero,
} from "../Redux/reducers/nav/navSlice";
import {
  setLogin,
} from "../Redux/reducers/customer/customerSlice";
import { setNameAndId } from "../Redux/reducers/professional/professionalSlice";
import ReactStars from "react-rating-stars-component";
import { RatingHandler } from "../utils/Rating";

const BookingModal = (props) => {
  //start- checking if the professional is online/available
  const [isOnline, setIsOnline] = useState(false);

  const getData = (id1) => {
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH + `/is_professional_available/${id1}`
      )
      .then((response) => {
        if (response.data.message?.length > 0) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log("error is=>", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("error is=>", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("error is=>", error.message);
        }
      });
  };

  //end - checking if the professional is online/available

  let dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  let prof_name_from_redux = useSelector(
    (state) => state.professional.professionalNameOfModal
  );
  let prof_id_from_redux = useSelector(
    (state) => state.professional.professionalIdOfModal
  );
  let profilePopUp = useSelector((state) => state.profile.isProfilePopUp);
  let navState = useSelector((state) => state.nav.bookingModalNavState);
  // const [navState, setNavState] = useState(0)
  const [professionalImage, setProfessionalImage] = useState(null);
  const [professionals, setProfessionals] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [textBody, setTextBody] = useState("");
  const [remainingWords, setRemainingWords] = useState(280);
  const [professionalID, setProfessionalID] = useState(null);
  // const [profilePopUp, setProfilePopUp] = useState(false)
  const [professionalName, setProfessionalName] = useState("");
  const [value, setValue] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [slotcolor, setSlotcolor] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  let comp =
    moment(new Date()).format("dd mm yyyy") ===
    moment(value).format("dd mm yyyy");

  const [profile, setProfile] = useState([]);
  const [experience, setExperience] = useState([]);
  //

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [industry, setIndustry] = useState([]);

  const getAllIndustries = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/industry`)
      .then((response) => {
        setIndustry(response.data);
      })
      .catch((e) => {
        console.log("error in industry api");
      });
  };
  const getIndustrySelected = (dataArray) => {
    dataArray.length > 0 &&
      dataArray.forEach((m) => {
        if (selectedIndustry.includes(m.id)) {
          // Remove ID
          const selectedIndustryCopy = [...selectedIndustry];
          const itemIndex = selectedIndustryCopy.indexOf(m.id);
          selectedIndustryCopy.splice(itemIndex, 1);
          setSelectedIndustry(selectedIndustryCopy);
        } else {
          // ADD ID
          setSelectedIndustry((selectedIndustry) => [
            ...selectedIndustry,
            m.id,
          ]);
        }
        m.sub &&
          m.sub.length > 0 &&
          m.sub.forEach((m) => {
            if (selectedIndustry.includes(m.id)) {
              // Remove ID
              const selectedIndustryCopy = [...selectedIndustry];
              const itemIndex = selectedIndustryCopy.indexOf(m.id);
              selectedIndustryCopy.splice(itemIndex, 1);
              setSelectedIndustry(selectedIndustryCopy);
            } else {
              // ADD ID
              setSelectedIndustry((selectedIndustry) => [
                ...selectedIndustry,
                m.id,
              ]);
              console.log("id added", m.id);
            }
          });
      });
  };

  const getProfessional = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${prof_id_from_redux}`
      )
      .then((response) => {
        getIndustrySelected(response.data?.Industries);
        if (response.data?.User?.is_online) {
          getData(response.data?.User?.id);
        } 
      });
  };
  useEffect(() => {
    getProfessional();
    getAllIndustries();
  }, [prof_id_from_redux]);
  //
  const responseTime = () => {
    const duration = moment.duration(profile.response_time, "minutes");
    let responseTime = "";
    for (const [unit, value] of Object.entries(duration._data)) {
      if (value > 1) responseTime = `${value} ${unit}`;
      else if (value > 0) responseTime = `${value} ${unit.slice(0, -1)}`;
    }
    return responseTime;
  };
  const [booking_date_end, setBooking_date_end] = useState(new Date());
  const [AfterConvertingtoUTC, setAfterConvertingtoUTC] = useState(new Date());

  const addToCalender = () => {
   
    let booking_date = new Date(value);
    const booking_time = timeSlot.split(" ");
    let hours_mint = booking_time[0].split(":");
    if (booking_time[1] === "PM") {
      hours_mint[0] = parseInt(hours_mint[0]) + 12;
    }
    booking_date = booking_date.setHours(hours_mint[0], hours_mint[1]);
    setBooking_date_end(
      new Date(booking_date).setMinutes(parseInt(15) + parseInt(hours_mint[1]))
    );
    // let dateString = value.getFullYear() + "-" + monthValue + "-";
    // dateString = new Date(dateString);
    setAfterConvertingtoUTC(
      moment(booking_date).utcOffset("GMT-06:00").format()
    );
  };

  useEffect(() => {
    //props.professionalID to give
    // dispatch({ type: 'setProfessionalID', id: props.professionalID })
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${prof_id_from_redux}`
      )
      .then((response) => {
        setProfile(response.data);
        setProfessionalImage(response.data?.User?.profile_photo);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/experience/${prof_id_from_redux}`
      )
      .then((response) => {
        setExperience(response.data);
      });
  }, [prof_id_from_redux]);

  const toggleProfileModal = () => {
    // dispatch({ type: "isProfilePopUp" });
    dispatch(isProfilePopUp());
  };

  const makeDic = async (available_slots, month, e) => {
    let dictionary = {};
    let value = e;
    for (const time of available_slots) {
      const dateTime = moment(time * 1000);
      if (dateTime.month() === month - 1) {
        const time = dateTime.format("LT");
        const date = dateTime.format("DD-MM-YYYY");
        if (dictionary[date]) dictionary[date].push(time);
        else dictionary[date] = [time];
      }
    }
    setAvailableDates(Object.keys(dictionary));
    for (let i in dictionary) {
      if (value !== undefined) {
        if (
          i ===
          `${moment(value).format("DD").toString()}-${moment(value)
            .format("MM")
            .toString()}-${value.getFullYear().toString()}`
        ) {
          setSlots(dictionary[i]);
        }
      }
    }
  };

  const getSlots = (month, year, e) => {
    let token = localStorage.getItem("cp_web_token");
   
    let duration = 15;
   

    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${prof_id_from_redux}/available_slots?month=${month}&year=${year}&slot_duration=${duration}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.message &&
          result.data.message.availableSlots
        ) {
          makeDic(result.data.message.availableSlots, month, e);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    setDate(value);
  }, [value, professionalID]);
  const handleTimeSlot = (m) => {
    setTimeSlot(m);
    setSlotcolor(m);
  };
  const setDate = (e) => {
    setSlotcolor(null);
    setValue(e);
    let month = e.getMonth() + 1;
    let year = e.getFullYear();
    getSlots(month, year, e);
  };

  useEffect(() => {
    if (props.partner) {
      if (
        props.partnerName.toLowerCase() === "Finatal".toLowerCase() ||
        props.partnerName.toLowerCase() === "finataljobs".toLowerCase() ||
        props.partnerName.toLowerCase() === "linkedinpejobs".toLowerCase() ||
        props.partnerName.toLowerCase() === "linkedintechjobs".toLowerCase() ||
        props.partnerName.toLowerCase() === "PER".toLowerCase() ||
        props.partnerName.toLowerCase() === "JBM".toLowerCase() ||
        props.partnerName.toLowerCase() === "efinancialcareers".toLowerCase() ||
        props.partnerName.toLowerCase() === "LinkedIn".toLowerCase()
      ) {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_PATH}/professionals?include=${process.env.REACT_APP_FREECALL_PROFESSIONLS_FINITAL}`
          )
          .then((response) => {
            setProfessionals(response.data);
          })
          .catch((e) => {
            console.log("Error from professional api in Modal");
          });
      } else {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_PATH}/users?search=&limit=10&page=1&freeCall=true&type=professional`
          )
          .then((response) => {
            setProfessionals(response.data);
          })
          .catch((e) => {
            console.log("Error from professional api in Modal");
          });
      }
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/users?search=&limit=10&page=1&freeCall=true&type=professional`
        )
        .then((response) => {
          setProfessionals(response.data);
        })
        .catch((e) => {
          console.log("Error from professional api in Modal");
        });
    }
  }, []);

  const combineDateAndTime = function (date, time12h) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    let finalDate = new Date(date);
    let timeString = hours + ":" + minutes + ":00";
    let year = finalDate.getFullYear();
    let month = finalDate.getMonth() + 1; // Jan is 0, dec is 11
    let day = finalDate.getDate();
    let dateString = "" + year + "-" + month + "-" + day;
    let combined = new Date(dateString + " " + timeString);
    return combined.getTime() / 1000;
  };

  const requestCallHandler = () => {
    setLoader(true);
    const bookingDetail = {
      topic_id: selectedOption.value,
      notes: textBody,
      session_duration: 15,
      booking_time: combineDateAndTime(value, timeSlot),
      coupon_code: process.env.REACT_APP_FREE_CALL,
      is_free_call: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${prof_id_from_redux}/request_call`,
        bookingDetail,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        addToCalender();
        dispatch(navStateAddBy1());
      })
      .catch((e) => {
        setLoader(false);
        console.log("Error in booking");
      });
  };
  let professionalsToRender = null;
  if (props.partner) {
    if (
      props.partnerName.toLowerCase() === "Finatal".toLowerCase() ||
      props.partnerName.toLowerCase() === "finataljobs".toLowerCase() ||
      props.partnerName.toLowerCase() === "linkedinpejobs".toLowerCase() ||
      props.partnerName.toLowerCase() === "linkedintechjobs".toLowerCase() ||
      props.partnerName.toLowerCase() === "PER".toLowerCase() ||
      props.partnerName.toLowerCase() === "JBM".toLowerCase() ||
      props.partnerName.toLowerCase() === "efinancialcareers".toLowerCase() ||
      props.partnerName.toLowerCase() === "LinkedIn".toLowerCase()
    ) {
      professionalsToRender = (
        <div className="flex flex-col md:flex-row flex-wrap md:justify-center sm:px-16 md:px-0">
          {professionals.map((p) => {
            return (
              <ReuseableComponentForPartner
                id={p.user_id}
                professionalID={professionalID}
                setProfessionalID={setProfessionalID}
                setProfessionalName={setProfessionalName}
                name={p.User?.first_name}
                lastName={p.User?.last_name}
                designation={p.job_title}
                time={p.response_time}
                price={p.session_price_15_min}
                image={p.User?.profile_photo}
                isCharity={p.is_charity}
                intro_link={p.prof_intro_link}
                location={p.location}
                profilePopUp={profilePopUp}
                Experiences={p.Experiences}
                avgRating={p.avgRating}
                reviewCount={p.reviewCount}
                // setProfilePopUp={setProfilePopUp}
              />
            );
          })}
        </div>
      );
    } else {
      professionalsToRender = (
        <div className="flex flex-col md:flex-row flex-wrap md:justify-center sm:px-16 md:px-0">
          {professionals.map((p) => {
            return (
              <ReuseableComponentForNoCompany
                id={p.id}
                professionalID={professionalID}
                setProfessionalID={setProfessionalID}
                setProfessionalName={setProfessionalName}
                name={p.first_name}
                lastName={p.last_name}
                designation={p.job_title}
                time={p.response_time}
                price={p.session_price_15_min}
                image={p.profile_photo}
                isCharity={p.is_charity}
                intro_link={p.prof_intro_link}
                location={p.location}
                profilePopUp={profilePopUp}
                Experiences={p.Experiences}
                avgRating={p.avgRating}
                reviewCount={p.reviewCount}
                // setProfilePopUp={setProfilePopUp}
              />
            );
          })}
        </div>
      );
    }
  } else {
    professionalsToRender = (
      <div className="flex flex-col md:flex-row flex-wrap md:justify-center sm:px-16 md:px-0">
        {professionals.map((p) => {
          return (
            <ReuseableComponentForNoCompany
              id={p.id}
              professionalID={professionalID}
              setProfessionalID={setProfessionalID}
              setProfessionalName={setProfessionalName}
              name={p.first_name}
              lastName={p.last_name}
              designation={p.job_title}
              time={p.response_time}
              price={p.session_price_15_min}
              image={p.profile_photo}
              isCharity={p.is_charity}
              intro_link={p.prof_intro_link}
              location={p.location}
              profilePopUp={profilePopUp}
              Experiences={p.Experiences}
              avgRating={p.avgRating}
              reviewCount={p.reviewCount}
              // setProfilePopUp={setProfilePopUp}
            />
          );
        })}
      </div>
    );
  }
  let ComponentToRender = null;
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  switch (navState) {
    case 0:
      ComponentToRender = (
        <div>
          <div className="w-full flex justify-end items-center -ml-4 md:ml-0 md:-mt-8 mb-4 md:mb-8">
            <button
              className="bg-bgGray rounded-full p-2"
              onClick={props.toggle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="px-2 w-full">
            <p className="text-2xl md:text-4xl text-center font-bold mb-8 px-8 md:px-0">
              Your first session is free! Simply select your preferred mentor
              from the options below to get started
            </p>
          </div>
          {professionalsToRender}
        </div>
      );
      break;
    case 1:
      ComponentToRender = (
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <button
              onClick={() => {
                dispatch(navStateDecBy1());
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
            </button>
            <button onClick={props.toggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <div className="h-screen md:h-auto overflow-y-auto rounded-lg bg-white p-8 flex flex-col md:flex-row justify-center relative">
              <div className="md:hidden mt-16">
                <img
                  src={
                    profile.User?.profile_photo
                      ? profile.User?.profile_photo
                      : "/avatar.png"
                  }
                  alt="photoss"
                  className="border border-borderGray rounded-xl mr-6 w-96 object-cover"
                  style={{ height: "200px" }}
                />
              </div>
              <div className="hidden md:block mr-6">
                <img
                  src={
                    profile.User?.profile_photo
                      ? profile.User?.profile_photo
                      : " /avatar.png"
                  }
                  alt="photoss"
                  className="border border-borderGray rounded-xl  w-96 object-cover"
                  style={{ height: "500px" }}
                />
              </div>
              <div
                className="w-full md:w-3/5 relative"
                style={{ height: "500px" }}
              >
                <div className="flex flex-col h-full overflow-y-auto ">
                  <div className="py-1">
                    <div className="flex items-start">
                      {profile.is_charity === 1 ? (
                        <div data-tip="Mentor donates proportion of earnings to charity">
                          <ReactTooltip />
                          <img
                            src="/charity.svg"
                            alt="charity"
                            className="w-6 mr-4 cursor-pointer"
                          />
                        </div>
                      ) : null}

                      <div className="flex flex-wrap">
                        {industry.map((m) => {
                          if (selectedIndustry.includes(m.id)) {
                            return (
                              <>
                                <span
                                  className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                                  style={{
                                    backgroundColor: Colors.bgBlue,
                                    color: "white",
                                    borderColor: Colors.bgBlue,
                                  }}
                                >
                                  {m.name}
                                </span>
                                {m.sub.length > 0 &&
                                  m.sub.map((s) => {
                                    if (selectedIndustry.includes(s.id)) {
                                      return (
                                        <span
                                          className={`flex items-center border border-borderGray rounded-full m-1 py-1 px-3 text-sm`}
                                          style={{
                                            backgroundColor: Colors.bgDarkBlue,
                                            color: "#383838",
                                            borderColor: Colors.bgDarkBlue,
                                          }}
                                        >
                                          {s.name}
                                        </span>
                                      );
                                    }
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-black my-2 truncate">
                      {profile.User?.first_name} {profile.User?.last_name}
                    </div>
                    <div className="text-sm text-textFullDark">
                      {profile.job_title}
                    </div>
                    <div className="text-xs text-textDark sm:w-full md:w-4/5 py-4">
                      {profile.User?.bio}
                    </div>
                    <div className={`flex items-center`}>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          {profile.avgRating > 0 ? (
                            <div>
                              {profile.avgRating && <Ratings value={RatingHandler(profile.avgRating)} />}
                            </div>
                          ) : (
                            <Ratings value={0} />
                          )}
                          &nbsp;&nbsp;
                          {profile.avgRating && (
                            <p className="text-textDark">
                              {(+profile.avgRating).toFixed(1)}
                            </p>
                          )}
                        </div>
                        {profile.reviewCount > 0 ? (
                          <div>
                            {profile.reviewCount && (
                              <p className="text-xs text-textDark">
                                {profile.reviewCount} reviews
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="text-xs text-textDark">{0} reviews</p>
                        )}
                      </div>
                      <div
                        className="flex flex-col ml-4"
                        data-tip="Time it takes for mentor to respond to your meeting request"
                      >
                        <ReactTooltip />
                        <div className="flex flex-row items-center justify-center">
                          <div>
                            <img
                              src="/Vector.png"
                              alt="photos"
                              className="w-2 h-3"
                            />
                          </div>
                          <p className="text-textDark">
                            &nbsp;{responseTime() ? responseTime() : 0}
                          </p>
                        </div>
                        <p className="text-xs text-textDark">Response time</p>
                      </div>
                    </div>
                  </div>

                  {experience.length > 0 && (
                    <div className="pb-4 md:pb-16">
                      <p className="my-1 text-2xl font-bold">Experience</p>
                      {experience.map((m, i) => {
                        return (
                          <Experience
                            key={i}
                            companyImage={m.profile_photo}
                            position={m.title}
                            companyName={m.company}
                            startDate={m.start_date}
                            endDate={m.end_date}
                            location={m.location}
                            description={m.description}
                            currentJob={m.current_job}
                          />
                        );
                      })}
                    </div>
                  )}

                  <div className="w-full hidden md:flex absolute items-end bottom-0 h-16 bg-white">
                    {isOnline ? (
                      <button
                        className="bg-bgAppColor h-10 py-2 font-bold text-white w-11/12  md:w-1/2 text-center rounded-lg"
                        onClick={() => {
                          if (localStorage.getItem("cp_web_token")) {
                            dispatch(navStateAddBy1());
                          } else {
                            dispatch(navStateToZero());
                            dispatch(toggleFreeCallModal());
                            dispatch(setLogin());
                            dispatch(isFromBookingModal());
                          }
                        }}
                      >
                        Book a session
                      </button>
                    ) : (
                      <p className="bg-gray-200 h-10 py-2 font-bold  w-11/12  md:w-1/2 text-center rounded-lg">
                        Professional not available
                      </p>
                    )}

                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex bg-red-500 justify-center md:hidden">
              <button
                className="bg-bgAppColor absolute -bottom-7 mb-8 py-2 font-bold text-white w-11/12  md:w-1/2 text-center rounded-lg mt-2"
                onClick={() => {
                  if (localStorage.getItem("cp_web_token")) {
                    dispatch(navStateAddBy1());
                  } else {
                    dispatch(navStateToZero());
                    dispatch(toggleFreeCallModal());
                    dispatch(setLogin());
                    dispatch(isFromBookingModal);
                  }
                }}
              >
                Book a session
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case 2:
      ComponentToRender = (
        <div>
          <div className="px-2 w-full mb-4">
            <p className="text-2xl md:text-4xl text-center font-bold">
              Well this is exciting! You're about to meet {prof_name_from_redux}
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-4/12 bg-white rounded flex flex-col pt-20 p-4 mb-4 md:mb-0">
              <div className="flex items-center">
                <img
                  alt=""
                  src={professionalImage ? professionalImage : "/avatar.png"}
                  className="rounded-full w-16 h-16 object-cover"
                />
                <p className="ml-2 font-bold text-xl text-textFullDark">
                  {prof_name_from_redux}
                </p>
              </div>
              <div className="mt-4">
                <p className="text-lg font-bold text-textFullDark">
                  {CompanyTitle} live video calls with {prof_name_from_redux}
                </p>
              </div>
              <div className="flex">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3">15 min</p>
              </div>
              {selectedOption !== null ? (
                <div className="mt-2 flex">
                  {/* <p className="text-sm text-gray-700">Web Conferencing details provided upon confirmation</p> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path
                      fillRule="evenodd"
                      d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="text-sm text-textFullDark ml-3">
                    {selectedOption !== null ? selectedOption.label : ""}
                  </p>
                </div>
              ) : null}
              {textBody.length > 0 && (
                <div className="flex mt-2 items-start">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill={Colors.darkGray}
                    >
                      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <p className="text-sm text-textFullDark ml-3 break-all">
                    {textBody}
                  </p>
                </div>
              )}
              <div className="flex items-start mt-1">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                  </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3">
                  Following confirmation of your booking, video call directly
                  from the app or website on the date and time of your meeting
                </p>
              </div>
            </div>
            <div className="md:ml-4 w-full md:w-8/12 bg-white rounded-xl">
              <SetDetails
                toggle={props.toggle}
                professionalName={professionalName}
                selectedOption={selectedOption}
                handleChange={handleChange}
                options={options}
                setOptions={setOptions}
                textBody={textBody}
                setTextBody={setTextBody}
                remainingWords={remainingWords}
                setRemainingWords={setRemainingWords}
                professionalID={professionalID}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 3:
      ComponentToRender = (
        <div>
          <div className="px-4 w-full mb-4">
            <p className="text-2xl md:text-4xl text-center font-bold">
              Please select a date and time for your meeting from the options
              below
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-4/12 bg-white rounded-xl flex flex-col pt-20 p-4 mb-4 md:mb-0">
              <div className="flex items-center">
                <img
                  alt=""
                  src={professionalImage ? professionalImage : "/avatar.png"}
                  className="rounded-full w-16 h-16 object-cover"
                />
                <p className="ml-2 font-bold text-xl text-textFullDark">
                  {prof_name_from_redux}
                </p>
              </div>
              <div className="mt-4">
                <p className="text-lg font-bold text-textFullDark">
                  {CompanyTitle} live video calls with {prof_name_from_redux}
                </p>
              </div>
              <div className="flex">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3">15 min</p>
              </div>
              {selectedOption !== null ? (
                <div className="mt-2 flex">
                  {/* <p className="text-sm text-gray-700">Web Conferencing details provided upon confirmation</p> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path
                      fillRule="evenodd"
                      d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="text-sm text-textFullDark ml-3">
                    {selectedOption !== null ? selectedOption.label : ""}
                  </p>
                </div>
              ) : null}
              {textBody.length > 0 && (
                <div className="flex mt-2 items-start">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill={Colors.darkGray}
                    >
                      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <p className="text-sm text-textFullDark ml-3 break-all">
                    {textBody}
                  </p>
                </div>
              )}
              {!comp && value !== null ? (
                <div className="flex mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="ml-3 text-sm text-textFullDark">
                    {props.dateBooking !== null
                      ? `${moment(value).format(
                          "dddd"
                        )}, ${value.getDate()} ${moment(value).format(
                          "MMMM"
                        )} ${timeSlot !== null ? timeSlot : ""} `
                      : ""}
                  </p>
                </div>
              ) : null}
              <div className="flex items-start mt-1">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                  </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3">
                  Following confirmation of your booking, video call directly
                  from the app or website on the date and time of your meeting
                </p>
              </div>
            </div>
            <div className="md:ml-4 w-full md:w-8/12 bg-white rounded-xl">
              <div className="w-full flex justify-between items-center">
                <button
                  className="p-4"
                  onClick={() => {
                    // dispatch({ type: "navStateDecBy1" });
                    dispatch(navStateDecBy1());
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </button>
                <button className="p-4" onClick={props.toggle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="p-4 pt-0 bg-white rounded-lg">
                <div className="flex sm:flex-col md:flex-row md:justify-between">
                  <div className="flex flex-col justify-center items-center h-96 px-2">
                    <p className="sm:text-lg md:text-2xl font-bold mb-2">
                      Select a date
                    </p>
                    <div className="calender w-80 md:w-auto">
                      <Calendar
                        onChange={setDate}
                        value={value}
                        onClickDay={({ action }) => {
                          setTimeSlot(null);
                        }}
                        showNavigation={true}
                        tileClassName={({ date, view, activeStartDate }) => {
                          if (
                            availableDates.find(
                              (x) =>
                                x === moment(date, view).format("DD-MM-YYYY")
                            )
                          ) {
                            return "text-textApp font-bold focus:text-white w-12 h-12 rounded-full";
                          } else {
                            return "text-textLight font-bold bg-white w-12 h-12 rounded-full pointer-events-none";
                          }
                        }}
                        onActiveStartDateChange={({
                          activeStartDate,
                        }) => {
                          setDate(activeStartDate);
                        }}
                        selectRange={false}
                        showNeighboringMonth={false}
                        next2Label={null}
                        prev2Label={null}
                        className="border-2 border-borderApp rounded-lg p-2"
                      />
                    </div>
                  </div>
                  <div className="md:ml-4 flex flex-col items-center md:h-96 overflow-auto w-full">
                    <p className="sm:text-lg md:text-2xl font-bold mb-2">
                      Select a time
                    </p>
                    {slots.length < 0 === null
                      ? null
                      : slots.map((m) => {
                          return (
                            <p
                              key={m}
                              className="cursor-pointer p-3 border-2 text-center bg-white border-borderApp font-bold rounded-full  mb-2 w-full"
                              style={{
                                backgroundColor:
                                  slotcolor === m ? Colors.blue : "",
                                color: slotcolor === m ? "white" : "black",
                              }}
                              onClick={() => handleTimeSlot(m)}
                            >
                              {m}
                            </p>
                          );
                        })}
                  </div>
                </div>
                <div className="flex w-full md:mt-10">
                  <button
                    className={`text-white w-full flex justify-center items-center px-3 py-2 ${
                      timeSlot !== null && !loader
                        ? "bg-bgAppColor"
                        : "bg-bgdarkGray"
                    } md:ml-4 rounded`}
                    onClick={requestCallHandler}
                    disabled={loader}
                  >
                    {loader ? (
                      <Loader
                        height={25}
                        width={25}
                        color="white"
                        type="Oval"
                      />
                    ) : (
                      "Book now"
                    )}
                  </button>
                </div>
                {/* <button className="w-full bg-blue-500 text-white mt-4 rounded py-2 flex justify-center items-center" onClick={props.nextStep}>Next</button> */}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 4:
      ComponentToRender = (
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-4/12 bg-white rounded-xl flex flex-col p-4 py-8 md:py-16 mb-4 md:mb-0">
            <div className="flex items-center">
              <img
                alt=""
                src={professionalImage ? professionalImage : "/avatar.png"}
                className="rounded-full w-16 h-16 object-cover"
              />
              <p className="ml-2 font-bold text-xl text-textFullDark">
                {prof_name_from_redux}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-lg font-bold text-textFullDark">
                {CompanyTitle} live video calls with {prof_name_from_redux}
              </p>
            </div>
            <div className="flex">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill={Colors.darkGray}
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <p className="text-sm text-textFullDark ml-3">15 min</p>
            </div>
            {selectedOption !== null ? (
              <div className="mt-2 flex">
                {/* <p className="text-sm text-gray-700">Web Conferencing details provided upon confirmation</p> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill={Colors.darkGray}
                >
                  <path
                    fillRule="evenodd"
                    d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="text-sm text-textFullDark ml-3">
                  {selectedOption !== null ? selectedOption.label : ""}
                </p>
              </div>
            ) : null}
            {textBody.length > 0 && (
              <div className="flex mt-2 items-start">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill={Colors.darkGray}
                  >
                    <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                    <path
                      fillRule="evenodd"
                      d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3 break-all">
                  {textBody}
                </p>
              </div>
            )}
            {value !== null ? (
              <div className="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill={Colors.darkGray}
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="ml-3 text-sm text-textFullDark">
                  {props.dateBooking !== null
                    ? `${moment(value).format(
                        "dddd"
                      )}, ${value.getDate()} ${moment(value).format("MMMM")} ${
                        timeSlot !== null ? timeSlot : ""
                      } `
                    : ""}
                </p>
              </div>
            ) : null}
            <div className="flex items-start mt-1">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill={Colors.darkGray}
                >
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                </svg>
              </button>
              <p className="text-sm text-textFullDark ml-3">
                Following confirmation of your booking, video call directly from
                the app or website on the date and time of your meeting
              </p>
            </div>
          </div>
          <div className="w-full md:w-8/12 md:ml-4 bg-white rounded-xl flex justify-center items-center p-3">
            <div className="flex flex-col items-center justify-center mt-8">
              <p className="text-3xl md:text-4xl text-center font-bold">
                Your request has been sent
              </p>
              <div className="hidden md:inline-block">
                <p className="text-lg text-textDark mt-3">
                  Your request was sent to {prof_name_from_redux}.You
                </p>
                <p className="text-lg text-textDark text-center mb-2">
                  should receive an email shortly
                </p>
              </div>
              <div className="md:hidden">
                <p className="text-lg text-center text-textDark mt-3">
                  Your request was sent to {prof_name_from_redux}.You should
                  receive an confirmation email shortly
                </p>
              </div>
              <div>
                <div>
                  <AddToCalendar
                    className="chq-atc--button"
                    children="Add to my calendar"
                    event={{
                      name: selectedOption.label,
                      details: textBody,
                      startsAt: AfterConvertingtoUTC,
                      endsAt: booking_date_end,
                    }}
                  />
                </div>
                <div>
                  <button
                    onClick={props.toggle}
                    className="border border-borderGray flex justify-center items-center rounded px-4 py-2 w-full mt-3"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    default:
      return null;
  }
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>
      <div
        className={`sm:h-screen md:h-5/6 overflow-y-scroll sm:flex md:block justify-center w-screen md:w-5/6 p-4 md:p-8 rounded-lg z-30 bg-bgGray`}
      >
        {profilePopUp && (
          <ProfilePopUp
            setProfessionalImage={setProfessionalImage}
            toggle={toggleProfileModal}
            overlay="overlay"
            modal="modal"
            modalcontent="modal-content"
            closeModal="close-modal"
            professionalID={professionalID}
            // setProfilePopUp={setProfilePopUp}
          />
        )}
        {ComponentToRender}
      </div>
    </div>
  );
};

export default BookingModal;

const ReuseableComponentForPartner = ({
  name,
  designation,
  time,
  price,
  image,
  isCharity,
  intro_link,
  setProfessionalID,
  id,
  lastName,
  setProfessionalName,
  Experiences,
  avgRating,
  reviewCount,
}) => {
  let dispatch = useDispatch();
  
  const getLatestCompany = (arr) => {
    if (arr.length > 0) {
      const data = arr.sort(function (a, b) {
        var keyA = new Date(a.start_date),
          keyB = new Date(b.start_date);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      return data[0]?.company;
    } else {
      return null;
    }
  };
  return (
    <div
      className="cursor-pointer mb-4 md:mb-2 sm:mr-2 md:mr-4 sm:p-1 md:p-3 border border-borderGray box-border rounded-lg hover:shadow-lg  w-56 sm:h-56 md:h-80"
      onClick={() => {
        dispatch(setNameAndId({ id, name: `${name} ${lastName}` }));
        setProfessionalID(id);
        setProfessionalName(`${name} ${lastName}`);
        dispatch(navStateAddBy1());
      }}
    >
      <div className="rounded overflow-hidden">
        <div className="w-full">
          <ReactTooltip place="bottom" className="w-40 text-xs" />
          {intro_link !== null && (
            <div
              className="relative"
              data-tip="Mentor has a video introduction"
            >
              <div className="absolute  m-2">
                <img src="/showvideo.png" alt="icon" className="w-6" />
              </div>
            </div>
          )}
          {isCharity === 1 && (
            <div
              className="relative"
              data-tip="Mentor donates proportion of earnings to charity"
            >
              <div className="absolute right-0 m-2">
                <img src="/charity.svg" alt="charity" className="w-5 z-20" />
              </div>
            </div>
          )}
          <img
            className="rounded-lg w-full sm:h-36 md:h-56 mb-1 md:mb-2 object-cover border border-borderGray"
            src={image ? image : "/avatar.png"}
            alt={name}
          />
        </div>
        <div className="pt-1">
          <div className="flex items-center justify-between">
            <div className="text-textFullDark font-bold text-md truncate">
              {name}
            </div>
            <div className="flex items-center">
              {avgRating > 0 ? (
                <div className="h-5 flex items-center justify-center">
                  {avgRating && (
                    <ReactStars
                      value={RatingHandler(avgRating)}
                      size={20}
                      activeColor="#ffd700"
                      isHalf={true}
                      edit={false}
                      count={1}
                    />
                  )}
                </div>
              ) : (
                <div className="h-5 flex items-center justify-center">
                  <ReactStars
                    value={0}
                    size={20}
                    activeColor="#ffd700"
                    isHalf={true}
                    edit={false}
                    count={1}
                  />
                </div>
              )}
              &nbsp;&nbsp;
              <p className="text-sm text-textDark -ml-1">
                {avgRating ? (+avgRating).toFixed(1) : Math.trunc(0).toFixed(1)}
              </p>
            </div>
          </div>
          <div className="w-full flex items-end justify-between">
            <div className="text-xs text-textDark truncate hidden md:inline-block">
              {" "}
              {designation.length > 15
                ? `${designation.slice(0, 15)} ...`
                : designation}
            </div>
            <div className="text-xs text-textDark truncate md:hidden">
              {" "}
              {designation.length > 10
                ? `${designation.slice(0, 10)} ...`
                : designation}
            </div>
            <p className="text-xs md:text-sm text-textDark underline">
              {reviewCount ? reviewCount : 0} reviews
            </p>
          </div>
        </div>
        <div className="text-textDark my-1 text-xs truncate">
          {getLatestCompany(Experiences) !== null
            ? getLatestCompany(Experiences)
            : null}
        </div>
        <div className="py-1 flex flex-row justify-between items-center text-sm text-textFullDark"></div>
      </div>
    </div>
  );
};

const ReuseableComponentForNoCompany = ({
  name,
  designation,
  time,
  price,
  image,
  isCharity,
  intro_link,
  location,
  professionalID,
  setProfessionalID,
  id,
  setProfilePopUp,
  lastName,
  setProfessionalName,
  Experiences,
  avgRating,
  reviewCount,
}) => {
  let dispatch = useDispatch();
 
  return (
    <div
      className="cursor-pointer mb-4 md:mb-2 sm:mr-2 md:mr-4 sm:p-1 md:p-3 border border-borderGray box-border rounded-lg hover:shadow-lg  w-56"
      onClick={() => {
        // dispatch({ type: "setNameAndId", id, name: `${name} ${lastName}` });
        dispatch(setNameAndId({ id, name: `${name} ${lastName}` }));
        setProfessionalID(id);
        setProfessionalName(`${name} ${lastName}`);
        // dispatch({ type: "navStateAddBy1" });
        dispatch(navStateAddBy1());
      }}
    >
      <User
        name={name}
        designation={designation}
        time={time}
        // price={price}
        image={image}
        isCharity={isCharity}
        intro_link={intro_link}
        location={location}
        reviewCount={reviewCount}
        // isAnonymous={isAnonymous}
        Experiences={Experiences}
        avgRating={avgRating}
        width={52}
      />
    </div>
  );
};

const ProfilePopUp = (props) => {
  let dispatch = useDispatch();
  
  let prof_id_from_redux = useSelector(
    (state) => state.professional.professionalIdOfModal
  );
  const [profile, setProfile] = useState([]);
  const [experience, setExperience] = useState([]);
  const responseTime = () => {
    const duration = moment.duration(profile.response_time, "minutes");
    let responseTime = "";
    for (const [unit, value] of Object.entries(duration._data)) {
      if (value > 1) responseTime = `${value} ${unit}`;
      else if (value > 0) responseTime = `${value} ${unit.slice(0, -1)}`;
    }
    return responseTime;
  };
 
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${prof_id_from_redux}`
      )
      .then((response) => {
        setProfile(response.data);
        props.setProfessionalImage(response.data?.User?.profile_photo);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/experience/${prof_id_from_redux}`
      )
      .then((response) => {
        setExperience(response.data);
      });
  }, []);
  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>
      <div className="h-screen md:h-auto overflow-y-auto w-screen md:w-2/3 rounded-lg z-30 bg-white p-8 flex flex-col md:flex-row justify-center relative">
        <span
          className="absolute right-3 top-3 cursor-pointer"
          onClick={props.toggle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <div className="md:hidden mt-16">
          <img
            src={
              profile.User?.profile_photo
                ? profile.User?.profile_photo
                : " /avatar.png"
            }
            alt="photoss"
            className="border border-borderGray rounded-xl mr-6 w-96 object-cover"
            style={{ height: "200px" }}
          />
        </div>
        <div className="hidden md:block">
          <img
            src={
              profile.User?.profile_photo
                ? profile.User?.profile_photo
                : " /avatar.png"
            }
            alt="photoss"
            className="border border-borderGray rounded-xl mr-6 w-96 object-cover"
            style={{ height: "500px" }}
          />
        </div>
        <div className="w-full md:w-2/5 relative" style={{ height: "500px" }}>
          <div className="flex flex-col h-full overflow-y-auto ">
            <div className="py-1">
              <div className="flex items-start">
                {profile.is_charity === 1 ? (
                  <img src="/charity.svg" alt="charity" className="w-6 mr-4" />
                ) : null}
                <div className="flex flex-wrap">
                  {profile.Industries &&
                    profile.Industries.length > 0 &&
                    profile.Industries.map((industry) => {
                      return (
                        <p
                          className="border border-borderApp text-textApp rounded-full py-1 px-4 text-xs mr-1 mb-1"
                          style={{ fontSize: "12px" }}
                        >
                          {industry.name}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className="text-2xl font-bold text-black my-2 truncate">
                {profile.User?.first_name} {profile.User?.last_name}
              </div>
              <div className="text-sm text-textFullDark">
                {profile.job_title}
              </div>
              <div className="text-xs text-textDark sm:w-full md:w-4/5 py-4">
                {profile.User?.bio}
              </div>
              <div className={`flex items-center`}>
                <div className="flex flex-col">
                  <div className="flex items-center">
                    {profile.avgRating > 0 ? (
                      <div>
                        {profile.avgRating && <Ratings value={RatingHandler(profile.avgRating)} />}
                      </div>
                    ) : (
                      <Ratings value={0} />
                    )}
                    &nbsp;&nbsp;
                    {profile.avgRating && (
                      <p className="text-textDark">
                        {(+profile.avgRating).toFixed(1)}
                      </p>
                    )}
                  </div>
                  {profile.reviewCount > 0 ? (
                    <div>
                      {profile.reviewCount && (
                        <p className="text-xs text-textDark">
                          {profile.reviewCount} reviews
                        </p>
                      )}
                    </div>
                  ) : (
                    <p className="text-xs text-textDark">{0} reviews</p>
                  )}
                </div>
                <div
                  className="flex flex-col ml-4"
                  data-tip="Time it takes for mentor to respond to your meeting request"
                >
                  <ReactTooltip />
                  <div className="flex flex-row items-center justify-center">
                    <div>
                      <img src="/Vector.png" alt="photos" className="w-2 h-3" />
                    </div>
                    <p className="text-textDark">
                      &nbsp;{responseTime() ? responseTime() : 0}
                    </p>
                  </div>
                  <p className="text-xs text-textDark">Response time</p>
                </div>
              </div>
            </div>
            {experience.length > 0 && (
              <div className="pb-10">
                <p className="my-1 text-2xl font-bold">Experience</p>
                {experience.map((m, i) => {
                  return (
                    <Experience
                      key={i}
                      companyImage={m.profile_photo}
                      position={m.title}
                      companyName={m.company}
                      startDate={m.start_date}
                      endDate={m.end_date}
                      location={m.location}
                      description={m.description}
                      currentJob={m.current_job}
                    />
                  );
                })}
              </div>
            )}
            <div className="absolute w-full bottom-0">
              <button
                className="bg-bgAppColor py-2 font-bold text-white w-full text-center rounded-lg mt-4"
                onClick={() => {
                  if (localStorage.getItem("cp_web_token")) {
                    // dispatch({ type: "isProfilePopUp" });
                    dispatch(isProfilePopUp());
                    // dispatch({ type: "navStateAddBy1" });
                    dispatch(navStateAddBy1());
                  } else {
                    // dispatch({ type: "navStateToZero" });
                    dispatch(navStateToZero());
                    dispatch(isProfilePopUp());
                    dispatch(toggleFreeCallModal());
                    dispatch(setLogin());
                    dispatch(isFromBookingModal());
                  }
                }}
              >
                Book a session
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SetDetails = (props) => {
  let dispatch = useDispatch();
  let prof_id_from_redux = useSelector(
    (state) => state.professional.professionalIdOfModal
  );
  useEffect(() => {
    let token = localStorage.getItem("cp_web_token");
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/professionals/${prof_id_from_redux}/topics_of_discussion`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        let options = [];
        response.data.message.forEach((i) => {
          options.push({
            value: i.id,
            label: i.topic,
          });
        });
        props.setOptions(options);
      });
  });
  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <button
          className="p-4"
          onClick={() => {
            // dispatch({ type: "navStateDecBy1" });
            dispatch(navStateDecBy1());
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </button>
        <button className="p-4" onClick={props.toggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="w-full bg-white rounded p-4">
        <div className="text-textFullDark">
          <p className="sm:text-lg md:text-2xl font-bold">Enter details</p>
          <p className="text-sm mt-5 mb-2">Select a topic of conversation</p>
          <Select
            isSearchable={false}
            value={props.selectedOption}
            onChange={props.handleChange}
            options={props.options}
            placeholder="Please select topic"
          />
          <p className="text-sm mt-5 mb-2">
            What would you like to discuss in your meeting?
          </p>
          <textarea
            className="resize-none border-borderGray rounded-md text-sm border w-full h-36 py-3 px-3  focus:outline-none"
            id="bio"
            type="text"
            placeholder="Write here"
            value={props.textBody}
            maxLength={280}
            onChange={(e) => {
              props.setTextBody(e.target.value);
              props.setRemainingWords(280 - e.target.value.length);
            }}
          ></textarea>
          <p className="text-xs mt-5 mb-2">
            {props.remainingWords} words remianing
          </p>
          <div className="flex w-full mt-5">
            <button
              className={`text-white px-3 py-2 w-full rounded ${
                props.textBody !== "" && props.selectedOption !== null
                  ? "bg-bgAppColor"
                  : "bg-bgdarkGray"
              }`}
              onClick={() => {
                if (props.textBody !== "" && props.selectedOption !== null)
                  // dispatch({ type: "navStateAddBy1" });
                  dispatch(navStateAddBy1());
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Experience = (props) => {
  const calcDate = (date1, date2) => {
    let diff = Math.floor(date1.getTime() - date2.getTime()) * -1;
    let day = 1000 * 60 * 60 * 24;
    let days = Math.floor(diff / day);
    let months = Math.floor(days / 31);
    let years = Math.floor(months / 12);
    let rem = months % 12;
    let message = years + " yrs ";
    message += rem + " mos ";
    return message;
  };

  return (
    <div>
      <div className="flex flex-row py-2 border-b">
        <div>
          <div className="bg-bgAppColor sm:w-12 sm:h-12 md:w-12 md:h-12 rounded-full flex items-center justify-center">
            <img
              src={
                props.companyImage
                  ? props.companyImage
                  : "/experienceplaceholder.png"
              }
              alt=""
              className="sm:w-12 sm:h-12 md:w-12 md:h-12 rounded-full object-cover bg-blue-400"
            />
          </div>
        </div>
        <div className="flex flex-col ml-5">
          <p className="text-sm text-textFullDark font-bold">
            {props.position}
          </p>
          <p className="text-xs text-textDark">
            {props.companyName} - {props.location}
          </p>
          <div className="flex items-center">
            <p className="text-xs text-textDark">
              {" "}
              {`${moment(props.startDate).format("MMM YYYY")} - `}
            </p>
            {props.currentJob === "1" ? (
              <p className="text-xs text-textDark">&nbsp;Present</p>
            ) : (
              <p className="text-xs text-textDark">
                &nbsp;{`${moment(props.endDate).format("MMM YYYY")}`}
              </p>
            )}
            <p className="text-textDark">&nbsp;•&nbsp;</p>
            <p className="text-xs text-textDark">
              {" "}
              {props.currentJob === "1"
                ? calcDate(new Date(props.startDate), new Date())
                : calcDate(new Date(props.startDate), new Date(props.endDate))}
            </p>
          </div>

          <div className="overflow-ellipsis mt-2">
            <p className="text-xs text-textFullDark">{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
