import axios from "axios";
import React, { useState, useEffect } from "react";
import Colors from "../Colors/Colors";
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";
import { update_profile_step } from "./StepUpdated";
import { Mixpanel } from "../Mixpanel";

const SelectIndustry = (props) => {
  localStorage.setItem("navState", 6);
  const [isLoading, setLoading] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
  const [industryError, setIndustryError] = useState(false);
  const [industryErrorText, setIndustryErrorText] = useState(false);

  const getUser = () => {
    console.log(jwt_decode(localStorage.getItem("signup_token")).id);
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH +
          `/professionals/${
            jwt_decode(localStorage.getItem("signup_token")).id
          }`
      )
      .then((response) => {
        getIndustrySelected(response.data.Industries);
      })
      .catch((e) => {
        console.log("error occured");
      });
  };
  const getIndustrySelected = (dataArray) => {
    dataArray.forEach((m) => {
      if (selectedIndustry.includes(m.id)) {
        // Remove ID
        const selectedIndustryCopy = [...selectedIndustry];
        const itemIndex = selectedIndustryCopy.indexOf(m.id);
        selectedIndustryCopy.splice(itemIndex, 1);
        setSelectedIndustry(selectedIndustryCopy);
      } else {
        // ADD ID
        setSelectedIndustry((selectedIndustry) => [...selectedIndustry, m.id]);
      }
    });
  };
  useEffect(() => {
    getUser();
    getAllIndustries();
  }, []);
  const getAllIndustries = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/industry`)
      .then((response) => {
        console.log(response.data);
        setIndustry(response.data);
      })
      .catch((e) => {
        console.log("error in industry api");
      });
  };
  useEffect(() => {
    getAllIndustries();
  }, []);

  const addIndustry = (id) => {
    let data = {
      user_id: jwt_decode(localStorage.getItem("signup_token")).id,
      industry_id: id,
    };
    axios
      .post(
        `${process.env.REACT_APP_SERVER_PATH}/professionals_industries`,
        data
      )
      .then((response) => {
        console.log(response);
        Mixpanel.track(`web: Add Industry.`, data);
        console.log("Industry Added");
      })
      .catch((e) => {
        console.log("error in industry added");
      });
  };

  const removeIndustry = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_PATH}/professional/${
          jwt_decode(localStorage.getItem("signup_token")).id
        }/industry/${id}`
      )
      .then((response) => {
        console.log(response);
        Mixpanel.track(`web: Remove Industry.`, { industry_id: id });
        console.log("Industry deleted");
      })
      .catch((e) => {
        console.log("error in industry deleted");
      });
  };
  const handleIndustry = (id, name) => {
    if (selectedIndustry.includes(id)) {
      // Remove ID
      const selectedIndustryCopy = [...selectedIndustry];
      const itemIndex = selectedIndustryCopy.indexOf(id);
      selectedIndustryCopy.splice(itemIndex, 1);
      setSelectedIndustry(selectedIndustryCopy);
      removeIndustry(id);
    } else {
      // Add ID
      // selectedIndustry.push(id)
      setSelectedIndustry([...selectedIndustry, id]);
      addIndustry(id);
    }
  };
  const handleSubIndustry = (id, name) => {
    if (selectedSubIndustry.includes(id)) {
      // Remove Sub ID
      const selectedIndustryCopy = [...selectedSubIndustry];
      const itemIndex = selectedIndustryCopy.indexOf(id);
      selectedIndustryCopy.splice(itemIndex, 1);
      setSelectedSubIndustry(selectedIndustryCopy);
      removeIndustry(id);
    } else {
      // Add Sub ID
      setSelectedSubIndustry([...selectedSubIndustry, id]);
      addIndustry(id);
    }
  };
  return (
    <div className="flex flex-col justify-center items-center text-textFullDark w-full">
      <img src="/selectIndustry.png" alt="addVideo" className="w-36" />
      <p className="text-2xl font-bold mt-3 text-textFullDark">
        Select your Industry
      </p>
      <p className="text-xs mt-3 text-center">
        Let mentees know in which industry you are most experienced. Select all
        that apply
      </p>
      <div className="w-full bg-bgIndustryColor border border-borderGray rounded-lg p-4 flex flex-wrap mt-4">
        {industry.map((m) => {
          return (
            <>
              {" "}
              <button
                className={`flex items-center border border-borderGray rounded-full m-1 py-1 px-3 text-sm`}
                style={{
                  backgroundColor: selectedIndustry.includes(m.id)
                    ? Colors.bgBlue
                    : Colors.bgGray,
                  color: selectedIndustry.includes(m.id) ? "white" : "black",
                  borderColor: selectedIndustry.includes(m.id)
                    ? Colors.bgBlue
                    : Colors.bgGray,
                }}
                onClick={() => {
                  if (selectedIndustry.length === 3) {
                    if (selectedIndustry.includes(m.id)) {
                      setIndustryError(false);
                      handleIndustry(m.id, m.name);
                    } else {
                      setIndustryErrorText(
                        "You can not select more than 3 main industries"
                      );
                      setIndustryError(true);
                    }
                  } else {
                    handleIndustry(m.id, m.name);
                  }
                }}
              >
                {m.name}
              </button>
              {selectedIndustry.includes(m.id) &&
                m.sub.length > 0 &&
                m.sub.map((s) => {
                  return (
                    <button
                      className={`flex items-center border border-borderGray rounded-full m-1 py-1 px-3 text-sm`}
                      style={{
                        backgroundColor: selectedSubIndustry.includes(s.id)
                          ? Colors.bgDarkBlue
                          : "white",
                        color: selectedSubIndustry.includes(s.id)
                          ? "#383838"
                          : Colors.bgBlue,
                        borderColor: selectedSubIndustry.includes(s.id)
                          ? Colors.bgDarkBlue
                          : Colors.bgBlue,
                      }}
                      onClick={() => {
                        handleSubIndustry(s.id, s.name);
                      }}
                    >
                      {s.name}
                    </button>
                  );
                })}
            </>
          );
        })}
      </div>
      {industryError && (
        <p className="text-left text-red-500 font-bold text-sm mt-2 w-full">
          {industryErrorText}
        </p>
      )}
      <div className="w-full flex justify-end items-center mt-4 text-white">
        <button
          className="py-2 px-8 mr-auto rounded-lg flex"
          style={{
            backgroundColor:
              selectedIndustry.length > 0 ? Colors.blue : Colors.blue,
            color: selectedIndustry.length > 0 ? "white" : "white",
          }}
          onClick={() => {
            props.setNavState(props.navState - 1);
            setLoading(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <p>Back</p>
        </button>
        <button
          className="py-2 px-8 rounded-lg flex"
          style={{
            backgroundColor:
              selectedIndustry.length > 0 ? Colors.blue : Colors.gray,
            color: selectedIndustry.length > 0 ? "white" : "gray",
          }}
          onClick={() => {
            if (selectedIndustry.length > 0) {
              update_profile_step(
                localStorage.getItem("signup_token"),
                jwt_decode(localStorage.getItem("signup_token")).id,
                "7"
              );
              props.setNavState(props.navState + 1);
              setLoading(false);
            }
          }}
        >
          <p>
            Next{" "}
            {isLoading ? (
              <span className="inline-block ml-4">
                <Loader
                  type="TailSpin"
                  color="white"
                  height={20}
                  width={20}
                  // timeout={5000} //5 secs
                />
              </span>
            ) : null}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SelectIndustry;
