import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import CompanyMain from './CompanyMain'

const Company = () => {
    return (
        <div>
            <Navbar />
            <hr />
            <CompanyMain />
            <Footer />
        </div>
    )
}

export default Company