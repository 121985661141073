import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import FaqMain from './FaqMain'

const FAQs = () => {
    window.scroll(0, 0)
    return (
        <div>
            <Navbar />
            <hr />
            <FaqMain />
            <Footer />
        </div>
    )
}

export default FAQs