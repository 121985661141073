import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import Colors from "../../../Colors/Colors";
import axios from 'axios';
import { Mixpanel } from '../../../Mixpanel';
import { CompanyTitle } from "../../../settings";


export default function CheckoutForm(props) {

// const options = {
//   style: {
//     base: {
//       color: "#32325d",
//       fontFamily: "Arial, sans-serif",
//       fontSize: "16px",
//     },
//   },
// };


  const url_id = props.id;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [coupenName] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [aftercal, setAftercal] = useState(props.propslocationprice);
  //  console error remove function setSubtotal remove the setSubtotal state
  const [subtotal, setSubtotal] = useState(props.propslocationprice);
  //
  // console error remove function setUserId remove the setUserId state
  const [userId, setUserId] = useState(localStorage.getItem("id"));
  // console error remove stripeError variable remove the stripeError variable state
  const [stripeError,setStripeError] = useState(false);
  const [referalInvit, setReferalInvit] = useState({
    status: true,
    message: "",
  });
  const [flag, setFlag] = useState(0);

  const referalCal = () => {
    let x = aftercal / 100;
    let y = x * 10;
    let c = aftercal - y;
    c = parseFloat(c).toFixed(2);
    setAftercal(c);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/isUserMakeFirstBooking/${userId}`
      )
      .then((response) => {
        setReferalInvit({
          status: response.data.status,
          message: response.data.message,
        });
        setFlag(1);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, []);

  if (flag === 1) {
    if (referalInvit.status === false && subtotal >= 20) {
      referalCal();
      setFlag(0);
    }
  }

  useEffect(async () => {
    let token = localStorage.getItem("cp_web_token");
    Mixpanel.track(`web: Booking Created Successfully`, props.bookingData);
    await axios.post(
      `${process.env.REACT_APP_SERVER_PATH}/professionals/${url_id}/request_call`,
      props.bookingData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "GBP",
        total: {
          label: `${CompanyTitle} Meeting`,
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [coupenName]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   // return_url: "http://localhost:3000/booking-confirm",
      //   // return_url:'if_required'
      // },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setStripeError(true);
      setMessage(error.message);
    } else {
      // setMessage("An unexpected error occured.");
      props.activeStep4();
    }
  };

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <h1 className="text-lg md:text-2xl font-bold mb-4">
          {" "}
          Add payment Information{" "}
        </h1>
        <PaymentElement id="payment-element"  />

        <div className="flex items-center justify-between sm:mt-3 md:mt-10">
          <button
            className="text-center text-lg py-2 px-4 w-full bg-blue-400 rounded text-white hover:text-2xl  hover:bg-blue-500"
            type="submit"
            style={{ backgroundColor: Colors.blue }}
          >
            {"Submit request"}
          </button>
        </div>
      </form>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message">
          <p className="text-red-500 font-bold mt-2">{message}</p>
        </div>
      )}
    </div>
  );
}