import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import AboutUsMain from './AboutUsMain'

const AboutUs = () => {
    window.scroll(0, 0)
    return (
        <div>
            <Navbar />
            <hr />
            <AboutUsMain />
            <Footer />
        </div>
    )
}

export default AboutUs