import { useEffect, useRef, useState } from 'react';
import Ratings from '../Rating/Ratings';
import { ReviewAll } from '../Profile/Reviews/Reviews';
import { toggleReviews } from '../Redux/reducers/review/reviewSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Guy from "../Images/Guy.png"
import logo1 from "../Images/logo1.png"
import logo2 from "../Images/logo2.png"
import PEB from "../Images/pebImg.png"
import fb from "../Images/socialFb.png"
import linkdin from "../Images/socialLink.png"
import twitter from "../Images/socialTweet.png"
import whatsapp from "../Images/socialWhatsapp.png"
import tele from "../Images/socialTele.png"
import copy from "../Images/copy.png"
import { Link, useNavigate } from 'react-router-dom';
import "./PEB.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PE from './PE.module.css';
import Reviews from '../Courses/components/Reviews';
const Hero = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const show = useSelector((state) => state.review.showReviews);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenClick, setScreenClick] = useState(false);
  const modalRef = useRef();
  const [copiedLabel, setCopiedLabel] = useState("");
  const ref = useRef();
  console.log(process.env.REACT_APP_SOCKET_SERVER_PATH);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
      
    };

    if (isModalOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isModalOpen]);

   useEffect(() => {
  window.scroll(0, 0);
   }, []);
   
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [reviewerData] = useState([
    {
      id: 430,
      rating: 5,
      feedback:
        "The course helped me better understand my strengths and weaknesses, and gave me the confidence to quit my job and start a career in Private Equity",
      reviewer: {
        id: 346,
        first_name: "Omegie A.",
      },
    },
    {
      id: 430,
      rating: 5,
      feedback:
        "Guy was engaging and very knowledgable about Private Equity. He helped me understand the basics and gave me more confidence to move into Private Equity.",
      reviewer: {
        id: 346,
        first_name: "Selim K.",
      },
    },
    {
      id: 430,
      rating: 5,
      feedback:
        "It's important for me to learn as much as I can. This course is another great asset for my knowledge bank!",
      reviewer: {
        id: 346,
        first_name: "Sophia B.",
      },
    },
    {
      id: 430,
      rating: 5,
      feedback:
        "I have researched across many platforms, you get a lot for your money through this course!",
      reviewer: {
        id: 346,
        first_name: "Mickey N.",
      },
    },
    {
      id: 430,
      rating: 5,
      feedback:
        "The course gave me hope and confidence for the future. I've also made some great connections through CareerPaths online learning community",
      reviewer: {
        id: 346,
        first_name: "Eddie R.",
      },
    },
  ]);

  const handleShare = () => {
    setIsModalOpen(true);
  };
  const handleScreenClick = () => {
    setIsModalOpen(false);
  };

  const handleBuyNow = () => {
    navigate("/checkout");
  };

  const copyToClipboard = () => {
    try {
      const textArea = document.createElement("textarea");
      const codeToCopy =
        process.env.REACT_APP_BASE_APP_URL + "private-equity-bundle";
      textArea.value = codeToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setCopiedLabel(codeToCopy);
      toast("Copied!");
    } catch (error) {
      console.error(error);
      alert("Copy failed");
    }
  };

  const shareOnFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        process.env.REACT_APP_BASE_APP_URL + "private-equity-bundle"
      )}`,
      "_blank"
    );
  };

  const shareOnLinkedIn = () => {
    const textToShare =
      "Check out this Private Equity Bundle from CareerPaths. It will set you up for a great start in your Private Equity career!";
    const urlToShare =
      process.env.REACT_APP_BASE_APP_URL + "private-equity-bundle";
    const shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      urlToShare
    )}&summary=${encodeURIComponent(textToShare)}`;
    window.open(shareUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const content =
      "Check out this Private Equity Bundle from CareerPaths. It will set you up for a great start in your Private Equity career!";
    const text = encodeURIComponent(content);
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        process.env.REACT_APP_BASE_APP_URL + "private-equity-bundle"
      )}&text=${text}`,
      "_blank"
    );
  };

  const shareOnWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `Check out this Private Equity Bundle from CareerPaths. It will set you up for a great start in your Private Equity career! ${
          process.env.REACT_APP_BASE_APP_URL + "private-equity-bundle"
        }`
      )}`,
      "_blank"
    );
  };

  const shareOnTelegram = () => {
    const textToShare =
      "Check out this Private Equity Bundle from CareerPaths. It will set you up for a great start in your Private Equity career!"; // Replace this with the text you want to share
    const urlToShare =
      process.env.REACT_APP_BASE_APP_URL + "private-equity-bundle";
    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      urlToShare
    )}&text=${encodeURIComponent(textToShare)}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <>
      <ToastContainer />
      <div className="xl:container mx-auto lg:flex-row px-2  mt-2 md:mt-6">
        <div
          className="rounded-3xl flex flex-col md:flex-row w-full items-center md:items-start lg:items-end py-8 md:py-10"
          style={{
            backgroundImage: "url(/events/hero-background.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <div className="w-full md:w-9/12 flex flex-col items-start px-6  md:py-0 md:px-20 ">
            <div
              className="text-black text-3xl md:text-4xl lg:text-5xl "
              // style={{ fontFamily: "Cerebri Extra Bold" }}
              style={{ fontFamily: "Lato Extra Bold" }}
            >
              <h1 className="text-left">PRIVATE EQUITY BUNDLE</h1>
            </div>
            <p className="w-full md:w-8/12 mt-2">
              Develop practical skills and insights into the world
              of Private Equity
            </p>

            <div className="w-full flex lg:flex-row sm:flex-col gap-4 px-5 sm:px-0 lg:px-5  py-7 ">
              <div>
                <img src={Guy} className="rounded-full w-1001 h-1001" />
              </div>
              <div className="flex flex-col">
                <div className="flex sm:flex-col lg:flex-row">
                  <p
                    className="text-lg mr-6"
                    // style={{ fontWeight: "Cerebri Extra Bold" }}
                    style={{ fontFamily: "Lato Extra Bold" }}
                  >
                    Content Author: Guy Ellis
                  </p>
                  <div className="flex flex-row">
                    <div
                      className="items-center mt-1 cursor-pointer justify-center"
                      onClick={handleClick}
                    >
                      <Ratings
                        value={5}
                        size="small"
                        activeColor="#ffd700"
                        isHalf={true}
                        edit={false}
                        x
                      />
                    </div>
                    <p
                      className="text-gray-700 text-lg mr-2 sm:text-sm lg:text-lg sm:mt-1 cursor-pointer lg:mt-0"
                      onClick={handleClick}
                    >
                      5.0
                    </p>
                    <p
                      className="text-gray-700 text-lg sm:text-sm lg:text-lg sm:mt-1 lg:mt-0 cursor-pointer"
                      onClick={handleClick}
                    >
                      <u>32 reviews</u>
                    </p>
                  </div>
                </div>
                <div className="flex flex-row">
                  <h1 className="text-xl mt-2 font-bold text-bold">
                    Partner at Rockpool Investments
                  </h1>
                </div>
                <div className="flex flex-row">
                  <p className="text-md text-gray-500 mt-2 font-bold text-bold">
                    Ex-Goldman Sachs European Private Equity Arm
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="text-md text-gray-500 font-bold text-bold">
                    Board Observer to multiple mid-market companies
                  </p>
                </div>
                <div className="flex mt-6 gap-3 sm:flex-col lg:flex-row">
                  <p className="flex items-center justify-center px-4 h-8 bg-white text-gray-800 text-sm font-medium rounded-xl">
                    <img src={logo1} className="w-4 h-4 mr-1" alt="Logo 1" />
                    <span className="align-middle">
                      Studied at University of Nottingham
                    </span>
                  </p>
                  <p className="flex items-center justify-center px-3 h-8 bg-white text-gray-800 text-sm font-medium rounded-xl">
                    <img
                      src={logo2}
                      className="h-3 w-4 mr-1 sm:ml-1.5 lg:ml-0"
                      alt="Logo 2"
                    />
                    <span className="align-middle mt-0.5">
                      Works at Rockpool
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-1 lg:grid-cols-3 gap-5 py-5 px-0 text-justify">
          <div className="col-span-2 ml-20 sm:ml-0 lg:ml-20 sm:col-span-1  lg:col-span-2 rounded-xl border w-5/6 sm:w-full md:w-5/6 lg:w-5/6  border-gray-300 p-7 shadow-xl">
            <h1
              className="text-xl font-bold"
              // style={{ fontFamily: "Cerebri Extra Bold" }}
              style={{ fontFamily: "Lato Extra Bold" }}
            >
              CareerPaths - Private Equity Bundle
            </h1>
            <div className="py-4 leading-7 text-sm">
              <p>
                Access everything you need to get into Private Equity, including
                exclusive video content, mentoring discounts, and more.
              </p>
              <p>
                Built by industry experienced professionals, our Private Equity
                Bundle is the perfect introductory package to Private Equity.
                Benefit from a range of products which will help improve the
                core and technical competencies needed to begin a successful
                career in Private Equity.
              </p>
            </div>
            <h1
              className="text-xl font-bold mt-2"
              // style={{ fontFamily: "Cerebri Extra Bold" }}
              style={{ fontFamily: "Lato Extra Bold" }}
            >
              What you'll receive as part of the bundle...
            </h1>
            <ol className={`list-decimal text-sm p-4 leading-7`}>
              <li>
                <>
                  <span className="list-marker">
                    <b style={{ fontFamily: "Lato Bold" }}>
                      A 45 minute 1:1 mentor session with a Private Equity
                      Partner{" "}
                    </b>
                    who can help you understand how to break into the industry
                    or the steps you need to take to accelerate your progress.
                    You'll also have the choice to continue your mentor sessions
                    with the Private Equity Partner after the initial session.
                  </span>
                </>
              </li>
              <li className="mt-2">
                <>
                  <span className="list-marker">
                    Training material designed to{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>
                      teach you the fundamentals of Private Equity.
                    </b>{" "}
                    Training material will help you:
                  </span>
                </>
                <ol className="pl-5  list-disc leading-7 text-sm">
                  <li>
                    Build an understanding of what Private Equity is, how funds
                    make investments and the private equity fund landscape
                  </li>
                  <li>
                    Understand how Private Equity funds originate investments,
                    value targets, structure a transaction and execute on a deal
                  </li>
                  <li>
                    How Private Equity funds generate returns and the levers
                    that deliver market leading returns
                  </li>
                  <li>
                    Working in Private Equity, including the investment process,
                    a day in the life of an Analyst, glossary of terms and how
                    incentive schemes work
                  </li>
                  <li>
                    Further reading - a list of the best Private Equity books
                    you can read
                  </li>
                </ol>
              </li>
              <li className="mt-2.5">
                <>
                  <span className="list-marker">
                    A{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>
                      bank of Private Equity interview questions
                    </b>{" "}
                    answers designed to give you a flavour of what to expect in
                    the toughest recruitment rounds.{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>
                      Developed by ex-Goldman Sachs
                    </b>{" "}
                    Private Equity Investors
                  </span>
                </>
              </li>
              <li className="mt-2">
                <>
                  <span className="list-marker">
                    Private Equity case studies are an important part of the
                    private equity recruiting process because they allow firms
                    to evaluate a candidate’s analytical, investing, and
                    presentation abilities. Our{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>
                      10 sample case studies
                    </b>{" "}
                    provide a broad range of examples with accompanying advice
                    on how to prepare for them. Our content will help you ace
                    your next Private Equity case study, whether you’re a
                    seasoned analyst or new to the field.
                  </span>
                </>
              </li>
              <li className="mt-2">
                <>
                  <span className="list-marker">
                    Our{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>LBO Excel model </b>
                    has been designed to help you learn how to build a Leveraged
                    Buyout step by step, preparing you for LBO case studies as
                    part of the Private Equity interview process. Our modelling
                    tool has been used by both mid-market investors and large
                    leverage buyout fund training in-house training programs.
                  </span>
                </>
              </li>
              <li className="mt-2">
                <>
                  <span className="list-marker">
                    Our{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>
                      bank of video interviews with Private Equity professionals
                      is an industry first.
                    </b>{" "}
                    Watch as we interview and learn from the experience of a
                    whole range of Private Equity professionals on how to enter
                    and succeed in the industry.
                  </span>
                </>
              </li>
              <li className="mt-2">
                <>
                  <span className="list-marker">
                    For a limited time only we're offering{" "}
                    <b style={{ fontFamily: "Lato Bold" }}>
                      10% off all mentoring sessions
                    </b>{" "}
                    if you purchase the Private Equity Bundle. Choose from
                    mentors from the most prestigious Private Equity firms
                    including KKR, Bain and Blackstone. Don't miss your chance
                    to speak to these top professionals and learn their career
                    development secrets.
                  </span>
                </>
              </li>
            </ol>
          </div>
          <div className="col-span-1 border bg-white sm:h-1002 lg:h-1002 2xl:h-1003 border-gray-100 rounded-3xl  p-3 right-0 w-1/4 left-auto bottom-0 sm:w-full md:w-1/4 lg:w-1/4 sm:block lg:absolute responsive-large-screen">
            <img src={PEB} className="keep-in-center" />
            <div className="flex flex-row mt-4">
              <h1
                className="text-4xl font-bold"
                // style={{ fontFamily: "Cerebri Extra Bold" }}
                style={{ fontFamily: "Lato Extra Bold" }}
              >
                £15
              </h1>
              <p className="text-sm mt-4">/month</p>
            </div>
            <div className="flex flex-row">
              <p className="text-sm" style={{ color: "#716F6F" }}>
                £180 Billed annually
              </p>
            </div>
            <button
              class="inline-flex items-center mt-4 text-white justify-center px-3 w-full py-3 bg-bgAppColor  hover:bg-cyan text-lg font-bold rounded-lg"
              onClick={handleBuyNow}
            >
              Pre-order now
            </button>
            <p className="mt-10">The package includes:</p>
            <ol class="list-decimal text-sm leading-loose pl-5">
              <li>
                <span className="list-marker">
                  1:1 mentor session with PE professional (45 min)
                </span>
              </li>
              <li>Private Equity 101 Training Pack</li>
              <li>50+ Private Equity interview questions </li>
              <li>10+ Private Equity practice case studies </li>
              <li>How to build an LBO Model (Excel) </li>
              <li>
                <span>Exclusive video interviews with PE professionals </span>{" "}
              </li>
              <li>10% off all mentor sessions</li>
            </ol>
            <div className="flex flex-row p-6">
              <p
                className="underline font-bold mr-6 cursor-pointer"
                style={{ fontFamily:  'Lato sans-serif' }}
                onClick={handleShare}
              >
                Share
              </p>
            </div>
            <hr className="text-black w-5/6 mx-auto" />
            <a
              href="mailto:team@careerpaths.io"
              class="inline-flex items-center mt-4 text-black border border-black justify-center px-3 w-full py-3 bg-white  hover:bg-bgAppColor text-sm font-bold rounded-lg cursor-pointer"
              style={{ fontWeight: "150px" }}
            >
              Have a question? Message us
            </a>
          </div>
        </div>
        {isModalOpen && (
          <div
            className={`fixed inset-0 flex items-center justify-center bg-black z-50 bg-opacity-80 w-full`}
          >
            <div
              ref={modalRef}
              className="backgroundModal shadow-lg px-6 py-8 pb-10 sm:w-5/6 lg:w-2/5"
              style={{ borderRadius: "25px" }}
            >
              <h2 className="text-lg font-semibold mb-1 text-black">
                Share this link via
              </h2>
              <div className="flex flex-row gap-4">
                <img
                  src={fb}
                  className="w-5 h-5 cursor-pointer"
                  onClick={shareOnFacebook}
                />
                <img
                  src={linkdin}
                  className="w-5 h-5 cursor-pointer"
                  onClick={shareOnLinkedIn}
                />
                <img
                  src={twitter}
                  className="w-5 h-5 cursor-pointer"
                  onClick={shareOnTwitter}
                />
                <img
                  src={whatsapp}
                  className="w-5 h-5 cursor-pointer"
                  onClick={shareOnWhatsApp}
                />
                <img
                  src={tele}
                  className="w-5 h-5 cursor-pointer"
                  onClick={shareOnTelegram}
                />
              </div>
              <p className="mt-7 text-sm">Or copy link:</p>
              <div className="relative mt-2">
                <div className="flex items-center border border-black sm:w-full lg:w-5/6  h-12 rounded-lg pl-3 text-white">
                  <div className="flex items-center flex-1">
                    <img src={copy} className="w-6 h-6" />
                    <p className="ml-4 text-xs text-black sm:hidden lg:block">
                      {process.env.REACT_APP_BASE_APP_URL}private-equity-bundle
                    </p>
                    <p className="ml-4 text-xs text-black sm:block lg:hidden">
                      https://www.care....
                    </p>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={copyToClipboard}
                      className="relative inline-flex bg-bgAppColor py-1 rounded-lg px-4 items-center sm:mr-4 lg:mr-5 cursor-pointer"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="reviews" ref={ref}>
        <Reviews />
      </div>
    </>
  );
}

export default Hero