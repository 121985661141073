import React, { useEffect } from 'react'
import db from './firebase'
import { collection, addDoc, onSnapshot, query, orderBy } from 'firebase/firestore'
import { useState } from 'react'


const FirebaseComponent = () => {
    const [messages, setMessages] = useState([])
    let dbRef = collection(db, 'messages', '2519', 'logs')
    const q = query(dbRef, orderBy('createdAt'))
    useEffect(() => {
        onSnapshot(q, (snapShot) => {
            let messagesCopy = []
            snapShot.docs.map((doc) => {
                messagesCopy.push(doc.data())
            })
            setMessages(messagesCopy)
        })
    }, [])

    const addMessage = () => {
        addDoc(dbRef, {
            action: 'MESSAGE',
            action_data: 'Abdul Majid',
            booking_id: 2519,
            professional_id: 734,
            user_id: 733,
            pending: false,
            sent: true,
            received: false,
            isRead: false,
            createdAt: new Date(),
            user: {
                _id: 733,
                name: `user Majid`,
                avatar: 'https://thumbs.dreamstime.com/b/rainbow-love-heart-background-red-wood-60045149.jpg',
            },
        }).then(() => {
            console.log('success')
        }).catch(e => {
            console.log('error')
        })
    }
    return (
        <div>message{
            messages.length > 0 ? messages.map((m) => {
                return <p>{m?.action_data}</p>
            }) : 'no messages'

        }
            <button onClick={addMessage}>add</button>
        </div>
    )
}

export default FirebaseComponent