import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Colors from '../Colors/Colors'
import jwt_decode from 'jwt-decode'
import Loader from "react-loader-spinner";
import { update_profile_step } from './StepUpdated';
import { Mixpanel } from '../Mixpanel';

const ProfessionalStatus = (props) => {
    localStorage.setItem("navState", 2)
    const [isLoading, setLoading] = useState(false)
    const [jobTitle, setJobTitle] = useState('')
    const [bio, setBio] = useState('')
   const [message, setmessage] = useState(false);


    const getUser = () => {
        axios.get(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`)
            .then((response) => {
                if (response.data.User.bio != null) {
                    setBio(response.data.User.bio)
                    setJobTitle(response.data.job_title);
                }
            }).catch(e => {
                console.log("error occured");
            })
    }
    useEffect(() => {
        setTimeout(() => { getUser() }, 500)
    }
        , [])



    const upDateUser = () => {
        setmessage(true);
        if (jobTitle !== '' && bio.length >= 100) {
            setLoading(true)
            axios.put(process.env.REACT_APP_SERVER_PATH + `/users/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                bio: bio
            }).then((r) => {
                console.log(r);

                axios.put(process.env.REACT_APP_SERVER_PATH + `/professionals/${jwt_decode(localStorage.getItem("signup_token")).id}`, {
                    job_title: jobTitle
                }).then((r) => {
                    console.log(r);
                    Mixpanel.track(`web: Add Bio.`, { 'bio': bio });
                    update_profile_step(localStorage.getItem('signup_token'), jwt_decode(localStorage.getItem("signup_token")).id, "2")
                    props.setNavState(props.navState + 1)
                }).catch(e => {
                    console.log('Error');
                })
            }).catch(e => {
                console.log('Error');
            })
        }
    }
    return (
      <div className="flex flex-col justify-center items-center text-textFullDark">
        <img
          src="/professionalStatus.png"
          alt="professionalStatus"
          className="w-36"
        />
        <p className="text-2xl font-bold mt-3 text-textFullDark">Bio</p>
        <p className="text-xs mt-3 text-center">
          Tell us a bit about yourself and how others can benefit from your
          insight.
           {/* Remember to keep it short and sweet!{" "} */}
        </p>
        <div className="w-full flex mt-8 text-xs font-bold">
          <div className="w-full">
            <label className="block text-textFullDark  mb-2" for="jobTitle">
              Job Title
            </label>
            <input
              className="text-sm border rounded-lg w-full border-borderGray py-2 px-3 text-textFullDark  focus:outline-none"
              id="jobTitle"
              type="text"
              placeholder="Job Title"
              value={jobTitle}
              onChange={(e) => {
                setJobTitle(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="my-4 w-full text-xs font-bold">
          <label className="block text-textFullDark  mb-2" for="bio">
            Bio (1200 characters maximum)
          </label>
          <textarea
            maxLength={1200}
            className="resize-none border border-borderGray rounded-md w-full h-24 py-3 px-3 text-textFullDark  focus:outline-none"
            id="bio"
            type="text"
            placeholder="Bio"
            value={bio}
            onChange={(e) => {
              setBio(e.target.value);
            }}
          ></textarea>
          {bio.length < 100 && (
            <>
              <p
                className="text-xs text-red-400"
                style={{ marginRight: "auto" }}
              >
                {message && "Input must be at least 100 characters long"}
                {/* Input must be at least 100 characters long */}
              </p>
              <p className="text-sm text-green-500 mt-5">
                {bio.length} / 1200 characters
              </p>
            </>
          )}
          {bio.length > 100 && bio.length <= 1200 && (
            <>
              {/* <p
                className="text-xs text-red-400"
                style={{ marginRight: "auto" }}
              >
                {message && " Input must be at most 1200 characters long"}
               
              </p> */}
              <p className="text-sm text-green-500 mt-5">
                {bio.length} / 1200 characters
              </p>
            </>
          )}
          {bio.length >= 1200 && (
            <p className="text-sm text-red-500">
              {message && " Max characters length reached"}
            
            </p>
          )}
        </div>
        <div className="w-full flex justify-end items-center mt-4 text-white">
          <button
            className="py-2  px-8 rounded-lg flex"
            style={{
              backgroundColor: Colors.blue,
              color: "white",
            }}
            onClick={upDateUser}
            // disabled={bio.length < 100}
          >
            <p>
              Next{" "}
              {isLoading ? (
                <span className="inline-block ml-4">
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                    // timeout={5000} //5 secs
                  />
                </span>
              ) : null}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>
        </div>
      </div>
    );
}

export default ProfessionalStatus
