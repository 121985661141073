import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../../Colors/Colors";
import Form from "../Form/Form";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Mixpanel } from "../../../../Mixpanel";
import ProfileInterest from "../ProfileInterests/ProfileInterest";

const ProfileBio = ({ user }) => {
  const [message, setmessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [profileVideoUrl, setProfileVideoUrl] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhonenumber, setPhonenumber] = useState("");
  const [userJobTitle, setUserJobTitle] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLong] = useState(null);
  const [userSession15, setSession15] = useState("");
  const [userSession30, setSession30] = useState("");
  const [userBio, setBio] = useState("");
  const [imgUrl, setImageUrl] = useState("");
  const [showPicError, setShowPicError] = useState(false);
  const [fileInput, setFileInput] = useState();
  const [fileInputVideo, setFileInputVideo] = useState();
  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [type, setType] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [industryError, setIndustryError] = useState(false);
  const [industryErrorText, setIndustryErrorText] = useState(false);
  const errorSectionRef = useRef(null);

  // errors
  const [userSession15Error, setUserSession15Error] = useState(false);
  const [userSession30Error, setUserSession30Error] = useState(false);

  const scrollToError = () => {
    if (errorSectionRef.current) {
      errorSectionRef.current.scrollIntoView({
        behavior: "smooth", // You can use 'auto' for instant scrolling
      });
    }
  };

  const specialty = useRef(null);

  const validation = () => {
    if (type === "professional") {
      if (userSession15 < 5) {
        setUserSession15Error(true);
        setmessage(true);
        setLoading(false);
        return 0;
      } else {
        setUserSession15Error(false);
      }

      if (userSession30 < 5) {
        setUserSession30Error(true);
        setLoading(false);
        return 0;
      } else {
        setUserSession30Error(false);
      }
    }
    return 1;
  };
  const textvalidation = () => {
    if (userBio?.length < 100) {
      setmessage(true);

      return 0;
    } else {
      setmessage(false);
    }
    if (userBio?.length < 100 && userBio?.length > 1200) {
      setmessage(true);
      return 0;
    } else {
      setmessage(false);
    }
    if (userBio?.length >= 1200) {
      setmessage(true);
      return 0;
    } else {
      setmessage(false);
    }
    return 1;
  };
  const saveData = () => {
    setmessage(true);
    setLoading(false);

    scrollToError();

    if (!textvalidation()) {
      return;
    }
    if (!validation()) {
      return;
    }

    let dataProfessional = {
      job_title: userJobTitle,
      location: userLocation,
      usermessage: message,
      session_price_15_min: userSession15,
      session_price_30_min: userSession30,
      phone: userPhonenumber,
    };
    let dataUser = {
      first_name: userFirstName,
      last_name: userLastName,
      phone: userPhonenumber,
      bio: userBio,
      lat: lat,
      lng: lng,
    };

    let dataCustomer = {
      location: userLocation,
    };

    // user.user_id instead of +localStorage.getItem('id') main point
    if (type === "professional") {
      axios
        .put(
          `${
            process.env.REACT_APP_SERVER_PATH
          }/professionals/${+localStorage.getItem("id")}`,
          dataProfessional
        )
        .then((response) => {
          axios
            .put(
              `${
                process.env.REACT_APP_SERVER_PATH
              }/users/${+localStorage.getItem("id")}`,
              dataUser
            )
            .then((response) => {
              Mixpanel.track(`web: Update profile Details.`, {
                ...dataProfessional,
                ...dataUser,
              });
              setLoading(false);
              toast("Data Updated Successfully!");
              window.location.reload(false);
            })
            .catch((e) => {
              console.log("error");
            });
        })
        .catch((e) => {
          console.log("error");
        });
    } else if (type === "customer") {
      axios
        .put(
          `${
            process.env.REACT_APP_SERVER_PATH
          }/customers/${+localStorage.getItem("id")}`,
          dataCustomer
        )
        .then((response) => {
          axios
            .put(
              `${
                process.env.REACT_APP_SERVER_PATH
              }/users/${+localStorage.getItem("id")}`,
              dataUser
            )
            .then((response) => {
              Mixpanel.track(`web: Update profile Details.`, {
                ...dataCustomer,
                ...dataUser,
              });
              setLoading(false);
              toast("Data Updated Successfully!");
              window.location.reload(false);
            })
            .catch((e) => {
              console.log("error");
            });
        })
        .catch((e) => {
          console.log("error");
        });
    }
  };
  const getIndustrySelected = (dataArray) => {
    dataArray.forEach((m) => {
      if (selectedIndustry.includes(m.id)) {
        // Remove ID
        const selectedIndustryCopy = [...selectedIndustry];
        const itemIndex = selectedIndustryCopy.indexOf(m.id);
        selectedIndustryCopy.splice(itemIndex, 1);
        setSelectedIndustry(selectedIndustryCopy);
      } else {
        // ADD ID
        setSelectedIndustry((selectedIndustry) => [...selectedIndustry, m.id]);
      }
    });
  };
  const getUser = () => {
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH +
          `/users/${+localStorage.getItem("id")}`
      )
      .then((response) => {
        if (response.data.type === "professional") {
          setType("professional");
          axios
            .get(
              process.env.REACT_APP_SERVER_PATH +
                `/professionals/${+localStorage.getItem("id")}`
            )
            .then((response) => {
              getIndustrySelected(response.data.Industries);
              setUserFirstName(response.data.User?.first_name);
              setUserLastName(response.data.User?.last_name);
              setPhonenumber(response?.data?.User?.phone);

              setUserJobTitle(response.data.job_title);
              setUserLocation(response.data.location);
              setLat(response.data.User?.lat);
              setLong(response.data.User?.lng);
              setSession15(response.data.session_price_15_min);
              setSession30(response.data.session_price_30_min);
              setBio(response.data.User?.bio);
              setProfileVideoUrl(response.data.prof_intro_link);
              if (response.data.User.profile_photo !== null) {
                setImageUrl(response.data.User.profile_photo);
                console.log(response.data.User.lat);
              } else {
                setImageUrl(" /avatar.png");
              }
            })
            .catch((e) => {
              console.log("Error in Professional Api in Professional Api");
            });
        } else if (response.data.type === "customer") {
          console.log(response.data,"customer number");
          setType("customer");
          axios
            .get(
              process.env.REACT_APP_SERVER_PATH +
                `/customers/${+localStorage.getItem("id")}`
            )
            .then((response) => {
              getIndustrySelected(response.data.Industries);
              setUserLocation(response.data.location);
            })
            .catch((e) => {
              console.log("Error in getting custoners industries");
            });
          setUserFirstName(response.data.first_name);
          setUserLastName(response.data.last_name);
          setPhonenumber(response?.data?.phone);
          setBio(response.data.bio);
          if (response.data.profile_photo !== null) {
            setImageUrl(response.data.profile_photo);
          } else {
            setImageUrl(" /avatar.png");
          }
        }
      })
      .catch((e) => {
        console.log("error occured");
      });
  };
  const getAllIndustries = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/industry`)
      .then((response) => {
        setIndustry(response.data);
      })
      .catch((e) => {
        console.log("error in industry api");
      });
  };
  useEffect(() => {
    getUser();
    getAllIndustries();
    setTimeout(() => {
      const search = window.location.search;
      if (search.includes("toSpecialty=")) {
        specialty.current.scrollIntoView();
      }
    }, 2500);
  }, []);
  const addIndustry = (id) => {
    let data = {
      user_id: +localStorage.getItem("id"),
      industry_id: id,
    };
    if (type === "professional") {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_PATH}/professionals_industries`,
          data
        )
        .then((response) => {
          Mixpanel.track(`web: Add new Industry.`, data);
        })
        .catch((e) => {
          console.log("error in industry added");
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_PATH}/customers_industries`, data)
        .then((response) => {
          Mixpanel.track(`web: Add new Industry.`, data);
        })
        .catch((e) => {
          console.log("error in industry added");
        });
    }
  };

  const removeIndustry = (id) => {
    if (type === "professional") {
      axios
        .delete(
          `${
            process.env.REACT_APP_SERVER_PATH
          }/professional/${+localStorage.getItem("id")}/industry/${id}`
        )
        .then((response) => {
          Mixpanel.track(`web: Remove Industry.`, { Industry_id: id });
        })
        .catch((e) => {
          console.log("error in industry deleted");
        });
    } else {
      axios
        .delete(
          `${
            process.env.REACT_APP_SERVER_PATH
          }/customers/${+localStorage.getItem("id")}/industry/${id}`
        )
        .then((response) => {
          Mixpanel.track(`web: Remove Industry.`, { Industry_id: id });
        })
        .catch((e) => {
          console.log("error in industry deleted");
        });
    }
  };
  const handleIndustry = (id, name) => {
    if (selectedIndustry.includes(id)) {
      // Remove ID
      const selectedIndustryCopy = [...selectedIndustry];
      const itemIndex = selectedIndustryCopy.indexOf(id);
      selectedIndustryCopy.splice(itemIndex, 1);
      setSelectedIndustry(selectedIndustryCopy);
      removeIndustry(id);
    } else {
      // Add ID
      // selectedIndustry.push(id)
      setSelectedIndustry([...selectedIndustry, id]);
      addIndustry(id);
    }
  };

  const handleVideo = (event) => {
    setUploading(true);
    const formDataExp = new FormData();
    formDataExp.append("file", event.target.files[0]);
    // formDataExp.append("user_id", +localStorage.getItem('id'));
    axios
      .patch(
        process.env.REACT_APP_SERVER_PATH +
          `/professionals/${+localStorage.getItem("id")}/video_intro`,
        formDataExp
      )
      .then((response) => {
        Mixpanel.track(`web: Add new intro video.`);
        setUploading(false);
        getUser();
      })
      .catch((e) => {
        console.log("error is from uploading");
      });
  };
  const handleImg = (event) => {
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/png"
    ) {
      setShowPicError(false);
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      const formDataExp = new FormData();
      formDataExp.append("file", event.target.files[0]);
      formDataExp.append("user_id", +localStorage.getItem("id"));
      axios
        .post(
          process.env.REACT_APP_SERVER_PATH + `/document/user/`,
          formDataExp
        )
        .then((response) => {
          Mixpanel.track(`web: Update Profile picture.`);
          getUser();
        })
        .catch((e) => {
          console.log("error is from uploading");
        });
    } else {
      setShowPicError(true);
    }
  };
  return (
    <div className="mx-6 mt-6">
      <div className="flex justify-center flex-col items-center mt-3">
        {showPicError ? (
          <p className="text-red-500 mt-2 text-center">
            Failed to upload image;the format is not supported
          </p>
        ) : null}
        <div className="flex">
          <div className="w-20 h-20 rounded-full border border-blue-400 flex items-center justify-center">
            <img
              src={imgUrl}
              alt="alon"
              className="w-20 h-20 rounded-full object-cover p-0.5"
            />
          </div>
          <input
            className="text-xs text-white ml-2"
            type="file"
            onChange={handleImg}
            ref={(fileinput) => setFileInput(fileinput)}
            style={{ display: "none" }}
          />
          <button
            className="-ml-5 mt-10 bg-bgAppColor  hover:bg-bgAppColor  rounded-full h-8 w-8 flex items-center justify-center"
            onClick={() => {
              fileInput.click();
            }}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7 p-1 hover:text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="gray"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              width="20px"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </button>
        </div>
        <button
          className="text-xs mt-2 text-textFullDark"
          onClick={() => {
            fileInput.click();
          }}
        >
          Add a profile picture
        </button>
      </div>
      {type === "professional" && (
        <div>
          {uploading ? (
            <div className="my-2 w-full flex justify-center">
              <Loader
                width={200}
                height={200}
                color={Colors.blue}
                type="Oval"
              />
            </div>
          ) : profileVideoUrl === null ? (
            <div className="flex items-center flex-col my-6">
              <img src="/flowvideo.png" alt="videoIcon" className="w-8" />
              <input
                className="text-xs text-white ml-2"
                type="file"
                onChange={handleVideo}
                ref={(fileinput) => setFileInputVideo(fileinput)}
                style={{ display: "none" }}
                accept="video/*"
              />
              <button
                onClick={() => {
                  fileInputVideo.click();
                }}
                className="my-2 text-xs"
              >
                Upload a video
              </button>
            </div>
          ) : (
            <div>
              <div className="w-full my-4 flex justify-center">
                <Video link={profileVideoUrl} />
              </div>
              <div className="w-full flex justify-center text-xs my-2">
                <input
                  className="text-xs text-white ml-2"
                  type="file"
                  onChange={handleVideo}
                  ref={(fileinput) => setFileInputVideo(fileinput)}
                  style={{ display: "none" }}
                  accept="video/*"
                />
                <button
                  onClick={() => {
                    fileInputVideo.click();
                  }}
                >
                  Upload a video
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        <Form
          user={user}
          userFirstName={userFirstName}
          setUserFirstName={setUserFirstName}
          userLastName={userLastName}
          setUserLastName={setUserLastName}
          userPhonenumber={userPhonenumber}
          setPhonenumber={setPhonenumber}
          userBio={userBio}
          setBio={setBio}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
          setLat={setLat}
          setLong={setLong}
          userSession15={userSession15}
          setSession15={setSession15}
          userSession30={userSession30}
          setSession30={setSession30}
          userJobTitle={userJobTitle}
          setUserJobTitle={setUserJobTitle}
          message={message}
          setmessage={setmessage}
          errorSectionRef={errorSectionRef}
          errors={{
            userSession15Error: userSession15Error,
            userSession30Error: userSession30Error,
          }}
        />
      </div>
      <div>
        <label
          className="block text-textFullDark text-sm font-bold mb-2"
          for="industry"
        >
          {type === "professional" ? "Industry" : "Seeking career insights in"}
        </label>

        <div className="bg-bgIndustryColor rounded p-4 flex sm:flex-col md:flex-row md:flex-wrap">
          {industry?.map((m) => {
            return (
              <>
                <button
                  className={`flex items-center border rounded-full m-1 py-1 px-3 text-sm`}
                  style={{
                    backgroundColor: selectedIndustry.includes(m.id)
                      ? Colors.bgBlue
                      : Colors.bgGray,
                    color: selectedIndustry.includes(m.id) ? "white" : "black",
                    borderColor: selectedIndustry.includes(m.id)
                      ? Colors.bgBlue
                      : Colors.bgGray,
                  }}
                  onClick={() => {
                    if (type === "professional") {
                      handleIndustry(m.id, m.name);
                    } else {
                      handleIndustry(m.id, m.name);
                    }
                  }}
                >
                  {m.name}
                </button>
                {selectedIndustry.includes(m.id) &&
                  m.sub?.length > 0 &&
                  m.sub.map((s) => {
                    return (
                      <button
                        className={`flex items-center border border-borderGray rounded-full m-1 py-1 px-3 text-sm`}
                        style={{
                          backgroundColor: selectedIndustry.includes(s.id)
                            ? Colors.bgDarkBlue
                            : "white",
                          color: selectedIndustry.includes(s.id)
                            ? "#383838"
                            : Colors.bgBlue,
                          borderColor: selectedIndustry.includes(s.id)
                            ? Colors.bgDarkBlue
                            : Colors.bgBlue,
                        }}
                        onClick={() => {
                          setIndustryError(false);
                          // setmessage(true);

                          handleIndustry(s.id, s.name);
                        }}
                      >
                        {s.name}
                      </button>
                    );
                  })}
              </>
            );
          })}
        </div>
      </div>
      {industryError && (
        <p className="text-left text-red-500 font-bold text-sm mt-2 w-full">
          {industryErrorText}
        </p>
      )}
      <div ref={specialty}>
        <label
          className="block text-textFullDark text-sm font-bold mb-2 mt-2"
          for="industry"
        >
          {type === "professional" ? "Specialty" : "Interests"}
        </label>

        <div className="bg-bgIndustryColor rounded p-4 flex sm:flex-col md:flex-row md:flex-wrap">
          <ProfileInterest userType={type} />
        </div>
      </div>

      {/* {userBio?.length < 100 ? ( */}
      {/* <button
            className="bg-bgGray text-center flex justify-center items-center w-full text-sm rounded mt-3 py-3"
            onClick={saveData}
            // style={{ color: "black" }}
            // disabled
          >
            <Notify />
            {isLoading ? (
              <div className="flex justify-center items-center ml-3">
                {" "}
                <Loader
                  type="TailSpin"
                  color="white"
                  height={20}
                  width={20}
                />{" "}
              </div>
            ) : null}
          </button> */}
      {/* ) : ( */}
      <button
        className="bg-bgAppColor text-white text-center flex justify-center items-center w-full text-sm rounded mt-3 py-3 hover:bg-blue-300"
        onClick={saveData}
      >
        <Notify />
        {isLoading ? (
          <div className="flex justify-center items-center ml-3">
            {" "}
            <Loader type="TailSpin" color="white" height={20} width={20} />{" "}
          </div>
        ) : null}
      </button>
      {/* )} */}
    </div>
  );
};

export default ProfileBio;

function Notify() {
  return (
    <div>
      <button>Save Changes</button>
      <ToastContainer />
    </div>
  );
}

const Video = (props) => {
  const videoRef = useRef(null);
  const [play, setPlay] = useState(true);
  useEffect(() => {
    playVideo();
  }, []);
  const playVideo = () => {
    if (play) {
      videoRef.current.play();
      setPlay(false);
    } else {
      videoRef.current.pause();
      setPlay(true);
    }
  };

  return (
    <div className="w-96 h-96">
      <div
        className="absolute bg-white m-4 z-10 rounded-full p-1"
        onClick={playVideo}
      >
        {!play ? (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        ) : (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        )}
      </div>

      <video
        ref={videoRef}
        className="w-full object-cover rounded-xl border h-96 flex items-center justify-center"
        muted={true}
        // controls={false}
        // autoPlay={true}
        onEnded={() => setPlay(true)}
        key={props.link}
      >
        <source src={props.link} />
      </video>
    </div>
  );
};
