import axios from "axios";
import React, { useState } from "react";
import Colors from "../Colors/Colors";
import jwt_decode from "jwt-decode";
import { Mixpanel } from "../Mixpanel";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const EducationForm = (props) => {
 const [type, setType] = useState("text");
 const [types, setTypes] = useState("text");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const validate = Yup.object({
    schoolName: Yup.string().required("School name is required"),
    degreeName: Yup.string()
      .required("Degree name is required"),
    subjectName: Yup.string()
    .required("Subject name is required"),
    startDate: Yup.date().required("Start date required"),  
    endDate: Yup.date().required("End date required"),  
  });

  localStorage.setItem("navState", 11);

  const handleStartDate = (e) => {
    setStartDate(new Date(e.target.value));
    // setStartDate(moment(e.target.value).format("MM YYYY"));
  };
  const handleEndDate = (e) => {
    setEndDate(new Date(e.target.value));
    // setEndDate(moment(e.target.value).format("MM YYYY"));
  };

  return (
    <div className="font-bold w-full">
      <div className="flex relative w-2/3 items-center justify-center">
        <div className="-mt-2">
          <button
            className="absolute left-0"
            onClick={() => {
              props.setNavState(props.navState - 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />{" "}
            </svg>{" "}
          </button>
        </div>

        <p className="text-2xl font-bold mt-3 text-textFullDark">
          Add Education
        </p>
      </div>

      <Formik
        initialValues={{
          schoolName: "",
          degreeName: "",
          subjectName: "",
          startDate: "",
          endDate: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          const Data = {
            school: values.schoolName,
            degree: values.degreeName,
            field_of_study: values.subjectName,
            user_id: jwt_decode(localStorage.getItem("signup_token")).id,
            start_date: values.startDate,
            description: "hello",
            end_date: values.endDate,
            status: "",
          };

          axios
            .post(`${process.env.REACT_APP_SERVER_PATH}/education`, Data)
            .then((response) => {
              Mixpanel.track(`web: Add Education.`, Data);
              props.setNavState(props.navState + 1);
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        {(formik) => (
          <Form>
            <div className="mb-4 mt-4">
              <label
                className="block text-textFullDark text-sm mb-2"
                for="schoolName"
              >
                School
              </label>
              <input
                className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                id="schoolName"
                type="text"
                placeholder="School"
                {...formik.getFieldProps("schoolName")}
              />
              {formik.touched.schoolName && formik.errors.schoolName ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.schoolName}
                </div>
              ) : null}
            </div>

            <div className="mb-4">
              <label
                className="block text-textFullDark text-sm mb-2"
                for="degree"
              >
                Degree
              </label>
              <input
                className="text-xs border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                id="degree"
                type="text"
                placeholder="Degree"
                {...formik.getFieldProps("degreeName")}
              />
              {formik.touched.degreeName && formik.errors.degreeName ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.degreeName}
                </div>
              ) : null}
            </div>

            <div className="mb-4">
              <label
                className="block text-textFullDark text-xs mb-2"
                for="subject"
              >
                Subject
              </label>
              <input
                className="text-xs border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                id="job"
                type="text"
                placeholder="Subject"
                {...formik.getFieldProps("subjectName")}
              />
              {formik.touched.subjectName && formik.errors.subjectName ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.subjectName}
                </div>
              ) : null}
            </div>

            <div className="flex w-full justify-between">
              <div className="w-1/2">
                <label
                  className="block text-textFullDark text-xs mb-2"
                  for="firstName"
                >
                  Start Date
                </label>
                <input
                  className="text-xs border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                  id="startDate"
                  type={types}
                  max={new Date().toISOString().split("T")[0]}
                  placeholder="MM/YY"
                  {...formik.getFieldProps("startDate")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStartDate(e);
                  }}
                  onFocus={() => setTypes("month")}
                  onBlur={() => {
                    formik.handleBlur("startDate");
                    setTypes("text");
                  }}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.startDate}
                  </div>
                ) : null}
              </div>
              <div className="ml-2 w-1/2">
                <label
                  className="block text-textFullDark text-xs mb-2"
                  for="firstName"
                >
                  End Date
                </label>
                <input
                  className="text-xs border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
                  id="endDate"
                  type={types}
                  max={new Date().toISOString().split("T")[0]}
                  placeholder="MM/YY"
                  {...formik.getFieldProps("endDate")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleEndDate(e);
                  }}
                  onFocus={() => setTypes("month")}
                  onBlur={() => {
                    formik.handleBlur("endDate");
                    setTypes("text");
                  }}
                />
                {formik.touched.endDate && formik.errors.endDate ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.endDate}
                  </div>
                ) : null}
              </div>
            </div>

            <button
              className="text-white w-full py-2 mt-4 rounded-lg"
              style={{ backgroundColor: Colors.blue }}
              // onClick={AddEducation}
              type="submit"
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EducationForm;
