import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import ProfileMainContent from './ProfileMainContent/ProfileMainContent'
import Reviews from './Reviews/Reviews'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { getRefFromQuery } from '../CustomFunctions/CustomFunctions'
const Profile = (props) => {
    const scrollToDiv = () => {
        const element = document.getElementById('targetDiv');
        element.scrollIntoView({ behavior: 'smooth' });
    };
    const [reviews, setReviews] = useState([])
    const [experience, setExperience] = useState([])
    const [education, setEducation] = useState([])
    const [profile, setProfile] = useState({})
    let { id } = useParams();
    id = parseInt(id.split('-')[2], 10);
    let queryString = useLocation()
    getRefFromQuery(queryString)

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/user_reviews/${id}`).then((response) => {
            setReviews(response.data)
        }).catch(e => {
            console.log("Error in Reviews Api", e);
        })
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/experience/${id}`).then((response) => {

            setExperience(response.data)
        })
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/education/${id}`).then((response) => {
            setEducation(response.data)
        })
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${id}`).then((response) => {
            console.log(response.data, "datatata")
            setProfile(response.data)
        })
    }, [id]);
    return (
        <div className='bg-white'>
            <Navbar />
            <hr />
            <ProfileMainContent scrollToDiv={scrollToDiv} id={id} profile={profile && profile} professionalIndustries={profile && profile.Industries !== undefined && profile.Industries} experience={experience} education={education && education} reviews={reviews} />
            <div id="targetDiv">
                {reviews.length > 0 && <Reviews name="Reviews" reviews={reviews} />}
            </div>
            <Footer />
        </div>
    )
}

export default Profile
