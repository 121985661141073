import axios from "axios";
import moment from "moment";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pricetoDecimal } from "../../../../price";
import { CompanyTitle } from "../../../../settings";
import Tooltip from "@mui/material/Tooltip";
import {
  FiCameraOff,
  FiCamera,
  FiMessageCircle,
  FiAlertCircle,
} from "react-icons/fi";

const TransactionDetails = ({ user }) => {
  const [transaction, setTransaction] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
    if (user.User?.type === "professional") {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/bookings_transaction/${location.state.booking_id}/${location.state.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setTransaction(response.data);
        })
        .catch((e) => {
          console.log("Error in professional transaction fetching");
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/bookings_transaction/${location.state.booking_id}/${location.state.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          setTransaction(response.data);
        })
        .catch((e) => {
          console.log("Error in customer transaction fetching");
        });
    }
  }, []);
  let blue = "/t_blue.png";
  let gray = "/t_gray.png";
  let red = "/t_red.png";
  return (
    <div>
      <div className="h-2/12">
        <div className="flex p-4 rounded-t items-center justify-between text-white text-xl w-full bg-bgAppColor">
          <div className="cursor-pointer">
            <span
              onClick={() => {
                navigate("/dashboard/payments");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </span>
          </div>
          <div className="w-full flex items-center justify-center">
            <p>Transaction Details</p>
          </div>
        </div>
      </div>
      {/* Refund for Customer */}
      {user.User?.type === "customer" ? (
        <div>
          {location.state.action === "Refund" ? (
            <div>
              <div className="bg-white rounded p-6 flex flex-col">
                <div className="w-full flex items-center justify-center flex-col">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        transaction.booking?.Professionals_Metadatum?.User
                          ?.profile_photo
                          ? transaction.booking?.Professionals_Metadatum?.User
                            ?.profile_photo
                          : "/avatar.png"
                      }
                      alt="picture"
                      className="w-20 h-20 rounded-full object-cover z-10"
                    />
                    {/* <img src={transaction.user_id === transaction.Booking?.customer_id ? transaction.Booking?.User?.profile_photo : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo} alt='picture' className='w-20 h-20 rounded-full object-cover -ml-4' /> */}
                  </div>
                  <p className="text-2xl font-bold text-textFullDark mt-4">
                    {`${transaction.booking?.session_duration}`} min Meeting
                    with{" "}
                    {
                      transaction.booking?.Professionals_Metadatum?.User
                        ?.first_name
                    }
                  </p>
                  <p className="text-lg mt-1 text-textDark">
                    {moment(transaction.booking?.booking_time * 1000).format(
                      "ddd,DD MMM,YYYY h:mm A"
                    )}
                  </p>
                </div>
                <div
                  className="w-full rounded-xl p-6 justify-center flex items-center flex-col mt-6"
                  style={{
                    backgroundImage: `url(${gray})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <p className="text-lg text-white">Transaction</p>
                  <p className="text-4xl font-bold text-white">
                    {pricetoDecimal(
                      transaction?.booking?.session_fee
                        ? Number(transaction.booking?.total_price) +
                        Number(process.env.REACT_APP_BOOKING_FEE)
                        : transaction.booking?.total_price
                    )}
                  </p>
                  <p className="text-lg text-white">
                    Your payment{" "}
                    {pricetoDecimal(transaction.booking?.session_price)} has
                    been refunded for{" "}
                    {`${transaction.booking?.session_duration}`} mins call
                  </p>
                </div>
              </div>
              <div className="bg-white rounded my-6 p-6 py-8">
                <p className="text-2xl font-bold text-textFullDark">
                  Transaction Details
                </p>
                <div className="flex items-center justify-between mt-4 text-lg text-textDark">
                  <p>Subtotal</p>
                  <p>{pricetoDecimal(transaction.booking?.total_price)}</p>
                </div>
                <div className="flex items-center justify-between mt-2 text-lg font-bold">
                  <p className="text-textFullDark">Total Refund</p>
                  <p>{pricetoDecimal(transaction.total)}</p>
                </div>
              </div>
            </div> //REFERRAL BONUS for Customer
          ) : location.state.action === "REFERRAL BONUS" ? (
            <div>
              <div className="bg-white rounded p-6 flex flex-col">
                <div className="w-full flex items-center justify-center flex-col">
                  <div className="flex items-center">
                    <img
                      src={
                        transaction.booking?.User?.profile_photo
                          ? transaction.booking?.User?.profile_photo
                          : "/avatar.png"
                      }
                      alt="picture"
                      className="w-20 h-20 rounded-full object-cover z-10"
                    />
                    {/* <img src={transaction.user_id === transaction.Booking?.customer_id ? transaction.Booking?.User?.profile_photo : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo} alt='picture' className='w-20 h-20 rounded-full object-cover -ml-4' /> */}
                  </div>
                  <p className="text-2xl font-bold text-textFullDark mt-4">
                    30 min Meeting with John
                  </p>
                  <p className="text-lg mt-1 text-textDark">
                    {moment(new Date()).format("ddd,DD MMM,YYYY h:mm A")}
                  </p>
                </div>
                <div
                  className="w-full rounded-xl p-6 justify-center flex items-center flex-col mt-6"
                  style={{
                    backgroundImage: `url(${red})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <p className="text-lg text-white">Transaction</p>
                  <p className="text-4xl font-bold text-white">
                    {pricetoDecimal(200)}
                  </p>
                  <p className="text-lg text-white">
                    You have received $175 from{" "}
                  </p>
                  <p className="text-lg text-white">john doe 30 min call</p>
                </div>
              </div>
              <div className="bg-white rounded my-6 p-6 py-8">
                <p className="text-2xl font-bold text-textFullDark">
                  Transaction Details
                </p>
                <div className="flex items-center justify-between mt-4 text-lg text-textDark">
                  <p>Subtotal</p>
                  <p>$5000</p>
                </div>
                <div className="flex items-center justify-between mt-2 text-lg text-textDark">
                  <p>Donation</p>
                  <p>$70</p>
                </div>
                <div className="flex items-center justify-between mt-2 text-lg text-textDark">
                  <p>{CompanyTitle} Fee</p>
                  <p>$51</p>
                </div>
                <div className="flex items-center justify-between mt-2 text-lg font-bold">
                  <p className="text-textFullDark">Total Earning</p>
                  <p>$5300</p>
                </div>
              </div>
            </div> //Session Booking for customer
          ) : location.state.action === "Session Booking" ? (
            <div>
              <div className="bg-white rounded p-6 flex flex-col">
                <div className="w-full flex items-center justify-center flex-col">
                  <div className="flex items-center">
                    <img
                      src={
                        transaction.booking?.User?.profile_photo
                          ? transaction.booking?.User?.profile_photo
                          : "/avatar.png"
                      }
                      alt="picture"
                      className="w-20 h-20 rounded-full object-cover z-10"
                    />
                    {/* <img src={transaction.user_id === transaction.Booking?.customer_id ? transaction.Booking?.User?.profile_photo : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo} alt='picture' className='w-20 h-20 rounded-full object-cover -ml-4' /> */}
                  </div>
                  <p className="text-2xl font-bold text-textFullDark mt-4">
                    {`${transaction.booking?.session_duration}`} min Meeting
                    with{" "}
                    {
                      transaction.booking?.Professionals_Metadatum?.User
                        ?.first_name
                    }
                  </p>
                  <p className="text-lg mt-1 text-textDark">
                    {moment(transaction.booking?.booking_time * 1000).format(
                      "ddd,DD MMM,YYYY h:mm A"
                    )}
                  </p>
                </div>
                <div
                  className="w-full rounded-xl p-6 justify-center flex items-center flex-col mt-6"
                  style={{
                    backgroundImage: `url(${red})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <p className="text-lg text-white">Transaction</p>
                  <p className="text-4xl font-bold text-white">
                    {pricetoDecimal(transaction.booking?.total_price)}
                  </p>
                  <p className="text-lg text-white text-center">
                    You have paid {pricetoDecimal(transaction.total)} to{" "}
                    {
                      transaction.booking?.Professionals_Metadatum?.User
                        ?.first_name
                    }{" "}
                    for {`${transaction.booking?.session_duration}`} mins call
                  </p>
                </div>
              </div>
              <div className="bg-white rounded my-6 p-6 py-8">
                <p className="text-2xl font-bold text-textFullDark">
                  Transaction Details
                </p>
                <div className="flex items-center justify-between mt-4 text-lg text-textDark">
                  <p>Subtotal</p>
                  <p>{pricetoDecimal(transaction.booking?.total_price)}</p>
                </div>
                {transaction.transactions &&
                  transaction.transactions.map((transaction) => {
                    if (transaction.action === "DISCOUNT") {
                      <div
                        key={transaction.id}
                        className="flex items-center justify-between mt-2 text-lg text-textDark"
                      >
                        <p>Discount Coupen</p>
                        <p>-{pricetoDecimal(transaction.amount)}</p>
                      </div>;
                    } else {
                      return null;
                    }
                  })}
                <div className="flex items-center justify-between mt-2 text-lg font-bold">
                  <p className="text-textFullDark">Total Earning</p>
                  <p>{pricetoDecimal(transaction.total)}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div> // Transactio details for Professional
      ) : (
        <div>
          {location.state.action === "Session Booking" ? (
            <div>
              <div className="bg-white rounded p-6 flex flex-col">
                <div className="w-full flex items-center justify-center flex-col">
                  <div className="flex items-center">
                    <img
                      src={
                        transaction.booking?.Professionals_Metadatum?.User
                          ?.profile_photo
                          ? transaction.booking?.Professionals_Metadatum?.User
                            ?.profile_photo
                          : "/avatar.png"
                      }
                      alt="picture"
                      className="w-20 h-20 rounded-full object-cover z-10"
                    />
                    {/* <img src={transaction.user_id === transaction.Booking?.customer_id ? transaction.Booking?.User?.profile_photo : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo} alt='picture' className='w-20 h-20 rounded-full object-cover -ml-4' /> */}
                  </div>
                  <p className="text-2xl font-bold text-textFullDark mt-4">
                    {`${transaction.booking?.session_duration}`} min Meeting
                    with{" "}
                    {
                      transaction.booking?.Professionals_Metadatum?.User
                        ?.first_name
                    }
                  </p>
                  <p className="text-lg mt-1 text-textDark">
                    {moment(transaction.booking?.booking_time * 1000).format(
                      "ddd,DD MMM,YYYY h:mm A"
                    )}
                  </p>
                </div>
                <div
                  className="w-full rounded-xl p-6 justify-center flex items-center flex-col mt-6"
                  style={{
                    backgroundImage: `url(${red})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <p className="text-lg text-white">Transaction</p>
                  <p className="text-4xl font-bold text-white">
                    {pricetoDecimal(
                      transaction?.booking?.session_fee
                        ? Number(transaction.booking?.total_price) +
                        Number(process.env.REACT_APP_BOOKING_FEE)
                        : transaction.booking?.total_price
                    )}
                  </p>
                  <p className="text-lg text-white text-center">
                    You have paid{" "}
                    {pricetoDecimal(
                      transaction?.booking?.session_fee
                        ? Number(transaction.booking?.session_price) +
                        Number(process.env.REACT_APP_BOOKING_FEE)
                        : transaction.booking?.session_price
                    )}{" "}
                    to{" "}
                    {
                      transaction.booking?.Professionals_Metadatum?.User
                        ?.first_name
                    }{" "}
                    for {`${transaction.booking?.session_duration}`} mins call
                  </p>
                </div>
              </div>
              <div className="bg-white rounded my-6 p-6 py-8">
                <p className="text-2xl font-bold text-textFullDark">
                  Transaction Details
                </p>
                <div className="flex items-center justify-between mt-4 text-lg text-textDark">
                  <p>Subtotal</p>
                  <p>{pricetoDecimal(transaction.booking?.total_price)}</p>
                </div>
                {/* <div > */}
                {transaction.transactions &&
                  transaction.transactions.map((transaction) => {
                    if (transaction.action === "DISCOUNT") {
                      return (
                        <div
                          key={transaction.id}
                          className="flex items-center justify-between mt-2 text-lg text-textDark"
                        >
                          <p>Discount Coupen</p>
                          <p>-{pricetoDecimal(transaction.amount)}</p>
                        </div>
                      );
                    } else if (transaction.action === "SESSION FEE") {
                      return (
                        <div
                          key={transaction.id}
                          className="flex items-center justify-between mt-2 text-lg text-textDark"
                        >
                          <p className="flex ">
                            Booking Fee{" "}
                            <Tooltip title="Booking fee is non refundable." placement="right-start">
                              <span>
                                <FiAlertCircle className="w-4 h-4 m-1" />
                              </span>
                            </Tooltip>
                          </p>
                          <p>{pricetoDecimal(transaction.amount)}</p>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}

                <div className="flex items-center justify-between mt-2 text-lg font-bold">
                  <p className="text-textFullDark">Total Paid</p>
                  <p>
                    {pricetoDecimal(
                      transaction?.booking?.session_fee
                        ? Number(transaction.booking?.session_price) +
                        Number(process.env.REACT_APP_BOOKING_FEE)
                        : transaction.booking?.session_price
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : location.state.action === "Commission" ? (
            <div>
              <div className="bg-white rounded p-6 flex flex-col">
                <div className="w-full flex items-center justify-center flex-col">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        transaction.booking?.User?.profile_photo
                          ? transaction.booking?.User?.profile_photo
                          : "/avatar.png"
                      }
                      alt="picture"
                      className="w-20 h-20 rounded-full object-cover z-10"
                    />
                    {/* <img src={transaction.user_id === transaction.Booking?.customer_id ? transaction.Booking?.User?.profile_photo : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo} alt='picture' className='w-20 h-20 rounded-full object-cover -ml-4' /> */}
                  </div>
                  <p className="text-2xl font-bold text-textFullDark mt-4">
                    {`${transaction.booking?.session_duration}`} min Meeting
                    with {transaction.booking?.User?.first_name}
                  </p>
                  <p className="text-lg mt-1 text-textDark">
                    {moment(transaction.booking?.booking_time * 1000).format(
                      "ddd,DD MMM,YYYY h:mm A"
                    )}
                  </p>
                </div>
                <div
                  className="w-full rounded-xl p-6 justify-center flex items-center flex-col mt-6"
                  style={{
                    backgroundImage: `url(${blue})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <p className="text-lg text-white">Transaction</p>
                  <p className="text-4xl font-bold text-white">
                    {Number(transaction?.total) < 1 ? pricetoDecimal(transaction.total) : pricetoDecimal(transaction.booking?.total_price)}
                  </p>
                  {Number(transaction?.total) < 1 ? <p className="text-lg text-white">
                    Call with CareerPaths representative
                  </p> : <p className="text-lg text-white">
                    You have received{" "}
                    {pricetoDecimal(transaction.total)} from{" "}
                    {`${transaction.booking?.User?.first_name}`}{" "}
                    {`${transaction.booking?.session_duration}`} mins call
                  </p>}
                </div>
              </div>
              <div className="bg-white rounded my-6 p-6 py-8">
                <p className="text-2xl font-bold text-textFullDark">
                  Transaction Details
                </p>
                <div className="flex items-center justify-between mt-4 text-lg text-textDark">
                  <p>Subtotal</p>
                  <p>{pricetoDecimal(transaction.total == 0 ? transaction.total : transaction.booking?.total_price)}</p>
                </div>

                {transaction.transactions && (
                  <div className="flex items-center justify-between text-lg text-textDark">
                    {transaction.transactions.map((transaction) =>
                      transaction.action === "CHARITY_DONATION" ? (
                        <div
                          key={transaction.id}
                          className="flex items-center mt-2 justify-between w-full"
                        >
                          <p>Charity donation ({transaction.notes}%)</p>
                          <p>-{pricetoDecimal(transaction.amount)}</p>
                        </div>
                      ) : null
                    )}
                  </div>
                )}

                <div className="flex items-center justify-between mt-2 text-lg text-textDark">
                  <p>{CompanyTitle} Fee</p>
                  <p>
                    {transaction.transactions &&
                      transaction.transactions.map((transaction) =>
                        transaction.action === "Admin Commission" ? (
                          <p key={transaction.id}>
                            -{pricetoDecimal(transaction.amount)}
                          </p>
                        ) : null
                      )}
                  </p>
                </div>

                {transaction.booking?.Coupon?.paid_by_mentor ? (<>
                  <div className="flex items-center justify-between mt-2 text-lg text-textDark">
                    <p>Discount Coupon</p>
                    <p>
                      {pricetoDecimal(
                        Number(transaction.booking?.total_price) * (
                          Number(transaction.booking?.Coupon.discount_amount) / 100)
                      )}
                    </p>
                  </div>
                </>) : null}

                <div className="flex items-center justify-between mt-2 text-lg font-bold">
                  <p className="text-textFullDark">Total Earnings</p>
                  <p>{pricetoDecimal(transaction.total)}</p>
                </div>
              </div>
            </div>
          ) : location.state.action === "Refund" ? (
            <div>
              <div className="bg-white rounded p-6 flex flex-col">
                <div className="w-full flex items-center justify-center flex-col">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        transaction.booking?.Professionals_Metadatum?.User
                          ?.profile_photo
                          ? transaction.booking?.Professionals_Metadatum?.User
                            ?.profile_photo
                          : "/avatar.png"
                      }
                      alt="picture"
                      className="w-20 h-20 rounded-full object-cover z-10"
                    />
                    {/* <img src={transaction.user_id === transaction.Booking?.customer_id ? transaction.Booking?.User?.profile_photo : transaction.Booking?.Professionals_Metadatum?.User?.profile_photo} alt='picture' className='w-20 h-20 rounded-full object-cover -ml-4' /> */}
                  </div>
                  <p className="text-2xl font-bold text-textFullDark mt-4">
                    {`${transaction.booking?.session_duration}`} min Meeting
                    with{" "}
                    {
                      transaction.booking?.Professionals_Metadatum?.User
                        ?.first_name
                    }
                  </p>
                  <p className="text-lg mt-1 text-textDark">
                    {moment(transaction.booking?.booking_time * 1000).format(
                      "ddd,DD MMM,YYYY h:mm A"
                    )}
                  </p>
                </div>
                <div
                  className="w-full rounded-xl p-6 justify-center flex items-center flex-col mt-6"
                  style={{
                    backgroundImage: `url(${gray})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <p className="text-lg text-white">Transaction</p>
                  <p className="text-4xl font-bold text-white">
                    {pricetoDecimal(
                      transaction?.booking?.session_fee
                        ? Number(transaction.booking?.total_price) +
                        Number(process.env.REACT_APP_BOOKING_FEE)
                        : transaction.booking?.total_price
                    )}
                  </p>
                  <p className="text-lg text-white">
                    Your payment{" "}
                    {pricetoDecimal(transaction.booking?.session_price)} has
                    been refunded for{" "}
                    {`${transaction.booking?.session_duration}`} mins call
                  </p>
                </div>
              </div>
              <div className="bg-white rounded my-6 p-5 py-8">
                <p className="text-2xl font-bold text-textFullDark">
                  Transaction Details
                </p>
                <div className="flex items-center justify-between mt-4 text-lg text-textDark">
                  <p>Subtotal</p>
                  <p>{pricetoDecimal(transaction.booking?.total_price)}</p>
                </div>
                {transaction.transactions &&
                  transaction.transactions.map((transaction) => {
                    if (transaction.action === "DISCOUNT") {
                      return (
                        <div
                          key={transaction.id}
                          className="flex items-center justify-between mt-2 text-lg text-textDark"
                        >
                          <p>Discount Coupen</p>
                          <p>-{pricetoDecimal(transaction.amount)}</p>
                        </div>
                      );
                    } else if (transaction.action === "SESSION FEE") {
                      return (
                        <div
                          key={transaction.id}
                          className="flex items-center justify-between mt-2 text-lg text-textDark"
                        >
                          <p className="flex ">
                            Booking Fee{" "}
                            <Tooltip title="Booking fee is non refundable." placement="right-start">
                              <span>
                                <FiAlertCircle className="w-4 h-4 m-1" />
                              </span>
                            </Tooltip>
                          </p>
                          <p>-{pricetoDecimal(transaction.amount)}</p>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                <div className="flex items-center justify-between mt-2 text-lg font-bold">
                  <p className="text-textFullDark">Total Refund</p>
                  <p>{pricetoDecimal(transaction.booking?.session_price)}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
