import React, { useState } from "react";
import Colors from "../Colors/Colors";
import axios from "axios";
import { Formik, Form } from "formik";
import TextField from "./TextField";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";
import { Mixpanel } from "../Mixpanel";
import { update_profile_step } from "./StepUpdated";
import { CompanyTitle } from "../settings";
import { useDispatch } from "react-redux";
import { setProfessionalsToTrue } from "../Redux/reducers/professional/professionalSlice";
import { setLogin } from "../Redux/reducers/customer/customerSlice";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";

const AddInformation = (props) => {
  localStorage.setItem("navState", 1);
  let dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false); //Show Error for Using Existing Email
  const [isLoading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [invideCodeError, setInviteCodeError] = useState(false);
  const [invideCodeErrorText, setInviteCodeErrorText] = useState("");
  
  const validate = Yup.object({
    first_name: Yup.string()
      .max(
        20,
        <p className="text-red-500 -mt-4">Must be 20 characters or less</p>
      )
      .required(<p className="text-red-500 -mt-4">Required</p>),
    
    last_name: Yup.string()
      .max(
        20,
        <p className="text-red-500 -mt-4">Must be 20 characters or less</p>
      )
      .required(<p className="text-red-500 -mt-4">Required</p>),

    email: Yup.string()
      .email(<p className="text-red-500 -mt-4">Please enter valid email</p>)
      .required(<p className="text-red-500 -mt-4">Email is required</p>),

    password: Yup.string()
      .min(
        6,
        <p className="text-red-500 -mt-4">Must be at least 6 characters</p>
      )
      .required(<p className="text-red-500 -mt-4">Password is required</p>),

    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        <p className="text-red-500 -mt-4">
          Password and confirm password must be same
        </p>
      )
      .required(
        <p className="text-red-500 -mt-4">Confirm password is required</p>
      ),
    phone: Yup.string().required(
      <p className="text-red-500 -mt-4">Please enter Phonenumber </p>
    ),
  });

  const toggleLogin = () => {
    props.setCustomerSignUpFlow(!props.customerSignUpFlow);
    Mixpanel.track("Press login button web");
    dispatch(setBannerTrue());
    dispatch(setLogin());
  };

  // this function will call when email input is focus and then unfocus. we check the the value if it is valid email then call the API
  const handleInputBlur = (event) => {
    var regularExpression =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    let valid = regularExpression.test(
      String(event.target.value).toLowerCase()
    );
    if (valid) {
      axios
        .post(`${process.env.REACT_APP_SERVER_PATH}/create-abandon-event`, {
          type: "signup",
          email: event.target.value,
        })
        .catch((err) => {
          console.log("err in create-abandon-event", err);
        });
    } else {
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        type: "",
        status: "",
        reference_code: localStorage.getItem("cp_ref")
          ? localStorage.getItem("cp_ref")
          : "",
        cp_ref: localStorage.getItem("cp_ref")
          ? localStorage.getItem("cp_ref")
          : "",
      }}
      validationSchema={validate}
      //New Sign Up
      onSubmit={(values) => {
        if (checked) {
          if (values.reference_code?.trim()?.length > 0) {
            setLoading(true);
            values.type = "customer";
            axios(
              `${process.env.REACT_APP_SERVER_PATH
              }/validate_reference_code/${values.reference_code?.trim()}`
            )
              .then((response) => {
                if (response.data.status) {
                  if (response.data.isPartner) {
                    values["cp_ref"] = values.reference_code?.trim();
                  } else {
                    values["reference_code"] = values.reference_code?.trim();
                  }

                  axios
                    .post(process.env.REACT_APP_SERVER_PATH + `/signup`, values)
                    .then((response) => {
                      let email = response.data.message.email;
                      let cp_ref = localStorage.getItem("cp_ref");
                      Mixpanel.alias(email);
                      Mixpanel.track("Successful Signup on website", {
                        "User id": jwt_decode(response.data.message.token).id,
                        ref_cp: cp_ref,
                      });
                      Mixpanel.people.set({
                        cp_ref: cp_ref,
                      });
                      props.setNavState(props.navState + 1);
                      localStorage.setItem(
                        "customer_signup_token",
                        response.data.message.token
                      );
                      update_profile_step(
                        response.data.message.token,
                        jwt_decode(response.data.message.token).id,
                        "1"
                      );
                      setLoading(false);
                    })
                    .catch((e) => {
                      setError(true);
                      setLoading(false);
                      console.log("error occured");
                    });
                } else {
                  setInviteCodeErrorText(response.data.msg);
                  setInviteCodeError(true);
                  setLoading(false);
                }
              })
              .catch((e) => {
                console.log("Error in validate_reference_code");
              });
          } else {
            setLoading(true);
            values.type = "customer";
            axios
              .post(process.env.REACT_APP_SERVER_PATH + `/signup`, values)
              .then((response) => {
                let email = response.data.message.email;
                let cp_ref = localStorage.getItem("cp_ref");
                Mixpanel.alias(email);
                Mixpanel.track("Successful Signup on website", {
                  "User id": jwt_decode(response.data.message.token).id,
                  ref_cp: cp_ref,
                });
                Mixpanel.people.set({
                  cp_ref: cp_ref,
                });
                if (window?.location?.pathname?.includes('checkout')) {
                  props.setNavState(12);
                  localStorage.setItem("type", JSON.stringify(response.data.message.type));
                  localStorage.setItem("email", JSON.stringify(response.data.message.email));
                  localStorage.setItem("cp_web_token", response.data.message.token);
                  localStorage.setItem("id", jwt_decode(response.data.message.token).id);
                } else {
                  props.setNavState(props.navState + 1);
                  localStorage.setItem(
                    "customer_signup_token",
                    response.data.message.token
                  );
                }

                update_profile_step(
                  response.data.message.token,
                  jwt_decode(response.data.message.token).id,
                  "1"
                );
              })
              .catch((e) => {
                setError(true);
                setLoading(false);
                console.log("error occured");
              });
          }
        }
      }}
    >
      {/* Using Formik for Form Submittion and Validation */}
      {(formik) => (
        <div className="flex flex-col justify-center items-center text-textFullDark sm:mt-20 md:-mt-4">
          <img src="/addName.png" alt="addName" className="w-48" />
          <p className="text-2xl font-bold mt-1 text-textFullDark">
            Lets start with the basics
          </p>
          <p className="text-xs mt-1 text-center">
            Please enter your contact details and set up your password
          </p>
          <div className="w-full">
            <Form>
              <TextField
                name="email"
                type="email"
                placeholder="Email"
                onBlur={handleInputBlur}
              />
              {formik.values.email === "" ? setError(false) : ""}
              {error === true ? (
                <p className="text-red-500 -mt-4">Email already exists </p>
              ) : null}
              <div className="flex">
                <TextField
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                />
                <div className="ml-2">
                  <TextField
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <TextField name="phone" type="phone" placeholder="Phone" />
              <TextField
                name="password"
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                passwordShown={passwordShown}
                setPasswordShown={setPasswordShown}
              />

              <TextField
                name="confirmPassword"
                type={passwordShown ? "text" : "password"}
                placeholder="Confirm password"
                passwordShown={passwordShown}
                setPasswordShown={setPasswordShown}
              />

              {window?.location?.pathname?.includes('checkout') ? null : <TextField
                name="reference_code"
                type="reference_code"
                placeholder="Invitation code (Optional)"
              />}
              {invideCodeError && (
                <p className="text-xs font-bold text-red-500">
                  {invideCodeErrorText}
                </p>
              )}
              <div className="w-full flex mb-4 mt-2 text-xs text-textDark">
                <label className="inline-flex items-center text-2">
                  <input
                    type="checkbox"
                    className="form-checkbox rounded"
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                  />
                  <span className="ml-2">Yes, I agree with {CompanyTitle}</span>
                </label>
                <a
                  className="text-blue-500 ml-1"
                  target="_blank"
                  rel="noreferrer"
                  href="https://careerpaths.io/terms-and-conditions"
                >
                  Terms &amp; Conditions
                </a>
              </div>
              <button
                type="submit"
                className="w-full flex items-center justify-center text-center py-3 rounded bg-bgAppColor text-white hover:bg-bgAppColor focus:outline-none my-1"
                style={{
                  backgroundColor:
                    checked &&
                      formik.values.VIP_CODE !== "" &&
                      formik.values.first_name !== "" &&
                      formik.values.last_name !== "" &&
                      formik.values.email !== "" &&
                      formik.values.password !== "" &&
                      formik.values.confirmPassword !== ""
                      ? Colors.blue
                      : Colors.gray,
                  color:
                    checked &&
                      formik.values.first_name !== "" &&
                      formik.values.last_name !== "" &&
                      formik.values.email !== "" &&
                      formik.values.password !== "" &&
                      formik.values.confirmPassword !== ""
                      ? "white"
                      : "gray",
                }}
              >
                Next{" "}
                {isLoading ? (
                  <span className="inline-block ml-4">
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={30}
                      width={30}
                    // timeout={5000} //5 secs
                    />
                  </span>
                ) : null}{" "}
              </button>
            </Form>
          </div>
          {!props.isFreeCredit && (
            window?.location?.pathname?.includes('checkout') ? null : <div className="w-full flex flex-col items-center justify-center">
              <div className="font-bold mx-auto my-2">
                <p>or</p>
              </div>
              <div className="w-full">
                <button
                  className="bg-black hover:bg-black text-white py-2 px-4 border border-borderGray rounded w-full"
                  onClick={() => {
                    props.setCustomerSignUpFlow(!props.customerSignUpFlow);
                    dispatch(setProfessionalsToTrue());
                  }}
                >
                  Mentors sign up here
                </button>
              </div>
            </div>
          )}
          <div className="flex text-sm items-start justify-start w-full text-textFullDark mt-2">
            <p>Already have an account?</p>
            <button
              className="ml-1 text-textApp underline"
              onClick={toggleLogin}
            >
              Login
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default AddInformation;
