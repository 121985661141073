import React, { useState } from 'react'
import { CompanyTitle } from '../settings'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AboutUsMain = () => {
    // let navigate = useNavigate()
    const [mentors] = useState([
        // team
        {
            img: '/careerpathsTeam/2p.png',
            name: 'Guy Ellis',
            first_name: 'Guy',
            last_name: 'Ellis',
            designation: 'Co-Founder',
            id: 21,
            linkedin: "guy-ellis-6b7a6216/"
        },
        {
            img: '/careerpathsTeam/saad.jpeg',
            name: 'Saad Farooq',
            first_name: 'Saad',
            last_name: 'Farooq',
            designation: 'Co-Founder',
            id: 2,
            linkedin: "saad-farooq-17647128/"
        },
        {
            img: '/careerpathsTeam/will.png',
            name: 'Will Collier',
            first_name: 'Will',
            last_name: 'Collier',
            designation: 'Chief Marketing Officer',
            linkedin: "willcollier/"

        },
        {
            img: '/careerpathsTeam/ole.jpeg',
            name: 'Ole Heidelk',
            first_name: 'Ole',
            last_name: 'Heidelk',
            designation: 'Chief of Staff',
            id: 600,
            linkedin: "ohei/"
        },
        {
            img: '/careerpathsTeam/maavaz.jpeg',
            name: 'Maavuz Saif',
            first_name: 'Maavuz',
            last_name: 'Saif',
            designation: 'Chief Technology Officer',
            linkedin: "maavuz/"
        },

        {
            img: '/careerpathsTeam/zainab.jpeg',
            name: 'Zainab Sohail',
            first_name: 'Zainab',
            last_name: 'Sohail',
            designation: 'Intern',
            linkedin: "zainab-sohail-165ba1233/"
        },
        {
            img: '/careerpathsTeam/seb.jpeg',
            name: 'Seb Lopes',
            first_name: 'Seb',
            last_name: 'Lopes',
            designation: 'Intern',
            linkedin: "seb-lopes-372612227/"

        },
        {
            img: '/careerpathsTeam/zak.jpeg',
            name: 'Zak Hammond',
            first_name: 'zak',
            last_name: 'Hammond',
            designation: 'Intern',
            linkedin: "zak-hammond-a16313225/"

        },
        {
            img: '/careerpathsTeam/burhan.jpeg',
            name: 'Burhan Khalil',
            first_name: 'Burhan',
            last_name: 'Khalil',
            designation: 'Intern',
            linkedin: "burhan-khalil-8580a2207/"

        },
        // board advisors
        // {
        //     img: '/alex.png',
        //     name: 'Alex Tew',
        //     designation: 'Co-Founder & Co-CEO at Calm'
        // }, {
        //     img: '/5p.png',
        //     name: 'Tobias Buck',
        //     designation: 'Private Equity Investor'
        // }, {
        //     img: 'https://pbs.twimg.com/profile_images/1121441937272209408/R4c10xrf_400x400.png',
        //     name: 'Higor Torchia',
        //     designation: 'CRO at Perkbox'
        // }, {
        //     img: 'https://miro.medium.com/focal/1200/1200/46/41/1*bCF3jMAUARCpHhsnstDcYw.jpeg',
        //     name: 'James Mitra',
        //     designation: 'Founder & MD JBM'
        // },
        // {
        //     img: 'https://d1hrwyvk1pin3o.cloudfront.net/AcuCustom/Sitename/DAM/020/Roshan_Awatar_Main.jpg',
        //     name: 'Roshan Awatar',
        //     designation: 'Data and Analytics Director'
        // },
        // {
        //     img: '/careerpathsTeam/david.jpeg',
        //     name: 'David Milton ',
        //     designation: 'Executive Coach'
        // },
        // {
        //     img: '/careerpathsTeam/robert.jpeg',
        //     name: 'Robert Allard',
        //     designation: 'Strategy and M&A Advisor'
        // },


    ])
    const [boardAdvisors] = useState([
        {
            img: '/alex.png',
            name: 'Alex Tew',
            first_name: 'Alex',
            last_name: 'Tew',
            designation: 'Co-Founder of Calm',
            linkedin: "alextew/"
        }, {
            img: '/5p.png',
            name: 'Tobias Buck',
            first_name: 'Tobias',
            last_name: 'Buck',
            designation: 'Private Equity Investor',
            id: 175,
            linkedin: "tobiasbuck/"
        }, {
            img: 'https://pbs.twimg.com/profile_images/1121441937272209408/R4c10xrf_400x400.png',
            name: 'Higor Torchia',
            first_name: 'Higor',
            last_name: 'Torchia',
            designation: 'CRO at Perkbox',
            linkedin: "htorchia/"
        }, {
            img: 'https://miro.medium.com/focal/1200/1200/46/41/1*bCF3jMAUARCpHhsnstDcYw.jpeg',
            name: 'James Mitra',
            first_name: 'James',
            last_name: 'Mitra',
            designation: 'Founder & MD JBM',
            id: 586,
            linkedin: "jamesmitra-jbm/"
        },
        {
            img: 'https://d1hrwyvk1pin3o.cloudfront.net/AcuCustom/Sitename/DAM/020/Roshan_Awatar_Main.jpg',
            name: 'Roshan Awatar',
            first_name: 'Roshan',
            last_name: 'Awatar',
            designation: 'Data and Analytics Director',
            id: 398,
            linkedin: "roshan-awatar-0250351/"
        },
        {
            img: '/careerpathsTeam/david.jpeg',
            name: 'David Milton ',
            first_name: 'David',
            last_name: 'Milton',
            designation: 'Executive Coach',
            id: 945,
            linkedin: "david-milton-coachingformanagers/"
        },
        {
            img: '/careerpathsTeam/robert.jpeg',
            name: 'Robert Allard',
            first_name: 'Robert',
            last_name: 'Allard',
            designation: 'Strategy and M&A Advisor',
            id: 658,
            linkedin: "allardrob/"
        },

    ])
    return (
      <div>
        <div className="xl:container mx-auto">
          <div className="xl:container mx-auto">
            <div className="my-4 mx-2 rounded-xl p-4 md:p-16 flex flex-col md:flex-row justify-center md:my-16">
              <div className="w-full md:w-10/12 flex flex-col items-center justify-center">
                <h1 className="hidden">
                  About CareerPaths, an online mentoring platform
                </h1>
                <h1
                  className="text-black md:ml-4 md:text-center font-bold text-2xl md:text-6xl xl:text-7xl"
                 
                  style={{ fontFamily: "Lato Bold" }}
                >
                  We make impossible career connections possible
                </h1>
                <h2 className="text-textFullDark ml-2 md:ml-4 text-xl md:text-2xl mt-3 md:text-center w-full  md:w-10/12">
                  {CompanyTitle} is one of the world’s largest career mentoring
                  platforms, developed to give you access to otherwise
                  inaccessible mentors and help you build your dream career
                </h2>
              </div>
            </div>
          </div>

          <div className="my-4 mx-2 rounded-xl p-4 md:p-16 flex flex-col-reverse md:flex-row items-center justify-center bg-white">
            <div className="w-full md:w-4/12 lg:w-2/5 xl:1/2 flex justify-end md:mr-10 rounded-lg ">
              <img
                src="/meetingabout.png"
                alt="Meeting"
                className="object-cover rounded-3xl"
                style={{
                  width: "430px",
                }}
              />
            </div>

            <div className="w-full md:w-1/2 mt-4 md:mt-0 md:flex flex-col ">
              <h1
                className="text-black ml-2 md:ml-4 font-bold text-2xl xl:text-5xl mb-4"
                // style={{ fontFamily: "Cerebri Extra Bold" }}
                style={{ fontFamily: "Lato Extra Bold" }}
              >
                Our Mission
              </h1>
              <div className="flex items-start my-1 md:my-2">
                <p className="ml-2 mt-2 md:ml-4 text-xl  text-textFullDark ">
                  Careers are built through networks. Many successful people are
                  in the right place, at the right time, speaking to the right
                  person. Here at CareerPaths, it’s our mission to give you
                  access to world class professionals working in some of the top
                  institutions globally.
                </p>
              </div>
              <div className="flex items-start my-1 md:my-2">
                <h2 className="ml-2 mt-2 md:ml-4 text-xl text-textFullDark ">
                  We help level the playing field for those looking to progress
                  their career in competitive industries.
                </h2>
              </div>
              <div className="flex items-start my-1 md:my-2">
                <h2 className="ml-2 mt-2 md:ml-4 text-xl text-textFullDark">
                  For our mentors, we’ve built a platform to provide a
                  structured way for you to pass on your invaluable experience
                  and give back to the industry you love. Our platform would be
                  nothing without you.
                </h2>
              </div>
              <div className="flex items-center my-1 md:my-2">
                <h2 className="ml-2 mt-2 md:ml-4 text-xl text-textFullDark ">
                  For our users, we hope the sessions you have completely
                  transform your life…
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* Mentors */}
        <div className="w-screen p-4 md:p-8 bg-bgGray">
          <div className="md:container mx-auto flex flex-col md:items-center md:px-16 md:justify-center w-full md:w-2/3 md:p-6">
            <h1 className="text-3xl md:text-5xl font-bold text-left md:text-center mb-2 md:mb-6">
              Meet the team and board advisors
            </h1>
            <h2 className="text-xl md:text-2xl md:text-center text-textDark">
              We put together an amazing team to help build CareerPaths into a
              global career platform spanning industries such as Private Equity,
              Venture Capital, Investment Banking, Tech and Strategy Consulting
            </h2>
          </div>

          <div>
            <div className="md:container md:px-8  mx-auto mt-6">
              <h1 className="text-lg md:text-3xl text-center font-bold ">
                Team
              </h1>
            </div>

            <div className="md:container md:px-8 mx-auto flex items-center justify-center flex-wrap ">
              {mentors.map((mentor, i) => {
                return (
                  <Link
                    to={`https://www.linkedin.com/in/${mentor.linkedin}`}
                    target="_blank"
                  >
                    <div className="flex flex-col items-center justify-center w-full md:w-52 h-72 m-3 cursor-pointer">
                      <div className="md:w-full h-72 self-center cursor-pointer">
                        <img
                          src={mentor.img}
                          alt="Mentor"
                          className="object-cover object-top rounded-lg w-52 h-56"
                        />
                      </div>
                      <p className="text-center font-bold text-xl text-textFullDark mt-4">
                        {mentor.name}
                      </p>
                      <p className="text-center text-sm text-textDark">
                        {mentor.designation}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div>
            <div className="md:container md:px-8  mx-auto my-6">
              <p className="text-lg md:text-3xl text-center font-bold ">
                Board advisors
              </p>
            </div>
            <div className="md:container md:px-8 mx-auto flex items-center justify-center flex-wrap md:pb-64">
              {boardAdvisors.map((mentor, i) => {
                return (
                  <Link
                    to={`https://www.linkedin.com/in/${mentor.linkedin}`}
                    target="_blank"
                  >
                    <div className="flex flex-col items-center justify-center w-full md:w-52 h-72 m-3 cursor-pointer">
                      <div className="md:w-full h-72 self-center cursor-pointer">
                        <img
                          src={mentor.img}
                          alt="Mentor"
                          className="object-cover object-top rounded-lg w-52 h-56"
                        />
                      </div>
                      <p className="text-center font-bold text-xl text-textFullDark mt-4">
                        {mentor.name}
                      </p>
                      <p className="text-center text-sm text-textDark">
                        {mentor.designation}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className="w-full flex justify-center"
          style={{
            backgroundImage: "url(/bgaboutbottom.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="p-8 bg-white shadow-lg w-full md:w-2/3 md:-mt-48 md:mb-48 rounded-xl flex flex-col-reverse md:flex-row items-center justify-center">
            <div className="w-full md:w-1/2 p-4 md:p-12">
              <h3 className="break-words font-bold text-2xl leading-10 text-textFullDark">
                If you’re interested in joining us on our mission to transform
                career advice, drop us an email with your CV and Cover Letter to{" "}
                <a
                  href="mailto:hello@careerpaths.io"
                  className="text-textApp underline"
                >
                  hello@careerpaths.io
                </a>
              </h3>
            </div>
            <div className="">
              <img src="/aboutavatar.png" alt="Avatar" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default AboutUsMain