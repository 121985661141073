import React from "react";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
const Form = (props) => {
  const { userSession15Error, userSession30Error } = props.errors;
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(false);
  console.log(props, "props");
  // const errorSectionRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const handleClearLocation = () => {
    props.setUserLocation("");
  };
  const handleLocationChange = (event) => {
    const value = event.target.value;
    props.setUserLocation(value);

    if (value?.length >= 3) {
      axios
        .get(process.env.REACT_APP_SERVER_PATH + "/googleMapsAutocomplete", {
          params: { input: value },
        })
        .then((response) => {
          const predictions = response.data.predictions;
          if (predictions && predictions?.length > 0) {
            const suggestions = predictions.map(
              (prediction) =>
                prediction.terms[prediction.terms?.length - 2].value
            );
            const uniqueSuggestions = [...new Set(suggestions)];
            setSuggestions(uniqueSuggestions);
          } else {
            setSuggestions([]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleLocationSelect = (value) => {
    props.setUserLocation(value);
    setSuggestions([]);
    // Retrieve the latitude and longitude of the selected location
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
      .then((response) => {
        if (
          response.data &&
          response.data.results &&
          response.data.results[0]
        ) {
          const location = response.data.results[0].geometry.location;
          props.setLat(location.lat);
          props.setLong(location.lng);
        } else {
          console.error("Invalid response from geocode API:", response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <form className="pt-6 mb-4 font-bold">
      <div className="mb-4">
        <label className="block text-textFullDark text-sm mb-2" for="firstName">
          First Name
        </label>
        <input
          className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
          id="firstName"
          type="text"
          placeholder="Enter first name"
          value={props.userFirstName}
          onChange={(e) => props.setUserFirstName(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <label className="block text-textFullDark text-sm mb-2" for="lastName">
          Last Name
        </label>
        <input
          className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none"
          id="lastName"
          type="text"
          placeholder="Enter last name"
          value={props.userLastName}
          onChange={(e) => props.setUserLastName(e.target.value)}
        />
      </div>

      <div className="mb-6">
        <label
          className="block text-textFullDark text-sm mb-2"
          for="Phonenumber"
        >
          Phone number
        </label>
        <input
          className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none"
          id="Phonenumber"
          type="number"
          placeholder="Enter the number"
          value={props.userPhonenumber}
          onChange={(e) => props.setPhonenumber(e.target.value)}
        />
      </div>

      {props.user.User?.type === "professional" ? (
        <div>
          <div className="mb-6">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="jobTitle"
            >
              Job Title
            </label>
            <input
              className="text-sm border border-borderGray rounded w-full py-3 px-3 text-textFullDark  focus:outline-none"
              id="jobTitle"
              type="text"
              placeholder="Enter job title"
              value={props.userJobTitle}
              onChange={(e) => props.setUserJobTitle(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="location"
            >
              Location
            </label>
            <div className="mt-3 relative w-full">
              <input
                type="text"
                className={`text-sm border rounded border-borderGray w-full py-3 px-3 pr-10 text-textFullDark  focus:outline-none`}
                placeholder="Your location"
                value={props.userLocation}
                onChange={handleLocationChange}
              />
              {props.userLocation && suggestions?.length === 0 && (
                <button
                  className="absolute top-0 right-0 h-full w-10 text-center text-gray-400 hover:text-gray-600"
                  onClick={handleClearLocation}
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="x-circle w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM5.293 6.707a1 1 0 111.414-1.414L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 11-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
              {suggestions?.length > 0 && (
                <div className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none">
                  {suggestions.map((location) => (
                    <div
                      key={location}
                      className="cursor-pointer py-1 px-3 hover:bg-gray-200"
                      onClick={() => handleLocationSelect(location)}
                    >
                      {location}
                    </div>
                  ))}
                </div>
              )}
              {error && (
                <p className="text-xs text-red-500 mt-2">
                  Please enter a valid location
                </p>
              )}
            </div>
          </div>
          <div className="mb-6">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="15minPrice"
            >
              15 minutes price
            </label>
            <input
              className="text-sm border border-borderGray rounded w-full py-3 px-3 text-textFullDark  focus:outline-none"
              id="location"
              type="text"
              placeholder="15 minutes price"
              value={props.userSession15}
              onChange={(e) => props.setSession15(e.target.value)}
            />
            {userSession15Error ? (
              <p className="text-red-500 mt-2 ">Minimum price £5</p>
            ) : null}
          </div>
          <div className="mb-6">
            <label
              className="block text-textFullDark text-sm mb-2"
              for="30minPrice"
            >
              30 minutes price
            </label>
            <input
              className="text-sm border border-borderGray rounded w-full py-3 px-3 text-textFullDark  focus:outline-none"
              id="location"
              type="text"
              placeholder="30 minutes price"
              value={props.userSession30}
              onChange={(e) => props.setSession30(e.target.value)}
            />
            {userSession30Error ? (
              <p className="text-red-500 mt-2 ">Minimum price £5</p>
            ) : null}
            <div className="mb-6">
              <label
                className="block text-textFullDark text-sm mb-2 mt-6"
                for="bio"
              >
                Tell us about your professional career. Talk about what you
                would want your customers to know. Remember to keep it short and
                a sweet!
              </label>
              <textarea
                maxlength="1200"
                className="resize-none border-borderGray rounded-md text-sm border  w-full h-36 py-3 px-3 text-textFullDark  focus:outline-none"
                id="bio"
                type="text"
                placeholder="Enter Bio"
                value={props.userBio}
                onChange={(e) => props.setBio(e.target.value)}
                onKeyDown={handleKeyDown}
              ></textarea>
              {props?.userBio?.length < 100 && (
                <>
                  <p
                    className="text-xs text-red-400"
                    style={{ marginRight: "auto" }}
                  >
                    {props.message &&
                      "Input must be at least 100 characters long"}
                    {/* Input must be at least 100 characters long */}
                  </p>
                  <p className="text-sm text-green-500 mt-5">
                    {props.userBio?.length} / 1200 characters
                  </p>
                </>
              )}
              {props?.userBio?.length > 100 && props.userBio?.length < 1200 && (
                <>
                  <p
                    className="text-xs text-red-400"
                    style={{ marginRight: "auto" }}
                  >
                    {props.message &&
                      " Input must be at most 1200 characters long"}
                    {/* Input must be at most 1200 characters long */}
                  </p>
                  <p className="text-sm text-green-500 mt-5">
                    {props?.userBio?.length} / 1200 characters
                  </p>
                </>
              )}
              {props?.userBio?.length >= 1200 && (
                <p className="text-sm text-red-500">
                  {props.message && " Max characters length reached"}
                  {/* Max characters length reached */}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {props?.user?.type === "customer" ? (
        <div>
          <div className="mb-6">
            <label className="block text-textFullDark text-sm" for="location">
              Location
            </label>
            <div className="mt-3 relative w-full">
              <input
                type="text"
                className={`text-sm border rounded border-borderGray w-full py-3 px-3 pr-10 text-textFullDark  focus:outline-none`}
                placeholder="Your location"
                value={props?.userLocation}
                onChange={handleLocationChange}
              />
              {props?.userLocation && suggestions?.length === 0 && (
                <button
                  className="absolute top-0 right-0 h-full w-10 text-center text-gray-400 hover:text-gray-600"
                  onClick={handleClearLocation}
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="x-circle w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM5.293 6.707a1 1 0 111.414-1.414L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 11-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
              {suggestions?.length > 0 && (
                <div className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none">
                  {suggestions?.map((location) => (
                    <div
                      key={location}
                      className="cursor-pointer py-1 px-3 hover:bg-gray-200"
                      onClick={() => handleLocationSelect(location)}
                    >
                      {location}
                    </div>
                  ))}
                </div>
              )}
              {error && (
                <p className="text-xs text-red-500 mt-2">
                  Please enter a valid location
                </p>
              )}
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-textFullDark text-sm mb-2" for="bio">
              Tell us a bit about yourself. Talk about what you're interested in
              gaining from a conversation with a professional.
            </label>
            <div ref={props.errorSectionRef}>
              <textarea
                maxlength="1200"
                className="resize-none border-borderGray rounded-md text-sm border  w-full h-36 py-3 px-3 text-textFullDark  focus:outline-none"
                id="bio"
                type="text"
                placeholder="Enter Bio"
                value={props?.userBio}
                onChange={(e) => props.setBio(e.target.value)}
                onKeyDown={handleKeyDown}
              ></textarea>

              {props.userBio?.length < 100 && (
                <>
                  <p
                    className="text-xs text-red-400"
                    style={{ marginRight: "auto" }}
                  >
                    {props.message &&
                      "Input must be at least 100 characters long"}
                    {/* Input must be at least 100 characters long */}
                  </p>
                  <p className="text-sm text-green-500 mt-5">
                    {props.userBio?.length} / 1200 characters
                  </p>
                </>
              )}
              {props.userBio?.length > 100 && props.userBio?.length < 1200 && (
                <>
                  <p
                    className="text-xs text-red-400"
                    style={{ marginRight: "auto" }}
                  >
                    {props.message &&
                      "Input must be at most 1200 characters long"}
                    {/* Input must be at most 1200 characters long */}
                  </p>
                  <p className="text-sm text-green-500 mt-5">
                    {props.userBio?.length} / 1200 characters
                  </p>
                </>
              )}
              {props.userBio?.length >= 1200 && (
                <p className="text-sm text-red-500">
                  {props.message && "Max characters length reached"}
                  {/* Max characters length reached */}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </form>
  );
};

export default Form;
