import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { pricetoDecimal } from '../../../price'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Colors from '../../../Colors/Colors'
import { CompanyTitle } from '../../../settings'
import { useLocation } from 'react-router-dom'

const SideContent = (props) => {
    const [profile, setProfile] = useState([]);
    let navigate = useNavigate()
    const location = useLocation();

    const [latestCompany, setLatestCompany] = useState({});

    const getLatestCompany = (arr) => {
      if (arr.length > 0) {
        const data = arr.sort(function (a, b) {
          var keyA = new Date(a.start_date),
            keyB = new Date(b.start_date);
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
       setLatestCompany(data[0]);
      } else {
       setLatestCompany(null);
  
      }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${props.location.id}`).then((response) => {
            setProfile(response.data)
            getLatestCompany(response.data?.Experiences)
        })
    }, [])

    return (
        <div className="sm:w-full md:w-1/3 rounded bg-white sm:p-2 md:p-6 px-8">
            {/* Back Buttons */}
            <button className="bg-bgGray hover:bg-bgGray rounded-full hover:text-white" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 p-1" fill="none" viewBox="0 0 24 24" stroke={Colors.darkGray}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                </svg>
            </button>
            {/* Profile and Name */}
            <div className="flex items-center mt-4">
                <div className="w-1/3 h-20 rounded-full  flex items-center justify-center">
                {profile?.is_anonymous ?
                    <img 
                    src="/anonymousavatar.png" 
                    alt="alon" className="w-20 h-20 rounded-full  object-cover border border-borderApp" />
                :    
                  <img 
                    src={profile.User?.profile_photo ? profile.User?.profile_photo : ' /avatar.png'} 
                    alt="alon" className="w-20 h-20 rounded-full  object-cover border border-borderApp" />
                }
                
                </div>
                <div className='w-2/3'>
                {profile?.is_anonymous ?
                    <p className="text-xl font-bold text-textFullDark  truncate p-2 leading-tight">{latestCompany?.job}</p>
                    :
                    <p className="text-xl font-bold text-textFullDark  p-2 leading-tight">{profile.User?.first_name} {profile.User?.last_name}</p>
                }
                </div>
            </div>
            {/* Text */}
            <div className="mt-4">
            {profile?.is_anonymous ?
                <h1 className="text-lg font-bold text-textFullDark">{CompanyTitle} live video calls with {latestCompany?.job}</h1>
                :
                <h1 className="text-lg font-bold text-textFullDark">{CompanyTitle} live video calls with {profile.User?.first_name} {profile.User?.last_name}</h1>
                }
            </div>
            {/* Time and Money */}
            <div className="flex">
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={Colors.darkGray}>
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                    </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3">{props.location.minutes} min</p>
            </div>
            <div className="flex mt-1">
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={Colors.darkGray}>
                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                        <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                    </svg>
                </button>
                {location.state?.isUserEligibleForFreeCall ?
                <p className="text-sm text-textFullDark ml-3">Free</p>
                    :
                <p className="text-sm text-textFullDark ml-3">{pricetoDecimal(props.propslocationprice)}</p>
                }
          
            </div>
            {props.topicBooking !== null ? <div className="mt-2 flex">
                {/* <p className="text-sm text-gray-700">Web Conferencing details provided upon confirmation</p> */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={Colors.darkGray}>
                    <path fillRule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clipRule="evenodd" />
                </svg>
                <p className="text-sm text-textFullDark ml-3">{props.topicBooking !== null ? props.topicBooking.label : ''}</p>
            </div> : null}
            {props.description.length > 0 && <div className="flex mt-2 items-start">
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={Colors.darkGray}>
                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                        <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                    </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3 break-all">{props.description}</p>
            </div>}
            {props.dateBooking !== null ? <div className="flex mt-2">
                {/* <p className="text-sm text-gray-700">Web Conferencing details provided upon confirmation</p> */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={Colors.darkGray}>
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
                <p className="ml-3 text-sm text-textFullDark">{props.dateBooking !== null ? `${moment(props.dateBooking).format('dddd')}, ${props.dateBooking.getDate()} ${moment(props.dateBooking).format('MMMM')} ${props.timeSlotBooking !== null ? props.timeSlotBooking : ''} ` : ''}</p>
            </div> : null}
            <div className="flex items-start mt-1">
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={Colors.darkGray}>
                        <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                    </svg>
                </button>
                <p className="text-sm text-textFullDark ml-3">Following confirmation of your booking, video call directly from the app or website on the date and time of your meeting</p>
            </div>
        </div>
    )
}

export default SideContent
