import React from "react";
import AudioTrack from "../AudioTrack";
import VideoTrack from "./VideoTrack1";
import { useTrack } from "use-twilio-video";
import "./localParticipant.css";
function Participant({ participant, name, flag }) {
  const { videoOn, videoTrack, audioTrack } = useTrack({
    participant,
  });

  return (
    <div className="rounded-lg">
      {videoOn ? (
        <div
          style={{ minHeight: "100%", minWidth: "100%"  }}
          className="items-start bg-gray-500 rounded-lg flex justify-start"
        >
          <VideoTrack track={videoTrack} name={name} />
        </div>
      ) : flag === 0 ? (
        <div className="rounded-xl w-72 h-48 bg-gray-600">
          <div className="absolute bg-white m-4 px-4 py-2 rounded-full">
            {name}
          </div>
        </div>
      ) : (
        <div
          style={{ minHeight: "100%", minWidth: "100%" }}
          className="bg-gray-600 right-2 rounded-xl"
        >
          <div className="absolute bg-white m-4 px-4 py-2 rounded-full">
            {name}
          </div>
        </div>
      )}
      <br />
      {/* {audioOn ? ( */}
        <AudioTrack track={audioTrack} />
      {/* // ) : (
      //   <img src="/mute.svg" alt="audio off" className="-mt-2 w-6 h-6" />
      // )} */}
    </div>
  );
}

export default Participant;
