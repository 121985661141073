import React from "react";
import Skeleton from "react-skeleton-loading";
import { useNavigate } from 'react-router-dom';

function PrivateEquitySkeleton({ name }) {
  const navigate = useNavigate();

  return (
    <div className="md:container mx-auto my-6 sm:px-2 xl:px-16">
      <div className=" flex flex-col md:flex-row w-full my-4 rounded-lg  items-center justify-between p-5 bg-gray-200">
        <div class="flex flex-col md:flex-row">
          <div className="w-full lg:w-2/3 p-2 md:p-5 ">
            <h1 className="text-3xl font-bold">
              {name ? name : <Skeleton height={40} width={500} />}
            </h1>
            <p className="text-textFullDark py-5">
              {name ? (
                "The course deals with the analysis of the Private Equity and Venture Capital business."
              ) : (
                <Skeleton height={20} width={300} />
              )}
            </p>

            <div className="w-full md:w-1/2 md:hidden flex items-center justify-center ">
              {name ? (
                <img
                  src="/events/course-laptop1.svg"
                  alt=""
                  className="w-full"
                />
              ) : (
                <Skeleton height={200} width={300} />
              )}
            </div>

            <div className="w-full flex items-center justify-center md:justify-start">
              {name ? (
                <button
                  onClick={() => navigate("/course")}
                  className="px-16 py-3  w-full md:w-auto font-bold text-white  bg-bgAppColor rounded-md"
                >
                  See course details
                </button>
              ) : (
                <Skeleton height={50} width={200} />
              )}
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 hidden md:flex md:items-start md:justify-start lg:items-end lg:justify-end ">
          {name ? (
            <img
              src="/events/course-laptop1.svg"
              alt=""
              className="md:w-full lg:w-1/2 self-end"
            />
          ) : (
            <Skeleton height={200} width={300} />
          )}
        </div>
      </div>
    </div>
  );
}

export default PrivateEquitySkeleton;
