import moment from 'moment'
import React from 'react'

const Education = (props) => {

    return (
        <div>
            <div className="py-2">
                <div className="flex items-start">
                    <div>
                        <div className="w-16 h-16 rounded-full bg-bgAppColor border flex justify-center items-center">
                            <img src={props.universityImage ? props.universityImage : '/educationplaceholder.png'} alt="" className="w-16 h-16 rounded-full object-cover " />
                        </div>
                    </div>
                    <div className="flex flex-col ml-5 " >
                        <p className="text-lg  text-textFullDark font-bold">{props.education_type}</p>
                        <p className="text-sm text-textFullDark">{props.universityName}</p>
                        <p className="text-sm text-textFullDark">{`${moment(props.startDate).format("MMM-YYYY")} - ${moment(props.endDate).format("MMM-YYYY")}`}</p>

                    </div>
                </div>
            </div>
            <hr className="my-2" />
        </div>
    )
}

export default Education
