import React, { useState } from "react";
import "./User.css";
// import moment from "moment";
import { pricetoDecimal } from "../../price";
import { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import ReactTooltip from "react-tooltip";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton, { SkeletonTheme } from "react-skeleton-loading"

const User = ({
  name,
  designation,
  time,
  price,
  image,
  reviewCount,
  isCharity,
  intro_link,
  location,
  isAnonymous,
  Experiences,
  avgRating,
  width,
  years_of_experience
}) => {
  const [experience, setExperience] = useState({});
  const [isLoading, setLoading] = useState(true);

  // const getDecimalsWithoutRounding = (value, numberOfDecimals) => {
  //   const stringValue = value?.toString();
  //   const dotIdx = stringValue?.indexOf(".");
  //   if (dotIdx) {
  //     return parseFloat(stringValue.slice(0, dotIdx + numberOfDecimals + 1));
  //   } else {
  //     return value;
  //   }
  // };

  const [latestCompany, setLatestCompany] = useState({});

  const getLatestCompany = (arr = Experiences) => {
    if (arr.length > 0) {
      const data = arr.sort(function (a, b) {
        var keyA = new Date(a.start_date),
          keyB = new Date(b.start_date);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setLatestCompany(data[0]);
      setLoading(false);
    } else {
      setLatestCompany(null);

    }
  };

  useEffect(() => {
    getLatestCompany();
  }, [])

  const [yearOfExperience, setYearOfExperience] = useState(0);
  const [monthOfExperience, setMonthOfExperience] = useState(0);

  const calcExperience = (years_of_experience) => {
    // convert to string for splitting and then convert to number for conditions
    const yearsAndMonths = String(years_of_experience).split(".")
    setYearOfExperience(Number(yearsAndMonths[0]))
    setMonthOfExperience(Number(yearsAndMonths[1]))
  }

  useEffect(() => {
    calcExperience(years_of_experience)
    setExperience(Experiences.length > 0 && Experiences[0]);
    ReactTooltip.rebuild();
  }, [isAnonymous, isCharity, intro_link]);

  return (
    // h-full will move the text to end
    <div className="rounded w-full flex flex-col justify-between">
      <div className="relative">
        <div className="" data-tip="This profile is anonymous">
          {isAnonymous === 1 && (
            <div className="absolute right-0  m-2">
              <img src="/anonymous.png" alt="icon" className="w-6" />
            </div>
          )}
        </div>

        <div>
          {isLoading ? (
            <span className="flex md:top-40 top-48 absolute items-center left-1  rounded-full px-2 ">
              <div className="">
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <p>
                    <Skeleton height={15} width={70} />
                  </p>
                </SkeletonTheme>
              </div>
            </span>) : (
            <div>
              {intro_link &&
                <span className="flex md:top-40 top-48 absolute items-center left-1  rounded-full px-2 ">
                  <div className="">
                    <img src="/showvideo.png" alt="video icon" className="w-6" />
                  </div>
                </span>
              }
            </div>
          )
          }
        </div>



        {isLoading ? (
          <span className="flex md:top-40 top-48 absolute items-center right-1 rounded-full px-2">
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <p>
                <Skeleton height={15} width={70} />
              </p>
            </SkeletonTheme>
          </span>
        ) : (
          <span className="flex md:top-40 top-48 absolute items-center right-1 bg-bgDark rounded-full px-2 ">
            {avgRating > 0 ? (
              <ReactStars
                value={5}
                size={12}
                color2="#ffd700"
                activeColor="#ffd700"
                isHalf={true}
                edit={false}
                count={1}
              />
            ) : (
              <ReactStars
                value={0}
                size={12}
                activeColor="#ffd700"
                isHalf={true}
                edit={false}
                count={1}
              />
            )}
            &nbsp;&nbsp;
            <div className="flex items-center justify-center">
              <p className="text-xs text-white -ml-1">
                {avgRating ? (+avgRating).toFixed(1) : Math.trunc(0).toFixed(1)}
                {/* {avgRating ? getDecimalsWithoutRounding(avgRating) : Math.trunc(0).toFixed(1)} */}
              </p>
              <span className="text-xs text-gray-100 ml-1">
                ({reviewCount ? reviewCount : 0})
              </span>
            </div>
          </span>
        )
        }


        {isAnonymous ? (
          <div>
            <div
              className="rounded-lg w-full flex items-center justify-center sm:h-56 md:h-48 object-cover border"
              style={{
                backgroundImage: "url(/anonymousbg.png)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <img
                alt="photoss"
                className="w-44 h-44 self-center rounded-full object-cover"
                src={
                  latestCompany?.profile_photo
                    ? latestCompany?.profile_photo
                    : "/anonymousavatar.png"
                }
                threshold={20}
              />
            </div>
          </div>
        ) : (
          <div>
            {isLoading ? (
              <Skeleton height={190} style={{ borderRadius: "1rem" }} />
            ) : (
              <img
                className="rounded-lg w-full sm:h-72 md:h-48 object-cover border"
                src={image ? image : " /avatar.png"}
                alt={name}
                threshold={20}
              />
            )
            }
          </div>
        )}
      </div>

      <div className="py-1 ">
        <div className="w-full flex justify-between">
          <span className={`flex flex-col ${isCharity === 1 ? "w-3/4" : "sm:w-11/12"}`}>
            {isAnonymous === 1 ? (
              <h2 className="text-textFullDark font-bold text-md truncate">
                {experience.company}
              </h2>
            ) : (
              <div>
                {isLoading ? (
                  <p className="text-textDark text-xs truncate mb-2">
                    <Skeleton height={15} width={120} style={{ borderRadius: "1rem" }} />
                  </p>
                ) : (
                  <h2 className="text-textFullDark font-bold text-md truncate">
                    {name}
                  </h2>
                )
                }
              </div>

            )}
            <div>
              {isLoading ? (
                <p className="text-textDark text-xs truncate">
                  <Skeleton height={15} width={120} style={{ borderRadius: "1rem" }} />
                </p>
              ) : (
                <p className="text-textDark text-xs truncate">
                  {/* if a professional has experience in Years show years else nothing and same for months */}
                  {yearOfExperience > 1 || monthOfExperience ?
                    <p>
                      {yearOfExperience > 0 ? `${yearOfExperience} Years` : ""}
                      {yearOfExperience > 0 && monthOfExperience ? " and " : ""}
                      {monthOfExperience > 0 ? `${monthOfExperience} months` : ``} experience
                    </p>
                    :
                    <p>
                      {yearOfExperience < 1 && !monthOfExperience ? "experience unavailable " : ""}
                    </p>
                  }
                </p>
              )
              }
            </div>

          </span>


          <div>
            {isLoading ? (
              <p className="text-textDark text-xs truncate">
                <Skeleton height={15} width={20} style={{ borderRadius: "1rem" }} />
              </p>
            ) : (
              <div>
                {isCharity === 1 && (
                  <div
                    className="relative"
                    data-tip="Mentor donates proportion of earnings to charity"
                  >
                    <ReactTooltip place="bottom" className="w-36 text-xs" />

                    <div className="right-0">
                      <img src="/charity.svg" alt="charity" className="w-4 mx-1" />
                    </div>
                  </div>
                )}
              </div>
            )
            }
          </div>

        </div>
        {/* )} */}

        <div className="flex flex-col mt-3">
          <div>
            {isLoading ? (
              <Skeleton height={15} width={70} style={{ borderRadius: "1rem" }} />
            ) : (
              <p className="text-textFullDark font-bold text-xs truncate">
                {experience.company}
              </p>
            )
            }
          </div>
          <div>
            {isLoading ? (
              <p className="text-textDark text-xs truncate">
                <Skeleton height={15} width={70} style={{ borderRadius: "1rem" }} />
              </p>
            ) : (
              <p className="text-textDark text-xs truncate">
                {String(designation).length > 30 && width <= 52
                  ? `${String(designation).slice(0, 30)} ...`
                  : String(designation)}
              </p>
            )
            }
          </div>
        </div>

        <div className="flex items-center justify-between mt-2">
          <span className="flex items-start justify-between text-ellipsis">
            {isLoading ? (
              <p className="text-textDark text-xs truncate mr-2 mt-0.4">
                <Skeleton height={15} width={20} style={{ borderRadius: "1rem" }} />
              </p>
            ) : (
              <svg
                className="h-4 w-4 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  d="M18,4.48a8.45,8.45,0,0,0-12,12l5.27,5.28a1,1,0,0,0,1.42,0L18,16.43A8.45,8.45,0,0,0,18,4.48ZM16.57,15,12,19.59,7.43,15a6.46,6.46,0,1,1,9.14,0ZM9,7.41a4.32,4.32,0,0,0,0,6.1,4.31,4.31,0,0,0,7.36-3,4.24,4.24,0,0,0-1.26-3.05A4.3,4.3,0,0,0,9,7.41Zm4.69,4.68a2.33,2.33,0,1,1,.67-1.63A2.33,2.33,0,0,1,13.64,12.09Z"
                  fill="#c0c0c0"
                ></path>
              </svg>
            )
            }
            <p className="text-textFullDark text-sm text-ellipsis">
              {location && (
                <div>
                  {isLoading ? (
                    <p>
                      <Skeleton height={15} width={70} style={{ borderRadius: "1rem" }} />
                    </p>
                  ) : (
                    <div>
                      <p>
                        {window.innerWidth <= 320 ? `${String(location).slice(0, 3)}...` : String(location).length > 9
                          ? `${String(location).slice(0, 9)}...`
                          : String(location)}
                      </p>
                    </div>
                  )
                  }
                </div>
              )}
            </p>
          </span>
          <span className="flex items-center justify-between">
            {price ? (
              <div>
                {isLoading ? (
                  <div className="flex items-center justify-between mt-1">
                    <p className="font-semibold md:text-lg sm:text-sm text-textFullDark">
                      <Skeleton height={15} width={60} style={{ borderRadius: "1rem" }} />
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between">
                    <p className="text-textDark text-xs truncate mr-1">from</p>
                    <p className="font-semibold md:text-lg sm:text-sm text-textFullDark">
                      {pricetoDecimal(price)}
                    </p>
                  </div>
                )
                }
              </div>
            ) : (
              <></>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default User;
