
export function RatingHandler(ratingFromApi){
    let rating = ratingFromApi;
    if (ratingFromApi > 0.3 && ratingFromApi < 0.7) {
      rating = 0.5;
    } else if (ratingFromApi > 0.7 && ratingFromApi < 1.3) {
      rating = 1;
    } else if (ratingFromApi > 1.3 && ratingFromApi < 1.7) {
      rating = 1.5;
    } else if (ratingFromApi > 1.7 && ratingFromApi < 2.3) {
      rating = 2;
    } else if (ratingFromApi > 2.3 && ratingFromApi < 2.7) {
      rating = 2.5;
    } else if (ratingFromApi > 2.7 && ratingFromApi < 3.3) {
      rating = 3;
    } else if (ratingFromApi > 3.3 && ratingFromApi < 3.7) {
      rating = 3.5;
    } else if (ratingFromApi > 3.7 && ratingFromApi < 4.3) {
      rating = 4;
    } else if (ratingFromApi > 4.3 && ratingFromApi < 4.7) {
      rating = 4.5;
    } else if (ratingFromApi > 4.7) {
      rating = 5;
    } else {
      rating = 0;
    }    
    return rating;
}
