import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../../../settings'
import Docx from "../../../Images/wd.png"
import Pdf from "../../../Images/pdf.png"
import Excel from "../../../Images/excel 1.png"
import axios from 'axios'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Hey from "./abc.pdf"
import Loader from "react-loader-spinner";
import Colors from '../../../Colors/Colors'
import { useNavigate } from 'react-router-dom'
const PEBProfile = () => {
    const [files, setFiles] = useState([])
    const [subscription, setSubscription] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        const FetchFiles = () => {
            setisLoading(true);
            axios
                .get(`${process.env.REACT_APP_SERVER_PATH}/files`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
                    },
                })
                .then((response) => {
                    console.log(response.data.rows)
                    setFiles(response.data.rows)
                    setisLoading(false);

                })
                .catch((e) => {
                    console.log("Error in professional transaction fetching");
                    setisLoading(false);

                });
        }
        FetchFiles();
    }, [])

    const handlePEB = () => {
        navigate("/private-equity-bundle")
    }

    useEffect(() => {
        const FetchSubs = () => {
            axios
                .get(`${process.env.REACT_APP_SERVER_PATH}/users/get/user-subscription/${jwt_decode(localStorage.getItem("cp_web_token")).id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
                    },
                })
                .then((response) => {
                    setSubscription(response.data.status);
                })
                .catch((e) => {
                    console.log("Error in professional transaction fetching");
                });
        }
        FetchSubs();
    }, [])

    const downloadFile = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.rel = "noopener noreferrer"; // For security reasons when using _blank target

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
      <>
        <ToastContainer />
        <div className="mb-6 bg-white sm:p-2 md:p-4 rounded">
          <Helmet>
            <title>PEB | Dashboard | {CompanyTitle}</title>
            <meta
              name={`PEB | Dashboard | ${CompanyTitle}`}
              content={`PEB | Dashboard | ${CompanyTitle}`}
            />
          </Helmet>

          {isLoading ? (
            <div className="w-full flex justify-center items-center h-full">
              <Loader width={30} height={30} type="Oval" color={Colors.blue} />
            </div>
          ) : (
            <>
              {subscription === "active" ? (
                <div>
                  {/* <p className="font-bold mt-4 text-textFullDark text-2xl">File's</p> */}
                  {/*        <div className='p-2'>
                                    {files.map((i) => (
                                        <>
                                            {i.status === "Published" && (
                                                <>
                                                    <div className='flex justify-between mt-5'>
                                                        <div className='flex flex-row'>
                                                            {i.url.includes('.doc') && <img src={Docx} className='h-12' /> }
                                                            {i.url.includes('.pdf') && <img src={Pdf} className='h-12' /> }
                                                            {i.url.includes('.xlsx') && <img src={Excel} className='h-12' /> }
                                                            <div className='flex flex-col ml-3'>
                                                                <p className='text-lg'>
                                                                    {i.name}
                                                                </p>
                                                                <p className='text-grayColor'>
                                                                    {moment(i.createdAt).format("DD MMMM, YYYY")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-row gap-2 ml-auto'>
                                                            <button
                                                                className="inline-flex items-center text-white justify-center mt-3 px-10 w-full py-2 bg-bgBlack text-sm font-medium rounded-lg"
                                                                onClick={() => window.open('https://docs.google.com/viewer?url=' + i.url, '_blank')}
                                                            >
                                                                View
                                                            </button>
                                                            <Link
                                                                className="inline-flex items-center text-white justify-center mt-3 px-8 w-full py-2 bg-bgAppColor text-sm font-medium rounded-lg"
                                                                // onClick={() => downloadFile(i.url, i.name)}
                                                                to={i.url}
                                                                download="Example-PDF-document"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Download
                                                            </Link>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                    <hr className='bg-hrColor mt-6' />
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                                */}
                  <div className="p-2">
                    <h2
                      className="text-lg mb-4 text-black"
                      style={{ fontFamily: "Lato Bold" }}
                    >
                      Bundle Pre-order Successfull
                    </h2>
                    <p>
                      Thank you for pre-ordering our bundle! We are thrilled to
                      offer you an amazing collection of content that will be
                      available on 1st January.
                    </p>
                    <h2
                      className="text-lg mt-3 text-black"
                      style={{ fontFamily: "Lato Bold" }}
                      ss
                    >
                      What to Expect:
                    </h2>
                    <ol className="list-decimal px-4 py-2 text-sm">
                      <li>
                        <b style={{ fontFamily: "Lato Bold" }}>
                          {" "}
                          Bundle Availability:{" "}
                        </b>{" "}
                        The bundle will be accessible in your dashboard on 1st
                        January. Mark your calendars and get ready to explore
                        its exciting content!
                      </li>
                      <li className="mt-2">
                        <b style={{ fontFamily: "Lato Bold" }}>
                          {" "}
                          Refreshed Content:{" "}
                        </b>{" "}
                        We are constantly updating and enhancing the bundle's
                        content. Rest assured that you'll have access to the
                        latest and greatest materials when the bundle becomes
                        available.
                      </li>
                    </ol>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                    role="alert"
                  >
                    <svg
                      class="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Oops</span>
                    <div>
                      <span class="font-medium">
                        You do not have any active subscription
                      </span>
                      <ul class="mt-1.5 ml-0 list-disc list-inside">
                        <li>
                          To access content please purchase CareerPaths Private
                          Equity Bundle{" "}
                          <span
                            className="cursor-pointer underline text-blue-500"
                            onClick={handlePEB}
                          >
                            here
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
}

export default PEBProfile;
