import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleCharityModal,
  toggleThankyouModal,
} from "../../../../Redux/reducers/charity/charitySlice";
import LearnMoreModal from "./LearnMoreModal";
import ThankyouModal from "./congratulationModal";

const RegisteredOrganizations = ({ organizatoins }) => {
  const dispatch = useDispatch();
  const isLoading = false;
  const isCharityModal = useSelector((state) => state.charity.isCharityModal);
  const isThankyouModal = useSelector((state) => state.charity.isThankyouModal);
  const [isDonate, setIsDonate]  = useState();

  const toggleCharityModal1 = (isDonate) => {
    setIsDonate(isDonate)
    dispatch(toggleCharityModal());
  };

  const toggleThankyouModal1 = () => {
    dispatch(toggleThankyouModal());
  };

  // const [organizations, setOrganizations] = useState([{}]);
  return (
    <section className="text-gray-600 body-font">
      {isCharityModal && (
        <LearnMoreModal
          toggle={toggleCharityModal1}
          overlay="overlay"
          modal="modal"
          isLoading={isLoading}
          isDonate={isDonate}
          />
      )}

      {isThankyouModal && (
        <ThankyouModal
          toggle={toggleThankyouModal1}
          overlay="overlay"
          modal="modal"
          isLoading={isLoading}
        />
      )}

      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-wrap -m-4">
          {organizatoins?.map((organization, index) => (
            <div className="p-4 w-full " key={index}>
              <div className="h-full border-2 border-gray-200 bg-white border-opacity-60 rounded-lg overflow-hidden">
                <div>
                  <div className="flex absolute flex-wrap justify-starts items-center m-4">
                    {/* <div className="text-xs mr-2 py-1.5 px-4 text-white bg-bgAppColor rounded-md">
                      Education
                    </div>
                    <div className="text-xs mr-2 py-1.5 px-4 text-white bg-bgAppColor rounded-md">
                      Food
                    </div> */}
                  </div>
                  <img
                    className="w-full object-cover object-center"
                    // src="https://dummyimage.com/720x400"
                    src="/charity/inspire.png"
                    alt="blog"
                  />
                </div>

                <div className="p-6">
                  <h1 className="title-font text-xl flex font-extrabold text-green-600 mb-3">
                    {/* {organization.name} */} Inspire
                    <h1 className="text-red-600">!</h1>
                  </h1>
                  <p className="leading-relaxed mb-3">
                    {organization.description}
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <button
                      onClick={()=>toggleCharityModal1(false)}
                      className="text-bgAppColor inline-flex items-center md:mb-2 lg:mb-0"
                    >
                      View Details
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
             
                    {/* <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                <svg className="w-4 h-4 mr-1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>1.2K
              </span>
              <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                <svg className="w-4 h-4 mr-1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                  <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                </svg>6
              </span> */}
                  </div>
             
                  <button
                      onClick={()=>toggleCharityModal1(true)}
                      className={`py-4 flex items-center justify-center bg-bgAppColor text-white w-full rounded my-3`}
                    >
                      Donate
                    </button>


                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RegisteredOrganizations;
