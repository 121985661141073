import React, { Fragment, Suspense } from "react";
import { Query } from "./../Query";
import ReuseableComponent from "../reusableComponents/reuseableComponents";
export const PopularMentors = () => (
    <Fragment>
        <Query url={`${process.env.REACT_APP_SERVER_PATH}/professionals?featured=1`}>
            {({ state: { data, loading, error }, actions }) => {
                if (error) {
                    return <p style={{ color: "red" }}>{error.message}</p>;
                }
                if (data) {
                    return (
                        <React.Fragment>
                            <React.Fragment>
                                <ReuseableComponent
                                    name="Popular Mentors"
                                    Featured={data}
                                    len={data.length}
                                />
                            </React.Fragment>
                        </React.Fragment>
                    );
                }
                return null;
            }}
        </Query>
    </Fragment>
);
