import axios from "axios";
import React, { useState } from "react";
const ChangeEmail = (props) => {
  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const handleSubmit = () => {
    console.log("Outside Condition");
    console.log("New Email:", newEmail);
    console.log("Email error:", errorText);
    var re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (re.test(newEmail)) {
      let values = {
        new_email: newEmail,
      };
      axios
        .patch(
          process.env.REACT_APP_SERVER_PATH +
            `/users/${+localStorage.getItem("id")}/email/reset`,
          values
        )
        .then((response) => {
          console.log(response.data);
          props.setNavState(1);
        })
        .catch((e) => {
          console.log(e.response.data, "response .data");
          console.log(
            "error in accessing email api",
            e.response.data.errors[0]
          );
          setErrorText(e.response.data.errors[0]);
          setError(true);
        });
    } else {
      setErrorText("Not an email");
      // setErrorText(true);
      setError(true);
    }
  };
  return (
    <div>
      <div className="pt-6 mb-4">
        <div className="mb-4">
          <label
            className="block text-textFullDark text-sm mb-2"
            for="currentEmail"
          >
            Enter your current email
          </label>
          <input
            disabled
            className="text-sm border font-bold bg-bgGray rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
            id="currentEmail"
            type="email"
            placeholder="info@gmail.com"
            value={JSON.parse(localStorage.getItem("email"))}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-textFullDark text-sm mb-2"
            for="newEmail"
          >
            Enter your new email
          </label>
          <input
            className="text-sm border rounded border-borderGray w-full py-3 px-3 text-textFullDark  focus:outline-none"
            id="newEmail"
            type="email"
            placeholder="info@gmail.com"
            value={newEmail}
            onChange={(e) => {
              setError(false);
              setNewEmail(e.target.value);
            }}
          />
        </div>
        {error && (
          <p className="text-xs font-bold text-red-600 mb-6">{errorText}</p>
        )}
        <button
          className={`w-full ${
            newEmail !== "" ? "bg-bgAppColor" : "bg-bgdarkGray"
          }  text-sm py-3 text-white rounded`}
          disabled={newEmail === "" ? true : false}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ChangeEmail;
