import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
const app = {
    apiKey: "AIzaSyBW216CysJhvgxI82Pl05yA0640D0atG1E",
    authDomain: "careerpath-35304.firebaseapp.com",
    projectId: "careerpath-35304",
    storageBucket: "careerpath-35304.appspot.com",
    messagingSenderId: "907944101770",
    appId: "1:907944101770:web:ca3b01f337633d24a9aa73",
    measurementId: "G-V97N0THTDQ"
};

initializeApp(app)
const db = getFirestore()
export default db