import React from 'react'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../../../settings'
import Notifications from './Notifications/Notifications'
const NotificationsSetting = ({ user }) => {
    return (
        <div className='mb-6'>
            <Helmet>
                <title>Notifications | Dashboard | {CompanyTitle}</title>
                <meta name={`Notifications | Dashboard | ${CompanyTitle}`} content={`Notifications | Dashboard | ${CompanyTitle}`} />
            </Helmet>
            <Notifications user={user} />
        </div>
    )
}

export default NotificationsSetting