import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactStars from "react-rating-stars-component";
import { useLocation } from "react-router-dom";
import { getRefFromQuery } from "../CustomFunctions/CustomFunctions";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { CompanyTitle } from "../settings";
import LeftArrow from "../Arrows/LeftArrow";
import RightArrow from "../Arrows/RightArrow";
import Slider from "react-slick";
import HowItWorksMain from "./HowItWorksMain";
import Skeleton, { SkeletonTheme } from "react-skeleton-loading";
const HowItWorks = () => {
  let queryString = useLocation();
  getRefFromQuery(queryString);
  return (
    <div className="w-full">
      <Helmet>
        <title>How it works | {CompanyTitle}</title>
        <meta
          name={`How it works | ${CompanyTitle}`}
          content={`How it works | ${CompanyTitle}`}
        />
      </Helmet>

      <Navbar />
      <hr />
      <HowItWorksMain />
      <Footer />
    </div>
  );
};

export default HowItWorks;

export const HowItWorksReviews = (props) => {
  const { text = "Reviews" } = props;
  const [reviewerData] = useState([
    {
      description:
        "“Great session with Guy. He was amazing in giving me a clear direction towards my end goals. After the call, I feel more motivated and excited to go down the route and see myself achieving my dream of becoming a PE analyst.”",
      name: "K.Hemanth",
      nameFirstLetter: "K.",
      title: "PE Analyst",
    },
    {
      description:
        "“I found my session with Guy very insightful; his candid approach was refreshing, allowing me to extract the maximum value from our session”",
      name: "Richard",
      nameFirstLetter: "R",
      title: "Student LSE",
    },
    {
      description:
        "“Excellent session with Shaun, very knowledgeable on the sector and in particular Series A investing”",
      name: "Matt",
      nameFirstLetter: "M",
      title: "PE Analyst",
    },
    {
      description:
        "“Wanted to switch careers from Consulting into Private Equity. Guy helped me understand what I needed to do to make the transition a success. Knowledge is power!”",
      name: "Hamza",
      nameFirstLetter: "H",
      title: "Senior Consultant",
    },
    {
      description:
        "“Completed a mock case study with Tobias who was super helpful in pointing out my development areas. I’m hoping this will help me get across the final interview hurdle!”",
      name: "Lara",
      nameFirstLetter: "L",
      title: "Corporate Finance",
    },
    {
      description:
        "“Spoilt for choice… great variety of mentors to pick from in Private Equity and Venture Capital”",
      name: "Georgina",
      nameFirstLetter: "S",
      title: "Student - Oxford",
    },
    {
      description: "“Quickest way to build a network in PE”",
      name: "Will",
      nameFirstLetter: "W",
      title: "Financial Analyst",
    },
    {
      description: "“The best £50 I've spent on my career to date”",
      name: "Alisha",
      nameFirstLetter: "A",
      title: "Credit - Associate",
    },
    {
      description: "“Unbelievable access to PE professionals”",
      name: "Ghada",
      nameFirstLetter: "G",
      title: "Investment Banking",
    },
    {
      description:
        "“Appreciated the 1:1 feedback and hearing career advice from someone actually working in the industry”",
      name: "Tanya",
      nameFirstLetter: "T",
      title: "M&A Analyst",
    },
    {
      description:
        "“I enjoyed the conversation with my mentor. He gave good advice and the app was super convenient to use!”",
      name: "Mike",
      nameFirstLetter: "M",
      title: " Investment Banking Associate",
    },
    {
      description:
        "“My conversation with Marina was very insightful, straightforward, and well-targeted. She gave good insights about how the industry works, what to look out for, strategies to employ and alternatives to explore.”",
      name: "Douye",
      nameFirstLetter: "D",
      title: " Investment Banking Associate",
    },
  ]);
  const Ref = useRef(null);
  const SlickNext = () => {
    Ref.current && Ref.current.slickNext();
    Ref.current && Ref.current.slickPause();
    setAutoplay(true);
    handleSliderPauseAndPlay();

    setTimeout(() => {
      Ref.current && Ref.current.slickPlay();
    }, 5500);
  };
  const SlickPrev = () => {
    Ref.current && Ref.current.slickPrev();
    Ref.current && Ref.current.slickPause();
    setAutoplay(true);
    handleSliderPauseAndPlay();
    setTimeout(() => {
      Ref.current && Ref.current.slickPlay();
      // setAutoplay(true);
    }, 5500);
  };
  const handleSliderPauseAndPlay = () => {
    Ref.current && Ref.current.slickPause();
    setAutoplay(false);

    setTimeout(() => {
      Ref.current && Ref.current.slickPlay();
      setAutoplay(true);
    }, 5500);
  };
  useEffect(() => {
    // Start autoplay
    Ref.current && Ref.current.slickPlay();
  }, []);
  const [autoplay, setAutoplay] = useState(true);
  const [settings] = useState({
    dots: false,
    slidesToShow: 3,
    arrows: false,
    autoplay: autoplay,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // const SlickNext = () => {
  //   Ref.current.slickNext();
  // };
  // const SlickPrev = () => {
  //   Ref.current.slickPrev();
  // };
  return (
    <div className="sm:p-4 md:p-16">
      <div>
        <h1 className="sm:text-3xl md:text-5xl text-textFullDark font-bold text-center my-4">
          {text}
        </h1>
        <div className="md:container mx-auto my-4 py-1 flex justify-end">
          <div className="text-md text-textFullDark flex items-center">
            <LeftArrow click={SlickNext} />
            <RightArrow click={SlickPrev} />
          </div>
        </div>
        <div className="md:container mx-auto my-4">
          <Slider {...settings} ref={Ref}>
            {reviewerData.map((reviewer) => {
              return (
                <div>
                  <div className="p-6 bg-blue-50 mr-2 md:mr-4 rounded flex flex-col justify-between h-96 md:h-80">
                    <div>
                      <ReactStars
                        value={5}
                        size={25}
                        activeColor="#FFD700"
                        isHalf={true}
                        edit={false}
                      />
                      <p className="text-lg text-textFullDark my-4 font-bold">
                        {reviewer.description}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center text-white font-bold">
                        {reviewer.nameFirstLetter}
                      </div>
                      <div className="ml-4">
                        <h2 className="text-xl text-black font-bold">
                          {reviewer.name}
                        </h2>
                        <p className="text-xs text-gray-600 font-bold">
                          {reviewer.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export const HowItWorksReviewsSkeleton = (props) => {
  const { text = "Reviews" } = props;
  const [reviewerData] = useState([
    {
      description:
        "“I found my session with Guy very insightful; his candid approach was refreshing, allowing me to extract the maximum value from our session”",
      name: "Richard",
      nameFirstLetter: "R",
      title: "Student LSE",
    },
    {
      description:
        "“Excellent session with Shaun, very knowledgeable on the sector and in particular Series A investing”",
      name: "Matt",
      nameFirstLetter: "M",
      title: "PE Analyst",
    },
    {
      description:
        "“Wanted to switch careers from Consulting into Private Equity. Guy helped me understand what I needed to do to make the transition a success. Knowledge is power!”",
      name: "Hamza",
      nameFirstLetter: "H",
      title: "Senior Consultant",
    },
    {
      description:
        "“Completed a mock case study with Tobias who was super helpful in pointing out my development areas. I’m hoping this will help me get across the final interview hurdle!”",
      name: "Lara",
      nameFirstLetter: "L",
      title: "Corporate Finance",
    },
    {
      description:
        "“Spoilt for choice… great variety of mentors to pick from in Private Equity and Venture Capital”",
      name: "Georgina",
      nameFirstLetter: "S",
      title: "Student - Oxford",
    },
    {
      description: "“Quickest way to build a network in PE”",
      name: "Will",
      nameFirstLetter: "W",
      title: "Financial Analyst",
    },
    {
      description: "“The best £50 I've spent on my career to date”",
      name: "Alisha",
      nameFirstLetter: "A",
      title: "Credit - Associate",
    },
    {
      description: "“Unbelievable access to PE professionals”",
      name: "Ghada",
      nameFirstLetter: "G",
      title: "Investment Banking",
    },
    {
      description:
        "“Appreciated the 1:1 feedback and hearing career advice from someone actually working in the industry”",
      name: "Tanya",
      nameFirstLetter: "T",
      title: "M&A Analyst",
    },
    {
      description:
        "“I enjoyed the conversation with my mentor. He gave good advice and the app was super convenient to use!”",
      name: "Mike",
      nameFirstLetter: "M",
      title: " Investment Banking Associate",
    },
    {
      description:
        "“My conversation with Marina was very insightful, straightforward, and well-targeted. She gave good insights about how the industry works, what to look out for, strategies to employ and alternatives to explore.”",
      name: "Douye",
      nameFirstLetter: "D",
      title: " Investment Banking Associate",
    },
  ]);
  const Ref = useRef(null);
  const SlickNext = () => {
    Ref.current && Ref.current.slickNext();
    Ref.current && Ref.current.slickPause();
    setAutoplay(true);

    setTimeout(() => {
      Ref.current && Ref.current.slickPlay();
    }, 5500);
  };
  const SlickPrev = () => {
    Ref.current && Ref.current.slickPrev();
    Ref.current && Ref.current.slickPause();
    setAutoplay(true);
    setTimeout(() => {
      Ref.current && Ref.current.slickPlay();
    }, 5500);
  };
  useEffect(() => {
    // Start autoplay
    Ref.current && Ref.current.slickPlay();
  }, []);
  const [autoplay, setAutoplay] = useState(true);
  const [settings] = useState({
    dots: false,
    slidesToShow: 3,
    arrows: false,
    autoplay: autoplay,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // const SlickNext = () => {
  //   Ref.current.slickNext();
  // };
  // const SlickPrev = () => {
  //   Ref.current.slickPrev();
  // };
  return (
    <div className="sm:p-4 md:p-16">
      <div>
        <h1 className="sm:text-3xl md:text-5xl text-textFullDark font-bold text-center my-4">
          {text}
        </h1>
        <div className="md:container mx-auto my-4 py-1 flex justify-end">
          <div className="text-md text-textFullDark flex items-center">
            <LeftArrow click={SlickPrev} />
            <RightArrow click={SlickNext} />
          </div>
        </div>
        <div className="md:container mx-auto my-4">
          <Slider {...settings} ref={Ref}>
            {reviewerData.map((reviewer) => {
              return (
                <div>
                  <div className="p-6 bg-blue-50 mr-2 md:mr-4 rounded flex flex-col justify-between h-96 md:h-80">
                    <div>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <p>
                          <Skeleton height={25} width={90} />
                        </p>
                      </SkeletonTheme>
                      <p className="text-lg text-textFullDark my-4 font-bold">
                        <SkeletonTheme baseColor="#202020" highlightColor="#444">
                          <p>
                            <Skeleton height={80} width={350} />
                          </p>
                        </SkeletonTheme>
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center text-white font-bold">
                        <Skeleton width={20} height={5} />
                      </div>
                      <div className="ml-4">
                        <h2 className="text-xl text-black font-bold">
                        <SkeletonTheme baseColor="#202020" highlightColor="#444">
                          <p>
                            <Skeleton height={20} width={80} />
                          </p>
                        </SkeletonTheme>
                        </h2>
                        <p className="text-xs text-gray-600 font-bold">
                        <SkeletonTheme baseColor="#202020" highlightColor="#444">
                          <p>
                            <Skeleton height={20} width={80} />
                          </p>
                        </SkeletonTheme>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>

  );
};
