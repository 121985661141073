import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scrollingEnabled: true,
};

export const Scroll = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    disableScroll: (state) => {
      state.scrollingEnabled = false;
    },
    enableScroll: (state) => {
      state.scrollingEnabled = true;
    },
  },
});

export const { disableScroll, enableScroll } = Scroll.actions;

export default Scroll.reducer;