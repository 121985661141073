import React, { useEffect, useState } from 'react';
import axios from 'axios';
const TermsContent = () => {

    const [htmlContent, setHtmlContent] = useState({heading:"", content:""})
    const getContent = ()=>{
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/static_page/${process.env.REACT_APP_TERMS_AND_CONDITIONS_ID}`).then((response) => {
            setHtmlContent({heading:response.data.message.name, content:response.data.message.content})
        }).catch(e => {
            console.log('Error in')
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getContent();
    }, [])
    
    const createHeading = ()=> {
        return {__html: htmlContent.heading};
    }

    const createContent = ()=> {
        return {__html: htmlContent.content};
    }

    return (
      <div className="my-5 ">
        <div dangerouslySetInnerHTML={createHeading()} />
        <div dangerouslySetInnerHTML={createContent()} />
      </div>
    
    );
}
export default TermsContent