import React, { useEffect, useRef } from "react";
const VideoTrack = ({ track, name }) => {
  const ref = useRef();

  useEffect(() => {
    if (track) {
      const el = ref.current;
      track.attach(el);

      return () => {
        track.detach(el);
      };
    }
  }, [track]);
  return (
      <div className="text-gray-700">
        <div>
          <div className="absolute bg-white m-4 px-4 py-2 rounded-full">
            {name}
          </div>
          <video style={{}} ref={ref} className="rounded-lg"></video>
        </div>
      </div>
  );
};

export default VideoTrack;
