import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '@culturehq/add-to-calendar/dist/styles.css';
import AddToCalendar from '@culturehq/add-to-calendar';
import moment from 'moment';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import "./AddToCalender.css";
import axios from 'axios';

const Done = (props) => {
    let navigate = useNavigate();
    const [name, setName] = useState('')
    let booking_date = new Date(props.dateBooking);
    const booking_time = props.timeSlot.split(" ");
    let hours_mint = booking_time[0].split(":");
    if (booking_time[1] === "PM") {
        hours_mint[0] = parseInt(hours_mint[0]) + 12;
    }
    booking_date = booking_date.setHours((hours_mint[0]), hours_mint[1]);
    let booking_date_end = new Date(booking_date).setMinutes(parseInt(props.sessionDuration) + parseInt(hours_mint[1]));
    let AfterConvertingtoUTC = moment(booking_date).utcOffset('GMT-06:00').format();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${props.id}`).then((response) => {
            setName(`${response.data.User?.first_name} ${response.data.User?.last_name}`)

        }).catch(e => {
            console.log('Error in Professional Api in Done component');
        })
    }, [])
    return (
        <div className="flex flex-col items-center justify-center text-textFullDark">
            <h1 className="text-2xl font-bold mt-8">Your request has</h1>
            <h1 className="text-2xl font-bold">been sent</h1>
            <p className="text-sm text-textDark mt-3">Your request was sent to {name}.You</p>
            <p className="text-sm text-textDark mb-2">should receive an email shortly</p>
            <div>
                <div>

                    <AddToCalendarButton
                        name={props?.selectedOption?.label}
                        label="Add reminder to calendar"
                        description={props?.textBody}
                        options="'Apple','Google','iCal','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
                        startDate={moment(new Date(booking_date)).format("YYYY-MM-DD")}
                        endDate={moment(new Date(booking_date)).format("YYYY-MM-DD")}
                        startTime={moment(new Date(booking_date)).format("HH:mm")}//"10:10"
                        endTime={moment(new Date(booking_date)).add(props.sessionDuration, 'minutes').format("HH:mm")}//"10:40"
                        styleLight="--btn-background: #00C4FF;--btn-text:#f9f9f9;--btn-shadow:0 0 #0000;--btn-background-hover:#00C4FF"
                        styleDark="--btn-background: #00C4FF;--btn-text:#f9f9f9;--btn-shadow:0 0 #0000;--btn-background-hover:#00C4FF"
                        buttonStyle="round"
                        inline
                        listStyle="modal"
                        hideCheckmark
                        timeZone="currentBrowser"
                        
                    ></AddToCalendarButton>
                </div>
                <button onClick={() => {
                    let dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'Purchase',
                        'profile': window.location.href,
                        'total': +props.sessionPriceFinal,
                    });
                    navigate('/dashboard/home')
                }} className="border border-borderGray rounded px-4 py-2 w-full mt-3">Done</button>
            </div>


        </div>
    )
}

export default Done
