import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { CompanyTitle } from "../../../settings";
import Loader from "react-loader-spinner";
import Colors from "../../../Colors/Colors";

import RegisteredOrganizations from "./components/registeredOrganizations";
import DescriptionCharityMain from "./components/descriptionCharityMain";
import CharityActive from "./components/charityActive";

const Chrity = ({ user }) => {
  const [isLoading, setLoading] = useState(false);
  const [isCharityActive, setIsCharityActive] = useState(false);
  const [registedOrganizations, setRegisterdOrganizations] = useState([]);
  
  const getCharityInfo = ()=>{
      setLoading(true);
    let id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/professionals_charity/${id}`)
      .then((response) => {
        setLoading(false);
        if(response.data.length > 0){
         setIsCharityActive(true)
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Error in geting charity");
      });
  }

  const getRegisteredOrganizations = ()=>{
    setLoading(true);
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/charity`)
      .then((response) => {
        setLoading(false);
        setRegisterdOrganizations(response.data)
        console.log("registered organizations are..", response.data);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Error in geting charity");
      });
  }

  useEffect(() => {
    window.scroll(0, 0);
    getCharityInfo();
    getRegisteredOrganizations();
  }, []);
  return (
    <div className="bg-white pb-8 mb-4 rounded p-6">
      {!isLoading ? (
        <div>
          <Helmet>
            <title>Charity | Dashboard | {CompanyTitle}</title>
            <meta
              name={`Charity | Dashboard | ${CompanyTitle}`}
              content={`Charity | Dashboard | ${CompanyTitle}`}
            />
          </Helmet>
          {isCharityActive ? (
            <CharityActive percentage={25} isCharityActive={isCharityActive} />
          ) : (
            <div>
              <DescriptionCharityMain />
              <RegisteredOrganizations organizatoins={registedOrganizations} />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center h-full">
          <Loader width={30} height={30} type="Oval" color={Colors.blue} />
        </div>
      )}
    </div>
  );
};

export default Chrity;
