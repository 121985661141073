import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Switch from "react-switch";
import Colors from "../../../Colors/Colors";
import Select from "./Select/Select";
import axios from "axios";
import ReactiveButton from "reactive-button";
// use material theme
import "react-times/css/material/default.css";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
// or you can use classic theme
import "react-times/css/classic/default.css";
import moment from "moment";
import { Helmet } from "react-helmet";
import { CompanyTitle } from "../../../settings";

const Availability = (props) => {
  const [checked, setChecked] = useState(false);
  const setUserOnLine = (value) => {
    if (value) {
      axios
        .get(
          process.env.REACT_APP_SERVER_PATH +
            `/changeUserStatus/${
              jwt_decode(localStorage.getItem("cp_web_token")).id
            }/${1}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          setChecked(true);
        })
        .catch(function (error) {
          console.log("Error", error.message);
        });
    } else {
      if (window.confirm("Are you sure?")) {
        axios
          .get(
            process.env.REACT_APP_SERVER_PATH +
              `/changeUserStatus/${
                jwt_decode(localStorage.getItem("cp_web_token")).id
              }/${0}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
              },
            }
          )
          .then((response) => {
            setChecked(false);
          })
          .catch(function (error) {
            console.log("Error", error.message);
          });
      }
    }
  };

  const getProf = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/users/${
          jwt_decode(localStorage.getItem("cp_web_token")).id
        }`,
        {}
      )
      .then((response) => {
        if (response.data.is_online) {
          setChecked(true);
        } else {
          setChecked(false);
        }
        console.log("Successfull", response);
      })
      .catch((e) => {
        console.log("error");
      });
  };
  useEffect(() => {
    getProf();
  }, []);

  return (
    <div className="bg-white pb-8 mb-4 rounded p-6">
      <Helmet>
        <title>Availability | Dashboard | {CompanyTitle}</title>
        <meta
          name={`Availability | Dashboard | ${CompanyTitle}`}
          content={`Availability | Dashboard | ${CompanyTitle}`}
        />
      </Helmet>
      <div className="flex flex-col justify-center items-center text-textDark w-full">
        {/* <img src="/setHours.png" alt="setHours" className="w-56" /> */}
        <div className="w-full flex justify-between items-center border-gray-200 rounded p-2 border-2">
          <div className="flex items-center justify-between">
            <ReactTooltip />
            <p className="text-2xl font-bold mx-3 text-textFullDark">
              Go Online
            </p>
            <span
              data-html={true}
              data-tip={ReactDOMServer.renderToString(
                <div>
                  <p>
                    When not Online, customers will not be able to book a
                    meeting with you. Use this function when you are on holiday
                    or you just need a break
                  </p>
                </div>
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </div>

          <Switch
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            onColor={Colors.blue}
            onChange={(e) => setUserOnLine(e)}
          />
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-2xl font-bold mt-3 text-textFullDark">
            Set your weekly hours
          </p>
        </div>

        <div className="w-full mt-4">
          <Weekday day={"Monday"} />
          <Weekday day={"Tuesday"} />
          <Weekday day={"Wednesday"} />
          <Weekday day={"Thursday"} />
          <Weekday day={"Friday"} />
          <Weekday day={"Saturday"} />
          <Weekday day={"Sunday"} />
        </div>
        {/* <div className="w-full flex justify-end items-center mt-4 text-white">
                    <button className="py-2  px-8 rounded-lg flex" style={{ backgroundColor: Colors.blue }}
                        onClick={() => { props.setNavState(props.navState + 1) }}>
                        <p >Next</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </button>
                </div> */}
      </div>
    </div>
  );
};

const Weekday = (props) => {
  const [fields, setFields] = useState([]);
  const [checked, setChecked] = useState(false);
  const [disableAddbtn, setDisableAddBtn] = useState(true);
  const [hours, setHour] = useState("8");
  const [minutes, setMinutes] = useState("00");
  const [endhours, setHoursEnd] = useState("9");
  const [endminutes, setMinutesEnd] = useState("00");
  const [state, setState] = useState("idle");
  const [alert_, setAlert] = useState(false);
  
  const [currentId, setCurrentId] = useState(null);

  const toggleAlert = (id) => {
  setCurrentId(id);
  setAlert(!alert_);
  };
  if (alert_) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const hoursoptions = [
    {
      id: "00",
      label: "00",
      value: "00",
    },
    {
      id: "01",
      label: "01",
      value: "01",
    },
    {
      id: "02",
      label: "02",
      value: "02",
    },
    {
      id: "03",
      label: "03",
      value: "03",
    },
    {
      id: "04",
      label: "04",
      value: "04",
    },
    {
      id: "05",
      label: "05",
      value: "05",
    },
    {
      id: "06",
      label: "06",
      value: "06",
    },
    {
      id: "07",
      label: "07",
      value: "07",
    },
    {
      id: "08",
      label: "08",
      value: "08",
    },
    {
      id: "09",
      label: "09",
      value: "09",
    },
    {
      id: "10",
      label: "10",
      value: "10",
    },
    {
      id: "11",
      label: "11",
      value: "11",
    },
    {
      id: "12",
      label: "12",
      value: "12",
    },
    {
      id: "13",
      label: "13",
      value: "13",
    },
    {
      id: "14",
      label: "14",
      value: "14",
    },
    {
      id: "15",
      label: "15",
      value: "15",
    },
    {
      id: "16",
      label: "16",
      value: "16",
    },
    {
      id: "17",
      label: "17",
      value: "17",
    },
    {
      id: "18",
      label: "18",
      value: "18",
    },
    {
      id: "19",
      label: "19",
      value: "19",
    },
    {
      id: "20",
      label: "20",
      value: "20",
    },
    {
      id: "21",
      label: "21",
      value: "21",
    },
    {
      id: "22",
      label: "22",
      value: "22",
    },
    {
      id: "23",
      label: "23",
      value: "23",
    },
  ];
  const minutesoptions = [
    {
      id: "00",
      label: "00",
      value: "00",
    },
    {
      id: "15",
      label: "15",
      value: "15",
    },
    {
      id: "30",
      label: "30",
      value: "30",
    },
    {
      id: "45",
      label: "45",
      value: "45",
    },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/professionals_availability`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        const result_day = response.data.message;
        const values = [...fields];
        result_day.map((element) => {
          if (element.day === props.day) {
            setChecked(true);
            const s_hours =
              new Date(element.start_time * 1000).getHours() === 0
                ? "00"
                : new Date(element.start_time * 1000).getHours();
            const s_minutes =
              new Date(element.start_time * 1000).getMinutes() === 0
                ? "00"
                : new Date(element.start_time * 1000).getMinutes();
            const e_hours =
              new Date(element.end_time * 1000).getHours() === 0
                ? "00"
                : new Date(element.end_time * 1000).getHours();
            const e_minutes =
              new Date(element.end_time * 1000).getMinutes() === 0
                ? "00"
                : new Date(element.end_time * 1000).getMinutes();

            const hours = s_hours;
            const minutes = s_minutes;
            const endhours = e_hours;
            const endminutes = e_minutes;

            values.push({
              day: element.day,
              hour: hours,
              minute: minutes,
              endhour: endhours,
              endminute: endminutes,
              id: element.id,
              unavailable: true,
            });
          }
        });
        setFields(values);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          alert(error.response.data.error);
          setState("idle");
          setDisableAddBtn(true);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }, []);

  function handleAdd() {
    const values = [...fields];
    values.push({
      day: props.day,
      hour: "--",
      minute: "00",
      endhour: "--",
      endminute: "00",
      id: "",
    });

    setFields(values);
    setDisableAddBtn(false);
    setHour("");
    setMinutes("");
    setHoursEnd("");
    setMinutesEnd("");
  }

  function handleRemove(i) {
    let values = [];
    if (fields.length > 0) {
      values = [...fields];
    }

    let myobject = values[i];
    if (myobject.hour === "--" || myobject.endhours === "--") {
      values.splice(i, 1);
      setFields(values);
      toggleAlert();
      return;
    }
    axios
      .delete(
        process.env.REACT_APP_SERVER_PATH +
          `/professionals_availability/${myobject.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        setState("idle");
        setDisableAddBtn(true);
        toggleAlert();
      })
      .catch(function (error) {
        setState("idle");
        setDisableAddBtn(true);
        toggleAlert();

        if (error.response) {
          // Request made and server responded
          alert("An error occurred", error.response.data.error);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          console.log("error is, ", error);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          console.log("error is, ", error);
        }
      });
      values.splice(i, 1);
      setFields(values);
  }

  function handleConfirm(i) {
    setState("loading");
    const time_professional = [...fields];
    let myobject = time_professional[i];
    const hour = myobject.hour;
    const minute = myobject.minute;
    const endhour = myobject.endhour;
    const endminute = myobject.endminute;

    const d = new Date();
    const start_timestamp = d.setHours(hour, minute);

    const end_timestamp = d.setHours(endhour, endminute);

    const start_date = moment(start_timestamp).unix();

    const end_date = moment(end_timestamp).unix();

    const data = {
      day: myobject.day,
      start_time: start_date,
      end_time: end_date,
    };
    axios
      .post(
        process.env.REACT_APP_SERVER_PATH + `/professionals_availability`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        fields[i].id = response.data.message.id;
        setTimeout(() => {
          setDisableAddBtn(true);
          setState("success");
        }, 1000);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          alert(error.response.data.error);
          const time_professional = [...fields];
          time_professional.splice(i, 1);
          setFields(time_professional);
          setState("idle");
          setDisableAddBtn(true);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }

  function handleChange(checked) {
    if (checked) {
      setChecked(checked);
      setState("idle");
      setDisableAddBtn(true);
      setState("idle");
    } else {
      let i = 0;
      let values = [];
      if (fields.length > 0) {
        values = [...fields];
      }

      
      for (i = 0; i < fields.length; i++) {
        axios
          .delete(
            process.env.REACT_APP_SERVER_PATH +
              `/professionals_availability/${values[i].id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
              },
            }
          )
          .then((response) => {
            setState("idle");
            setDisableAddBtn(false);
          })
          .catch(function (error) {
            setState("idle");
            setDisableAddBtn(true);
            if (error.response) {
              // Request made and server responded
              // alert("An error occurred", error.response.data.error);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              console.log("error is, ", error);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              console.log("error is, ", error);
            }
          });
        setFields([]);
      }
      setChecked(checked);
      setState("idle");
      setDisableAddBtn(true);
      setState("idle");
      // setChecked(false);
    }

    setChecked(checked);
    setState("idle");
    setDisableAddBtn(true);
    setState("idle");
    // toggleAlert();
  }

  return (
    <>
      <div className="w-full bg-bgGray rounded p-3 flex justify-between items-center mb-4">
        <div>
          <p className="text-textFullDark text-lg">{props.day}</p>
          <p className="text-textDark text-xs">
            {checked ? "Available" : "Unavailable"}
          </p>
        </div>
        <div>
          <Switch
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            onColor={Colors.blue}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        {checked
          ? fields.map((field, idx) => {
              return (
                <div key={`${field}-${idx}`}>
                  {alert_ &&  (
                    <Alert
                      toggle={toggleAlert}
                      overlay="overlay"
                      modal="modal"
                      modalcontent="modal-content"
                      closeModal="close-modal"
                      modalValue={alert_}
                      currentId={currentId}
                      id={idx}
                      handleRemove={handleRemove}
                    />
                  )}
                  <div className=" w-full flex items-center m-2 ">
                    <div className="col-11">
                      <div className="flex w-full justify-between items-center">
                        <p className="mr-1"> Start Time:</p>
                        <div className="w-24 m-1">
                          <Select
                            className="bg-bgGray"
                            options={hoursoptions}
                            unavailable={
                              field.unavailable ? field.unavailable : false
                            }
                            selectedOption={
                              field.hour
                                ? {
                                    id: `${field.hour}`,
                                    label: `${field.hour}`,
                                    value: `${field.hour}`,
                                  }
                                : hours
                            }
                            handelChange={(event) => {
                              field.hour = event.value;
                              setHour(event);
                            }}
                          />
                        </div>
                        <div className="w-24 m-1">
                          <Select
                            options={minutesoptions}
                            unavailable={
                              field.unavailable ? field.unavailable : false
                            }
                            selectedOption={
                              field.minute
                                ? {
                                    id: `${field.minute}`,
                                    label: `${field.minute}`,
                                    value: `${field.minute}`,
                                  }
                                : minutes
                            }
                            handelChange={(event) => {
                              field.minute = event.value;
                              setMinutes(event);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex w-full justify-between items-center">
                        <p className="mr-1"> End Time :</p>
                        <div className="w-24 m-1">
                          <Select
                            options={hoursoptions}
                            unavailable={
                              field.unavailable ? field.unavailable : false
                            }
                            selectedOption={
                              field.endhour
                                ? {
                                    id: `${field.endhour}`,
                                    label: `${field.endhour}`,
                                    value: `${field.endhour}`,
                                  }
                                : endhours
                            }
                            handelChange={(event) => {
                              setHoursEnd(event);
                              field.endhour = event.value;
                            }}
                          />
                        </div>
                        <div className="w-24 m-1">
                          <Select
                            options={minutesoptions}
                            unavailable={
                              field.unavailable ? field.unavailable : false
                            }
                            selectedOption={
                              field.endminute
                                ? {
                                    id: `${field.endminute}`,
                                    label: `${field.endminute}`,
                                    value: `${field.endminute}`,
                                  }
                                : endminutes
                            }
                            handelChange={(event) => {
                              setMinutesEnd(event);
                              field.endminute = event.value;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <button
                        type="button"
                        onClick={() => {
                          toggleAlert(idx);
                        }}
                      >
                        <img
                          className="mt-10 ml-3"
                          src="https://img.icons8.com/carbon-copy/25/000000/filled-trash.png"
                          alt="photosf"
                        />
                      </button>
                    </div>
                  </div>

                  {field.id === "" ? (
                    <div className=" w-full flex justify-center items-center py-2 ">
                      <ReactiveButton
                        buttonState={state}
                        onClick={() => handleConfirm(idx)}
                        idleText={"Save"}
                        loadingText={"Loading"}
                        successText={"Added"}
                        errorText={"Error"}
                        type={"button"}
                        className={
                          "flex justify-center items-center bg-bgAppColor"
                        }
                        style={{ borderRadius: "5px" }}
                        outline={false}
                        shadow={false}
                        rounded={false}
                        size={"large"}
                        block={false}
                        messageDuration={2000}
                        disabled={false}
                        buttonRef={null}
                        width={300}
                        height={40}
                        animation={true}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
        {checked && disableAddbtn ? (
          <div className=" w-full flex justify-center items-center py-2 ">
            <ReactiveButton
              buttonState={state}
              onClick={() => handleAdd()}
              idleText={"+ Add More"}
              loadingText={"Loading"}
              successText={"Added"}
              errorText={"Error"}
              type={"button"}
              className={"flex justify-center items-center bg-bgAppColor"}
              style={{ borderRadius: "5px" }}
              outline={false}
              shadow={false}
              rounded={false}
              size={"large"}
              block={false}
              messageDuration={2000}
              disabled={false}
              buttonRef={null}
              width={300}
              height={40}
              animation={true}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Availability;

const Alert = (props) => {
  const deleteA = (id) => {
    props.handleRemove(id);
  };
  return (
   <div>
    {props.currentId === props.id &&
      <div
        className={props.modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={props.overlay} onClick={props.toggle}></div>
        <div className="flex flex-col z-20 bg-white rounded p-6">
          <p className="text-2xl font-bold">Do you want to delete availability</p>
          <div className="flex items-center justify-between mt-4 w-full">
            <button
              className="px-5 text-lg py-2 text-white rounded-lg bg-green-500 hover:bg-green-800"
              onClick={props.toggle}
            >
              No
            </button>
            <button
              className="px-5 text-lg py-2 ml-2 text-white rounded-lg bg-red-500 hover:bg-red-800"
              onClick={()=>deleteA(props.id)}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    }
  </div>
  );
};
