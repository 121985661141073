import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { Mixpanel } from '../../../../../Mixpanel';

const AddEducation = (props) => {
    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [imgUrl, setImageUrl] = useState('')
    const [fileInput, setFileInput] = useState()
    const [universityName, setUniversityName] = useState('')
    const [degreeName, setDegreeName] = useState('')
    const [subjectName, setSubjectName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [image, setImage] = useState(null)
    const [dateError, setDateError] = useState(false)
    const [earlierDateError, setEarlierDateError] = useState(false)
    const [universityError, setUniversityError] = useState(false)
    const [subjectError, setSubjectError] = useState(false)
    const [degreeError, setDegreeError] = useState(false)
    const handleStartDate = (e) => {
        setDateError(false)
        setStartDate(new Date(e.target.value))

    }
    const handleEndDate = (e) => {
        setDateError(false)
        setEndDate(new Date(e.target.value))
    }

    const UploadImage = (id) => {
        const formDataExp = new FormData();
        formDataExp.append("file", image);
        formDataExp.append("education_id", id);
        axios.post(process.env.REACT_APP_SERVER_PATH + `/document/education/`, formDataExp).then((response) => {
            console.log("image uploaded to education")

            navigate('../../education')
            setLoading(false)
        }).catch(e => {
            console.log("error is from uploading");
        })
    }

    const AddEducation = () => {
        if (universityName === '') {
            setUniversityError(true)
        }
        if (degreeName === '') {
            setDegreeError(true)
        }
        if (subjectName === '') {
            setSubjectError(true)
        }
        if ((!moment(startDate).isSameOrBefore(endDate, 'month')) || moment(startDate).isSame(endDate, 'month')) {
            setDateError(true)
        }
        // check if the date is not greater than today date
        else if(moment(startDate).startOf('month').diff(moment(new Date().toISOString().split("T")[0]).startOf('month'), 'month') > 0){
          setEarlierDateError(true)
        } 
        else {
            setLoading(true)
            const Data = {
                school: universityName,
                degree: degreeName,
                field_of_study: subjectName,
                user_id: localStorage.getItem("id"),
                start_date: startDate,
                description: 'hello',
                end_date: endDate,
                status: ''
            }
            axios.post(`${process.env.REACT_APP_SERVER_PATH}/education`, Data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
                }
            }).then((response) => {
                Mixpanel.track(`web: Add Education.`, Data);

                if (imgUrl === '') {
                    navigate('../../education')
                    setLoading(false)
                } else {
                    UploadImage(response.data.id)
                }
            }).catch(e => {
                console.log(e)
            })
        }

    }

    const handleImg = (event) => {
        if (event.target.files[0].type === 'image/jpeg'
            || event.target.files[0].type === 'image/png') {
            setImageUrl(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        } 

    }

    return (
        <div>
            <div className="w-full flex items-center justify-center flex-col">
                <input className="text-xs text-white ml-2" type="file" onChange=
                    {handleImg}
                    ref={fileinput => setFileInput(fileinput)}
                    style={{ display: 'none' }} />
                <img src={imgUrl ? imgUrl : ' /educationplaceholder.png'} alt="images" className="w-16 h-16 rounded-full object-cover border border-borderApp text-textFullDark" />
                <button className="mt-3" onClick={() => {
                    fileInput.click()
                }}>Add Logo</button>
            </div>
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="university">
                    University
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="school" type="text" placeholder="University" value={universityName}
                    onChange={(e) => {
                        setUniversityError(false)
                        setUniversityName(e.target.value)
                    }} />
            </div>
            {universityError && <p className='text-sm -mt-2 text-red-500'>Please enter university name</p>}
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="degree">
                    Degree
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="degree" type="text" placeholder="Degree"
                    value={degreeName}
                    onChange={(e) => {
                        setDegreeError(false)
                        setDegreeName(e.target.value)
                    }} />
            </div>
            {degreeError && <p className='text-sm -mt-2 text-red-500'>Please enter degree name</p>}
            <div className="mb-4">
                <label className="block text-textFullDark text-sm mb-2" for="subject">
                    Subject
                </label>
                <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="job" type="text" placeholder="Subject"
                    value={subjectName}
                    onChange={(e) => {
                        setSubjectError(false)
                        setSubjectName(e.target.value)
                    }} />
            </div>
            {subjectError && <p className='text-sm -mt-2 text-red-500'>Please enter subject name</p>}
            <div className="flex w-full justify-between">
                <div className="w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="firstName">
                        Start Date
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="firstName" type="date" placeholder="Enter first name" onChange={handleStartDate}
                        max={new Date().toISOString().split("T")[0]}
                    />
                </div>
                <div className="ml-2 w-1/2">
                    <label className="block text-textFullDark text-sm mb-2" for="firstName">
                        End Date
                    </label>
                    <input className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textDark  focus:outline-none" id="firstName" type="date" placeholder="" onChange={handleEndDate} 
                    max={new Date().toISOString().split("T")[0]}
                    />
                </div>
            </div>
            {dateError && <p className='text-sm text-red-500 my-1'>Start date should be less than the end date</p>}
            {earlierDateError && <p className='text-sm text-red-500 my-1'>Date should be today or earlier</p>}
            <button className={`bg-bgAppColor text-white text-center w-full text-sm rounded flex items-center justify-center mt-3 py-3 hover:bg-blue-300 ${subjectName !== '' && universityName !== '' && degreeName !== '' ? 'bg-bgAppColor' : 'bg-bgdarkGray'}`}
                disabled={subjectName === '' && universityName === '' && degreeName === ''}
                onClick={AddEducation}>Save{isLoading ? <span className='ml-4'>
                    <Loader
                        type="TailSpin"
                        color="white"
                        height={20}
                        width={20}
                    // timeout={5000} //5 secs
                    /></span> : null}</button>
        </div >
    )
}

export default AddEducation
