import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from "./TextField";
import { Mixpanel } from '../Mixpanel';

import Colors from "../Colors/Colors";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import AppleSignin from "react-apple-signin-auth";
import Loader from "react-loader-spinner";
import { update_profile_step } from "../SignUpProcessOfCustomer/StepUpdated";
import { useDispatch, useSelector } from "react-redux";
import { FreeCallCompleted, offFreeCallModal, FreeCallNotCompleted, toggleFreeCallModal } from '../Redux/reducers/freeCall/freeCallSlice'
import { setBannerFalse } from "../Redux/reducers/banner/bannerSlice";
import { existingNavState } from '../Redux/reducers/nav/navSlice'
import { setProfessionalsToTrue } from '../Redux/reducers/professional/professionalSlice';

const SignIn = (props) => {
  let navigate = useNavigate();
  let isFromBookingModal = useSelector(state => state.freeCall.isFromBookingModal)
  const requiredUrl = useSelector(state => state.redirect.url);

  let dispatch = useDispatch()
  const signup = false;
  const [isLoading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [success, setSuccess] = useState(false)
  const [successText, setSuccessText] = useState('')
  const location = useLocation();
  if (signup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const [tz, setTz] = useState('')
  const updateTimeZone = (id) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    axios.put(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`, { time_zone: timezone }).then((response) => {
      console.log("Successfull updated timezone", timezone)
    }).catch(e => {
      console.log("error")
    })
  }


  const { linkedInLogin } = useLinkedIn({
    clientId: "78faiv7z5kqfl3",
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    scope: "r_emailaddress r_liteprofile",
    onSuccess: async (code) => {
      localStorage.setItem('linkedInLogin', 1)
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_PATH}/auth/linkedin_web`,
        {
          method: "POST",
          body: JSON.stringify({
            authorization_code: code,
            cp_ref: localStorage.getItem('cp_ref') ? localStorage.getItem('cp_ref') : null
          }),
          headers: {
            "Content-Type": "application/json",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          },
        }
      );
      const data = await res.json();
      if (data.error) {
        alert(data.error);
        return;
      }
      let token = data.token;
      let id = JSON.stringify(data.id);
      let email = JSON.stringify(data.email);
      let type = JSON.stringify(data.type);
      localStorage.setItem("type", type);
      localStorage.setItem("email", email);
      localStorage.removeItem("cp_ref");
      axios.get(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`).then((response) => {
        if (response.data.type === 'customer') {
          if (response.data.profile_step === null) {
            update_profile_step(token, id, "1").then((response) => {
              props.toggle()
              localStorage.setItem('customer_signup_token', token)
              props.setCustomerSignUpFlow(true)
              dispatch(setBannerFalse())
            }).catch(e => {
              console.log("Error in LinkedIn")
            })
          } else if (response.data.profile_step === '9') {
            localStorage.setItem("type", type);
            localStorage.setItem("email", email);
            localStorage.setItem("cp_web_token", token);
            localStorage.setItem("id", id);
            updateTimeZone(id)
            if (isFromBookingModal) {
              axios.get(process.env.REACT_APP_SERVER_PATH + `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
                { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
                  dispatch(FreeCallNotCompleted())
                  props.toggle();
                  dispatch(toggleFreeCallModal())
                  dispatch(isFromBookingModal())
                }).catch(e => {
                  props.toggle();
                  dispatch(offFreeCallModal())
                  alert('You have already used free call service')
                  dispatch(FreeCallCompleted())
                })
            } else {
              props.toggle();
              if (window?.location?.pathname?.includes('checkout')) {
              } else {
                navigate(!location.pathname.includes('/profile/') && requiredUrl == '/' ? `/dashboard/home` : requiredUrl);
              }
              setLoading(false);
            }
          } else {
            update_profile_step(token, id, response.data.profile_step).then((response) => {
              props.toggle()
              localStorage.setItem('customer_signup_token', token)
              props.setCustomerSignUpFlow(true)
              dispatch(setBannerFalse())
            }).catch(e => {
              console.log("Error in LinkedIn")
            })
          }
        } else {
          updateTimeZone(id)
          props.toggle();
          localStorage.setItem("type", type);
          localStorage.setItem("email", email);
          localStorage.setItem("cp_web_token", token);
          localStorage.setItem("id", id);
          if (window?.location?.pathname?.includes('checkout')) {
          } else {
            navigate("/dashboard/home");
          }

        }
      })
    },
    onError: (error) => {
      console.log("==============linkedin error======================");
      console.log(error);
      console.log("====================================");
    },
  });
  const handleLogin = async (googleData) => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_PATH}/auth/google_web`,
      {
        method: "POST",
        body: JSON.stringify({
          token: googleData.tokenId,
          cp_ref: localStorage.getItem('cp_ref') ? localStorage.getItem('cp_ref') : null
        }),
        headers: {
          "Content-Type": "application/json",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      }
    );
    const data = await res.json();
    localStorage.setItem('googleLogin', 1)
    let token = data.token;
    let id = JSON.stringify(data.id);
    let email = JSON.stringify(data.email);
    let type = JSON.stringify(data.type);
    localStorage.setItem("type", type);
    localStorage.setItem("email", email);
    localStorage.removeItem("cp_ref");
    axios.get(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`).then((response) => {
      if (response.data.type === 'customer') {
        if (response.data.profile_step === null) {
          update_profile_step(token, id, "1").then((response) => {
            props.toggle()
            localStorage.setItem('customer_signup_token', token)
            props.setCustomerSignUpFlow(true)
            dispatch(setBannerFalse())
          }).catch(e => {
            console.log("Error in Google")
          })
        } else if (response.data.profile_step === '9') {
          localStorage.setItem("type", type);
          localStorage.setItem("email", email);
          localStorage.setItem("cp_web_token", token);
          localStorage.setItem("id", id);
          updateTimeZone(id)
          if (isFromBookingModal) {
            axios.get(process.env.REACT_APP_SERVER_PATH + `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
              { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
                dispatch(FreeCallNotCompleted())
                props.toggle();
                dispatch(toggleFreeCallModal())
                dispatch(existingNavState())
                dispatch(isFromBookingModal())
              }).catch(e => {
                props.toggle();
                dispatch(offFreeCallModal())
                alert('You have already used free call service')
                dispatch(FreeCallCompleted())
              })
          } else {
            props.toggle();
            if (window?.location?.pathname?.includes('checkout')) {
            } else {
              navigate(!location.pathname.includes('/profile/') && requiredUrl == '/' ? `/dashboard/home` : requiredUrl);
            }
            setLoading(false);
          }
        } else {
          update_profile_step(token, id, response.data.profile_step).then((response) => {
            props.toggle()
            localStorage.setItem('customer_signup_token', token)
            props.setCustomerSignUpFlow(true)
            dispatch(setBannerFalse())
          }).catch(e => {
            console.log("Error in Google")
          })
        }
      } else {
        updateTimeZone(id)
        props.toggle();
        localStorage.setItem("type", type);
        localStorage.setItem("email", email);
        localStorage.setItem("cp_web_token", token);
        localStorage.setItem("id", id);
        if (window?.location?.pathname?.includes('checkout')) {
        } else {
          navigate("/dashboard/home");
        }

      }
    })
  };

  const handleAppleLogin = async (user_data) => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_PATH}/auth/apple_web`,
      {
        method: "POST",
        body: JSON.stringify({ ...user_data, cp_ref: localStorage.getItem('cp_ref') ? localStorage.getItem('cp_ref') : null }),
        headers: {
          "Content-Type": "application/json",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      }
    );
    const data = await res.json();
    if (data.error) {
      alert(`Failed to login. 😢 `);
      return 0;
    }

    let token = data.token;
    let id = JSON.stringify(data.id);
    let email = JSON.stringify(data.email);
    let type = JSON.stringify(data.type);
    localStorage.setItem("type", type);
    localStorage.setItem("email", email);
    // localStorage.setItem("token", token);
    // localStorage.setItem("id", id);
    localStorage.removeItem("cp_ref");
    axios.get(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`).then((response) => {
      if (response.data.type === 'customer') {
        if (response.data.profile_step === null) {
          update_profile_step(token, id, "1").then((response) => {
            props.toggle()
            localStorage.setItem('customer_signup_token', token)
            props.setCustomerSignUpFlow(true)
            dispatch(setBannerFalse())
          }).catch(e => {
            console.log("Error in Google")
          })

        } else if (response.data.profile_step === '9') {
          localStorage.setItem("type", type);
          localStorage.setItem("email", email);
          localStorage.setItem("cp_web_token", token);
          localStorage.setItem("id", id);
          updateTimeZone(id)
          if (isFromBookingModal) {
            axios.get(process.env.REACT_APP_SERVER_PATH + `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
              { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
                dispatch(FreeCallNotCompleted())
                props.toggle();
                dispatch(toggleFreeCallModal())
                dispatch(existingNavState())
                dispatch(isFromBookingModal())
              }).catch(e => {
                props.toggle();
                dispatch(offFreeCallModal())
                alert('You have already used free call service')
                dispatch(FreeCallCompleted())
              })
          } else {
            props.toggle();
            if (window?.location?.pathname?.includes('checkout')) {
            } else {
              navigate(!location.pathname.includes('/profile/') && requiredUrl == '/' ? `/dashboard/home` : requiredUrl);
            }
            setLoading(false);
          }
        } else {
          update_profile_step(token, id, response.data.profile_step).then((response) => {
            props.toggle()
            localStorage.setItem('customer_signup_token', token)
            props.setCustomerSignUpFlow(true)
            dispatch(setBannerFalse())
          }).catch(e => {
            console.log("Error in Google")
          })
        }
      } else {
        updateTimeZone(id)
        props.toggle();
        localStorage.setItem("type", type);
        localStorage.setItem("email", email);
        localStorage.setItem("cp_web_token", token);
        localStorage.setItem("id", id);
        if (window?.location?.pathname?.includes('checkout')) {
        } else {
          navigate("/dashboard/home");
        }

      }
    })
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values) => {
        if (forgetPassword) {
          setError(false);
          axios
            .post(process.env.REACT_APP_SERVER_PATH + `/forgot-password`, {
              email: values.email,
            })
            .then(() => {
              setForgetPassword(false);
              setSuccessText(
                "An email has been sent to you with the password reset instructions"
              );
              setSuccess(true);
            })
            .catch((e) => {
              setErrorText(e.response.data.errors[0]);
              setError(true);
              setForgetPassword(false);
              console.log(e);
            });
        } else {
          setSuccess(false);
          setLoading(true);
          axios
            .post(process.env.REACT_APP_SERVER_PATH + `/login`, values, {
              headers: {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            })
            .then((result) => {
              if (result.status === 200) {
                let token = result.data.message.token;
                let id = JSON.stringify(result.data.message.id);
                let email = JSON.stringify(result.data.message.email);
                let type = JSON.stringify(result.data.message.type);
                localStorage.setItem("type", type);
                localStorage.setItem("email", email);
                axios
                  .get(`${process.env.REACT_APP_SERVER_PATH}/users/${id}`)
                  .then((response) => {
                    console.log(
                      "🚀 ~ file: SignIn.js:358 ~ axios.get ~ response",
                      response.data
                    );
                    if (response.data.type === "customer") {
                      if (
                        response.data.profile_step === null ||
                        response.data.profile_step === "0"
                      ) {
                        update_profile_step(token, id, "1")
                          .then((response) => {
                            props.toggle();
                            localStorage.setItem(
                              "customer_signup_token",
                              token
                            );
                            props.setCustomerSignUpFlow(true);
                            dispatch(setBannerFalse());
                          })
                          .catch((e) => {
                            console.log("Error in Google");
                          });
                      } else if (response.data.profile_step === "9") {
                        localStorage.setItem("type", type);
                        localStorage.setItem("email", email);
                        localStorage.setItem("cp_web_token", token);
                        localStorage.setItem("id", id);
                        updateTimeZone(id);
                        if (isFromBookingModal) {
                          axios
                            .get(
                              process.env.REACT_APP_SERVER_PATH +
                                `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
                              { headers: { Authorization: `Bearer ${token}` } }
                            )
                            .then((response) => {
                              dispatch(FreeCallNotCompleted());
                              props.toggle();
                              dispatch(toggleFreeCallModal());
                              dispatch(existingNavState());
                              dispatch(isFromBookingModal());
                            })
                            .catch((e) => {
                              props.toggle();
                              dispatch(offFreeCallModal());
                              alert("You have already used free call service");
                              dispatch(FreeCallCompleted());
                            });
                        } else {
                          props.toggle();
                          navigate(
                            !location.pathname.includes("/profile/") &&
                              requiredUrl == "/"
                              ? `/dashboard/home`
                              : requiredUrl
                          );
                          setLoading(false);
                        }
                      } else {
                        update_profile_step(
                          token,
                          id,
                          response.data.profile_step
                        )
                          .then((response) => {
                            props.toggle();
                            localStorage.setItem(
                              "customer_signup_token",
                              token
                            );
                            props.setCustomerSignUpFlow(true);
                            dispatch(setBannerFalse());
                          })
                          .catch((e) => {
                            console.log("Error in Google");
                          });
                      }
                    } else {
                      if (
                        response.data.profile_step === null ||
                        response.data.profile_step === "0"
                      ) {
                        update_profile_step(token, id, "1")
                          .then((response) => {
                            props.toggle();
                            localStorage.setItem("signup_token", token);
                            dispatch(setProfessionalsToTrue());
                            dispatch(setBannerFalse());
                          })
                          .catch((e) => {
                            console.log("Error in Google");
                          });
                      } else if (response.data.profile_step === "12") {
                        if (localStorage.getItem("id")) {
                          axios
                            .get(
                              process.env.REACT_APP_SERVER_PATH +
                                `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
                              { headers: { Authorization: `Bearer ${token}` } }
                            )
                            .then((response) => {
                              dispatch(FreeCallNotCompleted());
                            })
                            .catch((e) => {
                              dispatch(FreeCallCompleted());
                            });
                        }
                        localStorage.setItem("type", type);
                        localStorage.setItem("email", email);
                        localStorage.setItem("cp_web_token", token);
                        localStorage.setItem("id", id);
                        updateTimeZone(id);
                        if (isFromBookingModal) {
                          axios
                            .get(
                              process.env.REACT_APP_SERVER_PATH +
                                `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
                              { headers: { Authorization: `Bearer ${token}` } }
                            )
                            .then((response) => {
                              dispatch(FreeCallNotCompleted());
                              props.toggle();
                              dispatch(toggleFreeCallModal());
                              dispatch(existingNavState());
                              dispatch(isFromBookingModal());
                            })
                            .catch((e) => {
                              props.toggle();
                              dispatch(offFreeCallModal());
                              alert("You have already used free call service");
                              dispatch(FreeCallCompleted());
                            });
                        } else {
                          props.toggle();
                          navigate(
                            !location.pathname.includes("/profile/") &&
                              requiredUrl == "/"
                              ? `/dashboard/home`
                              : requiredUrl
                          );
                          setLoading(false);
                        }
                      } else {
                        update_profile_step(
                          token,
                          id,
                          response.data.profile_step
                        )
                          .then((response) => {
                            props.toggle();
                            localStorage.setItem("signup_token", token);
                            dispatch(setProfessionalsToTrue());
                            dispatch(setBannerFalse());
                          })
                          .catch((e) => {
                            console.log("Error in Google");
                          });
                      }
                    }
                  });
                const name = `${result.data.message.first_name} ${result.data.message.last_name}`;
                Mixpanel.identify(result.data.message.email);
                Mixpanel.track("Successful login on website", {
                  "User id": id,
                });
                Mixpanel.people.set({
                  $first_name: result.data.message.first_name,
                  $last_name: result.data.message.last_name,
                  $name: name,
                  id: id,
                  $email: result.data.message.email,
                  type: type,
                });
              }
            })
            .catch((e) => {
              setErrorText(e.response.data.error);
              setError(true);
              setLoading(false);
              Mixpanel.track("Unsuccessful login on web");
            });
        }
      }}
    >
      {(formik) => (
        <div
          className={props.modal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={props.overlay} onClick={props.toggle}></div>
          <div className="flex flex-col w-11/12 md:w-1/3 z-20">
            {!forgetPassword ? (
              <div className="text-textFullDark flex flex-col items-center justify-center px-2">
                <div className="bg-white px-6 py-8 rounded shadow-md text-textFullDark w-full">
                  <h1 className="mb-4 text-3xl text-center">Sign In</h1>
                  <p className="text-center text-gray-400 mb-4 text-sm">
                    Please enter email address and password to Sign in.
                  </p>
                  <Form>
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Enter email address"
                    />
                    <TextField
                      name="password"
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      passwordShown={passwordShown}
                      setPasswordShown={setPasswordShown}
                    />
                    {error ? (
                      <p className="text-red-500 text-sm my-3">{errorText}</p>
                    ) : null}
                    {success && (
                      <p className="text-red-500 text-sm my-3">{successText}</p>
                    )}
                    <div className="w-full flex justify-between mb-4 text-xs text-textFullDark">
                      <label className="inline-flex items-center text-2">
                        <input
                          type="checkbox"
                          className="form-checkbox rounded"
                        />
                        <span className="ml-2">Remember me</span>
                      </label>
                      <span
                        className="text-textApp cursor-pointer"
                        onClick={() => setForgetPassword(!forgetPassword)}
                      >
                        Forgot Password?
                      </span>
                    </div>

                    <button
                      type="submit"
                      className="w-full text-center flex items-center justify-center py-3 rounded bg-bgAppColor text-white hover:bg-bgAppColor focus:outline-none my-1"
                    >
                      Sign In{" "}
                      {isLoading ? (
                        <span className="inline-block ml-4">
                          <Loader
                            type="TailSpin"
                            color="white"
                            height={20}
                            width={20}
                            // timeout={5000} //5 secs
                          />
                        </span>
                      ) : null}
                    </button>
                  </Form>
                  <hr className="my-2" />
                  <button className="w-full py-3 rounded bg-blue-600 flex items-center justify-center hover:bg-blue-700 focus:outline-none my-1">
                    <img
                      onClick={linkedInLogin}
                      src="/linkedin.png"
                      alt="Sign in with Linked In"
                      style={{ maxWidth: "180px", cursor: "pointer" }}
                    />
                  </button>
                  <div className="flex sm:flex-col md:flex-row justify-between items-center w-full mt-4">
                    <button
                      className="w-full md:w-1/2 rounded whitespace-nowrap focus:outline-none my-1"
                      style={{ fontFamily: "Lato sans-serif" }}
                    >
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Sign up with Google"
                        onSuccess={handleLogin}
                        className="w-full btn_font_family"
                        style={{ fontFamily: "Lato sans-serif" }}
                      />
                    </button>
                    <div className="w-full md:w-1/2 md:ml-2">
                      <AppleSignin
                        /** Auth options passed to AppleID.auth.init() */
                        authOptions={{
                          clientId: "com.careerpaths.application",
                          scope: "email name",
                          redirectURI: `${process.env.REACT_APP_SERVER_PATH}/auth/apple_callback`,
                          state: "",
                          nonce: "nonce",
                          usePopup: true,
                        }}
                        /** General props */
                        uiType="dark"
                        /** className */
                        className="apple-auth-btn w-full"
                        /** Allows to change the button's children, eg: for changing the button text */
                        buttonExtraChildren="Continue with Apple"
                        /** Checkout README.md for further customization props. */
                        /** Spread rest props if needed */
                        onSuccess={(response) => handleAppleLogin(response)} // default = undefined
                        /** Called upon signin error */
                        onError={(error) => console.error(error, "error")} // default = undefined
                        style={{ fontFamily: "Lato sans-serif" }}
                      />
                    </div>
                  </div>
                  <div className="flex text-sm text-textFullDark mt-2">
                    <p>Not a member?</p>
                    <button
                      className="ml-1 text-textApp underline"
                      onClick={() => {
                        // dispatch({ type: 'setBannerFalse' })
                        dispatch(setBannerFalse());
                        props.setCustomerSignUpFlow(!props.customerSignUpFlow);
                        props.toggle();
                      }}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded">
                <div
                  className="w-full text-white py-4 px-4 mb-6 flex justify-between items-center rounded text-textFullDark"
                  style={{ backgroundColor: Colors.White }}
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setForgetPassword(!forgetPassword);
                    }}
                  >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                  </svg> */}
                  </span>
                  <p className=" font-bold text-sm mb-4 text-2xl text-center mx-auto">
                    Reset Password
                  </p>
                  {/* <span className="cursor-pointer" onClick={() => {
                  props.toggle()
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </span> */}
                </div>
                <div className="px-6 pb-6">
                  <Form>
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Enter email address"
                    />
                    <button
                      type="submit"
                      className="w-full text-center flex items-center justify-center py-3 mt-6 rounded bg-blue-400 font-bold text-white hover:bg-blue-600 focus:outline-none my-1"
                      style={{ backgroundColor: Colors.blue }}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
}

export default SignIn;


