import React, { useState, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../Arrows/LeftArrow";
import RightArrow from "../Arrows/RightArrow";
import { Link } from "react-router-dom";
import User from "../components/User/UserCard";
import ReactTooltip from "react-tooltip";

const ReuseableComponents = (props) => {
  let Ref = useRef(null);
  const [settings] = useState({
    dots: false,
    speed: 500,
    lazyLoad: "ondemand",
    slidesToShow: props.len && props.len <= 4 ? props.len : 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const SlickNext = () => {
    Ref.current.slickNext();
  };
  const SlickPrev = () => {
    Ref.current.slickPrev();
  };
  return (
    <div>
      <div>
        <div className="md:container mx-auto my-4 sm:px-2 xl:px-12 py-1 flex justify-between items-center">
          <h1 className="text-textFullDark sm:text-lg md:text-2xl font-bold flex gap-1 md:ml-4">
            {props.name}{props.name === "Recommended for you" && (
              <div
                className="relative"
                data-tip="Mentors handpicked for you based on the help you need."
              >
                <ReactTooltip place="bottom" className="w-36 text-xs" />
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                  className="mt-1"
                >
                  <path d="M12 6a3.939 3.939 0 00-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 00-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0012 6zm-1 10h2v2h-2z" />
                  <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              </div>
            )}
          </h1>
          <div className="text-md text-textFullDark flex items-center">

            {props.name === "Popular Mentors" && (
              <Link className="clearfix mt-1" to={`/mentors?featured=1`}>View All</Link>
            )}

            {props.industryId && (
              <Link className=" mt-1" to={`/mentors/${props.industrySlug}`}>View All</Link>
            )}

            <LeftArrow click={SlickPrev} />
            <RightArrow click={SlickNext} />
          </div>
        </div>

        <div className="md:container mx-auto my-4 sm:px-2 md:pl-16 md:pr-12">
          <Slider {...settings} ref={Ref}>
            {props.Featured &&
              props.Featured.map((p, i) => {
                return (
                  <Link to={`profile/${p.User.first_name.toLowerCase()}-${p.User.last_name.toLowerCase()}-${p.User.id}`} key={i}>
                    <div className="sm:p-1 md:p-3 border border-borderGray box-border rounded-lg hover:shadow-lg sm:mr-2 md:mr-4">
                      <User
                        name={p.User.first_name}
                        designation={p.job_title}
                        time={p.response_time}
                        price={p.session_price_15_min}
                        image={p.User.profile_photo}
                        isCharity={p.is_charity}
                        intro_link={p.prof_intro_link}
                        location={p.location}
                        reviewCount={p.reviewCount}
                        isAnonymous={p.is_anonymous}
                        Experiences={p.Experiences}
                        avgRating={p.avgRating}
                        width={72}
                        years_of_experience={p.years_of_experience}
                      />
                    </div>
                  </Link>
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ReuseableComponents;
