import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import Colors from "../../../../Colors/Colors";
import { useNavigate } from "react-router-dom";
import { toggleChangePercentageModal } from "../../../../Redux/reducers/charity/charitySlice";
import { useDispatch } from "react-redux";

const LearnMoreModal = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const donations = [
    { id: 0, value: 2, label: "2%", isActive: false },
    { id: 1, value: 5, label: "5%", isActive: false },
    { id: 2, value: 10, label: "10%", isActive: false },
    { id: 3, value: 15, label: "15%", isActive: false },
  ];
  const [currentActive, setCurrentActive] = useState(0);
  const currentActiveValue = useRef(props.percent);
  const [submit, setSubmit] = useState(false);

  const gotoDashboard = () => {
    dispatch(toggleChangePercentageModal());
    navigate("/dashboard/home");
  };

  const onChangeValue = (event, id) => {
    setCurrentActive(id);
    currentActiveValue.current = event.target.value;
    console.log(
      "current active ",
      currentActive,
      "donation id...",
      donations[id].id
    );
    console.log(
      "value",
      event.target.value,
      donations[id].value,
      currentActiveValue.current
    );
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    let id = localStorage.getItem("id");
    if (currentActiveValue.current > 0 && currentActiveValue.current < 100) {
      axios
        .put(process.env.REACT_APP_SERVER_PATH + `/professionals_charity`, {
          charity_id: "1",
          user_id: id,
          percent: currentActiveValue.current,
        })
        .then((response) => {
          setSubmit(true);
          console.log("current percentage after update..", response.data.msg);
        })
        .catch(function (error) {
          console.log("Error in geting Anomysss");
        });
    }else{
      alert("Percentage should be between 0 and 100")
    }
  };

  const chooseCurrentActive = () => {
    if (props.percent == 2) {
      console.log("if value", 2);
      setCurrentActive(0);
    } else if (props.percent == 5) {
      console.log("if value", 5);
      setCurrentActive(1);
    } else if (props.percent == 10) {
      console.log("if value", 10);
      setCurrentActive(2);
    } else if (props.percent == 15) {
      console.log("if value", 15);
      setCurrentActive(3);
    } else {
      console.log("if value", 20);
      setCurrentActive(4);
    }
  };
  useEffect(() => {
    console.log("before useeffect current", currentActive, props.percent);

    chooseCurrentActive();
    console.log("useeffect current", currentActive, props.percent);
  }, []);

  return (
    <div
      className={props.modal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={props.overlay}></div>

      <div className="flex flex-col items-center justify-center md:w-2/5 w-11/12  z-20 bg-white rounded-xl py-4">
        {!props.isLoading ? (
          <div className="">
            {submit ? (
              <div className="bg-white  sm:max-w-sm rounded-xl dark:bg-gray-900 sm:my-8 sm:w-full sm:p-6">
                <div className="flex items-center justify-center">
                  <img
                    className="h-24 w-24 "
                    // src="https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    src="/charity/honor.png"
                    alt=""
                  />
                </div>

                <div className="mt-2 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-800 capitalize dark:text-white"
                    id="modal-title"
                  >
                    Your donation percentage is updated successfully
                  </h3>
                </div>

                <div className="mt-4 sm:flex sm:items-center sm:justify-between sm:mt-6 sm:-mx-2">
                  <button
                    onClick={gotoDashboard}
                    className="px-4 sm:mx-2 w-full py-2.5 mt-3 sm:mt-0 text-sm font-medium tracking-wide text-white capitalize bg-bgAppColor rounded-md "
                  >
                    Go to Dashboard
                  </button>
                </div>
              </div>
            ) : (
              <section className="text-gray-600 body-font">
                <div className="container px-5 mx-auto flex flex-col">
                  <div className="flex my-2 items-center justify-end">
                    <button
                      onClick={props.toggle}
                      type="button"
                      className="text-gray-400 hover:text-gray-500 "
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <section aria-labelledby="options-heading" className="">
                    <img
                      className="w-full p-5 object-cover object-center"
                      // src="https://dummyimage.com/720x400"
                      src="/charity/inspire.png"
                      alt="blog"
                    />
                    <form>
                      <div className="">
                        <div>
                          <h2 className="text-1xl font-bold text-gray-900 sm:pr-12">
                            Select Percentage
                          </h2>
                          <p className="leading-relaxed mb-3">
                            We’re delighted that you want to be a part of our
                            mission! We will provide you regular updates of your
                            total contribution to our charities. Please select
                            the proportion of your earnings that you would like
                            to donate to charity:{" "}
                          </p>
                        </div>
                        <div
                          // onChange={(e) => onChangeValue(e)}
                          className=" grid grid-cols-2 gap-4"
                        >
                          {donations.map((donation) => (
                            <label
                              key={donation.id}
                              className={`group ${
                                donation.id == currentActive
                                  ? "ring ring-offset-1"
                                  : ""
                              } relative border rounded-md py-6 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 bg-white shadow-sm text-gray-900 cursor-pointer`}
                            >
                              <input
                                type="radio"
                                name="size-choice"
                                value={donation.value}
                                onChange={(e) => onChangeValue(e, donation.id)}
                                className={"sr-only"}
                                aria-labelledby="size-choice-0-label"
                              />
                              <span>{donation.label}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="w-full">
                          <div className=" relative ">
                            <input
                              onChange={(e) =>
                                (currentActiveValue.current = e.target.value)
                              }
                              type="text"
                              className=" rounded-lg text-center border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base"
                              placeholder="Enter Percentage Manually"
                            />
                          </div>
                        </div>
                        <button
                          onClick={(e) => handleConfirm(e)}
                          className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-bgAppColor py-3 px-8 text-base font-medium text-white "
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </section>
                </div>
              </section>
            )}
          </div>
        ) : (
          <div className="w-full flex justify-center items-center h-full">
            <Loader width={200} height={200} type="Oval" color={Colors.blue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LearnMoreModal;
