import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
const Voucher = (props) => {
    const [loader, setLoader] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [error, setError] = useState('')
    const [color, setColor] = useState(false);
    const [inputvalue, setInputvalue] = useState('');
    const [responses, setResponses] = useState(
        [{
            discountAmount: 0,
            discount_type: null,
            status: false,
        }]);
    useEffect(() => {

    }, [responses])
    const ButtonHandler = (event) => {
        setButtonDisabled(prev => !prev)
        setLoader(true)
        props.coupenName(inputvalue);
        axios.get(`${process.env.REACT_APP_SERVER_PATH}/coupon/verify/${inputvalue}/${props.professionalID}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
            }
        }).then((response) => {
            setLoader(false)
            setButtonDisabled(false)

          if(response.data?.message?.discount_type !== "percentage" && response.data.message.discount_amount > props.propslocationprice){

                setError("Coupen code is greater than the meeting amount")
                return;
            }
            if (response?.data?.error?.msg === "Coupon is not valid") {
                setError("This Discount Code does not exist")
            } else {
                let token = localStorage.getItem("cp_web_token") && localStorage.getItem("cp_web_token")
                if (response.data.message.discount_amount == 100 && response.data.message.discount_type == 'percentage' && process.env.REACT_APP_FREE_CALL == inputvalue) {
                    axios.get(process.env.REACT_APP_SERVER_PATH + `/verify-freecall/${process.env.REACT_APP_FREE_CALL}`,
                        { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
                            setResponses([{
                                discountAmount: response.data.message.discount_amount,
                                discount_type: response.data.message.discount_type,
                                status: response.data.message.status,
                            }]);
                            props.toggle();
                        }).catch(e => {
                            console.log("Wo nhi use Kar sakta he")
                            setError("Discount code is not valid anymore")
                        })
                } else {
                    setResponses([{
                        discountAmount: response.data.message.discount_amount,
                        discount_type: response.data.message.discount_type,
                        status: response.data.message.status,
                    }]);
                    props.toggle();
                }

            }
        }).catch(error => {
            setButtonDisabled(false)
            setLoader(false)
            console.log(error, "error")
            if (error?.response?.data?.error?.msg === "Coupon is not valid") {
                setError("This Discount code doesn't exist")
            } else if (error?.response?.data?.error === "Coupon is not valid anymore") {
                setError("Discount code is not valid anymore")
            } else if (error?.response?.data?.error === "User exceeded single usage limit") {
                setError("Discount code is not valid anymore")
            } else {
                setError('Coupon is not valid for this mentor')
            }

        })

    }

    if (responses[0].discount_type === "percentage") {
        let z = props.propslocationprice;
        let x = props.propslocationprice / 100;
        let y = x * responses[0].discountAmount;
        let c = props.propslocationprice - y;
        c = parseFloat(c).toFixed(2);
        props.AfterCalHandler(c, y, z, responses[0].discount_type, responses[0].discountAmount);
    } else if (responses[0].discount_type === 'fixed') {

        let z = props.propslocationprice;

        let x = responses[0].discountAmount;
        if (x <= props.propslocationprice) {
            let c = props.propslocationprice - x;
            c = parseFloat(c).toFixed(2);
            props.AfterCalHandler(c, x, z, responses[0].discount_type, "");
        }
        if (x > props.propslocationprice) {
            // let c = props.propslocationprice - x;
            // c = parseFloat(x).toFixed(2);
            // props.AfterCalHandler(z, 0, z, responses[0].discount_type, "");
        }

    } else if (responses[0].discount_type === 'priceSpecific') {
        let z = responses[0].discountAmount
        let c = props.propslocationprice;
        props.AfterCalHandler(z, responses[0].discountAmount, c, responses[0].discount_type, "");
    }
    return (
        <div className={props.modal}>
            <div className={props.overlay} onClick={props.toggle}></div>
            <div className="w-screen h-screen  flex items-center justify-center">
                <div className="sm:w-11/12 md:w-1/3 bg-white border border-borderGray p-4 z-30 rounded ">
                    <div className="flex w-full justify-end">
                        <button className="border border-borderGray hover:bg-bgAppColor p-1 justify-center items-center flex rounded-full w-6 h-6 text-textDark hover:text-white" onClick={props.toggle}>x</button>
                    </div>
                    <div style={{ marginTop: "11%" }}>
                        <h1 className="text-2xl font-bold mt-4">Discount code</h1>
                        <h2 className="text-lg mt-3 text-textDark">Enter your discount code</h2>
                        <input style={{ borderColor: color ? "red" : null }} placeholder="Write here" value={inputvalue} onChange={evt => {
                            if (evt.target.value === '') {
                                setError("")
                            }
                            setInputvalue(evt.target.value)
                        }} className="outline-none border border-borderGray p-2 pl-4 w-full rounded mt-2" />
                        <div className="mt-4">
                            {responses[0].status && <label style={{ color: "grey" }} className="text-grey" for="vehicle1"> This Discount code Added successfully</label>}
                        </div>
                        {!responses[0].status && <p className="my-3 text-red-600"> {error} </p>}
                        <button className={`${inputvalue !== '' && buttonDisabled === false ? 'bg-bgAppColor' : 'bg-bgdarkGray'} font-bold text-xl py-2 w-full flex justify-center items-center rounded mt-4 text-white`} disabled={inputvalue !== '' && buttonDisabled === false ? false : true} onClick={ButtonHandler}>{loader ? <Loader
                            height={20}
                            width={20}
                            color="white"
                            type="Oval"
                            timeout={1000}
                        /> : 'Done'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Voucher
