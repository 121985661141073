import React from 'react'

const ButtonCookies = (props) => {
    return (
        <div className='md:w-1/2 bg-black m-4 p-4 rounded md:h-36'>
            <div className='w-full flex justify-end mb-4'>
                <span onClick={() => props.setCookies(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>
            </div>
            <div className='flex flex-col md:flex-row'>
                <p className='text-white md:w-4/5'>This website uses cookies to enhance the user experience
                    If you want to save cookies then you click on button</p>
                <button className='bg-yellow-400 mt-4 md:mt-0 md:w-1/5 p-2 md:ml-2'>Accept Cookies</button>
            </div>
        </div>
    )
}

export default ButtonCookies