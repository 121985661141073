import React from "react";
import { Link } from "react-router-dom";
import User from "../../components/User/UserCard";
const FilterUsers = (props) => {
  return (
    <div className="w-full flex md:flex-wrap sm:flex-col md:flex-row sm:px-8 md:px-0">
      {props.Featured.length > 0 ? (
        props.Featured.map((p, i) => (
          <div className="p-2 border sm:w-full md:w-52  rounded-md hover:shadow-2xl m-2 cursor-pointer">
            <Link to={`/profile/${p.User.first_name.toLowerCase()}-${p.User.last_name.toLowerCase()}-${p.User.id}`} key={i}>
              <User
                name={p.User.first_name}
                designation={p.job_title}
                time={p.response_time}
                price={p.session_price_15_min}
                image={p.User.profile_photo}
                isCharity={p.is_charity}
                intro_link={p.prof_intro_link}
                location={p.location}
                reviewCount={p.reviewCount}
                isAnonymous={p.is_anonymous}
                Experiences={p.Experiences}
                avgRating={p.avgRating}
                width={52}
                years_of_experience={p.years_of_experience}
              />
            </Link>
          </div>
        ))
      ) : (
        <div className="flex justify-center w-full items-center m-1 font-medium py-1 rounded-md text-red-700 bg-red-100 border border-red-300 ">
          <div slot="avatar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-alert-octagon w-5 h-5 mx-2"
            >
              <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12.01" y2="16"></line>
            </svg>
          </div>
          <p className="text-xl font-normal  max-w-full flex-initial">
            No mentors found based on this criteria. Try broaden your filter!
          </p>
          <div className="flex flex-auto flex-row-reverse">
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default FilterUsers;
