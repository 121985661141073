import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import { CompanyTitle } from '../settings'
import PrivacyPolicy from './PrivacyPolicy'

const Privacy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy | {CompanyTitle}</title>
                <meta name={`Privacy Policy | ${CompanyTitle}`} content={`Privacy Policy | ${CompanyTitle}`} />
            </Helmet>
            <Navbar />
            <hr />
            <div className='container mx-auto px-16'>
                <PrivacyPolicy />
            </div>
            <Footer />
        </div>
    )
}

export default Privacy