import React from 'react'
import { Link } from 'react-router-dom'
import { CompanyTitle } from '../../../settings'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { disableScroll, enableScroll } from "../../../Redux/reducers/Scroll/Scroll";
// import { disableScroll, enableScroll } from "../Redux/reducers/Scroll/Scroll";

import {
    LinkedinIcon,
    EmailIcon,
    WhatsappIcon
} from "react-share";
import {
    EmailShareButton,
    LinkedinShareButton,
    WhatsappShareButton
} from "react-share";

const GetReferal = (props) => {
    // const dispatch = disableScroll();
    //   dispatch(enableScroll());
    //   dispatch(disableScroll());

    let shareUrl = 'https://careerpaths.io/' + ' I am giving you 20% off your first session on CareerPaths app. To accept, use code ' + props.user.reference_code + ' when signing up! Click on the above link'
    return (
        <div className='rounded bg-white mb-6'>
            <div className='w-full bg-bgAppColor p-2'>
                <p className='text-center text-white text-lg'>Invite Friends</p>
            </div>
            <div className='w-full flex flex-col justify-center items-center'>
                <img src='/man.png' className='w-56 object-cover my-6' alt='man' />
                <p className='text-2xl text-center my-2 font-bold'>Get £20 FREE</p>
                <div className='w-10/12'>
                    <p className='text-lg text-center my-2'>When a friend signs up with your code and they book a {CompanyTitle} session priced at £20 or more, you'll get a free £20 credit and they will get 20% OFF their first booking</p>
                </div>
                <CopyToClipboard text={`I'm giving you 10% off your first session on CareerPaths app. \n To accept, use code ${props.user.reference_code} when signing up!  Details: https://careerpaths.io/`}
                    onCopy={() => toast('Copied to Clipboard')}>
                    <div>
                        <button className='p-2 rounded text-center w-full text-sm'>Click to copy</button>
                        <p className='text-sm text-black text-center font-bold cursor-pointer'>{props.user.reference_code}</p>
                    </div>
                </CopyToClipboard>

                <div className='w-full flex flex-col items-center justify-center my-2 mb-6 px-2'>
                    <p className='text-2xl text-center my-2 font-bold'>Invite Friends</p>
                    <div className='flex items-center my-4 w-full md:w-1/3 justify-evenly'>
                        {/* <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton> */}
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton url={shareUrl}>
                            <EmailIcon size={40} round={true} />
                        </EmailShareButton>

                    </div>
                    <Link to="/terms-and-conditions" className=' mt-3 p-2 rounded text-center w-full md:w-1/2'>
                        <button className='underline'>Terms and Conditions</button>
                    </Link>
                </div>

            </div>
            <ToastContainer
                autoClose={100}
            />
        </div>
    )
}


export default GetReferal