import React from 'react'
import TermsContent from './TermsContent'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet'
import { CompanyTitle } from '../settings'

const Terms = () => {
    return (
        <div>
            <Helmet>
                <title>Terms and conditions | {CompanyTitle}</title>
                <meta name={`Terms and conditions | ${CompanyTitle}`} content={`Terms and conditions | ${CompanyTitle}`} />
            </Helmet>
            <Navbar />
            <hr />
            <div className='md:container md:mx-auto px-2 xl:px-16'>
                <TermsContent />
            </div>
            <Footer />
        </div>
    )
}

export default Terms