import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const ForMentor = () => {
    return (
        <div>
            <Navbar />
            <hr />
            {/* Download Main Component  */}
            <div className='container mx-auto flex flex-col items-center justify-center py-6 md:py-40' style={{
                fontFamily: 'Lato'
            }} >
                <h1 className='text-4xl md:text-7xl text-center' style={{
                    fontFamily: 'Lato Bold'
                }}>Apply to become a mentor</h1>
                <h2 className='text-sm md:text-2xl text-center text-textDark w-full md:w-3/5 my-3'>Your knowledge is valuable. Share it with someone who knows that and make an immediate impact.</h2>
                <div className='w-full px-4 flex justify-center'>

                    <button className='p-2 text-center text-lg bg-bgAppColor rounded-full px-8 text-white' style={{ fontFamily: 'Lato Bold' }}><a href="https://form.typeform.com/to/toKmpu2Y?typeform-embed-id=2678222441507472&typeform-embed=popup-blank&typeform-source=careerpaths.io&typeform-medium=embed-sdk&typeform-medium-version=next" target='_blank' rel="noreferrer" >Apply</a></button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForMentor