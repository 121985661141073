import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'
import axios from 'axios';
import Colors from '../../../../../Colors/Colors';
import { useLocation, useNavigate } from 'react-router-dom';
import "./BookingDetails.css";
import 'fix-date'; 
const RescheduleMeeting = (props) => {
    let navigate = useNavigate()
    let location = useLocation()
    const [value, setValue] = useState(new Date());
    const [timeSlot, setTimeSlot] = useState(null);
    const [slots, setSlots] = useState([]);
    const [slotcolor, setSlotcolor] = useState(null);
    const [dictionary, setDictionary] = useState({})
    const [availableDates, setAvailableDates] = useState([]);
    const [disableAcceptBtn, setDisabledAcceptBtn] = useState(false);

    const makeDic = async (available_slots, month, e, isTile) => {
        let dictionary = {};
        let value = isTile ? e : undefined;
        let todayDate = moment(new Date(), 'DD-MM-YYYY');
        let pastDate = moment(e, 'DD-MM-YYYY');
        for (const time of available_slots) {
            const dateTime = moment(time * 1000);
            if (dateTime.month() === month - 1) {
                const time = dateTime.format("LT");
                const date = dateTime.format("DD-MM-YYYY");
                if (dictionary[date]) dictionary[date].push(time);
                else dictionary[date] = [time];
            }
        }
        setDictionary(dictionary)
        setAvailableDates(Object.keys(dictionary));

        if (todayDate.isSame(pastDate)) {
            for (let i in dictionary) {
                if (value !== undefined) {
                    if (
                        i ===
                        `${moment(value).format("DD").toString()}-${moment(value)
                            .format("MM")
                            .toString()}-${value.getFullYear().toString()}`
                    ) {
                        setSlots(dictionary[i]);
                    }
                }
            }
        } else if (todayDate.isBefore(pastDate)) {
            for (let i in dictionary) {
                if (value !== undefined) {
                    if (
                        i ===
                        `${moment(value).format("DD").toString()}-${moment(value)
                            .format("MM")
                            .toString()}-${value.getFullYear().toString()}`
                    ) {
                        setSlots(dictionary[i]);
                    }
                }
            }
        } else {

            setValue(new Date());
            setSlots([]);
            setSlotcolor(null);
            // props.setTimeSlotBooking('');
            setDictionary({})
        }

    };


    const getSlots = (month, year, e, isTile) => {
        let token = localStorage.getItem("cp_web_token");
        let duration = 15;

        axios.get(`${process.env.REACT_APP_SERVER_PATH}/professionals/${location.state.bookingDetails.professional_id}/available_slots?month=${month}&year=${year}&slot_duration=${duration}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then((result) => {
            if (
                result &&
                result.data &&
                result.data.message &&
                result.data.message.availableSlots
            ) {
                makeDic(result.data.message.availableSlots, month, e, isTile);
            }

        }).catch((err) => {
            console.log("err", err);
        });
    };
    const handleTimeSlot = (m) => {
        setTimeSlot(m);
        setSlotcolor(m);
    };
    const setDate = (e, isTile = true) => {
        setValue(e);
        setSlots([]);
        setSlotcolor(null);
        setDictionary({})
        let month = e.getMonth() + 1;
        let year = e.getFullYear();
        getSlots(month, year, e, isTile);
    };

    const combineDateAndTime = function (date, time12h) {
        if (time12h == null || time12h == undefined || time12h == '') return;
        const [time, modifier] = time12h?.split(' ');
        let [hours, minutes] = time?.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        let finalDate = new Date(date);
        let timeString = hours + ':' + minutes + ':00';
        let year = finalDate.getFullYear();
        let month = finalDate.getMonth() + 1; // Jan is 0, dec is 11
        let day = finalDate.getDate();
        let dateString = '' + year + '-' + month + '-' + day;
        let combined = new Date(dateString + ' ' + timeString);
        return (combined.getTime()) / 1000;
    };

    const ChangeStatusReschedule = (status) => {
        if (value == null || value == undefined || value == '' || timeSlot == null || timeSlot == undefined || timeSlot == '') return;
        setDisabledAcceptBtn(true);
        axios.post(`${process.env.REACT_APP_SERVER_PATH}/bookings/${location.state.bookingDetails.id}/update_status`, {
            status: status,
            data: moment(
                moment(combineDateAndTime(value, timeSlot) * 1000),
                'YYYY-MM-DD hh:mm A',
            ).unix()
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("cp_web_token")
            }
        }).then(response => {
            navigate(`/dashboard/bookings/${location.state.bookingDetails.id}`)

        }).catch(e => {
            console.log("Error in Change Status")
            setDisabledAcceptBtn(false);
        })
    }

    useEffect(() => {
        setDate(new Date())
    }, [])
    return (
        <div className="bg-white rounded p-4">
            <div className="flex sm:flex-col md:flex-col lg:flex-row lg:justify-between md:justify-between ">
                <div className="flex flex-col justify-center items-center px-2">
                    <p className="sm:text-lg md:text-2xl font-bold">Select a date</p>
                    <div className="calender">
                        <Calendar
                            onChange={setDate}
                            minDate={new Date()}
                            view={'month'}
                            value={value}
                            onClickDay={({ action }) => {
                            }}
                            showNavigation={true}
                            tileClassName={({ date, view, activeStartDate }) => {
                                if (availableDates.find(x => x === moment(date, view).format("DD-MM-YYYY"))) {
                                    return 'text-textApp font-bold focus:text-white w-12 h-12 rounded-full'
                                } else {
                                    return 'text-textLight font-bold bg-white w-12 h-12 rounded-full pointer-events-none'
                                }
                            }}
                            onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                                setDate(activeStartDate, false)
                            }}
                            selectRange={false}
                            showNeighboringMonth={false}
                            next2Label={null}
                            prev2Label={null}
                            className='border-2 border-borderApp rounded-lg p-2'
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center md:h-96 overflow-auto w-full lg:w-64">
                    <p className="sm:text-lg md:text-2xl font-bold">Select a time</p>
                    <div className="flex flex-col items-center md:h-96 overflow-auto w-full">
                        {slots.length < 0 === null
                            ? null
                            : slots.map((m) => {
                                return (
                                    <p
                                        key={m}
                                        className="cursor-pointer p-3 border-2 text-center bg-white border-borderApp font-bold rounded-full  mb-2 w-full"
                                        style={{
                                            backgroundColor: slotcolor === m ? Colors.blue : null,
                                            color: slotcolor === m ? 'white' : 'black'
                                        }}
                                        onClick={() => handleTimeSlot(m)}
                                    >
                                        {m}
                                    </p>
                                );
                            })}
                    </div>
                    {slots.length > 0 ? <div className='w-full mt-3'>
                        {disableAcceptBtn ? <div className={`bg-bgdarkGray rounded w-full py-2 text-center text-white flex justify-center items-center`}>
                            <div className="loading-spinner"></div>
                        </div> : <>
                            <button
                                disabled={disableAcceptBtn}
                                className={`${disableAcceptBtn ? "bg-bgdarkGray" : "bg-bgAppColor"} rounded w-full py-2 text-center text-white`}
                                style={{ backgroundColor: Colors.blue }}
                                onClick={
                                    () => {
                                        
                                        ChangeStatusReschedule('request-reschedule')
                                    }}>Confirm</button>
                        </>}

                    </div> : null}
                </div>


            </div>
        </div>
    )
}

export default RescheduleMeeting