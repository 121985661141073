import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import SignUpProcessOfCustomer from "../SignUpProcessOfCustomer/SignUpProcessOfCustomer";
import { setProfessionalsToFalse } from "../Redux/reducers/professional/professionalSlice";
import { setCustomerFalse } from "../Redux/reducers/customer/customerSlice";
import { useDispatch } from "react-redux";
import { Mixpanel } from "../Mixpanel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  disableScroll,
  enableScroll,
} from "../Redux/reducers/Scroll/Scroll";

import { zIndexend } from ".././Redux/reducers/searchBar/searchBar";

import { LinkedinIcon, EmailIcon, WhatsappIcon } from "react-share";
import {
  EmailShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import Loader from "react-loader-spinner";

const FreeCredit = () => {
  let type = JSON.parse(localStorage.getItem("type"));
  const [loaderf, setLoader] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
    // dispatch = enableScroll();
 
  useEffect(() => {
    let id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_SERVER_PATH + `/users/${id}`)
      .then((response) => {
        if (type === "professional") {
          axios
            .get(process.env.REACT_APP_SERVER_PATH + `/professionals/${id}`)
            .then((response) => {
              setLoader(false);
            })
            .catch(function (error) {
              if (error.response) {
                console.log(
                  "error in fetching user in invitaion page in free credit=>",
                  error.response.data
                );
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            });
        } else if (type === "customer") {
          setText(response.data.reference_code);
        }
        setLoader(false);
        console.log(response.data.first_name);
      })
      .catch((e) => {
        setLoader(false);
        console.log("error in user fetching");
      });
  }, []);
  

    // dispatch(disableScroll());
  let dispatch = useDispatch();
  const [text, setText] = useState("*******");
  let shareUrl =
    " Careerpaths makes impossible career connections possible! Connect with industry professionals instantly through our app. Use code " +
    text +
    " when signing up for 20% off your first meeting. Book now or download the app through" +
    "https://careerpaths.io/";

  const copy = async () => {
    await navigator.clipboard.writeText(shareUrl);
    toast("Copied to Clipboard");
  };

  const [customerSignUpFlow, setcustomerSignUpFlow] = useState(false);
  const toggleCustomerSignUpFlow = () => {
    setcustomerSignUpFlow(false);
    dispatch(setProfessionalsToFalse());
    Mixpanel.track("Press Signup button web");
    dispatch(setCustomerFalse());
  };  

  return (
    <div>
      <Navbar className="z-revert" />
      {/* {showNavbar && <Navbar />} */}
      <hr />

      {customerSignUpFlow && (
        <SignUpProcessOfCustomer
          toggle={toggleCustomerSignUpFlow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={customerSignUpFlow}
          customerSignUpFlow={customerSignUpFlow}
          setCustomerSignUpFlow={setcustomerSignUpFlow}
          isFreeCredit={true}
        />
      )}

      <div
        className="container py-2 bg-bgAppColor sm:my-5 md:my-5 rounded-lg	 mx-auto flex sm:flex-col-reverse md:flex-row items-center justify-center "
        style={{
          fontFamily: 'Lato sans-serif',
        }}
      >
        <div className="flex flex-col items-center md:w-1/2 sm:w-full justify-center">
          <h1
            className="text-white text-4xl md:text-7xl text-center"
            style={{
              fontFamily: "Lato Bold",
            }}
          >
            Get £20 free
          </h1>
          <h2 className="text-sm md:text-2xl text-center text-white w-4/5 mb-2">
            When a friend signs up with your code and they book a CareerPaths
            session priced at £20 or more, you'll get a free £20 credit and they
            will get 20% OFF their first booking{" "}
          </h2>

          <div className="w-full sm:mt-2 md:mt-0 md:w-1/2 md:hidden">
            <img src="/free-credit/1.png" className="object-cover " alt="" />
          </div>

          {loaderf ? (
            <Loader height={25} width={25} color="white" type="Oval" />
          ) : (
            <div className="w-4/5 max-w-xs overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800">
              {localStorage.getItem("cp_web_token") ? (
                <div className="py-5 text-center">
                  <span onClick={copy} className="cursor-pointer">
                    <span className="text-sm my-10 text-gray-700 dark:text-gray-200">
                      Copy to clipboard
                    </span>
                    <p className="block text-3xl mt-2 font-bold text-gray-800 dark:text-white">
                      {text}
                    </p>
                  </span>
                  <div className="w-full mt-2 px-4 flex flex-col items-center justify-center">
                    <button
                      className="p-2 w-full text-center text-lg bg-bgAppColor rounded-lg px-8 text-white"
                      style={{ fontFamily: "Lato Bold" }}
                      onClick={() => setShowButtons(!showButtons)}
                    >
                      Invite a friend
                    </button>

                    {showButtons && (
                      <div className="flex items-center mt-2 w-full justify-evenly">
                        <WhatsappShareButton url={shareUrl}>
                          <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <LinkedinShareButton url={shareUrl}>
                          <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton url={shareUrl}>
                          <EmailIcon size={40} round={true} />
                        </EmailShareButton>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="py-5 px-auto text-center">
                  <span className="cursor-pointer">
                    <span className="text-sm my-10 text-gray-700 dark:text-gray-200">
                      Sign up to see your invitation code
                    </span>
                  </span>
                  <div className="w-full mt-2 px-4 flex justify-center">
                    <button
                      className="p-2 text-center w-full text-lg bg-bgAppColor rounded-lg px-8 text-white"
                      style={{ fontFamily: "Lato Bold" }}
                      onClick={() => {
                        dispatch(zIndexend());
                        setcustomerSignUpFlow(true);
                      }}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="w-full sm:mt-2 md:mt-0 md:w-1/2 sm:hidden md:block">
          <img src="/free-credit/1.png" className="object-cover " alt="" />
        </div>

        <ToastContainer autoClose={100} />
      </div>
      <Footer />
    </div>
  );
};

export default FreeCredit;
