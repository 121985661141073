import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { getRefFromQuery } from "../CustomFunctions/CustomFunctions";

const EmailVerify = (props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  let { hash } = useParams();
  let queryString = useLocation()
  getRefFromQuery(queryString)

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/verify/${hash}`)
      .then((response) => {
        setShowSuccess(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          if (error.response.data.errors === 'Email already validated.')
            setShowSuccess(true)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
      axios
      .get(`https://staging.careerpaths.io/api/verify/${hash}`)
      .then((response) => {
        setShowSuccess(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          if(error.response.data.errors ==='Email already validated.')
          setShowSuccess(true)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }, []);
  return (
    <div>
      <Navbar />
      <hr />
      <div className="flex items-center justify-center min-h-screen p-5  min-w-screen">
        <div className="text-xl flex items-center flex-col bg-white">
          <img src="/user1.png" className="h-48 bg-white" alt="logo" />
          <h2 className="heading bg-white text-green-500">Verified.</h2>
          <p className="para bg-white text-green-500 sm:ml-5 sm:text-center">
            Voila! You have successfully verified the account. 
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmailVerify;
