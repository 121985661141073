import React, { useState } from 'react'
import { CompanySafetyEmail, CompanyTitle, CompanyWebsite } from '../settings'

const FaqMain = () => {
    const customerFAQs = [
        {
            title: `What is ${CompanyTitle}?`,
            description: `${CompanyTitle} is the leading marketplace connecting users directly with thousands of industry experienced professionals through live one-to-one personalised video calls and direct messages. Founded in March 2022 by Guy Ellis and Saad Farooq, CareerPaths’ mission is to make impossible career connections possible!`
        }, {
            title: `How do I request a ${CompanyTitle} meeting?`,
            description: `You can reach out to any of the available industry professionals right from the ${CompanyTitle} website or app! When you\'re ready to book, just log into your account (or create a new one), fill out the booking form, and click the "Request" button! We'll put a temporary authorisation hold on your payment method until your request has been accepted and fulfilled.`
        }, {
            title: 'I just sent a request! What happens next?',
            description: `Once you send your meeting request to a professional, they\'ll have up to 48 hours before the meeting date to accept the request. You can check the status of your request in the ${CompanyTitle} app. If the request is declined or expires, you\'ll receive a notification and email letting you know. When the professional accepts your request, you\'ll receive a notification and email with a link to launch the meeting.`
        }, {
            title: 'What should I put in my meeting request?',
            description: 'Remember you have a limited amount of time, so be clear and precise with the questions you want to cover in your meeting. Include as much detail as possible! What questions are you looking for advice and help on? What does the mentor need to know about you to answer your questions? - the more details, the better!'
        }, {
            title: 'How do I update my email address?',
            description: <p>Just open the app and tap on settings in the lower right corner. Then tap on "Change email." If you need help doing so, feel free to reach out to our team at <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a> and we'll update it for you.</p>
        }, {
            title: `Do I need a ${CompanyTitle} account to use ${CompanyTitle}?`,
            description: `If you\'re just browsing the site, you won\'t need to create an account. When you\'re ready to book your first ${CompanyTitle} meeting, we do ask that you create an account so we can keep you posted on your request\'s status. All of your meeting details will be saved to your ${CompanyTitle} account in the app`
        },
        {
            title: 'Why isn\'t my payment method being accepted?',
            description: <p>There are a few reasons why your payment would not be accepted:
                <p>- Your billing details don’t match what your financial institution has on file for you. In this case, please make sure that the card number, security code, expiration date, and postcode match what your financial institution has on file.</p>
                <p>- Insufficient funds.</p>
                <p>- You are using a payment method not accepted by {CompanyTitle}.</p>
                <p>If you continue to run into any trouble adding or updating your payment method, please reach out to our team at <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a> and we’d be more than happy to look into this for you.</p>
            </p>
        }, {
            title: 'How do I uninstall the app?',
            description: <p>If you’re running into any issues within the app, you may want to try a quick reinstall. You can find instructions on how to uninstall and reinstall the app below:
                <ul className='list-disc'>
                    <li>iOS devices (after you’ve uninstalled the app, just head over to the App Store and reinstall the {CompanyTitle} app)</li>
                    <li>Android devices (after you’ve uninstalled the app, head over to the Google Play Store and reinstall the {CompanyTitle} app)</li>
                </ul>
            </p>
        }, {
            title: 'How do I update my app?',
            description: <p>You can update your app by following the steps below:
                <p className='my-2 font-bold text-textFullDark'>iOS devices:</p>
                <p>Open the App Store and navigate to the Today screen
                    <p>Click on the profile icon</p>
                     If there’s an update available for the {CompanyTitle} app, you’ll see it listed on this screen.
                    Just click on the “Update” button and then relaunch the app once the update is complete
                </p>
                <p className='my-2 font-bold text-textFullDark'>Android devices:</p>
                <p>Open the Google Play Store
                    <p>Tap “Menu” and then select “My apps & games”</p>
                    <p>If there’s an update available for the {CompanyTitle} app, you’ll see it listed on this screen. Just click on the “Update” button and then relaunch the app once the update is complete</p>
                </p>
            </p>
        }, {

            title: `Is there someone I can talk to about inappropriate content I saw on ${CompanyTitle}?`,
            description: <p>If you believe that a {CompanyTitle} profile violates our Terms of Service, please send our team a message at <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a></p>
        }, {
            title: 'Tell me about the legal stuff',
            description: <p>{CompanyTitle} is all about connecting you with the most awesome industry mentors and specialists, but you know we've got to let our Legal Team give you the spiel. Check out our Terms of Service <a href={CompanyWebsite + 'terms-and-conditions'}><span className='text-textApp'>here</span></a></p>
        }, {
            title: `Is ${CompanyTitle} hiring?`,
            description: <p>We’re always looking for rockstars. Email us your CV alongside an overview of what you think you can offer {CompanyTitle} to <a href={'mailto:hello@careerpaths.io'}><span className='text-textApp'>hello@careerpaths.io</span></a>.</p>
        }

    ]
    const professionalFAQs = [
        {

            title: `Where can I see my booking requests?`,
            description: <p>Right in your app! You’ll see all {CompanyTitle} Live video call requests come into your request queue on your profile. You can accept, reschedule or decline any video call request that comes in.</p>
        }, {
            title: 'When do I get paid for my video calls?',
            description: `You can request a pay-out from your ${CompanyTitle} wallet at any time after your Live video call has been completed. Payment from your ${CompanyTitle} wallet to your chosen bank accounts takes anywhere from 2-5 working days following the pay-out request being submitted.`
        }, {
            title: `When do video call requests expire?`,
            description: <p>{CompanyTitle} Live video call requests expire 24 hours before the meeting date and time. If a request expires and you wish to have the mentee rebook you, just let us know by email <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a></p>
        }, {
            title: `What should I do if I’m not free at the requested dates and times anymore?`,
            description: `If for any reason you aren’t available during any of the requested dates and times anymore we recommend that you reschedule or decline the request and send the mentee a message. When you reschedule a video call request you can write in another date and time that works for you so the mentee can rebook!`
        }, {
            title: `How many mentees can attend a video call?`,
            description: `We suggest you limit the conversation to 1 mentee per call. This allows for much more tailored experience for the mentee and does not dilute the advice you are providing the mentee.`
        }, {
            title: 'How long is each video call?',
            description: `When booking a call with you, a mentee will have the option to book a 15 or 30 minute call. These are the only 2 options available on the ${CompanyTitle} platform.`
        }, {
            title: 'How far in advance can mentees request for a video call?',
            description: 'Mentees can request to book you up to 12 months in advance. Mentees will not be able to book you at a time and date where you have a pre-existing booking.'
        }, {
            title: 'How do I join a video call?',
            description: <p>After you accept a date and time for your video call request we will send you an email with the steps and link to join the video call. You can join the video call via the {CompanyTitle} app or through web <a href={CompanyWebsite}><span className='text-textApp'>{CompanyWebsite}</span></a>. As always if you have any questions feel free to reach out through <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a></p>
        }, {
            title: `How can I update my available times for mentees to choose from?`,
            description: <p>You can update your availability using the {CompanyTitle} app or on the website <a href={CompanyWebsite}><span className='text-textApp'>{CompanyWebsite}</span></a>. Simply go to the Settings tab and select Availability. From here you will be able to insert your availability for each day of the week.</p>
        },
        {
            title: 'Can I change my price for video calls?',
            description: 'Yes! You can change your price for video calls at any time! You can update your price for a 15 or 30 minute call, simply go to the Profile tab and select Edit Profile. From here you will be able to update your price.'
        }, {
            title: 'How do I set up my payment information?',
            description: 'Simply select the Profile tab and then select Wallet. At the bottom of the screen select Stripe Connect. Our payments partner Stripe will now take you through a step by step guide to allow you to receive payments.'
        }, {
            title: 'What percentage do I earn from each completed video call?',
            description: <p>For each completed {CompanyTitle} video call you’ll receive 75% of the booking price. The remaining 25% goes to {CompanyTitle} (<a href={CompanyWebsite + 'terms-and-conditions'}><span className='text-textApp'>see our Terms of Service</span></a>).</p>
        }, {
            title: 'When do I get paid?',
            description: `Once your Stripe account is set up, you can transfer your earnings using the ${CompanyTitle} Wallet. To transfer your earnings, simply select "Request Payout" button in your wallet. You will receive those funds within 10 days of transferring. *The majority of payments are received in less than 10 days after initiating each transfer.`
        }, {
            title: 'Can I get a breakdown of my earnings at the end of each month?',
            description: <p>Absolutely! If you ever need a breakdown of your earnings, feel free to reach out to your us over email <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a>.</p>
        }, {
            title: 'How do I add/ change my intro video?',
            description: `${CompanyTitle} allows you to go offline whenever you feel like you need a break from mentoring. When you’re offline, no mentees will be able to book you. To go offline, simply go to the Settings tab and select Availability. Here you will see an online/ offline toggle which you can use to switch your profile offline. You’ll remain offline until you switch yourself back online!`
        }, {
            title: 'How do I set up my payment information?',
            description: 'To change your intro video, click on the Profile tab and select Edit Profile. Here you will see an option to add or update your profile video.'
        }, {
            title: 'How do I change my profile photo?',
            description: 'To change your profile photo, click on the Profile tab and select Edit Profile. Here you will an option to add or update your profile photo.'
        }, {
            title: 'How do I change my bio?',
            description: 'You can change your Bio by clicking on Edit Profile and scrolling down to the “Tell us about your professional career” section. There is a 500 character limit for your Bio.'
        }, {
            title: 'How do I share my profile with my mentees and broader network?',
            description: `You can share your ${CompanyTitle} profile with your mentees by going to your profile in the App and clicking on the share icon button on the top right hand side of your profile page. Select share link icon and choose where you want to share your profile.`
        }, {
            title: 'How can I get rid of a bad review? ',
            description: <p>Unless it violates our Terms of Service, we won’t remove a mentees review. If you think a review violates our Terms, please reach out to <a href={'mailto:' + CompanySafetyEmail + '?'}><span className='text-textApp'>{CompanySafetyEmail}</span></a></p>
        }, {
            title: 'How do I get my profile featured?',
            description: <p>There are quite a few things that go into which profiles are featured. Here are some tips to increase your chances of getting your profile featured:
                <ul className='list-disc'>
                    <li>Respond to your requests - the faster, the better;</li>
                    <li>Give your mentees a 5-star experience each and every time</li>
                    <li>Share your {CompanyTitle} profile on LinkedIn and other social channels</li>
                </ul>
            </p>
        },

    ]
    const [isprofessional, setProfessional] = useState(0)
    const [clicked, setClicked] = useState(null)
    const [faqs, setFaqs] = useState(customerFAQs)
    const toggleAccordian = (index) => {
        if (clicked === index) {
            return setClicked(null)
        }
        setClicked(index)
    }
    return (
      <div>
        <div className="md:container mx-auto md:px-16">
          <div
            className="my-4 mx-4 md:mx-0 md:my-8 rounded-xl p-4 md:p-24 "
            style={{
              backgroundImage: "url(/faqgradient.png)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="w-full flex flex-col items-center">
              <h1 className="text-2xl md:text-4xl font-bold text-center">
                Frequently Asked Questions
              </h1>
              <div className="my-4 md:my-12 flex flex-col md:flex-row">
                <button
                  className={`text-sm px-12 md:px-24 py-2 rounded-md ${
                    isprofessional === 0 ? "bg-bgAppColor" : "bg-white"
                  } ${isprofessional === 0 ? "text-white" : "text-black"}`}
                  onClick={() => {
                    setFaqs(customerFAQs);
                    setProfessional(0);
                  }}
                >
                  Mentees
                </button>
                <button
                  className={`mt-4 md:mt-0 md:ml-6 text-sm px-24 py-2 rounded-md ${
                    isprofessional === 1 ? "bg-bgAppColor" : "bg-white"
                  } ${isprofessional === 1 ? "text-white" : "text-black"}`}
                  onClick={() => {
                    setFaqs(professionalFAQs);
                    setProfessional(1);
                  }}
                >
                  Mentors
                </button>
              </div>
              <Faq
                clicked={clicked}
                toggleAccordian={toggleAccordian}
                professionalQuestions={faqs}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default FaqMain  


const Faq = (props) => {
    return <div className='w-full md:w-4/5 p-3 md:p-10 bg-white rounded-xl'>
        {props.professionalQuestions.map((question, index) => {
            return <div className='border-b flex flex-col'>
                <div className={`w-full flex justify-between py-3 md:pt-4 cursor-pointer md:pl-6`} onClick={() => props.toggleAccordian(index)}>
                    <div className='w-full md:w-11/12'>
                        <h1 className={`text-lg md:text-xl font-bold ${props.clicked === index ? 'text-textApp' : 'text-textFullDark'}`}>{question.title}</h1>

                    </div>
                    <div className='w-1/12 flex justify-end'>
                        {props.clicked !== index ? <span className='text-textFullDark'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                            </svg>
                        </span> :
                            <span className={`${props.clicked === index ? 'text-textApp' : 'text-textFullDark'}`} >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                                </svg>
                            </span>}
                    </div>
                </div>
                <div className='md:pl-6'>
                    {props.clicked === index ? <p className={`break-words text-xs md:text-sm md:pb-4 text-textDark`}>{question.description}</p> : null}
                </div>
            </div>
        })}
    </div>

}
